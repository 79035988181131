import { FC, useEffect, useState } from 'react';

import { ClientData, ClientDataAPIForOwner } from '@/utils/UodkaClients/Core';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#fff',
        borderRadius: 4,
        padding: 18,
    },
}));

export const ConfigBusinessAccountLicense: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const {
        clients: { core: coreClient },
    } = useBusinessCtxAbsolutely();
    const { txt } = useLanguage();
    const [clientData, setClientData] = useState<ClientData | ClientDataAPIForOwner | undefined>(undefined);

    // USEEFFECT
    useEffect(() => {
        coreClient.getData().then((clientData) => setClientData(clientData));
    }, [coreClient]);

    return <div className={c.root}>License</div>;
};
ConfigBusinessAccountLicense.displayName = 'ConfigBusinessAccountLicense';
