import { forwardRef, ComponentProps, ReactNode } from 'react';

import { MenuItem, MenuItemProps } from '@/components/0_atom/Menu/MenuItem';

import MuiMenu from '@material-ui/core/Menu';

export const Menu = forwardRef<
    HTMLElement,
    ComponentProps<typeof MuiMenu> & {
        items: MenuItemProps[];
        emptyPlaceHolder: ReactNode;
    }
>(({ items, onClose, emptyPlaceHolder, ...otherProps }, ref) => {
    return (
        <MuiMenu ref={ref} {...otherProps} onClose={onClose}>
            {items.length > 0 ? (
                items.map((item, i) => (
                    <MenuItem
                        key={i}
                        {...item}
                        onClick={() => {
                            item.onClick && item.onClick();
                            onClose && onClose({}, 'backdropClick');
                        }}
                    />
                ))
            ) : typeof emptyPlaceHolder === 'string' ? (
                <MenuItem isDisabled>{emptyPlaceHolder}</MenuItem>
            ) : (
                <li>{emptyPlaceHolder}</li>
            )}
        </MuiMenu>
    );
});
Menu.displayName = 'Menu';
