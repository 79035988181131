import { BillRecipientSignatures as GqlBillRecipientSignatures } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import {
    ReceivingSignature,
    RevokingSignature,
    getReceivingSignatureFromGql,
    getRevokingSignatureFromGql,
} from '@/utils/UodkaClients/Invoices/models/Signature';

export interface BillRecipientSignaturesInterface {
    receive?: ReceivingSignature;
    revoke?: RevokingSignature;
}

export class BillRecipientSignatures implements BillRecipientSignaturesInterface {
    protected _receive?: ReceivingSignature;

    protected _revoke?: RevokingSignature;

    get receive() {
        return this._receive;
    }

    get revoke() {
        return this._revoke;
    }

    constructor({ receive, revoke }: BillRecipientSignaturesInterface) {
        this._receive = receive;
        this._revoke = revoke;
    }
}

export const getBillRecipientSignaturesFromGql = ({ receive, revoke }: GqlBillRecipientSignatures) => {
    return new BillRecipientSignatures({
        receive: receive ? getReceivingSignatureFromGql(receive) : undefined,
        revoke: revoke ? getRevokingSignatureFromGql(revoke) : undefined,
    });
};
