import { forwardRef, ComponentProps, useEffect } from 'react';

import { AddMutationInput, ProcessingMutation } from '@/utils/ctxs';
import { useMutationItem } from '@/utils/customHooks';
import { getActionState } from '@/components/0_atom/MutationStateIcon';
import Button from '@/components/0_atom/Button';

const MutationButton = forwardRef<
    HTMLButtonElement,
    ComponentProps<typeof Button> & {
        mutation: AddMutationInput;
        setCurrentSlot?: (slot?: ProcessingMutation) => void;
    }
>(({ mutation, setCurrentSlot, onClick, ...otherProps }, ref) => {
    // HOOKS
    const { handleClick, isAvailable, currentSlot } = useMutationItem({ newMutation: mutation, onClick });

    // USEEFFECT
    useEffect(() => {
        setCurrentSlot && setCurrentSlot(currentSlot);
    }, [currentSlot, setCurrentSlot]);

    return (
        <Button
            ref={ref}
            {...otherProps}
            disabled={
                otherProps.disabled ||
                !isAvailable ||
                currentSlot?.status === 'fetching' ||
                currentSlot?.status === 'canceled' ||
                currentSlot?.status === 'incorrectOldValue'
            }
            onClick={handleClick}
            actionState={getActionState(currentSlot)}
        />
    );
});
MutationButton.displayName = 'MutationButton';
export default MutationButton;
