import { GqlDateTimeData } from '@/utils/UodkaClients/Invoices/graphql/customScalars';
import { ReceiptInSharedBill as GqlReceiptInSharedBill } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import { ReceiptData, getReceiptDataFromGql } from '@/utils/UodkaClients/Invoices/models/InvoiceData';

interface ReceiptInSharedBillInterface {
    id: string;
    createdAt: Date;
    receiptData: ReceiptData;
}

export class ReceiptInSharedBill implements ReceiptInSharedBillInterface {
    protected _id: string;

    protected _createdAt: Date;

    protected _receiptData: ReceiptData;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get receiptData() {
        return this._receiptData;
    }

    constructor({ id, createdAt, receiptData }: ReceiptInSharedBillInterface) {
        this._id = id;
        this._createdAt = createdAt;
        this._receiptData = receiptData;
    }
}

export const getReceiptInSharedBillFromGql = ({ id, createdAt, receiptData }: GqlReceiptInSharedBill) => {
    return new ReceiptInSharedBill({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        receiptData: getReceiptDataFromGql(receiptData),
    });
};
