import { FC, useState } from 'react';
import clsx from 'clsx';

import { useUserCtx, useBusinessCtx } from '@/utils/ctxs';
import { useRouting, useLanguage } from '@/utils/customHooks';
import { ButtonWithMenu } from '@/components/1_mol/ButtonWithMenu';
import BusinessIcon from '@/components/0_atom/BusinessIcon';
import FetchingText from '@/components/0_atom/FetchingText';
import ContactNameWithIcon from '@/components/2_org/ContactNameWithIcon';
import AddBusinessModal from '@/components/2_org/AddBusinessModal';

import SvgPlusIcon from '@/svgs/fa-plus-light';
import SvgCheck from '@/svgs/fa-check-light';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: 'flex-start',
        textAlign: 'left',
        padding: '7px 8px',
        '& .MuiButton-startIcon': {
            width: 30,
            height: 30,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexShrink: 0,
        },
        '& .MuiButton-label': {
            color: '#fff',
        },
    },
    addButton: {
        display: 'flex',
        alignItems: 'center',
        minWidth: 78.27,
        color: 'rgba(255,255,255,1)',
        '& svg': {
            width: 28,
            height: 28,
            padding: 8,
            marginRight: 10,
        },
        '& span': {
            fontWeight: 'normal',
            lineHeight: 1,
            fontSize: theme.typography.body2.fontSize,
        },
        '&:hover': {
            background: 'rgba(255,255,255,.05)',
            borderRadius: '0 4px 4px 0',
        },
        marginLeft: -11,
        paddingLeft: 16 + 11,
    },
    notSelectedButton: {
        marginLeft: -11,
        paddingLeft: 16 + 11,
        '&:hover': {
            background: 'rgba(255,255,255,.05)',
            borderRadius: '0 4px 4px 0',
        },
    },
    selectedButton: {
        background: 'rgba(255,255,255,.1)',
        marginLeft: -11,
        paddingLeft: 16 + 11,
        borderRadius: '0 4px 4px 0',
        '&:hover': {
            background: 'rgba(255,255,255,.1)',
        },
    },
    menuPaper: {
        background: '#555',
        boxShadow: '0 4px 64px 0 rgba(0,0,0,.3)',
        padding: '0 11px',
        // paddingRight: 11,
    },
    business: {
        color: 'rgba(255,255,255,1)',
        '& .MuiTypography-root': {
            // fontSize: 10,
            fontWeight: 'normal',
        },
    },
    selectedBusiness: {
        color: '#fff',
        // '& > div:first-child > span:first-child': {
        //     border: 'solid 2px #fff',
        // },
        '& .MuiTypography-root': {
            // fontSize: 10,
            fontWeight: 600,
        },
    },
    subheader: {
        color: 'rgba(255,255,255,.65)',
    },
    check: {
        width: 10,
        height: 10,
        color: '#fff',
        position: 'absolute',
        marginLeft: -16,
    },
}));

const BusinessButton: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { memberWithBusinessProfiles } = useUserCtx();
    const { currentBusiness } = useBusinessCtx();
    const { switchBusinessId } = useRouting();
    const { txt } = useLanguage();
    const [isNewBusinessModalOpen, setIsNewBusinessModalOpen] = useState(false);

    // DATA
    const currentMemberWithBusinessProfile = memberWithBusinessProfiles?.find(
        ({ member }) => member.business.id === currentBusiness?.id
    );

    return (
        <>
            <ButtonWithMenu
                menuEmptyPlaceholder={'unexpected'}
                className={c.root}
                menuPaperClassName={c.menuPaper}
                startIcon={
                    currentBusiness && (
                        <BusinessIcon
                            name={currentBusiness?.name}
                            iconSrc={currentMemberWithBusinessProfile?.businessProfile?.iconSrc}
                            themeColor={currentMemberWithBusinessProfile?.businessProfile?.themeColor}
                        />
                    )
                }
                fullWidth
                items={(() => {
                    const businesses = (() => {
                        if (memberWithBusinessProfiles) {
                            return memberWithBusinessProfiles.map(({ member, businessProfile }) => ({
                                children: (
                                    <>
                                        {currentBusiness?.id === member.business.id && <SvgCheck className={c.check} />}
                                        <ContactNameWithIcon
                                            className={clsx(c.business, {
                                                [c.selectedBusiness]: currentBusiness?.id === member.business.id,
                                            })}
                                            contact={{ name: member.business.name }}
                                            profile={businessProfile}
                                        />
                                    </>
                                ),
                                onClick: () => switchBusinessId({ businessId: member.business.id }),
                                isSelected: currentBusiness?.id === member.business.id,
                                isDisabled: member.business.isArchived,
                                className:
                                    currentBusiness?.id === member.business.id ? c.selectedButton : c.notSelectedButton,
                            }));
                        }
                        return [
                            {
                                children: <FetchingText />,
                                isDisabled: true,
                            },
                        ];
                    })();
                    return [
                        {
                            children: txt({ en: 'Switch Business', ja: 'ビジネスを切り替え' }),
                            isSubheader: true,
                            className: c.subheader,
                        },
                        ...businesses,
                        {
                            children: (
                                <>
                                    <SvgPlusIcon />
                                    <span>{txt({ en: 'Add', ja: '追加' })}</span>
                                </>
                            ),
                            onClick: () => setIsNewBusinessModalOpen(true),
                            className: c.addButton,
                        },
                    ];
                })()}
            >
                {currentBusiness ? currentBusiness.name : 'ビジネスを選択してください'}
            </ButtonWithMenu>
            <AddBusinessModal isOpen={isNewBusinessModalOpen} onClose={() => setIsNewBusinessModalOpen(false)} />
        </>
    );
};
BusinessButton.displayName = 'BusinessButton';
export default BusinessButton;
