import {
    BusinessMemberRole,
    MutationValidationResult,
} from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { GraphQLSdk } from '@/utils/UodkaClients/Core/graphql/graphqlSdk';
import {
    MemberInBusiness,
    MemberInBusinessInterface,
} from '@/utils/UodkaClients/Core/models/Member/data/MemberInBusiness';

interface MemberAPIForOwnerInterface {
    modifyRole: (newRole: BusinessMemberRole) => Promise<MutationValidationResult>;
    remove: () => Promise<MutationValidationResult>;
}

interface ConstructorInput extends MemberInBusinessInterface {
    graphqlSdk: GraphQLSdk;
    parentBusinessId: string;
}

export class MemberAPIForOwner extends MemberInBusiness implements MemberAPIForOwnerInterface {
    protected _graphqlSdk: GraphQLSdk;

    protected _parentBusinessId: string;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
        this._parentBusinessId = input.parentBusinessId;
    }

    async modifyRole(newRole: BusinessMemberRole) {
        const { modifyBusinessMemberRole } = await this._graphqlSdk.modifyBusinessMemberRole({
            input: {
                businessId: this._parentBusinessId,
                id: this._id,
                oldValue: this._role,
                newValue: newRole,
                requiredInterval: 5,
            },
        });
        this._role = modifyBusinessMemberRole.businessMember.role;
        return modifyBusinessMemberRole.result;
    }

    async remove() {
        const { deleteBusinessMember } = await this._graphqlSdk.deleteBusinessMember({
            input: {
                businessId: this._parentBusinessId,
                id: this._id,
            },
        });
        return deleteBusinessMember.result;
    }
}
