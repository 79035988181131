import { FC } from 'react';

import { ClientDataAPIForOwner } from '@/utils/UodkaClients/Core';
import { useLanguage, Language } from '@/utils/customHooks';
import { ButtonWithTextMenu } from '@/components/1_mol/ButtonWithMenu';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({}));

export const ModifyLanguageButton: FC<{
    clientDataAPI: ClientDataAPIForOwner;
    setClientData: (clientData: ClientDataAPIForOwner) => void;
}> = ({ clientDataAPI, setClientData }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    // MUTATION
    const run = async (newLanguage: Language) => {
        const result = await clientDataAPI.modifyLanguage(newLanguage);
        setClientData(clientDataAPI);
        return result;
    };

    return (
        <ButtonWithTextMenu
            variant={'contained'}
            menuEmptyPlaceholder={'unexpected'}
            items={[
                {
                    text: 'English',
                    isSelected: !clientDataAPI.language || clientDataAPI.language === 'en',
                    mutation: {
                        run: () => run('en'),
                        description: txt({
                            en: `Change ${clientDataAPI.name}'s language to English`,
                            ja: `${clientDataAPI.name}の言語を英語に変更`,
                        }),
                    },
                },
                {
                    text: '日本語',
                    isSelected: clientDataAPI.language === 'ja',
                    mutation: {
                        run: () => run('ja'),
                        description: txt({
                            en: `Change ${clientDataAPI.name}'s language to Japanese`,
                            ja: `${clientDataAPI.name}の言語を日本語に変更`,
                        }),
                    },
                },
            ]}
        >
            {txt({
                en: 'Change',
                ja: '変更',
            })}
        </ButtonWithTextMenu>
    );
};
ModifyLanguageButton.displayName = 'views/config/business/account/Basic/ModifyLanguageButton';
