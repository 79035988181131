import { FC, useState } from 'react';
import clsx from 'clsx';

import { Contact, ContactAPIForOwner } from '@/utils/UodkaClients/Core';
import { IssuedBill, IssuedBillAPIForGeneral } from '@/utils/UodkaClients/Invoices';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { getEnvVariables } from '@/utils/envVariables';
import { downloadTextFile } from '@/utils/utilFunctions';
import Button from '@/components/0_atom/Button';
import CopyableText from '@/components/0_atom/CopyableText';
import { ButtonWithTextMenu } from '@/components/1_mol/ButtonWithMenu';

import SvgCopy from '@/svgs/fa-copy-light';
import SvgTrash from '@/svgs/trash';
import SvgCode from '@/svgs/code';
import SvgSpreadsheet from '@/svgs/spreadsheet';
import SvgPdf from '@/svgs/pdf';
import SvgDownload from '@/svgs/download';

import { RevokeModal } from '@/views/bills/BillDrawer/RevokeModal';
import { IssuedBillShareModal } from '@/views/bills/BillDrawer/IssuedBill/IssuedBillShareModal';
import { parepareIssuedBillJson } from '@/views/bills/parepareIssuedBillJson';
import { parepareBillCsv } from '@/views/bills/parepareBillCsv';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 27,
    },
    label: {
        color: theme.palette.text.disabled,
        fontWeight: 600,
        fontSize: theme.typography.caption.fontSize,
        marginBottom: 10,
    },
    content: {},
    copyButton: {
        minWidth: 0,
        padding: 10,
    },
    removeButton: {
        minWidth: 0,
        padding: 10,
        color: theme.palette.error.main,
    },
    downloadButton: {
        minWidth: 0,
        padding: 10,
    },
    svg: {
        width: 15,
        height: 15,
    },
    copyUrl: {
        background: 'none',
        padding: '10px !important',
        '& svg': {
            width: '15px !important',
            height: '15px !important',
        },
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.04)',
        },
    },
}));

export const IssuedBillMenu: FC<{
    className?: string;
    issuedBill: IssuedBill | IssuedBillAPIForGeneral;
    contact: Contact | ContactAPIForOwner;
    onRevoked: () => void;
}> = ({ className, issuedBill, contact, onRevoked }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { currentBusiness } = useBusinessCtxAbsolutely();
    const { txt } = useLanguage();
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

    return (
        <>
            <div className={clsx(className, c.root)}>
                <MuiTypography className={c.label}>{txt({ en: 'Menu', ja: 'メニュー' })}</MuiTypography>
                <div className={c.content}>
                    {contact.relatedBusiness && (
                        <CopyableText
                            copyText={`${getEnvVariables().billsRootUrl}/${currentBusiness.id}/${contact.id}/${
                                issuedBill.id
                            }/${contact.relatedBusiness.id}/${contact.relatedBusiness.relatedContactId}`}
                            isIconButton
                            className={c.copyUrl}
                            tipHoverText={txt({ en: 'Copy URL for sharing', ja: '共有URLをコピー' })}
                        />
                    )}
                    {issuedBill instanceof IssuedBillAPIForGeneral && (
                        <>
                            {!contact.relatedBusiness && contact instanceof ContactAPIForOwner && (
                                <Button className={c.copyButton} onClick={() => setIsShareModalOpen(true)}>
                                    <SvgCopy className={c.svg} />
                                </Button>
                            )}
                            <Button className={c.removeButton} onClick={() => setIsRemoveModalOpen(true)}>
                                <SvgTrash className={c.svg} />
                            </Button>
                        </>
                    )}
                    <ButtonWithTextMenu
                        className={c.downloadButton}
                        items={[
                            {
                                icon: <SvgCode />,
                                text: 'JSON',
                                onClick: () => {
                                    const data = parepareIssuedBillJson({ issuedBill });
                                    downloadTextFile({
                                        fileName: 'uodka_issued_bill.json',
                                        text: data,
                                        type: 'text/json',
                                    });
                                },
                            },
                            {
                                icon: <SvgSpreadsheet />,
                                text: 'CSV',
                                onClick: () => {
                                    const data = parepareBillCsv({ billData: issuedBill.billData, txt });
                                    downloadTextFile({
                                        fileName: 'uodka_issued_bill.csv',
                                        text: data,
                                        type: 'text/csv',
                                    });
                                },
                            },
                            {
                                icon: <SvgPdf className={c.svg} />,
                                text: 'PDF',
                                onClick: () => {
                                    // alert('later!');
                                },
                            },
                        ]}
                        menuEmptyPlaceholder={'unexpected'}
                    >
                        <SvgDownload className={c.svg} />
                    </ButtonWithTextMenu>
                </div>
            </div>
            {issuedBill instanceof IssuedBillAPIForGeneral && (
                <>
                    {!contact.relatedBusiness && contact instanceof ContactAPIForOwner && (
                        <IssuedBillShareModal
                            issuedBillAPI={issuedBill}
                            contactAPI={contact}
                            isOpen={isShareModalOpen}
                            setIsOpen={setIsShareModalOpen}
                        />
                    )}
                    <RevokeModal
                        isOpen={isRemoveModalOpen}
                        onClose={() => setIsRemoveModalOpen(false)}
                        billAPI={issuedBill}
                        onRevoked={onRevoked}
                    />
                </>
            )}
        </>
    );
};
IssuedBillMenu.displayName = 'IssuedBillMenu';
