import { useEffect, useCallback } from 'react';

import {
    UodkaCoreClientForObserver,
    UodkaCoreClientForGeneral,
    UodkaCoreClientForOwner,
    Contact,
    ContactAPIForOwner,
} from '@/utils/UodkaClients/Core';
import { initUodkaProfileClientForAnonymous, BusinessProfile } from '@/utils/UodkaClients/Profile';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLazyItems, UseItems, ItemType } from '@/utils/customHooks/useItems';
import { getBusinessProfile } from '@/utils/customHooks/useClientProfile';

export interface ContactItem<T extends Contact = Contact | ContactAPIForOwner> {
    id: string;
    contact: T;
    profile?: {
        data?: BusinessProfile;
        refetch: () => Promise<BusinessProfile | undefined>;
    };
}

// const getIsTs = <T extends Contact[]>(t: (Contact | ContactAPIForOwner)[]): t is T => {
//     return true;
// };
const getIsTs = <T extends Contact | ContactAPIForOwner>(t: ContactItem[]): t is ItemType<ContactItem<T>>[] => {
    return true;
};

export const getContactItems = async ({
    coreClient,
}: {
    coreClient: UodkaCoreClientForObserver | UodkaCoreClientForGeneral | UodkaCoreClientForOwner;
}): Promise<ContactItem[]> => {
    const contacts = await (() => {
        if (coreClient instanceof UodkaCoreClientForOwner) {
            return coreClient.getContactAPIs();
        }
        return coreClient.getContacts();
    })();
    console.log('coreClient->', coreClient, contacts);
    const sorted = contacts.sort((a, b) => (a.name > b.name ? 1 : -1));
    return Promise.all(
        sorted.map(async (contact) => {
            const fetchProfile = async (businessId?: string) => {
                return businessId ? getBusinessProfile(businessId) : undefined;
            };
            const profile = await fetchProfile(contact.relatedBusiness?.id);
            return {
                id: contact.id,
                contact,
                profile: profile
                    ? {
                          data: profile,
                          refetch: () => fetchProfile(contact.relatedBusiness?.id),
                      }
                    : undefined,
            };
        })
    );
};

export const useContactItems = <T extends Contact = Contact | ContactAPIForOwner>() => {
    // HOOKS
    const {
        clients: { core },
    } = useBusinessCtxAbsolutely();
    const { items, setLazyItems } = useLazyItems<ContactItem<T>>();
    const fetchContacts = useCallback(async () => {
        // const lazyItems = await (async () => {
        //     const contacts = await (() => {
        //         if (core instanceof UodkaCoreClientForOwner) {
        //             return core.getContactAPIs();
        //         }
        //         return core.getContacts();
        //     })();
        //     if (!getIsTs<T[]>(contacts)) {
        //         throw new Error('useContactItems: !getIsTs<T[]>(contacts)');
        //     }
        //     const sorted = contacts.sort((a, b) => (a.name > b.name ? 1 : -1));
        //     return Promise.all(
        //         sorted.map(async (contact) => {
        //             const fetchProfile = async (businessId?: string) => {
        //                 return businessId ? getBusinessProfile(businessId) : undefined;
        //             };
        //             const profile = await fetchProfile(contact.relatedBusiness?.id);
        //             return {
        //                 id: contact.id,
        //                 contact,
        //                 profile: profile
        //                     ? {
        //                           data: profile,
        //                           refetch: () => fetchProfile(contact.relatedBusiness?.id),
        //                       }
        //                     : undefined,
        //             };
        //         })
        //     );
        // })();
        const contactItems = await getContactItems({ coreClient: core });
        if (!getIsTs<T>(contactItems)) {
            throw new Error('useContactItems: !getIsTs<T>(contactItems)');
        }
        setLazyItems(contactItems);
    }, [core, setLazyItems]);

    // USEEFFECT
    useEffect(() => {
        fetchContacts();
    }, [fetchContacts]);

    return {
        items,
        refetchContacts: fetchContacts,
    };
};
