import { FC } from 'react';

import { UodkaCoreClientForOwner } from '@/utils/UodkaClients/Core';
import { useBusinessCtx } from '@/utils/ctxs';
import { useContactItems, useClientProfile, useClientData, useLanguage } from '@/utils/customHooks';
import FetchingText from '@/components/0_atom/FetchingText';
import Button from '@/components/0_atom/Button';
import BusinessIcon from '@/components/0_atom/BusinessIcon';

import { AddContactButton } from '@/views/contacts/AddContactButton';
import { ContactDetails } from '@/views/contacts/ContactDetails';
import { NoContactDisplay } from '@/views/contacts/NoContactDisplay';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    sidebar: {
        width: 204 + 18,
        maxHeight: '100vh',
        overflowY: 'scroll',
        marginTop: -18,
        marginBottom: -18,
        marginRight: 18,
        padding: '18px 18px 18px 0',
        flexShrink: 0,
        '&::-webkit-scrollbar': {
            overflow: 'hidden',
            width: 2,
            background: 'rgba(0,0,0,.02)',
        },
        '&::-webkit-scrollbar-thumb': {
            overflow: 'hidden',
            background: 'rgba(0,0,0,.08)',
            borderRadius: 1,
        },
    },
    contactButton: {
        display: 'flex',
        padding: '7px 9px',
        justifyContent: 'flex-start',
        fontWeight: 600,
        '& .MuiButton-startIcon': {
            width: 28,
            height: 28,
            margin: '0 11px 0 0 ',
        },
        '&:hover': {
            background: '#fff',
        },
    },
    details: {
        // position: 'fixed',
        flexGrow: 1,
        backgroundColor: '#fff',
        borderRadius: 4,
        padding: '49px 45px',
    },
    fetching: {
        padding: '7px 9px',
        fontWeight: 600,
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.disabled,
    },
}));

export const ContactsViewRoot: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const {
        clients: { core },
    } = useBusinessCtx();
    const { items, refetchContacts } = useContactItems();
    const { clientProfile } = useClientProfile();
    const { clientData } = useClientData();

    return (
        <div className={c.root}>
            <div className={c.sidebar}>
                {items ? (
                    items.ids.length > 0 ? (
                        items.ids.map((contactId, i) => (
                            <Button
                                key={i}
                                className={c.contactButton}
                                onClick={() => items.setSelectedItem(contactId)}
                                fullWidth
                                isSelected={contactId === items.currentItem?.id}
                                startIcon={
                                    <BusinessIcon
                                        name={items.getItemAbsolutely(contactId).contact.name}
                                        iconSrc={items.getItemAbsolutely(contactId).profile?.data?.iconSrc}
                                        themeColor={items.getItemAbsolutely(contactId).profile?.data?.themeColor}
                                    />
                                }
                            >
                                {items.getItemAbsolutely(contactId).contact.name}
                            </Button>
                        ))
                    ) : (
                        <Button className={c.contactButton} fullWidth disabled style={{ paddingLeft: 14 }}>
                            {txt({ en: 'No contact', ja: 'コンタクトがありません' })}
                        </Button>
                    )
                ) : (
                    <FetchingText className={c.fetching} />
                )}
                {core instanceof UodkaCoreClientForOwner && (
                    <AddContactButton coreClient={core} refetchContacts={refetchContacts} />
                )}
            </div>
            {!clientData || clientProfile === 'fetching' ? (
                <FetchingText />
            ) : items?.ids.length === 0 ? (
                <NoContactDisplay />
            ) : items?.currentItem ? (
                <div className={c.details}>
                    <ContactDetails
                        {...items.currentItem}
                        updateItem={items.updateItem}
                        removeContactItem={items.removeItem}
                        clientProfile={clientProfile}
                        clientData={clientData}
                    />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};
ContactsViewRoot.displayName = 'ContactsViewRoot';
