import { MutationValidationResult } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { GraphQLSdk as CoreSdk } from '@/utils/UodkaClients/Core/graphql//graphqlSdk';
import { GraphQLSdk as InvoicesSdk } from '@/utils/UodkaClients/Invoices/graphql//graphqlSdk';
import {
    TransferConfirmation,
    TransferConfirmationInterface,
} from '@/utils/UodkaClients/Core/models/TransferConfirmation';
import {
    PaymentInReceivedBill,
    getPaymentInReceivedBillFromGql,
} from '@/utils/UodkaClients/Invoices/models/PaymentEdge';
import { ReceivedBill } from '@/utils/UodkaClients/Invoices/models/ReceivedBill';
import { IssuedPayment, getIssuedPaymentFromGql } from '@/utils/UodkaClients/Invoices/models/IssuedPayment';

interface TransferConfirmationForBillAPIForGeneralInterface {
    approve: () => Promise<{
        payment: IssuedPayment;
        result: MutationValidationResult;
    }>;
    remove: () => Promise<{
        result: MutationValidationResult;
    }>;
}

interface ConstructorInput extends TransferConfirmationInterface {
    coreSdk: CoreSdk;
    invoicesSdk: InvoicesSdk;
    parentBusinessId: string;
    parentContactId: string;
    receivedBill: ReceivedBill;
    addPayment: (newPayment: PaymentInReceivedBill | undefined) => void;
}

export class TransferConfirmationForBillAPIForGeneral
    extends TransferConfirmation
    implements TransferConfirmationForBillAPIForGeneralInterface {
    protected _coreSdk: CoreSdk;

    protected _invoicesSdk: InvoicesSdk;

    protected _parentBusinessId: string;

    protected _parentContactId: string;

    protected _receivedBill: ReceivedBill;

    protected _addPayment: (newPayment: PaymentInReceivedBill | undefined) => void;

    constructor(input: ConstructorInput) {
        super(input);
        this._coreSdk = input.coreSdk;
        this._invoicesSdk = input.invoicesSdk;
        this._parentBusinessId = input.parentBusinessId;
        this._parentContactId = input.parentContactId;
        this._receivedBill = input.receivedBill;
        this._addPayment = input.addPayment;
    }

    async approve() {
        const { createTransfer } = await this._coreSdk.createTransfer({
            input: {
                businessId: this._parentBusinessId,
                contactId: this._parentContactId,
                transferConfirmationId: this.id,
            },
        });
        const { result, transfer } = createTransfer;
        const { createPaymentForBill } = await this._invoicesSdk.createPaymentForBill({
            input: {
                businessId: this._parentBusinessId,
                contactId: this._parentContactId,
                transferId: transfer.id,
                billId: this._receivedBill.id,
            },
        });
        const { payment } = createPaymentForBill;
        this._addPayment(getPaymentInReceivedBillFromGql(payment));
        return {
            result,
            payment: getIssuedPaymentFromGql(payment),
        };
    }

    async remove() {
        const { deleteTransferConfirmation } = await this._coreSdk.deleteTransferConfirmation({
            input: {
                businessId: this._parentBusinessId,
                contactId: this._parentContactId,
                id: this.id,
            },
        });
        return {
            result: deleteTransferConfirmation.result,
        };
    }
}
