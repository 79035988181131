import { FC, useState } from 'react';

import { ContactAPIForOwner } from '@/utils/UodkaClients/Core';
import { useLanguage, useFormField } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import { MutationFormModal } from '@/components/1_mol/FormModal';
import { TextInput } from '@/components/0_atom/Input';

import SvgTrash from '@/svgs/trash';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({}));

export const ModifyContactNotificationEmailButton: FC<{
    contactAPI: ContactAPIForOwner;
    updateItem: (contactAPI: ContactAPIForOwner) => void;
}> = ({ contactAPI, updateItem }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [isOpen, setIsOpen] = useState(false);
    const [editingNotificationEmail, setEditingNotificationEmail] = useFormField<string | undefined>(
        contactAPI.notificationEmail
    );

    // DATA
    const run = async () => {
        const result = await contactAPI.modifyNotificationEmail(editingNotificationEmail);
        updateItem(contactAPI);
        return result;
    };

    return (
        <>
            <Button onClick={() => setIsOpen(true)} variant={'contained'}>
                {txt({ en: 'Modify', ja: '設定' })}
            </Button>
            <MutationFormModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={txt({
                    en: `Modify ${contactAPI.name}'s notification email`,
                    ja: `${contactAPI.name}の通知メールアドレスを設定`,
                })}
                mutation={{
                    description: txt({
                        en: "Modify contact's notification email",
                        ja: 'コンタクトの通知メールアドレスを設定',
                    }),
                    run,
                }}
                submitText={txt({ en: 'Update', ja: '設定' })}
                items={[
                    {
                        labelText: txt({ en: 'New email to be notified', ja: '新しい通知先メールアドレス' }),
                        isSatisfied: true,
                        isError: undefined,
                        isRequired: false,
                        children: (
                            <TextInput
                                value={editingNotificationEmail}
                                setValue={setEditingNotificationEmail}
                                isSatisfied={!!editingNotificationEmail}
                            />
                        ),
                    },
                ]}
            />
        </>
    );
};
ModifyContactNotificationEmailButton.displayName = 'ModifyContactNotificationEmailButton';
