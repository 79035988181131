import { FC } from 'react';

import { InvoiceDataSummary } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';

import { getTaxName } from '@/views/bills/BillViewer/getTaxName';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const billThemeColor = '#397DA1';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        borderCollapse: 'collapse',
        '& tr': {
            verticalAlign: 'baseline',
        },
        '& th': {
            whiteSpace: 'nowrap',
        },
        '& td': {
            fontSize: theme.typography.body2.fontSize,
            textAlign: 'right',
            whiteSpace: 'nowrap',
            paddingLeft: '1em',
        },
    },
    taxName: {
        // color: theme.palette.text.disabled,
        textAlign: 'left',
        fontWeight: 'normal',
        fontSize: theme.typography.caption.fontSize,
    },
    for: {
        // color: theme.palette.text.disabled,
        fontWeight: 'normal',
        fontSize: theme.typography.caption.fontSize,
        padding: '0 .5em',
    },
    taxable: {
        // color: theme.palette.text.disabled,
        fontWeight: 'normal',
        fontSize: theme.typography.caption.fontSize,
        textAlign: 'right',
    },
    spacer: {
        width: '100%',
    },
    lastTh: {
        textAlign: 'right',
        fontWeight: 600,
        fontSize: theme.typography.caption.fontSize,
        color: billThemeColor,
    },
}));

const getIndexNumber = (taxTitle: string) => {
    if (taxTitle === 'consumptionTax (10%)') {
        return 0;
    }
    if (taxTitle === 'consumptionTax (8%)') {
        return 1;
    }
    if (taxTitle === 'withholdingTax') {
        return 2;
    }
    return 3;
};

export const TableFoot: FC<{
    summary: InvoiceDataSummary;
}> = ({ summary }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    // DATA
    const sortedTaxes = summary.taxes.sort((a, b) => {
        return getIndexNumber(a.title) > getIndexNumber(b.title) ? 1 : -1;
    });

    return (
        <table className={c.root}>
            <tbody>
                <tr>
                    <th className={c.spacer} />
                    <th></th>
                    <th></th>
                    <th></th>
                    <th className={c.lastTh}>{txt({ en: 'Subtotal', ja: '小計・税抜' })}</th>
                    <td>{`¥ ${summary.subtotal.toLocaleString()}`}</td>
                </tr>
                {sortedTaxes.map((tax, i) => (
                    <tr key={i}>
                        <th className={c.spacer} />
                        <th className={c.taxName}>{getTaxName({ taxTitle: tax.title, txt })}</th>
                        <th className={c.for}>{txt({ en: 'for', ja: '対象' })}</th>
                        <th className={c.taxable}>{`¥ ${tax.taxableValue.toLocaleString()}`}</th>
                        <th className={c.lastTh}>{txt({ en: 'Tax', ja: '税額' })}</th>
                        <td>{`¥ ${tax.value.toLocaleString()}`}</td>
                    </tr>
                ))}
                <tr>
                    <th className={c.spacer} />
                    <th></th>
                    <th></th>
                    <th></th>
                    <th className={c.lastTh}>{txt({ en: 'Total', ja: '合計・税込' })}</th>
                    <td>{`¥ ${summary.total.toLocaleString()}`}</td>
                </tr>
            </tbody>
        </table>
    );
};
TableFoot.displayName = 'TableFoot';
