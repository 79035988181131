import { FC, ReactNode } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    body: {},
    row: {
        display: 'flex',
        '&:not(:last-child)': {
            marginBottom: 16,
        },
    },
    labelCell: {
        border: 'none',
        borderRight: 'solid 2px rgba(0,0,0,.05)',
        width: 138,
        fontWeight: 600,
        padding: '0 16px 0 0',
        minHeight: 40,
        fontSize: theme.typography.body2.fontSize,
        // color: theme.palette.text.disabled,
    },
    valueCell: {
        border: 'none',
        padding: '0 0 0 16px',
        flexGrow: 1,
        fontWeight: 600,
        fontSize: theme.typography.body2.fontSize,
    },
}));

interface HorizontalTableItem {
    label: string;
    children: ReactNode;
}

const HorizontalTable: FC<{
    items: HorizontalTableItem[];
}> = ({ items }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={c.root}>
            {items.map(({ label, children }, i) => (
                <div key={i} className={c.row}>
                    <div className={c.labelCell}>{label}</div>
                    <div className={c.valueCell}>{children}</div>
                </div>
            ))}
        </div>
    );
};

HorizontalTable.displayName = 'HorizontalTable';
export default HorizontalTable;
