import { useEffect, useState, useCallback } from 'react';

import { IssuedBillsFilter, ReceivedBillsFilter } from '@/utils/UodkaClients/Invoices';
import { useBusinessCtxAbsolutely, useErrorCtx } from '@/utils/ctxs';
import { useLazyItems, UseItems, ContactItem, getContactItems } from '@/utils/customHooks';

import { useBillsRouting } from '@/views/bills/useBillsRouting';
import { IssuedBillItem, fetchIssuedBill, fetchIssuedBills } from '@/views/bills/useBillItems/fetchIssuedBill';
import { ReceivedBillItem, fetchReceivedBill, fetchReceivedBills } from '@/views/bills/useBillItems/fetchReceivedBill';

type UseBillItems = () => {
    contactItems: ContactItem[] | undefined;
    currentContactItem: ContactItem | undefined;
    setCurrentContactItem: (currentContactItem: ContactItem | undefined) => void;
    specifiedIssuedBillItem: IssuedBillItem | undefined;
    specifiedReceivedBillItem: ReceivedBillItem | undefined;
    clearSpecifiedBillItem: () => void;
    issuedBillItems: ReturnType<UseItems<IssuedBillItem>> | undefined;
    receivedBillItems: ReturnType<UseItems<ReceivedBillItem>> | undefined;
    issuedBillsFilter: IssuedBillsFilter;
    receivedBillsFilter: ReceivedBillsFilter;
    setIssuedBillsFilter: (issuedBillsFilter: IssuedBillsFilter) => void;
    setReceivedBillsFilter: (receivedBillsFilter: ReceivedBillsFilter) => void;
    refetchIssuedBills: () => Promise<void>;
    isFetching: boolean;
};

// url変化時に、itemsがあるかどうかでfetchかfindする

export const useBillItems: UseBillItems = () => {
    // HOOKS
    const { setIs404 } = useErrorCtx();
    const {
        clients: { core: coreClient, invoices: invoicesClient },
    } = useBusinessCtxAbsolutely();
    const { slug1, slug2, slug3, setSlug2 } = useBillsRouting();
    const { items: issuedBillItems, setLazyItems: setIssuedBillItems } = useLazyItems<IssuedBillItem>();
    const { items: receivedBillItems, setLazyItems: setReceivedBillItems } = useLazyItems<ReceivedBillItem>();
    const [specifiedIssuedBillItem, setSpecifiedIssuedBillItem] = useState<IssuedBillItem | undefined>(undefined);
    const [specifiedReceivedBillItem, setSpecifiedReceivedBillItem] = useState<ReceivedBillItem | undefined>(undefined);
    const [contactItems, setContactItems] = useState<ContactItem[] | undefined>(undefined);
    const [issuedBillsFilter, setIssuedBillsFilter] = useState<IssuedBillsFilter>({
        signatures: {
            issuerRevoke: false,
        },
    });
    const [receivedBillsFilter, setReceivedBillsFilter] = useState<ReceivedBillsFilter>({
        signatures: {
            recipientRevoke: false,
        },
    });
    const [currentContactItem, setCurrentContactItem] = useState<ContactItem | undefined>(undefined);
    const [isFetching, setIsFetching] = useState(true);

    // CALLBACK
    const fetchBills = useCallback(async () => {
        setIsFetching(true);
        console.log('fetch start');
        if (slug2 && slug3) {
            console.log('slug1->', slug1);
            if (slug1 === 'issued') {
                const targetBillItem = await (async () => {
                    if (issuedBillItems) {
                        return issuedBillItems.getItem(slug3);
                    }
                    return fetchIssuedBill({
                        coreClient,
                        invoicesClient,
                        contactId: slug2,
                        billId: slug3,
                    });
                })();
                if (!targetBillItem) {
                    setIs404(true);
                } else {
                    setSpecifiedIssuedBillItem(targetBillItem);
                }
            } else {
                const targetBillItem = await (async () => {
                    if (receivedBillItems) {
                        return receivedBillItems.getItem(slug3);
                    }
                    return fetchReceivedBill({
                        coreClient,
                        invoicesClient,
                        contactId: slug2,
                        billId: slug3,
                    });
                })();
                if (!targetBillItem) {
                    setIs404(true);
                } else {
                    setSpecifiedReceivedBillItem(targetBillItem);
                }
            }
        } else {
            if (specifiedIssuedBillItem) {
                setSpecifiedIssuedBillItem(undefined);
            }
            if (specifiedReceivedBillItem) {
                setSpecifiedReceivedBillItem(undefined);
            }
            const fetchedContactItems = await (async () => {
                if (contactItems) {
                    return contactItems;
                }
                const newItems = await getContactItems({ coreClient });
                setContactItems(newItems);
                return getContactItems({ coreClient });
            })();
            if (slug1 === 'issued' && !issuedBillItems) {
                const fetchedIssuedBillItems = await fetchIssuedBills({
                    invoicesClient,
                    filter: issuedBillsFilter,
                    contactItems: fetchedContactItems,
                });
                setIssuedBillItems(fetchedIssuedBillItems);
            } else if (!receivedBillItems) {
                const fetchedReceivedBillItems = await fetchReceivedBills({
                    invoicesClient,
                    filter: receivedBillsFilter,
                    contactItems: fetchedContactItems,
                });
                setReceivedBillItems(fetchedReceivedBillItems);
            }
        }
        setIsFetching(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coreClient, invoicesClient, slug1, slug2, slug3, issuedBillsFilter, receivedBillsFilter]);

    // USEEFFECT
    useEffect(() => {
        fetchBills();
    }, [fetchBills]);

    // HANDLER
    const clearSpecifiedBillItem = () => {
        setSlug2({ slug1: slug1 || 'received', slug2: undefined });
    };
    const refetchIssuedBills = async () => {
        setIsFetching(true);
        const fetchedContactItems = await (async () => {
            if (contactItems) {
                return contactItems;
            }
            const newItems = await getContactItems({ coreClient });
            setContactItems(newItems);
            return getContactItems({ coreClient });
        })();
        const fetchedIssuedBillItems = await fetchIssuedBills({
            invoicesClient,
            filter: issuedBillsFilter,
            contactItems: fetchedContactItems,
        });
        setIssuedBillItems(fetchedIssuedBillItems);
        setIsFetching(false);
    };
    console.log('{isFetching,specifiedIssuedBillItem,specifiedReceivedBillItem}->', {
        isFetching,
        specifiedIssuedBillItem,
        specifiedReceivedBillItem,
    });
    return {
        contactItems,
        currentContactItem,
        setCurrentContactItem,
        specifiedIssuedBillItem,
        specifiedReceivedBillItem,
        clearSpecifiedBillItem,
        issuedBillItems,
        receivedBillItems,
        issuedBillsFilter,
        setIssuedBillsFilter,
        receivedBillsFilter,
        setReceivedBillsFilter,
        refetchIssuedBills,
        isFetching,
    };
};
