import { FC, useState } from 'react';

import {
    MutationValidationResult,
    Contact,
    InviteForContactAPIForOwner,
    ContactAPIForOwner,
} from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';

import { useLanguage } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import InformationModal from '@/components/1_mol/InformationModal';

import { Issued } from '@/views/contacts/InviteButton/Issued';
import { NotIssued } from '@/views/contacts/InviteButton/NotIssued';

import SvgLink from '@/svgs/link';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    button: {
        // color: theme.palette.error.main,
        // color: '#fff',
        // backgroundColor: theme.palette.error.main,
    },
}));

export interface ContactAPIForOwnerWithInviteAPI extends ContactAPIForOwner {
    inviteAPI: InviteForContactAPIForOwner;
}

export interface ContactAPIForOwnerWithoutInviteAPI extends ContactAPIForOwner {
    inviteAPI: undefined;
    issueInvite: (
        durationDays?: number
    ) => Promise<{
        inviteAPI: InviteForContactAPIForOwner;
        result: MutationValidationResult;
    }>;
}

export const getIsInvited = (contact: ContactAPIForOwner): contact is ContactAPIForOwnerWithInviteAPI => {
    return !!(!contact.relatedBusiness && contact instanceof ContactAPIForOwner && contact.inviteAPI);
};
export const getIsNotInvited = (contact: ContactAPIForOwner): contact is ContactAPIForOwnerWithoutInviteAPI => {
    return !!(
        !contact.relatedBusiness &&
        contact instanceof ContactAPIForOwner &&
        !contact.inviteAPI &&
        contact.issueInvite
    );
};
export const getIsLinked = (contact: Contact | ContactAPIForOwner) => {
    if (contact instanceof ContactAPIForOwner) {
        return !getIsInvited(contact) && !getIsNotInvited(contact);
    }
    return contact.relatedBusiness;
};

export const InviteButton: FC<{
    updateItem: (contactAPI: ContactAPIForOwner) => void;
    contactAPI: ContactAPIForOwnerWithInviteAPI | ContactAPIForOwnerWithoutInviteAPI;
}> = ({ updateItem, contactAPI }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Button onClick={() => setIsOpen(true)} startIcon={<SvgLink />} className={c.button} variant={'contained'}>
                {getIsInvited(contactAPI)
                    ? txt({ en: 'Issued URL to link', ja: 'リンクURLを発行済み' })
                    : txt({ en: 'Link to other user', ja: '他のユーザーとリンク' })}
            </Button>
            <InformationModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={
                    getIsInvited(contactAPI)
                        ? txt({
                              en: 'URL for linking is available',
                              ja: 'リンクURLを発行済み',
                          })
                        : txt({
                              en: `Link ${contactAPI.name} to other user`,
                              ja: 'コンタクトを他のユーザーとリンクする',
                          })
                }
            >
                {getIsInvited(contactAPI) ? (
                    <Issued contactAPI={contactAPI} updateItem={updateItem} handleCloseModal={() => setIsOpen(false)} />
                ) : (
                    <NotIssued contactAPI={contactAPI} updateItem={updateItem} />
                )}
            </InformationModal>
        </>
    );
};
InviteButton.displayName = 'InviteButton';
