import { FC } from 'react';

import { useFilteredAccountingItems } from '@/views/records/useFilteredAccountingItems';
import { AccountingTemplate } from '@/views/records/AccountingTemplate';
import { AccountingTable } from '@/views/records/AccountingTable';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({}));

export const AccountingViewRoot: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { accountingItems, balanceWithInvoices, ...filterProps } = useFilteredAccountingItems();

    return (
        <AccountingTemplate items={accountingItems} balanceWithInvoices={balanceWithInvoices} {...filterProps}>
            {accountingItems ? <AccountingTable items={accountingItems} {...filterProps} /> : undefined}
        </AccountingTemplate>
    );
};
AccountingViewRoot.displayName = 'AccountingViewRoot';
