import { BusinessSnapshotItem as GqlBusinessSnapshotItem } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';

interface BusinessSnapshotItemInterface {
    title: string;
    value: string;
}

export class BusinessSnapshotItem implements BusinessSnapshotItemInterface {
    protected _title: string;

    protected _value: string;

    get title() {
        return this._title;
    }

    get value() {
        return this._value;
    }

    get data() {
        return {
            title: this._title,
            value: this._value,
        };
    }

    constructor({ title, value }: BusinessSnapshotItemInterface) {
        this._title = title;
        this._value = value;
    }
}

export const getBusinessSnapshotItemFromGql = ({ title, value }: GqlBusinessSnapshotItem) => {
    return new BusinessSnapshotItem({ title, value });
};
