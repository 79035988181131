import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
};


export type Query = {
  serviceHealth: ServiceHealth;
  businessProfile?: Maybe<BusinessProfile>;
  getSignedUrlToUploadBusinessLogo: Scalars['String'];
  getSignedUrlToUploadBusinessIcon: Scalars['String'];
};


export type QueryBusinessProfileArgs = {
  id: Scalars['String'];
};


export type QueryGetSignedUrlToUploadBusinessLogoArgs = {
  input: GetSignedUrlToUploadBusinessLogoInput;
};


export type QueryGetSignedUrlToUploadBusinessIconArgs = {
  input: GetSignedUrlToUploadBusinessIconInput;
};

export type Mutation = {
  modifyBusinessProfileThemeColor: ModifyBusinessProfileThemeColorPayload;
  modifyBusinessProfilePhone: ModifyBusinessProfilePhonePayload;
  modifyBusinessProfileEmail: ModifyBusinessProfileEmailPayload;
  modifyBusinessProfileWebsiteUrl: ModifyBusinessProfileWebsiteUrlPayload;
  modifyBusinessProfileAddress: ModifyBusinessProfileAddressPayload;
};


export type MutationModifyBusinessProfileThemeColorArgs = {
  input: ModifyBusinessProfileThemeColorInput;
};


export type MutationModifyBusinessProfilePhoneArgs = {
  input: ModifyBusinessProfilePhoneInput;
};


export type MutationModifyBusinessProfileEmailArgs = {
  input: ModifyBusinessProfileEmailInput;
};


export type MutationModifyBusinessProfileWebsiteUrlArgs = {
  input: ModifyBusinessProfileWebsiteUrlInput;
};


export type MutationModifyBusinessProfileAddressArgs = {
  input: ModifyBusinessProfileAddressInput;
};

export type BusinessProfile = {
  businessId: Scalars['ID'];
  themeColor?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

export type ModifyBusinessProfileThemeColorInput = {
  businessId: Scalars['ID'];
  oldValue?: Maybe<Scalars['String']>;
  newValue?: Maybe<Scalars['String']>;
  requiredInterval: Scalars['Int'];
};

export type ModifyBusinessProfilePhoneInput = {
  businessId: Scalars['ID'];
  oldValue?: Maybe<Scalars['String']>;
  newValue?: Maybe<Scalars['String']>;
  requiredInterval: Scalars['Int'];
};

export type ModifyBusinessProfileEmailInput = {
  businessId: Scalars['ID'];
  oldValue?: Maybe<Scalars['String']>;
  newValue?: Maybe<Scalars['String']>;
  requiredInterval: Scalars['Int'];
};

export type ModifyBusinessProfileWebsiteUrlInput = {
  businessId: Scalars['ID'];
  oldValue?: Maybe<Scalars['String']>;
  newValue?: Maybe<Scalars['String']>;
  requiredInterval: Scalars['Int'];
};

export type ModifyBusinessProfileAddressInput = {
  businessId: Scalars['ID'];
  oldValue?: Maybe<Scalars['String']>;
  newValue?: Maybe<Scalars['String']>;
  requiredInterval: Scalars['Int'];
};

export type ModifyBusinessProfileThemeColorPayload = {
  result: MutationValidationResult;
  businessProfile: BusinessProfile;
};

export type ModifyBusinessProfilePhonePayload = {
  result: MutationValidationResult;
  businessProfile: BusinessProfile;
};

export type ModifyBusinessProfileEmailPayload = {
  result: MutationValidationResult;
  businessProfile: BusinessProfile;
};

export type ModifyBusinessProfileWebsiteUrlPayload = {
  result: MutationValidationResult;
  businessProfile: BusinessProfile;
};

export type ModifyBusinessProfileAddressPayload = {
  result: MutationValidationResult;
  businessProfile: BusinessProfile;
};

export type ServiceHealth = {
  code: ServiceHealthCode;
  message?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ServiceHealthCode =
  | 'ok'
  | 'outOfService';

export type MutationValidationResult =
  | 'success'
  | 'incorrectOldValue'
  | 'intervalTooShort';

export type ImageExtension =
  | 'jpg'
  | 'png'
  | 'svg'
  | 'webp';

export type GetSignedUrlToUploadBusinessLogoInput = {
  businessId: Scalars['ID'];
  extensionName: ImageExtension;
};

export type GetSignedUrlToUploadBusinessIconInput = {
  businessId: Scalars['ID'];
  extensionName: ImageExtension;
};

export type ModifyBusinessProfileAddressMutationVariables = Exact<{
  input: ModifyBusinessProfileAddressInput;
}>;


export type ModifyBusinessProfileAddressMutation = { modifyBusinessProfileAddress: (
    Pick<ModifyBusinessProfileAddressPayload, 'result'>
    & { businessProfile: Pick<BusinessProfile, 'businessId' | 'themeColor' | 'phone' | 'email' | 'websiteUrl' | 'address'> }
  ) };

export type ModifyBusinessProfileEmailMutationVariables = Exact<{
  input: ModifyBusinessProfileEmailInput;
}>;


export type ModifyBusinessProfileEmailMutation = { modifyBusinessProfileEmail: (
    Pick<ModifyBusinessProfileEmailPayload, 'result'>
    & { businessProfile: Pick<BusinessProfile, 'businessId' | 'themeColor' | 'phone' | 'email' | 'websiteUrl' | 'address'> }
  ) };

export type ModifyBusinessProfilePhoneMutationVariables = Exact<{
  input: ModifyBusinessProfilePhoneInput;
}>;


export type ModifyBusinessProfilePhoneMutation = { modifyBusinessProfilePhone: (
    Pick<ModifyBusinessProfilePhonePayload, 'result'>
    & { businessProfile: Pick<BusinessProfile, 'businessId' | 'themeColor' | 'phone' | 'email' | 'websiteUrl' | 'address'> }
  ) };

export type ModifyBusinessProfileThemeColorMutationVariables = Exact<{
  input: ModifyBusinessProfileThemeColorInput;
}>;


export type ModifyBusinessProfileThemeColorMutation = { modifyBusinessProfileThemeColor: (
    Pick<ModifyBusinessProfileThemeColorPayload, 'result'>
    & { businessProfile: Pick<BusinessProfile, 'businessId' | 'themeColor' | 'phone' | 'email' | 'websiteUrl' | 'address'> }
  ) };

export type ModifyBusinessProfileWebsiteUrlMutationVariables = Exact<{
  input: ModifyBusinessProfileWebsiteUrlInput;
}>;


export type ModifyBusinessProfileWebsiteUrlMutation = { modifyBusinessProfileWebsiteUrl: (
    Pick<ModifyBusinessProfileWebsiteUrlPayload, 'result'>
    & { businessProfile: Pick<BusinessProfile, 'businessId' | 'themeColor' | 'phone' | 'email' | 'websiteUrl' | 'address'> }
  ) };

export type BusinessProfileQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type BusinessProfileQuery = { businessProfile?: Maybe<Pick<BusinessProfile, 'businessId' | 'themeColor' | 'phone' | 'email' | 'websiteUrl' | 'address'>> };

export type GetSignedUrlToUploadBusinessIconQueryVariables = Exact<{
  input: GetSignedUrlToUploadBusinessIconInput;
}>;


export type GetSignedUrlToUploadBusinessIconQuery = Pick<Query, 'getSignedUrlToUploadBusinessIcon'>;

export type GetSignedUrlToUploadBusinessLogoQueryVariables = Exact<{
  input: GetSignedUrlToUploadBusinessLogoInput;
}>;


export type GetSignedUrlToUploadBusinessLogoQuery = Pick<Query, 'getSignedUrlToUploadBusinessLogo'>;

export type ServiceHealthQueryVariables = Exact<{ [key: string]: never; }>;


export type ServiceHealthQuery = { serviceHealth: Pick<ServiceHealth, 'code' | 'message' | 'url'> };


export const ModifyBusinessProfileAddressDocument = gql`
    mutation modifyBusinessProfileAddress($input: ModifyBusinessProfileAddressInput!) {
  modifyBusinessProfileAddress(input: $input) {
    result
    businessProfile {
      businessId
      themeColor
      phone
      email
      websiteUrl
      address
    }
  }
}
    `;
export const ModifyBusinessProfileEmailDocument = gql`
    mutation modifyBusinessProfileEmail($input: ModifyBusinessProfileEmailInput!) {
  modifyBusinessProfileEmail(input: $input) {
    result
    businessProfile {
      businessId
      themeColor
      phone
      email
      websiteUrl
      address
    }
  }
}
    `;
export const ModifyBusinessProfilePhoneDocument = gql`
    mutation modifyBusinessProfilePhone($input: ModifyBusinessProfilePhoneInput!) {
  modifyBusinessProfilePhone(input: $input) {
    result
    businessProfile {
      businessId
      themeColor
      phone
      email
      websiteUrl
      address
    }
  }
}
    `;
export const ModifyBusinessProfileThemeColorDocument = gql`
    mutation modifyBusinessProfileThemeColor($input: ModifyBusinessProfileThemeColorInput!) {
  modifyBusinessProfileThemeColor(input: $input) {
    result
    businessProfile {
      businessId
      themeColor
      phone
      email
      websiteUrl
      address
    }
  }
}
    `;
export const ModifyBusinessProfileWebsiteUrlDocument = gql`
    mutation modifyBusinessProfileWebsiteUrl($input: ModifyBusinessProfileWebsiteUrlInput!) {
  modifyBusinessProfileWebsiteUrl(input: $input) {
    result
    businessProfile {
      businessId
      themeColor
      phone
      email
      websiteUrl
      address
    }
  }
}
    `;
export const BusinessProfileDocument = gql`
    query businessProfile($id: String!) {
  businessProfile(id: $id) {
    businessId
    themeColor
    phone
    email
    websiteUrl
    address
  }
}
    `;
export const GetSignedUrlToUploadBusinessIconDocument = gql`
    query getSignedUrlToUploadBusinessIcon($input: GetSignedUrlToUploadBusinessIconInput!) {
  getSignedUrlToUploadBusinessIcon(input: $input)
}
    `;
export const GetSignedUrlToUploadBusinessLogoDocument = gql`
    query getSignedUrlToUploadBusinessLogo($input: GetSignedUrlToUploadBusinessLogoInput!) {
  getSignedUrlToUploadBusinessLogo(input: $input)
}
    `;
export const ServiceHealthDocument = gql`
    query serviceHealth {
  serviceHealth {
    code
    message
    url
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    modifyBusinessProfileAddress(variables: ModifyBusinessProfileAddressMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModifyBusinessProfileAddressMutation> {
      return withWrapper(() => client.request<ModifyBusinessProfileAddressMutation>(ModifyBusinessProfileAddressDocument, variables, requestHeaders));
    },
    modifyBusinessProfileEmail(variables: ModifyBusinessProfileEmailMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModifyBusinessProfileEmailMutation> {
      return withWrapper(() => client.request<ModifyBusinessProfileEmailMutation>(ModifyBusinessProfileEmailDocument, variables, requestHeaders));
    },
    modifyBusinessProfilePhone(variables: ModifyBusinessProfilePhoneMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModifyBusinessProfilePhoneMutation> {
      return withWrapper(() => client.request<ModifyBusinessProfilePhoneMutation>(ModifyBusinessProfilePhoneDocument, variables, requestHeaders));
    },
    modifyBusinessProfileThemeColor(variables: ModifyBusinessProfileThemeColorMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModifyBusinessProfileThemeColorMutation> {
      return withWrapper(() => client.request<ModifyBusinessProfileThemeColorMutation>(ModifyBusinessProfileThemeColorDocument, variables, requestHeaders));
    },
    modifyBusinessProfileWebsiteUrl(variables: ModifyBusinessProfileWebsiteUrlMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModifyBusinessProfileWebsiteUrlMutation> {
      return withWrapper(() => client.request<ModifyBusinessProfileWebsiteUrlMutation>(ModifyBusinessProfileWebsiteUrlDocument, variables, requestHeaders));
    },
    businessProfile(variables: BusinessProfileQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BusinessProfileQuery> {
      return withWrapper(() => client.request<BusinessProfileQuery>(BusinessProfileDocument, variables, requestHeaders));
    },
    getSignedUrlToUploadBusinessIcon(variables: GetSignedUrlToUploadBusinessIconQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetSignedUrlToUploadBusinessIconQuery> {
      return withWrapper(() => client.request<GetSignedUrlToUploadBusinessIconQuery>(GetSignedUrlToUploadBusinessIconDocument, variables, requestHeaders));
    },
    getSignedUrlToUploadBusinessLogo(variables: GetSignedUrlToUploadBusinessLogoQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetSignedUrlToUploadBusinessLogoQuery> {
      return withWrapper(() => client.request<GetSignedUrlToUploadBusinessLogoQuery>(GetSignedUrlToUploadBusinessLogoDocument, variables, requestHeaders));
    },
    serviceHealth(variables?: ServiceHealthQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ServiceHealthQuery> {
      return withWrapper(() => client.request<ServiceHealthQuery>(ServiceHealthDocument, variables, requestHeaders));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;