import { UodkaInvoicesClientForAnonymous } from '@/utils/UodkaClients/Invoices/clients/Anonymous';
import { GraphQLSdk as CoreSdk } from '@/utils/UodkaClients/Core/graphql/graphqlSdk';
import { GraphQLSdk as CertSdk } from '@/utils/UodkaClients/Cert/graphql/graphqlSdk';
import { GraphQLSdk as InvoicesSdk } from '@/utils/UodkaClients/Invoices/graphql/graphqlSdk';
import { GqlDateTimeData } from '@/utils/UodkaClients/Invoices/graphql/customScalars';
import {
    BalanceWithInvoice,
    IssuedBillAPIForGeneral,
    ReceivedBillAPIForGeneral,
    BillDataInterface,
    BillData,
    getGqlBillData,
    getIssuedBillFromGql,
    getReceivedBillFromGql,
    getBalanceWithInvoiceFromGql,
} from '@/utils/UodkaClients/Invoices/models';
import { BalancesFilter } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import { IssuedBillsFilter, ReceivedBillsFilter } from '@/utils/UodkaClients/Invoices/clients/Business/ForObserver';
import { createIssueingSignature } from '@/utils/UodkaClients/Invoices/utils/createSignature';

interface UodkaInvoicesClientForGeneralInterface {
    getBalanceWithInvoices: (input: { filter?: BalancesFilter }) => Promise<BalanceWithInvoice[]>;
    getIssuedBillAPI: (input: { contactId: string; billId: string }) => Promise<IssuedBillAPIForGeneral>;
    getIssuedBillAPIs: (input: { filter?: IssuedBillsFilter }) => Promise<IssuedBillAPIForGeneral[]>;
    getReceivedBillAPI: (input: { contactId: string; billId: string }) => Promise<ReceivedBillAPIForGeneral>;
    getReceivedBillAPIs: (input: { filter?: ReceivedBillsFilter }) => Promise<ReceivedBillAPIForGeneral[]>;
    createBill: (input: { contactId: string; billData: BillDataInterface }) => Promise<IssuedBillAPIForGeneral>;
}

interface ConstructorInput {
    coreSdk: CoreSdk;
    certSdk: CertSdk;
    invoicesSdk: InvoicesSdk;
    clientBusinessId: string;
}

export class UodkaInvoicesClientForGeneral
    extends UodkaInvoicesClientForAnonymous
    implements UodkaInvoicesClientForGeneralInterface {
    protected _coreSdk: CoreSdk;

    protected _certSdk: CertSdk;

    protected _clientBusinessId: string;

    constructor(input: ConstructorInput) {
        super(input);
        this._coreSdk = input.coreSdk;
        this._certSdk = input.certSdk;
        this._clientBusinessId = input.clientBusinessId;
    }

    async getBalanceWithInvoices({ filter }: { filter?: BalancesFilter }) {
        const {
            getBalanceWithInvoices: { balances },
        } = await this._invoicesSdk.getBalanceWithInvoices({
            input: {
                businessId: this._clientBusinessId,
                filter,
            },
        });
        return balances.map((balance) => getBalanceWithInvoiceFromGql(balance));
    }

    async getIssuedBillAPI({ contactId, billId }: { contactId: string; billId: string }) {
        const {
            getIssuedBill: { bill },
        } = await this._invoicesSdk.getIssuedBill({
            input: {
                businessId: this._clientBusinessId,
                contactId,
                billId,
            },
        });
        return new IssuedBillAPIForGeneral({
            graphqlSdk: this._invoicesSdk,
            certSdk: this._certSdk,
            clientBusinessId: this._clientBusinessId,
            ...getIssuedBillFromGql(bill).data,
        });
    }

    async getIssuedBillAPIs({ filter }: { filter?: IssuedBillsFilter }) {
        const {
            getIssuedBills: { bills },
        } = await this._invoicesSdk.getIssuedBills({
            input: {
                businessId: this._clientBusinessId,
                filter,
            },
        });
        return bills.map(
            (bill) =>
                new IssuedBillAPIForGeneral({
                    graphqlSdk: this._invoicesSdk,
                    certSdk: this._certSdk,
                    clientBusinessId: this._clientBusinessId,
                    ...getIssuedBillFromGql(bill).data,
                })
        );
    }

    async getReceivedBillAPI({ contactId, billId }: { contactId: string; billId: string }) {
        const {
            getReceivedBill: { bill },
        } = await this._invoicesSdk.getReceivedBill({
            input: {
                businessId: this._clientBusinessId,
                contactId,
                billId,
            },
        });
        return new ReceivedBillAPIForGeneral({
            invoicesSdk: this._invoicesSdk,
            coreSdk: this._coreSdk,
            certSdk: this._certSdk,
            clientBusinessId: this._clientBusinessId,
            ...getReceivedBillFromGql(bill).data,
        });
    }

    async getReceivedBillAPIs({ filter }: { filter?: ReceivedBillsFilter }) {
        const {
            getReceivedBills: { bills },
        } = await this._invoicesSdk.getReceivedBills({
            input: {
                businessId: this._clientBusinessId,
                filter,
            },
        });
        return bills.map(
            (bill) =>
                new ReceivedBillAPIForGeneral({
                    invoicesSdk: this._invoicesSdk,
                    coreSdk: this._coreSdk,
                    certSdk: this._certSdk,
                    clientBusinessId: this._clientBusinessId,
                    ...getReceivedBillFromGql(bill).data,
                })
        );
    }

    async createBill({ contactId, billData }: { contactId: string; billData: BillDataInterface }) {
        const issueingSignature = await createIssueingSignature({
            businessId: this._clientBusinessId,
            contactId,
            certSdk: this._certSdk,
            billData: new BillData(billData),
        });
        const {
            importIssuedBill: { issuedBill },
        } = await this._invoicesSdk.importIssuedBill({
            input: {
                businessId: this._clientBusinessId,
                billData: getGqlBillData(billData),
                signatures: {
                    issuer: {
                        issue: {
                            issuedAt: GqlDateTimeData.parseValue(issueingSignature.issuedAt),
                            signature: issueingSignature.signature.gqlData,
                        },
                    },
                },
            },
        });
        return new IssuedBillAPIForGeneral({
            graphqlSdk: this._invoicesSdk,
            certSdk: this._certSdk,
            clientBusinessId: this._clientBusinessId,
            ...getIssuedBillFromGql(issuedBill).data,
        });
    }
}
