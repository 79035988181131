import { FC } from 'react';

import { useClientProfile } from '@/utils/customHooks';

import { useConfigRouting } from '@/views/config/useConfigRouting';
import { ConfigNavMenu } from '@/views/config/ConfigNavMenu';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    sideMenu: {
        position: 'fixed',
        width: 258,
    },
    content: {
        marginLeft: 258 + 28,
    },
}));

export const ConfigViewRoot: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { slug1, slug2, slug3, setSlug1, setSlug2, setSlug3, currentContent } = useConfigRouting();
    const { clientProfile } = useClientProfile();

    return (
        <div className={c.root}>
            <ConfigNavMenu {...{ slug1, slug2, slug3, setSlug1, setSlug2, setSlug3, profile: clientProfile }} />
            <div className={c.content}>{currentContent}</div>
        </div>
    );
};
ConfigViewRoot.displayName = 'ConfigViewRoot';
