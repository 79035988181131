import { GqlDateTimeData } from '@/utils/UodkaClients/Invoices/graphql/customScalars';
import {
    PaymentData as GqlPaymentData,
    CurrencyCode,
} from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import {
    BusinessSnapshot,
    getBusinessSnapshotFromGql,
} from '@/utils/UodkaClients/Invoices/models/InvoiceData/BusinessSnapshot';
import {
    InvoiceDataItem,
    getInvoiceDataItemFromGql,
} from '@/utils/UodkaClients/Invoices/models/InvoiceData/InvoiceDataItem';
import {
    InvoiceDataSummary,
    getInvoiceDataSummaryFromGql,
} from '@/utils/UodkaClients/Invoices/models/InvoiceData/InvoiceDataSummary';
import { Payee, getPayeeFromGql } from '@/utils/UodkaClients/Invoices/models/InvoiceData/Payee';

export interface PaymentDataInterface {
    billCustomizedId?: string;
    customizedId?: string;
    title?: string;
    issuer: BusinessSnapshot;
    recipient: BusinessSnapshot;
    payee: Payee;
    issueDate?: Date;
    currencyCode: CurrencyCode;
    items: InvoiceDataItem[];
    summary: InvoiceDataSummary;
    note?: string;
}

export class PaymentData implements PaymentDataInterface {
    protected _billCustomizedId?: string;

    protected _customizedId?: string;

    protected _title?: string;

    protected _issuer: BusinessSnapshot;

    protected _recipient: BusinessSnapshot;

    protected _payee: Payee;

    protected _issueDate?: Date;

    protected _currencyCode: CurrencyCode;

    protected _items: InvoiceDataItem[];

    protected _summary: InvoiceDataSummary;

    protected _note?: string;

    get billCustomizedId() {
        return this._billCustomizedId;
    }

    get customizedId() {
        return this._customizedId;
    }

    get title() {
        return this._title;
    }

    get issuer() {
        return this._issuer;
    }

    get recipient() {
        return this._recipient;
    }

    get payee() {
        return this._payee;
    }

    get issueDate() {
        return this._issueDate;
    }

    get currencyCode() {
        return this._currencyCode;
    }

    get items() {
        return this._items;
    }

    get summary() {
        return this._summary;
    }

    get note() {
        return this._note;
    }

    get data() {
        return {
            billCustomizedId: this._billCustomizedId,
            customizedId: this._customizedId,
            title: this._title,
            issuer: this._issuer,
            recipient: this._recipient,
            payee: this._payee,
            issueDate: this._issueDate,
            currencyCode: this._currencyCode,
            items: this._items,
            summary: this._summary,
            note: this._note,
        };
    }

    constructor({
        billCustomizedId,
        customizedId,
        title,
        issuer,
        recipient,
        payee,
        issueDate,
        currencyCode,
        items,
        summary,
        note,
    }: PaymentDataInterface) {
        this._billCustomizedId = billCustomizedId;
        this._customizedId = customizedId;
        this._title = title;
        this._issuer = issuer;
        this._recipient = recipient;
        this._payee = payee;
        this._issueDate = issueDate;
        this._currencyCode = currencyCode;
        this._items = items;
        this._summary = summary;
        this._note = note;
    }
}

export const getPaymentDataFromGql = ({
    billCustomizedId,
    customizedId,
    title,
    issuer,
    recipient,
    payee,
    issueDate,
    currencyCode,
    items,
    summary,
    note,
}: GqlPaymentData) => {
    return new PaymentData({
        billCustomizedId: billCustomizedId || undefined,
        customizedId: customizedId || undefined,
        title: title || undefined,
        issuer: getBusinessSnapshotFromGql(issuer),
        recipient: getBusinessSnapshotFromGql(recipient),
        payee: getPayeeFromGql(payee),
        issueDate: issueDate ? GqlDateTimeData.serialize(issueDate) : undefined,
        currencyCode,
        items: items.map((item) => getInvoiceDataItemFromGql(item)),
        summary: getInvoiceDataSummaryFromGql(summary),
        note: note || undefined,
    });
};

export const getGqlPaymentData = ({
    customizedId,
    title,
    issuer,
    recipient,
    payee,
    issueDate,
    currencyCode,
    items,
    summary,
    note,
}: PaymentDataInterface): GqlPaymentData => {
    return {
        customizedId,
        title,
        issuer,
        recipient,
        payee,
        issueDate: issueDate ? GqlDateTimeData.parseValue(issueDate) : undefined,
        currencyCode,
        items: items.map((item) => ({
            ...item.data,
            date: item.date ? GqlDateTimeData.parseValue(item.date) : undefined,
        })),
        summary,
        note,
    };
};
