import { ReactNode } from 'react';

export const getStrWithBr = (input: string): ReactNode[] => {
    return [...input].reduce((acc, prev, i) => {
        if (prev === '\n') {
            return [...acc, <br key={i} />];
        }
        const [remaining, last] = [acc.slice(0, acc.length - 1), acc[acc.length - 1]];
        if (typeof last === 'string') {
            return [...remaining, last + prev];
        }
        return [...acc, prev];
    }, [] as ReactNode[]);
};
