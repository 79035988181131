import { Balance as GqlBalance, BalanceEventType } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { GqlDateTimeData } from '@/utils/UodkaClients/Core/graphql/customScalars';
import { Charge, getChargeFromGql } from '@/utils/UodkaClients/Core/models/Charge';
import { TransferInBalance, getTransferInBalanceFromGql } from '@/utils/UodkaClients/Core/models/Transfer';

interface BalanceInterface {
    id: string;
    createdAt: Date;
    currentAmount: number;
    currentReserve: number;
    amountIncrease: number;
    reserveIncrease: number;
    eventType: BalanceEventType;
    transfer?: TransferInBalance;
    charge?: Charge;
}

export class Balance implements BalanceInterface {
    protected _id: string;

    protected _createdAt: Date;

    protected _currentAmount: number;

    protected _currentReserve: number;

    protected _amountIncrease: number;

    protected _reserveIncrease: number;

    protected _eventType: BalanceEventType;

    protected _transfer?: TransferInBalance;

    protected _charge?: Charge;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get currentAmount() {
        return this._currentAmount;
    }

    get currentReserve() {
        return this._currentReserve;
    }

    get amountIncrease() {
        return this._amountIncrease;
    }

    get reserveIncrease() {
        return this._reserveIncrease;
    }

    get eventType() {
        return this._eventType;
    }

    get transfer() {
        return this._transfer;
    }

    get charge() {
        return this._charge;
    }

    constructor({
        id,
        createdAt,
        currentAmount,
        currentReserve,
        amountIncrease,
        reserveIncrease,
        eventType,
        transfer,
        charge,
    }: BalanceInterface) {
        this._id = id;
        this._createdAt = createdAt;
        this._currentAmount = currentAmount;
        this._currentReserve = currentReserve;
        this._amountIncrease = amountIncrease;
        this._reserveIncrease = reserveIncrease;
        this._eventType = eventType;
        this._transfer = transfer;
        this._charge = charge;
    }
}

export const getBalanceFromGql = ({
    id,
    createdAt,
    currentAmount,
    currentReserve,
    amountIncrease,
    reserveIncrease,
    eventType,
    transfer,
    charge,
}: GqlBalance) => {
    return new Balance({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        currentAmount,
        currentReserve,
        amountIncrease,
        reserveIncrease,
        eventType,
        transfer: transfer ? getTransferInBalanceFromGql(transfer) : undefined,
        charge: charge ? getChargeFromGql(charge) : undefined,
    });
};
