import { ReactNode } from 'react';

import { useRouting } from '@/utils/customHooks';
import { TransactionsViewRoot } from '@/views/transactions';
import { ContactsViewRoot } from '@/views/contacts';
import { BillsViewRoot } from '@/views/bills';
import { AccountingViewRoot } from '@/views/records';
import { ConfigViewRoot } from '@/views/config';

import SvgYenCircle from '@/svgs/yen-circle';
import SvgContacts from '@/svgs/contacts';
import SvgBill from '@/svgs/bill';
import SvgAccounting from '@/svgs/accounting';
import SvgCog from '@/svgs/cog';

export type ViewName = 'transactions' | 'contacts' | 'bills' | 'records' | 'config';
export interface View {
    name: ViewName;
    icon: ReactNode;
    displayName: {
        en: string;
        ja: string;
    };
    content: ReactNode;
}

export const viewDefs = {
    transactions: {
        name: 'transactions' as const,
        icon: <SvgYenCircle />,
        displayName: {
            en: 'Transactions',
            ja: '入出金',
        },
        content: <TransactionsViewRoot />,
    },
    contacts: {
        name: 'contacts' as const,
        icon: <SvgContacts />,
        displayName: {
            en: 'Contacts',
            ja: 'コンタクト',
        },
        content: <ContactsViewRoot />,
    },
    bills: {
        name: 'bills' as const,
        icon: <SvgBill />,
        displayName: {
            en: 'Bills',
            ja: '請求',
        },
        content: <BillsViewRoot />,
    },
    records: {
        name: 'records' as const,
        icon: <SvgAccounting />,
        displayName: {
            en: 'Records',
            ja: '帳簿',
        },
        content: <AccountingViewRoot />,
    },
    config: {
        name: 'config' as const,
        icon: <SvgCog />,
        displayName: {
            en: 'Config',
            ja: '設定',
        },
        content: <ConfigViewRoot />,
    },
};

export const useView = () => {
    // HOOKS
    const { viewName } = useRouting();

    // DATA
    const currentView = viewName ? viewDefs[viewName] : viewDefs.transactions;

    return {
        currentView,
    };
};
