import {
    BusinessProfile,
    getBusinessProfileInterfaceFromGql,
} from '@/utils/UodkaClients/Profile/models/BusinessProfile';
import { GraphQLSdk } from '@/utils/UodkaClients/Profile/graphql/graphqlSdk';
import { getBusinessLogoSrc, getBusinessIconSrc } from '@/utils/GoogleCloud/storage';

interface UodkaProfileClientForAnonymousInterface {
    getProfileData: (input: { businessId: string }) => Promise<BusinessProfile | undefined>;
}

interface ConstructorInput {
    graphqlSdk: GraphQLSdk;
}

export class UodkaProfileClientForAnonymous implements UodkaProfileClientForAnonymousInterface {
    protected _graphqlSdk: GraphQLSdk;

    constructor({ graphqlSdk }: ConstructorInput) {
        this._graphqlSdk = graphqlSdk;
    }

    async getProfileData({ businessId }: { businessId: string }) {
        const { businessProfile } = await this._graphqlSdk.businessProfile({
            id: businessId,
        });
        const logoSrc = await getBusinessLogoSrc({ businessId });
        const iconSrc = await getBusinessIconSrc({ businessId });
        if (businessProfile || logoSrc || iconSrc) {
            return new BusinessProfile({
                ...getBusinessProfileInterfaceFromGql({
                    businessId,
                    ...businessProfile,
                }),
                logoSrc,
                iconSrc,
            });
        }
        return undefined;
    }
}
