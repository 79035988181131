import { FC, ReactNode } from 'react';

import { useLanguage } from '@/utils/customHooks';

import SvgCheckIcon from '@/svgs/fa-check-light';
import SvgTimesIcon from '@/svgs/fa-times-light';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '&:not(:last-child)': {
            marginBottom: 11,
        },
    },
    stateAndLabel: {
        display: 'flex',
        maxHeight: 62.63,
        alignItems: 'center',
    },
    state: {
        width: 67,
        flexShrink: 0,
    },
    required: {
        color: theme.palette.error.main,
        fontWeight: 600,
    },
    errorIcon: {
        color: theme.palette.error.main,
        height: 20,
    },
    satisfiedIcon: {
        color: theme.palette.success.main,
        height: 20,
    },
    label: {
        fontWeight: 600,
        width: 167,
        paddingRight: '1em',
        flexShrink: 0,
    },
    children: {
        flexGrow: 1,
    },
}));

const FormItem: FC<{
    isRequired?: boolean;
    labelText: string;
    isSatisfied: boolean;
    isError?: boolean;
    children: ReactNode;
}> = ({ isRequired, labelText, isSatisfied, isError, children }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    return (
        <div className={c.root}>
            <div className={c.stateAndLabel}>
                <div className={c.state}>
                    {isError ? (
                        <SvgTimesIcon className={c.errorIcon} />
                    ) : isSatisfied ? (
                        <SvgCheckIcon className={c.satisfiedIcon} />
                    ) : isRequired ? (
                        <span className={c.required}>{txt({ en: 'Required', ja: '必須' })}</span>
                    ) : (
                        <></>
                    )}
                </div>
                <Typography variant={'body2'} className={c.label}>
                    {labelText}
                </Typography>
            </div>
            <div className={c.children}>{children}</div>
        </div>
    );
};
FormItem.displayName = 'FormItem';
export default FormItem;
