import { forwardRef, ComponentProps, ReactElement } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
    root: {},
    icon: {
        width: 20,
        height: 20,
        minWidth: 'auto',
        marginRight: 13,
    },
    primary: {
        fontWeight: 600,
    },
}));

const ListItem = forwardRef<
    HTMLDivElement,
    Omit<ComponentProps<typeof MuiListItem>, 'button' | 'href'> & {
        icon: ReactElement;
        primaryText: string;
        secondaryText?: string;
    }
>(({ className, icon, primaryText, secondaryText, ...otherProps }, ref) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <MuiListItem
            ref={ref}
            {...otherProps}
            className={clsx(c.root, {}, className)}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            button={false as any}
            disableGutters
            dense
        >
            {icon && <MuiListItemIcon className={c.icon}>{icon}</MuiListItemIcon>}
            <MuiListItemText
                primary={primaryText}
                secondary={secondaryText}
                primaryTypographyProps={{ variant: 'body2', className: c.primary }}
            />
        </MuiListItem>
    );
});
ListItem.displayName = 'ListItem';
export default ListItem;
