import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 347 248" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M309.969 114.479C311.487 108.999 312.3 103.297 312.3 97.429C312.3 63.1629 285.136 35.429 251.575 35.429C242.52 35.429 233.737 37.4218 225.55 41.4076C224.518 39.9074 223.445 38.4378 222.332 37.0004L219.803 67.3147C227.719 58.6236 238.996 53.1433 251.575 53.1433C275.54 53.1433 294.95 72.9611 294.95 97.429C294.95 107.67 291.534 117.136 285.787 124.665C310.673 128.65 329.65 150.627 329.65 177.143C329.65 206.483 306.336 230.286 277.6 230.286H78.075C44.5136 230.286 17.35 202.552 17.35 168.286C17.35 136.843 40.2303 110.881 69.9422 106.84C69.5627 103.74 69.4 100.584 69.4 97.429C69.4 57.2317 98.5757 23.9619 136.476 18.5004L135 0.827148C89.6401 6.88533 54.1978 45.5255 52.1584 93.1111C21.2537 104.183 0 134.131 0 168.286C0 212.35 34.9169 248 78.075 248H277.6C315.933 248 347 216.336 347 177.143C347 151.125 333.012 126.879 309.969 114.479Z"
                fill="currentColor"
            />
            <path
                d="M182.617 0H165.382C160.265 0 156.178 4.02829 156.39 8.86195L161.64 127.966C161.841 132.523 165.808 136.119 170.632 136.119H177.367C182.192 136.119 186.158 132.524 186.359 127.966L191.609 8.86195C191.822 4.02829 187.734 0 182.617 0Z"
                fill="currentColor"
            />
            <path
                d="M153 170.149C153 159.186 162.402 150.299 174 150.299C185.598 150.299 195 159.186 195 170.149C195 181.113 185.598 190 174 190C162.402 190 153 181.113 153 170.149Z"
                fill="currentColor"
            />
        </svg>
    );
};
Svg.displayName = 'svg/cloud-caution';
export default Svg;
