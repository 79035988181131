import { FC, ReactNode, useState, useEffect, RefObject } from 'react';

import MuiSlide from '@material-ui/core/Slide';

export const SlidePages: FC<{
    className?: string;
    content1: ReactNode;
    content2: ReactNode;
    currentNumber: 1 | 2;
    scrollBoxRef: RefObject<HTMLDivElement>;
}> = ({ className, content1, content2, currentNumber, scrollBoxRef }) => {
    // HOOKS
    const [isMoving, setIsMoving] = useState(false);

    // USEEFFECT
    useEffect(() => {
        if (scrollBoxRef.current) {
            scrollBoxRef.current.scrollTo({
                top: 0,
                behavior: 'auto',
            });
        }
        setIsMoving(true);
        setTimeout(() => setIsMoving(false), 200);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentNumber]);

    return (
        <div className={className}>
            <MuiSlide timeout={500} in={currentNumber === 1 && !isMoving} direction={'right'}>
                <div
                    style={{
                        height: currentNumber === 1 && !isMoving ? undefined : 0,
                    }}
                >
                    {content1}
                </div>
            </MuiSlide>
            <MuiSlide timeout={500} in={currentNumber === 2 && !isMoving} direction={'left'}>
                <div
                    style={{
                        display: currentNumber !== 2 && !isMoving ? 'none' : undefined,
                    }}
                >
                    {content2}
                </div>
            </MuiSlide>
        </div>
    );
};
SlidePages.displayName = 'SlidePages';
