import { MutationValidationResult } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { GraphQLSdk } from '@/utils/UodkaClients/Core/graphql/graphqlSdk';
import { Contact, ContactInterface } from '@/utils/UodkaClients/Core/models/Contact/data/Contact';
import { BankAccountInTransferInterface } from '@/utils/UodkaClients/Core/models/BankAccount';
import {
    TransferConfirmationAPIForGeneral,
    getTransferConfirmationFromGql,
} from '@/utils/UodkaClients/Core/models/TransferConfirmation';
import { getValidRemitterName, getValidBankAccount } from '@/utils/UodkaClients/Core/utils';

export interface AddTransferConfirmationInput {
    amount: number;
    remitterNameKana: string;
}
export interface AddTransferConfirmationFlexInput {
    amount: number;
    remitterNameKana: string;
    bankAccount: BankAccountInTransferInterface;
}

interface ContactAPIForGeneralInterface {
    addTransferConfirmation: (
        input: AddTransferConfirmationInput
    ) => Promise<{
        transferConfirmationAPI: TransferConfirmationAPIForGeneral;
        result: MutationValidationResult;
    }>;
    addTransferConfirmationFlex: (
        input: AddTransferConfirmationFlexInput
    ) => Promise<{
        transferConfirmationAPI: TransferConfirmationAPIForGeneral;
        result: MutationValidationResult;
    }>;
}

interface ConstructorInput extends ContactInterface {
    graphqlSdk: GraphQLSdk;
    parentBusinessId: string;
}

export class ContactAPIForGeneral extends Contact implements ContactAPIForGeneralInterface {
    protected _graphqlSdk: GraphQLSdk;

    protected _parentBusinessId: string;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
        this._parentBusinessId = input.parentBusinessId;
    }

    async addTransferConfirmation({ amount, remitterNameKana: remitterNameKana_ }: AddTransferConfirmationInput) {
        const remitterNameKana = (() => {
            const { isValid, halfWidthKatakana } = getValidRemitterName(remitterNameKana_);
            if (isValid && halfWidthKatakana) {
                return halfWidthKatakana;
            }
            throw new Error('ContactAPIForGeneral.addTransfer: invalid remitterNameKana');
        })();
        const { createTransferConfirmation } = await this._graphqlSdk.createTransferConfirmation({
            input: {
                businessId: this._parentBusinessId,
                contactId: this._id,
                amount,
                remitterNameKana,
            },
        });
        const { transferConfirmation, result } = createTransferConfirmation;
        return {
            transferConfirmationAPI: new TransferConfirmationAPIForGeneral({
                graphqlSdk: this._graphqlSdk,
                parentBusinessId: this._parentBusinessId,
                parentContactId: this.id,
                ...getTransferConfirmationFromGql(transferConfirmation).data,
            }),
            result,
        };
    }

    async addTransferConfirmationFlex({
        amount,
        remitterNameKana: remitterNameKana_,
        bankAccount: bankAccount_,
    }: AddTransferConfirmationFlexInput) {
        const remitterNameKana = (() => {
            const { isValid, halfWidthKatakana } = getValidRemitterName(remitterNameKana_);
            if (isValid && halfWidthKatakana) {
                return halfWidthKatakana;
            }
            throw new Error('ContactAPIForGeneral.addTransfer: invalid remitterNameKana');
        })();
        const validBankAccount = (() => {
            const { isValid, bankAccount } = getValidBankAccount(bankAccount_);
            if (isValid && bankAccount) {
                return {
                    ...bankAccount,
                    isDepositAccount: bankAccount_.isDepositAccount,
                };
            }
            throw new Error('ContactAPIForGeneral.addTransferConfirmationFlex: invalid bankAccount');
        })();
        const { createTransferConfirmationFlex } = await this._graphqlSdk.createTransferConfirmationFlex({
            input: {
                businessId: this._parentBusinessId,
                contactId: this._id,
                amount,
                remitterNameKana,
                bankAccount: validBankAccount,
            },
        });
        const { transferConfirmation, result } = createTransferConfirmationFlex;
        return {
            transferConfirmationAPI: new TransferConfirmationAPIForGeneral({
                graphqlSdk: this._graphqlSdk,
                parentBusinessId: this._parentBusinessId,
                parentContactId: this.id,
                ...getTransferConfirmationFromGql(transferConfirmation).data,
            }),
            result,
        };
    }
}
