import { App as GqlApp } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { MultilingualText, getMultilingualTextFromGql } from '@/utils/UodkaClients/Core/models/MultilingualText';

interface AppInterface {
    id: string;
    name: MultilingualText;
}

export class App implements AppInterface {
    protected _id: string;

    protected _name: MultilingualText;

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    constructor({ id, name }: AppInterface) {
        this._id = id;
        this._name = name;
    }
}

export const getAppFromGql = ({ id, name }: GqlApp) => {
    return new App({
        id,
        name: getMultilingualTextFromGql(name),
    });
};
