import { FC } from 'react';
import clsx from 'clsx';

import Button from '@/components/0_atom/Button';

import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 20,
        width: 40,
        minWidth: 'auto',
        marginBottom: 3,
        display: 'block',
        borderRadius: 2,
    },
}));

export const getIsValidHexColorCode = (text: string) => {
    return !!text.match(/^#[a-fA-F0-9]{0,6}$/);
};

const ColorPalette: FC<{
    color?: string;
    className?: string;
    onClick?: () => void;
}> = ({ color, className, onClick }) => {
    // STYLE
    const c = useStyles(useTheme());

    // DATA
    const hexCode = color && getIsValidHexColorCode(color) ? color : undefined;

    return onClick ? (
        <Button className={clsx(c.root, className)} style={{ background: hexCode }} onClick={onClick}>
            <></>
        </Button>
    ) : (
        <div className={clsx(c.root, className)} style={{ background: hexCode }} />
    );
};
ColorPalette.displayName = 'ColorPalette';
export default ColorPalette;
