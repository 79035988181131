import { FC, useState } from 'react';

import { ContactAPIForOwner } from '@/utils/UodkaClients/Core';
import { useLanguage, useFormField } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import { MutationFormModal } from '@/components/1_mol/FormModal';
import { TextInput } from '@/components/0_atom/Input';

import SvgTrash from '@/svgs/trash';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({}));

export const ModifyContactNameButton: FC<{
    contactAPI: ContactAPIForOwner;
    updateItem: (contactAPI: ContactAPIForOwner) => void;
}> = ({ contactAPI, updateItem }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [isOpen, setIsOpen] = useState(false);
    const [editingName, setEditingName] = useFormField<string | undefined>(contactAPI.name);

    // DATA
    const run = async () => {
        if (editingName) {
            const result = await contactAPI.modifyName(editingName);
            updateItem(contactAPI);
            return result;
        }
        throw new Error('ModifyContactNameButton.run: !editingName');
    };

    return (
        <>
            <Button onClick={() => setIsOpen(true)} variant={'contained'}>
                {txt({ en: 'Change', ja: '変更' })}
            </Button>
            <MutationFormModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={txt({ en: `Change ${contactAPI.name}'s name`, ja: `${contactAPI.name}の名称を変更` })}
                mutation={{
                    description: txt({
                        en: 'Change contact name',
                        ja: 'コンタクトの名称を変更',
                    }),
                    run,
                }}
                submitText={txt({ en: 'Update', ja: '変更' })}
                items={[
                    {
                        labelText: txt({ en: 'New name', ja: '新しい名称' }),
                        isSatisfied: !!editingName,
                        isError: undefined,
                        isRequired: true,
                        children: (
                            <TextInput value={editingName} setValue={setEditingName} isSatisfied={!!editingName} />
                        ),
                    },
                ]}
            />
        </>
    );
};
ModifyContactNameButton.displayName = 'ModifyContactNameButton';
