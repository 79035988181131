import { UodkaInvoicesClientForAnonymous } from '@/utils/UodkaClients/Invoices/clients/Anonymous';
import { GraphQLSdk as InvoicesSdk } from '@/utils/UodkaClients/Invoices/graphql/graphqlSdk';
import {
    BalanceWithInvoice,
    IssuedBill,
    ReceivedBill,
    getBalanceWithInvoiceFromGql,
    getIssuedBillFromGql,
    getReceivedBillFromGql,
} from '@/utils/UodkaClients/Invoices/models';
import { BalancesFilter } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';

type BillSignaturesFilter = {
    issuerRevoke?: boolean;
    recipientReceive?: boolean;
    recipientRevoke?: boolean;
};
export type IssuedBillsFilter = {
    contactId?: string;
    signatures?: BillSignaturesFilter;
};
export type ReceivedBillsFilter = {
    contactId?: string;
    signatures?: BillSignaturesFilter;
};

interface UodkaInvoicesClientForObserverInterface {
    getBalanceWithInvoices: (input: { filter?: BalancesFilter }) => Promise<BalanceWithInvoice[]>;
    getIssuedBill: (input: { contactId: string; billId: string }) => Promise<IssuedBill>;
    getIssuedBills: (input: { filter?: IssuedBillsFilter }) => Promise<IssuedBill[]>;
    getReceivedBill: (input: { contactId: string; billId: string }) => Promise<ReceivedBill>;
    getReceivedBills: (input: { filter?: ReceivedBillsFilter }) => Promise<ReceivedBill[]>;
}

interface ConstructorInput {
    invoicesSdk: InvoicesSdk;
    clientBusinessId: string;
}

export class UodkaInvoicesClientForObserver
    extends UodkaInvoicesClientForAnonymous
    implements UodkaInvoicesClientForObserverInterface {
    protected _clientBusinessId: string;

    constructor(input: ConstructorInput) {
        super(input);
        this._clientBusinessId = input.clientBusinessId;
    }

    async getBalanceWithInvoices({ filter }: { filter?: BalancesFilter }) {
        const {
            getBalanceWithInvoices: { balances },
        } = await this._invoicesSdk.getBalanceWithInvoices({
            input: {
                businessId: this._clientBusinessId,
                filter,
            },
        });
        return balances.map((balance) => getBalanceWithInvoiceFromGql(balance));
    }

    async getIssuedBill({ contactId, billId }: { contactId: string; billId: string }) {
        const {
            getIssuedBill: { bill },
        } = await this._invoicesSdk.getIssuedBill({
            input: {
                businessId: this._clientBusinessId,
                contactId,
                billId,
            },
        });
        return getIssuedBillFromGql(bill);
    }

    async getIssuedBills({ filter }: { filter?: IssuedBillsFilter }) {
        const {
            getIssuedBills: { bills },
        } = await this._invoicesSdk.getIssuedBills({
            input: {
                businessId: this._clientBusinessId,
                filter,
            },
        });
        return bills.map((bill) => getIssuedBillFromGql(bill));
    }

    async getReceivedBill({ contactId, billId }: { contactId: string; billId: string }) {
        const {
            getReceivedBill: { bill },
        } = await this._invoicesSdk.getReceivedBill({
            input: {
                businessId: this._clientBusinessId,
                contactId,
                billId,
            },
        });
        return getReceivedBillFromGql(bill);
    }

    async getReceivedBills({ filter }: { filter?: ReceivedBillsFilter }) {
        const {
            getReceivedBills: { bills },
        } = await this._invoicesSdk.getReceivedBills({
            input: {
                businessId: this._clientBusinessId,
                filter,
            },
        });
        return bills.map((bill) => getReceivedBillFromGql(bill));
    }
}
