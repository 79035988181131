import { getValidRemitterName } from '@/utils/UodkaClients/Core/utils/getValidRemitterName';
import { ExternalBankAccountInterface } from '@/utils/UodkaClients/Core/models';

export const getIsValidAccountNumber = (text: string): boolean => {
    if (!Number.isNaN(Number(text)) && text.length === 7) {
        return true;
    }
    return false;
};

export const getIsValidBankCode = (text: string): boolean => {
    if (!Number.isNaN(Number(text)) && text.length === 4) {
        return true;
    }
    return false;
};

export const getIsValidBranchCode = (text: string): boolean => {
    if (!Number.isNaN(Number(text)) && text.length === 3) {
        return true;
    }
    return false;
};

export const getValidBankAccount = (input: ExternalBankAccountInterface) => {
    const { bankCode, branchCode, accountNumber, accountHolderName } = input;
    const { isValid, fullWidthKatakana } = getValidRemitterName(accountHolderName);
    if (
        getIsValidBankCode(bankCode) &&
        getIsValidBranchCode(branchCode) &&
        getIsValidAccountNumber(accountNumber) &&
        isValid &&
        fullWidthKatakana
    ) {
        return {
            isValid: true,
            bankAccount: {
                ...input,
                accountNumber,
                accountHolderName: fullWidthKatakana,
            },
        } as const;
    }
    return {
        isValid: false,
        bankAccount: undefined,
    } as const;
};
