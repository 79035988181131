import { FC } from 'react';

import { getPracticalBusinessName } from '@/utils/UodkaClients/Core';
import { provideTextColorForBackgroundColor } from '@/utils/utilFunctions';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 0,
        flexShrink: 0,
        border: 'solid 2px rgba(0,0,0,.035)',
        // padding: 4,
        backgroundColor: '#fff',
    },
}));

const BusinessIcon: FC<{ name: string; iconSrc?: string; themeColor?: string; size?: 'verySmall' | 'large' }> = ({
    name,
    iconSrc,
    themeColor,
    size,
}) => {
    // STYLE
    const theme = useTheme();
    const c = useStyles(theme);

    // DATA
    const style = {
        fontSize: size === 'verySmall' ? 6 : 10,
    };

    return iconSrc ? (
        <img className={c.root} style={style} src={iconSrc} alt={`Logo of ${name}`} />
    ) : (
        <span
            className={c.root}
            style={{
                ...style,
                background: themeColor || '#ccc',
                color: provideTextColorForBackgroundColor(theme.palette.text.primary, themeColor),
            }}
        >
            {getPracticalBusinessName(name)[0]}
        </span>
    );
};
BusinessIcon.displayName = 'BusinessIcon';
export default BusinessIcon;
