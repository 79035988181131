import {
    BusinessMemberInUser as GqlBusinessMemberInUser,
    BusinessMemberRole,
} from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import {
    ClientDataInBusinessMember,
    getClientDataInBusinessMemberFromGql,
} from '@/utils/UodkaClients/Core/models/ClientData';

interface MemberInUserInterface {
    id: string;
    role: BusinessMemberRole;
    business: ClientDataInBusinessMember;
}

export class MemberInUser implements MemberInUserInterface {
    protected _id: string;

    protected _role: BusinessMemberRole;

    protected _business: ClientDataInBusinessMember;

    get id() {
        return this._id;
    }

    get role() {
        return this._role;
    }

    get business() {
        return this._business;
    }

    get data() {
        return {
            id: this.id,
            role: this.role,
            business: this.business,
        };
    }

    constructor({ id, role, business }: MemberInUserInterface) {
        this._id = id;
        this._role = role;
        this._business = business;
    }
}

export const getMemberInUserFromGql = ({ id, role, business }: GqlBusinessMemberInUser) => {
    return new MemberInUser({
        id,
        role,
        business: getClientDataInBusinessMemberFromGql(business),
    });
};
