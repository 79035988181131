import { FC, useState } from 'react';

import { useLanguage } from '@/utils/customHooks';
import FirebaseAuth from '@/components/0_atom/FirebaseAuth';
import Button from '@/components/0_atom/Button';
import { TosModal } from '@/components/2_org/TosModal';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 25,
    },
    tosButton: {
        marginTop: 7,
    },
}));

const PreauthDisplay: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [isTosOpen, setIsTosOpen] = useState(false);

    return (
        <div className={c.root}>
            <FirebaseAuth />
            <Button className={c.tosButton} onClick={() => setIsTosOpen(true)}>
                {txt({ en: 'Terms of service', ja: '利用規約' })}
            </Button>
            <TosModal isOpen={isTosOpen} onClose={() => setIsTosOpen(false)} />
        </div>
    );
};
PreauthDisplay.displayName = 'PreauthDisplay';
export default PreauthDisplay;
