import {
    Language,
    BusinessInBusinessMember as GqlBusinessInBusinessMember,
} from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';

type ClientDataInBusinessMemberInterface = {
    id: string;
    name: string;
    notificationEmail: string;
    language?: Language;
    isArchived: boolean;
};

export class ClientDataInBusinessMember implements ClientDataInBusinessMemberInterface {
    protected _id: string;

    protected _name: string;

    protected _notificationEmail: string;

    protected _language?: Language;

    protected _isArchived: boolean;

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get notificationEmail() {
        return this._notificationEmail;
    }

    get language() {
        return this._language;
    }

    get isArchived() {
        return this._isArchived;
    }

    constructor({ id, name, notificationEmail, language, isArchived }: ClientDataInBusinessMemberInterface) {
        this._id = id;
        this._name = name;
        this._notificationEmail = notificationEmail;
        this._language = language;
        this._isArchived = isArchived;
    }
}

export const getClientDataInBusinessMemberFromGql = ({
    id,
    name,
    notificationEmail,
    language,
    isArchived,
}: GqlBusinessInBusinessMember) => {
    return new ClientDataInBusinessMember({
        id,
        name,
        notificationEmail,
        language: language || undefined,
        isArchived,
    });
};
