import { Language, MutationValidationResult } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { GraphQLSdk } from '@/utils/UodkaClients/Core/graphql/graphqlSdk';
import { User, UserInterface } from '@/utils/UodkaClients/Core/models/User/data/User';

interface UserAPIInterface {
    modifyLanguage: (newLanguage?: Language) => Promise<MutationValidationResult>;
}

interface ConstructorInput extends UserInterface {
    graphqlSdk: GraphQLSdk;
}

export class UserAPI extends User implements UserAPIInterface {
    protected _graphqlSdk: GraphQLSdk;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
    }

    async modifyLanguage(newLanguage?: Language) {
        const { modifyUserLanguage } = await this._graphqlSdk.modifyUserLanguage({
            input: {
                id: this._id,
                oldValue: this._language,
                newValue: newLanguage,
                requiredInterval: 5,
            },
        });
        this._language = modifyUserLanguage.user.language || undefined;
        return modifyUserLanguage.result;
    }
}
