import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 437 449" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.25 257.55L7.05 235.35C-2.35 225.95 -2.35 210.75 7.05 201.45L201.35 7.05C210.75 -2.35 225.95 -2.35 235.25 7.05L429.55 201.35C438.95 210.75 438.95 225.95 429.55 235.25L407.35 257.45C397.85 266.95 382.35 266.75 373.05 257.05L258.35 136.65V424.05C258.35 437.35 247.65 448.05 234.35 448.05H202.35C189.05 448.05 178.35 437.35 178.35 424.05V136.65L63.55 257.15C54.25 266.95 38.75 267.15 29.25 257.55V257.55Z"
                fill="currentColor"
            />
        </svg>
    );
};
Svg.displayName = 'svg/fa-arrow-up-solid';
export default Svg;
