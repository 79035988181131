import { FC } from 'react';

import { ExternalBankAccountInterface } from '@/utils/UodkaClients/Core';
import { useLanguage } from '@/utils/customHooks';
import { BankAccountCardBase } from '@/components/2_org/BankAccountCard/BankAccountCardBase';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    description: {
        fontWeight: 600,
        color: theme.palette.text.disabled,
        display: 'block',
        lineHeight: 1.2,
    },
}));

export const SelfDepositAccountCard: FC<{
    className?: string;
    depositAccount: ExternalBankAccountInterface;
}> = ({ className, depositAccount }) => {
    // STYLE
    const theme = useTheme();
    const c = useStyles(theme);

    // HOOKS
    const { txt, language } = useLanguage();

    return (
        <BankAccountCardBase
            className={className}
            bankAccount={{ ...depositAccount, isDepositAccount: true }}
            accountDescription={
                <MuiTypography variant={'caption'} className={c.description}>
                    {txt({ en: 'Self Deposit Account', ja: '自己入金口座' })}
                </MuiTypography>
            }
        />
    );
};
SelfDepositAccountCard.displayName = 'SelfDepositAccountCard';
