import { useEffect, useState, ReactElement } from 'react';

import { useErrorCtx } from '@/utils/ctxs';
import { useRouting } from '@/utils/customHooks';

import { PersonalConfig } from '@/views/config/PersonalConfig';
import { ConfigBusinessAccountBasic } from '@/views/config/business/account/Basic';
import { ConfigBusinessAccountCharge } from '@/views/config/business/account/Charge';
import { ConfigBusinessAccountLicense } from '@/views/config/business/account/License';
import { ConfigBusinessProfile } from '@/views/config/business/Profile';
import { ConfigBusinessMembers } from '@/views/config/business/Member';

const configRoutingDef = {
    business: {
        account: {
            data: {
                content: <ConfigBusinessAccountBasic />,
            },
            charge: {
                content: <ConfigBusinessAccountCharge />,
            },
            license: {
                content: <ConfigBusinessAccountLicense />,
            },
        },
        members: {
            content: <ConfigBusinessMembers />,
        },
        profile: {
            content: <ConfigBusinessProfile />,
        },
        // bills: {
        //     content: <>bills config content</>,
        // },
    },
    user: {
        content: <PersonalConfig />,
    },
};
export type ConfigViwSlug1 = keyof typeof configRoutingDef;
export type ConfigViwSlug2 = keyof typeof configRoutingDef.business;
export type ConfigViwSlug3 = keyof typeof configRoutingDef.business.account;
const getIsConfigViwSlug1 = (str: string): str is ConfigViwSlug1 => {
    return str === 'business' || str === 'user';
};
const getIsConfigViwSlug2 = (str: string): str is ConfigViwSlug2 => {
    return str === 'account' || str === 'members' || str === 'profile' || str === 'bills';
};
const getIsConfigViwSlug3 = (str: string): str is ConfigViwSlug3 => {
    return str === 'data' || str === 'charge' || str === 'license';
};

export const useConfigRouting = () => {
    // HOOKS
    const { setIs404 } = useErrorCtx();
    const { viewSlug1, viewSlug2, viewSlug3, setViewSlug1, setViewSlug2, setViewSlug3 } = useRouting();
    const [currentContent, setCurrentContent] = useState<ReactElement>(configRoutingDef.business.account.data.content);
    const [slug1, setSlug1] = useState<ConfigViwSlug1>('business');
    const [slug2, setSlug2] = useState<ConfigViwSlug2>('account');
    const [slug3, setSlug3] = useState<ConfigViwSlug3>('data');

    // USEEFFECT
    useEffect(() => {
        if (!viewSlug1) {
            setSlug1('business');
            setSlug2('account');
            setSlug3('data');
            setCurrentContent(configRoutingDef.business.account.data.content);
            return;
        }
        if (getIsConfigViwSlug1(viewSlug1)) {
            if (!viewSlug2) {
                setSlug1(viewSlug1);
                setSlug2('account');
                setSlug3('data');
                if (viewSlug1 === 'business') {
                    setCurrentContent(configRoutingDef.business.account.data.content);
                } else {
                    setCurrentContent(configRoutingDef.user.content);
                }
                return;
            }
            if (viewSlug1 === 'business' && getIsConfigViwSlug2(viewSlug2)) {
                if (!viewSlug3) {
                    setSlug1(viewSlug1);
                    setSlug2(viewSlug2);
                    setSlug3('data');
                    if (viewSlug2 === 'account') {
                        setCurrentContent(configRoutingDef.business.account.data.content);
                    } else {
                        setCurrentContent(configRoutingDef.business[viewSlug2].content);
                    }
                    return;
                }
                if (viewSlug2 === 'account' && getIsConfigViwSlug3(viewSlug3)) {
                    setSlug1(viewSlug1);
                    setSlug2(viewSlug2);
                    setSlug3(viewSlug3);
                    setCurrentContent(configRoutingDef.business.account[viewSlug3].content);
                    return;
                }
            }
        }
        setIs404(true);
    }, [viewSlug1, viewSlug2, viewSlug3, setIs404]);

    return {
        currentContent,
        slug1,
        slug2,
        slug3,
        setSlug1: (slugs: { slug1?: ConfigViwSlug1 }) => setViewSlug1(slugs),
        setSlug2: (slugs: { slug1: ConfigViwSlug1; slug2?: ConfigViwSlug2 }) => setViewSlug2(slugs),
        setSlug3: (slugs: { slug1: ConfigViwSlug1; slug2: ConfigViwSlug2; slug3?: ConfigViwSlug3 }) =>
            setViewSlug3(slugs),
    };
};
