import { FC, useEffect, useCallback } from 'react';

import { BusinessSnapshot, BusinessSnapshotItem } from '@/utils/UodkaClients/Invoices';
import { useLanguage, useFormField } from '@/utils/customHooks';
import { TextInput } from '@/components/0_atom/Input';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        background: 'rgba(255,255,255,1)',
    },
    label: {},
    children: {},
    input: {
        width: '100%',
        '&:not(:last-child)': {
            marginBottom: 7,
        },
        '& input': {
            fontSize: theme.typography.body2.fontSize,
            padding: 16,
        },
        '& textarea': {
            fontSize: theme.typography.body2.fontSize,
            padding: 16,
        },
    },
    left: {
        marginRight: 7,
        width: '50%',
    },
    right: {
        width: '50%',
    },
}));

export const BusinessSnapshotEditor: FC<{
    businessSnapshot: BusinessSnapshot;
    setBusinessSnapshot: (businessSnapshot: BusinessSnapshot) => void;
}> = ({ businessSnapshot, setBusinessSnapshot }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [address, setAddress] = useFormField(businessSnapshot.items.find(({ title }) => title === 'address')?.value);
    const [phone, setPhone] = useFormField(businessSnapshot.items.find(({ title }) => title === 'phone')?.value);
    const [email, setEmail] = useFormField(businessSnapshot.items.find(({ title }) => title === 'email')?.value);
    const [websiteUrl, setWebsiteUrl] = useFormField(
        businessSnapshot.items.find(({ title }) => title === 'websiteUrl')?.value
    );
    const updateBusinessSnapshotItem = useCallback(
        ({ title, value }: { title: string; value?: string }) => {
            const newItems = (() => {
                if (businessSnapshot.items.find((item) => item.title === title)) {
                    if (value) {
                        return businessSnapshot.items.map((item) => {
                            if (item.title === title) {
                                return new BusinessSnapshotItem({ title, value });
                            }
                            return item;
                        });
                    }
                    return businessSnapshot.items.filter((item) => item.title !== title);
                }
                if (value) {
                    return [...businessSnapshot.items, new BusinessSnapshotItem({ title, value })];
                }
                return businessSnapshot.items;
            })();
            setBusinessSnapshot(
                new BusinessSnapshot({
                    ...businessSnapshot.data,
                    items: newItems,
                })
            );
        },
        [businessSnapshot, setBusinessSnapshot]
    );

    // USEEFFECT
    // useEffect(() => {
    //     if (businessSnapshot.items.find(({ title }) => title === 'address')?.value !== address) {
    //         updateBusinessSnapshotItem({ title: 'address', value: address });
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [address, updateBusinessSnapshotItem]);
    // useEffect(() => {
    //     if (businessSnapshot.items.find(({ title }) => title === 'phone')?.value !== phone) {
    //         updateBusinessSnapshotItem({ title: 'phone', value: phone });
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [phone, updateBusinessSnapshotItem]);
    // useEffect(() => {
    //     if (businessSnapshot.items.find(({ title }) => title === 'email')?.value !== email) {
    //         updateBusinessSnapshotItem({ title: 'email', value: email });
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [email, updateBusinessSnapshotItem]);
    // useEffect(() => {
    //     if (businessSnapshot.items.find(({ title }) => title === 'websiteUrl')?.value !== websiteUrl) {
    //         updateBusinessSnapshotItem({ title: 'websiteUrl', value: websiteUrl });
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [websiteUrl, updateBusinessSnapshotItem]);

    return (
        <div className={c.root}>
            <div className={c.left}>
                <TextInput
                    className={c.input}
                    value={businessSnapshot.name}
                    setValue={(newName) =>
                        setBusinessSnapshot(
                            new BusinessSnapshot({
                                ...businessSnapshot.data,
                                name: newName || '',
                            })
                        )
                    }
                    placeholder={txt({ en: 'Name', ja: '名称' })}
                />
                <TextInput
                    className={c.input}
                    value={address}
                    setValue={setAddress}
                    placeholder={txt({ en: 'Address', ja: '住所' })}
                    multiline
                />
            </div>
            <div className={c.right}>
                <TextInput
                    className={c.input}
                    value={phone}
                    setValue={setPhone}
                    placeholder={txt({ en: 'Phone', ja: '電話番号' })}
                />
                <TextInput
                    className={c.input}
                    value={email}
                    setValue={setEmail}
                    placeholder={txt({ en: 'Email', ja: 'メールアドレス' })}
                />
                <TextInput
                    className={c.input}
                    value={websiteUrl}
                    setValue={setWebsiteUrl}
                    placeholder={txt({ en: 'Website URL', ja: 'ウェブサイトURl' })}
                />
            </div>
        </div>
    );
};
BusinessSnapshotEditor.displayName = 'BusinessSnapshotEditor';
