import { FC, ReactElement } from 'react';
import clsx from 'clsx';

import Button from '@/components/0_atom/Button';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useSideMenuStyles = makeStyles((theme) => ({
    root: {
        // marginRight: 36,
        position: 'fixed',
    },
    title: {
        marginBottom: 28,
    },
    button: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: 150,
        fontWeight: 600,
        padding: '14px 20px',
        '& .MuiButton-label': {
            lineHeight: 1,
        },
        '& .MuiButton-startIcon': {
            width: 12,
            marginRight: 14,
        },
        '&:hover': {
            background: 'rgba(255,255,255,.5)',
        },
    },
    buttonSelected: {
        background: '#fff',
    },
    actionButtonBox: {
        marginTop: 14,
        paddingTop: 28,
        borderTop: 'solid 2px rgba(0,0,0,.03)',
    },
    actionButton: {
        width: '100%',
    },
}));

type SideMenuButtonData = {
    text: string;
    icon: ReactElement;
    onClick: () => void;
    isSelected?: boolean;
};
type SideMenuActionButtonData = {
    text: string;
    icon?: ReactElement;
    onClick: () => void;
};

export const SideMenu: FC<{
    className?: string;
    title: string;
    buttonDatas: SideMenuButtonData[];
    actionButtonData?: SideMenuActionButtonData;
}> = ({ className, title, buttonDatas, actionButtonData }) => {
    // STYLE
    const c = useSideMenuStyles(useTheme());

    return (
        <div className={clsx(c.root, className)}>
            <MuiTypography variant={'h1'} className={c.title}>
                {title}
            </MuiTypography>
            <div>
                {buttonDatas.map(({ text, icon, onClick, isSelected }, i) => (
                    <Button
                        className={clsx(c.button, {
                            [c.buttonSelected]: isSelected,
                        })}
                        key={i}
                        startIcon={icon}
                        onClick={onClick}
                    >
                        {text}
                    </Button>
                ))}
            </div>
            {actionButtonData && (
                <div className={c.actionButtonBox}>
                    <Button
                        className={c.actionButton}
                        startIcon={actionButtonData.icon}
                        onClick={actionButtonData.onClick}
                        role={'submit'}
                    >
                        {actionButtonData.text}
                    </Button>
                </div>
            )}
        </div>
    );
};
SideMenu.displayName = 'SideMenu';

const useSideMenuTemplateStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        marginLeft: 150 + 36,
    },
}));

export const SideMenuTemplate: FC<{
    className?: string;
    title: string;
    sideMenuButtonDatas: SideMenuButtonData[];
    actionButtonData?: SideMenuActionButtonData;
    children: ReactElement;
}> = ({ className, title, sideMenuButtonDatas, actionButtonData, children }) => {
    // STYLE
    const c = useSideMenuTemplateStyles(useTheme());

    return (
        <div className={clsx(c.root, className)}>
            <SideMenu title={title} buttonDatas={sideMenuButtonDatas} actionButtonData={actionButtonData} />
            <div className={c.content}>{children}</div>
        </div>
    );
};
SideMenu.displayName = 'SideMenu';
