import { FC } from 'react';

import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage, useRouting } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';

import SvgContacts from '@/svgs/contacts';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '14px 21px',
    },
    icon: {
        width: 70,
        height: 70,
    },
    title: {
        fontWeight: 600,
        fontSize: theme.typography.body1.fontSize,
    },
    description: {
        fontSize: theme.typography.body2.fontSize,
        marginTop: 4,
    },
    buttonBox: {
        marginTop: 34,
        textAlign: 'right',
    },
}));

const NoContactDisplay: FC<{
    description?: string;
}> = ({ description }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { currentBusiness } = useBusinessCtxAbsolutely();
    const { txt } = useLanguage();
    const { switchView } = useRouting();

    return (
        <div className={c.root}>
            <SvgContacts className={c.icon} />
            <MuiTypography className={c.title}>{txt({ en: 'No contact', ja: 'コンタクトがありません' })}</MuiTypography>
            <MuiTypography className={c.description}>
                {description ||
                    txt({
                        en: 'Add a contact to initiate a transaction.',
                        ja: '取引を開始するにはコンタクトを追加してください。',
                    })}
            </MuiTypography>
            <div className={c.buttonBox}>
                <Button
                    onClick={() => switchView({ businessId: currentBusiness.id, viewName: 'contacts' })}
                    role={'submit'}
                >
                    {txt({ en: 'Manage contacts', ja: 'コンタクト一覧へ' })}
                </Button>
            </div>
        </div>
    );
};
NoContactDisplay.displayName = 'NoContactDisplay';
export default NoContactDisplay;
