import { InvoiceDataItem } from '@/utils/UodkaClients/Invoices';

export const getInvoiceDataItemsData = ({ invoiceDataItems }: { invoiceDataItems: InvoiceDataItem[] }) => {
    return invoiceDataItems.map(({ date, quantity, title, taxes, unitPrice }) => ({
        date,
        quantity,
        title,
        taxes: taxes.map(({ title, value }) => ({ title, value })),
        unitPrice,
    }));
};
