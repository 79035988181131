import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { useBusinessCtx, useUserCtx } from '@/utils/ctxs';
import HealthProvider from '@/providers/HealthProvider';
import MutationsProvider from '@/providers/MutationsProvider';
import ErrorProvider from '@/providers/ErrorProvider';
import UserProvider from '@/providers/UserProvider';
import BusinessProvider from '@/providers/BusinessProvider';
import NetworkIndicator from '@/components/2_org/NetworkIndicator';
import ConsoleTemplate from '@/components/3_template/ConsoleTemplate';
import PreConsoleTemplate from '@/components/3_template/PreConsoleTemplate';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import muiTheme from '@/styles/muiTheme';

const Handler: FC = () => {
    // HOOKS
    const { userClient, isFetching, firebaseUser } = useUserCtx();
    const { currentBusiness, clients } = useBusinessCtx();

    return currentBusiness ? <ConsoleTemplate /> : <PreConsoleTemplate />;
};

const AppRoot: FC = () => {
    return (
        <ErrorProvider>
            <HealthProvider>
                <MutationsProvider>
                    <UserProvider>
                        <BrowserRouter>
                            <BusinessProvider>
                                <ThemeProvider theme={muiTheme}>
                                    <CssBaseline />
                                    <NetworkIndicator />
                                    <Handler />
                                </ThemeProvider>
                            </BusinessProvider>
                        </BrowserRouter>
                    </UserProvider>
                </MutationsProvider>
            </HealthProvider>
        </ErrorProvider>
    );
};
AppRoot.displayName = 'AppRoot';
export default AppRoot;
