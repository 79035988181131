import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 1364 1001" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 24C0 10.7452 10.7452 0 24 0H626C639.255 0 650 10.7452 650 24V176C650 189.255 639.255 200 626 200H24C10.7451 200 0 189.255 0 176V24Z"
                fill="#3D73FF"
            />
            <path
                d="M714 24C714 10.7452 724.745 0 738 0H1340C1353.25 0 1364 10.7452 1364 24V176C1364 189.255 1353.25 200 1340 200H738C724.745 200 714 189.255 714 176V24Z"
                fill="#3D73FF"
            />
            <path
                d="M0 291C0 277.745 10.7452 267 24 267H626C639.255 267 650 277.745 650 291V443C650 456.255 639.255 467 626 467H24C10.7451 467 0 456.255 0 443V291Z"
                fill="#3D73FF"
            />
            <path
                d="M714 291C714 277.745 724.745 267 738 267H1340C1353.25 267 1364 277.745 1364 291V443C1364 456.255 1353.25 467 1340 467H738C724.745 467 714 456.255 714 443V291Z"
                fill="#3D73FF"
            />
            <path
                d="M0 291C0 277.745 10.7452 267 24 267H626C639.255 267 650 277.745 650 291V443C650 456.255 639.255 467 626 467H24C10.7451 467 0 456.255 0 443V291Z"
                fill="white"
                fillOpacity="0.4"
            />
            <path
                d="M714 24C714 10.7452 724.745 0 738 0H1340C1353.25 0 1364 10.7452 1364 24V176C1364 189.255 1353.25 200 1340 200H738C724.745 200 714 189.255 714 176V24Z"
                fill="white"
                fillOpacity="0.6"
            />
            <path
                d="M714 291C714 277.745 724.745 267 738 267H1340C1353.25 267 1364 277.745 1364 291V443C1364 456.255 1353.25 467 1340 467H738C724.745 467 714 456.255 714 443V291Z"
                fill="white"
                fillOpacity="0.9"
            />
            <path
                d="M0 558C0 544.745 10.7452 534 24 534H626C639.255 534 650 544.745 650 558V710C650 723.255 639.255 734 626 734H24C10.7451 734 0 723.255 0 710V558Z"
                fill="#3D73FF"
            />
            <path
                d="M714 558C714 544.745 724.745 534 738 534H1340C1353.25 534 1364 544.745 1364 558V710C1364 723.255 1353.25 734 1340 734H738C724.745 734 714 723.255 714 710V558Z"
                fill="#3D73FF"
            />
            <path
                d="M0 825C0 811.745 10.7452 801 24 801H626C639.255 801 650 811.745 650 825V977C650 990.255 639.255 1001 626 1001H24C10.7451 1001 0 990.255 0 977V825Z"
                fill="#3D73FF"
            />
            <path
                d="M714 825C714 811.745 724.745 801 738 801H1340C1353.25 801 1364 811.745 1364 825V977C1364 990.255 1353.25 1001 1340 1001H738C724.745 1001 714 990.255 714 977V825Z"
                fill="#3D73FF"
            />
            <path
                d="M0 558C0 544.745 10.7452 534 24 534H626C639.255 534 650 544.745 650 558V710C650 723.255 639.255 734 626 734H24C10.7451 734 0 723.255 0 710V558Z"
                fill="white"
                fillOpacity="0.8"
            />
            <path
                d="M714 558C714 544.745 724.745 534 738 534H1340C1353.25 534 1364 544.745 1364 558V710C1364 723.255 1353.25 734 1340 734H738C724.745 734 714 723.255 714 710V558Z"
                fill="white"
                fillOpacity="0.3"
            />
            <path
                d="M0 825C0 811.745 10.7452 801 24 801H626C639.255 801 650 811.745 650 825V977C650 990.255 639.255 1001 626 1001H24C10.7451 1001 0 990.255 0 977V825Z"
                fill="white"
                fillOpacity="0.6"
            />
            <path
                d="M714 825C714 811.745 724.745 801 738 801H1340C1353.25 801 1364 811.745 1364 825V977C1364 990.255 1353.25 1001 1340 1001H738C724.745 1001 714 990.255 714 977V825Z"
                fill="white"
                fillOpacity="0.6"
            />
        </svg>
    );
};
Svg.displayName = 'svg/accounting';
export default Svg;
