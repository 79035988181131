import { FC, useState } from 'react';

import { ContactAPIForOwner } from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage, useContactItems, ContactItem, useClientProfile } from '@/utils/customHooks';
import FetchingText from '@/components/0_atom/FetchingText';
import Button from '@/components/0_atom/Button';
import ContactNameWithIcon from '@/components/2_org/ContactNameWithIcon';
import NoContactDisplay from '@/components/2_org/NoContactDisplay';
import { ExternalBankAccountCardWithHolder, DepositAccountCardWithHolder } from '@/components/2_org/BankAccountCard';

import SvgChevronUp from '@/svgs/fa-chevron-up-solid';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiMenu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {},
    bankAccountCard: {
        '& .MuiTypography-root': {
            // fontSize: theme.typography.body2.fontSize,
        },
    },
    contactNameWithIcon: {
        '& .MuiTypography-root': {
            fontSize: theme.typography.body2.fontSize,
        },
    },
    noDestinationBlock: {},
    noDestination: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 600,
        color: theme.palette.text.disabled,
        margin: '14px 0',
    },
    button: {
        border: 'solid 2px rgba(0,0,0,.03)',
        width: 280,
        height: 140,
        // background: '#fafafa',
        justifyContent: 'flex-start',
        '& .MuiButton-endIcon': {
            position: 'absolute',
            right: 14,
            width: 12,
            height: 'auto',
            transform: 'rotate(180deg)',
            color: theme.palette.text.disabled,
        },
    },
    selectContact: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 600,
        // color: 'rgba(0,0,0,.45)',
        marginLeft: 9,
    },
    menuItem: {
        paddingTop: 14,
        paddingBottom: 28,
        '&:not(:last-child)': {
            borderBottom: 'solid 1px rgba(0,0,0,.05)',
        },
    },
    fetchingText: {
        marginLeft: 9,
    },
}));

const ContactWithBankAccount: FC<{
    contactItem: ContactItem<ContactAPIForOwner>;
    clientProfile: BusinessProfile | undefined;
}> = ({ contactItem, clientProfile }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const { currentBusiness } = useBusinessCtxAbsolutely();

    return (
        <>
            {contactItem.contact.transferDestination ? (
                contactItem.contact.transferDestination.isDepositAccount ? (
                    <DepositAccountCardWithHolder
                        className={c.bankAccountCard}
                        depositAccount={contactItem.contact.transferDestination}
                        holder={{ name: contactItem.contact.name, profile: contactItem.profile?.data }}
                        remitter={{
                            name: currentBusiness.name,
                            profile: clientProfile,
                        }}
                    />
                ) : (
                    <ExternalBankAccountCardWithHolder
                        className={c.bankAccountCard}
                        bankAccount={contactItem.contact.transferDestination}
                        holder={{ name: contactItem.contact.name, profile: contactItem.profile?.data }}
                    />
                )
            ) : (
                <div className={c.noDestinationBlock}>
                    <ContactNameWithIcon
                        contact={contactItem.contact}
                        profile={contactItem.profile?.data}
                        className={c.contactNameWithIcon}
                    />
                    <MuiTypography className={c.noDestination}>
                        {txt({
                            en: "Bank account hasn't been set up for the remittance.",
                            ja: '送金先の銀行口座が設定されていません',
                        })}
                    </MuiTypography>
                </div>
            )}
        </>
    );
};

export const SelectContactButton: FC<{
    selectedContactItem: ContactItem<ContactAPIForOwner> | undefined;
    setSelectedContactItem: (selectedContactItem: ContactItem<ContactAPIForOwner> | undefined) => void;
}> = ({ selectedContactItem, setSelectedContactItem }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const { clientProfile } = useClientProfile();
    const { items } = useContactItems<ContactAPIForOwner>();
    const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);

    // DATA
    const isFetching = !(items && clientProfile !== 'fetching');
    const sortedItems = (() => {
        const contactItems = items?.ids.map((id) => items.getItemAbsolutely(id));
        if (contactItems) {
            const itemWithDestinations = contactItems.filter(
                ({ contact: { transferDestination } }) => transferDestination
            );
            const itemWithoutDestinations = contactItems.filter(
                ({ contact: { transferDestination } }) => !transferDestination
            );
            return [...itemWithDestinations, ...itemWithoutDestinations];
        }
        return undefined;
    })();
    console.log('sortedItems->', sortedItems);
    return (
        <>
            <Button
                onClick={(e) => setAnchorEl(e.currentTarget)}
                fullWidth
                className={c.button}
                disabled={isFetching}
                endIcon={!selectedContactItem && <SvgChevronUp />}
            >
                {items && clientProfile !== 'fetching' ? (
                    selectedContactItem ? (
                        <ContactWithBankAccount contactItem={selectedContactItem} clientProfile={clientProfile} />
                    ) : (
                        <MuiTypography className={c.selectContact}>
                            {txt({ en: 'Select contact', ja: 'コンタクトを選択' })}
                        </MuiTypography>
                    )
                ) : (
                    <FetchingText className={c.fetchingText} />
                )}
            </Button>
            {sortedItems && clientProfile !== 'fetching' && (
                <MuiMenu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(undefined)}>
                    {sortedItems.length > 0 ? (
                        sortedItems.map((contactItem, i) => {
                            // const contactItem = items.getItemAbsolutely(id);
                            return (
                                <MuiMenuItem
                                    key={i}
                                    disabled={!contactItem.contact.transferDestination}
                                    onClick={
                                        contactItem.contact instanceof ContactAPIForOwner
                                            ? () => {
                                                  setSelectedContactItem(contactItem);
                                                  setAnchorEl(undefined);
                                              }
                                            : undefined
                                    }
                                    className={c.menuItem}
                                >
                                    <ContactWithBankAccount contactItem={contactItem} clientProfile={clientProfile} />
                                </MuiMenuItem>
                            );
                        })
                    ) : (
                        <NoContactDisplay />
                    )}
                </MuiMenu>
            )}
        </>
    );
};
SelectContactButton.displayName = 'SelectContactButton';
