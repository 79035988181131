import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import { print } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
};


export type Query = {
  serviceHealth: ServiceHealth;
  legalTexts: LegalTexts;
  fees: Fees;
  user?: Maybe<User>;
  userWithBusinessMembers?: Maybe<UserWithBusinessMembers>;
  inviteForBusinessMember?: Maybe<InviteForBusinessMember>;
  inviteForContact?: Maybe<InviteForContact>;
  sharedTransfer: GetSharedTransferPayload;
  businessMembersInUser: Array<BusinessMemberInUser>;
  apiKey?: Maybe<ApiKey>;
  business: Business;
  recentBalance?: Maybe<Balance>;
  balances: Array<Balance>;
  contact?: Maybe<Contact>;
  contactForRelatedBusinessId?: Maybe<Contact>;
  contacts: Array<Contact>;
  charge?: Maybe<Charge>;
  transfer?: Maybe<Transfer>;
  transferConfirmation?: Maybe<TransferConfirmation>;
  incomingTransfer: GetIncomingTransferPayload;
  contactWithInvites: Array<ContactWithInvite>;
  businessMembersInBusiness: Array<BusinessMemberInBusiness>;
  inviteForBusinessMembers: Array<InviteForBusinessMemberInBusiness>;
};


export type QueryInviteForBusinessMemberArgs = {
  businessId: Scalars['ID'];
  codeText: Scalars['ID'];
};


export type QueryInviteForContactArgs = {
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
  codeText: Scalars['ID'];
};


export type QuerySharedTransferArgs = {
  input: GetSharedTransferInput;
};


export type QueryBusinessArgs = {
  id: Scalars['ID'];
};


export type QueryRecentBalanceArgs = {
  businessId: Scalars['ID'];
};


export type QueryBalancesArgs = {
  businessId: Scalars['ID'];
  filter?: Maybe<BalancesFilter>;
};


export type QueryContactArgs = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
};


export type QueryContactForRelatedBusinessIdArgs = {
  businessId: Scalars['ID'];
  relatedBusinessId: Scalars['ID'];
};


export type QueryContactsArgs = {
  businessId: Scalars['ID'];
};


export type QueryChargeArgs = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
};


export type QueryTransferArgs = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  isRefreshStatus?: Maybe<Scalars['Boolean']>;
};


export type QueryTransferConfirmationArgs = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
};


export type QueryIncomingTransferArgs = {
  input: GetIncomingTransferInput;
};


export type QueryContactWithInvitesArgs = {
  businessId: Scalars['ID'];
};


export type QueryBusinessMembersInBusinessArgs = {
  businessId: Scalars['ID'];
};


export type QueryInviteForBusinessMembersArgs = {
  businessId: Scalars['ID'];
};

export type RunManualCronInput = {
  grantCode: Scalars['String'];
};

export type Mutation = {
  applyInviteForBusinessMember: ApplyInviteForBusinessMemberPayload;
  applyInviteForContactWithSigningUp: ApplyInviteForContactWithSigningUpPayload;
  modifyUserLanguage: ModifyUserLanguagePayload;
  createBusiness: CreateBusinessPayload;
  createTransferConfirmation: CreateTransferConfirmationPayload;
  createTransferConfirmationFlex: CreateTransferConfirmationFlexPayload;
  deleteTransferConfirmation: DeleteTransferConfirmationPayload;
  createTransfer: CreateTransferPayload;
  modifyBusinessName: ModifyBusinessNamePayload;
  modifyBusinessNotificationEmail: ModifyBusinessNotificationEmailPayload;
  modifyBusinessRemitterNameKana: ModifyBusinessRemitterNameKanaPayload;
  modifyBusinessExternalBankAccount: ModifyBusinessExternalBankAccountPayload;
  modifyBusinessLanguage: ModifyBusinessLanguagePayload;
  archiveBusiness: ArchiveBusinessPayload;
  modifyBusinessMemberRole: ModifyBusinessMemberRolePayload;
  deleteBusinessMember: DeleteBusinessMemberPayload;
  createContact: CreateContactPayload;
  addContactAdditionalId: AddContactAdditionalIdPayload;
  modifyContactName: ModifyContactNamePayload;
  modifyContactNotificationEmail: ModifyContactNotificationEmailPayload;
  modifyContactExternalBankAccount: ModifyContactExternalBankAccountPayload;
  archiveContact: ArchiveContactPayload;
  createInviteForBusinessMember: CreateInviteForBusinessMemberPayload;
  deleteInviteForBusinessMember: DeleteInviteForBusinessMemberPayload;
  createInviteForContact: CreateInviteForContactPayload;
  deleteInviteForContact: DeleteInviteForContactPayload;
  applyInviteForContactWithBusiness: ApplyInviteForContactWithBusinessPayload;
  createAppDeposit: CreateAppDepositPayload;
  createAppWithdraw: CreateAppWithdrawPayload;
  cancelAppWithdraw: CancelAppWithdrawPayload;
  createAppReserve: CreateAppReservePayload;
  resolveAppReserve: ResolveAppReservePayload;
  refreshApiKey: RefreshApiKeyPayload;
  deleteApiKey: DeleteApiKeyPayload;
  runManualCron: Scalars['Boolean'];
  runAutoCron10mins: Scalars['Boolean'];
  runAutoCron6hours: Scalars['Boolean'];
  runAutoCron1day: Scalars['Boolean'];
};


export type MutationApplyInviteForBusinessMemberArgs = {
  input: ApplyInviteForBusinessMemberInput;
};


export type MutationApplyInviteForContactWithSigningUpArgs = {
  input: ApplyInviteForContactWithSigningUpInput;
};


export type MutationModifyUserLanguageArgs = {
  input: ModifyUserLanguageInput;
};


export type MutationCreateBusinessArgs = {
  input: CreateBusinessInput;
};


export type MutationCreateTransferConfirmationArgs = {
  input: CreateTransferConfirmationInput;
};


export type MutationCreateTransferConfirmationFlexArgs = {
  input: CreateTransferConfirmationFlexInput;
};


export type MutationDeleteTransferConfirmationArgs = {
  input: DeleteTransferConfirmationInput;
};


export type MutationCreateTransferArgs = {
  input: CreateTransferInput;
};


export type MutationModifyBusinessNameArgs = {
  input: ModifyBusinessNameInput;
};


export type MutationModifyBusinessNotificationEmailArgs = {
  input: ModifyBusinessNotificationEmailInput;
};


export type MutationModifyBusinessRemitterNameKanaArgs = {
  input: ModifyBusinessRemitterNameKanaInput;
};


export type MutationModifyBusinessExternalBankAccountArgs = {
  input: ModifyBusinessExternalBankAccountInput;
};


export type MutationModifyBusinessLanguageArgs = {
  input: ModifyBusinessLanguageInput;
};


export type MutationArchiveBusinessArgs = {
  input: ArchiveBusinessInput;
};


export type MutationModifyBusinessMemberRoleArgs = {
  input: ModifyBusinessMemberRoleInput;
};


export type MutationDeleteBusinessMemberArgs = {
  input: DeleteBusinessMemberInput;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationAddContactAdditionalIdArgs = {
  input: AddContactAdditionalIdInput;
};


export type MutationModifyContactNameArgs = {
  input: ModifyContactNameInput;
};


export type MutationModifyContactNotificationEmailArgs = {
  input: ModifyContactNotificationEmailInput;
};


export type MutationModifyContactExternalBankAccountArgs = {
  input: ModifyContactExternalBankAccountInput;
};


export type MutationArchiveContactArgs = {
  input: ArchiveContactInput;
};


export type MutationCreateInviteForBusinessMemberArgs = {
  input: CreateInviteForBusinessMemberInput;
};


export type MutationDeleteInviteForBusinessMemberArgs = {
  input: DeleteInviteForBusinessMemberInput;
};


export type MutationCreateInviteForContactArgs = {
  input: CreateInviteForContactInput;
};


export type MutationDeleteInviteForContactArgs = {
  input: DeleteInviteForContactInput;
};


export type MutationApplyInviteForContactWithBusinessArgs = {
  input: ApplyInviteForContactWithBusinessInput;
};


export type MutationCreateAppDepositArgs = {
  input: CreateAppDepositInput;
};


export type MutationCreateAppWithdrawArgs = {
  input: CreateAppWithdrawInput;
};


export type MutationCancelAppWithdrawArgs = {
  input: CancelAppWithdrawInput;
};


export type MutationCreateAppReserveArgs = {
  input: CreateAppReserveInput;
};


export type MutationResolveAppReserveArgs = {
  input: ResolveAppReserveInput;
};


export type MutationRefreshApiKeyArgs = {
  input: RefreshApiKeyInput;
};


export type MutationRunManualCronArgs = {
  input: RunManualCronInput;
};

export type BalancesFilter = {
  contactId?: Maybe<Scalars['ID']>;
  type?: Maybe<BalanceEventType>;
};

export type ApiKeyWithSecret = {
  apiKey: Scalars['ID'];
  apiSecret: Scalars['String'];
  expireAt?: Maybe<Scalars['DateTime']>;
};

export type ApiKey = {
  apiKey: Scalars['ID'];
  expireAt?: Maybe<Scalars['DateTime']>;
};

export type RefreshApiKeyInput = {
  expireAt?: Maybe<Scalars['DateTime']>;
};

export type RefreshApiKeyPayload = {
  result: MutationValidationResult;
  apiKey: ApiKeyWithSecret;
};

export type DeleteApiKeyPayload = {
  result: MutationValidationResult;
  apiKey: ApiKey;
};

export type App = {
  id: Scalars['ID'];
  name: MultilingualText;
};

export type AppDepositInBalance = {
  id: Scalars['ID'];
  app: App;
  amount: Scalars['Int'];
};

export type CreateAppDepositInput = {
  businessId: Scalars['ID'];
  amount: Scalars['Int'];
};

export type CreateAppDepositPayload = {
  result: MutationValidationResult;
  balance: Balance;
};

export type AppReserveInBalance = {
  id: Scalars['ID'];
  app: App;
  amount: Scalars['Int'];
  resolvedAt?: Maybe<Scalars['DateTime']>;
};

export type CreateAppReserveInput = {
  businessId: Scalars['ID'];
  amount: Scalars['Int'];
};

export type ResolveAppReserveInput = {
  businessId: Scalars['ID'];
  appReserveId: Scalars['ID'];
};

export type CreateAppReservePayload = {
  result: MutationValidationResult;
  balance: Balance;
};

export type ResolveAppReservePayload = {
  result: MutationValidationResult;
  balance: Balance;
};

export type AppWithdrawInBalance = {
  id: Scalars['ID'];
  app: App;
  amount: Scalars['Int'];
  canceledAt?: Maybe<Scalars['DateTime']>;
};

export type CreateAppWithdrawInput = {
  businessId: Scalars['ID'];
  amount: Scalars['Int'];
};

export type CancelAppWithdrawInput = {
  businessId: Scalars['ID'];
  appWithdrawId: Scalars['ID'];
};

export type CreateAppWithdrawPayload = {
  result: MutationValidationResult;
  balance: Balance;
};

export type CancelAppWithdrawPayload = {
  result: MutationValidationResult;
  balance: Balance;
};

export type Balance = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  currentAmount: Scalars['Int'];
  currentReserve: Scalars['Int'];
  amountIncrease: Scalars['Int'];
  reserveIncrease: Scalars['Int'];
  eventType: BalanceEventType;
  transfer?: Maybe<TransferInBalance>;
  charge?: Maybe<Charge>;
  appDeposit?: Maybe<AppDepositInBalance>;
  appWithdraw?: Maybe<AppWithdrawInBalance>;
  appReserve?: Maybe<AppReserveInBalance>;
};

export type BalanceEventType =
  | 'charge'
  | 'transferTry'
  | 'transferError'
  | 'transferIssue'
  | 'transferFundsReturn'
  | 'appDeposit'
  | 'appWithdraw'
  | 'appWithdrawCancel'
  | 'appReserveCreate'
  | 'appReserveResolve';

export type Business = {
  id: Scalars['ID'];
  name: Scalars['String'];
  notificationEmail: Scalars['String'];
  remitterNameKana: Scalars['String'];
  depositAccountFromMeToMe?: Maybe<DepositAccountInBusiness>;
  externalBankAccount?: Maybe<ExternalBankAccount>;
  language?: Maybe<Language>;
  isArchived: Scalars['Boolean'];
};

export type BusinessInContact = {
  id: Scalars['ID'];
  name: Scalars['String'];
  remitterNameKana: Scalars['String'];
  externalBankAccount?: Maybe<ExternalBankAccount>;
  depositAccountFromMeToContact?: Maybe<DepositAccountInContact>;
  language?: Maybe<Language>;
  isArchived: Scalars['Boolean'];
  relatedContactId: Scalars['String'];
};

export type BusinessInTransfer = {
  id: Scalars['ID'];
  name: Scalars['String'];
  language?: Maybe<Language>;
  isArchived: Scalars['Boolean'];
};

export type BusinessInInvite = {
  id: Scalars['ID'];
  name: Scalars['String'];
  language?: Maybe<Language>;
  isArchived: Scalars['Boolean'];
};

export type BusinessInBusinessMember = {
  id: Scalars['ID'];
  name: Scalars['String'];
  notificationEmail: Scalars['String'];
  language?: Maybe<Language>;
  isArchived: Scalars['Boolean'];
};

export type CreateBusinessInput = {
  name: Scalars['String'];
  remitterNameKana: Scalars['String'];
};

export type ModifyBusinessNameInput = {
  id: Scalars['ID'];
  oldValue: Scalars['String'];
  newValue: Scalars['String'];
  requiredInterval: Scalars['Int'];
};

export type ModifyBusinessNotificationEmailInput = {
  id: Scalars['ID'];
  oldValue: Scalars['String'];
  newValue: Scalars['String'];
  requiredInterval: Scalars['Int'];
};

export type ModifyBusinessRemitterNameKanaInput = {
  id: Scalars['ID'];
  oldValue: Scalars['String'];
  newValue: Scalars['String'];
  requiredInterval: Scalars['Int'];
};

export type ModifyBusinessExternalBankAccountInput = {
  id: Scalars['ID'];
  oldValue?: Maybe<ExternalBankAccountInput>;
  newValue?: Maybe<ExternalBankAccountInput>;
  requiredInterval: Scalars['Int'];
};

export type ModifyBusinessLanguageInput = {
  id: Scalars['ID'];
  oldValue?: Maybe<Language>;
  newValue?: Maybe<Language>;
  requiredInterval: Scalars['Int'];
};

export type ArchiveBusinessInput = {
  id: Scalars['ID'];
};

export type CreateBusinessPayload = {
  result: MutationValidationResult;
  business: Business;
  businessMember: BusinessMemberInBusiness;
};

export type ModifyBusinessNamePayload = {
  result: MutationValidationResult;
  business: Business;
};

export type ModifyBusinessNotificationEmailPayload = {
  result: MutationValidationResult;
  business: Business;
};

export type ModifyBusinessRemitterNameKanaPayload = {
  result: MutationValidationResult;
  business: Business;
};

export type ModifyBusinessExternalBankAccountPayload = {
  result: MutationValidationResult;
  business: Business;
};

export type ModifyBusinessLanguagePayload = {
  result: MutationValidationResult;
  business: Business;
};

export type ArchiveBusinessPayload = {
  result: MutationValidationResult;
  business: Business;
};

export type BusinessMember = {
  id: Scalars['ID'];
  role: BusinessMemberRole;
  user: UserInBusinessMember;
  business: BusinessInBusinessMember;
};

export type BusinessMemberInUser = {
  id: Scalars['ID'];
  role: BusinessMemberRole;
  business: BusinessInBusinessMember;
};

export type BusinessMemberInBusiness = {
  id: Scalars['ID'];
  role: BusinessMemberRole;
  user: UserInBusinessMember;
};

export type BusinessMemberRole =
  | 'owner'
  | 'general'
  | 'observer';

export type ModifyBusinessMemberRoleInput = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  oldValue: BusinessMemberRole;
  newValue: BusinessMemberRole;
  requiredInterval: Scalars['Int'];
};

export type DeleteBusinessMemberInput = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
};

export type ModifyBusinessMemberRolePayload = {
  result: MutationValidationResult;
  businessMember: BusinessMember;
};

export type DeleteBusinessMemberPayload = {
  result: MutationValidationResult;
  businessMember: BusinessMember;
};

export type Charge = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  amount: Scalars['Int'];
  remitterNameKana: Scalars['String'];
  depositAccount: DepositAccount;
  transfer?: Maybe<TransferInCharge>;
};

export type ContactWithInvite = {
  id: Scalars['ID'];
  additionalId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  notificationEmail?: Maybe<Scalars['String']>;
  externalBankAccount?: Maybe<ExternalBankAccount>;
  depositAccountFromContactToMe?: Maybe<DepositAccountInContact>;
  relatedBusiness?: Maybe<BusinessInContact>;
  invite?: Maybe<InviteForContactInContact>;
  isArchived: Scalars['Boolean'];
};

export type Contact = {
  id: Scalars['ID'];
  additionalId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  notificationEmail?: Maybe<Scalars['String']>;
  externalBankAccount?: Maybe<ExternalBankAccount>;
  depositAccountFromContactToMe?: Maybe<DepositAccountInContact>;
  relatedBusiness?: Maybe<BusinessInContact>;
  isArchived: Scalars['Boolean'];
};

export type ContactInDepositAccount = {
  id: Scalars['ID'];
  additionalId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  relatedBusiness?: Maybe<BusinessInTransfer>;
  isArchived: Scalars['Boolean'];
};

export type ContactInInvite = {
  id: Scalars['ID'];
  additionalId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type ContactInTransfer = {
  id: Scalars['ID'];
  additionalId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  relatedBusiness?: Maybe<BusinessInTransfer>;
  isArchived: Scalars['Boolean'];
};

export type CreateContactInput = {
  businessId: Scalars['ID'];
  additionalId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  notificationEmail?: Maybe<Scalars['String']>;
  externalBankAccount?: Maybe<ExternalBankAccountInput>;
};

export type AddContactAdditionalIdInput = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  additionalId: Scalars['ID'];
};

export type ModifyContactNameInput = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  oldValue: Scalars['String'];
  newValue: Scalars['String'];
  requiredInterval: Scalars['Int'];
};

export type ModifyContactNotificationEmailInput = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  oldValue?: Maybe<Scalars['String']>;
  newValue?: Maybe<Scalars['String']>;
  requiredInterval: Scalars['Int'];
};

export type ModifyContactExternalBankAccountInput = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  oldValue?: Maybe<ExternalBankAccountInput>;
  newValue?: Maybe<ExternalBankAccountInput>;
  requiredInterval: Scalars['Int'];
};

export type ArchiveContactInput = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
};

export type CreateContactPayload = {
  result: MutationValidationResult;
  contact: ContactWithInvite;
};

export type AddContactAdditionalIdPayload = {
  result: MutationValidationResult;
  contact: ContactWithInvite;
};

export type ModifyContactNamePayload = {
  result: MutationValidationResult;
  contact: ContactWithInvite;
};

export type ModifyContactNotificationEmailPayload = {
  result: MutationValidationResult;
  contact: ContactWithInvite;
};

export type ModifyContactExternalBankAccountPayload = {
  result: MutationValidationResult;
  contact: ContactWithInvite;
};

export type ArchiveContactPayload = {
  result: MutationValidationResult;
  contact: ContactWithInvite;
};

export type DepositAccount = {
  id: Scalars['ID'];
  bankCode: Scalars['String'];
  branchCode: Scalars['String'];
  accountNumber: Scalars['String'];
  accountHolderName: Scalars['String'];
  fromContact?: Maybe<ContactInDepositAccount>;
};

export type DepositAccountInContact = {
  id: Scalars['ID'];
  bankCode: Scalars['String'];
  branchCode: Scalars['String'];
  accountNumber: Scalars['String'];
  accountHolderName: Scalars['String'];
};

export type DepositAccountInBusiness = {
  id: Scalars['ID'];
  bankCode: Scalars['String'];
  branchCode: Scalars['String'];
  accountNumber: Scalars['String'];
  accountHolderName: Scalars['String'];
};

export type InviteForBusinessMember = {
  codeText: Scalars['String'];
  createdAt: Scalars['DateTime'];
  expireAt: Scalars['DateTime'];
  remainingSeats: Scalars['Int'];
  business: BusinessInInvite;
  role: BusinessMemberRole;
};

export type InviteForBusinessMemberInBusiness = {
  codeText: Scalars['String'];
  createdAt: Scalars['DateTime'];
  expireAt: Scalars['DateTime'];
  remainingSeats: Scalars['Int'];
  role: BusinessMemberRole;
};

export type CreateInviteForBusinessMemberInput = {
  businessId: Scalars['ID'];
  durationDays: Scalars['Int'];
  numberOfSeats: Scalars['Int'];
  role: BusinessMemberRole;
  email?: Maybe<Scalars['String']>;
};

export type DeleteInviteForBusinessMemberInput = {
  businessId: Scalars['ID'];
  inviteCodeText: Scalars['String'];
};

export type ApplyInviteForBusinessMemberInput = {
  businessId: Scalars['ID'];
  inviteCodeText: Scalars['String'];
};

export type CreateInviteForBusinessMemberPayload = {
  result: MutationValidationResult;
  inviteForBusinessMember: InviteForBusinessMember;
};

export type DeleteInviteForBusinessMemberPayload = {
  result: MutationValidationResult;
  inviteForBusinessMember: InviteForBusinessMember;
};

export type ApplyInviteForBusinessMemberPayload = {
  result: MutationValidationResult;
  inviteForBusinessMember: InviteForBusinessMember;
  businessMember: BusinessMemberInBusiness;
  business: Business;
};

export type InviteForContact = {
  codeText: Scalars['String'];
  createdAt: Scalars['DateTime'];
  expireAt: Scalars['DateTime'];
  business: BusinessInInvite;
  contact: ContactInInvite;
};

export type InviteForContactInContact = {
  codeText: Scalars['String'];
  createdAt: Scalars['DateTime'];
  expireAt: Scalars['DateTime'];
};

export type CreateInviteForContactInput = {
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
  durationDays: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
};

export type DeleteInviteForContactInput = {
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
};

export type ApplyInviteForContactWithBusinessInput = {
  requestBusinessId: Scalars['ID'];
  requestContactId?: Maybe<Scalars['ID']>;
  issuerBusinessId: Scalars['ID'];
  issuerContactId: Scalars['ID'];
  inviteCodeText: Scalars['String'];
};

export type ApplyInviteForContactWithSigningUpInput = {
  requestBusinessName: Scalars['String'];
  requestBusinessRemitterNameKana: Scalars['String'];
  issuerBusinessId: Scalars['ID'];
  issuerContactId: Scalars['ID'];
  inviteCodeText: Scalars['String'];
};

export type CreateInviteForContactPayload = {
  result: MutationValidationResult;
  inviteForContact: InviteForContact;
};

export type DeleteInviteForContactPayload = {
  result: MutationValidationResult;
  contact: Contact;
};

export type ApplyInviteForContactWithBusinessPayload = {
  result: MutationValidationResult;
  business: Business;
  contact: Contact;
};

export type ApplyInviteForContactWithSigningUpPayload = {
  result: MutationValidationResult;
  business: Business;
  contact: Contact;
};

export type Transfer = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  issuer: BusinessInTransfer;
  contact: ContactInTransfer;
  bankAccount: BankAccountInTransfer;
  amount: Scalars['Int'];
  feeOnIssuer: Fee;
  remitterNameKana: Scalars['String'];
  error?: Maybe<TransferError>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  operatorApp?: Maybe<App>;
};

export type TransferInBalance = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  contact: ContactInTransfer;
  bankAccount: BankAccountInTransfer;
  amount: Scalars['Int'];
  feeOnIssuer: Fee;
  remitterNameKana: Scalars['String'];
  error?: Maybe<TransferError>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  operatorApp?: Maybe<App>;
};

export type TransferInCharge = {
  id: Scalars['ID'];
};

export type SharedTransfer = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  issuer: BusinessInTransfer;
  contact: ContactInTransfer;
  bankAccount: BankAccountInTransfer;
  amount: Scalars['Int'];
  feeOnIssuer: Fee;
  remitterNameKana: Scalars['String'];
  error?: Maybe<TransferError>;
  issuedAt?: Maybe<Scalars['DateTime']>;
};

export type TransferError = {
  setAt: Scalars['DateTime'];
  code: TransferErrorCode;
  message?: Maybe<Scalars['String']>;
};

export type TransferErrorCode =
  | 'uodkaError'
  | 'bankProcessError'
  | 'inputError'
  | 'fundsReturned';

export type GetSharedTransferInput = {
  transferId: Scalars['ID'];
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
  codeText: Scalars['ID'];
};

export type GetIncomingTransferInput = {
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
  transferId: Scalars['ID'];
};

export type CreateTransferInput = {
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
  transferConfirmationId: Scalars['ID'];
};

export type GetSharedTransferPayload = {
  invite: InviteForContact;
  transfer: SharedTransfer;
};

export type GetIncomingTransferPayload = {
  charge?: Maybe<Charge>;
  transfer: SharedTransfer;
};

export type CreateTransferPayload = {
  result: MutationValidationResult;
  transfer: TransferInBalance;
};

export type TransferConfirmation = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  issuer: BusinessInTransfer;
  contact: ContactInTransfer;
  bankAccount: BankAccountInTransfer;
  amount: Scalars['Int'];
  feeOnIssuer: Fee;
  remitterNameKana: Scalars['String'];
  confirmationDueDate: Scalars['DateTime'];
  operatorApp?: Maybe<App>;
};

export type CreateTransferConfirmationInput = {
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
  amount: Scalars['Int'];
  remitterNameKana?: Maybe<Scalars['String']>;
};

export type CreateTransferConfirmationFlexInput = {
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
  amount: Scalars['Int'];
  remitterNameKana?: Maybe<Scalars['String']>;
  bankAccount: BankAccountInTransferInput;
};

export type DeleteTransferConfirmationInput = {
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
  id: Scalars['ID'];
};

export type CreateTransferConfirmationPayload = {
  result: MutationValidationResult;
  transferConfirmation: TransferConfirmation;
};

export type CreateTransferConfirmationFlexPayload = {
  result: MutationValidationResult;
  transferConfirmation: TransferConfirmation;
};

export type DeleteTransferConfirmationPayload = {
  result: MutationValidationResult;
  transferConfirmation: TransferConfirmation;
};

export type User = {
  id: Scalars['ID'];
  firebaseUid: Scalars['ID'];
  email: Scalars['String'];
  language?: Maybe<Language>;
};

export type UserInBusinessMember = {
  id: Scalars['ID'];
  email: Scalars['String'];
  language?: Maybe<Language>;
};

export type UserWithBusinessMembers = {
  id: Scalars['ID'];
  firebaseUid: Scalars['ID'];
  email: Scalars['String'];
  businessMembers: Array<BusinessMemberInUser>;
  language?: Maybe<Language>;
};

export type ModifyUserLanguageInput = {
  id: Scalars['ID'];
  oldValue?: Maybe<Language>;
  newValue?: Maybe<Language>;
  requiredInterval: Scalars['Int'];
};

export type ModifyUserLanguagePayload = {
  result: MutationValidationResult;
  user: User;
};

export type LegalTexts = {
  tos: Scalars['String'];
};

export type TransferFees = {
  internal: Fee;
  external: Fee;
};

export type Fees = {
  transfer: TransferFees;
};

export type Fee = {
  priceBeforeTax: Scalars['Int'];
  taxRate: Scalars['Float'];
  total: Scalars['Int'];
};

export type FeeInput = {
  priceBeforeTax: Scalars['Int'];
  taxRate: Scalars['Float'];
  total: Scalars['Int'];
};

export type ServiceHealthCode =
  | 'ok'
  | 'outOfService';

export type ServiceHealth = {
  code: ServiceHealthCode;
  message?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type MutationValidationResult =
  | 'success'
  | 'incorrectOldValue'
  | 'intervalTooShort';

export type Language =
  | 'en'
  | 'ja';

export type MultilingualText = {
  en: Scalars['String'];
  ja?: Maybe<Scalars['String']>;
};

export type ExternalBankAccount = {
  bankCode: Scalars['String'];
  branchCode: Scalars['String'];
  accountNumber: Scalars['String'];
  accountHolderName: Scalars['String'];
};

export type ExternalBankAccountInput = {
  bankCode: Scalars['String'];
  branchCode: Scalars['String'];
  accountNumber: Scalars['String'];
  accountHolderName: Scalars['String'];
};

export type BankAccountInTransfer = {
  isDepositAccount: Scalars['Boolean'];
  bankCode: Scalars['String'];
  branchCode: Scalars['String'];
  accountNumber: Scalars['String'];
  accountHolderName: Scalars['String'];
};

export type BankAccountInTransferInput = {
  isDepositAccount: Scalars['Boolean'];
  bankCode: Scalars['String'];
  branchCode: Scalars['String'];
  accountNumber: Scalars['String'];
  accountHolderName: Scalars['String'];
};

export type DeleteApiKeyMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteApiKeyMutation = { deleteApiKey: (
    Pick<DeleteApiKeyPayload, 'result'>
    & { apiKey: Pick<ApiKey, 'apiKey' | 'expireAt'> }
  ) };

export type RefreshApiKeyMutationVariables = Exact<{
  input: RefreshApiKeyInput;
}>;


export type RefreshApiKeyMutation = { refreshApiKey: (
    Pick<RefreshApiKeyPayload, 'result'>
    & { apiKey: Pick<ApiKeyWithSecret, 'apiKey' | 'apiSecret' | 'expireAt'> }
  ) };

export type CancelAppWithdrawMutationVariables = Exact<{
  input: CancelAppWithdrawInput;
}>;


export type CancelAppWithdrawMutation = { cancelAppWithdraw: (
    Pick<CancelAppWithdrawPayload, 'result'>
    & { balance: (
      Pick<Balance, 'id' | 'createdAt' | 'currentAmount' | 'currentReserve' | 'amountIncrease' | 'reserveIncrease' | 'eventType'>
      & { transfer?: Maybe<(
        Pick<TransferInBalance, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
        & { contact: (
          Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
          & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
        ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>>, operatorApp?: Maybe<(
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        )> }
      )>, charge?: Maybe<(
        Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
        & { depositAccount: (
          Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
          & { fromContact?: Maybe<(
            Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
            & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
          )> }
        ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
      )>, appDeposit?: Maybe<(
        Pick<AppDepositInBalance, 'id' | 'amount'>
        & { app: (
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        ) }
      )>, appWithdraw?: Maybe<(
        Pick<AppWithdrawInBalance, 'id' | 'amount' | 'canceledAt'>
        & { app: (
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        ) }
      )>, appReserve?: Maybe<(
        Pick<AppReserveInBalance, 'id' | 'amount' | 'resolvedAt'>
        & { app: (
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        ) }
      )> }
    ) }
  ) };

export type CreateAppDepositMutationVariables = Exact<{
  input: CreateAppDepositInput;
}>;


export type CreateAppDepositMutation = { createAppDeposit: (
    Pick<CreateAppDepositPayload, 'result'>
    & { balance: (
      Pick<Balance, 'id' | 'createdAt' | 'currentAmount' | 'currentReserve' | 'amountIncrease' | 'reserveIncrease' | 'eventType'>
      & { transfer?: Maybe<(
        Pick<TransferInBalance, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
        & { contact: (
          Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
          & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
        ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>>, operatorApp?: Maybe<(
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        )> }
      )>, charge?: Maybe<(
        Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
        & { depositAccount: (
          Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
          & { fromContact?: Maybe<(
            Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
            & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
          )> }
        ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
      )>, appDeposit?: Maybe<(
        Pick<AppDepositInBalance, 'id' | 'amount'>
        & { app: (
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        ) }
      )>, appWithdraw?: Maybe<(
        Pick<AppWithdrawInBalance, 'id' | 'amount' | 'canceledAt'>
        & { app: (
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        ) }
      )>, appReserve?: Maybe<(
        Pick<AppReserveInBalance, 'id' | 'amount' | 'resolvedAt'>
        & { app: (
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        ) }
      )> }
    ) }
  ) };

export type CreateAppReserveMutationVariables = Exact<{
  input: CreateAppReserveInput;
}>;


export type CreateAppReserveMutation = { createAppReserve: (
    Pick<CreateAppReservePayload, 'result'>
    & { balance: (
      Pick<Balance, 'id' | 'createdAt' | 'currentAmount' | 'currentReserve' | 'amountIncrease' | 'reserveIncrease' | 'eventType'>
      & { transfer?: Maybe<(
        Pick<TransferInBalance, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
        & { contact: (
          Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
          & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
        ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>>, operatorApp?: Maybe<(
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        )> }
      )>, charge?: Maybe<(
        Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
        & { depositAccount: (
          Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
          & { fromContact?: Maybe<(
            Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
            & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
          )> }
        ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
      )>, appDeposit?: Maybe<(
        Pick<AppDepositInBalance, 'id' | 'amount'>
        & { app: (
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        ) }
      )>, appWithdraw?: Maybe<(
        Pick<AppWithdrawInBalance, 'id' | 'amount' | 'canceledAt'>
        & { app: (
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        ) }
      )>, appReserve?: Maybe<(
        Pick<AppReserveInBalance, 'id' | 'amount' | 'resolvedAt'>
        & { app: (
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        ) }
      )> }
    ) }
  ) };

export type CreateAppWithdrawMutationVariables = Exact<{
  input: CreateAppWithdrawInput;
}>;


export type CreateAppWithdrawMutation = { createAppWithdraw: (
    Pick<CreateAppWithdrawPayload, 'result'>
    & { balance: (
      Pick<Balance, 'id' | 'createdAt' | 'currentAmount' | 'currentReserve' | 'amountIncrease' | 'reserveIncrease' | 'eventType'>
      & { transfer?: Maybe<(
        Pick<TransferInBalance, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
        & { contact: (
          Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
          & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
        ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>>, operatorApp?: Maybe<(
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        )> }
      )>, charge?: Maybe<(
        Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
        & { depositAccount: (
          Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
          & { fromContact?: Maybe<(
            Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
            & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
          )> }
        ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
      )>, appDeposit?: Maybe<(
        Pick<AppDepositInBalance, 'id' | 'amount'>
        & { app: (
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        ) }
      )>, appWithdraw?: Maybe<(
        Pick<AppWithdrawInBalance, 'id' | 'amount' | 'canceledAt'>
        & { app: (
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        ) }
      )>, appReserve?: Maybe<(
        Pick<AppReserveInBalance, 'id' | 'amount' | 'resolvedAt'>
        & { app: (
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        ) }
      )> }
    ) }
  ) };

export type ResolveAppReserveMutationVariables = Exact<{
  input: ResolveAppReserveInput;
}>;


export type ResolveAppReserveMutation = { resolveAppReserve: (
    Pick<ResolveAppReservePayload, 'result'>
    & { balance: (
      Pick<Balance, 'id' | 'createdAt' | 'currentAmount' | 'currentReserve' | 'amountIncrease' | 'reserveIncrease' | 'eventType'>
      & { transfer?: Maybe<(
        Pick<TransferInBalance, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
        & { contact: (
          Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
          & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
        ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>>, operatorApp?: Maybe<(
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        )> }
      )>, charge?: Maybe<(
        Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
        & { depositAccount: (
          Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
          & { fromContact?: Maybe<(
            Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
            & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
          )> }
        ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
      )>, appDeposit?: Maybe<(
        Pick<AppDepositInBalance, 'id' | 'amount'>
        & { app: (
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        ) }
      )>, appWithdraw?: Maybe<(
        Pick<AppWithdrawInBalance, 'id' | 'amount' | 'canceledAt'>
        & { app: (
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        ) }
      )>, appReserve?: Maybe<(
        Pick<AppReserveInBalance, 'id' | 'amount' | 'resolvedAt'>
        & { app: (
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        ) }
      )> }
    ) }
  ) };

export type RunAutoCron10minsMutationVariables = Exact<{ [key: string]: never; }>;


export type RunAutoCron10minsMutation = Pick<Mutation, 'runAutoCron10mins'>;

export type RunAutoCron1dayMutationVariables = Exact<{ [key: string]: never; }>;


export type RunAutoCron1dayMutation = Pick<Mutation, 'runAutoCron1day'>;

export type RunAutoCron6hoursMutationVariables = Exact<{ [key: string]: never; }>;


export type RunAutoCron6hoursMutation = Pick<Mutation, 'runAutoCron6hours'>;

export type ApplyInviteForBusinessMemberMutationVariables = Exact<{
  input: ApplyInviteForBusinessMemberInput;
}>;


export type ApplyInviteForBusinessMemberMutation = { applyInviteForBusinessMember: (
    Pick<ApplyInviteForBusinessMemberPayload, 'result'>
    & { inviteForBusinessMember: (
      Pick<InviteForBusinessMember, 'codeText' | 'createdAt' | 'expireAt' | 'remainingSeats' | 'role'>
      & { business: Pick<BusinessInInvite, 'id' | 'name' | 'language' | 'isArchived'> }
    ), businessMember: (
      Pick<BusinessMemberInBusiness, 'id' | 'role'>
      & { user: Pick<UserInBusinessMember, 'id' | 'email' | 'language'> }
    ), business: (
      Pick<Business, 'id' | 'name' | 'notificationEmail' | 'remitterNameKana' | 'language' | 'isArchived'>
      & { depositAccountFromMeToMe?: Maybe<Pick<DepositAccountInBusiness, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
    ) }
  ) };

export type ApplyInviteForContactWithSigningUpMutationVariables = Exact<{
  input: ApplyInviteForContactWithSigningUpInput;
}>;


export type ApplyInviteForContactWithSigningUpMutation = { applyInviteForContactWithSigningUp: (
    Pick<ApplyInviteForContactWithSigningUpPayload, 'result'>
    & { business: (
      Pick<Business, 'id' | 'name' | 'notificationEmail' | 'remitterNameKana' | 'language' | 'isArchived'>
      & { depositAccountFromMeToMe?: Maybe<Pick<DepositAccountInBusiness, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
    ), contact: (
      Pick<Contact, 'id' | 'additionalId' | 'name' | 'notificationEmail' | 'isArchived'>
      & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromContactToMe?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, relatedBusiness?: Maybe<(
        Pick<BusinessInContact, 'id' | 'name' | 'remitterNameKana' | 'language' | 'isArchived'>
        & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromMeToContact?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
      )> }
    ) }
  ) };

export type CreateTransferMutationVariables = Exact<{
  input: CreateTransferInput;
}>;


export type CreateTransferMutation = { createTransfer: (
    Pick<CreateTransferPayload, 'result'>
    & { transfer: (
      Pick<TransferInBalance, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
      & { contact: (
        Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
        & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
      ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>>, operatorApp?: Maybe<(
        Pick<App, 'id'>
        & { name: Pick<MultilingualText, 'en' | 'ja'> }
      )> }
    ) }
  ) };

export type CreateTransferConfirmationMutationVariables = Exact<{
  input: CreateTransferConfirmationInput;
}>;


export type CreateTransferConfirmationMutation = { createTransferConfirmation: (
    Pick<CreateTransferConfirmationPayload, 'result'>
    & { transferConfirmation: (
      Pick<TransferConfirmation, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'confirmationDueDate'>
      & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
        Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
        & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
      ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, operatorApp?: Maybe<(
        Pick<App, 'id'>
        & { name: Pick<MultilingualText, 'en' | 'ja'> }
      )> }
    ) }
  ) };

export type CreateTransferConfirmationFlexMutationVariables = Exact<{
  input: CreateTransferConfirmationFlexInput;
}>;


export type CreateTransferConfirmationFlexMutation = { createTransferConfirmationFlex: (
    Pick<CreateTransferConfirmationFlexPayload, 'result'>
    & { transferConfirmation: (
      Pick<TransferConfirmation, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'confirmationDueDate'>
      & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
        Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
        & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
      ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, operatorApp?: Maybe<(
        Pick<App, 'id'>
        & { name: Pick<MultilingualText, 'en' | 'ja'> }
      )> }
    ) }
  ) };

export type DeleteTransferConfirmationMutationVariables = Exact<{
  input: DeleteTransferConfirmationInput;
}>;


export type DeleteTransferConfirmationMutation = { deleteTransferConfirmation: (
    Pick<DeleteTransferConfirmationPayload, 'result'>
    & { transferConfirmation: (
      Pick<TransferConfirmation, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'confirmationDueDate'>
      & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
        Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
        & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
      ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, operatorApp?: Maybe<(
        Pick<App, 'id'>
        & { name: Pick<MultilingualText, 'en' | 'ja'> }
      )> }
    ) }
  ) };

export type AddContactAdditionalIdMutationVariables = Exact<{
  input: AddContactAdditionalIdInput;
}>;


export type AddContactAdditionalIdMutation = { addContactAdditionalId: (
    Pick<AddContactAdditionalIdPayload, 'result'>
    & { contact: (
      Pick<ContactWithInvite, 'id' | 'additionalId' | 'name' | 'notificationEmail' | 'isArchived'>
      & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromContactToMe?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, relatedBusiness?: Maybe<(
        Pick<BusinessInContact, 'id' | 'name' | 'remitterNameKana' | 'language' | 'isArchived' | 'relatedContactId'>
        & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromMeToContact?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
      )>, invite?: Maybe<Pick<InviteForContactInContact, 'codeText' | 'createdAt' | 'expireAt'>> }
    ) }
  ) };

export type ApplyInviteForContactWithBusinessMutationVariables = Exact<{
  input: ApplyInviteForContactWithBusinessInput;
}>;


export type ApplyInviteForContactWithBusinessMutation = { applyInviteForContactWithBusiness: (
    Pick<ApplyInviteForContactWithBusinessPayload, 'result'>
    & { business: (
      Pick<Business, 'id' | 'name' | 'notificationEmail' | 'remitterNameKana' | 'language' | 'isArchived'>
      & { depositAccountFromMeToMe?: Maybe<Pick<DepositAccountInBusiness, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
    ), contact: (
      Pick<Contact, 'id' | 'additionalId' | 'name' | 'notificationEmail' | 'isArchived'>
      & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromContactToMe?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, relatedBusiness?: Maybe<(
        Pick<BusinessInContact, 'id' | 'name' | 'remitterNameKana' | 'language' | 'isArchived' | 'relatedContactId'>
        & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromMeToContact?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
      )> }
    ) }
  ) };

export type ArchiveBusinessMutationVariables = Exact<{
  input: ArchiveBusinessInput;
}>;


export type ArchiveBusinessMutation = { archiveBusiness: (
    Pick<ArchiveBusinessPayload, 'result'>
    & { business: (
      Pick<Business, 'id' | 'name' | 'notificationEmail' | 'remitterNameKana' | 'language' | 'isArchived'>
      & { depositAccountFromMeToMe?: Maybe<Pick<DepositAccountInBusiness, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
    ) }
  ) };

export type ArchiveContactMutationVariables = Exact<{
  input: ArchiveContactInput;
}>;


export type ArchiveContactMutation = { archiveContact: (
    Pick<ArchiveContactPayload, 'result'>
    & { contact: (
      Pick<ContactWithInvite, 'id' | 'additionalId' | 'name' | 'notificationEmail' | 'isArchived'>
      & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromContactToMe?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, relatedBusiness?: Maybe<(
        Pick<BusinessInContact, 'id' | 'name' | 'remitterNameKana' | 'language' | 'isArchived' | 'relatedContactId'>
        & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromMeToContact?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
      )>, invite?: Maybe<Pick<InviteForContactInContact, 'codeText' | 'createdAt' | 'expireAt'>> }
    ) }
  ) };

export type CreateContactMutationVariables = Exact<{
  input: CreateContactInput;
}>;


export type CreateContactMutation = { createContact: (
    Pick<CreateContactPayload, 'result'>
    & { contact: (
      Pick<ContactWithInvite, 'id' | 'additionalId' | 'name' | 'notificationEmail' | 'isArchived'>
      & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromContactToMe?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, relatedBusiness?: Maybe<(
        Pick<BusinessInContact, 'id' | 'name' | 'remitterNameKana' | 'language' | 'isArchived' | 'relatedContactId'>
        & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromMeToContact?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
      )>, invite?: Maybe<Pick<InviteForContactInContact, 'codeText' | 'createdAt' | 'expireAt'>> }
    ) }
  ) };

export type CreateInviteForBusinessMemberMutationVariables = Exact<{
  input: CreateInviteForBusinessMemberInput;
}>;


export type CreateInviteForBusinessMemberMutation = { createInviteForBusinessMember: (
    Pick<CreateInviteForBusinessMemberPayload, 'result'>
    & { inviteForBusinessMember: (
      Pick<InviteForBusinessMember, 'codeText' | 'createdAt' | 'expireAt' | 'remainingSeats' | 'role'>
      & { business: Pick<BusinessInInvite, 'id' | 'name' | 'language' | 'isArchived'> }
    ) }
  ) };

export type CreateInviteForContactMutationVariables = Exact<{
  input: CreateInviteForContactInput;
}>;


export type CreateInviteForContactMutation = { createInviteForContact: (
    Pick<CreateInviteForContactPayload, 'result'>
    & { inviteForContact: (
      Pick<InviteForContact, 'codeText' | 'createdAt' | 'expireAt'>
      & { business: Pick<BusinessInInvite, 'id' | 'name' | 'language' | 'isArchived'>, contact: Pick<ContactInInvite, 'id' | 'additionalId' | 'name'> }
    ) }
  ) };

export type DeleteBusinessMemberMutationVariables = Exact<{
  input: DeleteBusinessMemberInput;
}>;


export type DeleteBusinessMemberMutation = { deleteBusinessMember: (
    Pick<DeleteBusinessMemberPayload, 'result'>
    & { businessMember: (
      Pick<BusinessMember, 'id' | 'role'>
      & { user: Pick<UserInBusinessMember, 'id' | 'email' | 'language'>, business: Pick<BusinessInBusinessMember, 'id' | 'name' | 'notificationEmail' | 'language' | 'isArchived'> }
    ) }
  ) };

export type DeleteInviteForBusinessMemberMutationVariables = Exact<{
  input: DeleteInviteForBusinessMemberInput;
}>;


export type DeleteInviteForBusinessMemberMutation = { deleteInviteForBusinessMember: (
    Pick<DeleteInviteForBusinessMemberPayload, 'result'>
    & { inviteForBusinessMember: (
      Pick<InviteForBusinessMember, 'codeText' | 'createdAt' | 'expireAt' | 'remainingSeats' | 'role'>
      & { business: Pick<BusinessInInvite, 'id' | 'name' | 'language' | 'isArchived'> }
    ) }
  ) };

export type DeleteInviteForContactMutationVariables = Exact<{
  input: DeleteInviteForContactInput;
}>;


export type DeleteInviteForContactMutation = { deleteInviteForContact: (
    Pick<DeleteInviteForContactPayload, 'result'>
    & { contact: (
      Pick<Contact, 'id' | 'additionalId' | 'name' | 'notificationEmail' | 'isArchived'>
      & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromContactToMe?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, relatedBusiness?: Maybe<(
        Pick<BusinessInContact, 'id' | 'name' | 'remitterNameKana' | 'language' | 'isArchived' | 'relatedContactId'>
        & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromMeToContact?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
      )> }
    ) }
  ) };

export type ModifyBusinessExternalBankAccountMutationVariables = Exact<{
  input: ModifyBusinessExternalBankAccountInput;
}>;


export type ModifyBusinessExternalBankAccountMutation = { modifyBusinessExternalBankAccount: (
    Pick<ModifyBusinessExternalBankAccountPayload, 'result'>
    & { business: (
      Pick<Business, 'id' | 'name' | 'notificationEmail' | 'remitterNameKana' | 'language' | 'isArchived'>
      & { depositAccountFromMeToMe?: Maybe<Pick<DepositAccountInBusiness, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
    ) }
  ) };

export type ModifyBusinessLanguageMutationVariables = Exact<{
  input: ModifyBusinessLanguageInput;
}>;


export type ModifyBusinessLanguageMutation = { modifyBusinessLanguage: (
    Pick<ModifyBusinessLanguagePayload, 'result'>
    & { business: (
      Pick<Business, 'id' | 'name' | 'notificationEmail' | 'remitterNameKana' | 'language' | 'isArchived'>
      & { depositAccountFromMeToMe?: Maybe<Pick<DepositAccountInBusiness, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
    ) }
  ) };

export type ModifyBusinessMemberRoleMutationVariables = Exact<{
  input: ModifyBusinessMemberRoleInput;
}>;


export type ModifyBusinessMemberRoleMutation = { modifyBusinessMemberRole: (
    Pick<ModifyBusinessMemberRolePayload, 'result'>
    & { businessMember: (
      Pick<BusinessMember, 'id' | 'role'>
      & { user: Pick<UserInBusinessMember, 'id' | 'email' | 'language'>, business: Pick<BusinessInBusinessMember, 'id' | 'name' | 'notificationEmail' | 'language' | 'isArchived'> }
    ) }
  ) };

export type ModifyBusinessNameMutationVariables = Exact<{
  input: ModifyBusinessNameInput;
}>;


export type ModifyBusinessNameMutation = { modifyBusinessName: (
    Pick<ModifyBusinessNamePayload, 'result'>
    & { business: (
      Pick<Business, 'id' | 'name' | 'notificationEmail' | 'remitterNameKana' | 'language' | 'isArchived'>
      & { depositAccountFromMeToMe?: Maybe<Pick<DepositAccountInBusiness, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
    ) }
  ) };

export type ModifyBusinessNotificationEmailMutationVariables = Exact<{
  input: ModifyBusinessNotificationEmailInput;
}>;


export type ModifyBusinessNotificationEmailMutation = { modifyBusinessNotificationEmail: (
    Pick<ModifyBusinessNotificationEmailPayload, 'result'>
    & { business: (
      Pick<Business, 'id' | 'name' | 'notificationEmail' | 'remitterNameKana' | 'language' | 'isArchived'>
      & { depositAccountFromMeToMe?: Maybe<Pick<DepositAccountInBusiness, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
    ) }
  ) };

export type ModifyBusinessRemitterNameKanaMutationVariables = Exact<{
  input: ModifyBusinessRemitterNameKanaInput;
}>;


export type ModifyBusinessRemitterNameKanaMutation = { modifyBusinessRemitterNameKana: (
    Pick<ModifyBusinessRemitterNameKanaPayload, 'result'>
    & { business: (
      Pick<Business, 'id' | 'name' | 'notificationEmail' | 'remitterNameKana' | 'language' | 'isArchived'>
      & { depositAccountFromMeToMe?: Maybe<Pick<DepositAccountInBusiness, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
    ) }
  ) };

export type ModifyContactExternalBankAccountMutationVariables = Exact<{
  input: ModifyContactExternalBankAccountInput;
}>;


export type ModifyContactExternalBankAccountMutation = { modifyContactExternalBankAccount: (
    Pick<ModifyContactExternalBankAccountPayload, 'result'>
    & { contact: (
      Pick<ContactWithInvite, 'id' | 'additionalId' | 'name' | 'notificationEmail' | 'isArchived'>
      & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromContactToMe?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, relatedBusiness?: Maybe<(
        Pick<BusinessInContact, 'id' | 'name' | 'remitterNameKana' | 'language' | 'isArchived' | 'relatedContactId'>
        & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromMeToContact?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
      )>, invite?: Maybe<Pick<InviteForContactInContact, 'codeText' | 'createdAt' | 'expireAt'>> }
    ) }
  ) };

export type ModifyContactNameMutationVariables = Exact<{
  input: ModifyContactNameInput;
}>;


export type ModifyContactNameMutation = { modifyContactName: (
    Pick<ModifyContactNamePayload, 'result'>
    & { contact: (
      Pick<ContactWithInvite, 'id' | 'additionalId' | 'name' | 'notificationEmail' | 'isArchived'>
      & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromContactToMe?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, relatedBusiness?: Maybe<(
        Pick<BusinessInContact, 'id' | 'name' | 'remitterNameKana' | 'language' | 'isArchived' | 'relatedContactId'>
        & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromMeToContact?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
      )>, invite?: Maybe<Pick<InviteForContactInContact, 'codeText' | 'createdAt' | 'expireAt'>> }
    ) }
  ) };

export type ModifyContactNotificationEmailMutationVariables = Exact<{
  input: ModifyContactNotificationEmailInput;
}>;


export type ModifyContactNotificationEmailMutation = { modifyContactNotificationEmail: (
    Pick<ModifyContactNotificationEmailPayload, 'result'>
    & { contact: (
      Pick<ContactWithInvite, 'id' | 'additionalId' | 'name' | 'notificationEmail' | 'isArchived'>
      & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromContactToMe?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, relatedBusiness?: Maybe<(
        Pick<BusinessInContact, 'id' | 'name' | 'remitterNameKana' | 'language' | 'isArchived' | 'relatedContactId'>
        & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromMeToContact?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
      )>, invite?: Maybe<Pick<InviteForContactInContact, 'codeText' | 'createdAt' | 'expireAt'>> }
    ) }
  ) };

export type RunManualCronMutationVariables = Exact<{
  input: RunManualCronInput;
}>;


export type RunManualCronMutation = Pick<Mutation, 'runManualCron'>;

export type CreateBusinessMutationVariables = Exact<{
  input: CreateBusinessInput;
}>;


export type CreateBusinessMutation = { createBusiness: (
    Pick<CreateBusinessPayload, 'result'>
    & { business: (
      Pick<Business, 'id' | 'name' | 'notificationEmail' | 'remitterNameKana' | 'language' | 'isArchived'>
      & { depositAccountFromMeToMe?: Maybe<Pick<DepositAccountInBusiness, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
    ), businessMember: (
      Pick<BusinessMemberInBusiness, 'id' | 'role'>
      & { user: Pick<UserInBusinessMember, 'id' | 'email' | 'language'> }
    ) }
  ) };

export type ModifyUserLanguageMutationVariables = Exact<{
  input: ModifyUserLanguageInput;
}>;


export type ModifyUserLanguageMutation = { modifyUserLanguage: (
    Pick<ModifyUserLanguagePayload, 'result'>
    & { user: Pick<User, 'id' | 'email' | 'language'> }
  ) };

export type BalancesQueryVariables = Exact<{
  businessId: Scalars['ID'];
  filter?: Maybe<BalancesFilter>;
}>;


export type BalancesQuery = { balances: Array<(
    Pick<Balance, 'id' | 'createdAt' | 'currentAmount' | 'currentReserve' | 'amountIncrease' | 'reserveIncrease' | 'eventType'>
    & { transfer?: Maybe<(
      Pick<TransferInBalance, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
      & { contact: (
        Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
        & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
      ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>>, operatorApp?: Maybe<(
        Pick<App, 'id'>
        & { name: Pick<MultilingualText, 'en' | 'ja'> }
      )> }
    )>, charge?: Maybe<(
      Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
      & { depositAccount: (
        Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
        & { fromContact?: Maybe<(
          Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
          & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
        )> }
      ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
    )>, appDeposit?: Maybe<(
      Pick<AppDepositInBalance, 'id' | 'amount'>
      & { app: (
        Pick<App, 'id'>
        & { name: Pick<MultilingualText, 'en' | 'ja'> }
      ) }
    )>, appWithdraw?: Maybe<(
      Pick<AppWithdrawInBalance, 'id' | 'amount' | 'canceledAt'>
      & { app: (
        Pick<App, 'id'>
        & { name: Pick<MultilingualText, 'en' | 'ja'> }
      ) }
    )>, appReserve?: Maybe<(
      Pick<AppReserveInBalance, 'id' | 'amount' | 'resolvedAt'>
      & { app: (
        Pick<App, 'id'>
        & { name: Pick<MultilingualText, 'en' | 'ja'> }
      ) }
    )> }
  )> };

export type BusinessQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BusinessQuery = { business: (
    Pick<Business, 'id' | 'name' | 'notificationEmail' | 'remitterNameKana' | 'language' | 'isArchived'>
    & { depositAccountFromMeToMe?: Maybe<Pick<DepositAccountInBusiness, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
  ) };

export type ChargeQueryVariables = Exact<{
  businessId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type ChargeQuery = { charge?: Maybe<(
    Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
    & { depositAccount: (
      Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
      & { fromContact?: Maybe<(
        Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
        & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
      )> }
    ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
  )> };

export type ContactQueryVariables = Exact<{
  businessId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type ContactQuery = { contact?: Maybe<(
    Pick<Contact, 'id' | 'additionalId' | 'name' | 'notificationEmail' | 'isArchived'>
    & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromContactToMe?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, relatedBusiness?: Maybe<(
      Pick<BusinessInContact, 'id' | 'name' | 'remitterNameKana' | 'language' | 'isArchived' | 'relatedContactId'>
      & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromMeToContact?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
    )> }
  )> };

export type ContactForRelatedBusinessIdQueryVariables = Exact<{
  businessId: Scalars['ID'];
  relatedBusinessId: Scalars['ID'];
}>;


export type ContactForRelatedBusinessIdQuery = { contactForRelatedBusinessId?: Maybe<(
    Pick<Contact, 'id' | 'additionalId' | 'name' | 'notificationEmail' | 'isArchived'>
    & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromContactToMe?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, relatedBusiness?: Maybe<(
      Pick<BusinessInContact, 'id' | 'name' | 'remitterNameKana' | 'language' | 'isArchived' | 'relatedContactId'>
      & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromMeToContact?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
    )> }
  )> };

export type ContactsQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type ContactsQuery = { contacts: Array<(
    Pick<Contact, 'id' | 'additionalId' | 'name' | 'notificationEmail' | 'isArchived'>
    & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromContactToMe?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, relatedBusiness?: Maybe<(
      Pick<BusinessInContact, 'id' | 'name' | 'remitterNameKana' | 'language' | 'isArchived' | 'relatedContactId'>
      & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromMeToContact?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
    )> }
  )> };

export type IncomingTransferQueryVariables = Exact<{
  input: GetIncomingTransferInput;
}>;


export type IncomingTransferQuery = { incomingTransfer: { charge?: Maybe<(
      Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
      & { depositAccount: (
        Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
        & { fromContact?: Maybe<(
          Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
          & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
        )> }
      ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
    )>, transfer: (
      Pick<SharedTransfer, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
      & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
        Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
        & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
      ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>> }
    ) } };

export type RecentBalanceQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type RecentBalanceQuery = { recentBalance?: Maybe<(
    Pick<Balance, 'id' | 'createdAt' | 'currentAmount' | 'currentReserve' | 'amountIncrease' | 'reserveIncrease' | 'eventType'>
    & { transfer?: Maybe<(
      Pick<TransferInBalance, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
      & { contact: (
        Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
        & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
      ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>>, operatorApp?: Maybe<(
        Pick<App, 'id'>
        & { name: Pick<MultilingualText, 'en' | 'ja'> }
      )> }
    )>, charge?: Maybe<(
      Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
      & { depositAccount: (
        Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
        & { fromContact?: Maybe<(
          Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
          & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
        )> }
      ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
    )>, appDeposit?: Maybe<(
      Pick<AppDepositInBalance, 'id' | 'amount'>
      & { app: (
        Pick<App, 'id'>
        & { name: Pick<MultilingualText, 'en' | 'ja'> }
      ) }
    )>, appWithdraw?: Maybe<(
      Pick<AppWithdrawInBalance, 'id' | 'amount' | 'canceledAt'>
      & { app: (
        Pick<App, 'id'>
        & { name: Pick<MultilingualText, 'en' | 'ja'> }
      ) }
    )>, appReserve?: Maybe<(
      Pick<AppReserveInBalance, 'id' | 'amount' | 'resolvedAt'>
      & { app: (
        Pick<App, 'id'>
        & { name: Pick<MultilingualText, 'en' | 'ja'> }
      ) }
    )> }
  )> };

export type TransferQueryVariables = Exact<{
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  isRefreshStatus?: Maybe<Scalars['Boolean']>;
}>;


export type TransferQuery = { transfer?: Maybe<(
    Pick<Transfer, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
    & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
      Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
      & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
    ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>>, operatorApp?: Maybe<(
      Pick<App, 'id'>
      & { name: Pick<MultilingualText, 'en' | 'ja'> }
    )> }
  )> };

export type TransferConfirmationQueryVariables = Exact<{
  businessId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type TransferConfirmationQuery = { transferConfirmation?: Maybe<(
    Pick<TransferConfirmation, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'confirmationDueDate'>
    & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
      Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
      & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
    ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, operatorApp?: Maybe<(
      Pick<App, 'id'>
      & { name: Pick<MultilingualText, 'en' | 'ja'> }
    )> }
  )> };

export type BusinessMembersInBusinessQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type BusinessMembersInBusinessQuery = { businessMembersInBusiness: Array<(
    Pick<BusinessMemberInBusiness, 'id' | 'role'>
    & { user: Pick<UserInBusinessMember, 'id' | 'email' | 'language'> }
  )> };

export type ContactWithInvitesQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type ContactWithInvitesQuery = { contactWithInvites: Array<(
    Pick<ContactWithInvite, 'id' | 'additionalId' | 'name' | 'notificationEmail' | 'isArchived'>
    & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromContactToMe?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, relatedBusiness?: Maybe<(
      Pick<BusinessInContact, 'id' | 'name' | 'remitterNameKana' | 'language' | 'isArchived' | 'relatedContactId'>
      & { externalBankAccount?: Maybe<Pick<ExternalBankAccount, 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>>, depositAccountFromMeToContact?: Maybe<Pick<DepositAccountInContact, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>> }
    )>, invite?: Maybe<Pick<InviteForContactInContact, 'codeText' | 'createdAt' | 'expireAt'>> }
  )> };

export type InviteForBusinessMembersQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type InviteForBusinessMembersQuery = { inviteForBusinessMembers: Array<Pick<InviteForBusinessMemberInBusiness, 'codeText' | 'createdAt' | 'expireAt' | 'remainingSeats' | 'role'>> };

export type FeesQueryVariables = Exact<{ [key: string]: never; }>;


export type FeesQuery = { fees: { transfer: { internal: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, external: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'> } } };

export type InviteForBusinessMemberQueryVariables = Exact<{
  businessId: Scalars['ID'];
  codeText: Scalars['ID'];
}>;


export type InviteForBusinessMemberQuery = { inviteForBusinessMember?: Maybe<(
    Pick<InviteForBusinessMember, 'codeText' | 'createdAt' | 'expireAt' | 'remainingSeats' | 'role'>
    & { business: Pick<BusinessInInvite, 'id' | 'name' | 'language' | 'isArchived'> }
  )> };

export type InviteForContactQueryVariables = Exact<{
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
  codeText: Scalars['ID'];
}>;


export type InviteForContactQuery = { inviteForContact?: Maybe<(
    Pick<InviteForContact, 'codeText' | 'createdAt' | 'expireAt'>
    & { business: Pick<BusinessInInvite, 'id' | 'name' | 'language' | 'isArchived'>, contact: Pick<ContactInInvite, 'id' | 'additionalId' | 'name'> }
  )> };

export type LegalTextsQueryVariables = Exact<{ [key: string]: never; }>;


export type LegalTextsQuery = { legalTexts: Pick<LegalTexts, 'tos'> };

export type ServiceHealthQueryVariables = Exact<{ [key: string]: never; }>;


export type ServiceHealthQuery = { serviceHealth: Pick<ServiceHealth, 'code' | 'message' | 'url'> };

export type SharedTransferQueryVariables = Exact<{
  input: GetSharedTransferInput;
}>;


export type SharedTransferQuery = { sharedTransfer: { invite: (
      Pick<InviteForContact, 'codeText' | 'createdAt' | 'expireAt'>
      & { business: Pick<BusinessInInvite, 'id' | 'name' | 'language' | 'isArchived'>, contact: Pick<ContactInInvite, 'id' | 'additionalId' | 'name'> }
    ), transfer: (
      Pick<SharedTransfer, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
      & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
        Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
        & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
      ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>> }
    ) } };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { user?: Maybe<Pick<User, 'id' | 'firebaseUid' | 'email' | 'language'>> };

export type UserWithBusinessMembersQueryVariables = Exact<{ [key: string]: never; }>;


export type UserWithBusinessMembersQuery = { userWithBusinessMembers?: Maybe<(
    Pick<UserWithBusinessMembers, 'id' | 'firebaseUid' | 'email' | 'language'>
    & { businessMembers: Array<(
      Pick<BusinessMemberInUser, 'id' | 'role'>
      & { business: Pick<BusinessInBusinessMember, 'id' | 'name' | 'notificationEmail' | 'language' | 'isArchived'> }
    )> }
  )> };

export type ApiKeyQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiKeyQuery = { apiKey?: Maybe<Pick<ApiKey, 'apiKey' | 'expireAt'>> };

export type BusinessMembersInUserQueryVariables = Exact<{ [key: string]: never; }>;


export type BusinessMembersInUserQuery = { businessMembersInUser: Array<(
    Pick<BusinessMemberInUser, 'id' | 'role'>
    & { business: Pick<BusinessInBusinessMember, 'id' | 'name' | 'notificationEmail' | 'language' | 'isArchived'> }
  )> };


export const DeleteApiKeyDocument = gql`
    mutation deleteApiKey {
  deleteApiKey {
    result
    apiKey {
      apiKey
      expireAt
    }
  }
}
    `;
export const RefreshApiKeyDocument = gql`
    mutation refreshApiKey($input: RefreshApiKeyInput!) {
  refreshApiKey(input: $input) {
    result
    apiKey {
      apiKey
      apiSecret
      expireAt
    }
  }
}
    `;
export const CancelAppWithdrawDocument = gql`
    mutation cancelAppWithdraw($input: CancelAppWithdrawInput!) {
  cancelAppWithdraw(input: $input) {
    result
    balance {
      id
      createdAt
      currentAmount
      currentReserve
      amountIncrease
      reserveIncrease
      eventType
      transfer {
        id
        createdAt
        contact {
          id
          additionalId
          name
          relatedBusiness {
            id
            name
            language
            isArchived
          }
          isArchived
        }
        bankAccount {
          isDepositAccount
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        amount
        feeOnIssuer {
          priceBeforeTax
          taxRate
          total
        }
        remitterNameKana
        error {
          setAt
          code
          message
        }
        issuedAt
        operatorApp {
          id
          name {
            en
            ja
          }
        }
      }
      charge {
        id
        createdAt
        amount
        remitterNameKana
        depositAccount {
          id
          bankCode
          branchCode
          accountNumber
          accountHolderName
          fromContact {
            id
            additionalId
            name
            relatedBusiness {
              id
              name
              language
              isArchived
            }
            isArchived
          }
        }
        transfer {
          id
        }
      }
      appDeposit {
        id
        app {
          id
          name {
            en
            ja
          }
        }
        amount
      }
      appWithdraw {
        id
        app {
          id
          name {
            en
            ja
          }
        }
        amount
        canceledAt
      }
      appReserve {
        id
        app {
          id
          name {
            en
            ja
          }
        }
        amount
        resolvedAt
      }
    }
  }
}
    `;
export const CreateAppDepositDocument = gql`
    mutation createAppDeposit($input: CreateAppDepositInput!) {
  createAppDeposit(input: $input) {
    result
    balance {
      id
      createdAt
      currentAmount
      currentReserve
      amountIncrease
      reserveIncrease
      eventType
      transfer {
        id
        createdAt
        contact {
          id
          additionalId
          name
          relatedBusiness {
            id
            name
            language
            isArchived
          }
          isArchived
        }
        bankAccount {
          isDepositAccount
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        amount
        feeOnIssuer {
          priceBeforeTax
          taxRate
          total
        }
        remitterNameKana
        error {
          setAt
          code
          message
        }
        issuedAt
        operatorApp {
          id
          name {
            en
            ja
          }
        }
      }
      charge {
        id
        createdAt
        amount
        remitterNameKana
        depositAccount {
          id
          bankCode
          branchCode
          accountNumber
          accountHolderName
          fromContact {
            id
            additionalId
            name
            relatedBusiness {
              id
              name
              language
              isArchived
            }
            isArchived
          }
        }
        transfer {
          id
        }
      }
      appDeposit {
        id
        app {
          id
          name {
            en
            ja
          }
        }
        amount
      }
      appWithdraw {
        id
        app {
          id
          name {
            en
            ja
          }
        }
        amount
        canceledAt
      }
      appReserve {
        id
        app {
          id
          name {
            en
            ja
          }
        }
        amount
        resolvedAt
      }
    }
  }
}
    `;
export const CreateAppReserveDocument = gql`
    mutation createAppReserve($input: CreateAppReserveInput!) {
  createAppReserve(input: $input) {
    result
    balance {
      id
      createdAt
      currentAmount
      currentReserve
      amountIncrease
      reserveIncrease
      eventType
      transfer {
        id
        createdAt
        contact {
          id
          additionalId
          name
          relatedBusiness {
            id
            name
            language
            isArchived
          }
          isArchived
        }
        bankAccount {
          isDepositAccount
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        amount
        feeOnIssuer {
          priceBeforeTax
          taxRate
          total
        }
        remitterNameKana
        error {
          setAt
          code
          message
        }
        issuedAt
        operatorApp {
          id
          name {
            en
            ja
          }
        }
      }
      charge {
        id
        createdAt
        amount
        remitterNameKana
        depositAccount {
          id
          bankCode
          branchCode
          accountNumber
          accountHolderName
          fromContact {
            id
            additionalId
            name
            relatedBusiness {
              id
              name
              language
              isArchived
            }
            isArchived
          }
        }
        transfer {
          id
        }
      }
      appDeposit {
        id
        app {
          id
          name {
            en
            ja
          }
        }
        amount
      }
      appWithdraw {
        id
        app {
          id
          name {
            en
            ja
          }
        }
        amount
        canceledAt
      }
      appReserve {
        id
        app {
          id
          name {
            en
            ja
          }
        }
        amount
        resolvedAt
      }
    }
  }
}
    `;
export const CreateAppWithdrawDocument = gql`
    mutation createAppWithdraw($input: CreateAppWithdrawInput!) {
  createAppWithdraw(input: $input) {
    result
    balance {
      id
      createdAt
      currentAmount
      currentReserve
      amountIncrease
      reserveIncrease
      eventType
      transfer {
        id
        createdAt
        contact {
          id
          additionalId
          name
          relatedBusiness {
            id
            name
            language
            isArchived
          }
          isArchived
        }
        bankAccount {
          isDepositAccount
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        amount
        feeOnIssuer {
          priceBeforeTax
          taxRate
          total
        }
        remitterNameKana
        error {
          setAt
          code
          message
        }
        issuedAt
        operatorApp {
          id
          name {
            en
            ja
          }
        }
      }
      charge {
        id
        createdAt
        amount
        remitterNameKana
        depositAccount {
          id
          bankCode
          branchCode
          accountNumber
          accountHolderName
          fromContact {
            id
            additionalId
            name
            relatedBusiness {
              id
              name
              language
              isArchived
            }
            isArchived
          }
        }
        transfer {
          id
        }
      }
      appDeposit {
        id
        app {
          id
          name {
            en
            ja
          }
        }
        amount
      }
      appWithdraw {
        id
        app {
          id
          name {
            en
            ja
          }
        }
        amount
        canceledAt
      }
      appReserve {
        id
        app {
          id
          name {
            en
            ja
          }
        }
        amount
        resolvedAt
      }
    }
  }
}
    `;
export const ResolveAppReserveDocument = gql`
    mutation resolveAppReserve($input: ResolveAppReserveInput!) {
  resolveAppReserve(input: $input) {
    result
    balance {
      id
      createdAt
      currentAmount
      currentReserve
      amountIncrease
      reserveIncrease
      eventType
      transfer {
        id
        createdAt
        contact {
          id
          additionalId
          name
          relatedBusiness {
            id
            name
            language
            isArchived
          }
          isArchived
        }
        bankAccount {
          isDepositAccount
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        amount
        feeOnIssuer {
          priceBeforeTax
          taxRate
          total
        }
        remitterNameKana
        error {
          setAt
          code
          message
        }
        issuedAt
        operatorApp {
          id
          name {
            en
            ja
          }
        }
      }
      charge {
        id
        createdAt
        amount
        remitterNameKana
        depositAccount {
          id
          bankCode
          branchCode
          accountNumber
          accountHolderName
          fromContact {
            id
            additionalId
            name
            relatedBusiness {
              id
              name
              language
              isArchived
            }
            isArchived
          }
        }
        transfer {
          id
        }
      }
      appDeposit {
        id
        app {
          id
          name {
            en
            ja
          }
        }
        amount
      }
      appWithdraw {
        id
        app {
          id
          name {
            en
            ja
          }
        }
        amount
        canceledAt
      }
      appReserve {
        id
        app {
          id
          name {
            en
            ja
          }
        }
        amount
        resolvedAt
      }
    }
  }
}
    `;
export const RunAutoCron10minsDocument = gql`
    mutation runAutoCron10mins {
  runAutoCron10mins
}
    `;
export const RunAutoCron1dayDocument = gql`
    mutation runAutoCron1day {
  runAutoCron1day
}
    `;
export const RunAutoCron6hoursDocument = gql`
    mutation runAutoCron6hours {
  runAutoCron6hours
}
    `;
export const ApplyInviteForBusinessMemberDocument = gql`
    mutation applyInviteForBusinessMember($input: ApplyInviteForBusinessMemberInput!) {
  applyInviteForBusinessMember(input: $input) {
    result
    inviteForBusinessMember {
      codeText
      createdAt
      expireAt
      remainingSeats
      business {
        id
        name
        language
        isArchived
      }
      role
    }
    businessMember {
      id
      user {
        id
        email
        language
      }
      role
    }
    business {
      id
      name
      notificationEmail
      remitterNameKana
      depositAccountFromMeToMe {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      language
      isArchived
    }
  }
}
    `;
export const ApplyInviteForContactWithSigningUpDocument = gql`
    mutation applyInviteForContactWithSigningUp($input: ApplyInviteForContactWithSigningUpInput!) {
  applyInviteForContactWithSigningUp(input: $input) {
    result
    business {
      id
      name
      notificationEmail
      remitterNameKana
      depositAccountFromMeToMe {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      language
      isArchived
    }
    contact {
      id
      additionalId
      name
      notificationEmail
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      depositAccountFromContactToMe {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      relatedBusiness {
        id
        name
        remitterNameKana
        externalBankAccount {
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        depositAccountFromMeToContact {
          id
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        language
        isArchived
      }
      isArchived
    }
  }
}
    `;
export const CreateTransferDocument = gql`
    mutation createTransfer($input: CreateTransferInput!) {
  createTransfer(input: $input) {
    result
    transfer {
      id
      createdAt
      contact {
        id
        additionalId
        name
        relatedBusiness {
          id
          name
          language
          isArchived
        }
        isArchived
      }
      bankAccount {
        isDepositAccount
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      amount
      feeOnIssuer {
        priceBeforeTax
        taxRate
        total
      }
      remitterNameKana
      error {
        setAt
        code
        message
      }
      issuedAt
      operatorApp {
        id
        name {
          en
          ja
        }
      }
    }
  }
}
    `;
export const CreateTransferConfirmationDocument = gql`
    mutation createTransferConfirmation($input: CreateTransferConfirmationInput!) {
  createTransferConfirmation(input: $input) {
    result
    transferConfirmation {
      id
      createdAt
      issuer {
        id
        name
        language
        isArchived
      }
      contact {
        id
        additionalId
        name
        relatedBusiness {
          id
          name
          language
          isArchived
        }
        isArchived
      }
      bankAccount {
        isDepositAccount
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      amount
      feeOnIssuer {
        priceBeforeTax
        taxRate
        total
      }
      remitterNameKana
      confirmationDueDate
      operatorApp {
        id
        name {
          en
          ja
        }
      }
    }
  }
}
    `;
export const CreateTransferConfirmationFlexDocument = gql`
    mutation createTransferConfirmationFlex($input: CreateTransferConfirmationFlexInput!) {
  createTransferConfirmationFlex(input: $input) {
    result
    transferConfirmation {
      id
      createdAt
      issuer {
        id
        name
        language
        isArchived
      }
      contact {
        id
        additionalId
        name
        relatedBusiness {
          id
          name
          language
          isArchived
        }
        isArchived
      }
      bankAccount {
        isDepositAccount
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      amount
      feeOnIssuer {
        priceBeforeTax
        taxRate
        total
      }
      remitterNameKana
      confirmationDueDate
      operatorApp {
        id
        name {
          en
          ja
        }
      }
    }
  }
}
    `;
export const DeleteTransferConfirmationDocument = gql`
    mutation deleteTransferConfirmation($input: DeleteTransferConfirmationInput!) {
  deleteTransferConfirmation(input: $input) {
    result
    transferConfirmation {
      id
      createdAt
      issuer {
        id
        name
        language
        isArchived
      }
      contact {
        id
        additionalId
        name
        relatedBusiness {
          id
          name
          language
          isArchived
        }
        isArchived
      }
      bankAccount {
        isDepositAccount
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      amount
      feeOnIssuer {
        priceBeforeTax
        taxRate
        total
      }
      remitterNameKana
      confirmationDueDate
      operatorApp {
        id
        name {
          en
          ja
        }
      }
    }
  }
}
    `;
export const AddContactAdditionalIdDocument = gql`
    mutation addContactAdditionalId($input: AddContactAdditionalIdInput!) {
  addContactAdditionalId(input: $input) {
    result
    contact {
      id
      additionalId
      name
      notificationEmail
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      depositAccountFromContactToMe {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      relatedBusiness {
        id
        name
        remitterNameKana
        externalBankAccount {
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        depositAccountFromMeToContact {
          id
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        language
        isArchived
        relatedContactId
      }
      invite {
        codeText
        createdAt
        expireAt
      }
      isArchived
    }
  }
}
    `;
export const ApplyInviteForContactWithBusinessDocument = gql`
    mutation applyInviteForContactWithBusiness($input: ApplyInviteForContactWithBusinessInput!) {
  applyInviteForContactWithBusiness(input: $input) {
    result
    business {
      id
      name
      notificationEmail
      remitterNameKana
      depositAccountFromMeToMe {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      language
      isArchived
    }
    contact {
      id
      additionalId
      name
      notificationEmail
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      depositAccountFromContactToMe {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      relatedBusiness {
        id
        name
        remitterNameKana
        externalBankAccount {
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        depositAccountFromMeToContact {
          id
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        language
        isArchived
        relatedContactId
      }
      isArchived
    }
  }
}
    `;
export const ArchiveBusinessDocument = gql`
    mutation archiveBusiness($input: ArchiveBusinessInput!) {
  archiveBusiness(input: $input) {
    result
    business {
      id
      name
      notificationEmail
      remitterNameKana
      depositAccountFromMeToMe {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      language
      isArchived
    }
  }
}
    `;
export const ArchiveContactDocument = gql`
    mutation archiveContact($input: ArchiveContactInput!) {
  archiveContact(input: $input) {
    result
    contact {
      id
      additionalId
      name
      notificationEmail
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      depositAccountFromContactToMe {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      relatedBusiness {
        id
        name
        remitterNameKana
        externalBankAccount {
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        depositAccountFromMeToContact {
          id
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        language
        isArchived
        relatedContactId
      }
      invite {
        codeText
        createdAt
        expireAt
      }
      isArchived
    }
  }
}
    `;
export const CreateContactDocument = gql`
    mutation createContact($input: CreateContactInput!) {
  createContact(input: $input) {
    result
    contact {
      id
      additionalId
      name
      notificationEmail
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      depositAccountFromContactToMe {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      relatedBusiness {
        id
        name
        remitterNameKana
        externalBankAccount {
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        depositAccountFromMeToContact {
          id
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        language
        isArchived
        relatedContactId
      }
      invite {
        codeText
        createdAt
        expireAt
      }
      isArchived
    }
  }
}
    `;
export const CreateInviteForBusinessMemberDocument = gql`
    mutation createInviteForBusinessMember($input: CreateInviteForBusinessMemberInput!) {
  createInviteForBusinessMember(input: $input) {
    result
    inviteForBusinessMember {
      codeText
      createdAt
      expireAt
      remainingSeats
      business {
        id
        name
        language
        isArchived
      }
      role
    }
  }
}
    `;
export const CreateInviteForContactDocument = gql`
    mutation createInviteForContact($input: CreateInviteForContactInput!) {
  createInviteForContact(input: $input) {
    result
    inviteForContact {
      codeText
      createdAt
      expireAt
      business {
        id
        name
        language
        isArchived
      }
      contact {
        id
        additionalId
        name
      }
    }
  }
}
    `;
export const DeleteBusinessMemberDocument = gql`
    mutation deleteBusinessMember($input: DeleteBusinessMemberInput!) {
  deleteBusinessMember(input: $input) {
    result
    businessMember {
      id
      role
      user {
        id
        email
        language
      }
      business {
        id
        name
        notificationEmail
        language
        isArchived
      }
    }
  }
}
    `;
export const DeleteInviteForBusinessMemberDocument = gql`
    mutation deleteInviteForBusinessMember($input: DeleteInviteForBusinessMemberInput!) {
  deleteInviteForBusinessMember(input: $input) {
    result
    inviteForBusinessMember {
      codeText
      createdAt
      expireAt
      remainingSeats
      business {
        id
        name
        language
        isArchived
      }
      role
    }
  }
}
    `;
export const DeleteInviteForContactDocument = gql`
    mutation deleteInviteForContact($input: DeleteInviteForContactInput!) {
  deleteInviteForContact(input: $input) {
    result
    contact {
      id
      additionalId
      name
      notificationEmail
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      depositAccountFromContactToMe {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      relatedBusiness {
        id
        name
        remitterNameKana
        externalBankAccount {
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        depositAccountFromMeToContact {
          id
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        language
        isArchived
        relatedContactId
      }
      isArchived
    }
  }
}
    `;
export const ModifyBusinessExternalBankAccountDocument = gql`
    mutation modifyBusinessExternalBankAccount($input: ModifyBusinessExternalBankAccountInput!) {
  modifyBusinessExternalBankAccount(input: $input) {
    result
    business {
      id
      name
      notificationEmail
      remitterNameKana
      depositAccountFromMeToMe {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      language
      isArchived
    }
  }
}
    `;
export const ModifyBusinessLanguageDocument = gql`
    mutation modifyBusinessLanguage($input: ModifyBusinessLanguageInput!) {
  modifyBusinessLanguage(input: $input) {
    result
    business {
      id
      name
      notificationEmail
      remitterNameKana
      depositAccountFromMeToMe {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      language
      isArchived
    }
  }
}
    `;
export const ModifyBusinessMemberRoleDocument = gql`
    mutation modifyBusinessMemberRole($input: ModifyBusinessMemberRoleInput!) {
  modifyBusinessMemberRole(input: $input) {
    result
    businessMember {
      id
      role
      user {
        id
        email
        language
      }
      business {
        id
        name
        notificationEmail
        language
        isArchived
      }
    }
  }
}
    `;
export const ModifyBusinessNameDocument = gql`
    mutation modifyBusinessName($input: ModifyBusinessNameInput!) {
  modifyBusinessName(input: $input) {
    result
    business {
      id
      name
      notificationEmail
      remitterNameKana
      depositAccountFromMeToMe {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      language
      isArchived
    }
  }
}
    `;
export const ModifyBusinessNotificationEmailDocument = gql`
    mutation modifyBusinessNotificationEmail($input: ModifyBusinessNotificationEmailInput!) {
  modifyBusinessNotificationEmail(input: $input) {
    result
    business {
      id
      name
      notificationEmail
      remitterNameKana
      depositAccountFromMeToMe {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      language
      isArchived
    }
  }
}
    `;
export const ModifyBusinessRemitterNameKanaDocument = gql`
    mutation modifyBusinessRemitterNameKana($input: ModifyBusinessRemitterNameKanaInput!) {
  modifyBusinessRemitterNameKana(input: $input) {
    result
    business {
      id
      name
      notificationEmail
      remitterNameKana
      depositAccountFromMeToMe {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      language
      isArchived
    }
  }
}
    `;
export const ModifyContactExternalBankAccountDocument = gql`
    mutation modifyContactExternalBankAccount($input: ModifyContactExternalBankAccountInput!) {
  modifyContactExternalBankAccount(input: $input) {
    result
    contact {
      id
      additionalId
      name
      notificationEmail
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      depositAccountFromContactToMe {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      relatedBusiness {
        id
        name
        remitterNameKana
        externalBankAccount {
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        depositAccountFromMeToContact {
          id
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        language
        isArchived
        relatedContactId
      }
      invite {
        codeText
        createdAt
        expireAt
      }
      isArchived
    }
  }
}
    `;
export const ModifyContactNameDocument = gql`
    mutation modifyContactName($input: ModifyContactNameInput!) {
  modifyContactName(input: $input) {
    result
    contact {
      id
      additionalId
      name
      notificationEmail
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      depositAccountFromContactToMe {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      relatedBusiness {
        id
        name
        remitterNameKana
        externalBankAccount {
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        depositAccountFromMeToContact {
          id
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        language
        isArchived
        relatedContactId
      }
      invite {
        codeText
        createdAt
        expireAt
      }
      isArchived
    }
  }
}
    `;
export const ModifyContactNotificationEmailDocument = gql`
    mutation modifyContactNotificationEmail($input: ModifyContactNotificationEmailInput!) {
  modifyContactNotificationEmail(input: $input) {
    result
    contact {
      id
      additionalId
      name
      notificationEmail
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      depositAccountFromContactToMe {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      relatedBusiness {
        id
        name
        remitterNameKana
        externalBankAccount {
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        depositAccountFromMeToContact {
          id
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        language
        isArchived
        relatedContactId
      }
      invite {
        codeText
        createdAt
        expireAt
      }
      isArchived
    }
  }
}
    `;
export const RunManualCronDocument = gql`
    mutation runManualCron($input: RunManualCronInput!) {
  runManualCron(input: $input)
}
    `;
export const CreateBusinessDocument = gql`
    mutation createBusiness($input: CreateBusinessInput!) {
  createBusiness(input: $input) {
    result
    business {
      id
      name
      notificationEmail
      remitterNameKana
      depositAccountFromMeToMe {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      language
      isArchived
    }
    businessMember {
      id
      role
      user {
        id
        email
        language
      }
    }
  }
}
    `;
export const ModifyUserLanguageDocument = gql`
    mutation modifyUserLanguage($input: ModifyUserLanguageInput!) {
  modifyUserLanguage(input: $input) {
    result
    user {
      id
      email
      language
    }
  }
}
    `;
export const BalancesDocument = gql`
    query balances($businessId: ID!, $filter: BalancesFilter) {
  balances(businessId: $businessId, filter: $filter) {
    id
    createdAt
    currentAmount
    currentReserve
    amountIncrease
    reserveIncrease
    eventType
    transfer {
      id
      createdAt
      contact {
        id
        additionalId
        name
        relatedBusiness {
          id
          name
          language
          isArchived
        }
        isArchived
      }
      bankAccount {
        isDepositAccount
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      amount
      feeOnIssuer {
        priceBeforeTax
        taxRate
        total
      }
      remitterNameKana
      error {
        setAt
        code
        message
      }
      issuedAt
      operatorApp {
        id
        name {
          en
          ja
        }
      }
    }
    charge {
      id
      createdAt
      amount
      remitterNameKana
      depositAccount {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
        fromContact {
          id
          additionalId
          name
          relatedBusiness {
            id
            name
            language
            isArchived
          }
          isArchived
        }
      }
      transfer {
        id
      }
    }
    appDeposit {
      id
      app {
        id
        name {
          en
          ja
        }
      }
      amount
    }
    appWithdraw {
      id
      app {
        id
        name {
          en
          ja
        }
      }
      amount
      canceledAt
    }
    appReserve {
      id
      app {
        id
        name {
          en
          ja
        }
      }
      amount
      resolvedAt
    }
  }
}
    `;
export const BusinessDocument = gql`
    query business($id: ID!) {
  business(id: $id) {
    id
    name
    notificationEmail
    remitterNameKana
    depositAccountFromMeToMe {
      id
      bankCode
      branchCode
      accountNumber
      accountHolderName
    }
    externalBankAccount {
      bankCode
      branchCode
      accountNumber
      accountHolderName
    }
    language
    isArchived
  }
}
    `;
export const ChargeDocument = gql`
    query charge($businessId: ID!, $id: ID!) {
  charge(businessId: $businessId, id: $id) {
    id
    createdAt
    amount
    remitterNameKana
    depositAccount {
      id
      bankCode
      branchCode
      accountNumber
      accountHolderName
      fromContact {
        id
        additionalId
        name
        relatedBusiness {
          id
          name
          language
          isArchived
        }
        isArchived
      }
    }
    transfer {
      id
    }
  }
}
    `;
export const ContactDocument = gql`
    query contact($businessId: ID!, $id: ID!) {
  contact(businessId: $businessId, id: $id) {
    id
    additionalId
    name
    notificationEmail
    externalBankAccount {
      bankCode
      branchCode
      accountNumber
      accountHolderName
    }
    depositAccountFromContactToMe {
      id
      bankCode
      branchCode
      accountNumber
      accountHolderName
    }
    relatedBusiness {
      id
      name
      remitterNameKana
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      depositAccountFromMeToContact {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      language
      isArchived
      relatedContactId
    }
    isArchived
  }
}
    `;
export const ContactForRelatedBusinessIdDocument = gql`
    query contactForRelatedBusinessId($businessId: ID!, $relatedBusinessId: ID!) {
  contactForRelatedBusinessId(
    businessId: $businessId
    relatedBusinessId: $relatedBusinessId
  ) {
    id
    additionalId
    name
    notificationEmail
    externalBankAccount {
      bankCode
      branchCode
      accountNumber
      accountHolderName
    }
    depositAccountFromContactToMe {
      id
      bankCode
      branchCode
      accountNumber
      accountHolderName
    }
    relatedBusiness {
      id
      name
      remitterNameKana
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      depositAccountFromMeToContact {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      language
      isArchived
      relatedContactId
    }
    isArchived
  }
}
    `;
export const ContactsDocument = gql`
    query contacts($businessId: ID!) {
  contacts(businessId: $businessId) {
    id
    additionalId
    name
    notificationEmail
    externalBankAccount {
      bankCode
      branchCode
      accountNumber
      accountHolderName
    }
    depositAccountFromContactToMe {
      id
      bankCode
      branchCode
      accountNumber
      accountHolderName
    }
    relatedBusiness {
      id
      name
      remitterNameKana
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      depositAccountFromMeToContact {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      language
      isArchived
      relatedContactId
    }
    isArchived
  }
}
    `;
export const IncomingTransferDocument = gql`
    query incomingTransfer($input: GetIncomingTransferInput!) {
  incomingTransfer(input: $input) {
    charge {
      id
      createdAt
      amount
      remitterNameKana
      depositAccount {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
        fromContact {
          id
          additionalId
          name
          relatedBusiness {
            id
            name
            language
            isArchived
          }
          isArchived
        }
      }
      transfer {
        id
      }
    }
    transfer {
      id
      createdAt
      issuer {
        id
        name
        language
        isArchived
      }
      contact {
        id
        additionalId
        name
        relatedBusiness {
          id
          name
          language
          isArchived
        }
        isArchived
      }
      bankAccount {
        isDepositAccount
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      amount
      feeOnIssuer {
        priceBeforeTax
        taxRate
        total
      }
      remitterNameKana
      error {
        setAt
        code
        message
      }
      issuedAt
    }
  }
}
    `;
export const RecentBalanceDocument = gql`
    query recentBalance($businessId: ID!) {
  recentBalance(businessId: $businessId) {
    id
    createdAt
    currentAmount
    currentReserve
    amountIncrease
    reserveIncrease
    eventType
    transfer {
      id
      createdAt
      contact {
        id
        additionalId
        name
        relatedBusiness {
          id
          name
          language
          isArchived
        }
        isArchived
      }
      bankAccount {
        isDepositAccount
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      amount
      feeOnIssuer {
        priceBeforeTax
        taxRate
        total
      }
      remitterNameKana
      error {
        setAt
        code
        message
      }
      issuedAt
      operatorApp {
        id
        name {
          en
          ja
        }
      }
    }
    charge {
      id
      createdAt
      amount
      remitterNameKana
      depositAccount {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
        fromContact {
          id
          additionalId
          name
          relatedBusiness {
            id
            name
            language
            isArchived
          }
          isArchived
        }
      }
      transfer {
        id
      }
    }
    appDeposit {
      id
      app {
        id
        name {
          en
          ja
        }
      }
      amount
    }
    appWithdraw {
      id
      app {
        id
        name {
          en
          ja
        }
      }
      amount
      canceledAt
    }
    appReserve {
      id
      app {
        id
        name {
          en
          ja
        }
      }
      amount
      resolvedAt
    }
  }
}
    `;
export const TransferDocument = gql`
    query transfer($businessId: ID!, $id: ID!, $isRefreshStatus: Boolean) {
  transfer(businessId: $businessId, id: $id, isRefreshStatus: $isRefreshStatus) {
    id
    createdAt
    issuer {
      id
      name
      language
      isArchived
    }
    contact {
      id
      additionalId
      name
      relatedBusiness {
        id
        name
        language
        isArchived
      }
      isArchived
    }
    bankAccount {
      isDepositAccount
      bankCode
      branchCode
      accountNumber
      accountHolderName
    }
    amount
    feeOnIssuer {
      priceBeforeTax
      taxRate
      total
    }
    remitterNameKana
    error {
      setAt
      code
      message
    }
    issuedAt
    operatorApp {
      id
      name {
        en
        ja
      }
    }
  }
}
    `;
export const TransferConfirmationDocument = gql`
    query transferConfirmation($businessId: ID!, $id: ID!) {
  transferConfirmation(businessId: $businessId, id: $id) {
    id
    createdAt
    issuer {
      id
      name
      language
      isArchived
    }
    contact {
      id
      additionalId
      name
      relatedBusiness {
        id
        name
        language
        isArchived
      }
      isArchived
    }
    bankAccount {
      isDepositAccount
      bankCode
      branchCode
      accountNumber
      accountHolderName
    }
    amount
    feeOnIssuer {
      priceBeforeTax
      taxRate
      total
    }
    remitterNameKana
    confirmationDueDate
    operatorApp {
      id
      name {
        en
        ja
      }
    }
  }
}
    `;
export const BusinessMembersInBusinessDocument = gql`
    query businessMembersInBusiness($businessId: ID!) {
  businessMembersInBusiness(businessId: $businessId) {
    id
    role
    user {
      id
      email
      language
    }
  }
}
    `;
export const ContactWithInvitesDocument = gql`
    query contactWithInvites($businessId: ID!) {
  contactWithInvites(businessId: $businessId) {
    id
    additionalId
    name
    notificationEmail
    externalBankAccount {
      bankCode
      branchCode
      accountNumber
      accountHolderName
    }
    depositAccountFromContactToMe {
      id
      bankCode
      branchCode
      accountNumber
      accountHolderName
    }
    relatedBusiness {
      id
      name
      remitterNameKana
      externalBankAccount {
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      depositAccountFromMeToContact {
        id
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      language
      isArchived
      relatedContactId
    }
    invite {
      codeText
      createdAt
      expireAt
    }
    isArchived
  }
}
    `;
export const InviteForBusinessMembersDocument = gql`
    query inviteForBusinessMembers($businessId: ID!) {
  inviteForBusinessMembers(businessId: $businessId) {
    codeText
    createdAt
    expireAt
    remainingSeats
    role
  }
}
    `;
export const FeesDocument = gql`
    query fees {
  fees {
    transfer {
      internal {
        priceBeforeTax
        taxRate
        total
      }
      external {
        priceBeforeTax
        taxRate
        total
      }
    }
  }
}
    `;
export const InviteForBusinessMemberDocument = gql`
    query inviteForBusinessMember($businessId: ID!, $codeText: ID!) {
  inviteForBusinessMember(businessId: $businessId, codeText: $codeText) {
    codeText
    createdAt
    expireAt
    remainingSeats
    business {
      id
      name
      language
      isArchived
    }
    role
  }
}
    `;
export const InviteForContactDocument = gql`
    query inviteForContact($businessId: ID!, $contactId: ID!, $codeText: ID!) {
  inviteForContact(
    businessId: $businessId
    contactId: $contactId
    codeText: $codeText
  ) {
    codeText
    createdAt
    expireAt
    business {
      id
      name
      language
      isArchived
    }
    contact {
      id
      additionalId
      name
    }
  }
}
    `;
export const LegalTextsDocument = gql`
    query legalTexts {
  legalTexts {
    tos
  }
}
    `;
export const ServiceHealthDocument = gql`
    query serviceHealth {
  serviceHealth {
    code
    message
    url
  }
}
    `;
export const SharedTransferDocument = gql`
    query sharedTransfer($input: GetSharedTransferInput!) {
  sharedTransfer(input: $input) {
    invite {
      codeText
      createdAt
      expireAt
      business {
        id
        name
        language
        isArchived
      }
      contact {
        id
        additionalId
        name
      }
    }
    transfer {
      id
      createdAt
      issuer {
        id
        name
        language
        isArchived
      }
      contact {
        id
        additionalId
        name
        relatedBusiness {
          id
          name
          language
          isArchived
        }
        isArchived
      }
      bankAccount {
        isDepositAccount
        bankCode
        branchCode
        accountNumber
        accountHolderName
      }
      amount
      feeOnIssuer {
        priceBeforeTax
        taxRate
        total
      }
      remitterNameKana
      error {
        setAt
        code
        message
      }
      issuedAt
    }
  }
}
    `;
export const UserDocument = gql`
    query user {
  user {
    id
    firebaseUid
    email
    language
  }
}
    `;
export const UserWithBusinessMembersDocument = gql`
    query userWithBusinessMembers {
  userWithBusinessMembers {
    id
    firebaseUid
    email
    businessMembers {
      id
      role
      business {
        id
        name
        notificationEmail
        language
        isArchived
      }
    }
    language
  }
}
    `;
export const ApiKeyDocument = gql`
    query apiKey {
  apiKey {
    apiKey
    expireAt
  }
}
    `;
export const BusinessMembersInUserDocument = gql`
    query businessMembersInUser {
  businessMembersInUser {
    id
    role
    business {
      id
      name
      notificationEmail
      language
      isArchived
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    deleteApiKey(variables?: DeleteApiKeyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteApiKeyMutation> {
      return withWrapper(() => client.request<DeleteApiKeyMutation>(print(DeleteApiKeyDocument), variables, requestHeaders));
    },
    refreshApiKey(variables: RefreshApiKeyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RefreshApiKeyMutation> {
      return withWrapper(() => client.request<RefreshApiKeyMutation>(print(RefreshApiKeyDocument), variables, requestHeaders));
    },
    cancelAppWithdraw(variables: CancelAppWithdrawMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CancelAppWithdrawMutation> {
      return withWrapper(() => client.request<CancelAppWithdrawMutation>(print(CancelAppWithdrawDocument), variables, requestHeaders));
    },
    createAppDeposit(variables: CreateAppDepositMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateAppDepositMutation> {
      return withWrapper(() => client.request<CreateAppDepositMutation>(print(CreateAppDepositDocument), variables, requestHeaders));
    },
    createAppReserve(variables: CreateAppReserveMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateAppReserveMutation> {
      return withWrapper(() => client.request<CreateAppReserveMutation>(print(CreateAppReserveDocument), variables, requestHeaders));
    },
    createAppWithdraw(variables: CreateAppWithdrawMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateAppWithdrawMutation> {
      return withWrapper(() => client.request<CreateAppWithdrawMutation>(print(CreateAppWithdrawDocument), variables, requestHeaders));
    },
    resolveAppReserve(variables: ResolveAppReserveMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ResolveAppReserveMutation> {
      return withWrapper(() => client.request<ResolveAppReserveMutation>(print(ResolveAppReserveDocument), variables, requestHeaders));
    },
    runAutoCron10mins(variables?: RunAutoCron10minsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RunAutoCron10minsMutation> {
      return withWrapper(() => client.request<RunAutoCron10minsMutation>(print(RunAutoCron10minsDocument), variables, requestHeaders));
    },
    runAutoCron1day(variables?: RunAutoCron1dayMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RunAutoCron1dayMutation> {
      return withWrapper(() => client.request<RunAutoCron1dayMutation>(print(RunAutoCron1dayDocument), variables, requestHeaders));
    },
    runAutoCron6hours(variables?: RunAutoCron6hoursMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RunAutoCron6hoursMutation> {
      return withWrapper(() => client.request<RunAutoCron6hoursMutation>(print(RunAutoCron6hoursDocument), variables, requestHeaders));
    },
    applyInviteForBusinessMember(variables: ApplyInviteForBusinessMemberMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ApplyInviteForBusinessMemberMutation> {
      return withWrapper(() => client.request<ApplyInviteForBusinessMemberMutation>(print(ApplyInviteForBusinessMemberDocument), variables, requestHeaders));
    },
    applyInviteForContactWithSigningUp(variables: ApplyInviteForContactWithSigningUpMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ApplyInviteForContactWithSigningUpMutation> {
      return withWrapper(() => client.request<ApplyInviteForContactWithSigningUpMutation>(print(ApplyInviteForContactWithSigningUpDocument), variables, requestHeaders));
    },
    createTransfer(variables: CreateTransferMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateTransferMutation> {
      return withWrapper(() => client.request<CreateTransferMutation>(print(CreateTransferDocument), variables, requestHeaders));
    },
    createTransferConfirmation(variables: CreateTransferConfirmationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateTransferConfirmationMutation> {
      return withWrapper(() => client.request<CreateTransferConfirmationMutation>(print(CreateTransferConfirmationDocument), variables, requestHeaders));
    },
    createTransferConfirmationFlex(variables: CreateTransferConfirmationFlexMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateTransferConfirmationFlexMutation> {
      return withWrapper(() => client.request<CreateTransferConfirmationFlexMutation>(print(CreateTransferConfirmationFlexDocument), variables, requestHeaders));
    },
    deleteTransferConfirmation(variables: DeleteTransferConfirmationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteTransferConfirmationMutation> {
      return withWrapper(() => client.request<DeleteTransferConfirmationMutation>(print(DeleteTransferConfirmationDocument), variables, requestHeaders));
    },
    addContactAdditionalId(variables: AddContactAdditionalIdMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddContactAdditionalIdMutation> {
      return withWrapper(() => client.request<AddContactAdditionalIdMutation>(print(AddContactAdditionalIdDocument), variables, requestHeaders));
    },
    applyInviteForContactWithBusiness(variables: ApplyInviteForContactWithBusinessMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ApplyInviteForContactWithBusinessMutation> {
      return withWrapper(() => client.request<ApplyInviteForContactWithBusinessMutation>(print(ApplyInviteForContactWithBusinessDocument), variables, requestHeaders));
    },
    archiveBusiness(variables: ArchiveBusinessMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ArchiveBusinessMutation> {
      return withWrapper(() => client.request<ArchiveBusinessMutation>(print(ArchiveBusinessDocument), variables, requestHeaders));
    },
    archiveContact(variables: ArchiveContactMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ArchiveContactMutation> {
      return withWrapper(() => client.request<ArchiveContactMutation>(print(ArchiveContactDocument), variables, requestHeaders));
    },
    createContact(variables: CreateContactMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateContactMutation> {
      return withWrapper(() => client.request<CreateContactMutation>(print(CreateContactDocument), variables, requestHeaders));
    },
    createInviteForBusinessMember(variables: CreateInviteForBusinessMemberMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateInviteForBusinessMemberMutation> {
      return withWrapper(() => client.request<CreateInviteForBusinessMemberMutation>(print(CreateInviteForBusinessMemberDocument), variables, requestHeaders));
    },
    createInviteForContact(variables: CreateInviteForContactMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateInviteForContactMutation> {
      return withWrapper(() => client.request<CreateInviteForContactMutation>(print(CreateInviteForContactDocument), variables, requestHeaders));
    },
    deleteBusinessMember(variables: DeleteBusinessMemberMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteBusinessMemberMutation> {
      return withWrapper(() => client.request<DeleteBusinessMemberMutation>(print(DeleteBusinessMemberDocument), variables, requestHeaders));
    },
    deleteInviteForBusinessMember(variables: DeleteInviteForBusinessMemberMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteInviteForBusinessMemberMutation> {
      return withWrapper(() => client.request<DeleteInviteForBusinessMemberMutation>(print(DeleteInviteForBusinessMemberDocument), variables, requestHeaders));
    },
    deleteInviteForContact(variables: DeleteInviteForContactMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteInviteForContactMutation> {
      return withWrapper(() => client.request<DeleteInviteForContactMutation>(print(DeleteInviteForContactDocument), variables, requestHeaders));
    },
    modifyBusinessExternalBankAccount(variables: ModifyBusinessExternalBankAccountMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModifyBusinessExternalBankAccountMutation> {
      return withWrapper(() => client.request<ModifyBusinessExternalBankAccountMutation>(print(ModifyBusinessExternalBankAccountDocument), variables, requestHeaders));
    },
    modifyBusinessLanguage(variables: ModifyBusinessLanguageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModifyBusinessLanguageMutation> {
      return withWrapper(() => client.request<ModifyBusinessLanguageMutation>(print(ModifyBusinessLanguageDocument), variables, requestHeaders));
    },
    modifyBusinessMemberRole(variables: ModifyBusinessMemberRoleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModifyBusinessMemberRoleMutation> {
      return withWrapper(() => client.request<ModifyBusinessMemberRoleMutation>(print(ModifyBusinessMemberRoleDocument), variables, requestHeaders));
    },
    modifyBusinessName(variables: ModifyBusinessNameMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModifyBusinessNameMutation> {
      return withWrapper(() => client.request<ModifyBusinessNameMutation>(print(ModifyBusinessNameDocument), variables, requestHeaders));
    },
    modifyBusinessNotificationEmail(variables: ModifyBusinessNotificationEmailMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModifyBusinessNotificationEmailMutation> {
      return withWrapper(() => client.request<ModifyBusinessNotificationEmailMutation>(print(ModifyBusinessNotificationEmailDocument), variables, requestHeaders));
    },
    modifyBusinessRemitterNameKana(variables: ModifyBusinessRemitterNameKanaMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModifyBusinessRemitterNameKanaMutation> {
      return withWrapper(() => client.request<ModifyBusinessRemitterNameKanaMutation>(print(ModifyBusinessRemitterNameKanaDocument), variables, requestHeaders));
    },
    modifyContactExternalBankAccount(variables: ModifyContactExternalBankAccountMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModifyContactExternalBankAccountMutation> {
      return withWrapper(() => client.request<ModifyContactExternalBankAccountMutation>(print(ModifyContactExternalBankAccountDocument), variables, requestHeaders));
    },
    modifyContactName(variables: ModifyContactNameMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModifyContactNameMutation> {
      return withWrapper(() => client.request<ModifyContactNameMutation>(print(ModifyContactNameDocument), variables, requestHeaders));
    },
    modifyContactNotificationEmail(variables: ModifyContactNotificationEmailMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModifyContactNotificationEmailMutation> {
      return withWrapper(() => client.request<ModifyContactNotificationEmailMutation>(print(ModifyContactNotificationEmailDocument), variables, requestHeaders));
    },
    runManualCron(variables: RunManualCronMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RunManualCronMutation> {
      return withWrapper(() => client.request<RunManualCronMutation>(print(RunManualCronDocument), variables, requestHeaders));
    },
    createBusiness(variables: CreateBusinessMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateBusinessMutation> {
      return withWrapper(() => client.request<CreateBusinessMutation>(print(CreateBusinessDocument), variables, requestHeaders));
    },
    modifyUserLanguage(variables: ModifyUserLanguageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ModifyUserLanguageMutation> {
      return withWrapper(() => client.request<ModifyUserLanguageMutation>(print(ModifyUserLanguageDocument), variables, requestHeaders));
    },
    balances(variables: BalancesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BalancesQuery> {
      return withWrapper(() => client.request<BalancesQuery>(print(BalancesDocument), variables, requestHeaders));
    },
    business(variables: BusinessQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BusinessQuery> {
      return withWrapper(() => client.request<BusinessQuery>(print(BusinessDocument), variables, requestHeaders));
    },
    charge(variables: ChargeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ChargeQuery> {
      return withWrapper(() => client.request<ChargeQuery>(print(ChargeDocument), variables, requestHeaders));
    },
    contact(variables: ContactQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ContactQuery> {
      return withWrapper(() => client.request<ContactQuery>(print(ContactDocument), variables, requestHeaders));
    },
    contactForRelatedBusinessId(variables: ContactForRelatedBusinessIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ContactForRelatedBusinessIdQuery> {
      return withWrapper(() => client.request<ContactForRelatedBusinessIdQuery>(print(ContactForRelatedBusinessIdDocument), variables, requestHeaders));
    },
    contacts(variables: ContactsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ContactsQuery> {
      return withWrapper(() => client.request<ContactsQuery>(print(ContactsDocument), variables, requestHeaders));
    },
    incomingTransfer(variables: IncomingTransferQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IncomingTransferQuery> {
      return withWrapper(() => client.request<IncomingTransferQuery>(print(IncomingTransferDocument), variables, requestHeaders));
    },
    recentBalance(variables: RecentBalanceQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RecentBalanceQuery> {
      return withWrapper(() => client.request<RecentBalanceQuery>(print(RecentBalanceDocument), variables, requestHeaders));
    },
    transfer(variables: TransferQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TransferQuery> {
      return withWrapper(() => client.request<TransferQuery>(print(TransferDocument), variables, requestHeaders));
    },
    transferConfirmation(variables: TransferConfirmationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TransferConfirmationQuery> {
      return withWrapper(() => client.request<TransferConfirmationQuery>(print(TransferConfirmationDocument), variables, requestHeaders));
    },
    businessMembersInBusiness(variables: BusinessMembersInBusinessQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BusinessMembersInBusinessQuery> {
      return withWrapper(() => client.request<BusinessMembersInBusinessQuery>(print(BusinessMembersInBusinessDocument), variables, requestHeaders));
    },
    contactWithInvites(variables: ContactWithInvitesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ContactWithInvitesQuery> {
      return withWrapper(() => client.request<ContactWithInvitesQuery>(print(ContactWithInvitesDocument), variables, requestHeaders));
    },
    inviteForBusinessMembers(variables: InviteForBusinessMembersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InviteForBusinessMembersQuery> {
      return withWrapper(() => client.request<InviteForBusinessMembersQuery>(print(InviteForBusinessMembersDocument), variables, requestHeaders));
    },
    fees(variables?: FeesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FeesQuery> {
      return withWrapper(() => client.request<FeesQuery>(print(FeesDocument), variables, requestHeaders));
    },
    inviteForBusinessMember(variables: InviteForBusinessMemberQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InviteForBusinessMemberQuery> {
      return withWrapper(() => client.request<InviteForBusinessMemberQuery>(print(InviteForBusinessMemberDocument), variables, requestHeaders));
    },
    inviteForContact(variables: InviteForContactQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InviteForContactQuery> {
      return withWrapper(() => client.request<InviteForContactQuery>(print(InviteForContactDocument), variables, requestHeaders));
    },
    legalTexts(variables?: LegalTextsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LegalTextsQuery> {
      return withWrapper(() => client.request<LegalTextsQuery>(print(LegalTextsDocument), variables, requestHeaders));
    },
    serviceHealth(variables?: ServiceHealthQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ServiceHealthQuery> {
      return withWrapper(() => client.request<ServiceHealthQuery>(print(ServiceHealthDocument), variables, requestHeaders));
    },
    sharedTransfer(variables: SharedTransferQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SharedTransferQuery> {
      return withWrapper(() => client.request<SharedTransferQuery>(print(SharedTransferDocument), variables, requestHeaders));
    },
    user(variables?: UserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UserQuery> {
      return withWrapper(() => client.request<UserQuery>(print(UserDocument), variables, requestHeaders));
    },
    userWithBusinessMembers(variables?: UserWithBusinessMembersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UserWithBusinessMembersQuery> {
      return withWrapper(() => client.request<UserWithBusinessMembersQuery>(print(UserWithBusinessMembersDocument), variables, requestHeaders));
    },
    apiKey(variables?: ApiKeyQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ApiKeyQuery> {
      return withWrapper(() => client.request<ApiKeyQuery>(print(ApiKeyDocument), variables, requestHeaders));
    },
    businessMembersInUser(variables?: BusinessMembersInUserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BusinessMembersInUserQuery> {
      return withWrapper(() => client.request<BusinessMembersInUserQuery>(print(BusinessMembersInUserDocument), variables, requestHeaders));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;