import { FC } from 'react';

import { UodkaCoreClientForOwner } from '@/utils/UodkaClients/Core';
import { useUserCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import Table from '@/components/0_atom/Table';

import { useMemberItems } from '@/views/config/business/Member/useMemberItems';
import { ModifyMemberButton } from '@/views/config/business/Member/ModifyMemberButton';
import { getRoleText } from '@/views/config/business/Member/getRoleText';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    buttonPlaceholder: {
        height: 33,
    },
}));

export const MembersTable: FC<{
    coreClient: UodkaCoreClientForOwner;
}> = ({ coreClient }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const { userClient } = useUserCtxAbsolutely();
    const { items } = useMemberItems({ coreClient });

    return (
        <Table
            emptyPlaceholder={txt({ en: 'No member', ja: 'メンバーが存在しません' })}
            widths={[`calc(100% - ${94 + 64}px)`, 94, 64]}
            className={c.root}
            headItems={[
                { label: txt({ en: 'Email', ja: 'メールアドレス' }) },
                { label: txt({ en: 'Role', ja: '権限' }) },
            ]}
            rowItems={items?.ids.map((id) => {
                const { memberAPI } = items.getItemAbsolutely(id);
                return {
                    items: [
                        {
                            children: memberAPI.user.email,
                        },
                        {
                            children: getRoleText({ role: memberAPI.role, txt }),
                        },
                        {
                            children:
                                memberAPI.user.id !== userClient.id ? (
                                    <ModifyMemberButton
                                        memberAPI={memberAPI}
                                        updateMemberItem={(memberAPI) =>
                                            items.updateItem({
                                                id: memberAPI.id,
                                                memberAPI,
                                            })
                                        }
                                        removeMemberItem={items.removeItem}
                                    />
                                ) : (
                                    <div className={c.buttonPlaceholder} />
                                ),
                        },
                    ],
                };
            })}
        />
    );
};
MembersTable.displayName = 'MembersTable';
