import { FC, useState } from 'react';

import { ClientDataAPIForOwner, ExternalBankAccountInterface } from '@/utils/UodkaClients/Core';
import { useLanguage } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import { TextMenuItemProps } from '@/components/0_atom/Menu';
import { ButtonWithTextMenu } from '@/components/1_mol/ButtonWithMenu';
import { MutationFormModalForBankAccount } from '@/components/1_mol/FormModal';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({}));

export const ModifyBankAccountButton: FC<{
    clientDataAPI: ClientDataAPIForOwner;
    setClientData: (clientData: ClientDataAPIForOwner) => void;
}> = ({ clientDataAPI, setClientData }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [isOpen, setIsOpen] = useState(false);

    // MUTATION
    const run = async (newBankAccount: ExternalBankAccountInterface | undefined) => {
        const result = await clientDataAPI.modifyExternalBankAccount(newBankAccount);
        setClientData(clientDataAPI);
        return result;
    };

    return (
        <>
            <ButtonWithTextMenu
                menuEmptyPlaceholder={'unexpected'}
                variant={'contained'}
                items={(() => {
                    const arr: TextMenuItemProps[] = [
                        {
                            text: txt({
                                en: 'Use dedicated deposit account for each contact',
                                ja: 'コンタクトごとに専用の入金口座を利用',
                            }),
                            isSelected: !clientDataAPI.externalBankAccount,
                            mutation: {
                                run: () => run(undefined),
                                description: txt({
                                    en: 'Modify bank account for receiving remittances',
                                    ja: '送金受け取り時の銀行口座を設定',
                                }),
                            },
                        },
                    ];
                    if (clientDataAPI.externalBankAccount) {
                        arr.push({
                            text: txt({
                                en: 'Modify external bank account',
                                ja: '外部銀行口座を編集',
                            }),
                            isSelected: false,
                            onClick: () => setIsOpen(true),
                        });
                    } else {
                        arr.push({
                            text: txt({
                                en: 'Use external bank account',
                                ja: '外部の銀行口座を利用',
                            }),
                            isSelected: false,
                            onClick: () => setIsOpen(true),
                        });
                    }
                    return arr;
                })()}
            >
                {txt({
                    en: 'Change',
                    ja: '変更',
                })}
            </ButtonWithTextMenu>
            <MutationFormModalForBankAccount
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={txt({
                    en: 'Bank account for receiving remittances',
                    ja: '送金受け取り時の銀行口座',
                })}
                mutation={{
                    run,
                    description: txt({
                        en: 'Modify bank account for receiving remittances',
                        ja: '送金受け取り時の銀行口座を設定',
                    }),
                }}
                submitText={txt({ en: 'Update', ja: '変更' })}
                bankAccount={clientDataAPI.externalBankAccount}
            />
        </>
    );
};
ModifyBankAccountButton.displayName = 'views/config/business/account/Basic/ModifyBankAccountButton';
