import { UodkaCoreClientForObserver } from '@/utils/UodkaClients/Core/clients/Business/ForObserver';
import { ContactAPIForGeneral, getContactFromGql } from '@/utils/UodkaClients/Core/models';

interface UodkaCoreClientForGeneralInterface {
    getContactAPI: (input: { id: string }) => Promise<ContactAPIForGeneral | undefined>;
    getContactAPIs: () => Promise<ContactAPIForGeneral[]>;
}

export class UodkaCoreClientForGeneral
    extends UodkaCoreClientForObserver
    implements UodkaCoreClientForGeneralInterface {
    async getContactAPI({ id }: { id: string }) {
        const { contact } = await this._graphqlSdk.contact({
            businessId: this._id,
            id,
        });
        return contact
            ? new ContactAPIForGeneral({
                  graphqlSdk: this._graphqlSdk,
                  parentBusinessId: this._id,
                  ...getContactFromGql(contact).data,
              })
            : undefined;
    }

    async getContactAPIs() {
        const { contacts } = await this._graphqlSdk.contacts({
            businessId: this._id,
        });
        return contacts.map(
            (contact) =>
                new ContactAPIForGeneral({
                    graphqlSdk: this._graphqlSdk,
                    parentBusinessId: this._id,
                    ...getContactFromGql(contact).data,
                })
        );
    }
}
