import { Signature, getSignatureFromGql } from '@/utils/UodkaClients/Cert';
import { GqlDateTimeData } from '@/utils/UodkaClients/Invoices/graphql/customScalars';
import { IssueingSignature as GqlIssueingSignature } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';

export interface IssueingSignatureInterface {
    issuedAt: Date;
    signature: Signature;
}

export class IssueingSignature implements IssueingSignatureInterface {
    protected _issuedAt: Date;

    protected _signature: Signature;

    get issuedAt() {
        return this._issuedAt;
    }

    get signature() {
        return this._signature;
    }

    constructor({ issuedAt, signature }: IssueingSignatureInterface) {
        this._issuedAt = issuedAt;
        this._signature = signature;
    }
}

export const getIssueingSignatureFromGql = ({ issuedAt, signature }: GqlIssueingSignature) => {
    return new IssueingSignature({
        issuedAt: GqlDateTimeData.serialize(issuedAt),
        signature: getSignatureFromGql(signature),
    });
};
