import { BankAccountInTransfer as GqlBankAccountInTransfer } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { getKana } from '@/utils/UodkaClients/Core/utils/getKana';

export interface BankAccountInTransferInterface {
    isDepositAccount: boolean;
    bankCode: string;
    branchCode: string;
    accountNumber: string;
    accountHolderName: string;
}

export class BankAccountInTransfer implements BankAccountInTransferInterface {
    protected _isDepositAccount: boolean;

    protected _bankCode: string;

    protected _branchCode: string;

    protected _accountNumber: string;

    protected _accountHolderName: string;

    get isDepositAccount() {
        return this._isDepositAccount;
    }

    get bankCode() {
        return this._bankCode;
    }

    get branchCode() {
        return this._branchCode;
    }

    get accountNumber() {
        return this._accountNumber;
    }

    get accountHolderName() {
        return this._accountHolderName;
    }

    get data() {
        return {
            isDepositAccount: this.isDepositAccount,
            bankCode: this.bankCode,
            branchCode: this.branchCode,
            accountNumber: this.accountNumber,
            accountHolderName: this.accountHolderName,
        };
    }

    constructor({
        isDepositAccount,
        bankCode,
        branchCode,
        accountNumber,
        accountHolderName,
    }: BankAccountInTransferInterface) {
        this._isDepositAccount = isDepositAccount;
        this._bankCode = bankCode;
        this._branchCode = branchCode;
        this._accountNumber = accountNumber;
        this._accountHolderName = (() => {
            const result = getKana(accountHolderName);
            if (result) {
                return result.fullWidthKatakana;
            }
            throw new Error('BankAccountInTransfer.constructor: Unexpectedly getKana failed.');
        })();
    }
}

export const getBankAccountInTransferFromGql = ({
    isDepositAccount,
    bankCode,
    branchCode,
    accountNumber,
    accountHolderName,
}: GqlBankAccountInTransfer) => {
    return new BankAccountInTransfer({
        isDepositAccount,
        bankCode,
        branchCode,
        accountNumber,
        accountHolderName,
    });
};

// export const getBankAccountInTransfer = (bankAccountInTransferInterface?: BankAccountInTransferInterface) => {
//     if (bankAccountInTransferInterface) {
//         return new BankAccountInTransfer(bankAccountInTransferInterface);
//     }
//     return undefined;
// };
