import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 437 266" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M235.314 7.02888L429.657 201.372C439.03 210.745 439.03 225.941 429.657 235.313L406.99 257.98C397.633 267.337 382.468 267.355 373.089 258.02L218.343 104L63.5977 258.021C54.2187 267.356 39.0538 267.338 29.6968 257.981L7.02975 235.314C-2.34325 225.941 -2.34325 210.745 7.02975 201.373L201.373 7.02987C210.745 -2.34313 225.941 -2.34312 235.314 7.02888Z"
                fill="currentColor"
            />
        </svg>
    );
};
Svg.displayName = 'svg/fa-chevron-up';
export default Svg;
