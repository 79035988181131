import { createContext, useContext } from 'react';

import { MutationValidationResult } from '@/utils/UodkaClients/Core';

export type ProcessingMutationStatus = MutationValidationResult | 'fetching' | 'fetchError' | 'canceled';
export interface ProcessingMutation {
    description: string;
    retry: () => void;
    cancel: () => void;
    addedAt: Date;
    status: ProcessingMutationStatus;
}

export interface AddMutationInput {
    run: () => Promise<MutationValidationResult> | never;
    description: string;
    onThen?: (result: MutationValidationResult) => void;
}
export interface WrappedAddMutationInput<T> {
    run: (input: T) => Promise<MutationValidationResult> | never;
    description: string;
    onThen?: (result: MutationValidationResult) => void;
}

interface MutationsCtxType {
    slot1?: ProcessingMutation;
    slot2?: ProcessingMutation;
    slot3?: ProcessingMutation;
    slot4?: ProcessingMutation;
    addMutation: (newMutation: AddMutationInput) => 1 | 2 | 3 | 4 | never;
}

export const MutationsCtx = createContext<MutationsCtxType>({
    addMutation: () => 1,
});
export const useMutationsCtx = () => {
    return useContext(MutationsCtx);
};

export const getWrappedMutation = ({
    mutation,
    additionalOnThen,
}: {
    mutation: AddMutationInput;
    additionalOnThen: (result: MutationValidationResult) => void;
}) => ({
    ...mutation,
    onThen: (result: MutationValidationResult) => {
        mutation.onThen && mutation.onThen(result);
        additionalOnThen(result);
    },
});
