import { BusinessMemberRole } from '@/utils/UodkaClients/Core';

export const getRoleText = ({
    role,
    txt,
}: {
    role: BusinessMemberRole;
    txt: (multilingualTexts: { en: string; ja?: string | undefined }) => string;
}) => {
    return (() => {
        if (role === 'owner') {
            return txt({ en: 'Owner', ja: '管理者' });
        }
        if (role === 'general') {
            return txt({ en: 'General', ja: '一般' });
        }
        if (role === 'observer') {
            return txt({ en: 'Observer', ja: '閲覧のみ' });
        }
        throw new Error('getRoleText: Unexpected role');
    })();
};
