import { GqlDateTimeData } from '@/utils/UodkaClients/Invoices/graphql/customScalars';
import { ReceivedBill as GqlReceivedBill } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import { ContactInReceivedBill, getContactInReceivedBillFromGql } from '@/utils/UodkaClients/Invoices/models/Contact';
import { BillData, getBillDataFromGql } from '@/utils/UodkaClients/Invoices/models/InvoiceData';
import { BillSignatures, getBillSignaturesFromGql } from '@/utils/UodkaClients/Invoices/models/BillSignatures';
import {
    PaymentInReceivedBill,
    getPaymentInReceivedBillFromGql,
} from '@/utils/UodkaClients/Invoices/models/PaymentEdge';
import {
    ReceiptInReceivedBill,
    getReceiptInReceivedBillFromGql,
} from '@/utils/UodkaClients/Invoices/models/ReceiptEdge';

export interface ReceivedBillInterface {
    id: string;
    createdAt: Date;
    contact: ContactInReceivedBill;
    billData: BillData;
    signatures: BillSignatures;
    payment?: PaymentInReceivedBill;
    receipt?: ReceiptInReceivedBill;
}

export class ReceivedBill implements ReceivedBillInterface {
    protected _id: string;

    protected _createdAt: Date;

    protected _contact: ContactInReceivedBill;

    protected _billData: BillData;

    protected _signatures: BillSignatures;

    protected _payment?: PaymentInReceivedBill;

    protected _receipt?: ReceiptInReceivedBill;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get contact() {
        return this._contact;
    }

    get billData() {
        return this._billData;
    }

    get signatures() {
        return this._signatures;
    }

    get payment() {
        return this._payment;
    }

    get receipt() {
        return this._receipt;
    }

    get data() {
        return {
            id: this.id,
            createdAt: this.createdAt,
            contact: this.contact,
            billData: this.billData,
            signatures: this.signatures,
            payment: this.payment,
            receipt: this.receipt,
        };
    }

    constructor({ id, createdAt, contact, billData, signatures, payment, receipt }: ReceivedBillInterface) {
        this._id = id;
        this._createdAt = createdAt;
        this._contact = contact;
        this._billData = billData;
        this._signatures = signatures;
        this._payment = payment;
        this._receipt = receipt;
    }
}

export const getReceivedBillFromGql = ({
    id,
    createdAt,
    contact,
    billData,
    signatures,
    payment,
    receipt,
}: GqlReceivedBill) => {
    return new ReceivedBill({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        contact: getContactInReceivedBillFromGql(contact),
        billData: getBillDataFromGql(billData),
        signatures: getBillSignaturesFromGql(signatures),
        payment: payment ? getPaymentInReceivedBillFromGql(payment) : undefined,
        receipt: receipt ? getReceiptInReceivedBillFromGql(receipt) : undefined,
    });
};
