import { GraphQLClient } from 'graphql-request';
import { getSdk, ServiceHealthCode } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import { graphqlEndPoint } from '@/utils/UodkaClients/Invoices/config';

export const getGraphqlSdk = ({ firebaseUserIdToken }: { firebaseUserIdToken?: string }) => {
    const client = new GraphQLClient(graphqlEndPoint, {
        headers: {
            authentication: firebaseUserIdToken || '',
        },
    });
    return getSdk(client);
};

export type GraphQLSdk = ReturnType<typeof getGraphqlSdk>;

export type UodkaInvoicesServiceHealth = {
    code: ServiceHealthCode;
    message?: string;
    url?: string;
};
export const getUodkaInvoicesHealth = async (): Promise<UodkaInvoicesServiceHealth> => {
    const { serviceHealth } = await getGraphqlSdk({ firebaseUserIdToken: undefined }).serviceHealth();
    return {
        code: serviceHealth.code,
        message: serviceHealth.message || undefined,
        url: serviceHealth.url || undefined,
    };
};
