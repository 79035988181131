import { MutationValidationResult } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import { GraphQLSdk as CoreSdk } from '@/utils/UodkaClients/Core/graphql/graphqlSdk';
import { getTransferConfirmationFromGql } from '@/utils/UodkaClients/Core';
import { GraphQLSdk as CertSdk } from '@/utils/UodkaClients/Cert/graphql/graphqlSdk';
import { GraphQLSdk as InvoicesSdk } from '@/utils/UodkaClients/Invoices/graphql/graphqlSdk';
import { GqlDateTimeData } from '@/utils/UodkaClients/Invoices/graphql/customScalars';
import { PaymentInReceivedBill } from '@/utils/UodkaClients/Invoices/models/PaymentEdge';
import { getBillSignaturesFromGql } from '@/utils/UodkaClients/Invoices/models/BillSignatures';
import { TransferConfirmationForBillAPIForGeneral } from '@/utils/UodkaClients/Invoices/models/TransferConfirmationForBill';
import {
    ReceivedBill,
    ReceivedBillInterface,
} from '@/utils/UodkaClients/Invoices/models/ReceivedBill/data/ReceivedBill';
import { createReceivingSignature, createRevokingSignature } from '@/utils/UodkaClients/Invoices/utils/createSignature';

interface ReceivedBillAPIInterface {
    addReceivingSignature: () => Promise<{
        result: MutationValidationResult;
    }>;
    addRevokingSignature: () => Promise<{
        result: MutationValidationResult;
    }>;
    addTransferConfirmation: () => Promise<{
        result: MutationValidationResult;
        transferConfirmationForBillAPI: TransferConfirmationForBillAPIForGeneral;
    }>;
}

interface ConstructorInput extends ReceivedBillInterface {
    coreSdk: CoreSdk;
    certSdk: CertSdk;
    invoicesSdk: InvoicesSdk;
    clientBusinessId: string;
}

export class ReceivedBillAPIForGeneral extends ReceivedBill implements ReceivedBillAPIInterface {
    protected _coreSdk: CoreSdk;

    protected _certSdk: CertSdk;

    protected _invoicesSdk: InvoicesSdk;

    protected _clientBusinessId: string;

    constructor(input: ConstructorInput) {
        super(input);
        this._coreSdk = input.coreSdk;
        this._certSdk = input.certSdk;
        this._invoicesSdk = input.invoicesSdk;
        this._clientBusinessId = input.clientBusinessId;
    }

    async addReceivingSignature() {
        if (this.signatures.recipient.receive) {
            throw new Error('ReceivedBillAPIForGeneral.addReceivingSignature: receivingSignature already exists.');
        }
        const newReceivingSignature = await createReceivingSignature({
            businessId: this._clientBusinessId,
            resourceId: this.signatures.issuer.issue.signature.resourceId,
            certSdk: this._certSdk,
            billData: this.billData,
        });
        const { importSignaturesToReceivedBill } = await this._invoicesSdk.importSignaturesToReceivedBill({
            input: {
                businessId: this._clientBusinessId,
                receive: {
                    billReceivedAt: GqlDateTimeData.parseValue(newReceivingSignature.billReceivedAt),
                    signature: newReceivingSignature.signature.gqlData,
                },
            },
        });
        const { result, receivedBill } = importSignaturesToReceivedBill;
        this._signatures = getBillSignaturesFromGql(receivedBill.signatures);
        return {
            result,
        };
    }

    async addRevokingSignature() {
        if (this.signatures.recipient.revoke) {
            throw new Error('ReceivedBillAPIForGeneral.addRevokingSignature: revokingSignature already exists.');
        }
        const newRevokingSignature = await createRevokingSignature({
            businessId: this._clientBusinessId,
            resourceId: this.signatures.issuer.issue.signature.resourceId,
            certSdk: this._certSdk,
            billData: this.billData,
        });
        const { importSignaturesToReceivedBill } = await this._invoicesSdk.importSignaturesToReceivedBill({
            input: {
                businessId: this._clientBusinessId,
                revoke: {
                    agreeToRevokeAt: GqlDateTimeData.parseValue(newRevokingSignature.agreeToRevokeAt),
                    signature: newRevokingSignature.signature.gqlData,
                },
            },
        });
        const { result, receivedBill } = importSignaturesToReceivedBill;
        this._signatures = getBillSignaturesFromGql(receivedBill.signatures);
        return {
            result,
        };
    }

    async addTransferConfirmation() {
        if (!this.signatures.recipient.receive) {
            await this.addReceivingSignature();
        }
        const {
            createTransferConfirmationFlex: { transferConfirmation, result },
        } = await this._coreSdk.createTransferConfirmationFlex({
            input: {
                businessId: this._clientBusinessId,
                contactId: this.contact.id,
                amount: this.billData.summary.total,
                bankAccount: this.billData.payee.uodkaBankAccount.data,
            },
        });
        const transferConfirmationForBillAPI = new TransferConfirmationForBillAPIForGeneral({
            coreSdk: this._coreSdk,
            invoicesSdk: this._invoicesSdk,
            parentBusinessId: this._clientBusinessId,
            parentContactId: this.contact.id,
            receivedBill: this,
            addPayment: (newPayment: PaymentInReceivedBill | undefined) => {
                this._payment = newPayment;
            },
            ...getTransferConfirmationFromGql(transferConfirmation).data,
        });
        return {
            result,
            transferConfirmationForBillAPI,
        };
    }
}
