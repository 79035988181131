import { InvoiceDataItem, InvoiceDataSummary, AggregatedTax } from '@/utils/UodkaClients/Invoices/models';
import { DecimalRoundRule } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';

export const roundFloatToInt = ({ float, decimalRoundRule }: { float: number; decimalRoundRule: DecimalRoundRule }) => {
    const abs = Math.abs(float);
    const positiveOrNegative = float < 0 ? -1 : 1;
    if (decimalRoundRule === 'up') {
        return Math.ceil(abs) * positiveOrNegative;
    }
    if (decimalRoundRule === 'down') {
        return Math.floor(abs) * positiveOrNegative;
    }
    return Math.round(abs) * positiveOrNegative;
};

// export const getInvoiceDataSummary = ({
//     decimalRoundRule,
//     items,
// }: {
//     decimalRoundRule: DecimalRoundRule;
//     items: InvoiceDataItem[];
// }): InvoiceDataSummary => {
//     type CalulatingAggregatedTax = {
//         title: string;
//         taxableValue: number;
//         value: number;
//     };
//     type CalulatingSummary = {
//         subtotal: number;
//         taxes: CalulatingAggregatedTax[];
//         total: number;
//     };
//     const calulatingSummary = items.reduce(
//         (acc, prev) => {
//             const { quantity, unitPrice, taxes } = prev;
//             const newTaxValueTotal = taxes.reduce((acc2, prev2) => acc2 + prev2.value, 0);
//             const newSubtotal = acc.subtotal + quantity * unitPrice;
//             const newTotal = acc.total + newSubtotal + newTaxValueTotal;
//             const newTaxes = taxes.reduce((_, prev2) => {
//                 const targetAggregatedTax = acc.taxes.find(({ title }) => title === prev2.title);
//                 if (targetAggregatedTax) {
//                     return acc.taxes.map((existingAggregatedTax) => {
//                         if (existingAggregatedTax.title === targetAggregatedTax.title) {
//                             return {
//                                 title: existingAggregatedTax.title,
//                                 taxableValue: existingAggregatedTax.taxableValue + quantity * unitPrice,
//                                 value: existingAggregatedTax.value + prev2.value,
//                             };
//                         }
//                         return existingAggregatedTax;
//                     });
//                 }
//                 return [
//                     ...acc.taxes,
//                     {
//                         title: prev2.title,
//                         taxableValue: quantity * unitPrice,
//                         value: prev2.value,
//                     },
//                 ];
//             }, [] as CalulatingAggregatedTax[]);
//             return {
//                 subtotal: newSubtotal,
//                 taxes: newTaxes,
//                 total: newTotal,
//             };
//         },
//         {
//             subtotal: 0,
//             taxes: [],
//             total: 0,
//         } as CalulatingSummary
//     );
//     const totalTax = calulatingSummary.taxes.reduce(
//         (acc, prev) => acc + roundFloatToInt({ float: prev.value, decimalRoundRule }),
//         0
//     );
//     const roundedSubtotal = roundFloatToInt({ float: calulatingSummary.subtotal, decimalRoundRule });
//     const roundedTotal = roundedSubtotal + totalTax;
//     return new InvoiceDataSummary({
//         decimalRoundRuleForSubtotal: decimalRoundRule,
//         subtotal: roundedSubtotal,
//         taxes: calulatingSummary.taxes.map(
//             ({ title, taxableValue, value }) =>
//                 new AggregatedTax({
//                     title,
//                     taxableValue,
//                     value: roundFloatToInt({ float: value, decimalRoundRule }),
//                     decimalRoundRule,
//                 })
//         ),
//         total: roundedTotal,
//     });
// };

export const getInvoiceDataSummary = ({
    decimalRoundRule,
    items,
}: {
    decimalRoundRule: DecimalRoundRule;
    items: InvoiceDataItem[];
}): InvoiceDataSummary => {
    const taxes = items
        .map((item) =>
            item.taxes.map(
                (tax) =>
                    new AggregatedTax({
                        title: tax.title,
                        decimalRoundRule,
                        taxableValue: item.unitPrice * item.quantity,
                        value: tax.value,
                    })
            )
        )
        .flat()
        .reduce((acc, prev) => {
            let isUpdated = false;
            const updatedItems = acc.map((accItem) => {
                if (accItem.title === prev.title) {
                    isUpdated = true;
                    return new AggregatedTax({
                        title: prev.title,
                        decimalRoundRule,
                        taxableValue: accItem.taxableValue + prev.taxableValue,
                        value: accItem.value + prev.value,
                    });
                }
                return accItem;
            });
            if (isUpdated) {
                return updatedItems;
            }
            return [
                ...acc,
                new AggregatedTax({
                    title: prev.title,
                    decimalRoundRule,
                    taxableValue: prev.taxableValue,
                    value: prev.value,
                }),
            ];
        }, [] as AggregatedTax[]);
    const roundedTaxes = taxes.map(
        ({ title, taxableValue, value }) =>
            new AggregatedTax({
                title,
                taxableValue,
                value: roundFloatToInt({ float: value, decimalRoundRule }),
                decimalRoundRule,
            })
    );
    const totalTax = roundedTaxes.reduce((acc, prev) => acc + prev.value, 0);
    const roundedSubtotal = (() => {
        const rawSubtotal = items.reduce((acc, prev) => acc + prev.unitPrice * prev.quantity, 0);
        return roundFloatToInt({ float: rawSubtotal, decimalRoundRule });
    })();
    const roundedTotal = roundedSubtotal + totalTax;
    return new InvoiceDataSummary({
        decimalRoundRuleForSubtotal: decimalRoundRule,
        subtotal: roundedSubtotal,
        taxes: roundedTaxes,
        total: roundedTotal,
    });
};
