import { FC } from 'react';

import { useUserCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage, Language } from '@/utils/customHooks';
import { ButtonWithTextMenu } from '@/components/1_mol/ButtonWithMenu';
import HorizontalTable from '@/components/0_atom/HorizontalTable';
import ValueWithEditButton from '@/components/0_atom/ValueWithEditButton';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#fff',
        borderRadius: 4,
        padding: 18,
    },
}));

export const PersonalConfig: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { userClient, reInitialize } = useUserCtxAbsolutely();
    const { txt } = useLanguage();

    // MUTATION
    const runModifyLanguage = async (newLanguage: Language) => {
        const result = await userClient.modifyLanguage(newLanguage);
        if (result === 'success') {
            reInitialize();
        }
        return result;
    };

    return (
        <div className={c.root}>
            <HorizontalTable
                items={[
                    {
                        label: txt({
                            en: 'Google Account',
                            ja: 'Googleアカウント',
                        }),
                        children: <ValueWithEditButton value={userClient.email} />,
                    },
                    {
                        label: txt({
                            en: 'Language',
                            ja: '言語',
                        }),
                        children: (
                            <ValueWithEditButton
                                value={txt({
                                    en: 'English',
                                    ja: '日本語',
                                })}
                                editButton={
                                    <ButtonWithTextMenu
                                        menuEmptyPlaceholder={'unexpected'}
                                        variant={'contained'}
                                        items={[
                                            {
                                                text: 'English',
                                                isSelected: !userClient.language || userClient.language === 'en',
                                                mutation: {
                                                    run: () => runModifyLanguage('en'),
                                                    description: txt({
                                                        en: "Change User's Language to English",
                                                        ja: 'ユーザーの言語を英語に変更',
                                                    }),
                                                },
                                            },
                                            {
                                                text: '日本語',
                                                isSelected: userClient.language === 'ja',
                                                mutation: {
                                                    run: () => runModifyLanguage('ja'),
                                                    description: txt({
                                                        en: "Change User's Language to Japanese",
                                                        ja: 'ユーザーの言語を日本語に変更',
                                                    }),
                                                },
                                            },
                                        ]}
                                    >
                                        {txt({
                                            en: 'Change',
                                            ja: '変更',
                                        })}
                                    </ButtonWithTextMenu>
                                }
                            />
                        ),
                    },
                ]}
            />
        </div>
    );
};
PersonalConfig.displayName = 'PersonalConfig';
