import { Signature as GqlSignature } from '@/utils/UodkaClients/Cert/graphql/generated/graphqlClient';

interface SignatureInterface {
    resourceId: string;
    id: string;
    signerId: string;
    contactId: string;
    text: string;
    unixTime: number;
}

export class Signature implements SignatureInterface {
    protected _resourceId: string;

    protected _id: string;

    protected _signerId: string;

    protected _contactId: string;

    protected _text: string;

    protected _unixTime: number;

    get resourceId() {
        return this._resourceId;
    }

    get id() {
        return this._id;
    }

    get signerId() {
        return this._signerId;
    }

    get contactId() {
        return this._contactId;
    }

    get text() {
        return this._text;
    }

    get unixTime() {
        return this._unixTime;
    }

    get data() {
        return {
            resourceId: this._resourceId,
            id: this._id,
            signerId: this._signerId,
            contactId: this._contactId,
            text: this._text,
            unixTime: this._unixTime,
        };
    }

    get gqlData() {
        return {
            resourceId: this._resourceId,
            id: this._id,
            signerId: this._signerId,
            contactId: this._contactId,
            text: this._text,
            unixTime: String(this._unixTime),
        };
    }

    constructor({ resourceId, id, signerId, contactId, text, unixTime }: SignatureInterface) {
        this._resourceId = resourceId;
        this._id = id;
        this._signerId = signerId;
        this._contactId = contactId;
        this._text = text;
        this._unixTime = unixTime;
    }
}

export const getSignatureFromGql = ({ resourceId, id, signerId, contactId, text, unixTime }: GqlSignature) => {
    return new Signature({
        resourceId,
        id,
        signerId,
        contactId,
        text,
        unixTime: Number(unixTime),
    });
};
