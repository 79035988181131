import { FC, useState } from 'react';

import { useUserCtx, useBusinessCtx } from '@/utils/ctxs';
import { useRouting, useLanguage } from '@/utils/customHooks';
import { MenuList } from '@/components/0_atom/Menu';
import AddBusinessModal from '@/components/2_org/AddBusinessModal';
import ContactNameWithIcon from '@/components/2_org/ContactNameWithIcon';
import FetchingText from '@/components/0_atom/FetchingText';

import SvgPlusIcon from '@/svgs/fa-plus-light';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    addButton: {
        display: 'flex',
        alignItems: 'center',
        minWidth: 78.27,
        '& svg': {
            width: 28,
            height: 28,
            padding: 4,
            marginRight: 10,
        },
        '& span': {
            fontWeight: 600,
            lineHeight: 1,
            fontSize: theme.typography.body2.fontSize,
        },
    },
}));

const BusinessSelector: FC<{ className?: string }> = ({ className }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { memberWithBusinessProfiles } = useUserCtx();
    const { currentBusiness } = useBusinessCtx();
    const { switchBusinessId } = useRouting();
    const { txt } = useLanguage();
    const [isNewBusinessModalOpen, setIsNewBusinessModalOpen] = useState(false);

    return (
        <div className={className}>
            <MenuList
                items={(() => {
                    const businesses = (() => {
                        if (memberWithBusinessProfiles) {
                            return memberWithBusinessProfiles.map(({ member, businessProfile }) => ({
                                children: (
                                    <ContactNameWithIcon
                                        contact={{ name: member.business.name }}
                                        profile={businessProfile}
                                    />
                                ),
                                onClick: () => switchBusinessId({ businessId: member.business.id }),
                                isSelected: currentBusiness?.id === member.business.id,
                                isDisabled: member.business.isArchived,
                            }));
                        }
                        return [
                            {
                                children: <FetchingText />,
                                isDisabled: true,
                            },
                        ];
                    })();
                    return [
                        {
                            children: txt({ en: 'Select Business', ja: 'ビジネスを選択' }),
                            isSubheader: true,
                        },
                        ...businesses,
                        {
                            children: (
                                <span className={c.addButton}>
                                    <SvgPlusIcon />
                                    <span>{txt({ en: 'Add', ja: '追加' })}</span>
                                </span>
                            ),
                            onClick: () => setIsNewBusinessModalOpen(true),
                        },
                    ];
                })()}
            />
            <AddBusinessModal isOpen={isNewBusinessModalOpen} onClose={() => setIsNewBusinessModalOpen(false)} />
        </div>
    );
};
BusinessSelector.displayName = 'BusinessSelector';
export default BusinessSelector;
