import { FC } from 'react';

import { BankAccountInTransferInterface, ClientData } from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import { BusinessSnapshot, InvoiceDataItem, DecimalRoundRule, InvoiceDataSummary } from '@/utils/UodkaClients/Invoices';
import { useLanguage, ContactItem } from '@/utils/customHooks';
import ContactSelector from '@/components/1_mol/Selector/ContactSelector';

import { BillDataEditor } from '@/views/bills/AddBillDrawer/BillEditor/BillDataEditor';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import MuiPaper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 24,
        width: 800,
    },
    block1: {},
    block2: {
        marginTop: 25,
    },
    label: {
        display: 'block',
        fontSize: theme.typography.body2.fontSize,
        marginBottom: 5,
        fontWeight: 600,
        color: theme.palette.text.disabled,
    },
    specifyConytactFirst: {
        background: '#fafafa',
        padding: '28px 19px',
        borderRadius: 4,
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 600,
        color: theme.palette.text.disabled,
    },
}));

export const BillEditor: FC<{
    clientData: ClientData;
    clientProfile: BusinessProfile | undefined;
    contactItems: ContactItem[];
    selectedContactItem: ContactItem | undefined;
    setSelectedContactItem: (selectedContactItem: ContactItem | undefined) => void;
    bankAccount: BankAccountInTransferInterface | undefined;
    setBankAccount: (bankAccount: BankAccountInTransferInterface | undefined) => void;
    isBankAccountManual: boolean;
    setIsBankAccountManual: (isBankAccountManual: boolean) => void;
    customizedId: string | undefined;
    setCustomizedId: (customizedId: string | undefined) => void;
    title: string | undefined;
    setTitle: (title: string | undefined) => void;
    issuer: BusinessSnapshot;
    setIssuer: (issuer: BusinessSnapshot) => void;
    recipient: BusinessSnapshot;
    setRecipient: (recipient: BusinessSnapshot) => void;
    items: InvoiceDataItem[];
    setItems: (items: InvoiceDataItem[]) => void;
    note: string | undefined;
    setNote: (note: string | undefined) => void;
    issueDate: Date | undefined;
    setIssueDate: (issueDate: Date | undefined) => void;
    paymentDueDate: Date | undefined;
    setPaymentDueDate: (paymentDueDate: Date | undefined) => void;
    decimalRoundRule: DecimalRoundRule;
    setDecimalRoundRule: (decimalRoundRule: DecimalRoundRule) => void;
    summary: InvoiceDataSummary;
}> = ({
    clientData,
    clientProfile,
    contactItems,
    selectedContactItem,
    setSelectedContactItem,
    bankAccount,
    setBankAccount,
    isBankAccountManual,
    setIsBankAccountManual,
    customizedId,
    setCustomizedId,
    title,
    setTitle,
    issuer,
    setIssuer,
    recipient,
    setRecipient,
    items,
    setItems,
    note,
    setNote,
    issueDate,
    setIssueDate,
    paymentDueDate,
    setPaymentDueDate,
    decimalRoundRule,
    setDecimalRoundRule,
    summary,
}) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    return (
        <MuiPaper className={c.root} elevation={8}>
            <div className={c.block1}>
                <MuiTypography className={c.label}>{txt({ en: 'Bill To', ja: '請求先' })}</MuiTypography>
                <ContactSelector
                    contactItems={contactItems}
                    selectedContactItem={selectedContactItem}
                    setSelectedContactItem={setSelectedContactItem}
                />
            </div>
            <div className={c.block2}>
                <MuiTypography className={c.label}>{txt({ en: 'Bill Details', ja: '請求の内容' })}</MuiTypography>
                {selectedContactItem ? (
                    <BillDataEditor
                        {...{
                            clientData,
                            clientProfile,
                            selectedContactItem,
                            bankAccount,
                            setBankAccount,
                            isBankAccountManual,
                            setIsBankAccountManual,
                            customizedId,
                            setCustomizedId,
                            title,
                            setTitle,
                            issuer,
                            setIssuer,
                            recipient,
                            setRecipient,
                            items,
                            setItems,
                            note,
                            setNote,
                            issueDate,
                            setIssueDate,
                            paymentDueDate,
                            setPaymentDueDate,
                            decimalRoundRule,
                            setDecimalRoundRule,
                            summary,
                        }}
                    />
                ) : (
                    <MuiTypography className={c.specifyConytactFirst}>
                        {txt({ en: 'Specify contact first.', ja: '先に請求先を指定してください。' })}
                    </MuiTypography>
                )}
            </div>
        </MuiPaper>
    );
};
BillEditor.displayName = 'BillEditor';
