import { FC } from 'react';

import { MutationValidationResult, InviteForContactAPIForOwner, ContactAPIForOwner } from '@/utils/UodkaClients/Core';
import { useLanguage } from '@/utils/customHooks';
import CheckList from '@/components/1_mol/CheckList';
import MutationButton from '@/components/0_atom/MutationButton';

import SvgLink from '@/svgs/link';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    submit: {},
    checklist: {
        margin: '19px 0 34px 0',
    },
}));

export interface ContactAPIForOwnerWithoutInviteAPI extends ContactAPIForOwner {
    inviteAPI: undefined;
    issueInvite: (
        durationDays?: number
    ) => Promise<{
        inviteAPI: InviteForContactAPIForOwner;
        result: MutationValidationResult;
    }>;
}

export const NotIssued: FC<{
    updateItem: (contactAPI: ContactAPIForOwner) => void;
    contactAPI: ContactAPIForOwnerWithoutInviteAPI;
}> = ({ updateItem, contactAPI }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    // HANDLER
    const run = async () => {
        const { result } = await contactAPI.issueInvite(30);
        updateItem(contactAPI);
        return result;
    };

    return (
        <>
            <MuiTypography>
                {txt({
                    en: 'Linking a contact with another user, the following features become available.',
                    ja: 'コンタクトを他のユーザーとリンクすると、以下の機能を利用できるようになります。',
                })}
            </MuiTypography>
            <CheckList
                className={c.checklist}
                items={[
                    {
                        label: txt({
                            en: 'Automatic reflection of bank account information, etc.',
                            ja: '銀行口座情報などの自動反映',
                        }),
                        isOk: true,
                    },
                    {
                        label: txt({
                            en: 'Automatic synchronization of transaction records',
                            ja: '取引記録の自動同期',
                        }),
                        isOk: true,
                    },
                    {
                        label: txt({
                            en: 'It is not possible to delete or unlink linked contacts.',
                            ja: 'リンクしたコンタクトの削除・リンク解除はできません。',
                        }),
                        isOk: true,
                        isWarn: true,
                    },
                    {
                        label: txt({
                            en: 'Business with linked contacts cannot be deleted.',
                            ja: 'リンク済みのコンタクトがあるビジネスは削除できません',
                        }),
                        isOk: true,
                        isWarn: true,
                    },
                ]}
            />
            <MutationButton
                className={c.submit}
                startIcon={<SvgLink />}
                fullWidth
                role={'submit'}
                mutation={{
                    run,
                    description: txt({
                        en: `Issue URL for ${contactAPI.name}`,
                        ja: `${contactAPI.name}のリンクURLを発行`,
                    }),
                }}
            >
                {txt({
                    en: 'Issue URL',
                    ja: 'リンクURLを発行',
                })}
            </MutationButton>
        </>
    );
};
NotIssued.displayName = 'NotIssued';
