import { ClientDataInTransfer, getClientDataInTransferFromGql } from '@/utils/UodkaClients/Core';
import { ContactInReceivedBill as GqlContactInReceivedBill } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';

interface ContactInReceivedBillInterface {
    id: string;
    additionalId?: string;
    name: string;
    relatedBusiness: ClientDataInTransfer;
    isArchived: boolean;
}

export class ContactInReceivedBill implements ContactInReceivedBillInterface {
    protected _id: string;

    protected _additionalId?: string;

    protected _name: string;

    protected _relatedBusiness: ClientDataInTransfer;

    protected _isArchived: boolean;

    get id() {
        return this._id;
    }

    get additionalId() {
        return this._additionalId;
    }

    get name() {
        return this._name;
    }

    get relatedBusiness() {
        return this._relatedBusiness;
    }

    get isArchived() {
        return this._isArchived;
    }

    constructor({ id, additionalId, name, relatedBusiness, isArchived }: ContactInReceivedBillInterface) {
        this._id = id;
        this._additionalId = additionalId;
        this._name = name;
        this._relatedBusiness = relatedBusiness;
        this._isArchived = isArchived;
    }
}

export const getContactInReceivedBillFromGql = ({
    id,
    additionalId,
    name,
    relatedBusiness,
    isArchived,
}: GqlContactInReceivedBill) => {
    return new ContactInReceivedBill({
        id,
        additionalId: additionalId || undefined,
        name,
        relatedBusiness: getClientDataInTransferFromGql(relatedBusiness),
        isArchived,
    });
};
