import { GraphQLSdk as CertSdk } from '@/utils/UodkaClients/Cert/graphql/graphqlSdk';
import { getSignatureFromGql } from '@/utils/UodkaClients/Cert';
import { BillData } from '@/utils/UodkaClients/Invoices/models/InvoiceData';
import { RevokingSignature } from '@/utils/UodkaClients/Invoices/models/Signature';
import {
    getBillDataString,
    stringifyObject,
    getUnixTime,
    getHexDigest,
} from '@/utils/UodkaClients/Invoices/utils/verifySignature';

export const createRevokingSignature = async ({
    businessId,
    resourceId,
    certSdk,
    billData,
}: {
    businessId: string;
    resourceId: string;
    certSdk: CertSdk;
    billData: BillData;
}): Promise<RevokingSignature> => {
    const billDataString = getBillDataString({ billData });
    const agreeToRevokeAt = new Date();
    const agreeToRevokeAtString = stringifyObject({ agreeToRevokeAt: getUnixTime(agreeToRevokeAt) });
    const hexDigest = getHexDigest(billDataString + agreeToRevokeAtString);
    const { createSignature } = await certSdk.createSignature({
        input: {
            businessId,
            resourceId,
            text: hexDigest,
        },
    });
    return new RevokingSignature({
        agreeToRevokeAt,
        signature: getSignatureFromGql(createSignature.signature),
    });
};
