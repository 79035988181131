import { FC, useState } from 'react';

import { ClientData } from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import { PaymentInReceivedBill, IssuedPayment } from '@/utils/UodkaClients/Invoices';

import { BillViewer } from '@/views/bills/BillViewer';
import { ReceivedBillItem } from '@/views/bills/useBillItems';
import { ReceivedBillStatusDetails } from '@/views/bills/BillDrawer/ReceivedBill/ReceivedBillStatusDetails';
import { ReceivedBillMenu } from '@/views/bills/BillDrawer/ReceivedBill/ReceivedBillMenu';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 38,
        display: 'flex',
    },
    right: {
        // marginLeft: 29,
        position: 'fixed',
        left: 740 + 29 + 39 + 170,
    },
}));

export const ReceivedBillDrawerContent: FC<{
    receivedBillItem: ReceivedBillItem;
    updateReceivedBillItem: (newItem: ReceivedBillItem) => void;
    removeReceivedBillItem: () => void;
    clientData: ClientData;
    clientProfile: BusinessProfile | undefined;
}> = ({ receivedBillItem, updateReceivedBillItem, removeReceivedBillItem, clientData, clientProfile }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [payment, setPayment] = useState<PaymentInReceivedBill | IssuedPayment | undefined>(
        receivedBillItem.receivedBill.payment
    );

    return (
        <div className={c.root}>
            <BillViewer
                issuer={{
                    name: receivedBillItem.contactItem.contact.name,
                    profile: receivedBillItem.contactItem.profile?.data,
                }}
                recipient={{ name: clientData.name, profile: clientProfile }}
                billData={receivedBillItem.receivedBill.billData}
            />
            <div className={c.right}>
                <ReceivedBillStatusDetails
                    receivedBill={receivedBillItem.receivedBill}
                    payment={payment}
                    onAddTransfer={({ payment: newPayment }) => setPayment(newPayment)}
                    contactInfo={{
                        id: receivedBillItem.contactItem.id,
                        name: receivedBillItem.contactItem.contact.name,
                        profile: receivedBillItem.contactItem.profile?.data,
                    }}
                    clientData={clientData}
                    clientProfile={clientProfile}
                    removeBillItem={removeReceivedBillItem}
                />
                <ReceivedBillMenu
                    receivedBill={receivedBillItem.receivedBill}
                    contact={receivedBillItem.contactItem.contact}
                    onRevoked={removeReceivedBillItem}
                />
            </div>
        </div>
    );
};
ReceivedBillDrawerContent.displayName = 'ReceivedBillDrawerContent';
