import { LegalTexts, Fees, Fee, TransferFees } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { GraphQLSdk } from '@/utils/UodkaClients/Core/graphql/graphqlSdk';
import { InviteForMember, getInviteForMemberFromGql } from '@/utils/UodkaClients/Core/models/InviteForMember';
import { InviteForContact, getInviteForContactFromGql } from '@/utils/UodkaClients/Core/models/InviteForContact';

export type UodkaFees = Fees;
export type UodkaTransferFees = TransferFees;
export type UodkaFee = Fee;
interface GetInviteForBusinessMemberInput {
    businessId: string;
    inviteCodeText: string;
}
interface GetInviteForContactInput {
    issuerBusinessId: string;
    issuerContactId: string;
    inviteCodeText: string;
}
interface UodkaCorePublicClientInterface {
    getLegalTexts: () => Promise<LegalTexts>;
    getFees: () => Promise<Fees>;
    getInviteForMember: (input: GetInviteForBusinessMemberInput) => Promise<InviteForMember | undefined>;
    getInviteForContact: (input: GetInviteForContactInput) => Promise<InviteForContact | undefined>;
}

interface ConstructorInput {
    graphqlSdk: GraphQLSdk;
}

export class UodkaCorePublicClient implements UodkaCorePublicClientInterface {
    protected _graphqlSdk: GraphQLSdk;

    constructor({ graphqlSdk }: ConstructorInput) {
        this._graphqlSdk = graphqlSdk;
    }

    async getLegalTexts() {
        const { legalTexts } = await this._graphqlSdk.legalTexts();
        return legalTexts;
    }

    async getFees() {
        const { fees } = await this._graphqlSdk.fees();
        return fees;
    }

    async getInviteForMember({ businessId, inviteCodeText }: GetInviteForBusinessMemberInput) {
        const { inviteForBusinessMember } = await this._graphqlSdk.inviteForBusinessMember({
            businessId,
            codeText: inviteCodeText,
        });
        return inviteForBusinessMember ? getInviteForMemberFromGql(inviteForBusinessMember) : undefined;
    }

    async getInviteForContact({ issuerBusinessId, issuerContactId, inviteCodeText }: GetInviteForContactInput) {
        const { inviteForContact } = await this._graphqlSdk.inviteForContact({
            businessId: issuerBusinessId,
            contactId: issuerContactId,
            codeText: inviteCodeText,
        });
        return inviteForContact ? getInviteForContactFromGql(inviteForContact) : undefined;
    }
}
