import { FC } from 'react';

import { TransferConfirmationAPIForGeneral, ClientData } from '@/utils/UodkaClients/Core';
import { useLanguage, ContactItem, useClientProfile } from '@/utils/customHooks';
import ConfirmationModal from '@/components/1_mol/ConfirmationModal';
import FetchingText from '@/components/0_atom/FetchingText';

import { TransferConfirmationModalContent } from '@/views/transactions/AddTransferModal/TransferConfirmationModalContent';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
}));

export const TransferConfirmationModal: FC<{
    refetchBalances: () => Promise<void>;
    transferConfirmationAPI: TransferConfirmationAPIForGeneral | undefined;
    setTransferConfirmationAPI: (transferConfirmationAPI: TransferConfirmationAPIForGeneral | undefined) => void;
    selectedContactItem: ContactItem;
    clientData: ClientData;
    currentBalance: number;
    initializeForm: () => void;
}> = ({
    refetchBalances,
    transferConfirmationAPI,
    setTransferConfirmationAPI,
    selectedContactItem,
    clientData,
    currentBalance,
    initializeForm,
}) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const { clientProfile } = useClientProfile();

    // DATA
    const runAddTransfer = async () => {
        if (transferConfirmationAPI) {
            const { result } = await transferConfirmationAPI.approve();
            await refetchBalances();
            setTransferConfirmationAPI(undefined);
            initializeForm();
            return result;
        }
        throw new Error('TransferConfirmationModal.runAddTransfer: !transferConfirmationAPI');
    };

    return (
        <ConfirmationModal
            isOpen={!!transferConfirmationAPI}
            onClose={() => setTransferConfirmationAPI(undefined)}
            title={txt({
                en: 'Are you sure to create following remittance?',
                ja: '以下の内容で送金します。よろしいですか？',
            })}
            mutation={{
                description: txt({
                    en: `Send ¥${transferConfirmationAPI?.amount.toLocaleString()} to ${
                        selectedContactItem?.contact.name
                    }`,
                    ja: `${
                        selectedContactItem?.contact.name
                    }に${transferConfirmationAPI?.amount.toLocaleString()}円を送金`,
                }),
                run: runAddTransfer,
            }}
            submitText={txt({ en: 'Remit', ja: '送金' })}
        >
            {transferConfirmationAPI &&
            transferConfirmationAPI.contact.id === selectedContactItem?.id &&
            clientData &&
            clientProfile !== 'fetching' ? (
                <TransferConfirmationModalContent
                    transferConfirmationAPI={transferConfirmationAPI}
                    selectedContactItem={selectedContactItem}
                    clientData={clientData}
                    clientProfile={clientProfile}
                    currentBalance={currentBalance}
                />
            ) : (
                <FetchingText />
            )}
        </ConfirmationModal>
    );
};
TransferConfirmationModal.displayName = 'TransferConfirmationModal';
