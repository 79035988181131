import { FC, useState } from 'react';

import { ContactAPIForOwner, InviteForContactAPIForOwner, MutationValidationResult } from '@/utils/UodkaClients/Core';
import { IssuedBillAPIForGeneral } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';
import InformationModal from '@/components/1_mol/InformationModal';

import { IssuedBillShareModalContentInvite } from '@/views/bills/BillDrawer/IssuedBill/IssuedBillShareModal/Invite';
import { IssuedBillShareModalContentNoInvite } from '@/views/bills/BillDrawer/IssuedBill/IssuedBillShareModal/NoInvite';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    content: {
        width: 360,
    },
}));

export interface ContactAPIForOwnerWithoutInviteAPI extends ContactAPIForOwner {
    inviteAPI: undefined;
    issueInvite: (
        durationDays?: number
    ) => Promise<{
        inviteAPI: InviteForContactAPIForOwner;
        result: MutationValidationResult;
    }>;
}

export const IssuedBillShareModal: FC<{
    issuedBillAPI: IssuedBillAPIForGeneral;
    contactAPI: ContactAPIForOwner;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}> = ({ issuedBillAPI, contactAPI, isOpen, setIsOpen }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [inviteAPI, setInviteAPI] = useState(contactAPI.inviteAPI);

    return (
        <InformationModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            title={txt({
                en: inviteAPI ? 'URL for sharing is available' : 'URL for sharing',
                ja: inviteAPI ? '共有URLを発行済み' : '共有URL',
            })}
        >
            <div className={c.content}>
                {inviteAPI ? (
                    <IssuedBillShareModalContentInvite
                        issuedBillAPI={issuedBillAPI}
                        inviteAPI={inviteAPI}
                        setInviteAPI={setInviteAPI}
                        closeModal={() => setIsOpen(false)}
                    />
                ) : contactAPI.issueInvite ? (
                    <IssuedBillShareModalContentNoInvite
                        contactAPI={contactAPI}
                        issueInvite={contactAPI.issueInvite}
                        setInviteAPI={setInviteAPI}
                    />
                ) : (
                    <></>
                )}
            </div>
        </InformationModal>
    );
};
IssuedBillShareModal.displayName = 'IssuedBillShareModal';
