import { Charge, getChargeFromGql } from '@/utils/UodkaClients/Core';
import { GqlDateTimeData } from '@/utils/UodkaClients/Invoices/graphql/customScalars';
import { ReceiptInIssuedBill as GqlReceiptInIssuedBill } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import { ReceiptData, getReceiptDataFromGql } from '@/utils/UodkaClients/Invoices/models/InvoiceData';

interface ReceiptInIssuedBillInterface {
    id: string;
    createdAt: Date;
    receiptData: ReceiptData;
    charge: Charge;
}

export class ReceiptInIssuedBill implements ReceiptInIssuedBillInterface {
    protected _id: string;

    protected _createdAt: Date;

    protected _receiptData: ReceiptData;

    protected _charge: Charge;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get receiptData() {
        return this._receiptData;
    }

    get charge() {
        return this._charge;
    }

    constructor({ id, createdAt, receiptData, charge }: ReceiptInIssuedBillInterface) {
        this._id = id;
        this._createdAt = createdAt;
        this._receiptData = receiptData;
        this._charge = charge;
    }
}

export const getReceiptInIssuedBillFromGql = ({ id, createdAt, receiptData, charge }: GqlReceiptInIssuedBill) => {
    return new ReceiptInIssuedBill({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        receiptData: getReceiptDataFromGql(receiptData),
        charge: getChargeFromGql(charge),
    });
};
