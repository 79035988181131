import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 347 353" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M309.969 145.479C311.487 139.998 312.3 134.297 312.3 128.429C312.3 94.1627 285.136 66.4287 251.575 66.4287C242.52 66.4287 233.737 68.4216 225.55 72.4073C208.417 47.4966 179.844 31.0002 147.475 31.0002C96.2925 31.0002 54.3814 72.2412 52.1584 124.111C21.2537 135.182 0 165.131 0 199.286C0 243.35 34.9169 279 78.075 279H277.6C315.933 279 347 247.336 347 208.143C347 182.125 333.012 157.879 309.969 145.479ZM277.6 261.286H78.075C44.5136 261.286 17.35 233.552 17.35 199.286C17.35 167.843 40.2303 141.881 69.9422 137.839C69.5627 134.739 69.4 131.584 69.4 128.429C69.4 84.4198 104.371 48.7145 147.475 48.7145C180.169 48.7145 208.146 69.1966 219.803 98.3145C227.719 89.6234 238.996 84.143 251.575 84.143C275.54 84.143 294.95 103.961 294.95 128.429C294.95 138.67 291.534 148.136 285.787 155.664C310.673 159.65 329.65 181.627 329.65 208.143C329.65 237.482 306.336 261.286 277.6 261.286Z"
                fill="currentColor"
            />
            <path
                d="M17.7141 351.033C16.1774 352.62 13.645 352.661 12.0579 351.124L4.87366 344.168C3.28655 342.631 3.24568 340.099 4.78236 338.512L330.725 1.87384C332.262 0.286739 334.794 0.245862 336.381 1.78254L343.565 8.73856C345.153 10.2752 345.193 12.8076 343.657 14.3947L17.7141 351.033Z"
                fill="currentColor"
            />
        </svg>
    );
};
Svg.displayName = 'svg/cloud-unavailable';
export default Svg;
