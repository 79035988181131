import { BalanceWithInvoice } from '@/utils/UodkaClients/Invoices';

import {
    getChargeData,
    getTransferData,
    getPaymentData,
    getBalanceData,
    getBillData,
    getBillSignaturesData,
    getReceiptData,
    getSharedTransferData,
} from '@/views/records/getModelData';

export const parepareRecordsJson = ({ balanceWithInvoices }: { balanceWithInvoices: BalanceWithInvoice[] }) => {
    const arr = balanceWithInvoices.map((balanceWithInvoice) => {
        const { balance, issuedPayment, issuedReceipt, receivedPayment, receivedReceipt } = balanceWithInvoice;
        const balanceData = getBalanceData({ balance });
        const issuedPaymentData = (() => {
            if (issuedPayment) {
                const { bill, createdAt, id, paymentData, transfer } = issuedPayment;
                return {
                    bill: (() => {
                        if (bill) {
                            const { billData, createdAt, id, signatures } = bill;
                            return {
                                billData: getBillData({ billData }),
                                createdAt,
                                id,
                                signatures: getBillSignaturesData({ billSignatures: signatures }),
                            };
                        }
                        return undefined;
                    })(),
                    createdAt,
                    id,
                    paymentData: getPaymentData({ paymentData }),
                    transfer: getTransferData({ transfer }),
                };
            }
            return undefined;
        })();
        const issuedReceiptData = (() => {
            if (issuedReceipt) {
                const { bill, charge, createdAt, id, receiptData } = issuedReceipt;
                return {
                    bill: (() => {
                        if (bill) {
                            const { billData, createdAt, id, signatures } = bill;
                            return {
                                billData: getBillData({ billData }),
                                createdAt,
                                id,
                                signatures: getBillSignaturesData({ billSignatures: signatures }),
                            };
                        }
                        return undefined;
                    })(),
                    charge: getChargeData({ charge }),
                    createdAt,
                    id,
                    receiptData: getReceiptData({ receiptData }),
                };
            }
            return undefined;
        })();
        const receivedPaymentData = (() => {
            if (receivedPayment) {
                const { bill, charge, createdAt, id, incomingTransfer, paymentData } = receivedPayment;
                return {
                    bill: (() => {
                        if (bill) {
                            const { billData, createdAt, id, signatures } = bill;
                            return {
                                billData: getBillData({ billData }),
                                createdAt,
                                id,
                                signatures: getBillSignaturesData({ billSignatures: signatures }),
                            };
                        }
                        return undefined;
                    })(),
                    charge: charge ? getChargeData({ charge }) : undefined,
                    createdAt,
                    id,
                    incomingTransfer: getSharedTransferData({ transfer: incomingTransfer }),
                    paymentData: getPaymentData({ paymentData }),
                };
            }
            return undefined;
        })();
        const receivedReceiptData = (() => {
            if (receivedReceipt) {
                const { bill, createdAt, id, receiptData, transfer } = receivedReceipt;
                return {
                    bill: (() => {
                        if (bill) {
                            const { billData, createdAt, id, signatures } = bill;
                            return {
                                billData: getBillData({ billData }),
                                createdAt,
                                id,
                                signatures: getBillSignaturesData({ billSignatures: signatures }),
                            };
                        }
                        return undefined;
                    })(),
                    createdAt,
                    id,
                    receiptData: getReceiptData({ receiptData }),
                    transfer: getTransferData({ transfer }),
                };
            }
            return undefined;
        })();
        return {
            balance: balanceData,
            issuedPayment: issuedPaymentData,
            issuedReceipt: issuedReceiptData,
            receivedPayment: receivedPaymentData,
            receivedReceipt: receivedReceiptData,
        };
    });
    return JSON.stringify(arr, null, 4);
};
