import { getColorFromNumbers } from '@/utils/utilFunctions/getColorFromNumbers';

export const provideTextColorForBackgroundColor = (textColor: string, backgroundColor?: string) => {
    if (!backgroundColor) {
        return textColor;
    }
    if (backgroundColor.match(/^#/)) {
        const str = backgroundColor.replace(/^#/, ''); // 'FFFFFF'
        if (str.length === 6) {
            const hexStrs = [
                str.charAt(0) + str.charAt(1),
                str.charAt(2) + str.charAt(3),
                str.charAt(4) + str.charAt(5),
            ]; // ['FF', 'FF', 'FF']
            const numbers = hexStrs.map((hexStr) => parseInt(hexStr, 16)); // [255, 255, 255]
            return getColorFromNumbers(numbers, textColor);
        }
        if (str.length === 3) {
            const hexStrs = [str.charAt(0), str.charAt(1), str.charAt(2)]; // ['F', 'F', 'F']
            const numbers = hexStrs.map((hexStr) => parseInt(hexStr + hexStr, 16)); // [255, 255, 255]
            return getColorFromNumbers(numbers, textColor);
        }
        return textColor;
    }
    if (backgroundColor.match(/^rgb\(/)) {
        const withComma = backgroundColor.replace(/^rgb\(/, '').replace(/\).*$/, ''); // '255,255,255'
        const numberStrs = withComma.split(','); // ['255', '255', '255']
        const numbers = numberStrs.map((numberStr) => Number(numberStr)); // [255, 255, 255]
        return getColorFromNumbers(numbers, textColor);
    }
    return textColor;
};
