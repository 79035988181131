import { FC } from 'react';

import { BusinessSnapshot } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';
import { getStrWithBr } from '@/utils/utilFunctions';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    sectionTitle: {
        fontSize: theme.typography.caption.fontSize,
    },
    name: {
        fontWeight: 600,
    },
    nameValue: {
        fontSize: theme.typography.h1.fontSize,
        display: 'inline-block',
    },
    namePost: {
        fontSize: theme.typography.body1.fontSize,
        display: 'inline-block',
        marginLeft: '.5em',
    },
    item: {
        marginTop: 4,
        fontSize: theme.typography.caption.fontSize,
    },
}));

export const BillTo: FC<{
    recipient: BusinessSnapshot;
}> = ({ recipient }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    return (
        <div className={c.root}>
            <div className={c.sectionTitle}>{txt({ en: 'Bill To', ja: 'ご請求先' })}</div>
            <div className={c.name}>
                <div className={c.nameValue}>{recipient.name}</div>
                <div className={c.namePost}>{txt({ en: '', ja: '様' })}</div>
            </div>
            {recipient.items.length > 0 &&
                recipient.items.map((item, i) => (
                    <div key={i} className={c.item}>
                        {getStrWithBr(item.value)}
                    </div>
                ))}
        </div>
    );
};
BillTo.displayName = 'BillTo';
