import {
    BusinessMemberRole,
    MutationValidationResult,
} from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { UodkaCoreClientForObserver } from '@/utils/UodkaClients/Core/clients/Business/ForObserver';
import {
    ClientDataAPIForOwner,
    ContactAPIForOwner,
    MemberAPIForOwner,
    InviteForMemberAPIForOwner,
    ExternalBankAccountInterface,
    getClientDataFromGql,
    getContactWithInviteFromGql,
    getMemberInBusinessFromGql,
    getInviteForMemberInBusinessFromGql,
} from '@/utils/UodkaClients/Core/models';
import { getValidBankAccount } from '@/utils/UodkaClients/Core/utils';

interface IssueInviteForMemberInput {
    durationDays: number;
    numberOfSeats: number;
    role: BusinessMemberRole;
}

interface ApplyInviteForContactInput {
    issuerBusinessId: string;
    issuerContactId: string;
    inviteCodeText: string;
    requestContactId?: string;
}

interface AddContactInput {
    name: string;
    additionalId?: string;
    notificationEmail?: string;
    externalBankAccount?: ExternalBankAccountInterface;
}

interface UodkaCoreClientForOwnerInterface {
    getClientDataAPI: () => Promise<ClientDataAPIForOwner>;
    getContactAPI: (input: { id: string }) => Promise<ContactAPIForOwner | undefined>;
    getContactAPIs: () => Promise<ContactAPIForOwner[]>;
    getMemberAPIs: () => Promise<MemberAPIForOwner[]>;
    getInviteForMemberAPIs: () => Promise<InviteForMemberAPIForOwner[]>;
    addContact: (
        input: AddContactInput
    ) => Promise<{
        contactAPI: ContactAPIForOwner;
        result: MutationValidationResult;
    }>;
    issueInviteForMember: (
        input: IssueInviteForMemberInput
    ) => Promise<{
        inviteForMemberAPI: InviteForMemberAPIForOwner;
        result: MutationValidationResult;
    }>;
    applyInviteForContact: (
        input: ApplyInviteForContactInput
    ) => Promise<{
        contactAPI: ContactAPIForOwner;
        result: MutationValidationResult;
    }>;
    archive: () => Promise<{
        result: MutationValidationResult;
    }>;
}

export class UodkaCoreClientForOwner extends UodkaCoreClientForObserver implements UodkaCoreClientForOwnerInterface {
    async getClientDataAPI() {
        const { business } = await this._graphqlSdk.business({
            id: this._id,
        });
        return new ClientDataAPIForOwner({
            graphqlSdk: this._graphqlSdk,
            ...getClientDataFromGql(business).data,
        });
    }

    async getContactAPI({ id }: { id: string }) {
        // inviteがundefinedになっているので注意
        const { contact } = await this._graphqlSdk.contact({
            businessId: this._id,
            id,
        });
        if (!contact) {
            return undefined;
        }
        return new ContactAPIForOwner({
            graphqlSdk: this._graphqlSdk,
            parentBusinessId: this._id,
            ...getContactWithInviteFromGql(contact).data,
        });
    }

    async getContactAPIs() {
        const { contactWithInvites } = await this._graphqlSdk.contactWithInvites({
            businessId: this._id,
        });
        return contactWithInvites.map(
            (contact) =>
                new ContactAPIForOwner({
                    graphqlSdk: this._graphqlSdk,
                    parentBusinessId: this._id,
                    ...getContactWithInviteFromGql(contact).data,
                })
        );
    }

    async getMemberAPIs() {
        const { businessMembersInBusiness } = await this._graphqlSdk.businessMembersInBusiness({
            businessId: this._id,
        });
        return businessMembersInBusiness.map(
            (businessMemberInBusiness) =>
                new MemberAPIForOwner({
                    graphqlSdk: this._graphqlSdk,
                    parentBusinessId: this._id,
                    ...getMemberInBusinessFromGql(businessMemberInBusiness).data,
                })
        );
    }

    async getInviteForMemberAPIs() {
        const { inviteForBusinessMembers } = await this._graphqlSdk.inviteForBusinessMembers({
            businessId: this._id,
        });
        return inviteForBusinessMembers.map(
            (inviteForBusinessMember) =>
                new InviteForMemberAPIForOwner({
                    graphqlSdk: this._graphqlSdk,
                    parentBusinessId: this._id,
                    ...getInviteForMemberInBusinessFromGql(inviteForBusinessMember).data,
                })
        );
    }

    async addContact({
        name,
        additionalId,
        notificationEmail,
        externalBankAccount: externalBankAccount_,
    }: AddContactInput) {
        const externalBankAccount = (() => {
            if (externalBankAccount_) {
                const { isValid, bankAccount } = getValidBankAccount(externalBankAccount_);
                if (isValid && bankAccount) {
                    return bankAccount;
                }
                throw new Error('UodkaCoreClientForOwner.addContact: invalid bankAccount');
            }
            return undefined;
        })();
        const { createContact } = await this._graphqlSdk.createContact({
            input: {
                businessId: this._id,
                additionalId,
                name,
                notificationEmail,
                externalBankAccount,
            },
        });
        const { contact, result } = createContact;
        return {
            contactAPI: new ContactAPIForOwner({
                graphqlSdk: this._graphqlSdk,
                parentBusinessId: this._id,
                ...getContactWithInviteFromGql(contact).data,
            }),
            result,
        };
    }

    async issueInviteForMember({ durationDays, numberOfSeats, role }: IssueInviteForMemberInput) {
        const { createInviteForBusinessMember } = await this._graphqlSdk.createInviteForBusinessMember({
            input: {
                businessId: this._id,
                durationDays,
                numberOfSeats,
                role,
            },
        });
        const { inviteForBusinessMember, result } = createInviteForBusinessMember;
        return {
            inviteForMemberAPI: new InviteForMemberAPIForOwner({
                graphqlSdk: this._graphqlSdk,
                parentBusinessId: this._id,
                ...getInviteForMemberInBusinessFromGql(inviteForBusinessMember).data,
            }),
            result,
        };
    }

    async applyInviteForContact({
        issuerBusinessId,
        issuerContactId,
        inviteCodeText,
        requestContactId,
    }: ApplyInviteForContactInput) {
        const { applyInviteForContactWithBusiness } = await this._graphqlSdk.applyInviteForContactWithBusiness({
            input: {
                requestBusinessId: this._id,
                requestContactId,
                issuerBusinessId,
                issuerContactId,
                inviteCodeText,
            },
        });
        const { contact, result } = applyInviteForContactWithBusiness;
        return {
            contactAPI: new ContactAPIForOwner({
                graphqlSdk: this._graphqlSdk,
                parentBusinessId: this._id,
                ...getContactWithInviteFromGql(contact).data,
            }),
            result,
        };
    }

    async archive() {
        const { archiveBusiness } = await this._graphqlSdk.archiveBusiness({
            input: {
                id: this._id,
            },
        });
        const { result } = archiveBusiness;
        return {
            result,
        };
    }
}
