import { MutationValidationResult } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { GraphQLSdk } from '@/utils/UodkaClients/Core/graphql/graphqlSdk';
import { ContactAPIForGeneral } from '@/utils/UodkaClients/Core/models/Contact/GeneralAPI';
import { ContactWithInviteInterface } from '@/utils/UodkaClients/Core/models/Contact/data/ContactWithInvite';
import {
    ExternalBankAccountInterface,
    getExternalBankAccountFromGql,
} from '@/utils/UodkaClients/Core/models/BankAccount';
import {
    InviteForContactAPIForOwner,
    getInviteForContactInContactFromGql,
} from '@/utils/UodkaClients/Core/models/InviteForContact';
import { getValidBankAccount } from '@/utils/UodkaClients/Core/utils';

interface ContactAPIForOwnerInterface {
    inviteAPI?: InviteForContactAPIForOwner;
    addAdditionalId: (additionalId: string) => Promise<MutationValidationResult>;
    modifyName: (newName: string) => Promise<MutationValidationResult>;
    modifyNotificationEmail: (newNotificationEmail: string) => Promise<MutationValidationResult>;
    modifyExternalBankAccount: (newBankAccount?: ExternalBankAccountInterface) => Promise<MutationValidationResult>;
    issueInvite?: (
        durationDays?: number
    ) => Promise<{
        inviteAPI: InviteForContactAPIForOwner;
        result: MutationValidationResult;
    }>;
    archive: () => Promise<MutationValidationResult>;
}

interface ConstructorInput extends ContactWithInviteInterface {
    graphqlSdk: GraphQLSdk;
    parentBusinessId: string;
}

export class ContactAPIForOwner extends ContactAPIForGeneral implements ContactAPIForOwnerInterface {
    protected _inviteAPI?: InviteForContactAPIForOwner;

    get inviteAPI() {
        return this._inviteAPI;
    }

    constructor(input: ConstructorInput) {
        super(input);
        this._inviteAPI = (() => {
            if (input.invite) {
                return new InviteForContactAPIForOwner({
                    graphqlSdk: input.graphqlSdk,
                    parentBusinessId: input.parentBusinessId,
                    parentContactId: input.id,
                    onRemove: () => delete this._inviteAPI,
                    ...input.invite.data,
                });
            }
            return undefined;
        })();
    }

    async addAdditionalId(newAdditionalId: string) {
        if (this._additionalId) {
            throw new Error('ContactAPIForOwner.addAdditionalId: additionalId is already set.');
        }
        const { addContactAdditionalId } = await this._graphqlSdk.addContactAdditionalId({
            input: {
                businessId: this._parentBusinessId,
                id: this._id,
                additionalId: newAdditionalId,
            },
        });
        this._additionalId = addContactAdditionalId.contact.additionalId || undefined;
        return addContactAdditionalId.result;
    }

    async modifyName(newName: string) {
        const { modifyContactName } = await this._graphqlSdk.modifyContactName({
            input: {
                businessId: this._parentBusinessId,
                id: this._id,
                oldValue: this._name,
                newValue: newName,
                requiredInterval: 5,
            },
        });
        this._name = modifyContactName.contact.name;
        return modifyContactName.result;
    }

    async modifyNotificationEmail(newNotificationEmail?: string) {
        const { modifyContactNotificationEmail } = await this._graphqlSdk.modifyContactNotificationEmail({
            input: {
                businessId: this._parentBusinessId,
                id: this._id,
                oldValue: this._notificationEmail,
                newValue: newNotificationEmail,
                requiredInterval: 5,
            },
        });
        this._notificationEmail = modifyContactNotificationEmail.contact.notificationEmail || undefined;
        return modifyContactNotificationEmail.result;
    }

    async modifyExternalBankAccount(newBankAccount?: ExternalBankAccountInterface) {
        const newValue = (() => {
            if (newBankAccount) {
                const { isValid, bankAccount } = getValidBankAccount(newBankAccount);
                if (isValid && bankAccount) {
                    return bankAccount;
                }
                throw new Error('ContactAPIForOwner.modifyBankAccount:invalid newBankAccount');
            }
            return undefined;
        })();
        const { modifyContactExternalBankAccount } = await this._graphqlSdk.modifyContactExternalBankAccount({
            input: {
                businessId: this._parentBusinessId,
                id: this._id,
                oldValue: this._externalBankAccount?.data,
                newValue,
                requiredInterval: 5,
            },
        });
        this._externalBankAccount = modifyContactExternalBankAccount.contact.externalBankAccount
            ? getExternalBankAccountFromGql(modifyContactExternalBankAccount.contact.externalBankAccount)
            : undefined;
        return modifyContactExternalBankAccount.result;
    }

    issueInvite = (() => {
        if (this.relatedBusiness) {
            return undefined;
        }
        if (this.inviteAPI) {
            return undefined;
        }
        return async (durationDays?: number) => {
            const { createInviteForContact } = await this._graphqlSdk.createInviteForContact({
                input: {
                    businessId: this._parentBusinessId,
                    contactId: this._id,
                    durationDays: durationDays || 30,
                },
            });
            const newInviteAPI = new InviteForContactAPIForOwner({
                graphqlSdk: this._graphqlSdk,
                parentBusinessId: this._parentBusinessId,
                parentContactId: this._id,
                onRemove: () => delete this._inviteAPI,
                ...getInviteForContactInContactFromGql(createInviteForContact.inviteForContact).data,
            });
            this._inviteAPI = newInviteAPI;
            return {
                inviteAPI: newInviteAPI,
                result: createInviteForContact.result,
            };
        };
    })();

    async archive() {
        const { archiveContact } = await this._graphqlSdk.archiveContact({
            input: {
                businessId: this._parentBusinessId,
                id: this._id,
            },
        });
        return archiveContact.result;
    }
}
