import { useState } from 'react';

import { useAccountingItems } from '@/views/records/useAccountingItems';

const getOrder = ({ ascOrder, order }: { ascOrder: 1 | -1; order: 'asc' | 'desc' }) => {
    if (order === 'asc') {
        return ascOrder;
    }
    return ascOrder * -1;
};

export const useFilteredAccountingItems = () => {
    // CUSTOME HOOKS
    const { accountingItems, balanceWithInvoices } = useAccountingItems();

    // STATES
    const [isContactId, setIsContactId] = useState(false);
    const [isContactName, setIsContactName] = useState(true);
    const [isItemName, setIsItemName] = useState(true);
    const [isKind, setIsKind] = useState(true);
    const [isSelfDepositAmount, setIsSelfDepositAmount] = useState(true);
    const [isReceivedBillAmount, setIsReceivedBillAmount] = useState(true);
    const [isIssuedBillAmount, setIsIssuedBillAmount] = useState(true);
    const [isIssuedPaymentAmount, setIsIssuedPaymentAmount] = useState(true);
    const [isReceivedPaymentAmount, setIsReceivedPaymentAmount] = useState(true);
    const [isPaidTaxAmount, setIsPaidTaxAmount] = useState(true);
    const [isReceivedTaxAmount, setIsReceivedTaxAmount] = useState(true);
    const [isBankAccount, setIsBankAccount] = useState(false);
    const [isBillDate, setIsBillDate] = useState(true);
    const [isPaymentDate, setIsPaymentDate] = useState(true);
    const [sortBy, setSortBy] = useState<'billDate' | 'issueDate'>('billDate');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
    const [toDate, setToDate] = useState<Date | undefined>(undefined);

    // DATA
    const displayItems = (() => {
        if (accountingItems) {
            const termedItems = accountingItems.filter((item) => {
                const isFromOk = (() => {
                    if (fromDate) {
                        return fromDate <= item.paymentDate;
                    }
                    return true;
                })();
                const isToOk = (() => {
                    if (toDate) {
                        if (item.billDate) {
                            return item.billDate <= toDate;
                        }
                        return item.paymentDate <= toDate;
                    }
                    return true;
                })();
                return isFromOk && isToOk;
            });
            const sortedItems = termedItems.sort((a, b) => {
                if (sortBy === 'billDate') {
                    const aDate = a.billDate || a.paymentDate;
                    const bDate = b.billDate || b.paymentDate;
                    return bDate > aDate
                        ? getOrder({ ascOrder: -1, order: sortOrder })
                        : getOrder({ ascOrder: 1, order: sortOrder });
                }
                return b.paymentDate > a.paymentDate
                    ? getOrder({ ascOrder: -1, order: sortOrder })
                    : getOrder({ ascOrder: 1, order: sortOrder });
            });
            return sortedItems;
        }
        return undefined;
    })();

    return {
        accountingItems: displayItems,
        balanceWithInvoices,
        isContactId,
        setIsContactId,
        isContactName,
        setIsContactName,
        isItemName,
        setIsItemName,
        isKind,
        setIsKind,
        isSelfDepositAmount,
        setIsSelfDepositAmount,
        isReceivedBillAmount,
        setIsReceivedBillAmount,
        isIssuedBillAmount,
        setIsIssuedBillAmount,
        isIssuedPaymentAmount,
        setIsIssuedPaymentAmount,
        isReceivedPaymentAmount,
        setIsReceivedPaymentAmount,
        isPaidTaxAmount,
        setIsPaidTaxAmount,
        isReceivedTaxAmount,
        setIsReceivedTaxAmount,
        isBankAccount,
        setIsBankAccount,
        isBillDate,
        setIsBillDate,
        isPaymentDate,
        setIsPaymentDate,
        sortBy,
        setSortBy,
        sortOrder,
        setSortOrder,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
    };
};
