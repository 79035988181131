import { IssuedBill } from '@/utils/UodkaClients/Invoices';

import { getBillData } from '@/views/records/getModelData/getBillData';
import { getChargeData } from '@/views/records/getModelData/getChargeData';
import { getSharedTransferData } from '@/views/records/getModelData/getSharedTransferData';
import { getPaymentData } from '@/views/records/getModelData/getPaymentData';
import { getReceiptData } from '@/views/records/getModelData/getReceiptData';
import { getBillSignaturesData } from '@/views/records/getModelData/getBillSignaturesData';

export const getIssuedBillData = ({ issuedBill }: { issuedBill: IssuedBill }) => {
    const { billData, contact, createdAt, id, payment, receipt, signatures } = issuedBill;
    return {
        billData: getBillData({ billData }),
        contact: (() => {
            const { additionalId, id, isArchived, name, relatedBusiness } = contact;
            return {
                additionalId,
                id,
                isArchived,
                name,
                relatedBusiness: (() => {
                    if (relatedBusiness) {
                        const { id, isArchived, language, name } = relatedBusiness;
                        return { id, isArchived, language, name };
                    }
                    return undefined;
                })(),
            };
        })(),
        createdAt,
        id,
        payment: (() => {
            if (payment) {
                const { charge, createdAt, id, incomingTransfer, paymentData } = payment;
                return {
                    charge: charge ? getChargeData({ charge }) : undefined,
                    createdAt,
                    id,
                    incomingTransfer: getSharedTransferData({ transfer: incomingTransfer }),
                    paymentData: getPaymentData({ paymentData }),
                };
            }
            return undefined;
        })(),
        receipt: (() => {
            if (receipt) {
                const { charge, createdAt, id, receiptData } = receipt;
                return {
                    charge: charge ? getChargeData({ charge }) : undefined,
                    createdAt,
                    id,
                    receiptData: getReceiptData({ receiptData }),
                };
            }
            return undefined;
        })(),
        signatures: getBillSignaturesData({ billSignatures: signatures }),
    };
};
