import { FC } from 'react';

import { ClientData } from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';

import { IssuedBillItem } from '@/views/bills/useBillItems';
import { BillViewer } from '@/views/bills/BillViewer';
import { IssuedBillStatusDetails } from '@/views/bills/BillDrawer/IssuedBill/IssuedBillStatusDetails';
import { IssuedBillMenu } from '@/views/bills/BillDrawer/IssuedBill/IssuedBillMenu';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 38,
        display: 'flex',
    },
    right: {
        // marginLeft: 29,
        position: 'fixed',
        left: 740 + 29 + 39 + 170,
    },
}));

export const IssuedBillDrawerContent: FC<{
    issuedBillItem: IssuedBillItem;
    updateIssuedBillItem: (newItem: IssuedBillItem) => void;
    removeIssuedBillItem: () => void;
    clientData: ClientData;
    clientProfile: BusinessProfile | undefined;
}> = ({ issuedBillItem, updateIssuedBillItem, removeIssuedBillItem, clientData, clientProfile }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={c.root}>
            <BillViewer
                issuer={{ name: clientData.name, profile: clientProfile }}
                recipient={{
                    name: issuedBillItem.issuedBill.contact.name,
                    profile: issuedBillItem.contactItem.profile?.data,
                }}
                billData={issuedBillItem.issuedBill.billData}
            />
            <div className={c.right}>
                <IssuedBillStatusDetails issuedBill={issuedBillItem.issuedBill} />
                <IssuedBillMenu
                    issuedBill={issuedBillItem.issuedBill}
                    contact={issuedBillItem.contactItem.contact}
                    onRevoked={removeIssuedBillItem}
                />
            </div>
        </div>
    );
};
IssuedBillDrawerContent.displayName = 'IssuedBillDrawerContent';
