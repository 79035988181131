import { BillSignatures as GqlBillSignatures } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import {
    BillIssuerSignatures,
    getBillIssuerSignaturesFromGql,
} from '@/utils/UodkaClients/Invoices/models/BillSignatures/BillIssuerSignatures';
import {
    BillRecipientSignatures,
    getBillRecipientSignaturesFromGql,
} from '@/utils/UodkaClients/Invoices/models/BillSignatures/BillRecipientSignatures';

export interface BillSignaturesInterface {
    issuer: BillIssuerSignatures;
    recipient: BillRecipientSignatures;
}

export class BillSignatures implements BillSignaturesInterface {
    protected _issuer: BillIssuerSignatures;

    protected _recipient: BillRecipientSignatures;

    get issuer() {
        return this._issuer;
    }

    get recipient() {
        return this._recipient;
    }

    constructor({ issuer, recipient }: BillSignaturesInterface) {
        this._issuer = issuer;
        this._recipient = recipient;
    }
}

export const getBillSignaturesFromGql = ({ issuer, recipient }: GqlBillSignatures) => {
    return new BillSignatures({
        issuer: getBillIssuerSignaturesFromGql(issuer),
        recipient: getBillRecipientSignaturesFromGql(recipient),
    });
};
