import {
    UserWithBusinessMembers as GqlUserWithBusinessMembers,
    Language,
} from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { MemberInUser, getMemberInUserFromGql } from '@/utils/UodkaClients/Core/models/Member';

interface UserWithMembersInterface {
    id: string;
    firebaseUid: string;
    email: string;
    businessMembers: MemberInUser[];
    language?: Language;
}

export class UserWithMembers implements UserWithMembersInterface {
    protected _id: string;

    protected _firebaseUid: string;

    protected _email: string;

    protected _businessMembers: MemberInUser[];

    protected _language?: Language;

    get id() {
        return this._id;
    }

    get firebaseUid() {
        return this._firebaseUid;
    }

    get email() {
        return this._email;
    }

    get businessMembers() {
        return this._businessMembers;
    }

    get language() {
        return this._language;
    }

    get data() {
        return {
            id: this.id,
            firebaseUid: this.firebaseUid,
            email: this.email,
            businessMembers: this.businessMembers,
            language: this.language,
        };
    }

    constructor({ id, firebaseUid, email, businessMembers, language }: UserWithMembersInterface) {
        this._id = id;
        this._firebaseUid = firebaseUid;
        this._email = email;
        this._businessMembers = businessMembers;
        this._language = language;
    }
}

export const getUserWithMembersFromGql = ({
    id,
    firebaseUid,
    email,
    businessMembers,
    language,
}: GqlUserWithBusinessMembers) => {
    return new UserWithMembers({
        id,
        firebaseUid,
        email,
        businessMembers: businessMembers.map((businessMember) => getMemberInUserFromGql(businessMember)),
        language: language || undefined,
    });
};
