import { Payee } from '@/utils/UodkaClients/Invoices';

export const getPayeeData = ({ payee }: { payee: Payee }) => {
    return {
        uodkaBankAccount: (() => {
            const { isDepositAccount, bankCode, branchCode, accountNumber, accountHolderName } = payee.uodkaBankAccount;
            return {
                isDepositAccount,
                bankCode,
                branchCode,
                accountNumber,
                accountHolderName,
            };
        })(),
    };
};
