import { FC, useState } from 'react';
import clsx from 'clsx';

import { Contact } from '@/utils/UodkaClients/Core';
import { ReceivedBill, ReceivedBillAPIForGeneral } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';
import { downloadTextFile } from '@/utils/utilFunctions';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { getEnvVariables } from '@/utils/envVariables';
import Button from '@/components/0_atom/Button';
import CopyableText from '@/components/0_atom/CopyableText';
import { ButtonWithTextMenu } from '@/components/1_mol/ButtonWithMenu';

import SvgTrash from '@/svgs/trash';
import SvgCode from '@/svgs/code';
import SvgSpreadsheet from '@/svgs/spreadsheet';
import SvgPdf from '@/svgs/pdf';
import SvgDownload from '@/svgs/download';

import { RevokeModal } from '@/views/bills/BillDrawer/RevokeModal';
import { parepareReceivedBillJson } from '@/views/bills/parepareReceivedBillJson';
import { parepareBillCsv } from '@/views/bills/parepareBillCsv';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 27,
    },
    label: {
        color: theme.palette.text.disabled,
        fontWeight: 600,
        fontSize: theme.typography.caption.fontSize,
        marginBottom: 10,
    },
    content: {},
    removeButton: {
        minWidth: 0,
        padding: 10,
        color: theme.palette.error.main,
    },
    downloadButton: {
        minWidth: 0,
        padding: 10,
    },
    svg: {
        width: 15,
        height: 15,
    },
    copyUrl: {
        background: 'none',
        padding: '10px !important',
        '& svg': {
            width: '15px !important',
            height: '15px !important',
        },
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.04)',
        },
    },
}));

export const ReceivedBillMenu: FC<{
    className?: string;
    receivedBill: ReceivedBill;
    contact: Contact;
    onRevoked: () => void;
}> = ({ className, receivedBill, contact, onRevoked }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { currentBusiness } = useBusinessCtxAbsolutely();
    const { txt } = useLanguage();
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

    return (
        <>
            <div className={clsx(className, c.root)}>
                <MuiTypography className={c.label}>{txt({ en: 'Menu', ja: 'メニュー' })}</MuiTypography>
                <div className={c.content}>
                    {contact.relatedBusiness && (
                        <CopyableText
                            copyText={`${getEnvVariables().billsRootUrl}/${contact.relatedBusiness.id}/${
                                contact.relatedBusiness.relatedContactId
                            }/${receivedBill.id}/${currentBusiness.id}/${contact.id}`}
                            isIconButton
                            className={c.copyUrl}
                            tipHoverText={txt({ en: 'Copy URL for sharing', ja: '共有URLをコピー' })}
                        />
                    )}
                    {receivedBill instanceof ReceivedBillAPIForGeneral && (
                        <Button className={c.removeButton} onClick={() => setIsRemoveModalOpen(true)}>
                            <SvgTrash className={c.svg} />
                        </Button>
                    )}
                    <ButtonWithTextMenu
                        className={c.downloadButton}
                        items={[
                            {
                                icon: <SvgCode />,
                                text: 'JSON',
                                onClick: () => {
                                    const data = parepareReceivedBillJson({ receivedBill });
                                    downloadTextFile({
                                        fileName: 'uodka_received_bill.json',
                                        text: data,
                                        type: 'text/json',
                                    });
                                },
                            },
                            {
                                icon: <SvgSpreadsheet />,
                                text: 'CSV',
                                onClick: () => {
                                    const data = parepareBillCsv({ billData: receivedBill.billData, txt });
                                    downloadTextFile({
                                        fileName: 'uodka_received_bill.csv',
                                        text: data,
                                        type: 'text/csv',
                                    });
                                },
                            },
                            {
                                icon: <SvgPdf />,
                                text: 'PDF',
                                onClick: () => {
                                    // alert('later!');
                                },
                            },
                        ]}
                        menuEmptyPlaceholder={'unexpected'}
                    >
                        <SvgDownload className={c.svg} />
                    </ButtonWithTextMenu>
                </div>
            </div>
            {receivedBill instanceof ReceivedBillAPIForGeneral && (
                <RevokeModal
                    isOpen={isRemoveModalOpen}
                    onClose={() => setIsRemoveModalOpen(false)}
                    billAPI={receivedBill}
                    onRevoked={onRevoked}
                />
            )}
        </>
    );
};
ReceivedBillMenu.displayName = 'ReceivedBillMenu';
