import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.74824 17.3972L5.25276 17.8547C5.04296 18.0484 4.70372 18.0484 4.49615 17.8547L0.157349 13.8504C-0.0524492 13.6566 -0.0524492 13.3434 0.157349 13.1517L4.49615 9.14529C4.70595 8.95157 5.0452 8.95157 5.25276 9.14529L5.74824 9.60282C5.96027 9.7986 5.95581 10.118 5.73932 10.3097L3.04988 12.6756H9.46435C9.76119 12.6756 10 12.8962 10 13.1703V13.8297C10 14.1038 9.76119 14.3244 9.46435 14.3244H3.04988L5.73932 16.6903C5.95804 16.882 5.9625 17.2014 5.74824 17.3972Z"
                fill="#60D34E"
            />
            <path
                d="M8.25176 8.39718L8.74724 8.85471C8.95704 9.04843 9.29628 9.04843 9.50385 8.85471L13.8427 4.85036C14.0524 4.65663 14.0524 4.34337 13.8427 4.15171L9.50385 0.145294C9.29405 -0.0484314 8.9548 -0.0484314 8.74724 0.145294L8.25176 0.602817C8.03973 0.798603 8.04419 1.11804 8.26068 1.30971L10.9501 3.67564L4.53565 3.67564C4.23881 3.67564 4 3.89615 4 4.17025V4.82975C4 5.10385 4.23881 5.32437 4.53565 5.32437L10.9501 5.32437L8.26068 7.69029C8.04196 7.88196 8.0375 8.2014 8.25176 8.39718Z"
                fill="#1D77FF"
            />
        </svg>
    );
};
Svg.displayName = 'svg/bidirection';
export default Svg;
