import { FC, ReactElement } from 'react';

import { useLanguage } from '@/utils/customHooks';
import { DatePickerButton } from '@/components/0_atom/DatePickerButton';
import Button from '@/components/0_atom/Button';
import { TextSelector } from '@/components/1_mol/Selector';

import { FieldItem } from '@/views/records/useAccountingItems';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import MuiCheckbox from '@material-ui/core/Checkbox';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '4px 0',
        '& .MuiListItemIcon-root': {
            minWidth: 0,
            marginRight: 7,
            '& .MuiCheckbox-root': {
                padding: 0,
                '& .MuiTypography-root': {
                    fontSize: theme.typography.caption.fontSize,
                },
            },
        },
        '& .MuiListItemText-root': {
            margin: 0,
        },
    },
}));

export const FilterItem: FC<{
    isChecked: boolean;
    onChange: () => void;
    text: string;
}> = ({ isChecked, onChange, text }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt, getDateStr } = useLanguage();

    return (
        <MuiListItem className={c.root} dense>
            <MuiListItemIcon>
                <MuiCheckbox checked={isChecked} onChange={onChange} size={'small'} color={'primary'} />
            </MuiListItemIcon>
            <MuiListItemText>{text}</MuiListItemText>
        </MuiListItem>
    );
};
FilterItem.displayName = 'FilterItem';
