import { MutationValidationResult } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import { GraphQLSdk as CertSdk } from '@/utils/UodkaClients/Cert/graphql/graphqlSdk';
import { GraphQLSdk } from '@/utils/UodkaClients/Invoices/graphql/graphqlSdk';
import { GqlDateTimeData } from '@/utils/UodkaClients/Invoices/graphql/customScalars';
import { getBillSignaturesFromGql } from '@/utils/UodkaClients/Invoices/models/BillSignatures';
import {
    IssuedBill,
    IssuedBillInterface,
    getIssuedBillFromGql,
} from '@/utils/UodkaClients/Invoices/models/IssuedBill/data/IssuedBill';
import { createRevokingSignature } from '@/utils/UodkaClients/Invoices/utils/createSignature';

interface IssuedBillAPIInterface {
    addRevokingSignature: () => Promise<{
        result: MutationValidationResult;
    }>;
}

interface ConstructorInput extends IssuedBillInterface {
    graphqlSdk: GraphQLSdk;
    certSdk: CertSdk;
    clientBusinessId: string;
}

export class IssuedBillAPIForGeneral extends IssuedBill implements IssuedBillAPIInterface {
    protected _graphqlSdk: GraphQLSdk;

    protected _certSdk: CertSdk;

    protected _clientBusinessId: string;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
        this._certSdk = input.certSdk;
        this._clientBusinessId = input.clientBusinessId;
    }

    async addRevokingSignature() {
        if (this.signatures.issuer.revoke) {
            throw new Error('IssuedBillAPIForGeneral.addRevokingSignature: revokingSignature already exists.');
        }
        const newRevokingSignature = await createRevokingSignature({
            businessId: this._clientBusinessId,
            resourceId: this.signatures.issuer.issue.signature.resourceId,
            certSdk: this._certSdk,
            billData: this.billData,
        });
        const { importSignaturesToIssuedBill } = await this._graphqlSdk.importSignaturesToIssuedBill({
            input: {
                businessId: this._clientBusinessId,
                revoke: {
                    agreeToRevokeAt: GqlDateTimeData.parseValue(newRevokingSignature.agreeToRevokeAt),
                    signature: newRevokingSignature.signature.gqlData,
                },
            },
        });
        const { result, issuedBill } = importSignaturesToIssuedBill;
        this._signatures = getBillSignaturesFromGql(issuedBill.signatures);
        return {
            result,
            issuedBill: getIssuedBillFromGql(issuedBill),
        };
    }
}
