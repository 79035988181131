import { FC } from 'react';

import { ExternalBankAccountInterface } from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import { useLanguage } from '@/utils/customHooks';
import { BankAccountCardBase } from '@/components/2_org/BankAccountCard/BankAccountCardBase';
import ContactNameWithIcon from '@/components/2_org/ContactNameWithIcon';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    description1: {
        fontWeight: 600,
        lineHeight: 1.2,
        display: 'block',
        color: theme.palette.text.disabled,
        marginBottom: 3,
    },
    description2: {
        fontWeight: 600,
        lineHeight: 1.2,
        display: 'block',
        color: theme.palette.text.disabled,
        marginTop: 6,
    },
    contactName: {
        '& .MuiTypography-body1': {
            fontSize: 8,
            fontWeight: 600,
        },
    },
    contactIcon: {
        fontSize: 6,
    },
}));

export const DepositAccountCard: FC<{
    className?: string;
    depositAccount: ExternalBankAccountInterface;
    remitter: {
        name: string;
        profile: BusinessProfile | undefined;
    };
}> = ({ className, depositAccount, remitter }) => {
    // STYLE
    const theme = useTheme();
    const c = useStyles(theme);

    // HOOKS
    const { txt, language } = useLanguage();

    return (
        <BankAccountCardBase
            className={className}
            bankAccount={{ ...depositAccount, isDepositAccount: true }}
            accountDescription={
                <>
                    {(!language || language === 'en') && (
                        <MuiTypography variant={'caption'} className={c.description1}>
                            {txt({ en: 'Dedicated Deposit Account for' })}
                        </MuiTypography>
                    )}
                    <ContactNameWithIcon
                        contact={remitter}
                        profile={remitter.profile}
                        className={c.contactName}
                        size={'verySmall'}
                    />
                    {language === 'ja' && (
                        <MuiTypography variant={'caption'} className={c.description2}>
                            {txt({ en: '', ja: '専用入金口座' })}
                        </MuiTypography>
                    )}
                </>
            }
        />
    );
};
DepositAccountCard.displayName = 'DepositAccountCard';
