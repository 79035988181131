import { FC, useState } from 'react';

import { ReceivedBillAPIForGeneral } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';

import { RevokeModal } from '@/views/bills/BillDrawer/RevokeModal';

export const RevokeButton: FC<{
    className?: string;
    removeBillItem: () => void;
    receivedBillAPI: ReceivedBillAPIForGeneral;
    text?: string;
}> = ({ className, removeBillItem, receivedBillAPI, text }) => {
    // HOOKS
    const { txt } = useLanguage();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Button className={className} onClick={() => setIsOpen(true)}>
                {text || txt({ en: 'Decline', ja: '拒否' })}
            </Button>
            <RevokeModal
                billAPI={receivedBillAPI}
                onRevoked={removeBillItem}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
            />
        </>
    );
};
RevokeButton.displayName = 'RevokeButton';
