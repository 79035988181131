import { Charge as GqlCharge } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { GqlDateTimeData } from '@/utils/UodkaClients/Core/graphql/customScalars';
import { DepositAccount, getDepositAccountFromGql } from '@/utils/UodkaClients/Core/models/DepositAccount';

interface ChargeInterface {
    id: string;
    createdAt: Date;
    amount: number;
    remitterNameKana: string;
    depositAccount: DepositAccount;
}

export class Charge implements ChargeInterface {
    protected _id: string;

    protected _createdAt: Date;

    protected _amount: number;

    protected _remitterNameKana: string;

    protected _depositAccount: DepositAccount;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get amount() {
        return this._amount;
    }

    get remitterNameKana() {
        return this._remitterNameKana;
    }

    get depositAccount() {
        return this._depositAccount;
    }

    constructor({ id, createdAt, amount, remitterNameKana, depositAccount }: ChargeInterface) {
        this._id = id;
        this._createdAt = createdAt;
        this._amount = amount;
        this._remitterNameKana = remitterNameKana;
        this._depositAccount = depositAccount;
    }
}

export const getChargeFromGql = ({ id, createdAt, amount, remitterNameKana, depositAccount }: GqlCharge) => {
    return new Charge({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        amount,
        remitterNameKana,
        depositAccount: getDepositAccountFromGql(depositAccount),
    });
};
