import {
    Contact,
    ContactAPIForOwner,
    UodkaCoreClientForObserver,
    UodkaCoreClientForGeneral,
    UodkaCoreClientForOwner,
} from '@/utils/UodkaClients/Core';
import {
    UodkaInvoicesClientForObserver,
    UodkaInvoicesClientForGeneral,
    UodkaInvoicesClientForOwner,
} from '@/utils/UodkaClients/Invoices';
import { initUodkaProfileClientForAnonymous } from '@/utils/UodkaClients/Profile';
import { ContactItem } from '@/utils/customHooks';

import {
    IssuedBillItem,
    IssuedBillItemForObserver,
    IssuedBillItemForGeneral,
    IssuedBillItemForOwner,
} from '@/views/bills/useBillItems/fetchIssuedBill/IssuedBillItem';

const fetchIssuedBillForOwner = async ({
    coreClient,
    invoicesClient,
    contactId,
    billId,
}: {
    coreClient: UodkaCoreClientForOwner;
    invoicesClient: UodkaInvoicesClientForOwner;
    contactId: string;
    billId: string;
}): Promise<IssuedBillItemForOwner | undefined> => {
    const issuedBill = await invoicesClient.getIssuedBillAPI({ contactId, billId }).catch(() => undefined);
    if (!issuedBill) {
        return undefined;
    }
    const contactItem: ContactItem<ContactAPIForOwner> = await (async () => {
        const profileClient = initUodkaProfileClientForAnonymous();
        const fetchProfle = async () => {
            if (issuedBill.contact.relatedBusiness) {
                return profileClient.getProfileData({ businessId: issuedBill.contact.relatedBusiness.id });
            }
            return undefined;
        };
        const [contactAPI, contactProfile] = await Promise.all([
            coreClient.getContactAPI({ id: contactId }),
            fetchProfle(),
        ]);
        if (!contactAPI) {
            throw new Error('fetchIssuedBillForOwner: Unexpectedly (!contactAPI)');
        }
        return {
            id: contactAPI.id,
            contact: contactAPI,
            profile: {
                data: contactProfile,
                refetch: fetchProfle,
            },
        };
    })();
    return {
        id: issuedBill.id,
        issuedBill,
        contactItem,
    };
};

const fetchIssuedBillForGeneral = async ({
    coreClient,
    invoicesClient,
    contactId,
    billId,
}: {
    coreClient: UodkaCoreClientForGeneral;
    invoicesClient: UodkaInvoicesClientForGeneral;
    contactId: string;
    billId: string;
}): Promise<IssuedBillItemForGeneral | undefined> => {
    const issuedBill = await invoicesClient.getIssuedBillAPI({ contactId, billId }).catch(() => undefined);
    if (!issuedBill) {
        return undefined;
    }
    const contactItem: ContactItem<Contact> = await (async () => {
        const profileClient = initUodkaProfileClientForAnonymous();
        const fetchProfle = async () => {
            if (issuedBill.contact.relatedBusiness) {
                return profileClient.getProfileData({ businessId: issuedBill.contact.relatedBusiness.id });
            }
            return undefined;
        };
        const [contactAPI, contactProfile] = await Promise.all([
            coreClient.getContactAPI({ id: contactId }),
            fetchProfle(),
        ]);
        if (!contactAPI) {
            throw new Error('fetchIssuedBillForGeneral: Unexpectedly (!contactAPI)');
        }
        return {
            id: contactAPI.id,
            contact: contactAPI,
            profile: {
                data: contactProfile,
                refetch: fetchProfle,
            },
        };
    })();
    return {
        id: issuedBill.id,
        issuedBill,
        contactItem,
    };
};

const fetchIssuedBillForObserver = async ({
    coreClient,
    invoicesClient,
    contactId,
    billId,
}: {
    coreClient: UodkaCoreClientForObserver;
    invoicesClient: UodkaInvoicesClientForObserver;
    contactId: string;
    billId: string;
}): Promise<IssuedBillItemForObserver | undefined> => {
    const issuedBill = await invoicesClient.getIssuedBill({ contactId, billId }).catch(() => undefined);
    if (!issuedBill) {
        return undefined;
    }
    const contactItem: ContactItem<Contact> = await (async () => {
        const profileClient = initUodkaProfileClientForAnonymous();
        const fetchProfle = async () => {
            if (issuedBill.contact.relatedBusiness) {
                return profileClient.getProfileData({ businessId: issuedBill.contact.relatedBusiness.id });
            }
            return undefined;
        };
        const [contactAPI, contactProfile] = await Promise.all([
            coreClient.getContact({ id: contactId }),
            fetchProfle(),
        ]);
        if (!contactAPI) {
            throw new Error('fetchIssuedBillForObserver: Unexpectedly (!contactAPI)');
        }
        return {
            id: contactAPI.id,
            contact: contactAPI,
            profile: {
                data: contactProfile,
                refetch: fetchProfle,
            },
        };
    })();
    return {
        id: issuedBill.id,
        issuedBill,
        contactItem,
    };
};

export const fetchIssuedBill = async ({
    coreClient,
    invoicesClient,
    contactId,
    billId,
}: {
    coreClient: UodkaCoreClientForObserver | UodkaCoreClientForGeneral | UodkaCoreClientForOwner;
    invoicesClient: UodkaInvoicesClientForObserver | UodkaInvoicesClientForGeneral | UodkaInvoicesClientForOwner;
    contactId: string;
    billId: string;
}): Promise<IssuedBillItem | undefined> => {
    if (coreClient instanceof UodkaCoreClientForOwner && invoicesClient instanceof UodkaInvoicesClientForOwner) {
        return fetchIssuedBillForOwner({ coreClient, invoicesClient, contactId, billId });
    }
    if (coreClient instanceof UodkaCoreClientForGeneral && invoicesClient instanceof UodkaInvoicesClientForGeneral) {
        return fetchIssuedBillForGeneral({ coreClient, invoicesClient, contactId, billId });
    }
    if (coreClient instanceof UodkaCoreClientForObserver && invoicesClient instanceof UodkaInvoicesClientForObserver) {
        return fetchIssuedBillForObserver({ coreClient, invoicesClient, contactId, billId });
    }
    throw new Error('fetchIssuedBill: unexpected case');
};
