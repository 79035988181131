import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 384 265" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M289.203 132.501C320.553 132.501 345.988 107.066 345.988 75.7154C345.988 44.365 320.553 18.9297 289.203 18.9297C257.852 18.9297 232.417 44.365 232.417 75.7154C232.417 107.066 257.852 132.501 289.203 132.501ZM289.203 37.8583C310.083 37.8583 327.06 54.8348 327.06 75.7154C327.06 96.596 310.083 113.573 289.203 113.573C268.322 113.573 251.345 96.596 251.345 75.7154C251.345 54.8348 268.322 37.8583 289.203 37.8583ZM374.204 168.643C363.97 153.5 346.58 145.515 328.716 145.515C312.272 145.515 308.131 151.43 289.203 151.43C270.274 151.43 266.133 145.515 249.689 145.515C241.881 145.515 234.251 147.289 227.152 150.306C236.143 159.415 238.095 164.266 239.101 166.04C242.473 165.094 245.963 164.502 249.689 164.502C262.584 164.502 267.435 170.417 289.203 170.417C310.97 170.417 315.821 164.502 328.716 164.502C341.138 164.502 352.258 170.003 358.528 179.29C362.728 185.501 364.976 192.836 364.976 200.407V222.412C364.976 225.015 362.847 227.144 360.244 227.144H251.345C251.345 237.614 251.523 240.453 250.399 246.073H360.185C373.257 246.073 383.845 235.484 383.845 222.412V200.348C383.845 188.577 380.296 177.693 374.204 168.643Z"
                fill="currentColor"
                fillOpacity="0.364"
            />
            <path
                d="M113.571 132.5C150.186 132.5 179.821 102.865 179.821 66.25C179.821 29.635 150.186 0 113.571 0C76.9565 0 47.3214 29.635 47.3214 66.25C47.3214 102.865 76.9565 132.5 113.571 132.5ZM113.571 18.9286C139.657 18.9286 160.893 40.1641 160.893 66.25C160.893 92.3359 139.657 113.571 113.571 113.571C87.4855 113.571 66.25 92.3359 66.25 66.25C66.25 40.1641 87.4855 18.9286 113.571 18.9286ZM160.952 144.33C141.195 144.33 136.286 151.429 113.571 151.429C90.8571 151.429 85.9475 144.33 66.1909 144.33C44.7188 144.33 23.8382 153.913 11.5938 172.073C4.25893 182.956 0 196.029 0 210.107V236.607C0 252.282 12.7176 265 28.3929 265H198.75C214.425 265 227.143 252.282 227.143 236.607V210.107C227.143 196.029 222.884 182.956 215.549 172.073C203.305 153.913 182.424 144.33 160.952 144.33ZM208.214 236.607C208.214 241.813 203.955 246.071 198.75 246.071H28.3929C23.1875 246.071 18.9286 241.813 18.9286 236.607V210.107C18.9286 200.288 21.827 190.765 27.269 182.661C35.4319 170.535 49.9833 163.259 66.1317 163.259C82.3393 163.259 88.1362 170.357 113.571 170.357C139.007 170.357 144.804 163.259 160.952 163.259C177.1 163.259 191.652 170.535 199.815 182.661C205.257 190.765 208.155 200.288 208.155 210.107V236.607H208.214Z"
                fill="currentColor"
            />
        </svg>
    );
};
Svg.displayName = 'svg/contacts';
export default Svg;
