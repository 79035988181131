import { MutationValidationResult } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { GraphQLSdk } from '@/utils/UodkaClients/Core/graphql/graphqlSdk';
import {
    InviteForContactInContact,
    InviteForContactInContactInterface,
} from '@/utils/UodkaClients/Core/models/InviteForContact/data/InviteForContactInContact';

interface InviteForContactAPIForOwnerInterface {
    remove: () => Promise<MutationValidationResult>;
}

interface ConstructorInput extends InviteForContactInContactInterface {
    graphqlSdk: GraphQLSdk;
    parentBusinessId: string;
    parentContactId: string;
    onRemove: () => void;
}

export class InviteForContactAPIForOwner
    extends InviteForContactInContact
    implements InviteForContactAPIForOwnerInterface {
    protected _graphqlSdk: GraphQLSdk;

    protected _parentBusinessId: string;

    protected _parentContactId: string;

    protected _onRemove: () => void;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
        this._parentBusinessId = input.parentBusinessId;
        this._parentContactId = input.parentContactId;
        this._onRemove = input.onRemove;
    }

    async remove() {
        const { deleteInviteForContact } = await this._graphqlSdk.deleteInviteForContact({
            input: {
                businessId: this._parentBusinessId,
                contactId: this._parentContactId,
            },
        });
        this._onRemove();
        return deleteInviteForContact.result;
    }
}
