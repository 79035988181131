import {
    BusinessProfile,
    getBusinessProfileInterfaceFromGql,
} from '@/utils/UodkaClients/Profile/models/BusinessProfile';
import { GraphQLSdk } from '@/utils/UodkaClients/Profile/graphql/graphqlSdk';
import { getBusinessLogoSrc, getBusinessIconSrc } from '@/utils/GoogleCloud/storage';

interface UodkaProfileClientForObserverInterface {
    getProfileData: () => Promise<BusinessProfile | undefined>;
}

interface ConstructorInput {
    graphqlSdk: GraphQLSdk;
    businessId: string;
}

export class UodkaProfileClientForObserver implements UodkaProfileClientForObserverInterface {
    protected _graphqlSdk: GraphQLSdk;

    protected _businessId: string;

    constructor({ graphqlSdk, businessId }: ConstructorInput) {
        this._graphqlSdk = graphqlSdk;
        this._businessId = businessId;
    }

    async getProfileData() {
        const { businessProfile } = await this._graphqlSdk.businessProfile({
            id: this._businessId,
        });
        const logoSrc = await getBusinessLogoSrc({ businessId: this._businessId });
        const iconSrc = await getBusinessIconSrc({ businessId: this._businessId });
        if (businessProfile || logoSrc || iconSrc) {
            return new BusinessProfile({
                ...getBusinessProfileInterfaceFromGql({
                    businessId: this._businessId,
                    ...businessProfile,
                }),
                logoSrc,
                iconSrc,
            });
        }
        return undefined;
    }
}
