export const downloadTextFile = ({
    fileName,
    text,
    type,
}: {
    fileName: string;
    text: string;
    type: 'text/json' | 'text/csv';
}) => {
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const data = new Blob([bom, text], { type });
    const fileURL = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = fileURL;
    link.setAttribute('download', fileName);
    link.click();
    document.body.removeChild(link);
};
