import { FC } from 'react';

import { BusinessProfileAPIForOwner } from '@/utils/UodkaClients/Profile';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import FetchingText from '@/components/0_atom/FetchingText';
import HorizontalTable from '@/components/0_atom/HorizontalTable';
import ValueWithEditButton from '@/components/0_atom/ValueWithEditButton';
import ColorDisplay from '@/components/0_atom/ColorDisplay';
import NotSetText from '@/components/0_atom/NotSetText';
import BusinessIcon from '@/components/0_atom/BusinessIcon';

import { useProfile } from '@/views/config/business/Profile/useProfile';
import { ModifyAddressButton } from '@/views/config/business/Profile/ModifyAddressButton';
import { ModifyPhoneButton } from '@/views/config/business/Profile/ModifyPhoneButton';
import { ModifyEmailButton } from '@/views/config/business/Profile/ModifyEmailButton';
import { ModifyWebsiteUrlButton } from '@/views/config/business/Profile/ModifyWebsiteUrlButton';
import { ModifyThemeColorButton } from '@/views/config/business/Profile/ModifyThemeColorButton';
import { ModifyLogoButton } from '@/views/config/business/Profile/ModifyLogoButton';
import { ModifyIconButton } from '@/views/config/business/Profile/ModifyIconButton';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#fff',
        borderRadius: 4,
        padding: 18,
    },
    logo: {
        width: 200,
        height: 100,
        padding: 28,
        borderRadius: 4,
        border: 'solid 2px rgba(0,0,0,.03)',
        '& img': {
            width: '100%',
            height: '100%',
        },
    },
    icon: {
        width: 40,
        height: 40,
    },
}));

export const ConfigBusinessProfile: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { currentBusiness } = useBusinessCtxAbsolutely();
    const { txt } = useLanguage();
    const { profile, setProfile, isFetching } = useProfile();

    return (
        <div className={c.root}>
            <HorizontalTable
                items={[
                    {
                        label: txt({
                            en: 'Address',
                            ja: '住所',
                        }),
                        children: (
                            <ValueWithEditButton
                                value={!isFetching ? profile?.address || <NotSetText /> : <FetchingText />}
                                editButton={
                                    profile instanceof BusinessProfileAPIForOwner ? (
                                        <ModifyAddressButton profileAPI={profile} setProfile={setProfile} />
                                    ) : undefined
                                }
                            />
                        ),
                    },
                    {
                        label: txt({
                            en: 'Public phone',
                            ja: '公開電話番号',
                        }),
                        children: (
                            <ValueWithEditButton
                                value={!isFetching ? profile?.phone || <NotSetText /> : <FetchingText />}
                                editButton={
                                    profile instanceof BusinessProfileAPIForOwner ? (
                                        <ModifyPhoneButton profileAPI={profile} setProfile={setProfile} />
                                    ) : undefined
                                }
                            />
                        ),
                    },
                    {
                        label: txt({
                            en: 'Public email',
                            ja: '公開メールアドレス',
                        }),
                        children: (
                            <ValueWithEditButton
                                value={!isFetching ? profile?.email || <NotSetText /> : <FetchingText />}
                                editButton={
                                    profile instanceof BusinessProfileAPIForOwner ? (
                                        <ModifyEmailButton profileAPI={profile} setProfile={setProfile} />
                                    ) : undefined
                                }
                            />
                        ),
                    },
                    {
                        label: txt({
                            en: 'Public website URL',
                            ja: '公開ウェブサイトURL',
                        }),
                        children: (
                            <ValueWithEditButton
                                value={!isFetching ? profile?.websiteUrl || <NotSetText /> : <FetchingText />}
                                editButton={
                                    profile instanceof BusinessProfileAPIForOwner ? (
                                        <ModifyWebsiteUrlButton profileAPI={profile} setProfile={setProfile} />
                                    ) : undefined
                                }
                            />
                        ),
                    },
                    {
                        label: txt({
                            en: 'Brand Color',
                            ja: 'ブランドカラー',
                        }),
                        children: (
                            <ValueWithEditButton
                                value={!isFetching ? <ColorDisplay color={profile?.themeColor} /> : <FetchingText />}
                                editButton={
                                    profile instanceof BusinessProfileAPIForOwner ? (
                                        <ModifyThemeColorButton profileAPI={profile} setProfile={setProfile} />
                                    ) : undefined
                                }
                            />
                        ),
                    },
                    {
                        label: txt({
                            en: 'Logo',
                            ja: 'ロゴ',
                        }),
                        children: (
                            <ValueWithEditButton
                                value={
                                    !isFetching ? (
                                        profile?.logoSrc ? (
                                            <div className={c.logo}>
                                                <img src={profile.logoSrc} alt={'Logo of business'} />
                                            </div>
                                        ) : (
                                            <NotSetText />
                                        )
                                    ) : (
                                        <FetchingText />
                                    )
                                }
                                editButton={
                                    profile instanceof BusinessProfileAPIForOwner ? (
                                        <ModifyLogoButton profileAPI={profile} setProfile={setProfile} />
                                    ) : undefined
                                }
                            />
                        ),
                    },
                    {
                        label: txt({
                            en: 'Icon',
                            ja: 'アイコン',
                        }),
                        children: (
                            <ValueWithEditButton
                                value={
                                    !isFetching ? (
                                        <div className={c.icon}>
                                            <BusinessIcon
                                                name={currentBusiness.name}
                                                iconSrc={profile?.iconSrc}
                                                themeColor={profile?.themeColor}
                                            />
                                        </div>
                                    ) : (
                                        <FetchingText />
                                    )
                                }
                                editButton={
                                    profile instanceof BusinessProfileAPIForOwner ? (
                                        <ModifyIconButton profileAPI={profile} setProfile={setProfile} />
                                    ) : undefined
                                }
                            />
                        ),
                    },
                ]}
            />
        </div>
    );
};
ConfigBusinessProfile.displayName = 'ConfigBusinessProfile';
