import {
    InvoiceDataSummary as GqlInvoiceDataSummary,
    DecimalRoundRule,
} from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import { AggregatedTax, getAggregatedTaxFromGql } from '@/utils/UodkaClients/Invoices/models/InvoiceData/AggregatedTax';

export interface InvoiceDataSummaryInterface {
    decimalRoundRuleForSubtotal: DecimalRoundRule;
    subtotal: number;
    taxes: AggregatedTax[];
    total: number;
}

export class InvoiceDataSummary implements InvoiceDataSummaryInterface {
    protected _decimalRoundRuleForSubtotal: DecimalRoundRule;

    protected _subtotal: number;

    protected _taxes: AggregatedTax[];

    protected _total: number;

    get decimalRoundRuleForSubtotal() {
        return this._decimalRoundRuleForSubtotal;
    }

    get subtotal() {
        return this._subtotal;
    }

    get taxes() {
        return this._taxes;
    }

    get total() {
        return this._total;
    }

    get data() {
        return {
            decimalRoundRuleForSubtotal: this._decimalRoundRuleForSubtotal,
            subtotal: this._subtotal,
            taxes: this._taxes,
            total: this._total,
        };
    }

    constructor({ decimalRoundRuleForSubtotal, subtotal, taxes, total }: InvoiceDataSummaryInterface) {
        this._decimalRoundRuleForSubtotal = decimalRoundRuleForSubtotal;
        this._subtotal = subtotal;
        this._taxes = taxes;
        this._total = total;
    }
}

export const getInvoiceDataSummaryFromGql = ({
    decimalRoundRuleForSubtotal,
    subtotal,
    taxes,
    total,
}: GqlInvoiceDataSummary) => {
    return new InvoiceDataSummary({
        decimalRoundRuleForSubtotal,
        subtotal,
        taxes: taxes.map((tax) => getAggregatedTaxFromGql(tax)),
        total,
    });
};
