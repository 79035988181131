import { FC, useState } from 'react';

import { ClientDataAPIForOwner } from '@/utils/UodkaClients/Core';
import { useLanguage, useFormField } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import { MutationFormModal } from '@/components/1_mol/FormModal';
import { TextInput } from '@/components/0_atom/Input';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({}));

export const ModifyNameButton: FC<{
    clientDataAPI: ClientDataAPIForOwner;
    setClientData: (clientData: ClientDataAPIForOwner) => void;
}> = ({ clientDataAPI, setClientData }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [isOpen, setIsOpen] = useState(false);
    const [editingName, setEditingName] = useFormField(clientDataAPI.name);

    // MUTATION
    const run = async () => {
        const result = await clientDataAPI.modifyName(editingName);
        setClientData(clientDataAPI);
        return result;
    };

    return (
        <>
            <Button onClick={() => setIsOpen(true)} variant={'contained'}>
                {txt({ en: 'Change', ja: '変更' })}
            </Button>
            <MutationFormModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={txt({ en: `Modify ${clientDataAPI.name}'s name`, ja: `${clientDataAPI.name}の名称を変更` })}
                mutation={{
                    run,
                    description: txt({
                        en: `Modify ${clientDataAPI.name}'s name`,
                        ja: `${clientDataAPI.name}の名称を変更`,
                    }),
                }}
                items={[
                    {
                        isRequired: true,
                        labelText: txt({ en: 'New name', ja: '新しい名称' }),
                        isSatisfied: !!editingName,
                        isError: undefined,
                        children: (
                            <TextInput
                                fullWidth
                                placeholder={txt({ en: 'New name', ja: '新しい名称' })}
                                value={editingName}
                                setValue={(newValue) => setEditingName(newValue || '')}
                                isSatisfied={!!editingName}
                            />
                        ),
                    },
                ]}
                submitText={txt({ en: 'Update', ja: '変更' })}
            />
        </>
    );
};
ModifyNameButton.displayName = 'views/config/business/account/Basic/ModifyNameButton';
