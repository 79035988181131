import { FC, useState, useMemo } from 'react';

import { UodkaInvoicesClientForGeneral, UodkaInvoicesClientForOwner } from '@/utils/UodkaClients/Invoices';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import FetchingText from '@/components/0_atom/FetchingText';
import { SideMenuTemplate } from '@/components/1_mol/SideMenu';

import SvgArrow from '@/svgs/fa-arrow-up-solid';

import { useBillItems } from '@/views/bills/useBillItems';
import { useBillsRouting } from '@/views/bills/useBillsRouting';
import { IssuedBillsTable } from '@/views/bills/IssuedBills/IssuedBillsTable';
import { ReceivedBillsTable } from '@/views/bills/ReceivedBills/ReceivedBillsTable';
import { AddBillDrawer } from '@/views/bills/AddBillDrawer';
import { BillDrawer } from '@/views/bills/BillDrawer';
import { NoBillDisplay } from '@/views/bills/NoBillDisplay';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    receivedIcon: {
        transform: 'rotate(90deg)',
        color: '#FF5E3A',
    },
    issuedIcon: {
        transform: 'rotate(270deg)',
        color: '#1D77FF',
    },
}));

export const BillsViewRoot: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { slug1, setSlug1, specifiedBillUrl } = useBillsRouting();
    const {
        clients: { invoices },
    } = useBusinessCtxAbsolutely();
    const { txt } = useLanguage();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {
        contactItems,
        currentContactItem,
        setCurrentContactItem,
        specifiedIssuedBillItem,
        specifiedReceivedBillItem,
        clearSpecifiedBillItem,
        issuedBillItems,
        receivedBillItems,
        issuedBillsFilter,
        setIssuedBillsFilter,
        receivedBillsFilter,
        setReceivedBillsFilter,
        refetchIssuedBills,
        isFetching,
    } = useBillItems();

    // MEMO
    const content = useMemo(
        () => (
            <>
                {issuedBillItems?.ids.length === 0 && receivedBillItems?.ids.length === 0 ? (
                    <NoBillDisplay />
                ) : slug1 === 'issued' ? (
                    <IssuedBillsTable
                        contactItems={contactItems}
                        filter={issuedBillsFilter}
                        setFilter={setIssuedBillsFilter}
                        items={issuedBillItems}
                        currentContactItem={currentContactItem}
                        setCurrentContactItem={setCurrentContactItem}
                    />
                ) : slug1 === 'received' ? (
                    <ReceivedBillsTable
                        contactItems={contactItems}
                        filter={receivedBillsFilter}
                        setFilter={setReceivedBillsFilter}
                        items={receivedBillItems}
                        currentContactItem={currentContactItem}
                        setCurrentContactItem={setCurrentContactItem}
                    />
                ) : (
                    <FetchingText />
                )}
            </>
        ),
        [
            contactItems,
            currentContactItem,
            setCurrentContactItem,
            issuedBillItems,
            issuedBillsFilter,
            receivedBillItems,
            receivedBillsFilter,
            setIssuedBillsFilter,
            setReceivedBillsFilter,
            slug1,
        ]
    );

    return (
        <>
            <SideMenuTemplate
                className={c.root}
                title={txt({ en: 'Bills', ja: '請求' })}
                sideMenuButtonDatas={[
                    {
                        text: txt({ en: 'Received', ja: '受け取った請求' }),
                        icon: <SvgArrow className={c.receivedIcon} />,
                        onClick: () => setSlug1({ slug1: 'received' }),
                        isSelected: slug1 === 'received',
                    },
                    {
                        text: txt({ en: 'Issued', ja: '発行した請求' }),
                        icon: <SvgArrow className={c.issuedIcon} />,
                        onClick: () => setSlug1({ slug1: 'issued' }),
                        isSelected: slug1 === 'issued',
                    },
                ]}
                actionButtonData={
                    invoices instanceof UodkaInvoicesClientForGeneral || invoices instanceof UodkaInvoicesClientForOwner
                        ? {
                              text: txt({ en: 'New bill', ja: '新しい請求を発行' }),
                              onClick: () => setIsModalOpen(true),
                          }
                        : undefined
                }
            >
                {content}
            </SideMenuTemplate>
            {(invoices instanceof UodkaInvoicesClientForGeneral || invoices instanceof UodkaInvoicesClientForOwner) && (
                <AddBillDrawer
                    invoicesClient={invoices}
                    refetchIssuedBills={refetchIssuedBills}
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                    contactItems={contactItems}
                    currentContactItem={currentContactItem}
                />
            )}
            <BillDrawer
                isOpen={!!specifiedBillUrl}
                onClose={clearSpecifiedBillItem}
                issuedBillItem={specifiedIssuedBillItem}
                receivedBillItem={specifiedReceivedBillItem}
                updateIssuedBillItem={issuedBillItems?.updateItem || (() => undefined)}
                updateReceivedBillItem={receivedBillItems?.updateItem || (() => undefined)}
                removeIssuedBillItem={issuedBillItems?.removeItem || (() => undefined)}
                removeReceivedBillItem={receivedBillItems?.removeItem || (() => undefined)}
                isFetching={isFetching}
            />
        </>
    );
};
BillsViewRoot.displayName = 'BillsViewRoot';
