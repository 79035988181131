import { forwardRef, ComponentProps } from 'react';

import { MenuItem, MenuItemProps } from '@/components/0_atom/Menu/MenuItem';

import MuiMenuList from '@material-ui/core/MenuList';

export const MenuList = forwardRef<
    HTMLUListElement,
    ComponentProps<typeof MuiMenuList> & {
        items: MenuItemProps[];
    }
>(({ items, ...otherProps }, ref) => {
    return (
        <MuiMenuList ref={ref} {...otherProps}>
            {items.map((item, i) => (
                <MenuItem key={i} {...item} />
            ))}
        </MuiMenuList>
    );
});
MenuList.displayName = 'MenuList';
