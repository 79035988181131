import { ExternalBankAccount as GqlExternalBankAccount } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { getKana } from '@/utils/UodkaClients/Core/utils/getKana';

export interface ExternalBankAccountInterface {
    bankCode: string;
    branchCode: string;
    accountNumber: string;
    accountHolderName: string;
}

export class ExternalBankAccount implements ExternalBankAccountInterface {
    protected _bankCode: string;

    protected _branchCode: string;

    protected _accountNumber: string;

    protected _accountHolderName: string;

    get bankCode() {
        return this._bankCode;
    }

    get branchCode() {
        return this._branchCode;
    }

    get accountNumber() {
        return this._accountNumber;
    }

    get accountHolderName() {
        return this._accountHolderName;
    }

    get data() {
        return {
            bankCode: this.bankCode,
            branchCode: this.branchCode,
            accountNumber: this.accountNumber,
            accountHolderName: this.accountHolderName,
        };
    }

    constructor({ bankCode, branchCode, accountNumber, accountHolderName }: ExternalBankAccountInterface) {
        this._bankCode = bankCode;
        this._branchCode = branchCode;
        this._accountNumber = accountNumber;
        this._accountHolderName = (() => {
            const result = getKana(accountHolderName);
            if (result) {
                return result.fullWidthKatakana;
            }
            throw new Error('ExternalBankAccount.constructor: Unexpectedly getKana failed.');
        })();
    }
}

export const getExternalBankAccountFromGql = ({
    bankCode,
    branchCode,
    accountNumber,
    accountHolderName,
}: GqlExternalBankAccount) => {
    return new ExternalBankAccount({
        bankCode,
        branchCode,
        accountNumber,
        accountHolderName,
    });
};

// export const getExternalBankAccount = (externalBankAccountInterface?: ExternalBankAccountInterface) => {
//     if (externalBankAccountInterface) {
//         return new ExternalBankAccount(externalBankAccountInterface);
//     }
//     return undefined;
// };
