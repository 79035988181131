import { useState, useEffect } from 'react';

import { BusinessProfile, BusinessProfileAPIForOwner, UodkaProfileClientForOwner } from '@/utils/UodkaClients/Profile';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { getBusinessProfile } from '@/utils/customHooks';

export const useProfile = () => {
    // HOOKS
    const {
        clients: { profile: profileClient },
        currentBusiness,
    } = useBusinessCtxAbsolutely();
    const [wrapper, setWrapper] = useState<{
        profile?: BusinessProfile | BusinessProfileAPIForOwner;
    }>({});
    const [isFetching, setIsFetching] = useState(true);

    // USEEFFECT
    useEffect(() => {
        setIsFetching(true);
        if (profileClient instanceof UodkaProfileClientForOwner) {
            profileClient.getProfileDataAPI().then((profile) => {
                setWrapper({ profile });
                setIsFetching(false);
            });
        } else {
            getBusinessProfile(currentBusiness.id).then((profile) => {
                setWrapper({ profile });
                setIsFetching(false);
            });
        }
    }, [profileClient, currentBusiness]);

    return {
        profile: wrapper.profile,
        setProfile: (profile: BusinessProfile | BusinessProfileAPIForOwner) => setWrapper({ profile }),
        isFetching,
    };
};
