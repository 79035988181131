import { InviteForContact, getInviteForContactFromGql } from '@/utils/UodkaClients/Core';
import { GraphQLSdk as InvoicesSdk } from '@/utils/UodkaClients/Invoices/graphql/graphqlSdk';
import {
    SharedBill,
    SharedPayment,
    SharedReceipt,
    getSharedBillFromGql,
    getSharedPaymentFromGql,
    getSharedReceiptFromGql,
} from '@/utils/UodkaClients/Invoices/models';

interface UodkaInvoicesClientForAnonymousInterface {
    getSharedBill: (input: {
        issuerBusinessId: string;
        issuerContactId: string;
        billId: string;
        inviteCodeText: string;
    }) => Promise<{
        invite: InviteForContact;
        bill: SharedBill;
    }>;
    getSharedPayment: (input: {
        issuerBusinessId: string;
        issuerContactId: string;
        paymentId: string;
        inviteCodeText: string;
    }) => Promise<{
        invite: InviteForContact;
        payment: SharedPayment;
    }>;
    getSharedReceipt: (input: {
        issuerBusinessId: string;
        issuerContactId: string;
        receiptId: string;
        inviteCodeText: string;
    }) => Promise<{
        invite: InviteForContact;
        receipt: SharedReceipt;
    }>;
}

interface ConstructorInput {
    invoicesSdk: InvoicesSdk;
}

export class UodkaInvoicesClientForAnonymous implements UodkaInvoicesClientForAnonymousInterface {
    protected _invoicesSdk: InvoicesSdk;

    constructor({ invoicesSdk }: ConstructorInput) {
        this._invoicesSdk = invoicesSdk;
    }

    async getSharedBill({
        issuerBusinessId,
        issuerContactId,
        billId,
        inviteCodeText,
    }: {
        issuerBusinessId: string;
        issuerContactId: string;
        billId: string;
        inviteCodeText: string;
    }) {
        const { getSharedBill } = await this._invoicesSdk.getSharedBill({
            input: {
                issuerBusinessId,
                issuerContactId,
                billId,
                inviteCodeText,
            },
        });
        return {
            invite: getInviteForContactFromGql(getSharedBill.invite),
            bill: getSharedBillFromGql(getSharedBill.bill),
        };
    }

    async getSharedPayment({
        issuerBusinessId,
        issuerContactId,
        paymentId,
        inviteCodeText,
    }: {
        issuerBusinessId: string;
        issuerContactId: string;
        paymentId: string;
        inviteCodeText: string;
    }) {
        const { getSharedPayment } = await this._invoicesSdk.getSharedPayment({
            input: {
                issuerBusinessId,
                issuerContactId,
                paymentId,
                inviteCodeText,
            },
        });
        return {
            invite: getInviteForContactFromGql(getSharedPayment.invite),
            payment: getSharedPaymentFromGql(getSharedPayment.payment),
        };
    }

    async getSharedReceipt({
        issuerBusinessId,
        issuerContactId,
        receiptId,
        inviteCodeText,
    }: {
        issuerBusinessId: string;
        issuerContactId: string;
        receiptId: string;
        inviteCodeText: string;
    }) {
        const { getSharedReceipt } = await this._invoicesSdk.getSharedReceipt({
            input: {
                issuerBusinessId,
                issuerContactId,
                receiptId,
                inviteCodeText,
            },
        });
        return {
            invite: getInviteForContactFromGql(getSharedReceipt.invite),
            receipt: getSharedReceiptFromGql(getSharedReceipt.receipt),
        };
    }
}
