import { SharedTransfer, Charge, getSharedTransferFromGql, getChargeFromGql } from '@/utils/UodkaClients/Core';
import { GqlDateTimeData } from '@/utils/UodkaClients/Invoices/graphql/customScalars';
import { PaymentInIssuedBill as GqlPaymentInIssuedBill } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import { PaymentData, getPaymentDataFromGql } from '@/utils/UodkaClients/Invoices/models/InvoiceData';

interface PaymentInIssuedBillInterface {
    id: string;
    createdAt: Date;
    paymentData: PaymentData;
    incomingTransfer: SharedTransfer;
    charge?: Charge;
}

export class PaymentInIssuedBill implements PaymentInIssuedBillInterface {
    protected _id: string;

    protected _createdAt: Date;

    protected _paymentData: PaymentData;

    protected _incomingTransfer: SharedTransfer;

    protected _charge?: Charge;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get paymentData() {
        return this._paymentData;
    }

    get incomingTransfer() {
        return this._incomingTransfer;
    }

    get charge() {
        return this._charge;
    }

    constructor({ id, createdAt, paymentData, incomingTransfer, charge }: PaymentInIssuedBillInterface) {
        this._id = id;
        this._createdAt = createdAt;
        this._paymentData = paymentData;
        this._incomingTransfer = incomingTransfer;
        this._charge = charge;
    }
}

export const getPaymentInIssuedBillFromGql = ({
    id,
    createdAt,
    paymentData,
    incomingTransfer,
    charge,
}: GqlPaymentInIssuedBill) => {
    return new PaymentInIssuedBill({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        paymentData: getPaymentDataFromGql(paymentData),
        incomingTransfer: getSharedTransferFromGql(incomingTransfer),
        charge: charge ? getChargeFromGql(charge) : undefined,
    });
};
