import { FC, ReactNode, useCallback } from 'react';

import { useLanguage } from '@/utils/customHooks';
import Modal from '@/components/0_atom/Modal';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        marginBottom: 33,
    },
    children: {},
}));

const InformationModal: FC<{
    isOpen: boolean;
    animation?: 'left' | 'right' | 'up' | 'down' | 'grow' | 'fade' | undefined;
    onClose: () => void;
    title: string;
    children: ReactNode;
}> = ({ isOpen, animation, onClose, title, children }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <Modal {...{ isOpen, animation, onClose }}>
            <MuiTypography variant={'h1'} className={c.title}>
                {title}
            </MuiTypography>
            <div className={c.children}>{children}</div>
        </Modal>
    );
};
InformationModal.displayName = 'InformationModal';
export default InformationModal;
