import { FC, useState } from 'react';

import { ClientData } from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import { ReceivedBillAPIForGeneral, IssuedPayment } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';

import { PayToBillButton } from '@/views/bills/BillDrawer/ReceivedBill/PayToBillButton';
import { RevokeButton } from '@/views/bills/BillDrawer/ReceivedBill/RevokeButton';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 31,
    },
    buttons: {
        display: 'flex',
        minHeight: 35,
    },
    revokeButton: {
        color: theme.palette.error.main,
        background: 'rgba(255,71,71,.1)',
        width: '50%',
        marginRight: 4,
        '&:hover': {
            background: 'rgba(255,71,71,.2)',
        },
    },
    payButton: {
        width: '50%',
        '&.Mui-disabled': {
            color: 'rgba(255,255,255,.7)',
        },
    },
    errorDescription: {
        color: theme.palette.text.disabled,
        fontWeight: 600,
        fontSize: theme.typography.body2.fontSize,
        marginTop: 14,
        textAlign: 'center',
    },
    revokeBox: {},
    aloneRevokeButton: {
        fontWeight: 600,
        color: theme.palette.error.main,
        background: 'rgba(255,71,71,.1)',
        padding: '6px 24px',
        '&:hover': {
            background: 'rgba(255,71,71,.2)',
        },
    },
}));

export const ActionButtons: FC<{
    receivedBillAPI: ReceivedBillAPIForGeneral;
    onAddTransfer: (input: { payment: IssuedPayment }) => void;
    contactInfo: {
        id: string;
        name: string;
        profile: BusinessProfile | undefined;
    };
    clientData: ClientData;
    clientProfile: BusinessProfile | undefined;
    removeBillItem: () => void;
}> = ({ receivedBillAPI, onAddTransfer, contactInfo, clientData, clientProfile, removeBillItem }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [isAbleToCreateTransfer, setIsAbleToCreateTransfer] = useState<boolean | 'fetching'>('fetching');

    // DATA
    const isShowOnlyRevokeButton = (() => {
        if (receivedBillAPI.signatures.issuer.revoke) {
            return true;
        }
        if (receivedBillAPI.payment?.transfer.error?.code === 'inputError') {
            return true;
        }
        return false;
    })();

    return (
        <div className={c.root}>
            {isShowOnlyRevokeButton ? (
                <div className={c.revokeBox}>
                    <RevokeButton
                        className={c.aloneRevokeButton}
                        receivedBillAPI={receivedBillAPI}
                        removeBillItem={removeBillItem}
                        text={txt({ en: 'Revoke', ja: 'この請求を破棄' })}
                    />
                </div>
            ) : (
                <>
                    <div className={c.buttons}>
                        <RevokeButton
                            className={c.revokeButton}
                            receivedBillAPI={receivedBillAPI}
                            removeBillItem={removeBillItem}
                        />
                        <PayToBillButton
                            className={c.payButton}
                            receivedBillAPI={receivedBillAPI}
                            onAddTransfer={onAddTransfer}
                            contactInfo={contactInfo}
                            clientData={clientData}
                            clientProfile={clientProfile}
                            isAbleToCreateTransfer={isAbleToCreateTransfer}
                            setIsAbleToCreateTransfer={setIsAbleToCreateTransfer}
                        />
                    </div>
                    {isAbleToCreateTransfer === false && (
                        <MuiTypography className={c.errorDescription}>
                            {txt({ en: 'Insufficient balance', ja: '支払いに必要な残高がありません' })}
                        </MuiTypography>
                    )}
                </>
            )}
        </div>
    );
};
ActionButtons.displayName = 'ActionButtons';
