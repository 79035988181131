import { Charge } from '@/utils/UodkaClients/Core';

export const getChargeData = ({ charge }: { charge: Charge }) => {
    const { amount, createdAt, depositAccount, id, remitterNameKana } = charge;
    return {
        amount,
        createdAt,
        depositAccount: (() => {
            const { bankCode, branchCode, accountNumber, accountHolderName } = depositAccount;
            return { bankCode, branchCode, accountNumber, accountHolderName };
        })(),
        id,
        remitterNameKana,
    };
};
