import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  UnixTime: string;
};

export type AggregatedTax = {
  title: Scalars['String'];
  taxableValue: Scalars['Float'];
  decimalRoundRule: DecimalRoundRule;
  value: Scalars['Int'];
};

export type AggregatedTaxInput = {
  title: Scalars['String'];
  taxableValue: Scalars['Float'];
  decimalRoundRule: DecimalRoundRule;
  value: Scalars['Int'];
};

export type App = {
  id: Scalars['ID'];
  name: MultilingualText;
};

export type AppDepositInBalance = {
  id: Scalars['ID'];
  app: App;
  amount: Scalars['Int'];
};

export type AppReserveInBalance = {
  id: Scalars['ID'];
  app: App;
  amount: Scalars['Int'];
  resolvedAt?: Maybe<Scalars['DateTime']>;
};

export type AppWithdrawInBalance = {
  id: Scalars['ID'];
  app: App;
  amount: Scalars['Int'];
  canceledAt?: Maybe<Scalars['DateTime']>;
};

export type Balance = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  currentAmount: Scalars['Int'];
  currentReserve: Scalars['Int'];
  amountIncrease: Scalars['Int'];
  reserveIncrease: Scalars['Int'];
  eventType: BalanceEventType;
  transfer?: Maybe<TransferInBalance>;
  charge?: Maybe<Charge>;
  appDeposit?: Maybe<AppDepositInBalance>;
  appWithdraw?: Maybe<AppWithdrawInBalance>;
  appReserve?: Maybe<AppReserveInBalance>;
};

export type BalanceEventType =
  | 'charge'
  | 'transferTry'
  | 'transferError'
  | 'transferIssue'
  | 'appDeposit'
  | 'appWithdraw'
  | 'appWithdrawCancel'
  | 'appReserveCreate'
  | 'appReserveResolve';

export type BalanceWithInvoice = {
  balance: Balance;
  issuedPayment?: Maybe<IssuedPayment>;
  issuedReceipt?: Maybe<IssuedReceipt>;
  receivedPayment?: Maybe<ReceivedPayment>;
  receivedReceipt?: Maybe<ReceivedReceipt>;
};

export type BalancesFilter = {
  contactId?: Maybe<Scalars['ID']>;
  type?: Maybe<BalanceEventType>;
};

export type BankAccountInTransfer = {
  isDepositAccount: Scalars['Boolean'];
  bankCode: Scalars['String'];
  branchCode: Scalars['String'];
  accountNumber: Scalars['String'];
  accountHolderName: Scalars['String'];
};

export type BankAccountInTransferInput = {
  isDepositAccount: Scalars['Boolean'];
  bankCode: Scalars['String'];
  branchCode: Scalars['String'];
  accountNumber: Scalars['String'];
  accountHolderName: Scalars['String'];
};

export type BillData = {
  customizedId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  issuer: BusinessSnapshot;
  recipient: BusinessSnapshot;
  payee: Payee;
  issueDate?: Maybe<Scalars['DateTime']>;
  paymentDueDate?: Maybe<Scalars['DateTime']>;
  currencyCode: CurrencyCode;
  items: Array<InvoiceDataItem>;
  summary: InvoiceDataSummary;
  note?: Maybe<Scalars['String']>;
};

export type BillDataInput = {
  customizedId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  issuer: BusinessSnapshotInput;
  recipient: BusinessSnapshotInput;
  payee: PayeeInput;
  issueDate?: Maybe<Scalars['DateTime']>;
  paymentDueDate?: Maybe<Scalars['DateTime']>;
  currencyCode: CurrencyCode;
  items: Array<InvoiceDataItemInput>;
  summary: InvoiceDataSummaryInput;
  note?: Maybe<Scalars['String']>;
};

export type BillInPayment = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  billData: BillData;
  signatures: BillSignatures;
};

export type BillInReceipt = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  billData: BillData;
  signatures: BillSignatures;
};

export type BillIssuerSignatures = {
  issue: IssueingSignature;
  revoke?: Maybe<RevokingSignature>;
};

export type BillIssuerSignaturesInput = {
  issue: IssueingSignatureInput;
  revoke?: Maybe<RevokingSignatureInput>;
};

export type BillRecipientSignatures = {
  receive?: Maybe<ReceivingSignature>;
  revoke?: Maybe<RevokingSignature>;
};

export type BillRecipientSignaturesInput = {
  receive?: Maybe<ReceivingSignatureInput>;
  revoke?: Maybe<RevokingSignatureInput>;
};

export type BillSignatures = {
  issuer: BillIssuerSignatures;
  recipient: BillRecipientSignatures;
};

export type BillSignaturesFilter = {
  issuerRevoke?: Maybe<Scalars['Boolean']>;
  recipientReceive?: Maybe<Scalars['Boolean']>;
  recipientRevoke?: Maybe<Scalars['Boolean']>;
};

export type BillSignaturesInput = {
  issuer: BillIssuerSignaturesInput;
  recipient?: Maybe<BillRecipientSignaturesInput>;
};

export type Business = {
  id: Scalars['ID'];
  name: Scalars['String'];
  notificationEmail: Scalars['String'];
  remitterNameKana: Scalars['String'];
  depositAccountFromMeToMe?: Maybe<DepositAccountInBusiness>;
  externalBankAccount?: Maybe<ExternalBankAccount>;
  language?: Maybe<Language>;
  isArchived: Scalars['Boolean'];
};

export type BusinessInBusinessMember = {
  id: Scalars['ID'];
  name: Scalars['String'];
  notificationEmail: Scalars['String'];
  language?: Maybe<Language>;
  isArchived: Scalars['Boolean'];
};

export type BusinessInContact = {
  id: Scalars['ID'];
  name: Scalars['String'];
  remitterNameKana: Scalars['String'];
  externalBankAccount?: Maybe<ExternalBankAccount>;
  depositAccountFromMeToContact?: Maybe<DepositAccountInContact>;
  language?: Maybe<Language>;
  isArchived: Scalars['Boolean'];
  relatedContactId: Scalars['String'];
};

export type BusinessInInvite = {
  id: Scalars['ID'];
  name: Scalars['String'];
  language?: Maybe<Language>;
  isArchived: Scalars['Boolean'];
};

export type BusinessInTransfer = {
  id: Scalars['ID'];
  name: Scalars['String'];
  language?: Maybe<Language>;
  isArchived: Scalars['Boolean'];
};

export type BusinessSnapshot = {
  name: Scalars['String'];
  items: Array<BusinessSnapshotItem>;
};

export type BusinessSnapshotInput = {
  name: Scalars['String'];
  items: Array<BusinessSnapshotItemInput>;
};

export type BusinessSnapshotItem = {
  title: Scalars['String'];
  value: Scalars['String'];
};

export type BusinessSnapshotItemInput = {
  title: Scalars['String'];
  value: Scalars['String'];
};

export type Charge = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  amount: Scalars['Int'];
  remitterNameKana: Scalars['String'];
  depositAccount: DepositAccount;
  transfer?: Maybe<TransferInCharge>;
};

export type Contact = {
  id: Scalars['ID'];
  additionalId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  notificationEmail?: Maybe<Scalars['String']>;
  externalBankAccount?: Maybe<ExternalBankAccount>;
  depositAccountFromContactToMe?: Maybe<DepositAccountInContact>;
  relatedBusiness?: Maybe<BusinessInContact>;
  isArchived: Scalars['Boolean'];
};

export type ContactInDepositAccount = {
  id: Scalars['ID'];
  additionalId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  relatedBusiness?: Maybe<BusinessInTransfer>;
  isArchived: Scalars['Boolean'];
};

export type ContactInInvite = {
  id: Scalars['ID'];
  additionalId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type ContactInIssuedBill = {
  id: Scalars['ID'];
  additionalId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  relatedBusiness?: Maybe<BusinessInTransfer>;
  isArchived: Scalars['Boolean'];
};

export type ContactInReceivedBill = {
  id: Scalars['ID'];
  additionalId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  relatedBusiness: BusinessInTransfer;
  isArchived: Scalars['Boolean'];
};

export type ContactInTransfer = {
  id: Scalars['ID'];
  additionalId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  relatedBusiness?: Maybe<BusinessInTransfer>;
  isArchived: Scalars['Boolean'];
};

export type ContactWithInvite = {
  id: Scalars['ID'];
  additionalId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  notificationEmail?: Maybe<Scalars['String']>;
  externalBankAccount?: Maybe<ExternalBankAccount>;
  depositAccountFromContactToMe?: Maybe<DepositAccountInContact>;
  relatedBusiness?: Maybe<BusinessInContact>;
  invite?: Maybe<InviteForContactInContact>;
  isArchived: Scalars['Boolean'];
};

export type CreatePaymentForBillInput = {
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
  transferId: Scalars['ID'];
  billId: Scalars['ID'];
  paymentData?: Maybe<PaymentDataInput>;
};

export type CreatePaymentForBillPayload = {
  payment: IssuedPayment;
};

export type CreatePaymentInput = {
  businessId: Scalars['ID'];
  transferId: Scalars['ID'];
  paymentData: PaymentDataInput;
};

export type CreatePaymentPayload = {
  payment: IssuedPayment;
};

export type CreateReceiptForBillInput = {
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
  chargeId: Scalars['ID'];
  billId: Scalars['ID'];
  receiptData?: Maybe<ReceiptDataInput>;
};

export type CreateReceiptForBillPayload = {
  receipt: IssuedReceipt;
};

export type CreateReceiptInput = {
  businessId: Scalars['ID'];
  chargeId: Scalars['ID'];
  receiptData: ReceiptDataInput;
};

export type CreateReceiptPayload = {
  receipt: IssuedReceipt;
};

export type CreateResourceAndSignatureInput = {
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
  signatureText: Scalars['String'];
};

export type CreateResourceAndSignaturePayload = {
  resource: Resource;
  signature: Signature;
};

export type CreateResourceInput = {
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
};

export type CreateResourcePayload = {
  resource: Resource;
};

export type CurrencyCode =
  | 'jpy';


export type DecimalRoundRule =
  | 'down'
  | 'up'
  | 'near';

export type DepositAccount = {
  id: Scalars['ID'];
  bankCode: Scalars['String'];
  branchCode: Scalars['String'];
  accountNumber: Scalars['String'];
  accountHolderName: Scalars['String'];
  fromContact?: Maybe<ContactInDepositAccount>;
};

export type DepositAccountInBusiness = {
  id: Scalars['ID'];
  bankCode: Scalars['String'];
  branchCode: Scalars['String'];
  accountNumber: Scalars['String'];
  accountHolderName: Scalars['String'];
};

export type DepositAccountInContact = {
  id: Scalars['ID'];
  bankCode: Scalars['String'];
  branchCode: Scalars['String'];
  accountNumber: Scalars['String'];
  accountHolderName: Scalars['String'];
};

export type ExternalBankAccount = {
  bankCode: Scalars['String'];
  branchCode: Scalars['String'];
  accountNumber: Scalars['String'];
  accountHolderName: Scalars['String'];
};

export type ExternalBankAccountInput = {
  bankCode: Scalars['String'];
  branchCode: Scalars['String'];
  accountNumber: Scalars['String'];
  accountHolderName: Scalars['String'];
};

export type Fee = {
  priceBeforeTax: Scalars['Int'];
  taxRate: Scalars['Float'];
  total: Scalars['Int'];
};

export type FeeInput = {
  priceBeforeTax: Scalars['Int'];
  taxRate: Scalars['Float'];
  total: Scalars['Int'];
};

export type Fees = {
  transfer: TransferFees;
};

export type GetBalanceWithInvoicesInput = {
  businessId: Scalars['ID'];
  filter?: Maybe<BalancesFilter>;
};

export type GetBalanceWithInvoicesPayload = {
  balances: Array<BalanceWithInvoice>;
};

export type GetIssuedBillInput = {
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
  billId: Scalars['ID'];
};

export type GetIssuedBillPayload = {
  bill: IssuedBill;
};

export type GetIssuedBillsInput = {
  businessId: Scalars['ID'];
  filter?: Maybe<IssuedBillsFilter>;
};

export type GetIssuedBillsPayload = {
  bills: Array<IssuedBill>;
};

export type GetReceivedBillInput = {
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
  billId: Scalars['ID'];
};

export type GetReceivedBillPayload = {
  bill: ReceivedBill;
};

export type GetReceivedBillsInput = {
  businessId: Scalars['ID'];
  filter?: Maybe<ReceivedBillsFilter>;
};

export type GetReceivedBillsPayload = {
  bills: Array<ReceivedBill>;
};

export type GetResourceInput = {
  businessId: Scalars['ID'];
  resourceId: Scalars['ID'];
};

export type GetResourcePayload = {
  resource: Resource;
};

export type GetSharedBillInput = {
  issuerBusinessId: Scalars['ID'];
  issuerContactId: Scalars['ID'];
  billId: Scalars['ID'];
  inviteCodeText: Scalars['String'];
};

export type GetSharedBillPayload = {
  bill: SharedBill;
  invite: InviteForContact;
};

export type GetSharedPaymentInput = {
  issuerBusinessId: Scalars['ID'];
  issuerContactId: Scalars['ID'];
  paymentId: Scalars['ID'];
  inviteCodeText: Scalars['String'];
};

export type GetSharedPaymentPayload = {
  payment: SharedPayment;
  invite: InviteForContact;
};

export type GetSharedReceiptInput = {
  issuerBusinessId: Scalars['ID'];
  issuerContactId: Scalars['ID'];
  receiptId: Scalars['ID'];
  inviteCodeText: Scalars['String'];
};

export type GetSharedReceiptPayload = {
  receipt: SharedReceipt;
  invite: InviteForContact;
};

export type ImportIssuedBillInput = {
  businessId: Scalars['ID'];
  billData: BillDataInput;
  signatures: BillSignaturesInput;
};

export type ImportIssuedBillPayload = {
  issuedBill: IssuedBill;
};

export type ImportSignaturesToIssuedBillInput = {
  businessId: Scalars['ID'];
  revoke?: Maybe<RevokingSignatureInput>;
};

export type ImportSignaturesToIssuedBillPayload = {
  result: MutationValidationResult;
  issuedBill: IssuedBill;
};

export type ImportSignaturesToReceivedBillInput = {
  businessId: Scalars['ID'];
  receive?: Maybe<ReceivingSignatureInput>;
  revoke?: Maybe<RevokingSignatureInput>;
};

export type ImportSignaturesToReceivedBillPayload = {
  result: MutationValidationResult;
  receivedBill: ReceivedBill;
};

export type InviteForContact = {
  codeText: Scalars['String'];
  createdAt: Scalars['DateTime'];
  expireAt: Scalars['DateTime'];
  business: BusinessInInvite;
  contact: ContactInInvite;
};

export type InviteForContactInContact = {
  codeText: Scalars['String'];
  createdAt: Scalars['DateTime'];
  expireAt: Scalars['DateTime'];
};

export type InvoiceDataItem = {
  date?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  unitPrice: Scalars['Float'];
  quantity: Scalars['Float'];
  taxes: Array<TaxInInvoiceDataItem>;
};

export type InvoiceDataItemInput = {
  date?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  unitPrice: Scalars['Float'];
  quantity: Scalars['Float'];
  taxes: Array<TaxInInvoiceDataItemInput>;
};

export type InvoiceDataSummary = {
  decimalRoundRuleForSubtotal: DecimalRoundRule;
  subtotal: Scalars['Int'];
  taxes: Array<AggregatedTax>;
  total: Scalars['Int'];
};

export type InvoiceDataSummaryInput = {
  decimalRoundRuleForSubtotal: DecimalRoundRule;
  subtotal: Scalars['Int'];
  taxes: Array<AggregatedTaxInput>;
  total: Scalars['Int'];
};

export type IssuedBill = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  contact: ContactInIssuedBill;
  billData: BillData;
  signatures: BillSignatures;
  payment?: Maybe<PaymentInIssuedBill>;
  receipt?: Maybe<ReceiptInIssuedBill>;
};

export type IssuedBillsFilter = {
  contactId?: Maybe<Scalars['ID']>;
  signatures?: Maybe<BillSignaturesFilter>;
};

export type IssuedPayment = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  paymentData: PaymentData;
  bill?: Maybe<BillInPayment>;
  transfer: Transfer;
};

export type IssuedReceipt = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  receiptData: ReceiptData;
  bill?: Maybe<BillInReceipt>;
  charge: Charge;
};

export type IssueingSignature = {
  issuedAt: Scalars['DateTime'];
  signature: Signature;
};

export type IssueingSignatureInput = {
  issuedAt: Scalars['DateTime'];
  signature: SignatureInput;
};

export type Issuer = {
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
};

export type Language =
  | 'en'
  | 'ja';

export type LegalTexts = {
  tos: Scalars['String'];
};

export type MultilingualText = {
  en: Scalars['String'];
  ja?: Maybe<Scalars['String']>;
};

export type Mutation = {
  createPaymentForBill: CreatePaymentForBillPayload;
  createPayment: CreatePaymentPayload;
  createReceiptForBill: CreateReceiptForBillPayload;
  createReceipt: CreateReceiptPayload;
  importIssuedBill: ImportIssuedBillPayload;
  importSignaturesToIssuedBill: ImportSignaturesToIssuedBillPayload;
  importSignaturesToReceivedBill: ImportSignaturesToReceivedBillPayload;
};


export type MutationCreatePaymentForBillArgs = {
  input: CreatePaymentForBillInput;
};


export type MutationCreatePaymentArgs = {
  input: CreatePaymentInput;
};


export type MutationCreateReceiptForBillArgs = {
  input: CreateReceiptForBillInput;
};


export type MutationCreateReceiptArgs = {
  input: CreateReceiptInput;
};


export type MutationImportIssuedBillArgs = {
  input: ImportIssuedBillInput;
};


export type MutationImportSignaturesToIssuedBillArgs = {
  input: ImportSignaturesToIssuedBillInput;
};


export type MutationImportSignaturesToReceivedBillArgs = {
  input: ImportSignaturesToReceivedBillInput;
};

export type MutationValidationResult =
  | 'success'
  | 'incorrectOldValue'
  | 'intervalTooShort';

export type Payee = {
  uodkaBankAccount: BankAccountInTransfer;
};

export type PayeeInput = {
  uodkaBankAccount: BankAccountInTransferInput;
};

export type PaymentData = {
  billCustomizedId?: Maybe<Scalars['String']>;
  customizedId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  issuer: BusinessSnapshot;
  recipient: BusinessSnapshot;
  payee: Payee;
  issueDate?: Maybe<Scalars['DateTime']>;
  currencyCode: CurrencyCode;
  items: Array<InvoiceDataItem>;
  summary: InvoiceDataSummary;
  note?: Maybe<Scalars['String']>;
};

export type PaymentDataInput = {
  billCustomizedId?: Maybe<Scalars['String']>;
  customizedId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  issuer: BusinessSnapshotInput;
  recipient: BusinessSnapshotInput;
  payee: PayeeInput;
  issueDate?: Maybe<Scalars['DateTime']>;
  currencyCode: CurrencyCode;
  items: Array<InvoiceDataItemInput>;
  summary: InvoiceDataSummaryInput;
  note?: Maybe<Scalars['String']>;
};

export type PaymentInIssuedBill = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  paymentData: PaymentData;
  incomingTransfer: SharedTransfer;
  charge?: Maybe<Charge>;
};

export type PaymentInReceivedBill = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  paymentData: PaymentData;
  transfer: Transfer;
};

export type PaymentInSharedBill = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  paymentData: PaymentData;
};

export type Query = {
  serviceHealth: ServiceHealth;
  getSharedBill: GetSharedBillPayload;
  getSharedPayment: GetSharedPaymentPayload;
  getSharedReceipt: GetSharedReceiptPayload;
  getBalanceWithInvoices: GetBalanceWithInvoicesPayload;
  getIssuedBill: GetIssuedBillPayload;
  getReceivedBill: GetReceivedBillPayload;
  getIssuedBills: GetIssuedBillsPayload;
  getReceivedBills: GetReceivedBillsPayload;
};


export type QueryGetSharedBillArgs = {
  input: GetSharedBillInput;
};


export type QueryGetSharedPaymentArgs = {
  input: GetSharedPaymentInput;
};


export type QueryGetSharedReceiptArgs = {
  input: GetSharedReceiptInput;
};


export type QueryGetBalanceWithInvoicesArgs = {
  input: GetBalanceWithInvoicesInput;
};


export type QueryGetIssuedBillArgs = {
  input: GetIssuedBillInput;
};


export type QueryGetReceivedBillArgs = {
  input: GetReceivedBillInput;
};


export type QueryGetIssuedBillsArgs = {
  input: GetIssuedBillsInput;
};


export type QueryGetReceivedBillsArgs = {
  input: GetReceivedBillsInput;
};

export type ReceiptData = {
  billCustomizedId?: Maybe<Scalars['String']>;
  customizedId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  issuer: BusinessSnapshot;
  recipient: BusinessSnapshot;
  payee: Payee;
  issueDate?: Maybe<Scalars['DateTime']>;
  currencyCode: CurrencyCode;
  items: Array<InvoiceDataItem>;
  summary: InvoiceDataSummary;
  note?: Maybe<Scalars['String']>;
};

export type ReceiptDataInput = {
  billCustomizedId?: Maybe<Scalars['String']>;
  customizedId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  issuer: BusinessSnapshotInput;
  recipient: BusinessSnapshotInput;
  payee: PayeeInput;
  issueDate?: Maybe<Scalars['DateTime']>;
  currencyCode: CurrencyCode;
  items: Array<InvoiceDataItemInput>;
  summary: InvoiceDataSummaryInput;
  note?: Maybe<Scalars['String']>;
};

export type ReceiptInIssuedBill = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  receiptData: ReceiptData;
  charge: Charge;
};

export type ReceiptInReceivedBill = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  receiptData: ReceiptData;
  transfer: Transfer;
};

export type ReceiptInSharedBill = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  receiptData: ReceiptData;
};

export type ReceivedBill = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  contact: ContactInReceivedBill;
  billData: BillData;
  signatures: BillSignatures;
  payment?: Maybe<PaymentInReceivedBill>;
  receipt?: Maybe<ReceiptInReceivedBill>;
};

export type ReceivedBillsFilter = {
  contactId?: Maybe<Scalars['ID']>;
  signatures?: Maybe<BillSignaturesFilter>;
};

export type ReceivedPayment = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  paymentData: PaymentData;
  bill?: Maybe<BillInPayment>;
  incomingTransfer: SharedTransfer;
  charge?: Maybe<Charge>;
};

export type ReceivedReceipt = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  receiptData: ReceiptData;
  bill?: Maybe<BillInReceipt>;
  transfer: Transfer;
};

export type ReceivingSignature = {
  billReceivedAt: Scalars['DateTime'];
  signature: Signature;
};

export type ReceivingSignatureInput = {
  billReceivedAt: Scalars['DateTime'];
  signature: SignatureInput;
};

export type Recipient = {
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
};

export type Resource = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  issuer: Issuer;
  recipient?: Maybe<Recipient>;
};

export type RevokingSignature = {
  agreeToRevokeAt: Scalars['DateTime'];
  signature: Signature;
};

export type RevokingSignatureInput = {
  agreeToRevokeAt: Scalars['DateTime'];
  signature: SignatureInput;
};

export type ServiceHealth = {
  code: ServiceHealthCode;
  message?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ServiceHealthCode =
  | 'ok'
  | 'outOfService';

export type SharedBill = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  billData: BillData;
  signatures: BillSignatures;
  payment?: Maybe<PaymentInSharedBill>;
  receipt?: Maybe<ReceiptInSharedBill>;
};

export type SharedPayment = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  paymentData: PaymentData;
  bill?: Maybe<BillInPayment>;
  transfer: SharedTransfer;
};

export type SharedReceipt = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  receiptData: ReceiptData;
  bill?: Maybe<BillInReceipt>;
};

export type SharedTransfer = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  issuer: BusinessInTransfer;
  contact: ContactInTransfer;
  bankAccount: BankAccountInTransfer;
  amount: Scalars['Int'];
  feeOnIssuer: Fee;
  remitterNameKana: Scalars['String'];
  error?: Maybe<TransferError>;
  issuedAt?: Maybe<Scalars['DateTime']>;
};

export type Signature = {
  resourceId: Scalars['ID'];
  id: Scalars['ID'];
  signerId: Scalars['ID'];
  contactId: Scalars['ID'];
  text: Scalars['String'];
  unixTime: Scalars['UnixTime'];
};

export type SignatureInput = {
  resourceId: Scalars['ID'];
  id: Scalars['ID'];
  signerId: Scalars['ID'];
  contactId: Scalars['ID'];
  text: Scalars['String'];
  unixTime: Scalars['UnixTime'];
};

export type TaxInInvoiceDataItem = {
  title: Scalars['String'];
  value: Scalars['Float'];
};

export type TaxInInvoiceDataItemInput = {
  title: Scalars['String'];
  value: Scalars['Float'];
};

export type Transfer = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  issuer: BusinessInTransfer;
  contact: ContactInTransfer;
  bankAccount: BankAccountInTransfer;
  amount: Scalars['Int'];
  feeOnIssuer: Fee;
  remitterNameKana: Scalars['String'];
  error?: Maybe<TransferError>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  operatorApp?: Maybe<App>;
};

export type TransferConfirmation = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  issuer: BusinessInTransfer;
  contact: ContactInTransfer;
  bankAccount: BankAccountInTransfer;
  amount: Scalars['Int'];
  feeOnIssuer: Fee;
  remitterNameKana: Scalars['String'];
  confirmationDueDate: Scalars['DateTime'];
  operatorApp?: Maybe<App>;
};

export type TransferError = {
  setAt: Scalars['DateTime'];
  code: TransferErrorCode;
  message?: Maybe<Scalars['String']>;
};

export type TransferErrorCode =
  | 'uodkaError'
  | 'bankProcessError'
  | 'inputError';

export type TransferFees = {
  internal: Fee;
  external: Fee;
};

export type TransferInBalance = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  contact: ContactInTransfer;
  bankAccount: BankAccountInTransfer;
  amount: Scalars['Int'];
  feeOnIssuer: Fee;
  remitterNameKana: Scalars['String'];
  error?: Maybe<TransferError>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  operatorApp?: Maybe<App>;
};

export type TransferInCharge = {
  id: Scalars['ID'];
};


export type CreatePaymentMutationVariables = Exact<{
  input: CreatePaymentInput;
}>;


export type CreatePaymentMutation = { createPayment: { payment: (
      Pick<IssuedPayment, 'id' | 'createdAt'>
      & { paymentData: (
        Pick<PaymentData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
        & { issuer: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), recipient: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
          Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
          & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
        )>, summary: (
          Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
          & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
        ) }
      ), bill?: Maybe<(
        Pick<BillInPayment, 'id' | 'createdAt'>
        & { billData: (
          Pick<BillData, 'customizedId' | 'title' | 'issueDate' | 'paymentDueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), signatures: { issuer: { issue: (
              Pick<IssueingSignature, 'issuedAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            ), revoke?: Maybe<(
              Pick<RevokingSignature, 'agreeToRevokeAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            )> }, recipient: { receive?: Maybe<(
              Pick<ReceivingSignature, 'billReceivedAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            )>, revoke?: Maybe<(
              Pick<RevokingSignature, 'agreeToRevokeAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            )> } } }
      )>, transfer: (
        Pick<Transfer, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
        & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
          Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
          & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
        ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>>, operatorApp?: Maybe<(
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        )> }
      ) }
    ) } };

export type CreatePaymentForBillMutationVariables = Exact<{
  input: CreatePaymentForBillInput;
}>;


export type CreatePaymentForBillMutation = { createPaymentForBill: { payment: (
      Pick<IssuedPayment, 'id' | 'createdAt'>
      & { paymentData: (
        Pick<PaymentData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
        & { issuer: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), recipient: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
          Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
          & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
        )>, summary: (
          Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
          & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
        ) }
      ), bill?: Maybe<(
        Pick<BillInPayment, 'id' | 'createdAt'>
        & { billData: (
          Pick<BillData, 'customizedId' | 'title' | 'issueDate' | 'paymentDueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), signatures: { issuer: { issue: (
              Pick<IssueingSignature, 'issuedAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            ), revoke?: Maybe<(
              Pick<RevokingSignature, 'agreeToRevokeAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            )> }, recipient: { receive?: Maybe<(
              Pick<ReceivingSignature, 'billReceivedAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            )>, revoke?: Maybe<(
              Pick<RevokingSignature, 'agreeToRevokeAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            )> } } }
      )>, transfer: (
        Pick<Transfer, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
        & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
          Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
          & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
        ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>>, operatorApp?: Maybe<(
          Pick<App, 'id'>
          & { name: Pick<MultilingualText, 'en' | 'ja'> }
        )> }
      ) }
    ) } };

export type CreateReceiptMutationVariables = Exact<{
  input: CreateReceiptInput;
}>;


export type CreateReceiptMutation = { createReceipt: { receipt: (
      Pick<IssuedReceipt, 'id' | 'createdAt'>
      & { receiptData: (
        Pick<ReceiptData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
        & { issuer: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), recipient: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
          Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
          & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
        )>, summary: (
          Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
          & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
        ) }
      ), bill?: Maybe<(
        Pick<BillInReceipt, 'id' | 'createdAt'>
        & { billData: (
          Pick<BillData, 'customizedId' | 'title' | 'issueDate' | 'paymentDueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), signatures: { issuer: { issue: (
              Pick<IssueingSignature, 'issuedAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            ), revoke?: Maybe<(
              Pick<RevokingSignature, 'agreeToRevokeAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            )> }, recipient: { receive?: Maybe<(
              Pick<ReceivingSignature, 'billReceivedAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            )>, revoke?: Maybe<(
              Pick<RevokingSignature, 'agreeToRevokeAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            )> } } }
      )>, charge: (
        Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
        & { depositAccount: (
          Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
          & { fromContact?: Maybe<(
            Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
            & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
          )> }
        ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
      ) }
    ) } };

export type CreateReceiptForBillMutationVariables = Exact<{
  input: CreateReceiptForBillInput;
}>;


export type CreateReceiptForBillMutation = { createReceiptForBill: { receipt: (
      Pick<IssuedReceipt, 'id' | 'createdAt'>
      & { receiptData: (
        Pick<ReceiptData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
        & { issuer: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), recipient: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
          Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
          & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
        )>, summary: (
          Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
          & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
        ) }
      ), bill?: Maybe<(
        Pick<BillInReceipt, 'id' | 'createdAt'>
        & { billData: (
          Pick<BillData, 'customizedId' | 'title' | 'issueDate' | 'paymentDueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), signatures: { issuer: { issue: (
              Pick<IssueingSignature, 'issuedAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            ), revoke?: Maybe<(
              Pick<RevokingSignature, 'agreeToRevokeAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            )> }, recipient: { receive?: Maybe<(
              Pick<ReceivingSignature, 'billReceivedAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            )>, revoke?: Maybe<(
              Pick<RevokingSignature, 'agreeToRevokeAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            )> } } }
      )>, charge: (
        Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
        & { depositAccount: (
          Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
          & { fromContact?: Maybe<(
            Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
            & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
          )> }
        ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
      ) }
    ) } };

export type ImportIssuedBillMutationVariables = Exact<{
  input: ImportIssuedBillInput;
}>;


export type ImportIssuedBillMutation = { importIssuedBill: { issuedBill: (
      Pick<IssuedBill, 'id' | 'createdAt'>
      & { contact: (
        Pick<ContactInIssuedBill, 'id' | 'additionalId' | 'name' | 'isArchived'>
        & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
      ), billData: (
        Pick<BillData, 'customizedId' | 'title' | 'issueDate' | 'paymentDueDate' | 'currencyCode' | 'note'>
        & { issuer: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), recipient: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
          Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
          & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
        )>, summary: (
          Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
          & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
        ) }
      ), signatures: { issuer: { issue: (
            Pick<IssueingSignature, 'issuedAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          ), revoke?: Maybe<(
            Pick<RevokingSignature, 'agreeToRevokeAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )> }, recipient: { receive?: Maybe<(
            Pick<ReceivingSignature, 'billReceivedAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )>, revoke?: Maybe<(
            Pick<RevokingSignature, 'agreeToRevokeAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )> } }, payment?: Maybe<(
        Pick<PaymentInIssuedBill, 'id' | 'createdAt'>
        & { paymentData: (
          Pick<PaymentData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), charge?: Maybe<(
          Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
          & { depositAccount: (
            Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
            & { fromContact?: Maybe<(
              Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
              & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
            )> }
          ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
        )>, incomingTransfer: (
          Pick<SharedTransfer, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
          & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
            Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
            & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
          ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>> }
        ) }
      )>, receipt?: Maybe<(
        Pick<ReceiptInIssuedBill, 'id' | 'createdAt'>
        & { receiptData: (
          Pick<ReceiptData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), charge: (
          Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
          & { depositAccount: (
            Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
            & { fromContact?: Maybe<(
              Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
              & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
            )> }
          ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
        ) }
      )> }
    ) } };

export type ImportSignaturesToIssuedBillMutationVariables = Exact<{
  input: ImportSignaturesToIssuedBillInput;
}>;


export type ImportSignaturesToIssuedBillMutation = { importSignaturesToIssuedBill: (
    Pick<ImportSignaturesToIssuedBillPayload, 'result'>
    & { issuedBill: (
      Pick<IssuedBill, 'id' | 'createdAt'>
      & { contact: (
        Pick<ContactInIssuedBill, 'id' | 'additionalId' | 'name' | 'isArchived'>
        & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
      ), billData: (
        Pick<BillData, 'customizedId' | 'title' | 'issueDate' | 'paymentDueDate' | 'currencyCode' | 'note'>
        & { issuer: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), recipient: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
          Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
          & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
        )>, summary: (
          Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
          & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
        ) }
      ), signatures: { issuer: { issue: (
            Pick<IssueingSignature, 'issuedAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          ), revoke?: Maybe<(
            Pick<RevokingSignature, 'agreeToRevokeAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )> }, recipient: { receive?: Maybe<(
            Pick<ReceivingSignature, 'billReceivedAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )>, revoke?: Maybe<(
            Pick<RevokingSignature, 'agreeToRevokeAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )> } }, payment?: Maybe<(
        Pick<PaymentInIssuedBill, 'id' | 'createdAt'>
        & { paymentData: (
          Pick<PaymentData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), charge?: Maybe<(
          Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
          & { depositAccount: (
            Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
            & { fromContact?: Maybe<(
              Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
              & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
            )> }
          ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
        )>, incomingTransfer: (
          Pick<SharedTransfer, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
          & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
            Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
            & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
          ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>> }
        ) }
      )>, receipt?: Maybe<(
        Pick<ReceiptInIssuedBill, 'id' | 'createdAt'>
        & { receiptData: (
          Pick<ReceiptData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), charge: (
          Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
          & { depositAccount: (
            Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
            & { fromContact?: Maybe<(
              Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
              & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
            )> }
          ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
        ) }
      )> }
    ) }
  ) };

export type ImportSignaturesToReceivedBillMutationVariables = Exact<{
  input: ImportSignaturesToReceivedBillInput;
}>;


export type ImportSignaturesToReceivedBillMutation = { importSignaturesToReceivedBill: (
    Pick<ImportSignaturesToReceivedBillPayload, 'result'>
    & { receivedBill: (
      Pick<ReceivedBill, 'id' | 'createdAt'>
      & { contact: (
        Pick<ContactInReceivedBill, 'id' | 'additionalId' | 'name' | 'isArchived'>
        & { relatedBusiness: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'> }
      ), billData: (
        Pick<BillData, 'customizedId' | 'title' | 'issueDate' | 'paymentDueDate' | 'currencyCode' | 'note'>
        & { issuer: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), recipient: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
          Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
          & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
        )>, summary: (
          Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
          & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
        ) }
      ), signatures: { issuer: { issue: (
            Pick<IssueingSignature, 'issuedAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          ), revoke?: Maybe<(
            Pick<RevokingSignature, 'agreeToRevokeAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )> }, recipient: { receive?: Maybe<(
            Pick<ReceivingSignature, 'billReceivedAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )>, revoke?: Maybe<(
            Pick<RevokingSignature, 'agreeToRevokeAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )> } }, payment?: Maybe<(
        Pick<PaymentInReceivedBill, 'id' | 'createdAt'>
        & { paymentData: (
          Pick<PaymentData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), transfer: (
          Pick<Transfer, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
          & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
            Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
            & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
          ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>>, operatorApp?: Maybe<(
            Pick<App, 'id'>
            & { name: Pick<MultilingualText, 'en' | 'ja'> }
          )> }
        ) }
      )>, receipt?: Maybe<(
        Pick<ReceiptInReceivedBill, 'id' | 'createdAt'>
        & { receiptData: (
          Pick<ReceiptData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), transfer: (
          Pick<Transfer, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
          & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
            Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
            & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
          ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>>, operatorApp?: Maybe<(
            Pick<App, 'id'>
            & { name: Pick<MultilingualText, 'en' | 'ja'> }
          )> }
        ) }
      )> }
    ) }
  ) };

export type GetBalanceWithInvoicesQueryVariables = Exact<{
  input: GetBalanceWithInvoicesInput;
}>;


export type GetBalanceWithInvoicesQuery = { getBalanceWithInvoices: { balances: Array<{ balance: (
        Pick<Balance, 'id' | 'createdAt' | 'currentAmount' | 'currentReserve' | 'amountIncrease' | 'reserveIncrease' | 'eventType'>
        & { transfer?: Maybe<(
          Pick<TransferInBalance, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
          & { contact: (
            Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
            & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
          ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>>, operatorApp?: Maybe<(
            Pick<App, 'id'>
            & { name: Pick<MultilingualText, 'en' | 'ja'> }
          )> }
        )>, charge?: Maybe<(
          Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
          & { depositAccount: (
            Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
            & { fromContact?: Maybe<(
              Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
              & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
            )> }
          ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
        )>, appDeposit?: Maybe<(
          Pick<AppDepositInBalance, 'id' | 'amount'>
          & { app: (
            Pick<App, 'id'>
            & { name: Pick<MultilingualText, 'en' | 'ja'> }
          ) }
        )>, appWithdraw?: Maybe<(
          Pick<AppWithdrawInBalance, 'id' | 'amount' | 'canceledAt'>
          & { app: (
            Pick<App, 'id'>
            & { name: Pick<MultilingualText, 'en' | 'ja'> }
          ) }
        )>, appReserve?: Maybe<(
          Pick<AppReserveInBalance, 'id' | 'amount' | 'resolvedAt'>
          & { app: (
            Pick<App, 'id'>
            & { name: Pick<MultilingualText, 'en' | 'ja'> }
          ) }
        )> }
      ), issuedPayment?: Maybe<(
        Pick<IssuedPayment, 'id' | 'createdAt'>
        & { paymentData: (
          Pick<PaymentData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), bill?: Maybe<(
          Pick<BillInPayment, 'id' | 'createdAt'>
          & { billData: (
            Pick<BillData, 'customizedId' | 'title' | 'issueDate' | 'paymentDueDate' | 'currencyCode' | 'note'>
            & { issuer: (
              Pick<BusinessSnapshot, 'name'>
              & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
            ), recipient: (
              Pick<BusinessSnapshot, 'name'>
              & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
            ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
              Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
              & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
            )>, summary: (
              Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
              & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
            ) }
          ), signatures: { issuer: { issue: (
                Pick<IssueingSignature, 'issuedAt'>
                & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
              ), revoke?: Maybe<(
                Pick<RevokingSignature, 'agreeToRevokeAt'>
                & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
              )> }, recipient: { receive?: Maybe<(
                Pick<ReceivingSignature, 'billReceivedAt'>
                & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
              )>, revoke?: Maybe<(
                Pick<RevokingSignature, 'agreeToRevokeAt'>
                & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
              )> } } }
        )>, transfer: (
          Pick<Transfer, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
          & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
            Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
            & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
          ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>>, operatorApp?: Maybe<(
            Pick<App, 'id'>
            & { name: Pick<MultilingualText, 'en' | 'ja'> }
          )> }
        ) }
      )>, issuedReceipt?: Maybe<(
        Pick<IssuedReceipt, 'id' | 'createdAt'>
        & { receiptData: (
          Pick<ReceiptData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), bill?: Maybe<(
          Pick<BillInReceipt, 'id' | 'createdAt'>
          & { billData: (
            Pick<BillData, 'customizedId' | 'title' | 'issueDate' | 'paymentDueDate' | 'currencyCode' | 'note'>
            & { issuer: (
              Pick<BusinessSnapshot, 'name'>
              & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
            ), recipient: (
              Pick<BusinessSnapshot, 'name'>
              & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
            ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
              Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
              & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
            )>, summary: (
              Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
              & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
            ) }
          ), signatures: { issuer: { issue: (
                Pick<IssueingSignature, 'issuedAt'>
                & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
              ), revoke?: Maybe<(
                Pick<RevokingSignature, 'agreeToRevokeAt'>
                & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
              )> }, recipient: { receive?: Maybe<(
                Pick<ReceivingSignature, 'billReceivedAt'>
                & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
              )>, revoke?: Maybe<(
                Pick<RevokingSignature, 'agreeToRevokeAt'>
                & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
              )> } } }
        )>, charge: (
          Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
          & { depositAccount: (
            Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
            & { fromContact?: Maybe<(
              Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
              & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
            )> }
          ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
        ) }
      )>, receivedPayment?: Maybe<(
        Pick<ReceivedPayment, 'id' | 'createdAt'>
        & { paymentData: (
          Pick<PaymentData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), bill?: Maybe<(
          Pick<BillInPayment, 'id' | 'createdAt'>
          & { billData: (
            Pick<BillData, 'customizedId' | 'title' | 'issueDate' | 'paymentDueDate' | 'currencyCode' | 'note'>
            & { issuer: (
              Pick<BusinessSnapshot, 'name'>
              & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
            ), recipient: (
              Pick<BusinessSnapshot, 'name'>
              & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
            ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
              Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
              & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
            )>, summary: (
              Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
              & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
            ) }
          ), signatures: { issuer: { issue: (
                Pick<IssueingSignature, 'issuedAt'>
                & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
              ), revoke?: Maybe<(
                Pick<RevokingSignature, 'agreeToRevokeAt'>
                & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
              )> }, recipient: { receive?: Maybe<(
                Pick<ReceivingSignature, 'billReceivedAt'>
                & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
              )>, revoke?: Maybe<(
                Pick<RevokingSignature, 'agreeToRevokeAt'>
                & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
              )> } } }
        )>, charge?: Maybe<(
          Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
          & { depositAccount: (
            Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
            & { fromContact?: Maybe<(
              Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
              & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
            )> }
          ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
        )>, incomingTransfer: (
          Pick<SharedTransfer, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
          & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
            Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
            & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
          ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>> }
        ) }
      )>, receivedReceipt?: Maybe<(
        Pick<ReceivedReceipt, 'id' | 'createdAt'>
        & { receiptData: (
          Pick<ReceiptData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), bill?: Maybe<(
          Pick<BillInReceipt, 'id' | 'createdAt'>
          & { billData: (
            Pick<BillData, 'customizedId' | 'title' | 'issueDate' | 'paymentDueDate' | 'currencyCode' | 'note'>
            & { issuer: (
              Pick<BusinessSnapshot, 'name'>
              & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
            ), recipient: (
              Pick<BusinessSnapshot, 'name'>
              & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
            ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
              Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
              & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
            )>, summary: (
              Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
              & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
            ) }
          ), signatures: { issuer: { issue: (
                Pick<IssueingSignature, 'issuedAt'>
                & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
              ), revoke?: Maybe<(
                Pick<RevokingSignature, 'agreeToRevokeAt'>
                & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
              )> }, recipient: { receive?: Maybe<(
                Pick<ReceivingSignature, 'billReceivedAt'>
                & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
              )>, revoke?: Maybe<(
                Pick<RevokingSignature, 'agreeToRevokeAt'>
                & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
              )> } } }
        )>, transfer: (
          Pick<Transfer, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
          & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
            Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
            & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
          ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>>, operatorApp?: Maybe<(
            Pick<App, 'id'>
            & { name: Pick<MultilingualText, 'en' | 'ja'> }
          )> }
        ) }
      )> }> } };

export type GetIssuedBillQueryVariables = Exact<{
  input: GetIssuedBillInput;
}>;


export type GetIssuedBillQuery = { getIssuedBill: { bill: (
      Pick<IssuedBill, 'id' | 'createdAt'>
      & { contact: (
        Pick<ContactInIssuedBill, 'id' | 'additionalId' | 'name' | 'isArchived'>
        & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
      ), billData: (
        Pick<BillData, 'customizedId' | 'title' | 'issueDate' | 'paymentDueDate' | 'currencyCode' | 'note'>
        & { issuer: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), recipient: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
          Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
          & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
        )>, summary: (
          Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
          & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
        ) }
      ), signatures: { issuer: { issue: (
            Pick<IssueingSignature, 'issuedAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          ), revoke?: Maybe<(
            Pick<RevokingSignature, 'agreeToRevokeAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )> }, recipient: { receive?: Maybe<(
            Pick<ReceivingSignature, 'billReceivedAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )>, revoke?: Maybe<(
            Pick<RevokingSignature, 'agreeToRevokeAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )> } }, payment?: Maybe<(
        Pick<PaymentInIssuedBill, 'id' | 'createdAt'>
        & { paymentData: (
          Pick<PaymentData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), charge?: Maybe<(
          Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
          & { depositAccount: (
            Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
            & { fromContact?: Maybe<(
              Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
              & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
            )> }
          ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
        )>, incomingTransfer: (
          Pick<SharedTransfer, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
          & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
            Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
            & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
          ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>> }
        ) }
      )>, receipt?: Maybe<(
        Pick<ReceiptInIssuedBill, 'id' | 'createdAt'>
        & { receiptData: (
          Pick<ReceiptData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), charge: (
          Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
          & { depositAccount: (
            Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
            & { fromContact?: Maybe<(
              Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
              & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
            )> }
          ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
        ) }
      )> }
    ) } };

export type GetIssuedBillsQueryVariables = Exact<{
  input: GetIssuedBillsInput;
}>;


export type GetIssuedBillsQuery = { getIssuedBills: { bills: Array<(
      Pick<IssuedBill, 'id' | 'createdAt'>
      & { contact: (
        Pick<ContactInIssuedBill, 'id' | 'additionalId' | 'name' | 'isArchived'>
        & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
      ), billData: (
        Pick<BillData, 'customizedId' | 'title' | 'issueDate' | 'paymentDueDate' | 'currencyCode' | 'note'>
        & { issuer: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), recipient: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
          Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
          & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
        )>, summary: (
          Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
          & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
        ) }
      ), signatures: { issuer: { issue: (
            Pick<IssueingSignature, 'issuedAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          ), revoke?: Maybe<(
            Pick<RevokingSignature, 'agreeToRevokeAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )> }, recipient: { receive?: Maybe<(
            Pick<ReceivingSignature, 'billReceivedAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )>, revoke?: Maybe<(
            Pick<RevokingSignature, 'agreeToRevokeAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )> } }, payment?: Maybe<(
        Pick<PaymentInIssuedBill, 'id' | 'createdAt'>
        & { paymentData: (
          Pick<PaymentData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), charge?: Maybe<(
          Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
          & { depositAccount: (
            Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
            & { fromContact?: Maybe<(
              Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
              & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
            )> }
          ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
        )>, incomingTransfer: (
          Pick<SharedTransfer, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
          & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
            Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
            & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
          ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>> }
        ) }
      )>, receipt?: Maybe<(
        Pick<ReceiptInIssuedBill, 'id' | 'createdAt'>
        & { receiptData: (
          Pick<ReceiptData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), charge: (
          Pick<Charge, 'id' | 'createdAt' | 'amount' | 'remitterNameKana'>
          & { depositAccount: (
            Pick<DepositAccount, 'id' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>
            & { fromContact?: Maybe<(
              Pick<ContactInDepositAccount, 'id' | 'additionalId' | 'name' | 'isArchived'>
              & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
            )> }
          ), transfer?: Maybe<Pick<TransferInCharge, 'id'>> }
        ) }
      )> }
    )> } };

export type GetReceivedBillQueryVariables = Exact<{
  input: GetReceivedBillInput;
}>;


export type GetReceivedBillQuery = { getReceivedBill: { bill: (
      Pick<ReceivedBill, 'id' | 'createdAt'>
      & { contact: (
        Pick<ContactInReceivedBill, 'id' | 'additionalId' | 'name' | 'isArchived'>
        & { relatedBusiness: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'> }
      ), billData: (
        Pick<BillData, 'customizedId' | 'title' | 'issueDate' | 'paymentDueDate' | 'currencyCode' | 'note'>
        & { issuer: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), recipient: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
          Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
          & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
        )>, summary: (
          Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
          & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
        ) }
      ), signatures: { issuer: { issue: (
            Pick<IssueingSignature, 'issuedAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          ), revoke?: Maybe<(
            Pick<RevokingSignature, 'agreeToRevokeAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )> }, recipient: { receive?: Maybe<(
            Pick<ReceivingSignature, 'billReceivedAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )>, revoke?: Maybe<(
            Pick<RevokingSignature, 'agreeToRevokeAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )> } }, payment?: Maybe<(
        Pick<PaymentInReceivedBill, 'id' | 'createdAt'>
        & { paymentData: (
          Pick<PaymentData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), transfer: (
          Pick<Transfer, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
          & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
            Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
            & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
          ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>>, operatorApp?: Maybe<(
            Pick<App, 'id'>
            & { name: Pick<MultilingualText, 'en' | 'ja'> }
          )> }
        ) }
      )>, receipt?: Maybe<(
        Pick<ReceiptInReceivedBill, 'id' | 'createdAt'>
        & { receiptData: (
          Pick<ReceiptData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), transfer: (
          Pick<Transfer, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
          & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
            Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
            & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
          ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>>, operatorApp?: Maybe<(
            Pick<App, 'id'>
            & { name: Pick<MultilingualText, 'en' | 'ja'> }
          )> }
        ) }
      )> }
    ) } };

export type GetReceivedBillsQueryVariables = Exact<{
  input: GetReceivedBillsInput;
}>;


export type GetReceivedBillsQuery = { getReceivedBills: { bills: Array<(
      Pick<ReceivedBill, 'id' | 'createdAt'>
      & { contact: (
        Pick<ContactInReceivedBill, 'id' | 'additionalId' | 'name' | 'isArchived'>
        & { relatedBusiness: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'> }
      ), billData: (
        Pick<BillData, 'customizedId' | 'title' | 'issueDate' | 'paymentDueDate' | 'currencyCode' | 'note'>
        & { issuer: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), recipient: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
          Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
          & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
        )>, summary: (
          Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
          & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
        ) }
      ), signatures: { issuer: { issue: (
            Pick<IssueingSignature, 'issuedAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          ), revoke?: Maybe<(
            Pick<RevokingSignature, 'agreeToRevokeAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )> }, recipient: { receive?: Maybe<(
            Pick<ReceivingSignature, 'billReceivedAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )>, revoke?: Maybe<(
            Pick<RevokingSignature, 'agreeToRevokeAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )> } }, payment?: Maybe<(
        Pick<PaymentInReceivedBill, 'id' | 'createdAt'>
        & { paymentData: (
          Pick<PaymentData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), transfer: (
          Pick<Transfer, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
          & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
            Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
            & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
          ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>>, operatorApp?: Maybe<(
            Pick<App, 'id'>
            & { name: Pick<MultilingualText, 'en' | 'ja'> }
          )> }
        ) }
      )>, receipt?: Maybe<(
        Pick<ReceiptInReceivedBill, 'id' | 'createdAt'>
        & { receiptData: (
          Pick<ReceiptData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), transfer: (
          Pick<Transfer, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
          & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
            Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
            & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
          ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>>, operatorApp?: Maybe<(
            Pick<App, 'id'>
            & { name: Pick<MultilingualText, 'en' | 'ja'> }
          )> }
        ) }
      )> }
    )> } };

export type GetSharedBillQueryVariables = Exact<{
  input: GetSharedBillInput;
}>;


export type GetSharedBillQuery = { getSharedBill: { invite: (
      Pick<InviteForContact, 'codeText' | 'createdAt' | 'expireAt'>
      & { business: Pick<BusinessInInvite, 'id' | 'name' | 'language' | 'isArchived'>, contact: Pick<ContactInInvite, 'id' | 'additionalId' | 'name'> }
    ), bill: (
      Pick<SharedBill, 'id' | 'createdAt'>
      & { billData: (
        Pick<BillData, 'customizedId' | 'title' | 'issueDate' | 'paymentDueDate' | 'currencyCode' | 'note'>
        & { issuer: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), recipient: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
          Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
          & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
        )>, summary: (
          Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
          & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
        ) }
      ), signatures: { issuer: { issue: (
            Pick<IssueingSignature, 'issuedAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          ), revoke?: Maybe<(
            Pick<RevokingSignature, 'agreeToRevokeAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )> }, recipient: { receive?: Maybe<(
            Pick<ReceivingSignature, 'billReceivedAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )>, revoke?: Maybe<(
            Pick<RevokingSignature, 'agreeToRevokeAt'>
            & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
          )> } }, payment?: Maybe<(
        Pick<PaymentInSharedBill, 'id' | 'createdAt'>
        & { paymentData: (
          Pick<PaymentData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ) }
      )>, receipt?: Maybe<(
        Pick<ReceiptInSharedBill, 'id' | 'createdAt'>
        & { receiptData: (
          Pick<ReceiptData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ) }
      )> }
    ) } };

export type GetSharedPaymentQueryVariables = Exact<{
  input: GetSharedPaymentInput;
}>;


export type GetSharedPaymentQuery = { getSharedPayment: { invite: (
      Pick<InviteForContact, 'codeText' | 'createdAt' | 'expireAt'>
      & { business: Pick<BusinessInInvite, 'id' | 'name' | 'language' | 'isArchived'>, contact: Pick<ContactInInvite, 'id' | 'additionalId' | 'name'> }
    ), payment: (
      Pick<SharedPayment, 'id' | 'createdAt'>
      & { paymentData: (
        Pick<PaymentData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
        & { issuer: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), recipient: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
          Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
          & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
        )>, summary: (
          Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
          & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
        ) }
      ), bill?: Maybe<(
        Pick<BillInPayment, 'id' | 'createdAt'>
        & { billData: (
          Pick<BillData, 'customizedId' | 'title' | 'issueDate' | 'paymentDueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), signatures: { issuer: { issue: (
              Pick<IssueingSignature, 'issuedAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            ), revoke?: Maybe<(
              Pick<RevokingSignature, 'agreeToRevokeAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            )> }, recipient: { revoke?: Maybe<(
              Pick<RevokingSignature, 'agreeToRevokeAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            )> } } }
      )>, transfer: (
        Pick<SharedTransfer, 'id' | 'createdAt' | 'amount' | 'remitterNameKana' | 'issuedAt'>
        & { issuer: Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>, contact: (
          Pick<ContactInTransfer, 'id' | 'additionalId' | 'name' | 'isArchived'>
          & { relatedBusiness?: Maybe<Pick<BusinessInTransfer, 'id' | 'name' | 'language' | 'isArchived'>> }
        ), bankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'>, feeOnIssuer: Pick<Fee, 'priceBeforeTax' | 'taxRate' | 'total'>, error?: Maybe<Pick<TransferError, 'setAt' | 'code' | 'message'>> }
      ) }
    ) } };

export type GetSharedReceiptQueryVariables = Exact<{
  input: GetSharedReceiptInput;
}>;


export type GetSharedReceiptQuery = { getSharedReceipt: { invite: (
      Pick<InviteForContact, 'codeText' | 'createdAt' | 'expireAt'>
      & { business: Pick<BusinessInInvite, 'id' | 'name' | 'language' | 'isArchived'>, contact: Pick<ContactInInvite, 'id' | 'additionalId' | 'name'> }
    ), receipt: (
      Pick<SharedReceipt, 'id' | 'createdAt'>
      & { receiptData: (
        Pick<ReceiptData, 'billCustomizedId' | 'customizedId' | 'title' | 'issueDate' | 'currencyCode' | 'note'>
        & { issuer: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), recipient: (
          Pick<BusinessSnapshot, 'name'>
          & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
        ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
          Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
          & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
        )>, summary: (
          Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
          & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
        ) }
      ), bill?: Maybe<(
        Pick<BillInReceipt, 'id' | 'createdAt'>
        & { billData: (
          Pick<BillData, 'customizedId' | 'title' | 'issueDate' | 'paymentDueDate' | 'currencyCode' | 'note'>
          & { issuer: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), recipient: (
            Pick<BusinessSnapshot, 'name'>
            & { items: Array<Pick<BusinessSnapshotItem, 'title' | 'value'>> }
          ), payee: { uodkaBankAccount: Pick<BankAccountInTransfer, 'isDepositAccount' | 'bankCode' | 'branchCode' | 'accountNumber' | 'accountHolderName'> }, items: Array<(
            Pick<InvoiceDataItem, 'date' | 'title' | 'unitPrice' | 'quantity'>
            & { taxes: Array<Pick<TaxInInvoiceDataItem, 'title' | 'value'>> }
          )>, summary: (
            Pick<InvoiceDataSummary, 'decimalRoundRuleForSubtotal' | 'subtotal' | 'total'>
            & { taxes: Array<Pick<AggregatedTax, 'title' | 'taxableValue' | 'decimalRoundRule' | 'value'>> }
          ) }
        ), signatures: { issuer: { issue: (
              Pick<IssueingSignature, 'issuedAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            ), revoke?: Maybe<(
              Pick<RevokingSignature, 'agreeToRevokeAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            )> }, recipient: { revoke?: Maybe<(
              Pick<RevokingSignature, 'agreeToRevokeAt'>
              & { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> }
            )> } } }
      )> }
    ) } };

export type ServiceHealthQueryVariables = Exact<{ [key: string]: never; }>;


export type ServiceHealthQuery = { serviceHealth: Pick<ServiceHealth, 'code' | 'message' | 'url'> };


export const CreatePaymentDocument = gql`
    mutation createPayment($input: CreatePaymentInput!) {
  createPayment(input: $input) {
    payment {
      id
      createdAt
      paymentData {
        billCustomizedId
        customizedId
        title
        issuer {
          name
          items {
            title
            value
          }
        }
        recipient {
          name
          items {
            title
            value
          }
        }
        payee {
          uodkaBankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
        }
        issueDate
        currencyCode
        items {
          date
          title
          unitPrice
          quantity
          taxes {
            title
            value
          }
        }
        summary {
          decimalRoundRuleForSubtotal
          subtotal
          taxes {
            title
            taxableValue
            decimalRoundRule
            value
          }
          total
        }
        note
      }
      bill {
        id
        createdAt
        billData {
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          paymentDueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        signatures {
          issuer {
            issue {
              issuedAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
            revoke {
              agreeToRevokeAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
          }
          recipient {
            receive {
              billReceivedAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
            revoke {
              agreeToRevokeAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
          }
        }
      }
      transfer {
        id
        createdAt
        issuer {
          id
          name
          language
          isArchived
        }
        contact {
          id
          additionalId
          name
          relatedBusiness {
            id
            name
            language
            isArchived
          }
          isArchived
        }
        bankAccount {
          isDepositAccount
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        amount
        feeOnIssuer {
          priceBeforeTax
          taxRate
          total
        }
        remitterNameKana
        error {
          setAt
          code
          message
        }
        issuedAt
        operatorApp {
          id
          name {
            en
            ja
          }
        }
      }
    }
  }
}
    `;
export const CreatePaymentForBillDocument = gql`
    mutation createPaymentForBill($input: CreatePaymentForBillInput!) {
  createPaymentForBill(input: $input) {
    payment {
      id
      createdAt
      paymentData {
        billCustomizedId
        customizedId
        title
        issuer {
          name
          items {
            title
            value
          }
        }
        recipient {
          name
          items {
            title
            value
          }
        }
        payee {
          uodkaBankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
        }
        issueDate
        currencyCode
        items {
          date
          title
          unitPrice
          quantity
          taxes {
            title
            value
          }
        }
        summary {
          decimalRoundRuleForSubtotal
          subtotal
          taxes {
            title
            taxableValue
            decimalRoundRule
            value
          }
          total
        }
        note
      }
      bill {
        id
        createdAt
        billData {
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          paymentDueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        signatures {
          issuer {
            issue {
              issuedAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
            revoke {
              agreeToRevokeAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
          }
          recipient {
            receive {
              billReceivedAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
            revoke {
              agreeToRevokeAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
          }
        }
      }
      transfer {
        id
        createdAt
        issuer {
          id
          name
          language
          isArchived
        }
        contact {
          id
          additionalId
          name
          relatedBusiness {
            id
            name
            language
            isArchived
          }
          isArchived
        }
        bankAccount {
          isDepositAccount
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        amount
        feeOnIssuer {
          priceBeforeTax
          taxRate
          total
        }
        remitterNameKana
        error {
          setAt
          code
          message
        }
        issuedAt
        operatorApp {
          id
          name {
            en
            ja
          }
        }
      }
    }
  }
}
    `;
export const CreateReceiptDocument = gql`
    mutation createReceipt($input: CreateReceiptInput!) {
  createReceipt(input: $input) {
    receipt {
      id
      createdAt
      receiptData {
        billCustomizedId
        customizedId
        title
        issuer {
          name
          items {
            title
            value
          }
        }
        recipient {
          name
          items {
            title
            value
          }
        }
        payee {
          uodkaBankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
        }
        issueDate
        currencyCode
        items {
          date
          title
          unitPrice
          quantity
          taxes {
            title
            value
          }
        }
        summary {
          decimalRoundRuleForSubtotal
          subtotal
          taxes {
            title
            taxableValue
            decimalRoundRule
            value
          }
          total
        }
        note
      }
      bill {
        id
        createdAt
        billData {
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          paymentDueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        signatures {
          issuer {
            issue {
              issuedAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
            revoke {
              agreeToRevokeAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
          }
          recipient {
            receive {
              billReceivedAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
            revoke {
              agreeToRevokeAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
          }
        }
      }
      charge {
        id
        createdAt
        amount
        remitterNameKana
        depositAccount {
          id
          bankCode
          branchCode
          accountNumber
          accountHolderName
          fromContact {
            id
            additionalId
            name
            relatedBusiness {
              id
              name
              language
              isArchived
            }
            isArchived
          }
        }
        transfer {
          id
        }
      }
    }
  }
}
    `;
export const CreateReceiptForBillDocument = gql`
    mutation createReceiptForBill($input: CreateReceiptForBillInput!) {
  createReceiptForBill(input: $input) {
    receipt {
      id
      createdAt
      receiptData {
        billCustomizedId
        customizedId
        title
        issuer {
          name
          items {
            title
            value
          }
        }
        recipient {
          name
          items {
            title
            value
          }
        }
        payee {
          uodkaBankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
        }
        issueDate
        currencyCode
        items {
          date
          title
          unitPrice
          quantity
          taxes {
            title
            value
          }
        }
        summary {
          decimalRoundRuleForSubtotal
          subtotal
          taxes {
            title
            taxableValue
            decimalRoundRule
            value
          }
          total
        }
        note
      }
      bill {
        id
        createdAt
        billData {
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          paymentDueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        signatures {
          issuer {
            issue {
              issuedAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
            revoke {
              agreeToRevokeAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
          }
          recipient {
            receive {
              billReceivedAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
            revoke {
              agreeToRevokeAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
          }
        }
      }
      charge {
        id
        createdAt
        amount
        remitterNameKana
        depositAccount {
          id
          bankCode
          branchCode
          accountNumber
          accountHolderName
          fromContact {
            id
            additionalId
            name
            relatedBusiness {
              id
              name
              language
              isArchived
            }
            isArchived
          }
        }
        transfer {
          id
        }
      }
    }
  }
}
    `;
export const ImportIssuedBillDocument = gql`
    mutation importIssuedBill($input: ImportIssuedBillInput!) {
  importIssuedBill(input: $input) {
    issuedBill {
      id
      createdAt
      contact {
        id
        additionalId
        name
        relatedBusiness {
          id
          name
          language
          isArchived
        }
        isArchived
      }
      billData {
        customizedId
        title
        issuer {
          name
          items {
            title
            value
          }
        }
        recipient {
          name
          items {
            title
            value
          }
        }
        payee {
          uodkaBankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
        }
        issueDate
        paymentDueDate
        currencyCode
        items {
          date
          title
          unitPrice
          quantity
          taxes {
            title
            value
          }
        }
        summary {
          decimalRoundRuleForSubtotal
          subtotal
          taxes {
            title
            taxableValue
            decimalRoundRule
            value
          }
          total
        }
        note
      }
      signatures {
        issuer {
          issue {
            issuedAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
          revoke {
            agreeToRevokeAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
        }
        recipient {
          receive {
            billReceivedAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
          revoke {
            agreeToRevokeAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
        }
      }
      payment {
        id
        createdAt
        paymentData {
          billCustomizedId
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        charge {
          id
          createdAt
          amount
          remitterNameKana
          depositAccount {
            id
            bankCode
            branchCode
            accountNumber
            accountHolderName
            fromContact {
              id
              additionalId
              name
              relatedBusiness {
                id
                name
                language
                isArchived
              }
              isArchived
            }
          }
          transfer {
            id
          }
        }
        incomingTransfer {
          id
          createdAt
          issuer {
            id
            name
            language
            isArchived
          }
          contact {
            id
            additionalId
            name
            relatedBusiness {
              id
              name
              language
              isArchived
            }
            isArchived
          }
          bankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
          amount
          feeOnIssuer {
            priceBeforeTax
            taxRate
            total
          }
          remitterNameKana
          error {
            setAt
            code
            message
          }
          issuedAt
        }
      }
      receipt {
        id
        createdAt
        receiptData {
          billCustomizedId
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        charge {
          id
          createdAt
          amount
          remitterNameKana
          depositAccount {
            id
            bankCode
            branchCode
            accountNumber
            accountHolderName
            fromContact {
              id
              additionalId
              name
              relatedBusiness {
                id
                name
                language
                isArchived
              }
              isArchived
            }
          }
          transfer {
            id
          }
        }
      }
    }
  }
}
    `;
export const ImportSignaturesToIssuedBillDocument = gql`
    mutation importSignaturesToIssuedBill($input: ImportSignaturesToIssuedBillInput!) {
  importSignaturesToIssuedBill(input: $input) {
    result
    issuedBill {
      id
      createdAt
      contact {
        id
        additionalId
        name
        relatedBusiness {
          id
          name
          language
          isArchived
        }
        isArchived
      }
      billData {
        customizedId
        title
        issuer {
          name
          items {
            title
            value
          }
        }
        recipient {
          name
          items {
            title
            value
          }
        }
        payee {
          uodkaBankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
        }
        issueDate
        paymentDueDate
        currencyCode
        items {
          date
          title
          unitPrice
          quantity
          taxes {
            title
            value
          }
        }
        summary {
          decimalRoundRuleForSubtotal
          subtotal
          taxes {
            title
            taxableValue
            decimalRoundRule
            value
          }
          total
        }
        note
      }
      signatures {
        issuer {
          issue {
            issuedAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
          revoke {
            agreeToRevokeAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
        }
        recipient {
          receive {
            billReceivedAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
          revoke {
            agreeToRevokeAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
        }
      }
      payment {
        id
        createdAt
        paymentData {
          billCustomizedId
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        charge {
          id
          createdAt
          amount
          remitterNameKana
          depositAccount {
            id
            bankCode
            branchCode
            accountNumber
            accountHolderName
            fromContact {
              id
              additionalId
              name
              relatedBusiness {
                id
                name
                language
                isArchived
              }
              isArchived
            }
          }
          transfer {
            id
          }
        }
        incomingTransfer {
          id
          createdAt
          issuer {
            id
            name
            language
            isArchived
          }
          contact {
            id
            additionalId
            name
            relatedBusiness {
              id
              name
              language
              isArchived
            }
            isArchived
          }
          bankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
          amount
          feeOnIssuer {
            priceBeforeTax
            taxRate
            total
          }
          remitterNameKana
          error {
            setAt
            code
            message
          }
          issuedAt
        }
      }
      receipt {
        id
        createdAt
        receiptData {
          billCustomizedId
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        charge {
          id
          createdAt
          amount
          remitterNameKana
          depositAccount {
            id
            bankCode
            branchCode
            accountNumber
            accountHolderName
            fromContact {
              id
              additionalId
              name
              relatedBusiness {
                id
                name
                language
                isArchived
              }
              isArchived
            }
          }
          transfer {
            id
          }
        }
      }
    }
  }
}
    `;
export const ImportSignaturesToReceivedBillDocument = gql`
    mutation importSignaturesToReceivedBill($input: ImportSignaturesToReceivedBillInput!) {
  importSignaturesToReceivedBill(input: $input) {
    result
    receivedBill {
      id
      createdAt
      contact {
        id
        additionalId
        name
        relatedBusiness {
          id
          name
          language
          isArchived
        }
        isArchived
      }
      billData {
        customizedId
        title
        issuer {
          name
          items {
            title
            value
          }
        }
        recipient {
          name
          items {
            title
            value
          }
        }
        payee {
          uodkaBankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
        }
        issueDate
        paymentDueDate
        currencyCode
        items {
          date
          title
          unitPrice
          quantity
          taxes {
            title
            value
          }
        }
        summary {
          decimalRoundRuleForSubtotal
          subtotal
          taxes {
            title
            taxableValue
            decimalRoundRule
            value
          }
          total
        }
        note
      }
      signatures {
        issuer {
          issue {
            issuedAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
          revoke {
            agreeToRevokeAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
        }
        recipient {
          receive {
            billReceivedAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
          revoke {
            agreeToRevokeAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
        }
      }
      payment {
        id
        createdAt
        paymentData {
          billCustomizedId
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        transfer {
          id
          createdAt
          issuer {
            id
            name
            language
            isArchived
          }
          contact {
            id
            additionalId
            name
            relatedBusiness {
              id
              name
              language
              isArchived
            }
            isArchived
          }
          bankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
          amount
          feeOnIssuer {
            priceBeforeTax
            taxRate
            total
          }
          remitterNameKana
          error {
            setAt
            code
            message
          }
          issuedAt
          operatorApp {
            id
            name {
              en
              ja
            }
          }
        }
      }
      receipt {
        id
        createdAt
        receiptData {
          billCustomizedId
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        transfer {
          id
          createdAt
          issuer {
            id
            name
            language
            isArchived
          }
          contact {
            id
            additionalId
            name
            relatedBusiness {
              id
              name
              language
              isArchived
            }
            isArchived
          }
          bankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
          amount
          feeOnIssuer {
            priceBeforeTax
            taxRate
            total
          }
          remitterNameKana
          error {
            setAt
            code
            message
          }
          issuedAt
          operatorApp {
            id
            name {
              en
              ja
            }
          }
        }
      }
    }
  }
}
    `;
export const GetBalanceWithInvoicesDocument = gql`
    query getBalanceWithInvoices($input: GetBalanceWithInvoicesInput!) {
  getBalanceWithInvoices(input: $input) {
    balances {
      balance {
        id
        createdAt
        currentAmount
        currentReserve
        amountIncrease
        reserveIncrease
        eventType
        transfer {
          id
          createdAt
          contact {
            id
            additionalId
            name
            relatedBusiness {
              id
              name
              language
              isArchived
            }
            isArchived
          }
          bankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
          amount
          feeOnIssuer {
            priceBeforeTax
            taxRate
            total
          }
          remitterNameKana
          error {
            setAt
            code
            message
          }
          issuedAt
          operatorApp {
            id
            name {
              en
              ja
            }
          }
        }
        charge {
          id
          createdAt
          amount
          remitterNameKana
          depositAccount {
            id
            bankCode
            branchCode
            accountNumber
            accountHolderName
            fromContact {
              id
              additionalId
              name
              relatedBusiness {
                id
                name
                language
                isArchived
              }
              isArchived
            }
          }
          transfer {
            id
          }
        }
        appDeposit {
          id
          app {
            id
            name {
              en
              ja
            }
          }
          amount
        }
        appWithdraw {
          id
          app {
            id
            name {
              en
              ja
            }
          }
          amount
          canceledAt
        }
        appReserve {
          id
          app {
            id
            name {
              en
              ja
            }
          }
          amount
          resolvedAt
        }
      }
      issuedPayment {
        id
        createdAt
        paymentData {
          billCustomizedId
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        bill {
          id
          createdAt
          billData {
            customizedId
            title
            issuer {
              name
              items {
                title
                value
              }
            }
            recipient {
              name
              items {
                title
                value
              }
            }
            payee {
              uodkaBankAccount {
                isDepositAccount
                bankCode
                branchCode
                accountNumber
                accountHolderName
              }
            }
            issueDate
            paymentDueDate
            currencyCode
            items {
              date
              title
              unitPrice
              quantity
              taxes {
                title
                value
              }
            }
            summary {
              decimalRoundRuleForSubtotal
              subtotal
              taxes {
                title
                taxableValue
                decimalRoundRule
                value
              }
              total
            }
            note
          }
          signatures {
            issuer {
              issue {
                issuedAt
                signature {
                  resourceId
                  id
                  signerId
                  contactId
                  text
                  unixTime
                }
              }
              revoke {
                agreeToRevokeAt
                signature {
                  resourceId
                  id
                  signerId
                  contactId
                  text
                  unixTime
                }
              }
            }
            recipient {
              receive {
                billReceivedAt
                signature {
                  resourceId
                  id
                  signerId
                  contactId
                  text
                  unixTime
                }
              }
              revoke {
                agreeToRevokeAt
                signature {
                  resourceId
                  id
                  signerId
                  contactId
                  text
                  unixTime
                }
              }
            }
          }
        }
        transfer {
          id
          createdAt
          issuer {
            id
            name
            language
            isArchived
          }
          contact {
            id
            additionalId
            name
            relatedBusiness {
              id
              name
              language
              isArchived
            }
            isArchived
          }
          bankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
          amount
          feeOnIssuer {
            priceBeforeTax
            taxRate
            total
          }
          remitterNameKana
          error {
            setAt
            code
            message
          }
          issuedAt
          operatorApp {
            id
            name {
              en
              ja
            }
          }
        }
      }
      issuedReceipt {
        id
        createdAt
        receiptData {
          billCustomizedId
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        bill {
          id
          createdAt
          billData {
            customizedId
            title
            issuer {
              name
              items {
                title
                value
              }
            }
            recipient {
              name
              items {
                title
                value
              }
            }
            payee {
              uodkaBankAccount {
                isDepositAccount
                bankCode
                branchCode
                accountNumber
                accountHolderName
              }
            }
            issueDate
            paymentDueDate
            currencyCode
            items {
              date
              title
              unitPrice
              quantity
              taxes {
                title
                value
              }
            }
            summary {
              decimalRoundRuleForSubtotal
              subtotal
              taxes {
                title
                taxableValue
                decimalRoundRule
                value
              }
              total
            }
            note
          }
          signatures {
            issuer {
              issue {
                issuedAt
                signature {
                  resourceId
                  id
                  signerId
                  contactId
                  text
                  unixTime
                }
              }
              revoke {
                agreeToRevokeAt
                signature {
                  resourceId
                  id
                  signerId
                  contactId
                  text
                  unixTime
                }
              }
            }
            recipient {
              receive {
                billReceivedAt
                signature {
                  resourceId
                  id
                  signerId
                  contactId
                  text
                  unixTime
                }
              }
              revoke {
                agreeToRevokeAt
                signature {
                  resourceId
                  id
                  signerId
                  contactId
                  text
                  unixTime
                }
              }
            }
          }
        }
        charge {
          id
          createdAt
          amount
          remitterNameKana
          depositAccount {
            id
            bankCode
            branchCode
            accountNumber
            accountHolderName
            fromContact {
              id
              additionalId
              name
              relatedBusiness {
                id
                name
                language
                isArchived
              }
              isArchived
            }
          }
          transfer {
            id
          }
        }
      }
      receivedPayment {
        id
        createdAt
        paymentData {
          billCustomizedId
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        bill {
          id
          createdAt
          billData {
            customizedId
            title
            issuer {
              name
              items {
                title
                value
              }
            }
            recipient {
              name
              items {
                title
                value
              }
            }
            payee {
              uodkaBankAccount {
                isDepositAccount
                bankCode
                branchCode
                accountNumber
                accountHolderName
              }
            }
            issueDate
            paymentDueDate
            currencyCode
            items {
              date
              title
              unitPrice
              quantity
              taxes {
                title
                value
              }
            }
            summary {
              decimalRoundRuleForSubtotal
              subtotal
              taxes {
                title
                taxableValue
                decimalRoundRule
                value
              }
              total
            }
            note
          }
          signatures {
            issuer {
              issue {
                issuedAt
                signature {
                  resourceId
                  id
                  signerId
                  contactId
                  text
                  unixTime
                }
              }
              revoke {
                agreeToRevokeAt
                signature {
                  resourceId
                  id
                  signerId
                  contactId
                  text
                  unixTime
                }
              }
            }
            recipient {
              receive {
                billReceivedAt
                signature {
                  resourceId
                  id
                  signerId
                  contactId
                  text
                  unixTime
                }
              }
              revoke {
                agreeToRevokeAt
                signature {
                  resourceId
                  id
                  signerId
                  contactId
                  text
                  unixTime
                }
              }
            }
          }
        }
        charge {
          id
          createdAt
          amount
          remitterNameKana
          depositAccount {
            id
            bankCode
            branchCode
            accountNumber
            accountHolderName
            fromContact {
              id
              additionalId
              name
              relatedBusiness {
                id
                name
                language
                isArchived
              }
              isArchived
            }
          }
          transfer {
            id
          }
        }
        incomingTransfer {
          id
          createdAt
          issuer {
            id
            name
            language
            isArchived
          }
          contact {
            id
            additionalId
            name
            relatedBusiness {
              id
              name
              language
              isArchived
            }
            isArchived
          }
          bankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
          amount
          feeOnIssuer {
            priceBeforeTax
            taxRate
            total
          }
          remitterNameKana
          error {
            setAt
            code
            message
          }
          issuedAt
        }
      }
      receivedReceipt {
        id
        createdAt
        receiptData {
          billCustomizedId
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        bill {
          id
          createdAt
          billData {
            customizedId
            title
            issuer {
              name
              items {
                title
                value
              }
            }
            recipient {
              name
              items {
                title
                value
              }
            }
            payee {
              uodkaBankAccount {
                isDepositAccount
                bankCode
                branchCode
                accountNumber
                accountHolderName
              }
            }
            issueDate
            paymentDueDate
            currencyCode
            items {
              date
              title
              unitPrice
              quantity
              taxes {
                title
                value
              }
            }
            summary {
              decimalRoundRuleForSubtotal
              subtotal
              taxes {
                title
                taxableValue
                decimalRoundRule
                value
              }
              total
            }
            note
          }
          signatures {
            issuer {
              issue {
                issuedAt
                signature {
                  resourceId
                  id
                  signerId
                  contactId
                  text
                  unixTime
                }
              }
              revoke {
                agreeToRevokeAt
                signature {
                  resourceId
                  id
                  signerId
                  contactId
                  text
                  unixTime
                }
              }
            }
            recipient {
              receive {
                billReceivedAt
                signature {
                  resourceId
                  id
                  signerId
                  contactId
                  text
                  unixTime
                }
              }
              revoke {
                agreeToRevokeAt
                signature {
                  resourceId
                  id
                  signerId
                  contactId
                  text
                  unixTime
                }
              }
            }
          }
        }
        transfer {
          id
          createdAt
          issuer {
            id
            name
            language
            isArchived
          }
          contact {
            id
            additionalId
            name
            relatedBusiness {
              id
              name
              language
              isArchived
            }
            isArchived
          }
          bankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
          amount
          feeOnIssuer {
            priceBeforeTax
            taxRate
            total
          }
          remitterNameKana
          error {
            setAt
            code
            message
          }
          issuedAt
          operatorApp {
            id
            name {
              en
              ja
            }
          }
        }
      }
    }
  }
}
    `;
export const GetIssuedBillDocument = gql`
    query getIssuedBill($input: GetIssuedBillInput!) {
  getIssuedBill(input: $input) {
    bill {
      id
      createdAt
      contact {
        id
        additionalId
        name
        relatedBusiness {
          id
          name
          language
          isArchived
        }
        isArchived
      }
      billData {
        customizedId
        title
        issuer {
          name
          items {
            title
            value
          }
        }
        recipient {
          name
          items {
            title
            value
          }
        }
        payee {
          uodkaBankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
        }
        issueDate
        paymentDueDate
        currencyCode
        items {
          date
          title
          unitPrice
          quantity
          taxes {
            title
            value
          }
        }
        summary {
          decimalRoundRuleForSubtotal
          subtotal
          taxes {
            title
            taxableValue
            decimalRoundRule
            value
          }
          total
        }
        note
      }
      signatures {
        issuer {
          issue {
            issuedAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
          revoke {
            agreeToRevokeAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
        }
        recipient {
          receive {
            billReceivedAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
          revoke {
            agreeToRevokeAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
        }
      }
      payment {
        id
        createdAt
        paymentData {
          billCustomizedId
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        charge {
          id
          createdAt
          amount
          remitterNameKana
          depositAccount {
            id
            bankCode
            branchCode
            accountNumber
            accountHolderName
            fromContact {
              id
              additionalId
              name
              relatedBusiness {
                id
                name
                language
                isArchived
              }
              isArchived
            }
          }
          transfer {
            id
          }
        }
        incomingTransfer {
          id
          createdAt
          issuer {
            id
            name
            language
            isArchived
          }
          contact {
            id
            additionalId
            name
            relatedBusiness {
              id
              name
              language
              isArchived
            }
            isArchived
          }
          bankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
          amount
          feeOnIssuer {
            priceBeforeTax
            taxRate
            total
          }
          remitterNameKana
          error {
            setAt
            code
            message
          }
          issuedAt
        }
      }
      receipt {
        id
        createdAt
        receiptData {
          billCustomizedId
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        charge {
          id
          createdAt
          amount
          remitterNameKana
          depositAccount {
            id
            bankCode
            branchCode
            accountNumber
            accountHolderName
            fromContact {
              id
              additionalId
              name
              relatedBusiness {
                id
                name
                language
                isArchived
              }
              isArchived
            }
          }
          transfer {
            id
          }
        }
      }
    }
  }
}
    `;
export const GetIssuedBillsDocument = gql`
    query getIssuedBills($input: GetIssuedBillsInput!) {
  getIssuedBills(input: $input) {
    bills {
      id
      createdAt
      contact {
        id
        additionalId
        name
        relatedBusiness {
          id
          name
          language
          isArchived
        }
        isArchived
      }
      billData {
        customizedId
        title
        issuer {
          name
          items {
            title
            value
          }
        }
        recipient {
          name
          items {
            title
            value
          }
        }
        payee {
          uodkaBankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
        }
        issueDate
        paymentDueDate
        currencyCode
        items {
          date
          title
          unitPrice
          quantity
          taxes {
            title
            value
          }
        }
        summary {
          decimalRoundRuleForSubtotal
          subtotal
          taxes {
            title
            taxableValue
            decimalRoundRule
            value
          }
          total
        }
        note
      }
      signatures {
        issuer {
          issue {
            issuedAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
          revoke {
            agreeToRevokeAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
        }
        recipient {
          receive {
            billReceivedAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
          revoke {
            agreeToRevokeAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
        }
      }
      payment {
        id
        createdAt
        paymentData {
          billCustomizedId
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        charge {
          id
          createdAt
          amount
          remitterNameKana
          depositAccount {
            id
            bankCode
            branchCode
            accountNumber
            accountHolderName
            fromContact {
              id
              additionalId
              name
              relatedBusiness {
                id
                name
                language
                isArchived
              }
              isArchived
            }
          }
          transfer {
            id
          }
        }
        incomingTransfer {
          id
          createdAt
          issuer {
            id
            name
            language
            isArchived
          }
          contact {
            id
            additionalId
            name
            relatedBusiness {
              id
              name
              language
              isArchived
            }
            isArchived
          }
          bankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
          amount
          feeOnIssuer {
            priceBeforeTax
            taxRate
            total
          }
          remitterNameKana
          error {
            setAt
            code
            message
          }
          issuedAt
        }
      }
      receipt {
        id
        createdAt
        receiptData {
          billCustomizedId
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        charge {
          id
          createdAt
          amount
          remitterNameKana
          depositAccount {
            id
            bankCode
            branchCode
            accountNumber
            accountHolderName
            fromContact {
              id
              additionalId
              name
              relatedBusiness {
                id
                name
                language
                isArchived
              }
              isArchived
            }
          }
          transfer {
            id
          }
        }
      }
    }
  }
}
    `;
export const GetReceivedBillDocument = gql`
    query getReceivedBill($input: GetReceivedBillInput!) {
  getReceivedBill(input: $input) {
    bill {
      id
      createdAt
      contact {
        id
        additionalId
        name
        relatedBusiness {
          id
          name
          language
          isArchived
        }
        isArchived
      }
      billData {
        customizedId
        title
        issuer {
          name
          items {
            title
            value
          }
        }
        recipient {
          name
          items {
            title
            value
          }
        }
        payee {
          uodkaBankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
        }
        issueDate
        paymentDueDate
        currencyCode
        items {
          date
          title
          unitPrice
          quantity
          taxes {
            title
            value
          }
        }
        summary {
          decimalRoundRuleForSubtotal
          subtotal
          taxes {
            title
            taxableValue
            decimalRoundRule
            value
          }
          total
        }
        note
      }
      signatures {
        issuer {
          issue {
            issuedAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
          revoke {
            agreeToRevokeAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
        }
        recipient {
          receive {
            billReceivedAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
          revoke {
            agreeToRevokeAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
        }
      }
      payment {
        id
        createdAt
        paymentData {
          billCustomizedId
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        transfer {
          id
          createdAt
          issuer {
            id
            name
            language
            isArchived
          }
          contact {
            id
            additionalId
            name
            relatedBusiness {
              id
              name
              language
              isArchived
            }
            isArchived
          }
          bankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
          amount
          feeOnIssuer {
            priceBeforeTax
            taxRate
            total
          }
          remitterNameKana
          error {
            setAt
            code
            message
          }
          issuedAt
          operatorApp {
            id
            name {
              en
              ja
            }
          }
        }
      }
      receipt {
        id
        createdAt
        receiptData {
          billCustomizedId
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        transfer {
          id
          createdAt
          issuer {
            id
            name
            language
            isArchived
          }
          contact {
            id
            additionalId
            name
            relatedBusiness {
              id
              name
              language
              isArchived
            }
            isArchived
          }
          bankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
          amount
          feeOnIssuer {
            priceBeforeTax
            taxRate
            total
          }
          remitterNameKana
          error {
            setAt
            code
            message
          }
          issuedAt
          operatorApp {
            id
            name {
              en
              ja
            }
          }
        }
      }
    }
  }
}
    `;
export const GetReceivedBillsDocument = gql`
    query getReceivedBills($input: GetReceivedBillsInput!) {
  getReceivedBills(input: $input) {
    bills {
      id
      createdAt
      contact {
        id
        additionalId
        name
        relatedBusiness {
          id
          name
          language
          isArchived
        }
        isArchived
      }
      billData {
        customizedId
        title
        issuer {
          name
          items {
            title
            value
          }
        }
        recipient {
          name
          items {
            title
            value
          }
        }
        payee {
          uodkaBankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
        }
        issueDate
        paymentDueDate
        currencyCode
        items {
          date
          title
          unitPrice
          quantity
          taxes {
            title
            value
          }
        }
        summary {
          decimalRoundRuleForSubtotal
          subtotal
          taxes {
            title
            taxableValue
            decimalRoundRule
            value
          }
          total
        }
        note
      }
      signatures {
        issuer {
          issue {
            issuedAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
          revoke {
            agreeToRevokeAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
        }
        recipient {
          receive {
            billReceivedAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
          revoke {
            agreeToRevokeAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
        }
      }
      payment {
        id
        createdAt
        paymentData {
          billCustomizedId
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        transfer {
          id
          createdAt
          issuer {
            id
            name
            language
            isArchived
          }
          contact {
            id
            additionalId
            name
            relatedBusiness {
              id
              name
              language
              isArchived
            }
            isArchived
          }
          bankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
          amount
          feeOnIssuer {
            priceBeforeTax
            taxRate
            total
          }
          remitterNameKana
          error {
            setAt
            code
            message
          }
          issuedAt
          operatorApp {
            id
            name {
              en
              ja
            }
          }
        }
      }
      receipt {
        id
        createdAt
        receiptData {
          billCustomizedId
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        transfer {
          id
          createdAt
          issuer {
            id
            name
            language
            isArchived
          }
          contact {
            id
            additionalId
            name
            relatedBusiness {
              id
              name
              language
              isArchived
            }
            isArchived
          }
          bankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
          amount
          feeOnIssuer {
            priceBeforeTax
            taxRate
            total
          }
          remitterNameKana
          error {
            setAt
            code
            message
          }
          issuedAt
          operatorApp {
            id
            name {
              en
              ja
            }
          }
        }
      }
    }
  }
}
    `;
export const GetSharedBillDocument = gql`
    query getSharedBill($input: GetSharedBillInput!) {
  getSharedBill(input: $input) {
    invite {
      codeText
      createdAt
      expireAt
      business {
        id
        name
        language
        isArchived
      }
      contact {
        id
        additionalId
        name
      }
    }
    bill {
      id
      createdAt
      billData {
        customizedId
        title
        issuer {
          name
          items {
            title
            value
          }
        }
        recipient {
          name
          items {
            title
            value
          }
        }
        payee {
          uodkaBankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
        }
        issueDate
        paymentDueDate
        currencyCode
        items {
          date
          title
          unitPrice
          quantity
          taxes {
            title
            value
          }
        }
        summary {
          decimalRoundRuleForSubtotal
          subtotal
          taxes {
            title
            taxableValue
            decimalRoundRule
            value
          }
          total
        }
        note
      }
      signatures {
        issuer {
          issue {
            issuedAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
          revoke {
            agreeToRevokeAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
        }
        recipient {
          receive {
            billReceivedAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
          revoke {
            agreeToRevokeAt
            signature {
              resourceId
              id
              signerId
              contactId
              text
              unixTime
            }
          }
        }
      }
      payment {
        id
        createdAt
        paymentData {
          billCustomizedId
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
      }
      receipt {
        id
        createdAt
        receiptData {
          billCustomizedId
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
      }
    }
  }
}
    `;
export const GetSharedPaymentDocument = gql`
    query getSharedPayment($input: GetSharedPaymentInput!) {
  getSharedPayment(input: $input) {
    invite {
      codeText
      createdAt
      expireAt
      business {
        id
        name
        language
        isArchived
      }
      contact {
        id
        additionalId
        name
      }
    }
    payment {
      id
      createdAt
      paymentData {
        billCustomizedId
        customizedId
        title
        issuer {
          name
          items {
            title
            value
          }
        }
        recipient {
          name
          items {
            title
            value
          }
        }
        payee {
          uodkaBankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
        }
        issueDate
        currencyCode
        items {
          date
          title
          unitPrice
          quantity
          taxes {
            title
            value
          }
        }
        summary {
          decimalRoundRuleForSubtotal
          subtotal
          taxes {
            title
            taxableValue
            decimalRoundRule
            value
          }
          total
        }
        note
      }
      bill {
        id
        createdAt
        billData {
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          paymentDueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        signatures {
          issuer {
            issue {
              issuedAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
            revoke {
              agreeToRevokeAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
          }
          recipient {
            revoke {
              agreeToRevokeAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
          }
        }
      }
      transfer {
        id
        createdAt
        issuer {
          id
          name
          language
          isArchived
        }
        contact {
          id
          additionalId
          name
          relatedBusiness {
            id
            name
            language
            isArchived
          }
          isArchived
        }
        bankAccount {
          isDepositAccount
          bankCode
          branchCode
          accountNumber
          accountHolderName
        }
        amount
        feeOnIssuer {
          priceBeforeTax
          taxRate
          total
        }
        remitterNameKana
        error {
          setAt
          code
          message
        }
        issuedAt
      }
    }
  }
}
    `;
export const GetSharedReceiptDocument = gql`
    query getSharedReceipt($input: GetSharedReceiptInput!) {
  getSharedReceipt(input: $input) {
    invite {
      codeText
      createdAt
      expireAt
      business {
        id
        name
        language
        isArchived
      }
      contact {
        id
        additionalId
        name
      }
    }
    receipt {
      id
      createdAt
      receiptData {
        billCustomizedId
        customizedId
        title
        issuer {
          name
          items {
            title
            value
          }
        }
        recipient {
          name
          items {
            title
            value
          }
        }
        payee {
          uodkaBankAccount {
            isDepositAccount
            bankCode
            branchCode
            accountNumber
            accountHolderName
          }
        }
        issueDate
        currencyCode
        items {
          date
          title
          unitPrice
          quantity
          taxes {
            title
            value
          }
        }
        summary {
          decimalRoundRuleForSubtotal
          subtotal
          taxes {
            title
            taxableValue
            decimalRoundRule
            value
          }
          total
        }
        note
      }
      bill {
        id
        createdAt
        billData {
          customizedId
          title
          issuer {
            name
            items {
              title
              value
            }
          }
          recipient {
            name
            items {
              title
              value
            }
          }
          payee {
            uodkaBankAccount {
              isDepositAccount
              bankCode
              branchCode
              accountNumber
              accountHolderName
            }
          }
          issueDate
          paymentDueDate
          currencyCode
          items {
            date
            title
            unitPrice
            quantity
            taxes {
              title
              value
            }
          }
          summary {
            decimalRoundRuleForSubtotal
            subtotal
            taxes {
              title
              taxableValue
              decimalRoundRule
              value
            }
            total
          }
          note
        }
        signatures {
          issuer {
            issue {
              issuedAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
            revoke {
              agreeToRevokeAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
          }
          recipient {
            revoke {
              agreeToRevokeAt
              signature {
                resourceId
                id
                signerId
                contactId
                text
                unixTime
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const ServiceHealthDocument = gql`
    query serviceHealth {
  serviceHealth {
    code
    message
    url
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    createPayment(variables: CreatePaymentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreatePaymentMutation> {
      return withWrapper(() => client.request<CreatePaymentMutation>(CreatePaymentDocument, variables, requestHeaders));
    },
    createPaymentForBill(variables: CreatePaymentForBillMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreatePaymentForBillMutation> {
      return withWrapper(() => client.request<CreatePaymentForBillMutation>(CreatePaymentForBillDocument, variables, requestHeaders));
    },
    createReceipt(variables: CreateReceiptMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateReceiptMutation> {
      return withWrapper(() => client.request<CreateReceiptMutation>(CreateReceiptDocument, variables, requestHeaders));
    },
    createReceiptForBill(variables: CreateReceiptForBillMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateReceiptForBillMutation> {
      return withWrapper(() => client.request<CreateReceiptForBillMutation>(CreateReceiptForBillDocument, variables, requestHeaders));
    },
    importIssuedBill(variables: ImportIssuedBillMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ImportIssuedBillMutation> {
      return withWrapper(() => client.request<ImportIssuedBillMutation>(ImportIssuedBillDocument, variables, requestHeaders));
    },
    importSignaturesToIssuedBill(variables: ImportSignaturesToIssuedBillMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ImportSignaturesToIssuedBillMutation> {
      return withWrapper(() => client.request<ImportSignaturesToIssuedBillMutation>(ImportSignaturesToIssuedBillDocument, variables, requestHeaders));
    },
    importSignaturesToReceivedBill(variables: ImportSignaturesToReceivedBillMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ImportSignaturesToReceivedBillMutation> {
      return withWrapper(() => client.request<ImportSignaturesToReceivedBillMutation>(ImportSignaturesToReceivedBillDocument, variables, requestHeaders));
    },
    getBalanceWithInvoices(variables: GetBalanceWithInvoicesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetBalanceWithInvoicesQuery> {
      return withWrapper(() => client.request<GetBalanceWithInvoicesQuery>(GetBalanceWithInvoicesDocument, variables, requestHeaders));
    },
    getIssuedBill(variables: GetIssuedBillQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetIssuedBillQuery> {
      return withWrapper(() => client.request<GetIssuedBillQuery>(GetIssuedBillDocument, variables, requestHeaders));
    },
    getIssuedBills(variables: GetIssuedBillsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetIssuedBillsQuery> {
      return withWrapper(() => client.request<GetIssuedBillsQuery>(GetIssuedBillsDocument, variables, requestHeaders));
    },
    getReceivedBill(variables: GetReceivedBillQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReceivedBillQuery> {
      return withWrapper(() => client.request<GetReceivedBillQuery>(GetReceivedBillDocument, variables, requestHeaders));
    },
    getReceivedBills(variables: GetReceivedBillsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReceivedBillsQuery> {
      return withWrapper(() => client.request<GetReceivedBillsQuery>(GetReceivedBillsDocument, variables, requestHeaders));
    },
    getSharedBill(variables: GetSharedBillQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetSharedBillQuery> {
      return withWrapper(() => client.request<GetSharedBillQuery>(GetSharedBillDocument, variables, requestHeaders));
    },
    getSharedPayment(variables: GetSharedPaymentQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetSharedPaymentQuery> {
      return withWrapper(() => client.request<GetSharedPaymentQuery>(GetSharedPaymentDocument, variables, requestHeaders));
    },
    getSharedReceipt(variables: GetSharedReceiptQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetSharedReceiptQuery> {
      return withWrapper(() => client.request<GetSharedReceiptQuery>(GetSharedReceiptDocument, variables, requestHeaders));
    },
    serviceHealth(variables?: ServiceHealthQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ServiceHealthQuery> {
      return withWrapper(() => client.request<ServiceHealthQuery>(ServiceHealthDocument, variables, requestHeaders));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;