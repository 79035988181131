import { FC } from 'react';
import clsx from 'clsx';

import ListItem from '@/components/0_atom/ListItem';

import SvgCheck from '@/svgs/fa-check-light';
import SvgExclamation from '@/svgs/fa-exclamation';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiList from '@material-ui/core/List';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiListItemIcon-root': {
            justifyContent: 'center',
        },
    },
    rootError: {
        color: theme.palette.error.main,
    },
    rootWarn: {},
    errorIcon: {
        color: theme.palette.error.main,
    },
    warnIcon: {
        color: theme.palette.warning.main,
    },
    checkIconOk: {
        color: theme.palette.success.main,
    },
    checkIconDisabled: {
        color: theme.palette.text.disabled,
    },
}));

interface CheckListItem {
    label: string;
    description?: string;
    isOk: boolean;
    isError?: boolean;
    isWarn?: boolean;
}

const CheckList: FC<{
    items: CheckListItem[];
    className?: string;
}> = ({ items, className }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <MuiList className={className}>
            {items.map(({ label, description, isOk, isError, isWarn }, i) => (
                <ListItem
                    key={i}
                    primaryText={label}
                    secondaryText={description}
                    className={clsx(c.root, {
                        [c.rootError]: isError,
                        [c.rootWarn]: isWarn,
                    })}
                    icon={
                        isError ? (
                            <SvgExclamation className={c.errorIcon} />
                        ) : isWarn ? (
                            <SvgExclamation className={c.warnIcon} />
                        ) : (
                            <SvgCheck className={isOk ? c.checkIconOk : c.checkIconDisabled} />
                        )
                    }
                />
            ))}
        </MuiList>
    );
};
CheckList.displayName = 'CheckList';
export default CheckList;
