import { Contact, ContactAPIForOwner } from '@/utils/UodkaClients/Core';
import {
    IssuedBillsFilter,
    UodkaInvoicesClientForObserver,
    UodkaInvoicesClientForGeneral,
    UodkaInvoicesClientForOwner,
} from '@/utils/UodkaClients/Invoices';
import { ContactItem } from '@/utils/customHooks';

import {
    IssuedBillItem,
    IssuedBillItemForObserver,
    IssuedBillItemForGeneral,
    IssuedBillItemForOwner,
} from '@/views/bills/useBillItems/fetchIssuedBill/IssuedBillItem';

const fetchIssuedBillItemForOwners = async ({
    invoicesClient,
    filter,
    contactItems,
}: {
    invoicesClient: UodkaInvoicesClientForOwner;
    filter: IssuedBillsFilter | undefined;
    contactItems: ContactItem<ContactAPIForOwner>[];
}): Promise<IssuedBillItemForOwner[]> => {
    const issuedBills = await invoicesClient.getIssuedBillAPIs({ filter });
    const issuedBillItems = await Promise.all(
        issuedBills.map(async (issuedBill) => {
            const contactItem = contactItems.find(({ id }) => id === issuedBill.contact.id);
            if (!contactItem) {
                throw new Error('fetchIssuedBillItemForOwners: Unexpectedly (!contactItem)');
            }
            return {
                id: issuedBill.id,
                issuedBill,
                contactItem,
            };
        })
    );
    return issuedBillItems;
};

const fetchIssuedBillItemForGenerals = async ({
    invoicesClient,
    filter,
    contactItems,
}: {
    invoicesClient: UodkaInvoicesClientForGeneral;
    filter: IssuedBillsFilter | undefined;
    contactItems: ContactItem<Contact>[];
}): Promise<IssuedBillItemForGeneral[]> => {
    const issuedBills = await invoicesClient.getIssuedBillAPIs({ filter });
    const issuedBillItems = await Promise.all(
        issuedBills.map(async (issuedBill) => {
            const contactItem = contactItems.find(({ id }) => id === issuedBill.contact.id);
            if (!contactItem) {
                throw new Error('fetchIssuedBillItemForGenerals: Unexpectedly (!contactItem)');
            }
            return {
                id: issuedBill.id,
                issuedBill,
                contactItem,
            };
        })
    );
    return issuedBillItems;
};

const fetchIssuedBillItemForObservers = async ({
    invoicesClient,
    filter,
    contactItems,
}: {
    invoicesClient: UodkaInvoicesClientForObserver;
    filter: IssuedBillsFilter | undefined;
    contactItems: ContactItem<Contact>[];
}): Promise<IssuedBillItemForObserver[]> => {
    const issuedBills = await invoicesClient.getIssuedBills({ filter });
    const issuedBillItems = await Promise.all(
        issuedBills.map(async (issuedBill) => {
            const contactItem = contactItems.find(({ id }) => id === issuedBill.contact.id);
            if (!contactItem) {
                throw new Error('fetchIssuedBillItemForObservers: Unexpectedly (!contactItem)');
            }
            return {
                id: issuedBill.id,
                issuedBill,
                contactItem,
            };
        })
    );
    return issuedBillItems;
};

const getIsContactAPIForOwners = (contactItems: ContactItem[]): contactItems is ContactItem<ContactAPIForOwner>[] => {
    return contactItems[0] instanceof ContactAPIForOwner;
};

export const fetchIssuedBills = async ({
    invoicesClient,
    filter,
    contactItems,
}: {
    invoicesClient: UodkaInvoicesClientForObserver | UodkaInvoicesClientForGeneral | UodkaInvoicesClientForOwner;
    filter: IssuedBillsFilter | undefined;
    contactItems: ContactItem[];
}): Promise<IssuedBillItem[]> => {
    if (invoicesClient instanceof UodkaInvoicesClientForOwner && getIsContactAPIForOwners(contactItems)) {
        return fetchIssuedBillItemForOwners({ invoicesClient, filter, contactItems });
    }
    if (invoicesClient instanceof UodkaInvoicesClientForGeneral) {
        return fetchIssuedBillItemForGenerals({ invoicesClient, filter, contactItems });
    }
    return fetchIssuedBillItemForObservers({ invoicesClient, filter, contactItems });
};
