import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5227 3.44461L9.68297 0.495703C9.37828 0.179297 8.96526 0 8.53565 0H1.62466C0.727865 0.00351563 0 0.759375 0 1.69066V16.3129C0 17.2441 0.727865 18 1.62466 18H11.3753C12.2721 18 13 17.2441 13 16.3129V4.63957C13 4.19344 12.8273 3.76102 12.5227 3.44461ZM8.66768 1.14574C8.76247 1.17035 8.84711 1.21957 8.9182 1.2934L11.7579 4.2423C11.829 4.31613 11.8764 4.40402 11.9001 4.50246H8.66768V1.14574ZM11.917 16.3129C11.917 16.6222 11.6733 16.8754 11.3753 16.8754H1.62466C1.32674 16.8754 1.08299 16.6222 1.08299 16.3129V1.69066C1.08299 1.38129 1.32674 1.125 1.62466 1.125H7.58435V4.78371C7.58435 5.25129 7.94659 5.625 8.39685 5.625H11.917V16.3129ZM2.16667 7.3125V15.1875C2.16667 15.4983 2.40906 15.75 2.70833 15.75H10.2917C10.5909 15.75 10.8333 15.4983 10.8333 15.1875V7.3125C10.8333 7.00172 10.5909 6.75 10.2917 6.75H2.70833C2.40906 6.75 2.16667 7.00172 2.16667 7.3125ZM9.75 14.625H7.04167V12.9375H9.75V14.625ZM9.75 11.8125H7.04167V10.125H9.75V11.8125ZM3.25 7.875H9.75V9H3.25V7.875ZM3.25 10.125H5.95833V11.8125H3.25V10.125ZM3.25 12.9375H5.95833V14.625H3.25V12.9375Z"
                fill="currentColor"
            />
        </svg>
    );
};
Svg.displayName = 'svg/spreadsheet';
export default Svg;
