import { FC, ComponentProps, useState, useCallback } from 'react';
import clsx from 'clsx';

import { AddMutationInput, ProcessingMutation, getWrappedMutation } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import FormItem from '@/components/0_atom/FormItem';
import MutationButton from '@/components/0_atom/MutationButton';
import Button from '@/components/0_atom/Button';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        marginBottom: 44,
    },
    items: {},
    submits: {
        display: 'flex',
        marginTop: 73,
    },
    cancelButton: {
        flexGrow: 1,
        width: 'calc(50% - 14px)',
    },
    submitButton: {
        flexGrow: 1,
        marginLeft: 14,
        width: 'calc(50% - 14px)',
    },
}));

export const MutationForm: FC<{
    className?: string;
    title: string;
    mutation: AddMutationInput;
    onClose: () => void;
    items: ComponentProps<typeof FormItem>[];
    submitText: string;
    initializeForm?: () => void;
    isDisplayCancelButton?: boolean;
}> = ({ className, title, mutation, items, submitText, initializeForm, onClose, isDisplayCancelButton }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [currentSlot, setCurrentSlot] = useState<ProcessingMutation | undefined>(undefined);
    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);
    const initialize = useCallback(() => {
        if (currentSlot) {
            setCurrentSlot(undefined);
        }
        initializeForm && initializeForm();
    }, [currentSlot, initializeForm]);

    // DATA
    const isReady = !items.find((item) => !item.isSatisfied);
    const wrappedMutation = getWrappedMutation({
        mutation,
        additionalOnThen: (result) => {
            if (result === 'success') {
                handleClose();
                setTimeout(initialize, 500);
            }
        },
    });

    return (
        <div className={clsx(c.root, className)}>
            <MuiTypography variant={'h1'} className={c.title}>
                {title}
            </MuiTypography>
            <div className={c.items}>
                {items.map((item, i) => (
                    <FormItem key={i} {...item} />
                ))}
            </div>
            <div className={c.submits}>
                <Button
                    style={{ visibility: !isDisplayCancelButton ? 'hidden' : undefined }}
                    onClick={handleClose}
                    className={c.cancelButton}
                    role={'cancel'}
                    variant={'text'}
                >
                    {txt({ en: 'Cancel', ja: 'キャンセル' })}
                </Button>
                <MutationButton
                    mutation={wrappedMutation}
                    disabled={!isReady}
                    className={c.submitButton}
                    role={'submit'}
                    setCurrentSlot={setCurrentSlot}
                >
                    {submitText}
                </MutationButton>
            </div>
        </div>
    );
};
MutationForm.displayName = 'MutationForm';
