import {
    InviteForBusinessMemberInBusiness as GqlInviteForBusinessMemberInBusiness,
    BusinessMemberRole,
} from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { GqlDateTimeData } from '@/utils/UodkaClients/Core/graphql/customScalars';

export interface InviteForMemberInBusinessInterface {
    codeText: string;
    createdAt: Date;
    expireAt: Date;
    remainingSeats: number;
    role: BusinessMemberRole;
}

export class InviteForMemberInBusiness implements InviteForMemberInBusinessInterface {
    protected _codeText: string;

    protected _createdAt: Date;

    protected _expireAt: Date;

    protected _remainingSeats: number;

    protected _role: BusinessMemberRole;

    get codeText() {
        return this._codeText;
    }

    get createdAt() {
        return this._createdAt;
    }

    get expireAt() {
        return this._expireAt;
    }

    get remainingSeats() {
        return this._remainingSeats;
    }

    get role() {
        return this._role;
    }

    get data() {
        return {
            codeText: this.codeText,
            createdAt: this.createdAt,
            expireAt: this.expireAt,
            remainingSeats: this.remainingSeats,
            role: this.role,
        };
    }

    constructor({ codeText, createdAt, expireAt, remainingSeats, role }: InviteForMemberInBusinessInterface) {
        this._codeText = codeText;
        this._createdAt = createdAt;
        this._expireAt = expireAt;
        this._remainingSeats = remainingSeats;
        this._role = role;
    }
}

export const getInviteForMemberInBusinessFromGql = ({
    codeText,
    createdAt,
    expireAt,
    remainingSeats,

    role,
}: GqlInviteForBusinessMemberInBusiness) => {
    return new InviteForMemberInBusiness({
        codeText,
        createdAt: GqlDateTimeData.serialize(createdAt),
        expireAt: GqlDateTimeData.serialize(expireAt),
        remainingSeats,
        role,
    });
};
