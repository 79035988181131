import { forwardRef, ComponentProps } from 'react';

import { TextMenuItem, TextMenuItemProps } from '@/components/0_atom/Menu/TextMenuItem';

import MuiMenuList from '@material-ui/core/MenuList';

export const TextMenuList = forwardRef<
    HTMLUListElement,
    ComponentProps<typeof MuiMenuList> & {
        items: TextMenuItemProps[];
    }
>(({ items, ...otherProps }, ref) => {
    // DATA
    const isWithIcon = !!items.find((item) => item.icon || item.isSelected);
    return (
        <MuiMenuList ref={ref} {...otherProps}>
            {items.map((item, i) => (
                <TextMenuItem key={i} {...item} isWithIcon={isWithIcon} />
            ))}
        </MuiMenuList>
    );
});
TextMenuList.displayName = 'TextMenuList';
