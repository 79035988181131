import { FC } from 'react';

import { ReceivedBill } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';

import { getReceivedBillStatusHistory } from '@/views/bills/ReceivedBills/getReceivedBillStatusHistory';
import { ReceivedBillStatusSection } from '@/views/bills/ReceivedBills/ReceivedBillStatusDisplay/ReceivedBillStatusSection';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 116,
    },
}));

export const ReceivedBillStatusDisplay: FC<{
    bill: ReceivedBill;
}> = ({ bill }) => {
    // STYLE
    const theme = useTheme();
    const c = useStyles(theme);

    // HOOKS
    const { txt } = useLanguage();

    // DATA
    const { billStatus, paymentStatus } = (() => {
        const items = getReceivedBillStatusHistory({ bill, txt });
        const billStatuses = items.filter(({ section }) => section === 'bill');
        const paymentStatuses = items.filter(({ section }) => section === 'payment');
        const billStatus = (() => {
            if (billStatuses.length > 0) {
                return billStatuses[billStatuses.length - 1];
            }
            return undefined;
        })();
        const paymentStatus = (() => {
            if (paymentStatuses.length > 0) {
                return paymentStatuses[paymentStatuses.length - 1];
            }
            return undefined;
        })();
        return {
            billStatus,
            paymentStatus,
        };
    })();

    return (
        <div className={c.root}>
            <ReceivedBillStatusSection sectionLabel={txt({ en: 'Bill', ja: '請求' })} status={billStatus} />
            <ReceivedBillStatusSection sectionLabel={txt({ en: 'Payment', ja: '支払い' })} status={paymentStatus} />
        </div>
    );
};
ReceivedBillStatusDisplay.displayName = 'ReceivedBillStatusDisplay';
