import { FC, ReactElement } from 'react';

import { useLanguage } from '@/utils/customHooks';
import { getBankAccountText } from '@/utils/utilFunctions';

import { getTaxName } from '@/views/bills/BillViewer/getTaxName';
import { FieldItem, getKindName } from '@/views/records/useAccountingItems';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTable from '@material-ui/core/Table';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';

const useStyles = makeStyles((theme) => ({
    root: {
        '& thead': {
            '& th': {
                background: '#fff',
                color: theme.palette.primary.main,
                fontSize: theme.typography.caption.fontSize,
                borderBottom: 'solid 3px rgba(0,0,0,.05)',
            },
        },
        '& tr': {
            '&:nth-child(odd)': {
                background: '#fafafa',
            },
            '& th': {
                whiteSpace: 'nowrap',
                fontWeight: 600,
                '&:not(:first-child)': {
                    borderLeft: 'solid 1px rgba(0,0,0,.05)',
                },
            },
            '& td': {
                fontSize: theme.typography.caption.fontSize,
                color: theme.palette.primary.main,
                whiteSpace: 'nowrap',
                borderBottom: 'solid 1px rgba(0,0,0,.05)',
                '&:not(:first-child)': {
                    borderLeft: 'solid 1px rgba(0,0,0,.05)',
                },
            },
        },
    },
    noRecordCell: {
        background: '#fff',
        borderBottom: 'none !important',
        fontWeight: 600,
        color: `${theme.palette.text.disabled} !important`,
    },
    revenueCell: {
        color: '#1D77FF !important',
    },
    costCell: {
        color: '#F44 !important',
    },
    taxCell: {
        color: 'rgba(0,0,0,.35) !important',
    },
}));

export const AccountingTable: FC<{
    items: FieldItem[];
    isContactId: boolean;
    isContactName: boolean;
    isItemName: boolean;
    isKind: boolean;
    isSelfDepositAmount: boolean;
    isReceivedBillAmount: boolean;
    isIssuedBillAmount: boolean;
    isIssuedPaymentAmount: boolean;
    isReceivedPaymentAmount: boolean;
    isPaidTaxAmount: boolean;
    isReceivedTaxAmount: boolean;
    isBankAccount: boolean;
    isBillDate: boolean;
    isPaymentDate: boolean;
}> = ({
    items,
    isContactId,
    isContactName,
    isItemName,
    isKind,
    isSelfDepositAmount,
    isReceivedBillAmount,
    isIssuedBillAmount,
    isIssuedPaymentAmount,
    isReceivedPaymentAmount,
    isPaidTaxAmount,
    isReceivedTaxAmount,
    isBankAccount,
    isBillDate,
    isPaymentDate,
}) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt, getDateStr } = useLanguage();

    return (
        <MuiTable className={c.root} stickyHeader>
            <MuiTableHead>
                <MuiTableRow>
                    {(() => {
                        const arr: ReactElement[] = [];
                        if (isContactId) {
                            arr.push(
                                <MuiTableCell key={1}>{txt({ en: 'Contact ID', ja: 'コンタクトID' })}</MuiTableCell>
                            );
                        }
                        if (isContactName) {
                            arr.push(
                                <MuiTableCell key={2}>
                                    {txt({ en: 'Contact Name', ja: 'コンタクトの名称' })}
                                </MuiTableCell>
                            );
                        }
                        if (isItemName) {
                            arr.push(<MuiTableCell key={3}>{txt({ en: 'Item', ja: '項目' })}</MuiTableCell>);
                        }
                        if (isKind) {
                            arr.push(<MuiTableCell key={4}>{txt({ en: 'Kind', ja: '種別' })}</MuiTableCell>);
                        }
                        if (isSelfDepositAmount) {
                            arr.push(
                                <MuiTableCell key={5}>
                                    {txt({ en: 'Amount (Self Deposit)', ja: '金額（自己入金）' })}
                                </MuiTableCell>
                            );
                        }
                        if (isReceivedBillAmount) {
                            arr.push(
                                <MuiTableCell key={6}>
                                    {txt({ en: 'Amount (Received Bill)', ja: '金額（受取請求）' })}
                                </MuiTableCell>
                            );
                        }
                        if (isIssuedBillAmount) {
                            arr.push(
                                <MuiTableCell key={7}>
                                    {txt({ en: 'Amount (Issued Bill)', ja: '金額（発行請求）' })}
                                </MuiTableCell>
                            );
                        }
                        if (isIssuedPaymentAmount) {
                            arr.push(
                                <MuiTableCell key={8}>{txt({ en: 'Amount (Paid)', ja: '金額（支払）' })}</MuiTableCell>
                            );
                        }
                        if (isReceivedPaymentAmount) {
                            arr.push(
                                <MuiTableCell key={9}>
                                    {txt({ en: 'Amount (Deposit)', ja: '金額（受取）' })}
                                </MuiTableCell>
                            );
                        }
                        if (isPaidTaxAmount) {
                            arr.push(
                                <MuiTableCell key={10}>
                                    {txt({ en: 'Amount (Paid Tax)', ja: '金額（支払税金）' })}
                                </MuiTableCell>
                            );
                        }
                        if (isReceivedTaxAmount) {
                            arr.push(
                                <MuiTableCell key={11}>
                                    {txt({ en: 'Amount (Received Tax)', ja: '金額（受取税金）' })}
                                </MuiTableCell>
                            );
                        }
                        if (isBankAccount) {
                            arr.push(
                                <MuiTableCell key={12}>{txt({ en: 'Bank Account', ja: '銀行口座' })}</MuiTableCell>
                            );
                        }
                        if (isBillDate) {
                            arr.push(
                                <MuiTableCell key={13}>{txt({ en: 'Billing Date', ja: '請求発行日' })}</MuiTableCell>
                            );
                        }
                        if (isPaymentDate) {
                            arr.push(
                                <MuiTableCell key={14}>
                                    {txt({ en: 'Transaction Date', ja: '支払・入金日' })}
                                </MuiTableCell>
                            );
                        }
                        return arr;
                    })()}
                </MuiTableRow>
            </MuiTableHead>
            <MuiTableBody>
                {items.length > 0 ? (
                    items.map((item, i) => (
                        <MuiTableRow key={i}>
                            {(() => {
                                const arr: ReactElement[] = [];
                                if (isContactId) {
                                    arr.push(<MuiTableCell key={1}>{item.contactId || '-'}</MuiTableCell>);
                                }
                                if (isContactName) {
                                    arr.push(<MuiTableCell key={2}>{item.contactName || '-'}</MuiTableCell>);
                                }
                                if (isItemName) {
                                    arr.push(
                                        <MuiTableCell
                                            key={3}
                                            className={
                                                item.kind === 'paidTax' || item.kind === 'receivedTax'
                                                    ? c.taxCell
                                                    : undefined
                                            }
                                        >
                                            {item.kind === 'paidTax' || item.kind === 'receivedTax'
                                                ? getTaxName({ taxTitle: item.itemName, txt })
                                                : item.itemName}
                                        </MuiTableCell>
                                    );
                                }
                                if (isKind) {
                                    arr.push(
                                        <MuiTableCell
                                            key={4}
                                            className={
                                                item.kind === 'revenue'
                                                    ? c.revenueCell
                                                    : item.kind === 'cost'
                                                    ? c.costCell
                                                    : item.kind === 'paidTax' || item.kind === 'receivedTax'
                                                    ? c.taxCell
                                                    : undefined
                                            }
                                        >
                                            {getKindName({ kind: item.kind, txt })}
                                        </MuiTableCell>
                                    );
                                }
                                if (isSelfDepositAmount) {
                                    arr.push(
                                        <MuiTableCell key={5} align={'right'}>
                                            {item.selfDepositAmount
                                                ? `¥${item.selfDepositAmount.toLocaleString()}`
                                                : undefined}
                                        </MuiTableCell>
                                    );
                                }
                                if (isReceivedBillAmount) {
                                    arr.push(
                                        <MuiTableCell key={6} align={'right'} className={c.costCell}>
                                            {item.receivedBillAmount
                                                ? `¥${item.receivedBillAmount.toLocaleString()}`
                                                : undefined}
                                        </MuiTableCell>
                                    );
                                }
                                if (isIssuedBillAmount) {
                                    arr.push(
                                        <MuiTableCell key={7} align={'right'} className={c.revenueCell}>
                                            {item.issuedBillAmount
                                                ? `¥${item.issuedBillAmount.toLocaleString()}`
                                                : undefined}
                                        </MuiTableCell>
                                    );
                                }
                                if (isIssuedPaymentAmount) {
                                    arr.push(
                                        <MuiTableCell key={8} align={'right'} className={c.costCell}>
                                            {item.kind === 'cost' && item.issuedPaymentAmount
                                                ? `¥${item.issuedPaymentAmount.toLocaleString()}`
                                                : undefined}
                                        </MuiTableCell>
                                    );
                                }
                                if (isReceivedPaymentAmount) {
                                    arr.push(
                                        <MuiTableCell key={9} align={'right'} className={c.revenueCell}>
                                            {item.kind === 'revenue' && item.receivedPaymentAmount
                                                ? `¥${item.receivedPaymentAmount.toLocaleString()}`
                                                : undefined}
                                        </MuiTableCell>
                                    );
                                }
                                if (isPaidTaxAmount) {
                                    arr.push(
                                        <MuiTableCell key={10} className={c.taxCell}>
                                            {item.kind === 'paidTax' && item.issuedPaymentAmount
                                                ? `¥${item.issuedPaymentAmount.toLocaleString()}`
                                                : undefined}
                                        </MuiTableCell>
                                    );
                                }
                                if (isReceivedTaxAmount) {
                                    arr.push(
                                        <MuiTableCell key={11} className={c.taxCell}>
                                            {item.kind === 'receivedTax' && item.receivedPaymentAmount
                                                ? `¥${item.receivedPaymentAmount.toLocaleString()}`
                                                : undefined}
                                        </MuiTableCell>
                                    );
                                }
                                if (isBankAccount) {
                                    arr.push(
                                        <MuiTableCell key={12}>
                                            {item.bankAccount
                                                ? getBankAccountText({ bankAccount: item.bankAccount })
                                                : '-'}
                                        </MuiTableCell>
                                    ); // later
                                }
                                if (isBillDate) {
                                    arr.push(
                                        <MuiTableCell key={13}>
                                            {item.billDate ? getDateStr(item.billDate) : undefined}
                                        </MuiTableCell>
                                    );
                                }
                                if (isPaymentDate) {
                                    arr.push(<MuiTableCell key={14}> {getDateStr(item.paymentDate)}</MuiTableCell>);
                                }
                                return arr;
                            })()}
                        </MuiTableRow>
                    ))
                ) : (
                    <MuiTableRow>
                        <MuiTableCell className={c.noRecordCell}>
                            {txt({ en: 'No record', ja: 'レコードがありません' })}
                        </MuiTableCell>
                    </MuiTableRow>
                )}
            </MuiTableBody>
        </MuiTable>
    );
};
AccountingTable.displayName = 'AccountingTable';
