import { SharedTransfer, getSharedTransferFromGql } from '@/utils/UodkaClients/Core';
import { GqlDateTimeData } from '@/utils/UodkaClients/Invoices/graphql/customScalars';
import { SharedPayment as GqlSharedPayment } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import { PaymentData, getPaymentDataFromGql } from '@/utils/UodkaClients/Invoices/models/InvoiceData';
import { BillInPayment, getBillInPaymentFromGql } from '@/utils/UodkaClients/Invoices/models/BillEdge';

interface SharedPaymentInterface {
    id: string;
    createdAt: Date;
    paymentData: PaymentData;
    transfer: SharedTransfer;
    bill?: BillInPayment;
}

export class SharedPayment implements SharedPaymentInterface {
    protected _id: string;

    protected _createdAt: Date;

    protected _paymentData: PaymentData;

    protected _transfer: SharedTransfer;

    protected _bill?: BillInPayment;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get paymentData() {
        return this._paymentData;
    }

    get transfer() {
        return this._transfer;
    }

    get bill() {
        return this._bill;
    }

    constructor({ id, createdAt, paymentData, transfer, bill }: SharedPaymentInterface) {
        this._id = id;
        this._createdAt = createdAt;
        this._paymentData = paymentData;
        this._transfer = transfer;
        this._bill = bill;
    }
}

export const getSharedPaymentFromGql = ({ id, createdAt, paymentData, transfer, bill }: GqlSharedPayment) => {
    return new SharedPayment({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        paymentData: getPaymentDataFromGql(paymentData),
        transfer: getSharedTransferFromGql(transfer),
        bill: bill ? getBillInPaymentFromGql(bill) : undefined,
    });
};
