import { createContext, useContext } from 'react';

import { UodkaCoreServiceHealth } from '@/utils/UodkaClients/Core';
import { UodkaProfileServiceHealth } from '@/utils/UodkaClients/Profile';
import { UodkaCertServiceHealth } from '@/utils/UodkaClients/Cert';
import { UodkaInvoicesServiceHealth } from '@/utils/UodkaClients/Invoices';

export interface ServiceHealthWithFetchStatus<T> {
    health?: T;
    fetchStatus: 'fetchError' | 'fetching' | 'fetched';
}
export type ServiceHealth = UodkaCoreServiceHealth;

interface HealthCtxType {
    core: ServiceHealthWithFetchStatus<UodkaCoreServiceHealth>;
    profile: ServiceHealthWithFetchStatus<UodkaProfileServiceHealth>;
    cert: ServiceHealthWithFetchStatus<UodkaCertServiceHealth>;
    invoices: ServiceHealthWithFetchStatus<UodkaInvoicesServiceHealth>;
}

export const HealthCtx = createContext<HealthCtxType>({
    core: {
        fetchStatus: 'fetching',
    },
    profile: {
        fetchStatus: 'fetching',
    },
    cert: {
        fetchStatus: 'fetching',
    },
    invoices: {
        fetchStatus: 'fetching',
    },
});
export const useHealthCtx = () => {
    return useContext(HealthCtx);
};
