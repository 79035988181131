import { MutationValidationResult } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { GraphQLSdk } from '@/utils/UodkaClients/Core/graphql/graphqlSdk';
import { TransferInBalance, getTransferInBalanceFromGql } from '@/utils/UodkaClients/Core/models/Transfer';
import {
    TransferConfirmation,
    TransferConfirmationInterface,
} from '@/utils/UodkaClients/Core/models/TransferConfirmation/data/TransferConfirmation';

interface TransferConfirmationAPIForGeneralInterface {
    approve: () => Promise<{
        transfer: TransferInBalance;
        result: MutationValidationResult;
    }>;
    remove: () => Promise<{
        result: MutationValidationResult;
    }>;
}

interface ConstructorInput extends TransferConfirmationInterface {
    graphqlSdk: GraphQLSdk;
    parentBusinessId: string;
    parentContactId: string;
}

export class TransferConfirmationAPIForGeneral
    extends TransferConfirmation
    implements TransferConfirmationAPIForGeneralInterface {
    protected _graphqlSdk: GraphQLSdk;

    protected _parentBusinessId: string;

    protected _parentContactId: string;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
        this._parentBusinessId = input.parentBusinessId;
        this._parentContactId = input.parentContactId;
    }

    async approve() {
        const { createTransfer } = await this._graphqlSdk.createTransfer({
            input: {
                businessId: this._parentBusinessId,
                contactId: this._parentContactId,
                transferConfirmationId: this.id,
            },
        });
        return {
            result: createTransfer.result,
            transfer: getTransferInBalanceFromGql(createTransfer.transfer),
        };
    }

    async remove() {
        const { deleteTransferConfirmation } = await this._graphqlSdk.deleteTransferConfirmation({
            input: {
                businessId: this._parentBusinessId,
                contactId: this._parentContactId,
                id: this.id,
            },
        });
        return {
            result: deleteTransferConfirmation.result,
        };
    }
}
