import { FC } from 'react';
import clsx from 'clsx';

import { useBusinessCtx } from '@/utils/ctxs';
import { useView, viewDefs, ViewName, useRouting, useLanguage } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: 'flex-start',
        padding: '7px 8px',
        '& .MuiButton-startIcon': {
            width: 30,
            height: 30,
            padding: 6,
            background: '#fff',
            borderRadius: 4,
            color: theme.palette.text.primary,
        },
        '& .MuiButton-label': {
            color: '#fff',
        },
    },
    rootSelected: {
        '& .MuiButton-label': {
            fontWeight: 600,
        },
    },
    rootNotSelected: {
        '& .MuiButton-startIcon': {
            opacity: 0.3,
        },
        '& .MuiButton-label': {
            opacity: 0.7,
        },
    },
}));

const ViewButton: FC<{
    viewName: ViewName;
}> = ({ viewName }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const { currentBusiness } = useBusinessCtx();
    const { currentView } = useView();
    const { switchView } = useRouting();

    // DATA
    const view = viewDefs[viewName];

    return (
        <Button
            className={clsx(c.root, {
                [c.rootSelected]: currentView?.name === viewName,
                [c.rootNotSelected]: currentView?.name !== viewName,
            })}
            startIcon={view.icon}
            onClick={
                currentBusiness ? () => switchView({ businessId: currentBusiness.id, viewName: view.name }) : undefined
            }
            fullWidth
        >
            {txt(view.displayName)}
        </Button>
    );
};
ViewButton.displayName = 'ViewButton';
export default ViewButton;
