import { parseISO } from 'date-fns';

export class GqlDateTimeData {
    static parseValue(date: Date): string {
        if (date instanceof Date) {
            return date.toISOString();
        }
        throw new Error('GraphQLScalarType DateTime.parseValue(arg) arg should be Date');
    }

    static serialize(isoString: string): Date {
        const date = parseISO(isoString);
        if (date.toString() === 'Invalid Date') {
            throw new Error('GraphQLScalarType DateTime.serialize(arg) arg should be ISO8601 string');
        }
        return date;
    }
}
