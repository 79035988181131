import { DepositAccountInContact as GqlDepositAccountInContact } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';

interface DepositAccountInContactInterface {
    id: string;
    bankCode: string;
    branchCode: string;
    accountNumber: string;
    accountHolderName: string;
}

export class DepositAccountInContact implements DepositAccountInContactInterface {
    protected _id: string;

    protected _bankCode: string;

    protected _branchCode: string;

    protected _accountNumber: string;

    protected _accountHolderName: string;

    get id() {
        return this._id;
    }

    get bankCode() {
        return this._bankCode;
    }

    get branchCode() {
        return this._branchCode;
    }

    get accountNumber() {
        return this._accountNumber;
    }

    get accountHolderName() {
        return this._accountHolderName;
    }

    get data() {
        return {
            id: this._id,
            bankCode: this.bankCode,
            branchCode: this.branchCode,
            accountNumber: this.accountNumber,
            accountHolderName: this.accountHolderName,
        };
    }

    constructor({ id, bankCode, branchCode, accountNumber, accountHolderName }: DepositAccountInContactInterface) {
        this._id = id;
        this._bankCode = bankCode;
        this._branchCode = branchCode;
        this._accountNumber = accountNumber;
        this._accountHolderName = accountHolderName;
    }
}

export const getDepositAccountInContactFromGql = ({
    id,
    bankCode,
    branchCode,
    accountNumber,
    accountHolderName,
}: GqlDepositAccountInContact) => {
    return new DepositAccountInContact({
        id,
        bankCode,
        branchCode,
        accountNumber,
        accountHolderName,
    });
};

// export const getDepositAccountInContact = (depositAccountInterface?: DepositAccountInContactInterface) => {
//     if (depositAccountInterface) {
//         return new DepositAccountInContact(depositAccountInterface);
//     }
//     return undefined;
// };
