import { useState, useEffect } from 'react';

import { initUodkaCorePublicClient, UodkaFees } from '@/utils/UodkaClients/Core';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';

export const useTransferInformations = () => {
    // HOOKS
    const {
        clients: { core: coreClient },
    } = useBusinessCtxAbsolutely();
    const [fees, setFees] = useState<UodkaFees | undefined>(undefined);
    const [balance, setBalance] = useState<number | undefined>(undefined);

    // USEEFFECT
    useEffect(() => {
        coreClient
            .getRecentBalance()
            .then((recentBalance) => setBalance(recentBalance ? recentBalance.currentAmount : 0));
        initUodkaCorePublicClient()
            .getFees()
            .then((fees) => setFees(fees));
    }, [coreClient]);

    return {
        fees,
        currentBalance: balance,
    };
};
