import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 246 185" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M131 7.72222C131 3.45736 127.642 0 123.5 0C119.358 0 116 3.45736 116 7.72222V113.257L82.169 79.2699C79.1564 76.2434 74.272 76.2434 71.2593 79.2699C68.2467 82.2965 68.2467 87.2035 71.2593 90.2301L117.545 136.73C118.992 138.183 120.954 139 123 139C123.084 139 123.168 138.999 123.252 138.996C123.334 138.999 123.417 139 123.5 139C126.236 139 128.63 137.491 129.94 135.238L174.74 90.2301C177.753 87.2035 177.753 82.2965 174.74 79.2699C171.728 76.2434 166.843 76.2434 163.831 79.2699L131 112.253V7.72222Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.6875 123C11.9332 123 15.375 126.47 15.375 130.75V146.25C15.375 152.416 17.8048 158.33 22.1299 162.69C26.4549 167.05 32.3209 169.5 38.4375 169.5H207.562C213.679 169.5 219.545 167.05 223.87 162.69C228.195 158.33 230.625 152.416 230.625 146.25V130.75C230.625 126.47 234.067 123 238.312 123C242.558 123 246 126.47 246 130.75V146.25C246 156.527 241.95 166.383 234.742 173.65C227.533 180.917 217.757 185 207.562 185H38.4375C28.2432 185 18.4665 180.917 11.2581 173.65C4.04965 166.383 0 156.527 0 146.25V130.75C0 126.47 3.44181 123 7.6875 123Z"
                fill="currentColor"
            />
        </svg>
    );
};
Svg.displayName = 'svg/download';
export default Svg;
