import { FC } from 'react';
import clsx from 'clsx';

import { useLanguage } from '@/utils/customHooks';
import { provideTextColorForBackgroundColor } from '@/utils/utilFunctions/provideTextColorForBackgroundColor';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 80,
        height: 40,
        borderRadius: 4,
        border: 'solid 2px rgba(0,0,0,.03)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        fontWeight: 600,
        fontSize: theme.typography.caption.fontSize,
    },
}));

export const getIsValidHexColorCode = (text: string) => {
    return !!text.match(/^#[a-fA-F0-9]{0,6}$/);
};

const ColorDisplay: FC<{
    color?: string;
    className?: string;
}> = ({ color, className }) => {
    // STYLE
    const theme = useTheme();
    const c = useStyles(theme);

    // HOOKS
    const { txt } = useLanguage();

    // DATA
    const hexCode = color && getIsValidHexColorCode(color) ? color : undefined;

    return (
        <div className={clsx(c.root, className)} style={{ background: hexCode }}>
            <MuiTypography
                className={c.text}
                style={{ color: provideTextColorForBackgroundColor(theme.palette.text.primary, hexCode) }}
            >
                {hexCode ? hexCode.toUpperCase() : txt({ en: 'Not Specified', ja: '未設定' })}
            </MuiTypography>
        </div>
    );
};
ColorDisplay.displayName = 'ColorDisplay';
export default ColorDisplay;
