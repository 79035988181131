import { FC, useState } from 'react';

import { ContactAPIForOwner } from '@/utils/UodkaClients/Core';
import { useLanguage } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import { MutationFormModalForBankAccount } from '@/components/1_mol/FormModal';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({}));

export const ModifyContactBankAccountButton: FC<{
    contactAPI: ContactAPIForOwner;
    updateItem: (contactAPI: ContactAPIForOwner) => void;
}> = ({ contactAPI, updateItem }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Button onClick={() => setIsOpen(true)} variant={'contained'}>
                {txt({ en: 'Modify', ja: '設定' })}
            </Button>
            <MutationFormModalForBankAccount
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={txt({
                    en: `Modify ${contactAPI.name}'s bank account`,
                    ja: `${contactAPI.name}の銀行口座を設定`,
                })}
                mutation={{
                    description: txt({
                        en: "Modify contact's bank account",
                        ja: 'コンタクトの銀行口座を設定',
                    }),
                    run: async (bankAccount) => {
                        const result = await contactAPI.modifyExternalBankAccount(bankAccount);
                        updateItem(contactAPI);
                        return result;
                    },
                }}
                submitText={txt({ en: 'Update', ja: '設定' })}
                bankAccount={contactAPI.externalBankAccount}
            />
        </>
    );
};
ModifyContactBankAccountButton.displayName = 'ModifyContactBankAccountButton';
