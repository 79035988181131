import { FC, createRef } from 'react';

import { UodkaInvoicesClientForGeneral, UodkaInvoicesClientForOwner } from '@/utils/UodkaClients/Invoices';
import { useLanguage, ContactItem, useClientData, useClientProfile } from '@/utils/customHooks';
import Drawer from '@/components/0_atom/Drawer';

import { AddBillDrawerContent } from '@/views/bills/AddBillDrawer/AddBillDrawerContent';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
}));

export const AddBillDrawer: FC<{
    invoicesClient: UodkaInvoicesClientForGeneral | UodkaInvoicesClientForOwner;
    refetchIssuedBills: () => Promise<void>;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    contactItems?: ContactItem[];
    currentContactItem?: ContactItem;
}> = ({ invoicesClient, refetchIssuedBills, isOpen, setIsOpen, contactItems, currentContactItem }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const { clientData } = useClientData();
    const { clientProfile } = useClientProfile();

    // REFS
    const drawerPaperRef = createRef<HTMLDivElement>();

    return (
        <Drawer
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            backText={txt({ en: 'Back to bills', ja: '一覧に戻る' })}
            title={txt({ en: 'New bill', ja: '新しい請求' })}
            paperRef={drawerPaperRef}
        >
            {clientData && clientProfile !== 'fetching' && contactItems ? (
                <AddBillDrawerContent
                    {...{
                        invoicesClient,
                        refetchIssuedBills,
                        onClose: () => setIsOpen(false),
                        contactItems,
                        initialContactItem: currentContactItem,
                        clientData,
                        clientProfile,
                        drawerPaperRef,
                    }}
                />
            ) : (
                <>{'Fetching'}</>
            )}
        </Drawer>
    );
};
AddBillDrawer.displayName = 'AddBillDrawer';
