import { FC, useState } from 'react';

import {
    UodkaCoreClientForGeneral,
    UodkaCoreClientForOwner,
    ContactAPIForOwner,
    TransferConfirmationAPIForGeneral,
} from '@/utils/UodkaClients/Core';
import { useLanguage, ContactItem, useClientData } from '@/utils/customHooks';
import { MutationFormModal } from '@/components/1_mol/FormModal';
import { IntInput } from '@/components/0_atom/Input';

import { useTransferInformations } from '@/views/transactions/AddTransferModal/useTransferInformations';
import { SelectContactButton } from '@/views/transactions/AddTransferModal/SelectContactButton';
import { getFormMessages } from '@/views/transactions/AddTransferModal/getFormMessages';
import { TransferConfirmationModal } from '@/views/transactions/AddTransferModal/TransferConfirmationModal';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    selectRemitteeFirst: {
        padding: '22px 0',
        color: theme.palette.text.disabled,
        fontWeight: 600,
        fontSize: theme.typography.body2.fontSize,
    },
    message: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& span:first-child': {},
        '& span:nth-child(2)': {
            width: 60,
            textAlign: 'right',
        },
    },
}));

export const AddTransferModal: FC<{
    coreClient: UodkaCoreClientForGeneral | UodkaCoreClientForOwner;
    refetchBalances: () => Promise<void>;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}> = ({ coreClient, refetchBalances, isOpen, setIsOpen }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const { clientData } = useClientData();
    const { fees, currentBalance } = useTransferInformations();
    const [selectedContactItem, setSelectedContactItem] = useState<ContactItem<ContactAPIForOwner> | undefined>(
        undefined
    );
    const [amount, setAmount] = useState(0);
    const [transferConfirmationAPI, setTransferConfirmationAPI] = useState<
        TransferConfirmationAPIForGeneral | undefined
    >(undefined);

    // DATA
    const transferFee = (() => {
        if (selectedContactItem?.contact.transferDestination && fees) {
            return selectedContactItem.contact.transferDestination.isDepositAccount
                ? fees.transfer.internal
                : fees.transfer.external;
        }
        return undefined;
    })();
    const isAmountSatisfied = (() => {
        if (currentBalance !== undefined && transferFee && amount) {
            return amount + transferFee.total <= currentBalance;
        }
        return false;
    })();
    const runAddTransferConfirmation = async () => {
        if (
            selectedContactItem?.contact &&
            fees &&
            isAmountSatisfied &&
            clientData &&
            selectedContactItem.contact.transferDestination
        ) {
            const {
                result,
                transferConfirmationAPI: createdTransferConfirmationAPI,
            } = await selectedContactItem.contact.addTransferConfirmation({
                amount,
                remitterNameKana: clientData.remitterNameKana,
            });
            setTransferConfirmationAPI(createdTransferConfirmationAPI);
            return result;
        }
        throw new Error(
            'AddTransferModal.runAddTransferConfirmation: !(selectedContactItem.contact && fee && selectedContactItem.contact.bankAccount && isAmountSatisfied)'
        );
    };

    // HANDLER
    const initializeForm = () => {
        setSelectedContactItem(undefined);
        setAmount(0);
    };

    return (
        <>
            <MutationFormModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={txt({ en: 'New Remittance', ja: '新しい送金' })}
                mutation={{
                    description: txt({
                        en: 'Confirm new remittance',
                        ja: '新しい送金の内容を確認',
                    }),
                    run: runAddTransferConfirmation,
                }}
                items={[
                    {
                        isRequired: true,
                        labelText: txt({ en: 'Remittee', ja: '送金先' }),
                        isSatisfied: !!selectedContactItem?.contact.transferDestination,
                        isError: selectedContactItem?.contact && !selectedContactItem.contact.transferDestination,
                        children: (
                            <SelectContactButton
                                selectedContactItem={selectedContactItem}
                                setSelectedContactItem={setSelectedContactItem}
                            />
                        ),
                    },
                    {
                        isRequired: true,
                        labelText: txt({ en: 'Amount', ja: '金額' }),
                        isSatisfied: isAmountSatisfied,
                        isError: !!amount && !isAmountSatisfied,
                        children: selectedContactItem ? (
                            <IntInput
                                fullWidth
                                startAdornment={'¥'}
                                value={amount}
                                setValue={(newAmount) => setAmount(newAmount || 0)}
                                isSatisfied={isAmountSatisfied}
                                messages={getFormMessages({
                                    messageClassName: c.message,
                                    txt,
                                    transferFee,
                                    amount,
                                    balance: currentBalance,
                                    isAmountSatisfied,
                                })}
                            />
                        ) : (
                            <div className={c.selectRemitteeFirst}>
                                {txt({ en: 'Select remittee first.', ja: '先に送金先を選択してください。' })}
                            </div>
                        ),
                    },
                ]}
                submitText={txt({ en: 'Continue', ja: '次へ' })}
            />
            {selectedContactItem && clientData && typeof currentBalance === 'number' && (
                <TransferConfirmationModal
                    refetchBalances={refetchBalances}
                    transferConfirmationAPI={transferConfirmationAPI}
                    setTransferConfirmationAPI={setTransferConfirmationAPI}
                    selectedContactItem={selectedContactItem}
                    clientData={clientData}
                    currentBalance={currentBalance}
                    initializeForm={initializeForm}
                />
            )}
        </>
    );
};
AddTransferModal.displayName = 'AddTransferModal';
