import { forwardRef, ComponentProps } from 'react';

import { TextMenuItem, TextMenuItemProps } from '@/components/0_atom/Menu/TextMenuItem';

import MuiMenu from '@material-ui/core/Menu';

export const TextMenu = forwardRef<
    HTMLElement,
    ComponentProps<typeof MuiMenu> & {
        items: TextMenuItemProps[];
        emptyPlaceHolder: string;
    }
>(({ items, onClose, emptyPlaceHolder, ...otherProps }, ref) => {
    // DATA
    const isWithIcon = !!items.find((item) => item.icon || item.isSelected);
    return (
        <MuiMenu ref={ref} {...otherProps} onClose={onClose}>
            {items.length > 0 ? (
                items.map((item, i) => (
                    <TextMenuItem
                        key={i}
                        {...item}
                        onClick={() => {
                            item.onClick && item.onClick();
                            onClose && onClose({}, 'backdropClick');
                        }}
                        isWithIcon={isWithIcon}
                    />
                ))
            ) : (
                <TextMenuItem isDisabled text={emptyPlaceHolder} isWithIcon={false} />
            )}
        </MuiMenu>
    );
});
TextMenu.displayName = 'TextMenu';
