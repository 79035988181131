import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.6234 1.80307L10.2337 0.384426C9.99261 0.138284 9.66558 1.82021e-06 9.32459 0L4.71429 0C4.0042 0 3.42857 0.587617 3.42857 1.3125V2.625H1.28571C0.575625 2.625 0 3.21262 0 3.9375V12.6875C0 13.4124 0.575625 14 1.28571 14H7.28571C7.9958 14 8.57143 13.4124 8.57143 12.6875V11.375H10.7143C11.4244 11.375 12 10.7874 12 10.0625V2.73115C12 2.38305 11.8645 2.04921 11.6234 1.80307ZM9.42857 0.888426C9.50393 0.907407 9.57276 0.947075 9.62762 1.00313L11.0173 2.42181C11.0722 2.47781 11.1111 2.54807 11.1297 2.625H9.42857V0.888426ZM7.71429 12.6875C7.71429 12.9287 7.52202 13.125 7.28571 13.125H1.28571C1.04941 13.125 0.857143 12.9287 0.857143 12.6875V3.9375C0.857143 3.69627 1.04941 3.5 1.28571 3.5H3.42857V10.0625C3.42857 10.7874 4.0042 11.375 4.71429 11.375H7.71429V12.6875ZM11.1429 10.0625C11.1429 10.3037 10.9506 10.5 10.7143 10.5H4.71429C4.47798 10.5 4.28571 10.3037 4.28571 10.0625V1.3125C4.28571 1.07127 4.47798 0.875 4.71429 0.875H8.57143V2.84375C8.57143 3.20469 8.86071 3.5 9.21429 3.5H11.1429V10.0625Z"
                fill="currentColor"
            />
        </svg>
    );
};
Svg.displayName = 'svg/fa-copy-light';
export default Svg;
