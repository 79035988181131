import { FC } from 'react';

import { useLanguage } from '@/utils/customHooks';

import SvgEllipsis from '@/svgs/ellipsis';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    '@keyframes dot': {
        '0%': {
            color: 'rgba(0,0,0,.2)',
        },
        '100%': {
            color: 'rgba(0,0,0,0)',
        },
    },
    inner: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            width: 2,
            '&:not(:last-child)': {
                marginRight: 3,
            },
        },
    },
    ellipsis: {},
    text: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.disabled,
        fontWeight: 600,
    },
    dots: {
        marginLeft: 4,
        color: theme.palette.text.disabled,
        '& svg:nth-child(1)': {
            animation: '$dot .8s linear infinite',
        },
        '& svg:nth-child(2)': {
            animation: '$dot .8s linear infinite',
            animationDelay: '0.2s',
        },
        '& svg:nth-child(3)': {
            animation: '$dot .8s linear infinite',
            animationDelay: '0.4s',
        },
        '& svg:nth-child(4)': {
            animation: '$dot .8s linear infinite',
            animationDelay: '0.6s',
        },
    },
}));

const FetchingText: FC<{
    className?: string;
    text?: string;
}> = ({ className, text }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    return (
        <div className={className}>
            <div className={c.inner}>
                <span className={c.text}>{text || txt({ en: 'Fetching', ja: '取得中' })}</span>
                <span className={c.dots}>
                    <SvgEllipsis className={c.ellipsis} />
                    <SvgEllipsis className={c.ellipsis} />
                    <SvgEllipsis className={c.ellipsis} />
                    <SvgEllipsis className={c.ellipsis} />
                </span>
            </div>
        </div>
    );
};
FetchingText.displayName = 'FetchingText';
export default FetchingText;
