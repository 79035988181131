import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 600,
    },
}));

const ErrorDisplay: FC<{ errorCode: 404 | 500 }> = ({ errorCode }) => {
    // STYLE
    const c = useStyles(useTheme());

    return <div className={c.root}>Error: {errorCode}</div>;
};
ErrorDisplay.displayName = 'ErrorDisplay';
export default ErrorDisplay;
