import { FC, useState } from 'react';

import { UodkaCoreClientForOwner } from '@/utils/UodkaClients/Core';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { getShortenedString } from '@/utils/utilFunctions';
import { getEnvVariables } from '@/utils/envVariables';
import Table from '@/components/0_atom/Table';
import CopyableText from '@/components/0_atom/CopyableText';

import { useInviteItems } from '@/views/config/business/Member/useInviteItems';
import { DeleteInviteButton } from '@/views/config/business/Member/DeleteInviteButton';
import { AddInviteModal } from '@/views/config/business/Member/AddInviteModal';
import { getRoleText } from '@/views/config/business/Member/getRoleText';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 44,
        // paddingTop: 14,
        // borderTop: 'solid 2px rgba(0,0,0,.03)',
    },
    table: {
        // marginTop: 35,
    },
}));

export const InvitesTable: FC<{
    coreClient: UodkaCoreClientForOwner;
}> = ({ coreClient }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { currentBusiness } = useBusinessCtxAbsolutely();
    const { txt, getDateStr } = useLanguage();
    const { items, refetchInvites } = useInviteItems({ coreClient });
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);

    return (
        <div className={c.root}>
            <Table
                title={txt({ en: 'Valid Invites', ja: '有効な招待' })}
                emptyPlaceholder={txt({ en: 'No invites', ja: '招待がありません' })}
                widths={[`calc(100% - ${80 + 94 + 94 + 64}px)`, 80, 94, 94, 64]}
                className={c.table}
                headItems={[
                    { label: txt({ en: 'URL', ja: '招待URL' }) },
                    { label: txt({ en: 'Role', ja: '権限' }) },
                    { label: txt({ en: 'Expires on', ja: '期限' }) },
                    { label: txt({ en: 'Remaining quota', ja: '残り人数' }) },
                ]}
                rowItems={items?.ids.map((id) => {
                    const { inviteAPI } = items.getItemAbsolutely(id);
                    const inviteUrl = `${getEnvVariables().inviteRootUrl}/member/${currentBusiness.id}/${
                        inviteAPI.codeText
                    }`;
                    return {
                        items: [
                            {
                                children: (
                                    <CopyableText
                                        copyText={inviteUrl}
                                        displayText={getShortenedString({ text: inviteUrl, maxLangth: 20 })}
                                    />
                                ),
                            },
                            {
                                children: getRoleText({ role: inviteAPI.role, txt }),
                            },
                            {
                                children: getDateStr(inviteAPI.expireAt),
                            },
                            {
                                children: inviteAPI.remainingSeats,
                            },
                            {
                                children: (
                                    <DeleteInviteButton inviteAPI={inviteAPI} removeInviteItem={items.removeItem} />
                                ),
                            },
                        ],
                    };
                })}
                addItem={{
                    text: txt({ en: 'Issue invite', ja: '招待URLを発行' }),
                    onClick: () => setIsAddModalOpen(true),
                }}
            />
            <AddInviteModal {...{ isOpen: isAddModalOpen, setIsOpen: setIsAddModalOpen, coreClient, refetchInvites }} />
        </div>
    );
};
InvitesTable.displayName = 'InvitesTable';
