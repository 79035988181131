import { BusinessMemberRole } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { getGraphqlSdk } from '@/utils/UodkaClients/Profile/graphql/graphqlSdk';
import {
    UodkaProfileClientForObserver,
    UodkaProfileClientForGeneral,
    UodkaProfileClientForOwner,
    UodkaProfileClientForAnonymous,
} from '@/utils/UodkaClients/Profile/clients';

export const initUodkaProfileClientForAnonymous = () => {
    const graphqlSdk = getGraphqlSdk({ firebaseUserIdToken: undefined });
    return new UodkaProfileClientForAnonymous({
        graphqlSdk,
    });
};

export const initUodkaProfileClientForObserver = ({
    firebaseUserIdToken,
    clientId,
}: {
    firebaseUserIdToken: string;
    clientId: string;
}) => {
    const graphqlSdk = getGraphqlSdk({ firebaseUserIdToken });
    return new UodkaProfileClientForObserver({
        graphqlSdk,
        businessId: clientId,
    });
};

export const initUodkaProfileClientForGeneral = ({
    firebaseUserIdToken,
    clientId,
}: {
    firebaseUserIdToken: string;
    clientId: string;
}) => {
    const graphqlSdk = getGraphqlSdk({ firebaseUserIdToken });
    return new UodkaProfileClientForGeneral({
        graphqlSdk,
        businessId: clientId,
    });
};

export const initUodkaProfileClientForOwner = ({
    firebaseUserIdToken,
    clientId,
}: {
    firebaseUserIdToken: string;
    clientId: string;
}) => {
    const graphqlSdk = getGraphqlSdk({ firebaseUserIdToken });
    return new UodkaProfileClientForOwner({
        graphqlSdk,
        businessId: clientId,
    });
};

export const initUodkaProfileClient = ({
    firebaseUserIdToken,
    clientId,
    role,
}: {
    firebaseUserIdToken?: string;
    clientId?: string;
    role: BusinessMemberRole;
}) => {
    if (clientId) {
        if (role === 'owner') {
            if (!firebaseUserIdToken) {
                throw new Error('initUodkaProfileClient: missing firebaseUserIdToken');
            }
            return initUodkaProfileClientForOwner({
                firebaseUserIdToken,
                clientId,
            });
        }
        if (role === 'general') {
            if (!firebaseUserIdToken) {
                throw new Error('initUodkaProfileClient: missing firebaseUserIdToken');
            }
            return initUodkaProfileClientForGeneral({
                firebaseUserIdToken,
                clientId,
            });
        }
        if (role === 'observer') {
            if (!firebaseUserIdToken) {
                throw new Error('initUodkaProfileClient: missing firebaseUserIdToken');
            }
            return initUodkaProfileClientForObserver({
                firebaseUserIdToken,
                clientId,
            });
        }
    }
    return initUodkaProfileClientForAnonymous();
};
