import {
    AggregatedTax as GqlAggregatedTax,
    DecimalRoundRule,
} from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';

interface AggregatedTaxInterface {
    title: string;
    taxableValue: number;
    decimalRoundRule: DecimalRoundRule;
    value: number;
}

export class AggregatedTax implements AggregatedTaxInterface {
    protected _title: string;

    protected _taxableValue: number;

    protected _decimalRoundRule: DecimalRoundRule;

    protected _value: number;

    get title() {
        return this._title;
    }

    get taxableValue() {
        return this._taxableValue;
    }

    get decimalRoundRule() {
        return this._decimalRoundRule;
    }

    get value() {
        return this._value;
    }

    get data() {
        return {
            title: this._title,
            taxableValue: this._taxableValue,
            decimalRoundRule: this._decimalRoundRule,
            value: this._value,
        };
    }

    constructor({ title, taxableValue, decimalRoundRule, value }: AggregatedTaxInterface) {
        this._title = title;
        this._taxableValue = taxableValue;
        this._decimalRoundRule = decimalRoundRule;
        this._value = value;
    }
}

export const getAggregatedTaxFromGql = ({ title, taxableValue, decimalRoundRule, value }: GqlAggregatedTax) => {
    return new AggregatedTax({ title, taxableValue, decimalRoundRule, value });
};
