import { IssuedBill } from '@/utils/UodkaClients/Invoices';

type IssuedBillStatustype = 'processing' | 'completed' | 'error';
export type IssuedBillStatus = {
    title: string;
    textForCurrent: string;
    date: Date;
    type: IssuedBillStatustype;
    description?: string;
    priority: number;
    section: 'bill' | 'payment';
};
export const getIssuedBillStatusHistory = ({
    bill,
    txt,
}: {
    bill: IssuedBill;
    txt: (multilingualTexts: { en: string; ja?: string | undefined }) => string;
}): IssuedBillStatus[] => {
    const { payment, signatures } = bill;
    const arr: IssuedBillStatus[] = [
        {
            title: txt({ en: 'Issued', ja: '発行' }),
            textForCurrent: txt({ en: 'Issued', ja: '発行' }),
            type: 'completed',
            description: undefined,
            date: signatures.issuer.issue.issuedAt,
            priority: 0,
            section: 'bill',
        },
    ];
    if (signatures.recipient.receive) {
        arr.push({
            title: txt({ en: 'Recipient received', ja: '相手が受領' }),
            textForCurrent: txt({ en: 'Received', ja: '受領' }),
            type: 'completed',
            description: undefined,
            date: signatures.recipient.receive.billReceivedAt,
            priority: 1,
            section: 'bill',
        });
    }
    if (payment) {
        arr.push({
            title: txt({ en: 'Payment process started', ja: '支払い手続きが開始' }),
            textForCurrent: txt({ en: 'Processing', ja: '手続き中' }),
            type: 'processing',
            description: undefined,
            date: payment.incomingTransfer.createdAt,
            priority: 2,
            section: 'payment',
        });
    }
    if (payment?.charge) {
        arr.push({
            title: txt({ en: 'Payment received', ja: '支払いの受け取りを完了' }),
            textForCurrent: txt({ en: 'Received', ja: '受取完了' }),
            type: 'completed',
            description: undefined,
            date: payment.charge.createdAt,
            priority: 3,
            section: 'payment',
        });
    }
    if (payment?.incomingTransfer.error) {
        arr.push({
            title: txt({ en: 'Payment failed', ja: '支払いエラー' }),
            textForCurrent: txt({ en: 'Failed', ja: 'エラー' }),
            type: 'error',
            description: txt({
                en: 'Transfer has failed. Please check with the remitter.',
                ja: '振り込みが失敗しています。請求先に確認してください。',
            }),
            date: payment.incomingTransfer.error.setAt,
            priority: 4,
            section: 'payment',
        });
    }
    if (signatures.issuer.revoke) {
        arr.push({
            title: txt({ en: 'Revoked', ja: '請求を破棄' }),
            textForCurrent: txt({ en: 'Revoked', ja: '破棄' }),
            type: 'error',
            description: undefined,
            date: signatures.issuer.revoke.agreeToRevokeAt,
            priority: 5,
            section: 'bill',
        });
    }
    if (signatures.recipient.revoke) {
        arr.push({
            title: txt({ en: 'Recipient revoked', ja: '相手が請求を破棄' }),
            textForCurrent: txt({ en: 'Revoked', ja: '相手が破棄' }),
            type: 'error',
            description: undefined,
            date: signatures.recipient.revoke.agreeToRevokeAt,
            priority: 5,
            section: 'bill',
        });
    }
    return arr.sort((a, b) => {
        if (a.priority > b.priority) {
            return 1;
        }
        if (a.priority < b.priority) {
            return -1;
        }
        // 同じ場合
        const aTime = a.date.getTime();
        const bTime = b.date.getTime();
        if (aTime > bTime) {
            return 1;
        }
        if (aTime < bTime) {
            return -1;
        }
        // 同じ場合
        return 1;
    });
};
