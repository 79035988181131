import { useLocation, useHistory } from 'react-router-dom';

import { useErrorCtx } from '@/utils/ctxs';
import { ViewName } from '@/utils/customHooks/useView';

interface Routing {
    businessId?: string;
    viewName?: ViewName;
    viewSlug1?: string;
    viewSlug2?: string;
    viewSlug3?: string;
}

export const useRouting = () => {
    // HOOKS
    const { pathname } = useLocation();
    const { push } = useHistory();
    const { setIs404 } = useErrorCtx();

    const routing: Routing = (() => {
        const matchArr = pathname.match(/^\/([^/?#]+)?\/?([^/?#]+)?\/?([^/?#]+)?\/?([^/?#]+)?\/?([^/?#]+)?/);
        if (matchArr) {
            const [_, businessId, viewNameStr, viewSlug1, viewSlug2, viewSlug3]: (string | undefined)[] = matchArr;
            const viewName = (() => {
                if (viewNameStr) {
                    if (['transactions', 'contacts', 'bills', 'records', 'config'].includes(viewNameStr)) {
                        return viewNameStr as ViewName;
                    }
                    setIs404(true);
                }
                return undefined;
            })();
            return {
                businessId,
                viewName,
                viewSlug1,
                viewSlug2,
                viewSlug3,
            };
        }
        return {};
    })();

    // HANDLER
    const switchBusinessId = ({ businessId }: { businessId: string }) => {
        push(`/${businessId}`);
    };
    const switchView = ({ businessId, viewName }: { businessId: string; viewName: ViewName }) => {
        push(`/${businessId}/${viewName}`);
    };
    const setViewSlug1 = <T1 extends string = string>({ slug1 }: { slug1?: T1 }) => {
        const { businessId, viewName, viewSlug1 } = routing;
        if (!viewName || !viewSlug1) {
            setIs404(true);
        }
        if (slug1) {
            push(`/${businessId}/${viewName}/${slug1}`);
        } else {
            push(`/${businessId}/${viewName}`);
        }
    };
    const setViewSlug2 = <T1 extends string = string, T2 extends string = string>({
        slug1,
        slug2,
    }: {
        slug1: T1;
        slug2?: T2;
    }) => {
        const { businessId, viewName, viewSlug1 } = routing;
        if (!viewName || !viewSlug1) {
            setIs404(true);
        }
        if (slug2) {
            push(`/${businessId}/${viewName}/${slug1}/${slug2}`);
        } else {
            push(`/${businessId}/${viewName}/${slug1}`);
        }
    };
    const setViewSlug3 = <T1 extends string = string, T2 extends string = string, T3 extends string = string>({
        slug1,
        slug2,
        slug3,
    }: {
        slug1: T1;
        slug2: T2;
        slug3?: T3;
    }) => {
        const { businessId, viewName, viewSlug1 } = routing;
        if (!viewName || !viewSlug1) {
            setIs404(true);
        }
        if (slug3) {
            push(`/${businessId}/${viewName}/${slug1}/${slug2}/${slug3}`);
        } else {
            push(`/${businessId}/${viewName}/${slug1}/${slug2}`);
        }
    };

    return {
        ...routing,
        switchBusinessId,
        switchView,
        setViewSlug1,
        setViewSlug2,
        setViewSlug3,
    };
};
