export const getCSV = (input: (string | number | Date | undefined)[][]): string => {
    const rows = input.map((row) =>
        row.reduce((acc: string, prev, i) => {
            const stringifiedPrv = (() => {
                if (typeof prev === 'string') {
                    const escaped = prev.replaceAll('"', "'");
                    return `"${escaped}"`;
                }
                if (prev instanceof Date) {
                    return prev.toISOString();
                }
                if (!prev) {
                    return '';
                }
                return String(prev);
            })();
            if (i === 0) {
                return stringifiedPrv;
            }
            return `${acc},${stringifiedPrv}`;
        }, '')
    );
    return rows.reduce((acc, prev, i) => {
        if (i === 0) {
            return prev;
        }
        return `${acc}\n${prev}`;
    }, '');
};
