import { ContactInTransfer as GqlContactInTransfer } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { ClientDataInTransfer, getClientDataInTransferFromGql } from '@/utils/UodkaClients/Core/models/ClientData';

interface ContactInTransferInterface {
    id: string;
    additionalId?: string;
    name: string;
    relatedBusiness?: ClientDataInTransfer;
    isArchived: boolean;
}

export class ContactInTransfer implements ContactInTransferInterface {
    protected _id: string;

    protected _additionalId?: string;

    protected _name: string;

    protected _relatedBusiness?: ClientDataInTransfer;

    protected _isArchived: boolean;

    get id() {
        return this._id;
    }

    get additionalId() {
        return this._additionalId;
    }

    get name() {
        return this._name;
    }

    get relatedBusiness() {
        return this._relatedBusiness;
    }

    get isArchived() {
        return this._isArchived;
    }

    get data() {
        return {
            id: this.id,
            additionalId: this.additionalId,
            name: this.name,
            relatedBusiness: this.relatedBusiness,
            isArchived: this.isArchived,
        };
    }

    constructor({ id, additionalId, name, relatedBusiness, isArchived }: ContactInTransferInterface) {
        this._id = id;
        this._additionalId = additionalId;
        this._name = name;
        this._relatedBusiness = relatedBusiness;
        this._isArchived = isArchived;
    }
}

export const getContactInTransferFromGql = ({
    id,
    additionalId,
    name,
    relatedBusiness,
    isArchived,
}: GqlContactInTransfer) => {
    return new ContactInTransfer({
        id,
        additionalId: additionalId || undefined,
        name,
        relatedBusiness: relatedBusiness ? getClientDataInTransferFromGql(relatedBusiness) : undefined,
        isArchived,
    });
};
