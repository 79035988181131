import { FC, useState } from 'react';

import { BusinessProfileAPIForOwner } from '@/utils/UodkaClients/Profile';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage, useFormField } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import { MutationFormModal } from '@/components/1_mol/FormModal';
import { TextInput } from '@/components/0_atom/Input';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({}));

export const ModifyEmailButton: FC<{
    profileAPI: BusinessProfileAPIForOwner;
    setProfile: (clientData: BusinessProfileAPIForOwner) => void;
}> = ({ profileAPI, setProfile }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { currentBusiness } = useBusinessCtxAbsolutely();
    const { txt } = useLanguage();
    const [isOpen, setIsOpen] = useState(false);
    const [editingEmail, setEditingEmail] = useFormField(profileAPI.email);

    // MUTATION
    const run = async () => {
        const result = await profileAPI.modifyEmail(editingEmail);
        setProfile(profileAPI);
        return result;
    };

    return (
        <>
            <Button onClick={() => setIsOpen(true)} variant={'contained'}>
                {txt({ en: 'Change', ja: '変更' })}
            </Button>
            <MutationFormModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={txt({
                    en: `Modify ${currentBusiness.name}'s profile`,
                    ja: `${currentBusiness.name}のプロフィールを編集`,
                })}
                mutation={{
                    run,
                    description: txt({
                        en: `Modify ${currentBusiness.name}'s profile`,
                        ja: `${currentBusiness.name}のプロフィールを編集`,
                    }),
                }}
                items={[
                    {
                        isRequired: false,
                        labelText: txt({ en: 'Public email', ja: '公開メールアドレス' }),
                        isSatisfied: true,
                        isError: undefined,
                        children: (
                            <TextInput
                                fullWidth
                                placeholder={txt({ en: 'Public email', ja: '公開メールアドレス' })}
                                value={editingEmail}
                                setValue={(newValue) => setEditingEmail(newValue || '')}
                                isSatisfied={true}
                                messages={[
                                    {
                                        message: txt({
                                            en:
                                                'Anyone can see the items in your profile. Please enter only public information.',
                                            ja:
                                                'プロフィールの項目は誰でも確認することができます。公開情報のみを入力してください。',
                                        }),
                                    },
                                ]}
                            />
                        ),
                    },
                ]}
                submitText={txt({ en: 'Update', ja: '変更' })}
            />
        </>
    );
};
ModifyEmailButton.displayName = 'views/config/business/Profile/ModifyEmailButton';
