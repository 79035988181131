import { createContext, useContext } from 'react';

import { UodkaUserClient, MemberInUser } from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';

export interface MemberInUserWithBusinessProfile {
    member: MemberInUser;
    businessProfile?: BusinessProfile;
}
interface UserCtxType {
    userClient?: UodkaUserClient;
    memberWithBusinessProfiles?: MemberInUserWithBusinessProfile[];
    isFetching: boolean;
    reInitialize: () => void;
    signOut: () => void;
    firebaseUser?: {
        uid: string;
        email: string;
        idToken: string;
    };
    isFirebaseError?: boolean;
}
export const UserCtx = createContext<UserCtxType>({
    isFetching: true,
    reInitialize: () => undefined,
    signOut: () => undefined,
});
export const useUserCtx = () => {
    return useContext(UserCtx);
};
export const useUserCtxAbsolutely = () => {
    const { userClient, memberWithBusinessProfiles, firebaseUser, ...otherProps } = useUserCtx();
    if (!userClient || !memberWithBusinessProfiles || !firebaseUser) {
        throw new Error('useUserCtxAbsolutely: !userClient || !memberWithBusinessProfiles || !firebaseUser');
    }
    return { userClient, memberWithBusinessProfiles, firebaseUser, ...otherProps };
};
