import { ContactInInvite as GqlContactInInvite } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';

interface ContactInInviteInterface {
    id: string;
    additionalId?: string;
    name: string;
}

export class ContactInInvite implements ContactInInviteInterface {
    protected _id: string;

    protected _additionalId?: string;

    protected _name: string;

    get id() {
        return this._id;
    }

    get additionalId() {
        return this._additionalId;
    }

    get name() {
        return this._name;
    }

    get data() {
        return {
            id: this.id,
            additionalId: this.additionalId,
            name: this.name,
        };
    }

    constructor({ id, additionalId, name }: ContactInInviteInterface) {
        this._id = id;
        this._additionalId = additionalId;
        this._name = name;
    }
}

export const getContactInInviteFromGql = ({ id, additionalId, name }: GqlContactInInvite) => {
    return new ContactInInvite({
        id,
        additionalId: additionalId || undefined,
        name,
    });
};
