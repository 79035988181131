import {
    Language,
    BusinessInContact as GqlBusinessInContact,
} from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { ExternalBankAccount, getExternalBankAccountFromGql } from '@/utils/UodkaClients/Core/models/BankAccount';
import {
    DepositAccountInContact,
    getDepositAccountInContactFromGql,
} from '@/utils/UodkaClients/Core/models/DepositAccount';
import { getKana } from '@/utils/UodkaClients/Core/utils';

type ClientDataInContactInterface = {
    id: string;
    name: string;
    remitterNameKana: string;
    depositAccountFromMeToContact?: DepositAccountInContact;
    externalBankAccount?: ExternalBankAccount;
    language?: Language;
    isArchived: boolean;
    relatedContactId: string;
};

export class ClientDataInContact implements ClientDataInContactInterface {
    protected _id: string;

    protected _name: string;

    protected _remitterNameKana: string;

    protected _depositAccountFromMeToContact?: DepositAccountInContact;

    protected _externalBankAccount?: ExternalBankAccount;

    protected _language?: Language;

    protected _isArchived: boolean;

    protected _relatedContactId: string;

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get remitterNameKana() {
        const kana = getKana(this._remitterNameKana);
        if (!kana) {
            throw new Error('ClientData.remitterNameKana: unexpectedly got invalid value.');
        }
        return this._remitterNameKana;
    }

    get depositAccountFromMeToContact() {
        return this._depositAccountFromMeToContact;
    }

    get externalBankAccount() {
        return this._externalBankAccount;
    }

    get language() {
        return this._language;
    }

    get isArchived() {
        return this._isArchived;
    }

    get relatedContactId() {
        return this._relatedContactId;
    }

    constructor({
        id,
        name,
        remitterNameKana,
        depositAccountFromMeToContact,
        externalBankAccount,
        language,
        isArchived,
        relatedContactId,
    }: ClientDataInContactInterface) {
        this._id = id;
        this._name = name;
        this._remitterNameKana = remitterNameKana;
        this._depositAccountFromMeToContact = depositAccountFromMeToContact;
        this._externalBankAccount = externalBankAccount;
        this._language = language;
        this._isArchived = isArchived;
        this._relatedContactId = relatedContactId;
    }
}

export const getClientDataInContactFromGql = ({
    id,
    name,
    remitterNameKana,
    depositAccountFromMeToContact,
    externalBankAccount,
    language,
    isArchived,
    relatedContactId,
}: GqlBusinessInContact) => {
    return new ClientDataInContact({
        id,
        name,
        remitterNameKana,
        depositAccountFromMeToContact: depositAccountFromMeToContact
            ? getDepositAccountInContactFromGql(depositAccountFromMeToContact)
            : undefined,
        externalBankAccount: externalBankAccount ? getExternalBankAccountFromGql(externalBankAccount) : undefined,
        language: language || undefined,
        isArchived,
        relatedContactId,
    });
};
