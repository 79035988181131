/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useReducer } from 'react';

import { HealthCtx, ServiceHealthWithFetchStatus, ServiceHealth, useErrorCtx } from '@/utils/ctxs';
import { UodkaCoreServiceHealth } from '@/utils/UodkaClients/Core';
import { UodkaProfileServiceHealth } from '@/utils/UodkaClients/Profile';
import { UodkaCertServiceHealth } from '@/utils/UodkaClients/Cert';
import { UodkaInvoicesServiceHealth } from '@/utils/UodkaClients/Invoices';

const getIsHealthEqual = (health1: ServiceHealth, health2: ServiceHealth) => {
    const { code: code1, message: message1, url: url1 } = health1;
    const { code: code2, message: message2, url: url2 } = health2;
    return code1 === code2 && message1 === message2 && url1 === url2;
};

export const useHealth = <
    T extends UodkaCoreServiceHealth | UodkaProfileServiceHealth | UodkaCertServiceHealth | UodkaInvoicesServiceHealth
>(
    getHealth: () => Promise<T>
) => {
    // HOOKS
    const { setIs500 } = useErrorCtx();
    const [health, setHealth] = useState<ServiceHealthWithFetchStatus<T>>({
        fetchStatus: 'fetching',
    });
    const [fetchCounter, addFetchCounter] = useReducer<(state: number) => number>((state) => state + 1, 0);

    // USEEFFECT (INTERVAL)
    useEffect(() => {
        setTimeout(
            async () => {
                const serviceHealth = await getHealth().catch(() => {
                    if (health.fetchStatus !== 'fetchError') {
                        setHealth({
                            fetchStatus: 'fetchError',
                        });
                    }
                });
                if (serviceHealth) {
                    if (!(health.health && getIsHealthEqual(health.health, serviceHealth))) {
                        setHealth({
                            health: serviceHealth,
                            fetchStatus: 'fetched',
                        });
                    }
                }
                addFetchCounter();
            },
            fetchCounter === 0 ? 0 : 5000
        );
    }, [fetchCounter]);
    useEffect(() => {
        if (health.health && health.health.code !== 'ok') {
            setIs500(true);
        }
    }, [health]);

    return health;
};
