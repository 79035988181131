/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useMemo } from 'react';

type RecordWithId = Record<string, any> & {
    id: string;
};
export type ItemType<T extends Record<string, any>> = T & {
    id: string;
};
export type UseItems<T extends RecordWithId> = (
    items: ItemType<T>[]
) => {
    ids: string[];
    currentItem: ItemType<T> | undefined;
    getItem: (id: string) => ItemType<T> | undefined;
    getItemAbsolutely: (id: string) => ItemType<T>;
    setSelectedItem: (id?: string | undefined) => void;
    updateItem: (newItem: ItemType<T>) => void;
    removeItem: (id: string) => void;
};

export const useItems = <T extends RecordWithId>(items: ItemType<T>[]) => {
    // HOOKS
    const [ids, setIds] = useState<string[]>(items.map((item) => item.id));
    const [itemDatas, setItemDatas] = useState(items);
    const [currentItemData, setCurrentItemData] = useState<ItemType<T> | undefined>(undefined);

    // USEEFFECT
    useEffect(() => {
        setIds(items.map((item) => item.id));
        setItemDatas(items);
    }, [items]);

    // DATA
    const getItemData = (id: string) => {
        return itemDatas.find((item) => item.id === id);
    };
    const getItemDataAbsolutely = (id: string) => {
        const target = getItemData(id);
        if (target) {
            return target;
        }
        throw new Error('useItems.getItemDataAbsolutely: item for id not found!');
    };

    // HANDLER
    const setSelectedItem = (id?: string) => {
        if (!id) {
            setCurrentItemData(undefined);
            return;
        }
        const itemData = getItemData(id);
        if (itemData) {
            setCurrentItemData(itemData);
        }
    };
    const updateItem = (newItem: ItemType<T>) => {
        const newItemDatas = itemDatas.map((itemData) => {
            if (itemData.id === newItem.id) {
                return newItem;
            }
            return itemData;
        });
        setItemDatas(newItemDatas);
    };
    const removeItem = (id: string) => {
        if (currentItemData?.id === id) {
            setCurrentItemData(undefined);
        }
        setIds(ids.filter((idsId) => idsId !== id));
        setItemDatas(itemDatas.filter((itemData) => itemData.id !== id));
    };

    return {
        ids,
        currentItem: currentItemData,
        getItem: getItemData,
        getItemAbsolutely: getItemDataAbsolutely,
        setSelectedItem,
        updateItem,
        removeItem,
    };
};

export const useLazyItems = <T extends RecordWithId>(items?: ItemType<T>[]) => {
    // HOOKS
    const [lazeItems, setLazyItems] = useState<ItemType<T>[] | undefined>(items);
    const initilaItems = useMemo(() => lazeItems || [], [lazeItems]);
    const states = useItems(initilaItems);
    const [isInitialized, setIsInitialized] = useState(false);
    const resultItems = useMemo(() => {
        if (lazeItems && states.ids.length === initilaItems.length) {
            return states;
        }
        if (isInitialized) {
            return states;
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [states]);

    // USEEFFECT
    useEffect(() => {
        setLazyItems(items);
    }, [items]);
    useEffect(() => {
        if (lazeItems && states.ids.length === initilaItems.length) {
            setIsInitialized(true);
        }
    }, [lazeItems, states.ids, initilaItems]);

    return {
        items: resultItems,
        setLazyItems,
    };
};
