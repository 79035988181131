import { BusinessSnapshot as GqlBusinessSnapshot } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import { BusinessSnapshotItem } from '@/utils/UodkaClients/Invoices/models/InvoiceData/BusinessSnapshotItem';

interface BusinessSnapshotInterface {
    name: string;
    items: BusinessSnapshotItem[];
}

export class BusinessSnapshot implements BusinessSnapshotInterface {
    protected _name: string;

    protected _items: BusinessSnapshotItem[];

    get name() {
        return this._name;
    }

    get items() {
        return this._items;
    }

    get data() {
        return {
            name: this._name,
            items: this._items,
        };
    }

    constructor({ name, items }: BusinessSnapshotInterface) {
        this._name = name;
        this._items = items;
    }
}

export const getBusinessSnapshotFromGql = ({ name, items }: GqlBusinessSnapshot) => {
    return new BusinessSnapshot({
        name,
        items: items.map((item) => new BusinessSnapshotItem(item)),
    });
};
