import {
    InviteForBusinessMember as GqlInviteForBusinessMember,
    BusinessMemberRole,
} from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { GqlDateTimeData } from '@/utils/UodkaClients/Core/graphql/customScalars';
import { ClientDataInInvite, getClientDataInInviteFromGql } from '@/utils/UodkaClients/Core/models/ClientData';

export interface InviteForMemberInterface {
    codeText: string;
    createdAt: Date;
    expireAt: Date;
    remainingSeats: number;
    business: ClientDataInInvite;
    role: BusinessMemberRole;
}

export class InviteForMember implements InviteForMemberInterface {
    protected _codeText: string;

    protected _createdAt: Date;

    protected _expireAt: Date;

    protected _remainingSeats: number;

    protected _business: ClientDataInInvite;

    protected _role: BusinessMemberRole;

    get codeText() {
        return this._codeText;
    }

    get createdAt() {
        return this._createdAt;
    }

    get expireAt() {
        return this._expireAt;
    }

    get remainingSeats() {
        return this._remainingSeats;
    }

    get business() {
        return this._business;
    }

    get role() {
        return this._role;
    }

    get data() {
        return {
            codeText: this.codeText,
            createdAt: this.createdAt,
            expireAt: this.expireAt,
            remainingSeats: this.remainingSeats,
            business: this.business,
            role: this.role,
        };
    }

    constructor({ codeText, createdAt, expireAt, remainingSeats, business, role }: InviteForMemberInterface) {
        this._codeText = codeText;
        this._createdAt = createdAt;
        this._expireAt = expireAt;
        this._remainingSeats = remainingSeats;
        this._business = business;
        this._role = role;
    }
}

export const getInviteForMemberFromGql = ({
    codeText,
    createdAt,
    expireAt,
    remainingSeats,
    business,
    role,
}: GqlInviteForBusinessMember) => {
    return new InviteForMember({
        codeText,
        createdAt: GqlDateTimeData.serialize(createdAt),
        expireAt: GqlDateTimeData.serialize(expireAt),
        remainingSeats,
        business: getClientDataInInviteFromGql(business),
        role,
    });
};
