import { FC } from 'react';
import clsx from 'clsx';

import { ButtonWithTextMenu } from '@/components/1_mol/ButtonWithMenu';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 21px',
        // marginBottom: 10,
        minHeight: 40,
        marginTop: -12,
        color: theme.palette.text.disabled,
        lineHeight: 1,
    },
    label: {
        fontWeight: 600,
    },
    colon: {
        fontWeight: 600,
    },
    button: {
        fontWeight: 600,
        color: theme.palette.text.disabled,
        fontSize: theme.typography.caption.fontSize,
        padding: 12,
        marginLeft: -12,
        minWidth: 0,
        lineHeight: 1,
    },
}));

interface TableHeadItemMenuItem {
    text: string;
    isSelected?: boolean;
    onClick: () => void;
}

export interface TableHeadItem {
    label: string;
    menu?: {
        buttonText: string;
        items: TableHeadItemMenuItem[];
        emptyPlaceholder: string;
    };
}

const TableHead: FC<{
    items: TableHeadItem[];
    widths: (string | number)[];
}> = ({ items, widths }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={c.root}>
            {items.map(({ label, menu }, i) => (
                <div key={i} style={{ width: widths[i] }}>
                    {menu ? (
                        <ButtonWithTextMenu
                            menuEmptyPlaceholder={menu.emptyPlaceholder}
                            items={menu.items}
                            className={c.button}
                        >
                            {`${label} : ${menu.buttonText}`}
                        </ButtonWithTextMenu>
                    ) : (
                        <MuiTypography variant={'caption'} className={c.label}>
                            {label}
                        </MuiTypography>
                    )}
                </div>
            ))}
        </div>
    );
};

TableHead.displayName = 'TableHead';
export default TableHead;
