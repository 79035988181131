import { Balance, getBalanceFromGql } from '@/utils/UodkaClients/Core';
import { BalanceWithInvoice as GqlBalanceWithInvoice } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import { IssuedPayment, getIssuedPaymentFromGql } from '@/utils/UodkaClients/Invoices/models/IssuedPayment';
import { IssuedReceipt, getIssuedReceiptFromGql } from '@/utils/UodkaClients/Invoices/models/IssuedReceipt';
import { ReceivedPayment, getReceivedPaymentFromGql } from '@/utils/UodkaClients/Invoices/models/ReceivedPayment';
import { ReceivedReceipt, getReceivedReceiptFromGql } from '@/utils/UodkaClients/Invoices/models/ReceivedReceipt';

interface BalanceWithInvoiceInterface {
    balance: Balance;
    issuedPayment?: IssuedPayment;
    issuedReceipt?: IssuedReceipt;
    receivedPayment?: ReceivedPayment;
    receivedReceipt?: ReceivedReceipt;
}

export class BalanceWithInvoice implements BalanceWithInvoiceInterface {
    protected _balance: Balance;

    protected _issuedPayment?: IssuedPayment;

    protected _issuedReceipt?: IssuedReceipt;

    protected _receivedPayment?: ReceivedPayment;

    protected _receivedReceipt?: ReceivedReceipt;

    get balance() {
        return this._balance;
    }

    get issuedPayment() {
        return this._issuedPayment;
    }

    get issuedReceipt() {
        return this._issuedReceipt;
    }

    get receivedPayment() {
        return this._receivedPayment;
    }

    get receivedReceipt() {
        return this._receivedReceipt;
    }

    constructor({
        balance,
        issuedPayment,
        issuedReceipt,
        receivedPayment,
        receivedReceipt,
    }: BalanceWithInvoiceInterface) {
        this._balance = balance;
        this._issuedPayment = issuedPayment;
        this._issuedReceipt = issuedReceipt;
        this._receivedPayment = receivedPayment;
        this._receivedReceipt = receivedReceipt;
    }
}

export const getBalanceWithInvoiceFromGql = ({
    balance,
    issuedPayment,
    issuedReceipt,
    receivedPayment,
    receivedReceipt,
}: GqlBalanceWithInvoice) => {
    return new BalanceWithInvoice({
        balance: getBalanceFromGql(balance),
        issuedPayment: issuedPayment ? getIssuedPaymentFromGql(issuedPayment) : undefined,
        issuedReceipt: issuedReceipt ? getIssuedReceiptFromGql(issuedReceipt) : undefined,
        receivedPayment: receivedPayment ? getReceivedPaymentFromGql(receivedPayment) : undefined,
        receivedReceipt: receivedReceipt ? getReceivedReceiptFromGql(receivedReceipt) : undefined,
    });
};
