import { FC, useState } from 'react';

import { UodkaCoreClientForGeneral, UodkaCoreClientForOwner } from '@/utils/UodkaClients/Core';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage, useBalanceWithInvoiceItems, useContactItems } from '@/utils/customHooks';
import { SideMenuTemplate } from '@/components/1_mol/SideMenu';

import SvgArrow from '@/svgs/fa-arrow-up-solid';
import SvgBidirection from '@/svgs/bidirection';

import { BalancesTable } from '@/views/transactions/BalancesTable';
import { AddTransferModal } from '@/views/transactions/AddTransferModal';
import { NoTransactionDisplay } from '@/views/transactions/NoTransactionDisplay';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    allIcon: {},
    depositIcon: {
        transform: 'rotate(90deg)',
        color: '#1D77FF',
    },
    withdrawIcon: {
        transform: 'rotate(270deg)',
        color: '#60D34E',
    },
}));

export const TransactionsViewRoot: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const {
        clients: { core },
    } = useBusinessCtxAbsolutely();
    const [isOpenAddTransfer, setIsOpenAddTransfer] = useState(false);
    const { items: contactItems } = useContactItems();
    const [selectedType, setSelectedType] = useState<'transfer' | 'charge' | undefined>(undefined);
    const { refetchBalances, filteredIds, items: balanceWithInvoiceItems } = useBalanceWithInvoiceItems({
        filter: {
            contactId: contactItems?.currentItem?.id,
            type: selectedType,
        },
    });

    return (
        <>
            <SideMenuTemplate
                className={c.root}
                title={txt({ en: 'Transactions', ja: '入出金' })}
                sideMenuButtonDatas={[
                    {
                        text: txt({ en: 'All', ja: '全て' }),
                        icon: <SvgBidirection className={c.allIcon} />,
                        onClick: () => setSelectedType(undefined),
                        isSelected: !selectedType,
                    },
                    {
                        text: txt({ en: 'Deposit', ja: '入金' }),
                        icon: <SvgArrow className={c.depositIcon} />,
                        onClick: () => setSelectedType('charge'),
                        isSelected: selectedType === 'charge',
                    },
                    {
                        text: txt({ en: 'Withdraw', ja: '出金' }),
                        icon: <SvgArrow className={c.withdrawIcon} />,
                        onClick: () => setSelectedType('transfer'),
                        isSelected: selectedType === 'transfer',
                    },
                ]}
                actionButtonData={
                    core instanceof UodkaCoreClientForGeneral || core instanceof UodkaCoreClientForOwner
                        ? {
                              text: txt({ en: 'New Remittance', ja: '新しい送金' }),
                              onClick: () => setIsOpenAddTransfer(true),
                          }
                        : undefined
                }
            >
                {filteredIds && balanceWithInvoiceItems?.ids.length === 0 ? (
                    <NoTransactionDisplay />
                ) : (
                    <BalancesTable
                        filteredIds={filteredIds}
                        items={balanceWithInvoiceItems}
                        contactItems={contactItems?.ids.map((id) => contactItems.getItemAbsolutely(id))}
                        currentContactItem={contactItems?.currentItem}
                        setCurrentContactId={(newId) => contactItems?.setSelectedItem(newId)}
                    />
                )}
            </SideMenuTemplate>
            {(core instanceof UodkaCoreClientForGeneral || core instanceof UodkaCoreClientForOwner) && (
                <AddTransferModal
                    coreClient={core}
                    isOpen={isOpenAddTransfer}
                    setIsOpen={setIsOpenAddTransfer}
                    refetchBalances={refetchBalances}
                />
            )}
        </>
    );
};
TransactionsViewRoot.displayName = 'TransactionsViewRoot';
