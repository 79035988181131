import { FC, useState, ReactElement } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import clsx from 'clsx';

import { useLanguage } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';

import SvgClipboard from '@/svgs/clipboard';
import SvgClipboardCheck from '@/svgs/clipboard-chick';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTooltip from '@material-ui/core/Tooltip';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '10px 22px',
        justifyContent: 'space-between',
        minWidth: 0,
        '& .MuiButton-label svg': {
            width: 14,
            height: 14,
        },
    },
    endIcon: {
        width: 14,
        height: 14,
        marginLeft: 22,
    },
}));

const CopyableText: FC<{
    copyText: string;
    displayText?: string;
    tipHoverText?: string;
    className?: string;
    isIconButton?: boolean;
    icon?: ReactElement;
    copiedIcon?: ReactElement;
}> = ({ copyText, displayText, tipHoverText, className, isIconButton, icon, copiedIcon }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [isTipClicked, setIsTipClicked] = useState(false);

    // HOOKS
    const { txt } = useLanguage();

    // HANDLER
    const handleClickButton = () => {
        setIsTipClicked(true);
        setTimeout(() => {
            setIsTipClicked(false);
        }, 1500);
    };

    return (
        <>
            <MuiTooltip
                arrow
                title={
                    isTipClicked
                        ? txt({ en: 'Copied', ja: 'コピーしました' })
                        : tipHoverText || txt({ en: 'Copy', ja: 'コピー' })
                }
                className={clsx(c.root, className)}
            >
                <CopyToClipboard text={copyText}>
                    <Button
                        disabled={!copyText}
                        onClick={handleClickButton}
                        endIcon={
                            !isIconButton
                                ? !isTipClicked
                                    ? icon || <SvgClipboard className={c.endIcon} />
                                    : copiedIcon || <SvgClipboardCheck className={c.endIcon} />
                                : undefined
                        }
                        variant={'contained'}
                    >
                        {!isIconButton ? (
                            <MuiTypography variant={'body2'}>{displayText || copyText}</MuiTypography>
                        ) : !isTipClicked ? (
                            icon || <SvgClipboard />
                        ) : (
                            copiedIcon || <SvgClipboardCheck />
                        )}
                    </Button>
                </CopyToClipboard>
            </MuiTooltip>
        </>
    );
};
CopyableText.displayName = 'CopyableText';
export default CopyableText;
