import { UodkaCertClientForObserver } from '@/utils/UodkaClients/Cert/clients/Business/ForObserver';
import { Signature, Resource, getResourceFromGql, getSignatureFromGql } from '@/utils/UodkaClients/Cert/models';

type CreateResourceInput = {
    contactId: string;
};
type CreateResourceAndSignatureInput = {
    contactId: string;
    signatureText: string;
};
type CreateSignatureInput = {
    resourceId: string;
    text: string;
};

interface UodkaCertClientForGeneralInterface {
    createResource(input: CreateResourceInput): Promise<Resource>;
    createResourceAndSignature(
        input: CreateResourceAndSignatureInput
    ): Promise<{
        resource: Resource;
        signature: Signature;
    }>;
    createSignature(input: CreateSignatureInput): Promise<Signature>;
}

export class UodkaCertClientForGeneral
    extends UodkaCertClientForObserver
    implements UodkaCertClientForGeneralInterface {
    async createResource({ contactId }: CreateResourceInput) {
        const { createResource } = await this._graphqlSdk.createResource({
            input: {
                businessId: this._id,
                contactId,
            },
        });
        return getResourceFromGql(createResource.resource);
    }

    async createResourceAndSignature({ contactId, signatureText }: CreateResourceAndSignatureInput) {
        const { createResourceAndSignature } = await this._graphqlSdk.createResourceAndSignature({
            input: {
                businessId: this._id,
                contactId,
                signatureText,
            },
        });
        return {
            resource: getResourceFromGql(createResourceAndSignature.resource),
            signature: getSignatureFromGql(createResourceAndSignature.signature),
        };
    }

    async createSignature({ resourceId, text }: CreateSignatureInput) {
        const { createSignature } = await this._graphqlSdk.createSignature({
            input: {
                businessId: this._id,
                resourceId,
                text,
            },
        });
        return getSignatureFromGql(createSignature.signature);
    }
}
