import { FC, ReactElement } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '&:not(:last-child)': {
            marginBottom: 14,
        },
    },
    label: {
        width: 120,
        paddingTop: 12,
        paddingRight: 14,
        flexShrink: 0,
    },
    labelText: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 600,
    },
    children: {
        flexGrow: 1,
    },
}));

export const BillDataViewerItem: FC<{
    label: string;
    children: ReactElement;
}> = ({ label, children }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={c.root}>
            <div className={c.label}>
                <MuiTypography className={c.labelText}>{label}</MuiTypography>
            </div>
            <div className={c.children}>{children}</div>
        </div>
    );
};
BillDataViewerItem.displayName = 'BillDataViewerItem';
