import { BusinessMemberRole } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { getGraphqlSdk } from '@/utils/UodkaClients/Core/graphql/graphqlSdk';
import {
    UodkaCoreClientForObserver,
    UodkaCoreClientForGeneral,
    UodkaCoreClientForOwner,
} from '@/utils/UodkaClients/Core/clients';

export const initUodkaCoreClientForObserver = ({
    firebaseUserIdToken,
    clientId,
}: {
    firebaseUserIdToken: string;
    clientId: string;
}) => {
    const graphqlSdk = getGraphqlSdk({ firebaseUserIdToken });
    return new UodkaCoreClientForObserver({
        graphqlSdk,
        id: clientId,
    });
};

export const initUodkaCoreClientForGeneral = ({
    firebaseUserIdToken,
    clientId,
}: {
    firebaseUserIdToken: string;
    clientId: string;
}) => {
    const graphqlSdk = getGraphqlSdk({ firebaseUserIdToken });
    return new UodkaCoreClientForGeneral({
        graphqlSdk,
        id: clientId,
    });
};

export const initUodkaCoreClientForOwner = ({
    firebaseUserIdToken,
    clientId,
}: {
    firebaseUserIdToken: string;
    clientId: string;
}) => {
    const graphqlSdk = getGraphqlSdk({ firebaseUserIdToken });
    return new UodkaCoreClientForOwner({
        graphqlSdk,
        id: clientId,
    });
};

export const initUodkaCoreClient = ({
    role,
    ...initializerInput
}: {
    firebaseUserIdToken: string;
    clientId: string;
    role: BusinessMemberRole;
}) => {
    if (role === 'owner') {
        return initUodkaCoreClientForOwner(initializerInput);
    }
    if (role === 'general') {
        return initUodkaCoreClientForGeneral(initializerInput);
    }
    if (role === 'observer') {
        return initUodkaCoreClientForObserver(initializerInput);
    }
    throw new Error('getUodkaCoreClient: invalid role');
};
