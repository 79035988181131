/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import remark from 'remark';

const remarkReact: any = require('remark-react');

export type RemarkReactComponents = Partial<{
    a: FC<Record<string, any>>;
    p: FC<Record<string, any>>;
    h2: FC<Record<string, any>>;
    h3: FC<Record<string, any>>;
    h4: FC<Record<string, any>>;
    li: FC<Record<string, any>>;
    table: FC<Record<string, any>>;
    img: FC<Record<string, any>>;
    blockquote: FC<Record<string, any>>;
}>;

const MarkdownViewer: FC<{
    textMd: string;
    options?: {
        async?: boolean;
        remarkReactComponents?: RemarkReactComponents;
    };
}> = ({ textMd, options }) => {
    const processor = remark().use(remarkReact, options);

    if (options && options.async) {
        // 非同期版
        return new Promise((accept, reject) => {
            processor.process(textMd, (error, file: any) => {
                if (error) {
                    reject(error);
                } else {
                    accept(file.result || file.contents);
                }
            });
        });
    }
    // 同期版
    const file: any = processor.processSync(textMd);
    return file.result || file.contents;
};
MarkdownViewer.displayName = 'MarkdownViewer';
export default MarkdownViewer;
