import { FC } from 'react';

import { MemberAPIForOwner } from '@/utils/UodkaClients/Core';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import ConfirmationModal from '@/components/1_mol/ConfirmationModal';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({}));

export const DeleteMemberModal: FC<{
    memberAPI: MemberAPIForOwner;
    removeMemberItem: (id: string) => void;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}> = ({ memberAPI, removeMemberItem, isOpen, setIsOpen }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { currentBusiness } = useBusinessCtxAbsolutely();
    const { txt } = useLanguage();

    // MUTATION
    const run = async () => {
        const result = await memberAPI.remove();
        removeMemberItem(memberAPI.id);
        return result;
    };

    return (
        <ConfirmationModal
            isWarning
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            title={txt({
                en: `Delete ${memberAPI.user.email} from ${currentBusiness.name}`,
                ja: `${currentBusiness.name}から${memberAPI.user.email}を削除`,
            })}
            mutation={{
                run,
                description: txt({
                    en: `Delete ${memberAPI.user.email} from ${currentBusiness.name}`,
                    ja: `${currentBusiness.name}から${memberAPI.user.email}を削除`,
                }),
            }}
            submitText={txt({ en: 'Delete', ja: '削除' })}
        >
            <MuiTypography>
                {txt({
                    en: 'Deleted member cannot be restored. Are you sure you want to continue?',
                    ja: '削除したメンバーは元に戻せません。本当に削除しますか？',
                })}
            </MuiTypography>
        </ConfirmationModal>
    );
};
DeleteMemberModal.displayName = 'views/config/business/Member/DeleteMemberModal';
