export const getColorFromNumbers = (numbers: number[], textColor: string) => {
    if (numbers.find((num) => Number.isNaN(num))) {
        return textColor;
    }
    const sum = numbers[0] + numbers[1] + numbers[2];
    if (sum > 400) {
        return textColor;
    }
    return '#fff';
};
