import { FC, useState } from 'react';

import { BusinessProfileAPIForOwner } from '@/utils/UodkaClients/Profile';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage, useFormField } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import { MutationFormModal } from '@/components/1_mol/FormModal';
import ColorPicker from '@/components/0_atom/ColorPicker';
import { getIsValidHexColorCode } from '@/components/0_atom/ColorPalette';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({}));

export const ModifyThemeColorButton: FC<{
    profileAPI: BusinessProfileAPIForOwner;
    setProfile: (clientData: BusinessProfileAPIForOwner) => void;
}> = ({ profileAPI, setProfile }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { currentBusiness } = useBusinessCtxAbsolutely();
    const { txt } = useLanguage();
    const [isOpen, setIsOpen] = useState(false);
    const [editingThemeColor, setEditingThemeColor] = useFormField(profileAPI.themeColor);

    // DATA
    const isValidThemeColor = (() => {
        if (!editingThemeColor || editingThemeColor === '#') {
            return true;
        }
        return editingThemeColor.length === 4 || editingThemeColor.length === 7;
    })();

    // MUTATION
    const run = async () => {
        if (isValidThemeColor) {
            const result = await profileAPI.modifyThemeColor(editingThemeColor === '#' ? undefined : editingThemeColor);
            setProfile(profileAPI);
            return result;
        }
        throw new Error('ModifyThemeColorButton: !isValidThemeColor');
    };

    return (
        <>
            <Button onClick={() => setIsOpen(true)} variant={'contained'}>
                {txt({ en: 'Change', ja: '変更' })}
            </Button>
            <MutationFormModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={txt({
                    en: `Modify ${currentBusiness.name}'s profile`,
                    ja: `${currentBusiness.name}のプロフィールを編集`,
                })}
                mutation={{
                    run,
                    description: txt({
                        en: `Modify ${currentBusiness.name}'s profile`,
                        ja: `${currentBusiness.name}のプロフィールを編集`,
                    }),
                }}
                items={[
                    {
                        isRequired: false,
                        labelText: txt({ en: 'Brand Color', ja: 'ブランドカラー' }),
                        isSatisfied: isValidThemeColor,
                        isError: undefined,
                        children: <ColorPicker color={editingThemeColor} setColor={setEditingThemeColor} />,
                    },
                ]}
                submitText={txt({ en: 'Update', ja: '変更' })}
            />
        </>
    );
};
ModifyThemeColorButton.displayName = 'views/config/business/Profile/ModifyThemeColorButton';
