import { useEffect, useState } from 'react';

import { useErrorCtx } from '@/utils/ctxs';
import { useRouting } from '@/utils/customHooks';

export type BillsViwSlug1 = 'issued' | 'received';

const getIsBillsViwSlug1 = (str: string): str is BillsViwSlug1 => {
    return str === 'issued' || str === 'received';
};

export const useBillsRouting = () => {
    // HOOKS
    const { setIs404 } = useErrorCtx();
    const { viewSlug1, viewSlug2, viewSlug3, setViewSlug1, setViewSlug2, setViewSlug3 } = useRouting();
    const [slug1, setSlug1] = useState<BillsViwSlug1 | undefined>(
        viewSlug1 && getIsBillsViwSlug1(viewSlug1) ? viewSlug1 : undefined
    );
    const [slug2, setSlug2] = useState<string | undefined>(viewSlug2);
    const [slug3, setSlug3] = useState<string | undefined>(viewSlug3);

    // DATA
    const specifiedBillUrl = (() => {
        if (slug2 && slug3) {
            return {
                direction: slug1,
                contactId: slug2,
                billId: slug3,
            };
        }
        return undefined;
    })();

    // USEEFFECT
    useEffect(() => {
        if (!viewSlug1) {
            setSlug1('received');
            setSlug2(undefined);
            setSlug3(undefined);
            return;
        }
        if (getIsBillsViwSlug1(viewSlug1)) {
            if (!viewSlug2) {
                setSlug1(viewSlug1);
                setSlug2(undefined);
                setSlug3(undefined);
            }
            if (!viewSlug3) {
                setSlug1(viewSlug1);
                setSlug2(viewSlug2);
                setSlug3(undefined);
                return;
            }
            setSlug1(viewSlug1);
            setSlug2(viewSlug2);
            setSlug3(viewSlug3);
        }
        setIs404(true);
    }, [viewSlug1, viewSlug2, viewSlug3, setIs404]);

    return {
        specifiedBillUrl,
        slug1,
        slug2,
        slug3,
        setSlug1: (slugs: { slug1?: BillsViwSlug1 }) => setViewSlug1(slugs),
        setSlug2: (slugs: { slug1: BillsViwSlug1; slug2?: string }) => setViewSlug2(slugs),
        setSlug3: (slugs: { slug1: BillsViwSlug1; slug2: string; slug3?: string }) => setViewSlug3(slugs),
    };
};
