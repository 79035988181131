import { FC } from 'react';
import clsx from 'clsx';

import { useLanguage } from '@/utils/customHooks';
import { ButtonWithTextMenu } from '@/components/1_mol/ButtonWithMenu';

import SvgChevronUp from '@/svgs/fa-chevron-up-solid';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        border: 'solid 2px rgba(0,0,0,.05)',
        position: 'relative',
        background: '#fafafa',
        paddingRight: 24,
        '& .MuiButton-endIcon': {
            position: 'absolute',
            right: 12,
            width: 9,
            height: 'auto',
            transform: 'rotate(180deg)',
            color: theme.palette.text.disabled,
        },
    },
}));

interface SelectorItem {
    id: string;
    text: string;
}

export const TextSelector: FC<{
    className?: string;
    items: SelectorItem[];
    onSelect: (id: string) => void;
    selectedId?: string;
    notSelectedText?: string;
    emptyPlaceholder: string;
}> = ({ className, items, onSelect, selectedId, notSelectedText, emptyPlaceholder }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    // DATA
    const selectedItem = items.find((item) => item.id === selectedId);

    return (
        <ButtonWithTextMenu
            menuEmptyPlaceholder={emptyPlaceholder}
            className={clsx(c.root, className)}
            items={items.map((item) => ({
                text: item.text,
                onClick: () => onSelect(item.id),
                isSelected: item.id === selectedId,
            }))}
            endIcon={<SvgChevronUp />}
        >
            {selectedItem?.text || notSelectedText || txt({ en: 'Not selected', ja: '未選択' })}
        </ButtonWithTextMenu>
    );
};
TextSelector.displayName = 'TextSelector';
