import { FC, useState } from 'react';

import { ClientDataAPIForOwner } from '@/utils/UodkaClients/Core';
import { useLanguage, useFormField } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import { MutationFormModal } from '@/components/1_mol/FormModal';
import { TextInput } from '@/components/0_atom/Input';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({}));

export const ModifyNotificationEmailButton: FC<{
    clientDataAPI: ClientDataAPIForOwner;
    setClientData: (clientData: ClientDataAPIForOwner) => void;
}> = ({ clientDataAPI, setClientData }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [isOpen, setIsOpen] = useState(false);
    const [editingNotificationEmail, setEditingNotificationEmail] = useFormField(clientDataAPI.notificationEmail);

    // MUTATION
    const run = async () => {
        const result = await clientDataAPI.modifyNotificationEmail(editingNotificationEmail);
        setClientData(clientDataAPI);
        return result;
    };

    return (
        <>
            <Button onClick={() => setIsOpen(true)} variant={'contained'}>
                {txt({ en: 'Change', ja: '変更' })}
            </Button>
            <MutationFormModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={txt({
                    en: 'Change NotificationEmail',
                    ja: '通知用メールアドレスを変更',
                })}
                mutation={{
                    run,
                    description: txt({
                        en: 'Change NotificationEmail',
                        ja: '通知用メールアドレスを変更',
                    }),
                }}
                items={[
                    {
                        isRequired: true,
                        labelText: txt({ en: 'New email address', ja: '新しいメールアドレス' }),
                        isSatisfied: !!editingNotificationEmail,
                        isError: undefined,
                        children: (
                            <TextInput
                                fullWidth
                                placeholder={txt({ en: 'New email address', ja: '新しいメールアドレス' })}
                                value={editingNotificationEmail}
                                setValue={(newValue) => setEditingNotificationEmail(newValue || '')}
                                isSatisfied={!!editingNotificationEmail}
                            />
                        ),
                    },
                ]}
                submitText={txt({ en: 'Update', ja: '変更' })}
            />
        </>
    );
};
ModifyNotificationEmailButton.displayName = 'views/config/business/account/Basic/ModifyNotificationEmailButton';
