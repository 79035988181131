import { FC } from 'react';

import { IssuedBill } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';

import { getIssuedBillStatusHistory } from '@/views/bills/IssuedBills/getIssuedBillStatusHistory';
import { IssuedBillStatusSection } from '@/views/bills/IssuedBills/IssuedBillStatusDisplay/IssuedBillStatusSection';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 116,
    },
}));

export const IssuedBillStatusDisplay: FC<{
    bill: IssuedBill;
}> = ({ bill }) => {
    // STYLE
    const theme = useTheme();
    const c = useStyles(theme);

    // HOOKS
    const { txt } = useLanguage();

    // DATA
    const { billStatus, paymentStatus } = (() => {
        const items = getIssuedBillStatusHistory({ bill, txt });
        const billStatuses = items.filter(({ section }) => section === 'bill');
        const paymentStatuses = items.filter(({ section }) => section === 'payment');
        const billStatus = (() => {
            if (billStatuses.length > 0) {
                return billStatuses[billStatuses.length - 1];
            }
            return undefined;
        })();
        const paymentStatus = (() => {
            if (paymentStatuses.length > 0) {
                return paymentStatuses[paymentStatuses.length - 1];
            }
            return undefined;
        })();
        return {
            billStatus,
            paymentStatus,
        };
    })();

    return (
        <div className={c.root}>
            <IssuedBillStatusSection sectionLabel={txt({ en: 'Bill', ja: '請求' })} status={billStatus} />
            <IssuedBillStatusSection sectionLabel={txt({ en: 'Payment', ja: '支払い' })} status={paymentStatus} />
        </div>
    );
};
IssuedBillStatusDisplay.displayName = 'IssuedBillStatusDisplay';
