import { FC } from 'react';

import { BusinessMemberRole } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { MemberAPIForOwner } from '@/utils/UodkaClients/Core';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage, useFormField } from '@/utils/customHooks';
import { MutationFormModal } from '@/components/1_mol/FormModal';
import { TextSelector } from '@/components/1_mol/Selector';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({}));

export const ModifyMemberRoleModal: FC<{
    memberAPI: MemberAPIForOwner;
    updateMemberItem: (memberAPI: MemberAPIForOwner) => void;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}> = ({ memberAPI, updateMemberItem, isOpen, setIsOpen }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { currentBusiness } = useBusinessCtxAbsolutely();
    const { txt } = useLanguage();
    const [editingRole, setEditingRole] = useFormField(memberAPI.role);

    // MUTATION
    const run = async () => {
        const result = await memberAPI.modifyRole(editingRole);
        updateMemberItem(memberAPI);
        return result;
    };

    return (
        <MutationFormModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            title={txt({
                en: `Change ${memberAPI.user.email}'s role`,
                ja: `${memberAPI.user.email}の権限を変更`,
            })}
            mutation={{
                run,
                description: txt({
                    en: 'Change member role',
                    ja: 'メンバーの権限を変更',
                }),
            }}
            items={[
                {
                    labelText: txt({ en: 'New role', ja: '新しい権限' }),
                    isSatisfied: !!editingRole,
                    isError: undefined,
                    isRequired: true,
                    children: (
                        <TextSelector
                            emptyPlaceholder={'unexpected'}
                            items={[
                                { id: 'owner', text: txt({ en: 'Owner', ja: 'オーナー' }) },
                                { id: 'general', text: txt({ en: 'General', ja: '一般' }) },
                                { id: 'observer', text: txt({ en: 'Observer', ja: '閲覧のみ' }) },
                            ]}
                            onSelect={(role) => setEditingRole(role as BusinessMemberRole)}
                            selectedId={editingRole}
                        />
                    ),
                },
            ]}
            submitText={txt({ en: 'Update', ja: '変更' })}
        />
    );
};
ModifyMemberRoleModal.displayName = 'views/config/business/Member/ModifyMemberRoleModal';
