import {
    UserInBusinessMember as GqlUserInBusinessMember,
    Language,
} from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';

interface UserInMemberInterface {
    id: string;
    email: string;
    language?: Language;
}

export class UserInMember implements UserInMemberInterface {
    protected _id: string;

    protected _email: string;

    protected _language?: Language;

    get id() {
        return this._id;
    }

    get email() {
        return this._email;
    }

    get language() {
        return this._language;
    }

    get data() {
        return {
            id: this.id,
            email: this.email,
            language: this.language,
        };
    }

    constructor({ id, email, language }: UserInMemberInterface) {
        this._id = id;
        this._email = email;
        this._language = language || undefined;
    }
}

export const getUserInMemberFromGql = ({ id, email, language }: GqlUserInBusinessMember) => {
    return new UserInMember({
        id,
        email,
        language: language || undefined,
    });
};
