import { FC, ReactNode } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 600,
        paddingBottom: 2,
        color: theme.palette.text.disabled,
    },
    children: {
        fontWeight: 600,
        fontSize: theme.typography.body1.fontSize,
    },
}));

export const ItemField: FC<{
    className?: string;
    label: string;
    children: ReactNode;
}> = ({ className, label, children }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={className}>
            <MuiTypography className={c.label}>{label}</MuiTypography>
            <div className={c.children}>{children}</div>
        </div>
    );
};
ItemField.displayName = 'ItemField';
