import { FC, useEffect, useState } from 'react';

import { useBusinessCtx } from '@/utils/ctxs';
import { useView } from '@/utils/customHooks';
import BusinessButton from '@/components/2_org/BusinessButton';
import ViewButton from '@/components/2_org/ViewButton';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiSlide from '@material-ui/core/Slide';
import MuiFade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#555',
        width: '100vw',
        height: '100vh',
    },
    sideBar: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        padding: '14px 8px',
        width: 170,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: '#555',
        transitionDuration: '.2s',
        transitionProperty: 'left',
    },
    viewBox: {
        marginLeft: 170,
        position: 'relative',
        zIndex: 0,
        padding: '18px 36px',
        '&::before': {
            content: `''`,
            position: 'fixed',
            top: 0,
            right: 0,
            height: '100vh',
            width: 'calc(100vw - 170px)',
            background: '#f8f8f8',
            boxShadow: '-32px 0 64px 0 rgba(0,0,0,.3)',
            zIndex: -1,
        },
    },
    viewButtons: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1,
    },
    otherViewButtons: {},
    configViewButton: {},
    businessButton: {
        marginTop: 14,
    },
    viewBoxInner: {
        // maxWidth: 900,
        // margin: '0 auto',
    },
}));

const ConsoleTemplate: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { currentBusiness } = useBusinessCtx();
    const { currentView } = useView();
    const [isSwitchingBusiness, setIsSwitchingBusiness] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isSidebarIn, setIsSidebarIn] = useState(true);

    // USEEFFECT
    useEffect(() => {
        if (isInitialized) {
            setIsSidebarIn(false);
            setIsSwitchingBusiness(true);
            setTimeout(() => {
                setIsSwitchingBusiness(false);
                setTimeout(() => {
                    setIsSidebarIn(true);
                }, 500);
            }, 500);
        }
        if (!isInitialized) {
            setIsInitialized(true);
            setIsSidebarIn(false);
            setTimeout(() => {
                setIsSidebarIn(true);
            }, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentBusiness]);

    return (
        <div className={c.root}>
            <MuiFade
                in={isSidebarIn}
                timeout={{
                    appear: 500,
                    enter: 500,
                    exit: 200,
                }}
            >
                <div
                    className={c.sideBar}
                    style={{
                        left: isSidebarIn ? 0 : -20,
                    }}
                >
                    <div className={c.viewButtons}>
                        <div className={c.otherViewButtons}>
                            <ViewButton viewName={'transactions'} />
                            <ViewButton viewName={'contacts'} />
                            <ViewButton viewName={'bills'} />
                            <ViewButton viewName={'records'} />
                        </div>
                        <div className={c.configViewButton}>
                            <ViewButton viewName={'config'} />
                        </div>
                    </div>
                    <div className={c.businessButton}>
                        <BusinessButton />
                    </div>
                </div>
            </MuiFade>
            <MuiSlide
                in={!isSwitchingBusiness}
                direction={'left'}
                timeout={{
                    appear: 800,
                    enter: 800,
                    exit: 500,
                }}
            >
                <div className={c.viewBox}>
                    <div className={c.viewBoxInner}>{isSwitchingBusiness ? <></> : currentView?.content || <></>}</div>
                </div>
            </MuiSlide>
        </div>
    );
};
ConsoleTemplate.displayName = 'ConsoleTemplate';
export default ConsoleTemplate;
