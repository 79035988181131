import { FC } from 'react';
import clsx from 'clsx';

import { ExternalBankAccountInterface } from '@/utils/UodkaClients/Core';

import SvgUodka from '@/svgs/uodkalogo';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'block',
        textAlign: 'left',
    },
    midrow: {
        marginTop: 9,
        lineHeight: 1,
    },
    bankName: {
        fontWeight: 600,
        lineHeight: 1,
        display: 'flex',
        justifyContent: 'space-between',
    },
    branchName: {
        lineHeight: 1.4,
        display: 'inline-block',
    },
    accountNumber: {
        lineHeight: 1.4,
        display: 'inline-block',
        marginLeft: 6,
    },
    accountHolderName: {
        display: 'block',
        marginTop: 6,
        lineHeight: 1.4,
    },
    uodkaTip: {
        background: theme.palette.primary.main,
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        padding: '0 4px',
        borderRadius: 2,
        height: 12,
        marginLeft: 7,
    },
    uodkaLogo: {
        height: 6,
    },
}));

export const BankAccountText: FC<{
    className?: string;
    bankAccount: ExternalBankAccountInterface;
    isWithUodkaLogo?: boolean;
}> = ({ className, bankAccount, isWithUodkaLogo }) => {
    // STYLE
    const theme = useTheme();
    const c = useStyles(theme);

    return (
        <div className={clsx(c.root, className)}>
            <MuiTypography className={c.bankName} variant={'body2'}>
                {isWithUodkaLogo ? 'GMOあおぞらネット銀行' : 'ダミー銀行'}
                {isWithUodkaLogo && (
                    <span className={c.uodkaTip}>
                        <SvgUodka className={c.uodkaLogo} />
                    </span>
                )}
            </MuiTypography>
            <div className={c.midrow}>
                <MuiTypography className={c.branchName} variant={'caption'}>
                    {`ダミー支店 (${bankAccount.branchCode})`}
                </MuiTypography>
                <MuiTypography className={c.accountNumber} variant={'caption'}>
                    {bankAccount.accountNumber}
                </MuiTypography>
            </div>
            <MuiTypography className={c.accountHolderName} variant={'caption'}>
                {bankAccount.accountHolderName}
            </MuiTypography>
        </div>
    );
};
BankAccountText.displayName = 'BankAccountText';
