import { FC, CSSProperties } from 'react';

const Svg: FC<{ className?: string; style?: CSSProperties }> = ({ className, style }) => {
    return (
        <svg className={className} style={style} viewBox="0 0 2 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="1" cy="1" r="1" fill="currentColor" />
        </svg>
    );
};
Svg.displayName = 'svg/ellipsis';
export default Svg;
