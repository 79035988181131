import { Resource as GqlResource } from '@/utils/UodkaClients/Cert/graphql/generated/graphqlClient';
import { GqlDateTimeData } from '@/utils/UodkaClients/Cert/graphql/customScalars';

interface ResourceInterface {
    id: string;
    createdAt: Date;
    issuer: {
        businessId: string;
        contactId: string;
    };
    recipient?: {
        businessId: string;
        contactId: string;
    };
}

export class Resource implements ResourceInterface {
    protected _id: string;

    protected _createdAt: Date;

    protected _issuer: {
        businessId: string;
        contactId: string;
    };

    protected _recipient?: {
        businessId: string;
        contactId: string;
    };

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get issuer() {
        return this._issuer;
    }

    get recipient() {
        return this._recipient;
    }

    constructor({ id, createdAt, issuer, recipient }: ResourceInterface) {
        this._id = id;
        this._createdAt = createdAt;
        this._issuer = {
            businessId: issuer.businessId,
            contactId: issuer.contactId,
        };
        this._recipient = (() => {
            if (recipient) {
                return {
                    businessId: recipient.businessId,
                    contactId: recipient.contactId,
                };
            }
            return undefined;
        })();
    }
}

export const getResourceFromGql = ({ id, createdAt, issuer, recipient }: GqlResource) => {
    return new Resource({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        issuer,
        recipient: recipient || undefined,
    });
};
