import { FC } from 'react';
import clsx from 'clsx';

import { BusinessSnapshot } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';
import { getStrWithBr } from '@/utils/utilFunctions';

import SvgAddress from '@/svgs/address';
import SvgPhone from '@/svgs/phone';
import SvgEmail from '@/svgs/email';
import SvgGlobe from '@/svgs/globe';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
        '& .MuiInputBase-input': {
            border: 'none',
            padding: 0,
            fontWeight: 'inherit',
            fontSize: theme.typography.body2.fontSize,
            minHeight: '2em',
        },
    },
    multilineInput: {
        padding: 0,
        '& .MuiInputBase-input': {
            border: 'none',
            padding: '6px 0',
            fontWeight: 'inherit',
            fontSize: theme.typography.body2.fontSize,
        },
    },
    inputLarge: {
        '& .MuiInputBase-input': {
            border: 'none',
            padding: 0,
            fontWeight: 600,
            fontSize: theme.typography.body1.fontSize,
            minHeight: '2em',
        },
    },
    sectionTitle: {
        fontSize: theme.typography.caption.fontSize,
        marginBottom: 2,
    },
    name: {
        fontWeight: 600,
        marginBottom: 4,
    },
    item: {
        fontSize: theme.typography.caption.fontSize,
        display: 'flex',
        '&:not(:last-child)': {
            marginBottom: 2,
        },
    },
    itemAddress: {
        marginBottom: '6px !important',
    },
    icon: {
        width: 7,
        height: 14,
        marginRight: 7,
        // color: 'rgba(0,0,0,.4)',
    },
    value: {},
}));

const getIcon = ({ title, className }: { title: string; className?: string }) => {
    // if (title === 'address') {
    //     return <SvgAddress className={className} />;
    // }
    if (title === 'phone') {
        return <SvgPhone className={className} />;
    }
    if (title === 'email') {
        return <SvgEmail className={className} />;
    }
    if (title === 'websiteUrl') {
        return <SvgGlobe className={className} />;
    }
    return <></>;
};

export const Issuer: FC<{
    issuer: BusinessSnapshot;
}> = ({ issuer }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    // DATA

    return (
        <div className={c.root}>
            <div className={c.sectionTitle}>{txt({ en: 'Issuer', ja: '請求者' })}</div>
            <div className={c.name}>{issuer.name}</div>
            {issuer.items.length > 0 &&
                issuer.items.map((item, i) => (
                    <div
                        key={i}
                        className={clsx(c.item, {
                            [c.itemAddress]: item.title === 'address',
                        })}
                    >
                        {getIcon({ title: item.title, className: c.icon })}
                        <span className={c.value}>{getStrWithBr(item.value)}</span>
                    </div>
                ))}
        </div>
    );
};
Issuer.displayName = 'Issuer';
