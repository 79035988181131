import { GqlDateTimeData } from '@/utils/UodkaClients/Invoices/graphql/customScalars';
import { BillInPayment as GqlBillInPayment } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import { BillData, getBillDataFromGql } from '@/utils/UodkaClients/Invoices/models/InvoiceData';
import { BillSignatures, getBillSignaturesFromGql } from '@/utils/UodkaClients/Invoices/models/BillSignatures';

interface BillInPaymentInterface {
    id: string;
    createdAt: Date;
    billData: BillData;
    signatures: BillSignatures;
}

export class BillInPayment implements BillInPaymentInterface {
    protected _id: string;

    protected _createdAt: Date;

    protected _billData: BillData;

    protected _signatures: BillSignatures;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get billData() {
        return this._billData;
    }

    get signatures() {
        return this._signatures;
    }

    constructor({ id, createdAt, billData, signatures }: BillInPaymentInterface) {
        this._id = id;
        this._createdAt = createdAt;
        this._billData = billData;
        this._signatures = signatures;
    }
}

export const getBillInPaymentFromGql = ({ id, createdAt, billData, signatures }: GqlBillInPayment) => {
    return new BillInPayment({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        billData: getBillDataFromGql(billData),
        signatures: getBillSignaturesFromGql(signatures),
    });
};
