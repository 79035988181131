import { FC } from 'react';
import clsx from 'clsx';

import { ExternalBankAccountInterface, ClientData } from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import ContactNameWithIcon from '@/components/2_org/ContactNameWithIcon';
import { DepositAccountCard } from '@/components/2_org/BankAccountCard/DepositAccountCard';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    holder: {
        marginBottom: 24,
    },
}));

export const DepositAccountCardWithHolder: FC<{
    className?: string;
    depositAccount: ExternalBankAccountInterface;
    holder: {
        name: string;
        profile: BusinessProfile | undefined;
    };
    remitter: {
        name: string;
        profile: BusinessProfile | undefined;
    };
}> = ({ className, depositAccount, holder, remitter }) => {
    // STYLE
    const theme = useTheme();
    const c = useStyles(theme);

    return (
        <div className={clsx(c.root, className)}>
            <ContactNameWithIcon className={c.holder} contact={{ name: holder.name }} profile={holder.profile} />
            <DepositAccountCard depositAccount={depositAccount} remitter={remitter} />
        </div>
    );
};
DepositAccountCardWithHolder.displayName = 'DepositAccountCardWithHolder';
