import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 628 527" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.55294 168.452C5.58674 159.366 16.2224 155.27 25.3083 159.304L616.45 421.747C625.536 425.781 629.631 436.417 625.598 445.503C621.564 454.589 610.928 458.684 601.842 454.651L10.7006 192.207C1.61469 188.173 -2.48087 177.538 1.55294 168.452Z"
                fill="currentColor"
            />
            <path
                d="M299.511 217.194C309.519 221.723 318.882 228.089 327.096 236.303C337.434 246.64 344.858 258.813 349.365 271.786L387.945 281.882C383.742 255.858 372.579 230.875 352.557 210.853C340.31 198.605 326.195 189.309 311.122 182.985C304.734 180.304 297.306 183.664 295.203 190.265C293.628 195.213 292.679 200.339 292.377 205.524C292.085 210.527 294.945 215.128 299.511 217.194Z"
                fill="currentColor"
            />
            <path
                d="M499.852 63.5596C463.093 26.8216 403.664 26.8286 366.912 63.5596L287.352 143.12C263.96 166.751 255.708 199.821 262.568 230.121L224.259 210.853C223.84 177.02 236.508 143.053 262.261 117.299L341.461 38.1086C392.239 -12.6904 474.499 -12.7154 525.301 38.1086C576.1 88.8866 576.125 171.146 525.301 221.949L446.111 301.149C444.955 302.305 443.778 303.439 442.579 304.552C435.031 311.557 422.784 306.557 422.371 296.266C422.179 291.455 421.773 286.656 421.155 281.882C420.674 278.165 421.901 274.436 424.552 271.786C440.581 255.769 496.506 199.846 499.709 196.643L499.852 196.5C536.59 159.741 536.583 100.312 499.852 63.5596Z"
                fill="currentColor"
            />
            <path
                d="M285.198 488.394L364.397 409.204C372.31 401.291 378.987 392.603 384.429 383.383L352.575 366.5C348.889 372.499 344.466 378.171 339.306 383.383L259.746 462.943C222.994 499.674 163.565 499.681 126.807 462.943C90.0755 426.191 90.0685 366.762 126.807 330.003C126.898 329.911 146.76 310.05 166.614 290.2L131.907 274L101.358 304.554C50.5335 355.357 50.5585 437.616 101.358 488.394C152.161 539.218 234.42 539.193 285.198 488.394Z"
                fill="currentColor"
            />
        </svg>
    );
};
Svg.displayName = 'svg/unlink';
export default Svg;
