import { useEffect, useCallback, useState } from 'react';

import { BalancesFilter } from '@/utils/UodkaClients/Core';
import { BusinessProfile, initUodkaProfileClientForAnonymous } from '@/utils/UodkaClients/Profile';
import { BalanceWithInvoice } from '@/utils/UodkaClients/Invoices';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLazyItems } from '@/utils/customHooks';

export type BalanceWithInvoiceItem = {
    id: string;
    balanceWithInvoice: BalanceWithInvoice;
    contactProfile?: BusinessProfile;
};

const filterBalanceItems = (allItems: BalanceWithInvoiceItem[]) => {
    return allItems.filter(({ balanceWithInvoice }) => {
        console.log('balanceWithInvoice=>', balanceWithInvoice);
        if (balanceWithInvoice.balance.transfer?.bankAccount.isDepositAccount) {
            return true;
        }
        return (
            balanceWithInvoice.balance.eventType !== 'transferError' &&
            balanceWithInvoice.balance.eventType !== 'transferIssue'
        );
    });
};

export const useBalanceWithInvoiceItems = ({
    filter,
}: {
    filter: {
        contactId?: string;
        type?: 'transfer' | 'charge';
    };
}) => {
    // HOOKS
    const {
        clients: { invoices },
    } = useBusinessCtxAbsolutely();
    const { items, setLazyItems } = useLazyItems<BalanceWithInvoiceItem>();
    const [filteredIds, setFilteredIds] = useState<string[] | undefined>(undefined);
    const fetchBalances = useCallback(async () => {
        setFilteredIds(undefined);
        const bwis = await invoices.getBalanceWithInvoices({});
        const balanceItems: BalanceWithInvoiceItem[] = await Promise.all(
            bwis.map(async (bwi) => {
                const contactBusinessId = (() => {
                    const { transfer, charge } = bwi.balance;
                    if (transfer) {
                        return transfer.contact.relatedBusiness?.id;
                    }
                    return charge?.depositAccount.fromContact?.relatedBusiness?.id;
                })();
                if (contactBusinessId) {
                    const profileClient = initUodkaProfileClientForAnonymous();
                    const contactProfile = await profileClient.getProfileData({ businessId: contactBusinessId });
                    return {
                        id: bwi.balance.id,
                        balanceWithInvoice: bwi,
                        contactProfile,
                    };
                }
                return {
                    id: bwi.balance.id,
                    balanceWithInvoice: bwi,
                };
            })
        );
        setLazyItems(filterBalanceItems(balanceItems));
    }, [invoices, setLazyItems]);

    // USEEFFECT
    useEffect(() => {
        fetchBalances();
    }, [fetchBalances]);
    useEffect(() => {
        const { contactId, type } = filter;
        if (items) {
            const newFilteredIds = items.ids.filter((id) => {
                if (contactId || type) {
                    const item = items.getItemAbsolutely(id);
                    const isSatisfyContactId = (() => {
                        if (contactId) {
                            return (
                                item.balanceWithInvoice.balance.transfer?.contact.id === contactId ||
                                item.balanceWithInvoice.balance.charge?.depositAccount.fromContact?.id === contactId
                            );
                        }
                        return true;
                    })();
                    const isSatisfyType = (() => {
                        if (type) {
                            return (
                                (type === 'charge' && item.balanceWithInvoice.balance.eventType === 'charge') ||
                                (type === 'transfer' &&
                                    (!!item.balanceWithInvoice.balance.transfer?.bankAccount.isDepositAccount ||
                                        item.balanceWithInvoice.balance.eventType === 'transferTry'))
                            );
                        }
                        return true;
                    })();
                    return isSatisfyContactId && isSatisfyType;
                }
                return items.ids;
            });
            setFilteredIds(newFilteredIds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items?.ids, filter.contactId, filter.type]);

    return {
        items,
        refetchBalances: fetchBalances,
        filteredIds,
    };
};
