import { FC } from 'react';

import { useLanguage } from '@/utils/customHooks';

import { ReceivedBillStatus } from '@/views/bills/ReceivedBills/getReceivedBillStatusHistory';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    section: {
        display: 'flex',
        alignItems: 'center',
        '&:not(:last-child)': {
            marginBottom: 4,
        },
    },
    label: {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 600,
        color: theme.palette.text.disabled,
        width: 46,
    },
    value: {
        display: 'flex',
        alignItems: 'center',
        padding: '6px 12px',
        borderRadius: 2,
    },
    text: {
        fontWeight: 600,
        lineHeight: 1,
        fontSize: theme.typography.caption.fontSize,
    },
}));

export const ReceivedBillStatusSection: FC<{
    status: ReceivedBillStatus | undefined;
    sectionLabel: string;
}> = ({ status, sectionLabel }) => {
    // STYLE
    const theme = useTheme();
    const c = useStyles(theme);

    // HOOKS
    const { txt } = useLanguage();

    return (
        <div className={c.section}>
            <MuiTypography variant={'body2'} className={c.label}>
                {sectionLabel}
            </MuiTypography>
            <div
                className={c.value}
                style={{
                    color:
                        status?.type === 'completed'
                            ? theme.palette.success.main
                            : status?.type === 'error'
                            ? theme.palette.error.main
                            : status?.type === 'processing'
                            ? theme.palette.warning.main
                            : status?.type === 'arrival'
                            ? 'rgb(255,132,132)'
                            : theme.palette.text.disabled,
                    background:
                        status?.type === 'completed'
                            ? 'rgba(96,211,78,.2)'
                            : status?.type === 'error'
                            ? 'rgba(255,71,71,.2)'
                            : status?.type === 'processing'
                            ? 'rgba(255,211,56,.2)'
                            : status?.type === 'arrival'
                            ? 'rgba(255,132,132,.2)'
                            : '#fafafa',
                }}
            >
                <MuiTypography variant={'body2'} className={c.text}>
                    {status?.textForCurrent || txt({ en: 'none', ja: 'なし' })}
                </MuiTypography>
            </div>
        </div>
    );
};
ReceivedBillStatusSection.displayName = 'ReceivedBillStatusSection';
