import { FC, ReactElement } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '&:not(:last-child)': {
            marginBottom: 14,
        },
    },
    label: {
        width: 120,
        paddingTop: 14,
        paddingRight: 14,
        flexShrink: 0,
    },
    labelText: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 600,
    },
    errors: {
        marginTop: 4,
    },
    errorText: {
        fontSize: theme.typography.caption.fontSize,
        color: theme.palette.error.main,
    },
    children: {
        flexGrow: 1,
    },
}));

export const BillDataEditorItem: FC<{
    label: string;
    children: ReactElement;
    isSatisfied: boolean;
    isRequired: boolean;
    errors: {
        isError: boolean;
        message: string;
    }[];
}> = ({ label, children, isRequired, isSatisfied, errors }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={c.root}>
            <div className={c.label}>
                <MuiTypography className={c.labelText}>{label}</MuiTypography>
                {errors.length > 0 && (
                    <div className={c.errors}>
                        {errors.map(({ isError, message }, i) =>
                            isError ? (
                                <MuiTypography key={i} className={c.errorText}>
                                    {message}
                                </MuiTypography>
                            ) : undefined
                        )}
                    </div>
                )}
            </div>
            <div className={c.children}>{children}</div>
        </div>
    );
};
BillDataEditorItem.displayName = 'BillDataEditorItem';
