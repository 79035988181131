import { useState, useEffect } from 'react';

import { BankAccountInTransferInterface, ClientData } from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import {
    BusinessSnapshot,
    BusinessSnapshotItem,
    getInvoiceDataSummary,
    InvoiceDataItem,
    DecimalRoundRule,
} from '@/utils/UodkaClients/Invoices';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { ContactItem } from '@/utils/customHooks';

const getInitialBankAccount = ({
    clientData,
    contactItem,
}: {
    clientData: ClientData;
    contactItem: ContactItem | undefined;
}): BankAccountInTransferInterface | undefined => {
    if (clientData.externalBankAccount) {
        return {
            ...clientData.externalBankAccount.data,
            isDepositAccount: false,
        };
    }
    if (contactItem?.contact.depositAccountFromContactToMe) {
        return {
            ...contactItem.contact.depositAccountFromContactToMe.data,
            isDepositAccount: true,
        };
    }
    return undefined;
};

const getBusinessSnapshotItemsFromBusinessProfile = (profile: BusinessProfile) => {
    const { address, phone, email, websiteUrl } = profile;
    const arr: BusinessSnapshotItem[] = [];
    if (address) {
        arr.push(
            new BusinessSnapshotItem({
                title: 'address',
                value: address,
            })
        );
    }
    if (phone) {
        arr.push(
            new BusinessSnapshotItem({
                title: 'phone',
                value: phone,
            })
        );
    }
    if (email) {
        arr.push(
            new BusinessSnapshotItem({
                title: 'email',
                value: email,
            })
        );
    }
    if (websiteUrl) {
        arr.push(
            new BusinessSnapshotItem({
                title: 'websiteUrl',
                value: websiteUrl,
            })
        );
    }
    return arr;
};

export const useNewBillFields = ({
    clientData,
    clientProfile,
    initialContactItem,
}: {
    clientData: ClientData;
    clientProfile: BusinessProfile | undefined;
    initialContactItem: ContactItem | undefined;
}) => {
    // HOOKS
    const { currentBusiness } = useBusinessCtxAbsolutely();
    const [selectedContactItem, setSelectedContactItem] = useState<ContactItem | undefined>(initialContactItem);
    const [bankAccount, setBankAccount] = useState<BankAccountInTransferInterface | undefined>(
        getInitialBankAccount({
            clientData,
            contactItem: initialContactItem,
        })
    );
    const [isBankAccountManual, setIsBankAccountManual] = useState(!bankAccount);
    const [customizedId, setCustomizedId] = useState<string | undefined>();
    const [title, setTitle] = useState<string | undefined>();
    const [issuer, setIssuer] = useState<BusinessSnapshot>(
        new BusinessSnapshot({
            name: currentBusiness.name,
            items: clientProfile ? getBusinessSnapshotItemsFromBusinessProfile(clientProfile) : [],
        })
    );
    const [recipient, setRecipient] = useState<BusinessSnapshot>(
        new BusinessSnapshot({
            name: initialContactItem?.contact.name || '',
            items: initialContactItem?.profile?.data
                ? getBusinessSnapshotItemsFromBusinessProfile(initialContactItem.profile.data)
                : [],
        })
    );
    const [items, setItems] = useState<InvoiceDataItem[]>([]);
    const [note, setNote] = useState<string | undefined>();
    const [issueDate, setIssueDate] = useState<Date | undefined>();
    const [paymentDueDate, setPaymentDueDate] = useState<Date | undefined>();
    const [decimalRoundRule, setDecimalRoundRule] = useState<DecimalRoundRule>('down');

    // DATA
    const summary = getInvoiceDataSummary({
        decimalRoundRule,
        items,
    });

    // USEEFFECT
    useEffect(() => {
        const newBankAccount = getInitialBankAccount({
            clientData,
            contactItem: selectedContactItem,
        });
        setBankAccount(newBankAccount);
        setIsBankAccountManual(!newBankAccount);
        if (selectedContactItem) {
            const newRecipient = new BusinessSnapshot({
                name: selectedContactItem.contact.name || '',
                items: selectedContactItem.profile?.data
                    ? getBusinessSnapshotItemsFromBusinessProfile(selectedContactItem.profile.data)
                    : [],
            });
            setRecipient(newRecipient);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientData, selectedContactItem]);
    useEffect(() => {
        if (isBankAccountManual === true && bankAccount) {
            setBankAccount(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBankAccountManual]);

    return {
        selectedContactItem,
        setSelectedContactItem,
        bankAccount,
        setBankAccount,
        isBankAccountManual,
        setIsBankAccountManual,
        customizedId,
        setCustomizedId,
        title,
        setTitle,
        issuer,
        setIssuer,
        recipient,
        setRecipient,
        items,
        setItems,
        note,
        setNote,
        issueDate,
        setIssueDate,
        paymentDueDate,
        setPaymentDueDate,
        decimalRoundRule,
        setDecimalRoundRule,
        summary,
    };
};
