import { InvoiceDataItem as GqlInvoiceDataItem } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import { GqlDateTimeData } from '@/utils/UodkaClients/Invoices/graphql/customScalars';
import {
    TaxInInvoiceDataItem,
    getTaxInInvoiceDataItemFromGql,
} from '@/utils/UodkaClients/Invoices/models/InvoiceData/TaxInInvoiceDataItem';

export interface InvoiceDataItemInterface {
    date?: Date;
    title: string;
    unitPrice: number;
    quantity: number;
    taxes: TaxInInvoiceDataItem[];
}

export class InvoiceDataItem implements InvoiceDataItemInterface {
    protected _date?: Date;

    protected _title: string;

    protected _unitPrice: number;

    protected _quantity: number;

    protected _taxes: TaxInInvoiceDataItem[];

    get date() {
        return this._date;
    }

    get title() {
        return this._title;
    }

    get unitPrice() {
        return this._unitPrice;
    }

    get quantity() {
        return this._quantity;
    }

    get taxes() {
        return this._taxes;
    }

    get data() {
        return {
            date: this._date,
            title: this._title,
            unitPrice: this._unitPrice,
            quantity: this._quantity,
            taxes: this._taxes,
        };
    }

    constructor({ date, title, unitPrice, quantity, taxes }: InvoiceDataItemInterface) {
        this._date = date;
        this._title = title;
        this._unitPrice = unitPrice;
        this._quantity = quantity;
        this._taxes = taxes;
    }
}

export const getInvoiceDataItemFromGql = ({ date, title, unitPrice, quantity, taxes }: GqlInvoiceDataItem) => {
    return new InvoiceDataItem({
        date: date ? GqlDateTimeData.serialize(date) : undefined,
        title,
        unitPrice,
        quantity,
        taxes: taxes.map((tax) => getTaxInInvoiceDataItemFromGql(tax)),
    });
};

export const getGqlInvoiceDataItem = ({
    date,
    title,
    unitPrice,
    quantity,
    taxes,
}: InvoiceDataItemInterface): GqlInvoiceDataItem => {
    return {
        date: date ? GqlDateTimeData.parseValue(date) : undefined,
        title,
        unitPrice,
        quantity,
        taxes,
    };
};
