import { FC } from 'react';

import { useLanguage } from '@/utils/customHooks';
import { getStrWithBr } from '@/utils/utilFunctions';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const billThemeColor = '#397DA1';
const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 200,
        maxWidth: 240,
    },
    total: {
        borderBottom: `solid 2px ${billThemeColor}`,
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    totalLabel: {
        color: billThemeColor,
        fontSize: theme.typography.caption.fontSize,
    },
    totalValue: {
        fontSize: 17,
    },
    note: {
        marginTop: 21,
    },
    table: {
        marginTop: 6,
    },
    tr: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:not(:last-child)': {
            marginBottom: 2,
        },
    },
    th: {
        textAlign: 'left',
        fontWeight: 'normal',
        paddingRight: '.5em',
        lineHeight: 1,
        width: 78,
        fontSize: theme.typography.caption.fontSize,
    },
    td: {
        flexGrow: 1,
        fontSize: theme.typography.body2.fontSize,
    },
}));

export const RightTop: FC<{
    total: number;
    paymentDueDate: Date | undefined;
    issueDate: Date | undefined;
    title: string | undefined;
    customizedId: string | undefined;
    note: string | undefined;
}> = ({ total, paymentDueDate, issueDate, title, customizedId, note }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt, getDateStr } = useLanguage();

    // DATA

    return (
        <div className={c.root}>
            <div className={c.total}>
                <div className={c.totalLabel}>{txt({ en: 'Total', ja: 'ご請求金額' })}</div>
                <div className={c.totalValue}>{`¥ ${total.toLocaleString()}`}</div>
            </div>
            <div className={c.table}>
                {paymentDueDate && (
                    <div className={c.tr}>
                        <div className={c.th}>{txt({ en: 'Due date', ja: '支払い期限' })}</div>
                        <div className={c.td}>{getDateStr(paymentDueDate)}</div>
                    </div>
                )}
                {title && (
                    <div className={c.tr}>
                        <div className={c.th}>{txt({ en: 'Subject', ja: '件名' })}</div>
                        <div className={c.td}>{title}</div>
                    </div>
                )}
                {issueDate && (
                    <div className={c.tr}>
                        <div className={c.th}>{txt({ en: 'Date of issue', ja: '発行日' })}</div>
                        <div className={c.td}>{getDateStr(issueDate)}</div>
                    </div>
                )}
                {customizedId && (
                    <div className={c.tr}>
                        <div className={c.th}>{txt({ en: 'Invoice #', ja: '請求書コード' })}</div>
                        <div className={c.td}>{customizedId}</div>
                    </div>
                )}
            </div>
            {note && <div className={c.note}>{getStrWithBr(note)}</div>}
        </div>
    );
};
RightTop.displayName = 'RightTop';
