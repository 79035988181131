import { FC } from 'react';

import { InviteForContactAPIForOwner, ContactAPIForOwner } from '@/utils/UodkaClients/Core';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { getEnvVariables } from '@/utils/envVariables';
import { getShortenedString } from '@/utils/utilFunctions';
import MutationButton from '@/components/0_atom/MutationButton';
import CopyableText from '@/components/0_atom/CopyableText';

import SvgTrash from '@/svgs/trash';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    buttons: {
        display: 'flex',
        margin: '52px 0',
    },
    url: {
        flexGrow: 1,
    },
    deleteButton: {
        marginLeft: 4,
        flexGrow: 0,
    },
}));

export interface ContactAPIForOwnerWithInviteAPI extends ContactAPIForOwner {
    inviteAPI: InviteForContactAPIForOwner;
}

export const Issued: FC<{
    updateItem: (contactAPI: ContactAPIForOwner) => void;
    contactAPI: ContactAPIForOwnerWithInviteAPI;
    handleCloseModal: () => void;
}> = ({ updateItem, contactAPI, handleCloseModal }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const { currentBusiness } = useBusinessCtxAbsolutely();

    // DATA
    const linkUrl = `${getEnvVariables().inviteRootUrl}/contact/${currentBusiness.id}/${contactAPI.id}/${
        contactAPI.inviteAPI.codeText
    }`;

    // HANDLER
    const runRemoveInvite = async () => {
        const result = await contactAPI.inviteAPI.remove();
        updateItem(contactAPI);
        return result;
    };

    return (
        <>
            <MuiTypography>
                {txt({
                    en: `Share the URL below with user who you want to link to ${contactAPI.name}.`,
                    ja: `下のURLを${contactAPI.name}とリンクしたいユーザーに共有してください。`,
                })}
            </MuiTypography>
            <div className={c.buttons}>
                <CopyableText
                    copyText={linkUrl}
                    displayText={getShortenedString({ text: linkUrl, maxLangth: 40 })}
                    className={c.url}
                />
                <MutationButton
                    startIcon={<SvgTrash />}
                    className={c.deleteButton}
                    color={'error'}
                    mutation={{
                        run: runRemoveInvite,
                        description: txt({ en: 'Linked', ja: 'リンク済み' }),
                        onThen: () => {
                            handleCloseModal();
                        },
                    }}
                >
                    {txt({ en: 'Delete', ja: '削除' })}
                </MutationButton>
            </div>
        </>
    );
};
Issued.displayName = 'Issued';
