import { FC } from 'react';
import clsx from 'clsx';

import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import { useLanguage } from '@/utils/customHooks';
import ContactNameWithIcon from '@/components/2_org/ContactNameWithIcon';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    description: {
        marginRight: 14,
        width: 74,
        display: 'block',
        textAlign: 'left',
        whiteSpace: 'normal',
        lineHeight: 1,
        flexShrink: 0,
    },
    descriptionSelf: {
        fontWeight: 600,
        color: theme.palette.text.disabled,
        display: 'block',
        lineHeight: 1.2,
    },
    description1: {
        fontWeight: 600,
        lineHeight: 1.2,
        display: 'block',
        color: theme.palette.text.disabled,
        marginBottom: 3,
    },
    description2: {
        fontWeight: 600,
        lineHeight: 1.2,
        display: 'block',
        color: theme.palette.text.disabled,
        marginTop: 6,
    },
    contactName: {
        '& .MuiTypography-body1': {
            fontSize: 8,
            fontWeight: 600,
        },
    },
    bankAccount: {},
    bankAccountTitle: {
        fontWeight: 600,
        lineHeight: 1,
        display: 'block',
        fontSize: theme.typography.body2.fontSize,
        // color: theme.palette.text.disabled,
    },
    bankAccountDescription: {
        lineHeight: 1.4,
        display: 'block',
        fontSize: theme.typography.caption.fontSize,
        marginTop: 4,
        // color: theme.palette.text.disabled,
    },
}));

export const PreparingBankAccountCard: FC<{
    className?: string;
    remitter:
        | {
              name: string;
              profile: BusinessProfile | undefined;
          }
        | 'self';
}> = ({ className, remitter }) => {
    // STYLE
    const theme = useTheme();
    const c = useStyles(theme);

    // HOOKS
    const { txt, language } = useLanguage();

    return (
        <div className={clsx(c.root, className)}>
            <div className={c.description}>
                {remitter === 'self' ? (
                    <MuiTypography variant={'caption'} className={c.descriptionSelf}>
                        {txt({ en: 'Self Deposit Account', ja: '自己入金口座' })}
                    </MuiTypography>
                ) : (
                    <>
                        {(!language || language === 'en') && (
                            <MuiTypography variant={'caption'} className={c.description1}>
                                {txt({ en: 'Dedicated Deposit Account for' })}
                            </MuiTypography>
                        )}
                        <ContactNameWithIcon
                            contact={remitter}
                            profile={remitter.profile}
                            className={c.contactName}
                            size={'verySmall'}
                        />
                        {language === 'ja' && (
                            <MuiTypography variant={'caption'} className={c.description2}>
                                {txt({ en: '', ja: '専用入金口座' })}
                            </MuiTypography>
                        )}
                    </>
                )}
            </div>
            <div className={c.bankAccount}>
                <MuiTypography className={c.bankAccountTitle}>
                    {txt({
                        en: 'In preparation',
                        ja: '準備中',
                    })}
                </MuiTypography>
                <MuiTypography className={c.bankAccountDescription}>
                    {txt({
                        en: 'Preparing deposit account. Please allow up to 3 business days.',
                        ja: 'Uodka入金口座を準備中です。最長で３営業日お待ちください。',
                    })}
                </MuiTypography>
            </div>
        </div>
    );
};
PreparingBankAccountCard.displayName = 'PreparingBankAccountCard';
