import { BillData } from '@/utils/UodkaClients/Invoices';

import { getBusinessSnapshotData } from '@/views/records/getModelData/getBusinessSnapshotData';
import { getPayeeData } from '@/views/records/getModelData/getPayeeData';
import { getInvoiceDataItemsData } from '@/views/records/getModelData/getInvoiceDataItemsData';
import { getInvoiceDataSummaryData } from '@/views/records/getModelData/getInvoiceDataSummaryData';

export const getBillData = ({ billData }: { billData: BillData }) => {
    const {
        currencyCode,
        customizedId,
        issueDate,
        issuer,
        items,
        note,
        payee,
        paymentDueDate,
        recipient,
        summary,
        title,
    } = billData;
    return {
        currencyCode,
        customizedId,
        issueDate,
        issuer: getBusinessSnapshotData({ businessSnapshot: issuer }),
        items: getInvoiceDataItemsData({ invoiceDataItems: items }),
        note,
        payee: getPayeeData({ payee }),
        paymentDueDate,
        recipient: getBusinessSnapshotData({ businessSnapshot: recipient }),
        summary: getInvoiceDataSummaryData({ invoiceDataSummary: summary }),
        title,
    };
};
