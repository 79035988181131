import { MultilingualText as GqlMultilingualText } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';

interface MultilingualTextInterface {
    en: string;
    ja?: string;
}

export class MultilingualText implements MultilingualTextInterface {
    protected _en: string;

    protected _ja?: string;

    get en() {
        return this._en;
    }

    get ja() {
        return this._ja;
    }

    constructor({ en, ja }: MultilingualTextInterface) {
        this._en = en;
        this._ja = ja;
    }
}

export const getMultilingualTextFromGql = ({ en, ja }: GqlMultilingualText) => {
    return new MultilingualText({
        en,
        ja: ja || undefined,
    });
};
