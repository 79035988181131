import { forwardRef, ReactNode, ReactElement } from 'react';
import clsx from 'clsx';

import { AddMutationInput } from '@/utils/ctxs';
import MutationMenuItem from '@/components/0_atom/MutationMenuItem';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiListSubheader from '@material-ui/core/ListSubheader';

const useStyles = makeStyles((theme) => ({
    subheader: {
        fontWeight: 600,
        // padding: '0 7px',
        lineHeight: '32px',
        color: theme.palette.text.disabled,
        fontSize: theme.typography.caption.fontSize,
    },
}));

export interface MenuItemProps {
    className?: string;
    children: ReactNode;
    onClick?: () => void;
    mutation?: AddMutationInput;
    isDisabled?: boolean;
    isSubheader?: boolean;
    setIcon?: (icon?: ReactElement) => void;
}

export const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
    ({ className, children, onClick, mutation, isDisabled, isSubheader, setIcon }, ref) => {
        // STYLE
        const c = useStyles(useTheme());

        if (isSubheader) {
            return (
                <MuiListSubheader ref={ref} className={clsx(c.subheader, className)}>
                    {children}
                </MuiListSubheader>
            );
        }
        if (mutation) {
            return (
                <MutationMenuItem
                    ref={ref}
                    disabled={isDisabled}
                    newMutation={mutation}
                    onClick={onClick}
                    className={className}
                    setIcon={setIcon || (() => undefined)}
                >
                    {children}
                </MutationMenuItem>
            );
        }
        return (
            <MuiMenuItem ref={ref} disabled={isDisabled} onClick={onClick} className={className}>
                {children}
            </MuiMenuItem>
        );
    }
);
