import { FC, useState } from 'react';

import { BusinessProfileAPIForOwner } from '@/utils/UodkaClients/Profile';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage, useFormField } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import { MutationFormModal } from '@/components/1_mol/FormModal';
import FileDropZone from '@/components/0_atom/FileDropZone';

import SvgImagePlus from '@/svgs/image-plus';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dropZone: {
        // width: 80,
        // height: 50,
    },
    uploadButton: {
        width: 64,
        height: 64,
        border: 'solid 2px rgba(0,0,0,.05)',
        borderRadius: '50%',
        padding: 0,
        '& img': {
            width: '100%',
            height: '100%',
            borderRadius: '50%',
        },
        '& svg': {
            padding: 14,
        },
    },
}));

export const ModifyIconButton: FC<{
    profileAPI: BusinessProfileAPIForOwner;
    setProfile: (clientData: BusinessProfileAPIForOwner) => void;
}> = ({ profileAPI, setProfile }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { currentBusiness } = useBusinessCtxAbsolutely();
    const { txt } = useLanguage();
    const [isOpen, setIsOpen] = useState(false);
    const [editingFile, setEditingFile] = useFormField<File | undefined>(undefined);

    // MUTATION
    const run = async () => {
        const result = await profileAPI.updateIcon(editingFile);
        setProfile(profileAPI);
        return result;
    };

    return (
        <>
            <Button onClick={() => setIsOpen(true)} variant={'contained'}>
                {txt({ en: 'Change', ja: '変更' })}
            </Button>
            <MutationFormModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={txt({
                    en: `Modify ${currentBusiness.name}'s profile`,
                    ja: `${currentBusiness.name}のプロフィールを編集`,
                })}
                mutation={{
                    run,
                    description: txt({
                        en: `Modify ${currentBusiness.name}'s profile`,
                        ja: `${currentBusiness.name}のプロフィールを編集`,
                    }),
                }}
                items={[
                    {
                        isRequired: false,
                        labelText: txt({ en: 'Icon', ja: 'アイコン' }),
                        isSatisfied: !!editingFile,
                        isError: undefined,
                        children: (
                            <>
                                <FileDropZone
                                    className={c.dropZone}
                                    setFiles={(files) => setEditingFile(files[0])}
                                    children={
                                        <Button className={c.uploadButton}>
                                            {editingFile ? (
                                                <img
                                                    src={window.URL.createObjectURL(editingFile) || profileAPI.iconSrc}
                                                    alt={'Uploaded icon'}
                                                />
                                            ) : profileAPI.iconSrc ? (
                                                <img src={profileAPI.iconSrc} alt={'Current icon'} />
                                            ) : (
                                                <SvgImagePlus />
                                            )}
                                        </Button>
                                    }
                                />
                            </>
                        ),
                    },
                ]}
                submitText={txt({ en: 'Update', ja: '変更' })}
            />
        </>
    );
};
ModifyIconButton.displayName = 'views/config/business/Profile/ModifyIconButton';
