import { FC } from 'react';

import { useLanguage } from '@/utils/customHooks';
import { getEnvVariables } from '@/utils/envVariables';

import SvgContacts from '@/svgs/contacts';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import MuiPaper from '@material-ui/core/Paper';
import MuiButton from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        // background: '#fff',
        // borderRadius: 4,
        padding: 44,
        textAlign: 'center',
    },
    icon: {
        width: 60,
        height: 60,
    },
    title: {
        fontWeight: 600,
        fontSize: theme.typography.h2.fontSize,
        marginTop: 22,
    },
    description: {
        fontSize: theme.typography.body2.fontSize,
        marginTop: 14,
    },
    button: {
        marginTop: 22,
        color: '#3D73FF',
    },
}));

export const NoContactDisplay: FC = () => {
    // STYLE
    const theme = useTheme();
    const c = useStyles(theme);

    // HOOKS
    const { txt } = useLanguage();

    // DATA

    return (
        <MuiPaper className={c.root}>
            <SvgContacts className={c.icon} />
            <MuiTypography className={c.title}>{txt({ en: 'No contact', ja: 'コンタクトがありません' })}</MuiTypography>
            <MuiTypography className={c.description}>
                {txt({
                    en: "Let's add a contact and start your first transaction with Quick Start!",
                    ja: 'クイックスタートを見ながら、コンタクトを追加して最初の取引をしてみましょう！',
                })}
            </MuiTypography>
            <MuiButton
                className={c.button}
                href={`${getEnvVariables().quickstartUrl}`}
                target={'_blank'}
                rel={'noopener noreferrer'}
            >
                {txt({
                    en: 'Open Quick Start',
                    ja: 'クイックスタートへ',
                })}
            </MuiButton>
        </MuiPaper>
    );
};
NoContactDisplay.displayName = 'NoContactDisplay';
