import { FC, useState } from 'react';

import { MemberAPIForOwner } from '@/utils/UodkaClients/Core';
import { useLanguage } from '@/utils/customHooks';
import { ButtonWithTextMenu } from '@/components/1_mol/ButtonWithMenu';

import { ModifyMemberRoleModal } from '@/views/config/business/Member/ModifyMemberButton/ModifyMemberRoleModal';
import { DeleteMemberModal } from '@/views/config/business/Member/ModifyMemberButton/DeleteMemberModal';

import SvgTrash from '@/svgs/trash';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    button: {
        background: '#fafafa',
    },
}));

export const ModifyMemberButton: FC<{
    memberAPI: MemberAPIForOwner;
    updateMemberItem: (memberAPI: MemberAPIForOwner) => void;
    removeMemberItem: (id: string) => void;
}> = ({ memberAPI, updateMemberItem, removeMemberItem }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [isOpenModify, setIsOpenModify] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState(false);

    return (
        <>
            <ButtonWithTextMenu
                className={c.button}
                menuEmptyPlaceholder={'unexpected'}
                items={[
                    {
                        text: txt({ en: 'Change role', ja: '権限を変更' }),
                        onClick: () => setIsOpenModify(true),
                        icon: <SvgTrash />,
                    },
                    {
                        text: txt({ en: 'Delete', ja: '削除' }),
                        isWarn: true,
                        onClick: () => setIsOpenDelete(true),
                        icon: <SvgTrash />,
                    },
                ]}
            >
                {txt({ en: 'Modify', ja: '編集' })}
            </ButtonWithTextMenu>
            <ModifyMemberRoleModal
                {...{ isOpen: isOpenModify, setIsOpen: setIsOpenModify, memberAPI, updateMemberItem }}
            />
            <DeleteMemberModal {...{ isOpen: isOpenDelete, setIsOpen: setIsOpenDelete, memberAPI, removeMemberItem }} />
        </>
    );
};
ModifyMemberButton.displayName = 'views/config/business/Member/ModifyMemberButton';
