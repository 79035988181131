import { forwardRef, FC, ReactNode } from 'react';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import { Slide, Dialog, Grow, Fade } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        background: theme.palette.background.paper,
        boxShadow: '0 80px 200px 0 rgba(0,0,0,.3)',
        padding: '22px 27px',
    },
    backdropProps: {
        background: 'none',
    },
}));

const TransitionUp = forwardRef(
    (props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) => {
        return <Slide ref={ref} direction={'up'} {...props} />;
    }
);
const TransitionDown = forwardRef(
    (props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) => {
        return <Slide ref={ref} direction={'down'} {...props} />;
    }
);
const TransitionRight = forwardRef(
    (props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) => {
        return <Slide ref={ref} direction={'right'} {...props} />;
    }
);
const TransitionLeft = forwardRef(
    (props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) => {
        return <Slide ref={ref} direction={'left'} {...props} />;
    }
);
const TransitionGrow = forwardRef(
    (props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) => {
        return <Grow ref={ref} {...props} />;
    }
);
const TransitionFade = forwardRef(
    (props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) => {
        return <Fade ref={ref} {...props} />;
    }
);

const Modal: FC<{
    isOpen: boolean;
    animation?: 'up' | 'left' | 'right' | 'down' | 'grow' | 'fade';
    onClose: () => void;
    children: ReactNode;
    paperClassName?: string;
    backdropClassName?: string;
}> = ({ isOpen, animation, onClose, children, paperClassName, backdropClassName }) => {
    const classes = useStyles(useTheme());

    const Transition = (() => {
        switch (animation) {
            case 'up':
                return TransitionUp;
            case 'down':
                return TransitionDown;
            case 'left':
                return TransitionLeft;
            case 'right':
                return TransitionRight;
            case 'fade':
                return TransitionFade;
            default:
                return TransitionGrow;
        }
    })();

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            classes={{
                paper: clsx(classes.dialogPaper, paperClassName),
            }}
            TransitionComponent={Transition}
            transitionDuration={{ enter: 300, exit: 200 }}
            BackdropProps={{
                classes: {
                    root: clsx(classes.backdropProps, backdropClassName),
                },
            }}
        >
            {children}
        </Dialog>
    );
};
Modal.displayName = 'Modal';
export default Modal;
