import { MutationValidationResult, Language } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { GraphQLSdk } from '@/utils/UodkaClients/Core/graphql/graphqlSdk';
import { ClientData, ClientDataInterface } from '@/utils/UodkaClients/Core/models/ClientData/data/ClientData';
import {
    ExternalBankAccountInterface,
    getExternalBankAccountFromGql,
} from '@/utils/UodkaClients/Core/models/BankAccount';
import { getValidBankAccount, getValidVaHolderNameUserSpace } from '@/utils/UodkaClients/Core/utils';

interface ClientDataAPIForOwnerInterface {
    modifyName: (newName: string) => Promise<MutationValidationResult>;
    modifyNotificationEmail: (newNotificationEmail: string) => Promise<MutationValidationResult>;
    modifyRemitterNameKana: (newRemitterNameKana: string) => Promise<MutationValidationResult>;
    modifyExternalBankAccount: (
        newExternalBankAccount?: ExternalBankAccountInterface
    ) => Promise<MutationValidationResult>;
    modifyLanguage: (newLanguage?: Language) => Promise<MutationValidationResult>;
    archive: () => Promise<MutationValidationResult>;
}

interface ConstructorInput extends ClientDataInterface {
    graphqlSdk: GraphQLSdk;
}

export class ClientDataAPIForOwner extends ClientData implements ClientDataAPIForOwnerInterface {
    _graphqlSdk: GraphQLSdk;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
    }

    async modifyName(newName: string) {
        const { modifyBusinessName } = await this._graphqlSdk.modifyBusinessName({
            input: {
                id: this._id,
                oldValue: this._name,
                newValue: newName,
                requiredInterval: 5,
            },
        });
        this._name = modifyBusinessName.business.name;
        return modifyBusinessName.result;
    }

    async modifyNotificationEmail(newNotificationEmail: string) {
        const { modifyBusinessNotificationEmail } = await this._graphqlSdk.modifyBusinessNotificationEmail({
            input: {
                id: this._id,
                oldValue: this._notificationEmail,
                newValue: newNotificationEmail,
                requiredInterval: 5,
            },
        });
        this._notificationEmail = modifyBusinessNotificationEmail.business.notificationEmail;
        return modifyBusinessNotificationEmail.result;
    }

    async modifyRemitterNameKana(newRemitterNameKana: string) {
        // Contact用のVirtualAccountに利用するので
        const { isValid, fullWidthKatakana } = getValidVaHolderNameUserSpace(newRemitterNameKana);
        if (!(isValid && fullWidthKatakana)) {
            throw new Error('ClientDataAPIForOwner.modifyRemitterNameKana: invalid remitterName');
        }
        const { modifyBusinessRemitterNameKana } = await this._graphqlSdk.modifyBusinessRemitterNameKana({
            input: {
                id: this._id,
                oldValue: this._remitterNameKana,
                newValue: fullWidthKatakana,
                requiredInterval: 5,
            },
        });
        this._remitterNameKana = modifyBusinessRemitterNameKana.business.remitterNameKana;
        return modifyBusinessRemitterNameKana.result;
    }

    async modifyExternalBankAccount(newExternalBankAccount?: ExternalBankAccountInterface) {
        const newValue = (() => {
            if (newExternalBankAccount) {
                const { isValid, bankAccount } = getValidBankAccount(newExternalBankAccount);
                if (isValid && bankAccount) {
                    return bankAccount;
                }
                throw new Error('ClientDataAPIForOwner.modifyExternalBankAccount:invalid newExternalBankAccount');
            }
            return undefined;
        })();
        const { modifyBusinessExternalBankAccount } = await this._graphqlSdk.modifyBusinessExternalBankAccount({
            input: {
                id: this._id,
                oldValue: this._externalBankAccount?.data,
                newValue,
                requiredInterval: 5,
            },
        });
        this._externalBankAccount = modifyBusinessExternalBankAccount.business.externalBankAccount
            ? getExternalBankAccountFromGql(modifyBusinessExternalBankAccount.business.externalBankAccount)
            : undefined;
        return modifyBusinessExternalBankAccount.result;
    }

    async modifyLanguage(newLanguage?: Language) {
        const { modifyBusinessLanguage } = await this._graphqlSdk.modifyBusinessLanguage({
            input: {
                id: this._id,
                oldValue: this._language,
                newValue: newLanguage,
                requiredInterval: 5,
            },
        });
        this._language = modifyBusinessLanguage.business.language || undefined;
        return modifyBusinessLanguage.result;
    }

    async archive() {
        const { archiveBusiness } = await this._graphqlSdk.archiveBusiness({
            input: {
                id: this._id,
            },
        });
        this._isArchived = archiveBusiness.business.isArchived;
        return archiveBusiness.result;
    }
}
