import { FC } from 'react';
import clsx from 'clsx';

import { IssuedBill, IssuedBillAPIForGeneral } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';

import SvgEllipsis from '@/svgs/ellipsis';

import { getIssuedBillStatusHistory } from '@/views/bills/IssuedBills/getIssuedBillStatusHistory';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    '@keyframes ellipsisError': {
        '0%': {
            boxShadow: '0 0 0 0 rgba(255,71,71,.8)',
        },
        '100%': {
            boxShadow: '0 0 0 13px rgba(255,71,71,0)',
        },
    },
    '@keyframes ellipsisProcessing': {
        '0%': {
            boxShadow: '0 0 0 0 rgba(255,211,56,.8)',
        },
        '100%': {
            boxShadow: '0 0 0 13px rgba(255,211,56,0)',
        },
    },
    item: {
        color: '#ddd',
        position: 'relative',
        '&:not(:last-child)': {
            // marginBottom: 31,
            height: 18 + 31,
            '&::before': {
                content: '""',
                display: 'block',
                width: 2,
                height: 47,
                background: '#ddd',
                position: 'absolute',
                top: 9,
                left: 2,
            },
        },
    },
    itemInner: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    ellipsis: {
        width: 6,
        height: 6,
        marginRight: 20,
        marginTop: 7,
    },
    lastEllipsisError: {
        borderRadius: '50%',
        animation: '$ellipsisError 3s linear infinite',
    },
    lastEllipsisProcessing: {
        borderRadius: '50%',
        animation: '$ellipsisProcessing 3s linear infinite',
    },
    title: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 600,
        width: 127,
    },
    date: {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 600,
        width: 94,
        color: '#ddd',
        marginTop: 2,
    },
    description: {
        fontSize: theme.typography.caption.fontSize,
        width: 127 + 94,
        marginTop: 7,
        marginLeft: 26,
        display: 'block',
        fontWeight: 'normal',
    },
    completed: {
        color: theme.palette.success.main,
    },
    error: {
        color: theme.palette.error.main,
    },
    processing: {
        color: theme.palette.warning.main,
    },
    label: {
        color: theme.palette.text.disabled,
        fontWeight: 600,
        fontSize: theme.typography.caption.fontSize,
        marginBottom: 10,
    },
    content: {
        background: '#fff',
        borderRadius: 4,
        padding: '16px 20px',
    },
}));

export const IssuedBillStatusDetails: FC<{
    className?: string;
    issuedBill: IssuedBill | IssuedBillAPIForGeneral;
}> = ({ className, issuedBill }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt, getDateTimeStr } = useLanguage();

    // DATA
    const items = getIssuedBillStatusHistory({ bill: issuedBill, txt });

    return (
        <div className={className}>
            <MuiTypography className={c.label}>{txt({ en: 'Status', ja: 'ステータス' })}</MuiTypography>
            <div className={c.content}>
                {items.map((item, i) => (
                    <div
                        key={i}
                        className={clsx(c.item, {
                            [c.completed]: item.type === 'completed' && i + 1 === items.length,
                            [c.error]: item.type === 'error' && i + 1 === items.length,
                            [c.processing]: item.type === 'processing' && i + 1 === items.length,
                        })}
                    >
                        <div className={c.itemInner}>
                            <SvgEllipsis
                                className={clsx(c.ellipsis, {
                                    [c.lastEllipsisError]: item.type === 'error' && i + 1 === items.length,
                                    [c.lastEllipsisProcessing]: item.type === 'processing' && i + 1 === items.length,
                                })}
                            />
                            <MuiTypography className={c.title}>{item.title}</MuiTypography>
                            {item.date && <MuiTypography className={c.date}>{getDateTimeStr(item.date)}</MuiTypography>}
                        </div>
                        {item.description && <span className={c.description}>{item.description}</span>}
                    </div>
                ))}
            </div>
        </div>
    );
};
IssuedBillStatusDetails.displayName = 'IssuedBillStatusDetails';
