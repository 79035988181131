import { FC, ComponentProps, useCallback } from 'react';
import clsx from 'clsx';

import { useLanguage } from '@/utils/customHooks';
import FormItem from '@/components/0_atom/FormItem';
import Button from '@/components/0_atom/Button';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        marginBottom: 44,
    },
    items: {},
    submits: {
        display: 'flex',
        marginTop: 73,
    },
    cancelButton: {
        flexGrow: 1,
        width: 'calc(50% - 14px)',
    },
    submitButton: {
        flexGrow: 1,
        marginLeft: 14,
        width: 'calc(50% - 14px)',
    },
}));

export const Form: FC<{
    className?: string;
    title: string;
    onSubmit: () => void;
    onClose: () => void;
    items: ComponentProps<typeof FormItem>[];
    submitText: string;
    initializeForm?: () => void;
    isDisplayCancelButton?: boolean;
}> = ({ className, title, onSubmit, items, submitText, initializeForm, onClose, isDisplayCancelButton }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);
    const initialize = useCallback(() => {
        initializeForm && initializeForm();
    }, [initializeForm]);

    // DATA
    const isReady = !items.find((item) => !item.isSatisfied);

    // HANDLER
    const handleSumit = () => {
        onSubmit();
        handleClose();
        setTimeout(initialize, 500);
    };

    return (
        <div className={clsx(c.root, className)}>
            <MuiTypography variant={'h1'} className={c.title}>
                {title}
            </MuiTypography>
            <div className={c.items}>
                {items.map((item, i) => (
                    <FormItem key={i} {...item} />
                ))}
            </div>
            <div className={c.submits}>
                <Button
                    style={{ visibility: !isDisplayCancelButton ? 'hidden' : undefined }}
                    onClick={handleClose}
                    className={c.cancelButton}
                    role={'cancel'}
                    variant={'text'}
                >
                    {txt({ en: 'Cancel', ja: 'キャンセル' })}
                </Button>
                <Button onClick={handleSumit} disabled={!isReady} className={c.submitButton} role={'submit'}>
                    {submitText}
                </Button>
            </div>
        </div>
    );
};
Form.displayName = 'Form';
