import { FC, useState } from 'react';

import { UodkaCoreClientForOwner } from '@/utils/UodkaClients/Core';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import ConfirmationModal from '@/components/1_mol/ConfirmationModal';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    businessInfo: {
        margin: '21px 0 60px 0',
        '& .MuiTypography-root': {
            fontWeight: 600,
            fontSize: theme.typography.body1.fontSize,
            color: theme.palette.primary.main,
        },
    },
}));

export const ArchiveBusinessButton: FC<{
    coreClient: UodkaCoreClientForOwner;
}> = ({ coreClient }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const { currentBusiness } = useBusinessCtxAbsolutely();
    const [isOpen, setIsOpen] = useState(false);

    // MUTATION
    const run = async () => {
        const { result } = await coreClient.archive();
        if (result === 'success') {
            window.location.href = '/';
        }
        return result;
    };

    return (
        <>
            <Button onClick={() => setIsOpen(true)} variant={'contained'} role={'dangerouslySubmit'}>
                {txt({ en: 'Delete this business', ja: 'このビジネスを削除' })}
            </Button>
            <ConfirmationModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={txt({
                    en: `Delete ${currentBusiness.name}`,
                    ja: `${currentBusiness.name}を削除`,
                })}
                mutation={{
                    run,
                    description: txt({
                        en: `Delete ${currentBusiness.name}`,
                        ja: `${currentBusiness.name}を削除`,
                    }),
                }}
                submitText={txt({ en: 'Delete', ja: '削除' })}
                isWarning
            >
                <MuiTypography>
                    {txt({
                        en: 'You are trying to delete following business.',
                        ja: '以下のビジネスを削除しようとしています。',
                    })}
                </MuiTypography>
                <div className={c.businessInfo}>
                    <MuiTypography>{`ID: ${currentBusiness.id}`}</MuiTypography>
                    <MuiTypography>
                        {txt({
                            en: `Name: ${currentBusiness.name}`,
                            ja: `名称: ${currentBusiness.name}`,
                        })}
                    </MuiTypography>
                </div>
                <MuiTypography>
                    {txt({
                        en: 'Deleted business cannot be restored. Are you sure you want to continue?',
                        ja: '削除したビジネスは元に戻せません。本当に削除しますか？',
                    })}
                </MuiTypography>
            </ConfirmationModal>
        </>
    );
};
ArchiveBusinessButton.displayName = 'views/config/business/account/Basic/ArchiveBusinessButton';
