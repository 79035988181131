import { Charge, getChargeFromGql } from '@/utils/UodkaClients/Core';
import { GqlDateTimeData } from '@/utils/UodkaClients/Invoices/graphql/customScalars';
import { IssuedReceipt as GqlIssuedReceipt } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import { ReceiptData, getReceiptDataFromGql } from '@/utils/UodkaClients/Invoices/models/InvoiceData';
import { BillInReceipt, getBillInReceiptFromGql } from '@/utils/UodkaClients/Invoices/models/BillEdge';

interface IssuedReceiptInterface {
    id: string;
    createdAt: Date;
    receiptData: ReceiptData;
    bill?: BillInReceipt;
    charge: Charge;
}

export class IssuedReceipt implements IssuedReceiptInterface {
    protected _id: string;

    protected _createdAt: Date;

    protected _receiptData: ReceiptData;

    protected _bill?: BillInReceipt;

    protected _charge: Charge;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get receiptData() {
        return this._receiptData;
    }

    get bill() {
        return this._bill;
    }

    get charge() {
        return this._charge;
    }

    constructor({ id, createdAt, receiptData, bill, charge }: IssuedReceiptInterface) {
        this._id = id;
        this._createdAt = createdAt;
        this._receiptData = receiptData;
        this._bill = bill;
        this._charge = charge;
    }
}

export const getIssuedReceiptFromGql = ({ id, createdAt, receiptData, bill, charge }: GqlIssuedReceipt) => {
    return new IssuedReceipt({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        receiptData: getReceiptDataFromGql(receiptData),
        bill: bill ? getBillInReceiptFromGql(bill) : undefined,
        charge: getChargeFromGql(charge),
    });
};
