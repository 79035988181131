import { FC, useState, ReactNode } from 'react';

import Button from '@/components/0_atom/Button';
import DatePicker from '@/components/0_atom/DatePicker';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
}));

export const DatePickerButton: FC<{
    className?: string;
    date: Date | undefined;
    setDate: (date: Date | undefined) => void;
    children: ReactNode;
    isEnablePast?: boolean;
    isDisableFuture?: boolean;
}> = ({ className, date, setDate, children, isEnablePast, isDisableFuture }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [anchor, setAnchor] = useState<Element | undefined>(undefined);

    return (
        <>
            <Button className={className} onClick={(e) => setAnchor(e.currentTarget)}>
                {children}
            </Button>
            <DatePicker
                date={date}
                setDate={setDate}
                anchorEl={anchor}
                onClose={() => setAnchor(undefined)}
                isEnablePast={isEnablePast}
                isDisableFuture={isDisableFuture}
            />
        </>
    );
};
DatePickerButton.displayName = 'DatePickerButton';
