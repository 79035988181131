import { FC, useState } from 'react';

import { useLanguage, ContactItem, UseItems, BalanceWithInvoiceItem } from '@/utils/customHooks';
import ExpandableTable from '@/components/0_atom/Table/ExpandableTable';
import ContactNameWithIcon from '@/components/2_org/ContactNameWithIcon';

import { TransferStatus } from '@/views/transactions/TransferStatus';
import { BalanceItemAmount } from '@/views/transactions/BalanceItemAmount';
import { BalanceItemExpanedContent } from '@/views/transactions/BalanceItemExpanedContent';
import { BillModal, BillInfomation } from '@/views/transactions/BillModal';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {},
    total: {
        fontWeight: 600,
        fontSize: theme.typography.h1.fontSize,
    },
    date: {
        fontWeight: 600,
        fontSize: theme.typography.caption.fontSize,
        color: theme.palette.text.disabled,
    },
    contact: {
        '& .MuiTypography-root': {
            fontSize: theme.typography.body2.fontSize,
        },
    },
    charge: {
        fontWeight: 600,
        fontSize: theme.typography.body2.fontSize,
    },
}));

export const BalancesTable: FC<{
    items: ReturnType<UseItems<BalanceWithInvoiceItem>> | undefined;
    filteredIds: string[] | undefined;
    contactItems: ContactItem[] | undefined;
    currentContactItem: ContactItem | undefined;
    setCurrentContactId: (contactId: string | undefined) => void;
}> = ({ filteredIds, items, contactItems, currentContactItem, setCurrentContactId }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt, getDateTimeStr } = useLanguage();
    const [selectedBill, setSelectedBill] = useState<BillInfomation | undefined>(undefined);

    return (
        <>
            <div className={c.root}>
                <ExpandableTable
                    emptyPlaceholder={txt({ en: 'No transaction', ja: '取引がありません' })}
                    widths={[180, `calc(100% - ${180 + 138 + 94}px)`, 138, 94]}
                    expandedWidths={[180, `calc(100% - ${180 + 156 + 94}px)`, 156, 94]}
                    headItems={[
                        {
                            label: txt({
                                en: 'Contact',
                                ja: '相手',
                            }),
                            menu: {
                                emptyPlaceholder: 'unexpected',
                                buttonText: currentContactItem?.contact.name || txt({ en: 'All', ja: '全て' }),
                                items: [
                                    {
                                        text: txt({ en: 'All', ja: '全て' }),
                                        onClick: () => setCurrentContactId(undefined),
                                        isSelected: !currentContactItem,
                                    },
                                    ...(contactItems?.map(({ contact }) => ({
                                        text: contact.name,
                                        onClick: () => setCurrentContactId(contact.id),
                                        isSelected: currentContactItem?.id === contact.id,
                                    })) || [
                                        {
                                            text: txt({ en: 'Fetching...', ja: '取得中...' }),
                                            onClick: () => undefined,
                                            isSelected: false,
                                        },
                                    ]),
                                ],
                            },
                        },
                        {
                            label: txt({
                                en: 'Transaction Amount',
                                ja: '取引金額',
                            }),
                        },
                        {
                            label: txt({
                                en: 'State',
                                ja: '状態',
                            }),
                        },
                        {
                            label: txt({
                                en: 'Date',
                                ja: '日時',
                            }),
                        },
                    ]}
                    rowItems={
                        items && filteredIds
                            ? filteredIds.map((id) => {
                                  const { balanceWithInvoice, contactProfile } = items.getItemAbsolutely(id);
                                  const { receivedPayment } = balanceWithInvoice;
                                  const { transfer, charge, amountIncrease, createdAt } = balanceWithInvoice.balance;
                                  const netTransactionAmount = (() => {
                                      if (transfer) {
                                          return transfer.amount * -1;
                                      }
                                      if (charge) {
                                          return charge.amount;
                                      }
                                      throw new Error('BalancesTable: later: got no transfer or charge.');
                                  })();
                                  return {
                                      items: [
                                          {
                                              children: transfer ? (
                                                  <ContactNameWithIcon
                                                      className={c.contact}
                                                      contact={transfer.contact}
                                                      profile={contactProfile}
                                                  />
                                              ) : charge?.depositAccount.fromContact ? (
                                                  <ContactNameWithIcon
                                                      className={c.contact}
                                                      contact={charge.depositAccount.fromContact}
                                                      profile={contactProfile}
                                                  />
                                              ) : charge ? (
                                                  <MuiTypography className={c.charge}>
                                                      {txt({ en: 'Self Deposit', ja: '自己入金' })}
                                                  </MuiTypography>
                                              ) : (
                                                  <></>
                                              ),
                                          },
                                          {
                                              children: (
                                                  <BalanceItemAmount
                                                      isExpaned={items.currentItem?.id === id && !!transfer}
                                                      amount={netTransactionAmount}
                                                      status={
                                                          transfer && !transfer.issuedAt && transfer.error
                                                              ? 'canceled'
                                                              : transfer && !transfer.issuedAt
                                                              ? 'processing'
                                                              : 'completed'
                                                      }
                                                  />
                                              ),
                                          },
                                          {
                                              children: transfer ? (
                                                  <TransferStatus transfer={transfer} />
                                              ) : //   ) : receivedPayment?.incomingTransfer ? (
                                              //       <TransferStatus transfer={receivedPayment.incomingTransfer} />
                                              undefined,
                                          },
                                          {
                                              children: !(items.currentItem?.id === id && transfer) ? (
                                                  <MuiTypography className={c.date}>
                                                      {getDateTimeStr(createdAt)}
                                                  </MuiTypography>
                                              ) : (
                                                  <></>
                                              ),
                                          },
                                      ],
                                      isSelected: items.currentItem?.id === id,
                                      onClick: () => items.setSelectedItem(id),
                                      expandedChildren: (
                                          <BalanceItemExpanedContent
                                              balanceWithInvoice={balanceWithInvoice}
                                              setSelectedBill={setSelectedBill}
                                          />
                                      ),
                                  };
                              })
                            : undefined
                    }
                />
            </div>
            <BillModal billInfomation={selectedBill} onClose={() => setSelectedBill(undefined)} />
        </>
    );
};
BalancesTable.displayName = 'BalancesTable';
