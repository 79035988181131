import { Resource, getResourceFromGql } from '@/utils/UodkaClients/Cert/models';
import { GraphQLSdk } from '@/utils/UodkaClients/Cert/graphql/graphqlSdk';

type GetResourceInput = {
    resourceId: string;
};

interface UodkaCertClientForObserverInterface {
    getResource(input: GetResourceInput): Promise<Resource>;
}

interface ConstructorInput {
    graphqlSdk: GraphQLSdk;
    id: string;
}

export class UodkaCertClientForObserver implements UodkaCertClientForObserverInterface {
    protected _graphqlSdk: GraphQLSdk;

    protected _id: string;

    constructor({ graphqlSdk, id }: ConstructorInput) {
        this._graphqlSdk = graphqlSdk;
        this._id = id;
    }

    async getResource({ resourceId }: GetResourceInput) {
        const { getResource } = await this._graphqlSdk.getResource({
            input: {
                businessId: this._id,
                resourceId,
            },
        });
        return getResourceFromGql(getResource.resource);
    }
}
