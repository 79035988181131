import { Transfer } from '@/utils/UodkaClients/Core';

export const getTransferData = ({ transfer }: { transfer: Transfer }) => {
    const {
        amount,
        bankAccount,
        contact,
        createdAt,
        error,
        feeOnIssuer,
        id,
        issuedAt,
        issuer,
        operatorApp,
        remitterNameKana,
    } = transfer;
    return {
        amount,
        bankAccount: (() => {
            const { isDepositAccount, bankCode, branchCode, accountNumber, accountHolderName } = bankAccount;
            return { isDepositAccount, bankCode, branchCode, accountNumber, accountHolderName };
        })(),
        contact: (() => {
            const { additionalId, id, isArchived, name, relatedBusiness } = contact;
            return {
                additionalId,
                id,
                isArchived,
                name,
                relatedBusiness: (() => {
                    if (relatedBusiness) {
                        const { id, isArchived, language, name } = relatedBusiness;
                        return { id, isArchived, language, name };
                    }
                    return undefined;
                })(),
            };
        })(),
        createdAt,
        error: (() => {
            if (error) {
                const { code, message, setAt } = error;
                return { code, message, setAt };
            }
            return undefined;
        })(),
        feeOnIssuer: (() => {
            const { priceBeforeTax, taxRate, total } = feeOnIssuer;
            return { priceBeforeTax, taxRate, total };
        })(),
        id,
        issuedAt,
        issuer: (() => {
            const { id, isArchived, language, name } = issuer;
            return { id, isArchived, language, name };
        })(),
        operatorApp: (() => {
            if (operatorApp) {
                const { id, name } = operatorApp;
                return { id, name };
            }
            return undefined;
        })(),
        remitterNameKana,
    };
};
