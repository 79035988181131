import { FC, ReactNode, ReactElement, useState } from 'react';
import clsx from 'clsx';

import Button from '@/components/0_atom/Button';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    tabs: {
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        '& .MuiButton-root': {
            justifyContent: 'flex-start',
            textAlign: 'left',
            fontSize: theme.typography.caption.fontSize,
            // fontWeight: 600,
            borderRadius: '4px 0 0 4px',
        },
    },
    selectedButton: {
        background: '#f6f6f6',
    },
    content: {
        // background: '#fafafa',
        // padding: '12px 17px',
        paddingLeft: 17,
        paddingTop: 6,
        borderLeft: 'solid 1px rgba(0,0,0,.03)',
    },
}));

export type TabData = {
    label: string;
    content: ReactElement;
};

export const VerticalTabBox: FC<{
    className?: string;
    tabDatas: TabData[];
    initialIndex?: number;
    placeholder?: ReactNode;
}> = ({ className, tabDatas, initialIndex, placeholder }) => {
    // STYLE
    const theme = useTheme();
    const c = useStyles(theme);

    // HOOKS
    const [selectedIndex, setSelectedIndex] = useState(initialIndex);

    // DATA

    return (
        <div className={clsx(c.root, className)}>
            <div className={c.tabs}>
                {tabDatas.map(({ label }, i) => (
                    <Button
                        key={i}
                        onClick={() => setSelectedIndex(i)}
                        className={selectedIndex === i ? c.selectedButton : undefined}
                    >
                        {label}
                    </Button>
                ))}
            </div>
            <div className={c.content}>
                {selectedIndex === undefined
                    ? placeholder
                    : tabDatas.find((_, i) => i === selectedIndex)?.content || placeholder || <></>}
            </div>
        </div>
    );
};
VerticalTabBox.displayName = 'VerticalTabBox';
