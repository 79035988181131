import { Transfer, getTransferFromGql } from '@/utils/UodkaClients/Core';
import { GqlDateTimeData } from '@/utils/UodkaClients/Invoices/graphql/customScalars';
import { ReceiptInReceivedBill as GqlReceiptInReceivedBill } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import { ReceiptData, getReceiptDataFromGql } from '@/utils/UodkaClients/Invoices/models/InvoiceData';

interface ReceiptInReceivedBillInterface {
    id: string;
    createdAt: Date;
    receiptData: ReceiptData;
    transfer: Transfer;
}

export class ReceiptInReceivedBill implements ReceiptInReceivedBillInterface {
    protected _id: string;

    protected _createdAt: Date;

    protected _receiptData: ReceiptData;

    protected _transfer: Transfer;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get receiptData() {
        return this._receiptData;
    }

    get transfer() {
        return this._transfer;
    }

    constructor({ id, createdAt, receiptData, transfer }: ReceiptInReceivedBillInterface) {
        this._id = id;
        this._createdAt = createdAt;
        this._receiptData = receiptData;
        this._transfer = transfer;
    }
}

export const getReceiptInReceivedBillFromGql = ({ id, createdAt, receiptData, transfer }: GqlReceiptInReceivedBill) => {
    return new ReceiptInReceivedBill({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        receiptData: getReceiptDataFromGql(receiptData),
        transfer: getTransferFromGql(transfer),
    });
};
