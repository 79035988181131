import { BalancesFilter } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import {
    Balance,
    ClientData,
    Contact,
    getClientDataFromGql,
    getBalanceFromGql,
    getContactFromGql,
} from '@/utils/UodkaClients/Core/models';
import { GraphQLSdk } from '@/utils/UodkaClients/Core/graphql/graphqlSdk';

interface UodkaCoreClientForObserverInterface {
    getRecentBalance: () => Promise<Balance | undefined>;
    getBalances: (filter?: BalancesFilter) => Promise<Balance[]>;
    getData: () => Promise<ClientData>;
    getContact: (input: { id: string }) => Promise<Contact | undefined>;
    getContacts: () => Promise<Contact[]>;
}

interface ConstructorInput {
    graphqlSdk: GraphQLSdk;
    id: string;
}

export class UodkaCoreClientForObserver implements UodkaCoreClientForObserverInterface {
    protected _graphqlSdk: GraphQLSdk;

    protected _id: string;

    constructor({ graphqlSdk, id }: ConstructorInput) {
        this._graphqlSdk = graphqlSdk;
        this._id = id;
    }

    async getData() {
        const { business } = await this._graphqlSdk.business({
            id: this._id,
        });
        return getClientDataFromGql(business);
    }

    async getRecentBalance() {
        const { recentBalance } = await this._graphqlSdk.recentBalance({
            businessId: this._id,
        });
        return recentBalance ? getBalanceFromGql(recentBalance) : undefined;
    }

    async getBalances(filter?: BalancesFilter) {
        const { balances } = await this._graphqlSdk.balances({
            businessId: this._id,
            filter,
        });
        return balances.map((balance) => getBalanceFromGql(balance));
    }

    async getContact({ id }: { id: string }) {
        const { contact } = await this._graphqlSdk.contact({
            businessId: this._id,
            id,
        });
        return contact ? getContactFromGql(contact) : undefined;
    }

    async getContacts() {
        const { contacts } = await this._graphqlSdk.contacts({
            businessId: this._id,
        });
        return contacts.map((contact) => getContactFromGql(contact));
    }
}
