import { FC } from 'react';
import clsx from 'clsx';

import SvgArrow from '@/svgs/fa-arrow-up-solid';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    processing: {},
    completed: {},
    canceled: {
        opacity: 0.4,
        color: theme.palette.text.primary,
    },
    income: {
        // color: '#1D77FF',
    },
    expense: {
        // color: '#FF5E3A',
    },
    arrowIncome: {
        width: 12,
        height: 12,
        transform: 'rotate(90deg)',
        color: '#1D77FF',
    },
    arrowExpense: {
        width: 12,
        height: 12,
        transform: 'rotate(270deg)',
    },
    arrowCompleted: {
        color: 'rgb(96, 211, 78)',
    },
    arrowProcessing: {
        color: 'rgb(255, 211, 56)',
    },
    arrowCanceled: {
        color: theme.palette.text.primary,
    },
    priceBox: {},
    yenSign: {
        fontWeight: 600,
        fontSize: theme.typography.body1.fontSize,
        marginLeft: 11,
        marginRight: 2,
        display: 'inline-block',
    },
    text: {
        fontWeight: 600,
        fontSize: theme.typography.h1.fontSize,
        display: 'inline-block',
    },
    expanded: {
        // justifyContent: 'flex-end',
        // paddingRight: 77,
    },
}));

export const BalanceItemAmount: FC<{
    isExpaned: boolean;
    amount: number;
    status: 'completed' | 'processing' | 'canceled';
}> = ({ isExpaned, amount, status }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div
            className={clsx(c.root, {
                [c.income]: amount > 0,
                [c.expense]: amount < 0 && status !== 'canceled',
                [c.processing]: status === 'processing',
                [c.canceled]: status === 'canceled',
                [c.completed]: status === 'completed',
                [c.expanded]: isExpaned,
            })}
        >
            <SvgArrow
                className={clsx({
                    [c.arrowIncome]: amount > 0,
                    [c.arrowExpense]: amount < 0,
                    [c.arrowCompleted]: amount < 0 && status === 'completed',
                    [c.arrowProcessing]: status === 'processing',
                    [c.arrowCanceled]: status === 'canceled',
                })}
            />
            <div className={c.priceBox}>
                <MuiTypography className={c.yenSign}>{'¥'}</MuiTypography>
                <MuiTypography className={c.text}>{Math.abs(amount).toLocaleString()}</MuiTypography>
            </div>
        </div>
    );
};
BalanceItemAmount.displayName = 'BalanceItemAmount';
