import { Signature, getSignatureFromGql } from '@/utils/UodkaClients/Cert';
import { GqlDateTimeData } from '@/utils/UodkaClients/Invoices/graphql/customScalars';
import { ReceivingSignature as GqlReceivingSignature } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';

export interface ReceivingSignatureInterface {
    billReceivedAt: Date;
    signature: Signature;
}

export class ReceivingSignature implements ReceivingSignatureInterface {
    protected _billReceivedAt: Date;

    protected _signature: Signature;

    get billReceivedAt() {
        return this._billReceivedAt;
    }

    get signature() {
        return this._signature;
    }

    constructor({ billReceivedAt, signature }: ReceivingSignatureInterface) {
        this._billReceivedAt = billReceivedAt;
        this._signature = signature;
    }
}

export const getReceivingSignatureFromGql = ({ billReceivedAt, signature }: GqlReceivingSignature) => {
    return new ReceivingSignature({
        billReceivedAt: GqlDateTimeData.serialize(billReceivedAt),
        signature: getSignatureFromGql(signature),
    });
};
