import { FC } from 'react';

import { useUserCtx, useErrorCtx, useHealthCtx } from '@/utils/ctxs';
import PreauthDisplay from '@/components/2_org/PreauthDisplay';
import NoUserDisplay from '@/components/2_org/NoUserDisplay';
import BusinessSelector from '@/components/2_org/BusinessSelector';
import ErrorDisplay from '@/components/2_org/ErrorDisplay';
import FetchingText from '@/components/0_atom/FetchingText';

import SvgUodkaLogo from '@/svgs/uodkalogo';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateY(-50%) translateX(-50%)',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        height: 20,
    },
    content: {
        marginTop: 24,
    },
}));

const PreConsoleTemplate: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { is404, is500 } = useErrorCtx();
    const { userClient, isFetching, firebaseUser } = useUserCtx();
    const { cert, core, profile, invoices } = useHealthCtx();

    // DATA
    const isAllHealthOk =
        core.health?.code === 'ok' &&
        profile.health?.code === 'ok' &&
        cert.health?.code === 'ok' &&
        invoices.health?.code === 'ok';

    return (
        <div className={c.root}>
            <SvgUodkaLogo className={c.logo} />
            <div className={c.content}>
                {isFetching || !isAllHealthOk ? (
                    <FetchingText />
                ) : is500 ? (
                    <ErrorDisplay errorCode={500} />
                ) : is404 ? (
                    <ErrorDisplay errorCode={404} />
                ) : userClient ? (
                    <BusinessSelector />
                ) : firebaseUser ? (
                    <NoUserDisplay />
                ) : (
                    <PreauthDisplay />
                )}
            </div>
        </div>
    );
};
PreConsoleTemplate.displayName = 'PreConsoleTemplate';
export default PreConsoleTemplate;
