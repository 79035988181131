import { FC } from 'react';

import { BalanceWithInvoice } from '@/utils/UodkaClients/Invoices';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import { SelfDepositAccountCard, BankAccountText } from '@/components/2_org/BankAccountCard';
import { BankAccountCardBase } from '@/components/2_org/BankAccountCard/BankAccountCardBase';

import SvgBill from '@/svgs/bill';

import { TransferStatusDetails } from '@/views/transactions/TransferStatusDetails';
import { BillInfomation } from '@/views/transactions/BillModal';
import { VerticalTabBox, TabData } from '@/views/transactions/VerticalTabBox';
import { PaymentStatement } from '@/views/transactions/PaymentStatement';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    chargeRemitterNameLabel: {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 600,
        display: 'inline-block',
        color: theme.palette.text.disabled,
        marginRight: '1em',
    },
    chargeRemitterName: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 600,
        display: 'inline-block',
    },
    transferStatus: {
        marginLeft: 83,
    },
    bankAccountCardDescription: {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 600,
        color: theme.palette.text.disabled,
    },
    bankAccountText: {
        maxWidth: 180,
    },
    openBillButton: {
        border: 'solid 2px rgba(0,0,0,.03)',
        boxShadow: '0 0 8px 0 rgba(0,0,0,.04)',
        padding: 12,
        '& .MuiButton-label': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& svg': {
                height: 28,
                marginBottom: 7,
            },
            '& span': {
                fontSize: theme.typography.caption.fontSize,
                fontWeight: 600,
                lineHeight: '1',
            },
        },
    },
}));

export const BalanceItemExpanedContent: FC<{
    balanceWithInvoice: BalanceWithInvoice;
    setSelectedBill: (billInfomation: BillInfomation | undefined) => void;
}> = ({
    balanceWithInvoice: {
        balance: { transfer, charge },
        issuedPayment,
        receivedPayment,
    },
    setSelectedBill,
}) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const { currentBusiness } = useBusinessCtxAbsolutely();

    // DATA
    const payment = issuedPayment || receivedPayment;
    const billInformation = (() => {
        const contactId = transfer?.contact.id || charge?.depositAccount.fromContact?.id; // 必ずどっちかあるはず
        const billId = payment?.bill?.id;
        if (issuedPayment && contactId && billId) {
            return {
                direction: 'received' as const,
                contactId,
                billId,
            };
        }
        if (receivedPayment && contactId && billId) {
            return {
                direction: 'issued' as const,
                contactId,
                billId,
            };
        }
        return undefined;
    })();
    const tabDatas: TabData[] = (() => {
        const arr: TabData[] = [];
        if (transfer || payment) {
            const transferAmount = transfer?.amount || payment?.paymentData.summary.total || 0; // exact
            arr.push({
                label: txt({ en: 'Statement', ja: '明細' }),
                content: (
                    <PaymentStatement
                        payment={payment}
                        transfer={transfer}
                        sumTitle={
                            receivedPayment
                                ? txt({ en: 'Amount of remittance', ja: '受取金額' })
                                : txt({ en: 'Amount of remittance', ja: '送金額' })
                        }
                        total={transferAmount}
                    />
                ),
            });
        }
        if (transfer) {
            arr.push({
                label: txt({ en: 'Bank account', ja: '入金先口座' }),
                content: (
                    <BankAccountText
                        className={c.bankAccountText}
                        bankAccount={transfer.bankAccount}
                        isWithUodkaLogo={transfer.bankAccount.isDepositAccount}
                    />
                ),
            });
        }
        if (charge) {
            arr.push({
                label: txt({ en: 'Bank account', ja: '入金口座' }),
                content: charge.depositAccount.fromContact ? (
                    <BankAccountCardBase
                        bankAccount={{
                            ...charge.depositAccount.data,
                            isDepositAccount: true,
                        }}
                        accountDescription={
                            <MuiTypography className={c.bankAccountCardDescription}>
                                {txt({ en: 'Dedicated deposit account', ja: '専用入金口座' })}
                            </MuiTypography>
                        }
                    />
                ) : (
                    <SelfDepositAccountCard depositAccount={charge.depositAccount} />
                ),
            });
            arr.push({
                label: txt({ en: 'Remitter name', ja: '振込名義人' }),
                content: (
                    <>
                        <MuiTypography className={c.chargeRemitterNameLabel}>
                            {txt({ en: 'Remitter name', ja: '振込名義人' })}
                        </MuiTypography>
                        <MuiTypography className={c.chargeRemitterName}>{charge.remitterNameKana}</MuiTypography>
                    </>
                ),
            });
        }
        if (billInformation) {
            arr.push({
                label: txt({ en: 'Related Invoices', ja: '関連データ' }),
                content: (
                    <Button onClick={() => setSelectedBill(billInformation)} className={c.openBillButton}>
                        <SvgBill />
                        <span>{txt({ en: 'Bill', ja: '請求' })}</span>
                    </Button>
                ),
            });
        }
        return arr;
    })();

    return (
        <div className={c.root}>
            <VerticalTabBox tabDatas={tabDatas} initialIndex={0} />
            {transfer && <TransferStatusDetails className={c.transferStatus} transfer={transfer} />}
        </div>
    );
};
BalanceItemExpanedContent.displayName = 'BalanceItemExpanedContent';
