import { FC } from 'react';
import clsx from 'clsx';

import { ClientData } from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import {
    ReceivedBill,
    ReceivedBillAPIForGeneral,
    IssuedPayment,
    PaymentInReceivedBill,
} from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';

import SvgEllipsis from '@/svgs/ellipsis';

import { getReceivedBillStatusHistory } from '@/views/bills/ReceivedBills/getReceivedBillStatusHistory';
import { ActionButtons } from '@/views/bills/BillDrawer/ReceivedBill/ActionButtons';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    '@keyframes ellipsisError': {
        '0%': {
            boxShadow: '0 0 0 0 rgba(255,71,71,.8)',
        },
        '100%': {
            boxShadow: '0 0 0 13px rgba(255,71,71,0)',
        },
    },
    '@keyframes ellipsisProcessing': {
        '0%': {
            boxShadow: '0 0 0 0 rgba(255,211,56,.8)',
        },
        '100%': {
            boxShadow: '0 0 0 13px rgba(255,211,56,0)',
        },
    },
    '@keyframes ellipsisArrival': {
        '0%': {
            boxShadow: '0 0 0 0 rgba(255,132,132,.8)',
        },
        '100%': {
            boxShadow: '0 0 0 13px rgba(255,132,132,0)',
        },
    },
    item: {
        color: '#ddd',
        position: 'relative',
        '&:not(:last-child)': {
            // marginBottom: 31,
            height: 18 + 31,
            '&::before': {
                content: '""',
                display: 'block',
                width: 2,
                height: 47,
                background: '#ddd',
                position: 'absolute',
                top: 9,
                left: 2,
            },
        },
    },
    itemInner: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    ellipsis: {
        width: 6,
        height: 6,
        marginRight: 20,
        marginTop: 7,
    },
    lastEllipsisError: {
        borderRadius: '50%',
        animation: '$ellipsisError 3s linear infinite',
    },
    lastEllipsisProcessing: {
        borderRadius: '50%',
        animation: '$ellipsisProcessing 3s linear infinite',
    },
    lastEllipsisArrival: {
        borderRadius: '50%',
        animation: '$ellipsisArrival 3s linear infinite',
    },
    title: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 600,
        width: 127,
    },
    date: {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 600,
        width: 94,
        color: '#ddd',
        marginTop: 2,
    },
    description: {
        fontSize: theme.typography.caption.fontSize,
        width: 127 + 94,
        marginTop: 7,
        marginLeft: 26,
        display: 'block',
        fontWeight: 'normal',
    },
    completed: {
        color: theme.palette.success.main,
    },
    error: {
        color: theme.palette.error.main,
    },
    processing: {
        color: theme.palette.warning.main,
    },
    arrival: {
        color: 'rgb(255,132,132)',
    },
    label: {
        color: theme.palette.text.disabled,
        fontWeight: 600,
        fontSize: theme.typography.caption.fontSize,
        marginBottom: 10,
    },
    content: {
        background: '#fff',
        borderRadius: 4,
        padding: '16px 20px',
    },
}));

export const ReceivedBillStatusDetails: FC<{
    className?: string;
    receivedBill: ReceivedBill | ReceivedBillAPIForGeneral;
    payment: IssuedPayment | PaymentInReceivedBill | undefined;
    onAddTransfer: (input: { payment: IssuedPayment }) => void;
    contactInfo: {
        id: string;
        name: string;
        profile: BusinessProfile | undefined;
    };
    clientData: ClientData;
    clientProfile: BusinessProfile | undefined;
    removeBillItem: () => void;
}> = ({ className, receivedBill, payment, onAddTransfer, contactInfo, clientData, clientProfile, removeBillItem }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt, getDateTimeStr } = useLanguage();

    // DATA
    const items = getReceivedBillStatusHistory({
        bill: {
            signatures: receivedBill.signatures,
            payment,
        },
        txt,
    });
    const isActionButtonsEnabled = (() => {
        if (receivedBill instanceof ReceivedBillAPIForGeneral) {
            if (!payment) {
                return true;
            }
            if (payment.transfer.error) {
                return true;
            }
        }
        return false;
    })();

    return (
        <div className={className}>
            <MuiTypography className={c.label}>{txt({ en: 'Status', ja: 'ステータス' })}</MuiTypography>
            <div className={c.content}>
                <div>
                    {items.map((item, i) => (
                        <div
                            key={i}
                            className={clsx(c.item, {
                                [c.completed]: item.type === 'completed' && i + 1 === items.length,
                                [c.error]: item.type === 'error' && i + 1 === items.length,
                                [c.processing]: item.type === 'processing' && i + 1 === items.length,
                                [c.arrival]: item.type === 'arrival' && i + 1 === items.length,
                            })}
                        >
                            <div className={c.itemInner}>
                                <SvgEllipsis
                                    className={clsx(c.ellipsis, {
                                        [c.lastEllipsisError]: item.type === 'error' && i + 1 === items.length,
                                        [c.lastEllipsisProcessing]:
                                            item.type === 'processing' && i + 1 === items.length,
                                        [c.lastEllipsisArrival]: item.type === 'arrival' && i + 1 === items.length,
                                    })}
                                />
                                <MuiTypography className={c.title}>{item.title}</MuiTypography>
                                {item.date && (
                                    <MuiTypography className={c.date}>{getDateTimeStr(item.date)}</MuiTypography>
                                )}
                            </div>
                            {item.description && <span className={c.description}>{item.description}</span>}
                        </div>
                    ))}
                </div>
                {isActionButtonsEnabled && receivedBill instanceof ReceivedBillAPIForGeneral && (
                    <ActionButtons
                        receivedBillAPI={receivedBill}
                        onAddTransfer={onAddTransfer}
                        contactInfo={contactInfo}
                        clientData={clientData}
                        clientProfile={clientProfile}
                        removeBillItem={removeBillItem}
                    />
                )}
            </div>
        </div>
    );
};
ReceivedBillStatusDetails.displayName = 'ReceivedBillStatusDetails';
