import { Contact, ContactAPIForOwner } from '@/utils/UodkaClients/Core';
import {
    ReceivedBillsFilter,
    UodkaInvoicesClientForObserver,
    UodkaInvoicesClientForGeneral,
    UodkaInvoicesClientForOwner,
} from '@/utils/UodkaClients/Invoices';
import { ContactItem } from '@/utils/customHooks';

import {
    ReceivedBillItem,
    ReceivedBillItemForObserver,
    ReceivedBillItemForGeneral,
    ReceivedBillItemForOwner,
} from '@/views/bills/useBillItems/fetchReceivedBill/ReceivedBillItem';

const fetchReceivedBillItemForOwners = async ({
    invoicesClient,
    filter,
    contactItems,
}: {
    invoicesClient: UodkaInvoicesClientForOwner;
    filter: ReceivedBillsFilter | undefined;
    contactItems: ContactItem<ContactAPIForOwner>[];
}): Promise<ReceivedBillItemForOwner[]> => {
    const receivedBills = await invoicesClient.getReceivedBillAPIs({ filter });
    const receivedBillItems = await Promise.all(
        receivedBills.map(async (receivedBill) => {
            const contactItem = contactItems.find(({ id }) => id === receivedBill.contact.id);
            if (!contactItem) {
                throw new Error('fetchReceivedBillItemForOwners: Unexpectedly (!contactItem)');
            }
            return {
                id: receivedBill.id,
                receivedBill,
                contactItem,
            };
        })
    );
    return receivedBillItems;
};

const fetchReceivedBillItemForGenerals = async ({
    invoicesClient,
    filter,
    contactItems,
}: {
    invoicesClient: UodkaInvoicesClientForGeneral;
    filter: ReceivedBillsFilter | undefined;
    contactItems: ContactItem<Contact>[];
}): Promise<ReceivedBillItemForGeneral[]> => {
    const receivedBills = await invoicesClient.getReceivedBillAPIs({ filter });
    const receivedBillItems = await Promise.all(
        receivedBills.map(async (receivedBill) => {
            const contactItem = contactItems.find(({ id }) => id === receivedBill.contact.id);
            if (!contactItem) {
                throw new Error('fetchReceivedBillItemForGenerals: Unexpectedly (!contactItem)');
            }
            return {
                id: receivedBill.id,
                receivedBill,
                contactItem,
            };
        })
    );
    return receivedBillItems;
};

const fetchReceivedBillItemForObservers = async ({
    invoicesClient,
    filter,
    contactItems,
}: {
    invoicesClient: UodkaInvoicesClientForObserver;
    filter: ReceivedBillsFilter | undefined;
    contactItems: ContactItem<Contact>[];
}): Promise<ReceivedBillItemForObserver[]> => {
    const receivedBills = await invoicesClient.getReceivedBills({ filter });
    const receivedBillItems = await Promise.all(
        receivedBills.map(async (receivedBill) => {
            const contactItem = contactItems.find(({ id }) => id === receivedBill.contact.id);
            if (!contactItem) {
                throw new Error('fetchReceivedBillItemForObservers: Unexpectedly (!contactItem)');
            }
            return {
                id: receivedBill.id,
                receivedBill,
                contactItem,
            };
        })
    );
    return receivedBillItems;
};

const getIsContactAPIForOwners = (contactItems: ContactItem[]): contactItems is ContactItem<ContactAPIForOwner>[] => {
    return contactItems[0] instanceof ContactAPIForOwner;
};

export const fetchReceivedBills = async ({
    invoicesClient,
    filter,
    contactItems,
}: {
    invoicesClient: UodkaInvoicesClientForObserver | UodkaInvoicesClientForGeneral | UodkaInvoicesClientForOwner;
    filter: ReceivedBillsFilter | undefined;
    contactItems: ContactItem[];
}): Promise<ReceivedBillItem[]> => {
    if (invoicesClient instanceof UodkaInvoicesClientForOwner && getIsContactAPIForOwners(contactItems)) {
        return fetchReceivedBillItemForOwners({ invoicesClient, filter, contactItems });
    }
    if (invoicesClient instanceof UodkaInvoicesClientForGeneral) {
        return fetchReceivedBillItemForGenerals({ invoicesClient, filter, contactItems });
    }
    return fetchReceivedBillItemForObservers({ invoicesClient, filter, contactItems });
};
