import { FC, useState } from 'react';
import clsx from 'clsx';

import { ClientData, BankAccountInTransferInterface } from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import { useLanguage, ContactItem } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import { FormModalForBankAccount } from '@/components/1_mol/FormModal';
import { ButtonWithMenu } from '@/components/1_mol/ButtonWithMenu';
import {
    ExternalBankAccountCard,
    ExternalBankAccountCardWithHolder,
    DepositAccountCardWithHolder,
    PreparingBankAccountCardWithHolder,
} from '@/components/2_org/BankAccountCard';

import SvgChevronUp from '@/svgs/fa-chevron-up-solid';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
    },
    button1: {
        border: 'solid 2px rgba(0,0,0,.05)',
        position: 'relative',
        background: '#fff',
        padding: '21px 17px',
        paddingRight: 48,
        minHeight: 54,
        minWidth: 237,
        justifyContent: 'flex-start',
        marginRight: 7,
        '& .MuiButton-endIcon': {
            position: 'absolute',
            right: 14,
            width: 12,
            height: 'auto',
            transform: 'rotate(180deg)',
            color: theme.palette.text.disabled,
        },
        '& .MuiButton-label': {
            fontWeight: 600,
        },
    },
    manualBankAccount: {
        border: 'solid 2px rgba(0,0,0,.05)',
        borderRadius: 4,
        padding: '21px 17px',
        fontWeight: 600,
    },
    other: {
        height: 120,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 600,
        minWidth: 240,
    },
}));

export const BankAccountSelector: FC<{
    className?: string;
    clientData: ClientData;
    clientProfile?: BusinessProfile;
    contactItem: ContactItem;
    selectedBankAccount?: BankAccountInTransferInterface;
    setSelectedBankAccount: (bankAccount?: BankAccountInTransferInterface) => void;
    isBankAccountManual: boolean;
    setIsBankAccountManual: (isBankAccountManual: boolean) => void;
}> = ({
    className,
    clientData,
    clientProfile,
    contactItem,
    selectedBankAccount,
    setSelectedBankAccount,
    isBankAccountManual,
    setIsBankAccountManual,
}) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [isModalOpen, setIsModalOpen] = useState(false);

    // DATA
    const bankAccounts = (() => {
        const arr: BankAccountInTransferInterface[] = [];
        if (contactItem.contact.depositAccountFromContactToMe) {
            arr.push({
                isDepositAccount: true,
                ...contactItem.contact.depositAccountFromContactToMe.data,
            });
        }
        if (clientData.externalBankAccount) {
            arr.push({
                isDepositAccount: false,
                ...clientData.externalBankAccount.data,
            });
        }
        return arr;
    })();
    const preparingDepositAccounts = (() => {
        if (contactItem.contact.depositAccountFromContactToMe) {
            return [];
        }
        return [
            <PreparingBankAccountCardWithHolder
                holder={{ name: clientData.name, profile: clientProfile }}
                remitter={{
                    name: contactItem.contact.name,
                    profile: contactItem.profile?.data,
                }}
            />,
        ];
    })();

    // HANDLER
    const handleSwitchToManual = () => {
        setIsBankAccountManual(true);
        setIsModalOpen(true);
        setSelectedBankAccount(undefined);
    };

    return (
        <div className={clsx(c.root, className)}>
            <ButtonWithMenu
                menuEmptyPlaceholder={'unexpected'}
                className={c.button1}
                items={[
                    ...bankAccounts.map((bankAccount) => ({
                        children: bankAccount.isDepositAccount ? (
                            <DepositAccountCardWithHolder
                                depositAccount={bankAccount}
                                holder={{ name: clientData.name, profile: clientProfile }}
                                remitter={{
                                    name: contactItem.contact.name,
                                    profile: contactItem.profile?.data,
                                }}
                            />
                        ) : (
                            <ExternalBankAccountCardWithHolder
                                bankAccount={bankAccount}
                                holder={{ name: clientData.name, profile: clientProfile }}
                            />
                        ),
                        onClick: () => {
                            setSelectedBankAccount(bankAccount);
                            if (isBankAccountManual) {
                                setIsBankAccountManual(false);
                            }
                        },
                        isDisabled: false,
                    })),
                    ...preparingDepositAccounts.map((card) => ({
                        children: card,
                        isDisabled: true,
                    })),
                    {
                        children: (
                            <MuiTypography className={c.other}>
                                {txt({ en: 'Other', ja: 'その他（個別に設定）' })}
                            </MuiTypography>
                        ),
                        onClick: handleSwitchToManual,
                        isDisabled: false,
                    },
                ]}
                endIcon={<SvgChevronUp />}
            >
                {isBankAccountManual ? (
                    txt({ en: 'Other', ja: '個別に設定' })
                ) : selectedBankAccount ? (
                    selectedBankAccount.isDepositAccount ? (
                        <DepositAccountCardWithHolder
                            depositAccount={selectedBankAccount}
                            holder={{ name: clientData.name, profile: clientProfile }}
                            remitter={{
                                name: contactItem.contact.name,
                                profile: contactItem.profile?.data,
                            }}
                        />
                    ) : (
                        <ExternalBankAccountCardWithHolder
                            bankAccount={selectedBankAccount}
                            holder={{ name: clientData.name, profile: clientProfile }}
                        />
                    )
                ) : (
                    txt({ en: 'Select Beneficiary', ja: '入金先を選択' })
                )}
            </ButtonWithMenu>
            {isBankAccountManual && (
                <>
                    <Button className={c.manualBankAccount} onClick={() => setIsModalOpen(true)}>
                        {selectedBankAccount ? (
                            <ExternalBankAccountCard bankAccount={selectedBankAccount} />
                        ) : (
                            txt({ en: 'Specify Beneficiary', ja: '入金先を入力' })
                        )}
                    </Button>
                    <FormModalForBankAccount
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        title={txt({ en: 'Specify Beneficiary', ja: '入金先を個別に設定' })}
                        setBankAccount={(b) =>
                            setSelectedBankAccount({
                                ...b,
                                isDepositAccount: false,
                            })
                        }
                        submitText={txt({ en: 'Set', ja: '設定' })}
                        bankAccount={selectedBankAccount}
                    />
                </>
            )}
        </div>
    );
};
BankAccountSelector.displayName = 'BankAccountSelector';
