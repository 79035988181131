import {
    Fee,
    TransferConfirmation as GqlTransferConfirmation,
} from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { GqlDateTimeData } from '@/utils/UodkaClients/Core/graphql/customScalars';
import { BankAccountInTransfer, getBankAccountInTransferFromGql } from '@/utils/UodkaClients/Core/models/BankAccount';
import { ContactInTransfer, getContactInTransferFromGql } from '@/utils/UodkaClients/Core/models/Contact';
import { ClientDataInTransfer, getClientDataInTransferFromGql } from '@/utils/UodkaClients/Core/models/ClientData';

export interface TransferConfirmationInterface {
    id: string;
    createdAt: Date;
    issuer: ClientDataInTransfer;
    contact: ContactInTransfer;
    bankAccount: BankAccountInTransfer;
    amount: number;
    feeOnIssuer: Fee;
    remitterNameKana: string;
    confirmationDueDate: Date;
}

export class TransferConfirmation implements TransferConfirmationInterface {
    protected _id: string;

    protected _createdAt: Date;

    protected _issuer: ClientDataInTransfer;

    protected _contact: ContactInTransfer;

    protected _bankAccount: BankAccountInTransfer;

    protected _amount: number;

    protected _feeOnIssuer: Fee;

    protected _remitterNameKana: string;

    protected _confirmationDueDate: Date;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get issuer() {
        return this._issuer;
    }

    get contact() {
        return this._contact;
    }

    get bankAccount() {
        return this._bankAccount;
    }

    get amount() {
        return this._amount;
    }

    get feeOnIssuer() {
        return this._feeOnIssuer;
    }

    get remitterNameKana() {
        return this._remitterNameKana;
    }

    get confirmationDueDate() {
        return this._confirmationDueDate;
    }

    get data() {
        return {
            id: this.id,
            createdAt: this.createdAt,
            issuer: this.issuer,
            contact: this.contact,
            bankAccount: this.bankAccount,
            amount: this.amount,
            feeOnIssuer: this.feeOnIssuer,
            remitterNameKana: this.remitterNameKana,
            confirmationDueDate: this.confirmationDueDate,
        };
    }

    constructor({
        id,
        createdAt,
        issuer,
        contact,
        bankAccount,
        amount,
        feeOnIssuer,
        remitterNameKana,
        confirmationDueDate,
    }: TransferConfirmationInterface) {
        this._id = id;
        this._createdAt = createdAt;
        this._issuer = issuer;
        this._contact = contact;
        this._bankAccount = bankAccount;
        this._amount = amount;
        this._feeOnIssuer = feeOnIssuer;
        this._remitterNameKana = remitterNameKana;
        this._confirmationDueDate = confirmationDueDate;
    }
}

export const getTransferConfirmationFromGql = ({
    id,
    createdAt,
    issuer,
    contact,
    bankAccount,
    amount,
    feeOnIssuer,
    remitterNameKana,
    confirmationDueDate,
}: GqlTransferConfirmation) => {
    return new TransferConfirmation({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        issuer: getClientDataInTransferFromGql(issuer),
        contact: getContactInTransferFromGql(contact),
        bankAccount: getBankAccountInTransferFromGql(bankAccount),
        amount,
        feeOnIssuer,
        remitterNameKana,
        confirmationDueDate: GqlDateTimeData.serialize(confirmationDueDate),
    });
};
