import { FC, ComponentProps } from 'react';

import Modal from '@/components/0_atom/Modal';
import { Form } from '@/components/0_atom/Form';
import FormItem from '@/components/0_atom/FormItem';

export const FormModal: FC<{
    isOpen: boolean;
    animation?: 'left' | 'right' | 'up' | 'down' | 'grow' | 'fade' | undefined;
    onClose: () => void;
    title: string;
    onSubmit: () => void;
    items: ComponentProps<typeof FormItem>[];
    submitText: string;
    initializeForm?: () => void;
}> = ({ isOpen, animation, onClose, title, onSubmit, items, submitText, initializeForm }) => {
    return (
        <Modal {...{ isOpen, animation, onClose }}>
            <Form {...{ onClose, title, onSubmit, items, submitText, initializeForm, isDisplayCancelButton: true }} />
        </Modal>
    );
};
FormModal.displayName = 'FormModal';
