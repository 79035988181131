import { FC } from 'react';

import { ClientDataAPIForOwner, UodkaCoreClientForOwner } from '@/utils/UodkaClients/Core';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage, useModifiableClientData } from '@/utils/customHooks';
import HorizontalTable from '@/components/0_atom/HorizontalTable';
import FetchingText from '@/components/0_atom/FetchingText';
import ValueWithEditButton from '@/components/0_atom/ValueWithEditButton';
import { ExternalBankAccountCard } from '@/components/2_org/BankAccountCard';

import { ModifyNameButton } from '@/views/config/business/account/Basic/ModifyNameButton';
import { ModifyNotificationEmailButton } from '@/views/config/business/account/Basic/ModifyNotificationEmailButton';
import { ModifyBankAccountButton } from '@/views/config/business/account/Basic/ModifyBankAccountButton';
import { ModifyRemitterNameKanaButton } from '@/views/config/business/account/Basic/ModifyRemitterNameKanaButton';
import { ModifyLanguageButton } from '@/views/config/business/account/Basic/ModifyLanguageButton';
import { ArchiveBusinessButton } from '@/views/config/business/account/Basic/ArchiveBusinessButton';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    box: {
        background: '#fff',
        borderRadius: 4,
        padding: 18,
        '&:not(:last-child)': {
            marginBottom: 22,
        },
    },
    bankAccountCard: {
        padding: 12,
        borderRadius: 4,
        border: 'solid 2px rgba(0,0,0,.02)',
    },
    archiveBox: {
        background: '#fff',
        borderRadius: 4,
        padding: 18,
    },
}));

export const ConfigBusinessAccountBasic: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const { clientData, setClientData } = useModifiableClientData();
    const {
        clients: { core: coreClient },
    } = useBusinessCtxAbsolutely();

    return (
        <div className={c.root}>
            <div className={c.box}>
                <HorizontalTable
                    items={[
                        {
                            label: txt({
                                en: 'Name',
                                ja: '名称',
                            }),
                            children: (
                                <ValueWithEditButton
                                    value={clientData?.name || <FetchingText />}
                                    editButton={
                                        clientData instanceof ClientDataAPIForOwner ? (
                                            <ModifyNameButton
                                                clientDataAPI={clientData}
                                                setClientData={setClientData}
                                            />
                                        ) : undefined
                                    }
                                />
                            ),
                        },
                        {
                            label: txt({
                                en: 'Notification email',
                                ja: '通知メールアドレス',
                            }),
                            children: (
                                <ValueWithEditButton
                                    value={clientData?.notificationEmail || <FetchingText />}
                                    editButton={
                                        clientData instanceof ClientDataAPIForOwner ? (
                                            <ModifyNotificationEmailButton
                                                clientDataAPI={clientData}
                                                setClientData={setClientData}
                                            />
                                        ) : undefined
                                    }
                                />
                            ),
                        },
                        {
                            label: txt({
                                en: 'Bank account for receiving remittances',
                                ja: '送金受け取り時の銀行口座',
                            }),
                            children: (
                                <ValueWithEditButton
                                    value={
                                        clientData ? (
                                            <>
                                                {clientData.externalBankAccount ? (
                                                    <ExternalBankAccountCard
                                                        bankAccount={clientData.externalBankAccount}
                                                        className={c.bankAccountCard}
                                                    />
                                                ) : (
                                                    <>
                                                        {txt({
                                                            en: 'Use a dedicated deposit account for each contact',
                                                            ja: 'コンタクトごとに専用の入金口座を利用',
                                                        })}
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <FetchingText />
                                        )
                                    }
                                    editButton={
                                        clientData instanceof ClientDataAPIForOwner ? (
                                            <ModifyBankAccountButton
                                                clientDataAPI={clientData}
                                                setClientData={setClientData}
                                            />
                                        ) : undefined
                                    }
                                />
                            ),
                        },
                        {
                            label: txt({
                                en: 'Remitter name',
                                ja: '振込時の名義人名',
                            }),
                            children: (
                                <ValueWithEditButton
                                    value={clientData?.remitterNameKana || <FetchingText />}
                                    editButton={
                                        clientData instanceof ClientDataAPIForOwner ? (
                                            <ModifyRemitterNameKanaButton
                                                clientDataAPI={clientData}
                                                setClientData={setClientData}
                                            />
                                        ) : undefined
                                    }
                                />
                            ),
                        },
                        {
                            label: txt({
                                en: 'Language',
                                ja: '言語',
                            }),
                            children: (
                                <ValueWithEditButton
                                    value={
                                        clientData ? (
                                            clientData.language === 'ja' ? (
                                                '日本語'
                                            ) : (
                                                'English'
                                            )
                                        ) : (
                                            <FetchingText />
                                        )
                                    }
                                    editButton={
                                        clientData instanceof ClientDataAPIForOwner ? (
                                            <ModifyLanguageButton
                                                clientDataAPI={clientData}
                                                setClientData={setClientData}
                                            />
                                        ) : undefined
                                    }
                                />
                            ),
                        },
                    ]}
                />
            </div>
            {coreClient instanceof UodkaCoreClientForOwner && (
                // later: Contact.relatedBusinessの場合は削除できない
                <div className={c.box}>
                    <HorizontalTable
                        items={[
                            {
                                label: txt({
                                    en: 'Delete this business',
                                    ja: 'このビジネスを削除',
                                }),
                                children: <ArchiveBusinessButton coreClient={coreClient} />,
                            },
                        ]}
                    />
                </div>
            )}
        </div>
    );
};
ConfigBusinessAccountBasic.displayName = 'ConfigBusinessAccountBasic';
