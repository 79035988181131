import { BusinessMemberRole } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { getGraphqlSdk } from '@/utils/UodkaClients/Cert/graphql/graphqlSdk';
import {
    UodkaCertClientForObserver,
    UodkaCertClientForGeneral,
    UodkaCertClientForOwner,
} from '@/utils/UodkaClients/Cert/clients';

export const initUodkaCertClientForObserver = ({
    firebaseUserIdToken,
    clientId,
}: {
    firebaseUserIdToken: string;
    clientId: string;
}) => {
    const graphqlSdk = getGraphqlSdk({ firebaseUserIdToken });
    return new UodkaCertClientForObserver({
        graphqlSdk,
        id: clientId,
    });
};

export const initUodkaCertClientForGeneral = ({
    firebaseUserIdToken,
    clientId,
}: {
    firebaseUserIdToken: string;
    clientId: string;
}) => {
    const graphqlSdk = getGraphqlSdk({ firebaseUserIdToken });
    return new UodkaCertClientForGeneral({
        graphqlSdk,
        id: clientId,
    });
};

export const initUodkaCertClientForOwner = ({
    firebaseUserIdToken,
    clientId,
}: {
    firebaseUserIdToken: string;
    clientId: string;
}) => {
    const graphqlSdk = getGraphqlSdk({ firebaseUserIdToken });
    return new UodkaCertClientForOwner({
        graphqlSdk,
        id: clientId,
    });
};

export const initUodkaCertClient = ({
    role,
    ...initializerInput
}: {
    firebaseUserIdToken: string;
    clientId: string;
    role: BusinessMemberRole;
}) => {
    if (role === 'owner') {
        return initUodkaCertClientForOwner(initializerInput);
    }
    if (role === 'general') {
        return initUodkaCertClientForGeneral(initializerInput);
    }
    if (role === 'observer') {
        return initUodkaCertClientForObserver(initializerInput);
    }
    throw new Error('getUodkaCertClient: invalid role');
};
