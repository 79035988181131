import { FC, ReactNode } from 'react';

import FetchingText from '@/components/0_atom/FetchingText';
import TableHead, { TableHeadItem } from '@/components/0_atom/Table/TableHead';
import TableRow, { TableRowProps } from '@/components/0_atom/Table/TableRow';
import Button from '@/components/0_atom/Button';

import SvgPlus from '@/svgs/fa-plus-regular';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    addButton: {},
    emptyPlaceholder: {
        padding: 21,
        fontWeight: 600,
        color: theme.palette.text.disabled,
        fontSize: theme.typography.body2.fontSize,
    },
    overhead: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 24,
    },
    title: {
        fontWeight: 600,
        fontSize: theme.typography.body1.fontSize,
        paddingLeft: 14,
    },
}));

const Table: FC<{
    headItems: TableHeadItem[];
    rowItems: TableRowProps[] | undefined;
    className?: string;
    addItem?: {
        text: string;
        icon?: ReactNode;
        onClick: () => void;
    };
    widths: (string | number)[];
    emptyPlaceholder: string;
    title?: string;
}> = ({ headItems, rowItems, className, addItem, widths, emptyPlaceholder, title }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={className}>
            {(title || addItem) && (
                <div className={c.overhead}>
                    {title && <MuiTypography className={c.title}>{title}</MuiTypography>}
                    {addItem && (
                        <Button
                            onClick={addItem.onClick}
                            startIcon={addItem.icon || <SvgPlus />}
                            className={c.addButton}
                            role={'submit'}
                        >
                            {addItem.text}
                        </Button>
                    )}
                </div>
            )}
            <div>
                <TableHead items={headItems} widths={widths} />
                <div>
                    {rowItems ? (
                        rowItems.length === 0 ? (
                            <div className={c.emptyPlaceholder}>{emptyPlaceholder}</div>
                        ) : (
                            rowItems.map(({ items, isSelected, onClick, className }, i) => (
                                <TableRow key={i} {...{ items, isSelected, onClick, className }} widths={widths} />
                            ))
                        )
                    ) : (
                        <div className={c.emptyPlaceholder}>
                            <FetchingText />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

Table.displayName = 'Table';
export default Table;
