import { FC, ReactElement } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

const useButtonsStyles = makeStyles((theme) => ({
    root: {},
}));
const useButtonStyles = makeStyles((theme) => ({
    root: {
        '&:not(:last-child)': {
            '& .MuiAccordionSummary-root': {
                marginBottom: 4,
            },
        },
    },
    summary: {
        padding: '15px 16px',
        borderRadius: 4,
        fontWeight: 600,
        '&:hover': {
            background: 'rgba(255,255,255,.7)',
        },
    },
    summaryContent: {
        display: 'flex',
        alignItems: 'center',
    },
    summarySmall: {
        padding: '9px 16px',
    },
    summarySelected: {
        background: '#fff',
    },
    details: {
        padding: '4px 0 0 40px',
    },
    icon: {
        width: 22,
        height: 22,
        marginRight: 22,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    avatarIcon: {
        width: 28,
        height: 28,
        marginRight: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

interface NestableButtonData {
    button: {
        icon?: ReactElement;
        text: string;
        onClick?: () => void;
        size?: 'regular' | 'small';
        isAvatarIcon?: boolean;
    };
    id: string;
    isSelected: boolean;
    childButtons?: {
        selectedChildId: string;
        setSelectedChildId: (id: string) => void;
        items: NestableButtonData[];
    };
}

const NestableButton: FC<Omit<NestableButtonData, 'id'>> = ({ button, isSelected, childButtons }) => {
    // STYLE
    const c = useButtonStyles(useTheme());

    return (
        <MuiAccordion expanded={isSelected} className={c.root}>
            <MuiAccordionSummary
                onClick={button.onClick}
                className={clsx(c.summary, {
                    [c.summarySelected]: isSelected,
                    [c.summarySmall]: button.size === 'small',
                })}
                classes={{ content: c.summaryContent }}
            >
                {button.icon && (
                    <span
                        className={clsx({
                            [c.icon]: !button.isAvatarIcon,
                            [c.avatarIcon]: button.isAvatarIcon,
                        })}
                    >
                        {button.icon}
                    </span>
                )}
                {button.text}
            </MuiAccordionSummary>
            {childButtons && (
                <MuiAccordionDetails className={c.details}>
                    {childButtons.items.map((child, i) => (
                        <NestableButton
                            key={i}
                            button={{
                                ...child.button,
                                onClick: () => {
                                    childButtons.setSelectedChildId(child.id);
                                    child.button.onClick && child.button.onClick();
                                },
                            }}
                            isSelected={child.id === childButtons.selectedChildId}
                            childButtons={child.childButtons}
                        />
                    ))}
                </MuiAccordionDetails>
            )}
        </MuiAccordion>
    );
};

const NestableButtons: FC<{
    items: NestableButtonData[];
    selectedId: string;
    setSelectedId: (id: string) => void;
    className?: string;
}> = ({ items, selectedId, setSelectedId, className }) => {
    // STYLE
    const c = useButtonsStyles(useTheme());

    return (
        <div className={clsx(c.root, className)}>
            {items.map((item, i) => (
                <NestableButton
                    key={i}
                    {...item}
                    isSelected={item.id === selectedId}
                    button={{
                        ...item.button,
                        onClick: () => {
                            setSelectedId(item.id);
                            item.button.onClick && item.button.onClick();
                        },
                    }}
                />
            ))}
        </div>
    );
};
NestableButtons.displayName = 'NestableButtons';
export default NestableButtons;
