import { BusinessProfile as GqlBusinessProfile } from '@/utils/UodkaClients/Profile/graphql/generated/graphqlClient';

export interface BusinessProfileInterface {
    businessId: string;
    themeColor?: string;
    phone?: string;
    email?: string;
    websiteUrl?: string;
    address?: string;
    logoSrc?: string;
    iconSrc?: string;
}

export class BusinessProfile implements BusinessProfileInterface {
    protected _businessId: string;

    protected _themeColor?: string;

    protected _phone?: string;

    protected _email?: string;

    protected _websiteUrl?: string;

    protected _address?: string;

    protected _logoSrc?: string;

    protected _iconSrc?: string;

    get businessId() {
        return this._businessId;
    }

    get themeColor() {
        return this._themeColor;
    }

    get phone() {
        return this._phone;
    }

    get email() {
        return this._email;
    }

    get websiteUrl() {
        return this._websiteUrl;
    }

    get address() {
        return this._address;
    }

    get logoSrc() {
        return this._logoSrc;
    }

    get iconSrc() {
        return this._iconSrc;
    }

    constructor({
        businessId,
        themeColor,
        phone,
        email,
        websiteUrl,
        address,
        logoSrc,
        iconSrc,
    }: BusinessProfileInterface) {
        this._businessId = businessId;
        this._themeColor = themeColor;
        this._phone = phone;
        this._email = email;
        this._websiteUrl = websiteUrl;
        this._address = address;
        this._logoSrc = logoSrc;
        this._iconSrc = iconSrc;
    }
}

export const getBusinessProfileInterfaceFromGql = ({
    businessId,
    themeColor,
    phone,
    email,
    websiteUrl,
    address,
}: GqlBusinessProfile): BusinessProfileInterface => {
    return {
        businessId,
        themeColor: themeColor || undefined,
        phone: phone || undefined,
        email: email || undefined,
        websiteUrl: websiteUrl || undefined,
        address: address || undefined,
    };
};
