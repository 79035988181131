import { MutationValidationResult } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import {
    MemberInUser,
    UserAPI,
    getMemberInUserFromGql,
    getClientDataInBusinessMemberFromGql,
} from '@/utils/UodkaClients/Core/models';
import { getValidVaHolderNameUserSpace } from '@/utils/UodkaClients/Core/utils';

interface UodkaUserClientInterface {
    getMembers: () => Promise<MemberInUser[]>;
    addBusiness: (input: {
        name: string;
        remitterNameKana: string;
    }) => Promise<{
        memberInUser: MemberInUser;
        result: MutationValidationResult;
    }>;
    acceptInviteForBusiness: (input: {
        businessId: string;
        inviteCodeText: string;
    }) => Promise<{
        memberInUser: MemberInUser;
        result: MutationValidationResult;
    }>;
}

export class UodkaUserClient extends UserAPI implements UodkaUserClientInterface {
    async getMembers() {
        const { businessMembersInUser } = await this._graphqlSdk.businessMembersInUser();
        return businessMembersInUser.map((businessMemberInUser) => getMemberInUserFromGql(businessMemberInUser));
    }

    async addBusiness({ name, remitterNameKana: remitterNameKana_ }: { name: string; remitterNameKana: string }) {
        const { isValid, halfWidthKatakana } = getValidVaHolderNameUserSpace(remitterNameKana_);
        if (!(isValid && halfWidthKatakana)) {
            throw new Error('UodkaUserClient.addBusiness: Invalid remitterNameKana');
        }
        const { createBusiness } = await this._graphqlSdk.createBusiness({
            input: {
                name,
                remitterNameKana: halfWidthKatakana,
            },
        });
        const { business, businessMember, result } = createBusiness;
        const memberInUser = new MemberInUser({
            ...businessMember,
            business: getClientDataInBusinessMemberFromGql(business),
        });
        return { memberInUser, result };
    }

    async acceptInviteForBusiness({ businessId, inviteCodeText }: { businessId: string; inviteCodeText: string }) {
        const { applyInviteForBusinessMember } = await this._graphqlSdk.applyInviteForBusinessMember({
            input: {
                businessId,
                inviteCodeText,
            },
        });
        const { business, businessMember, result } = applyInviteForBusinessMember;
        const memberInUser = new MemberInUser({
            ...businessMember,
            business: getClientDataInBusinessMemberFromGql(business),
        });
        return { memberInUser, result };
    }
}
