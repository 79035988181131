import {
    TransferInBalance as GqlTransferInBalance,
    TransferErrorCode,
    Fee,
} from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { GqlDateTimeData } from '@/utils/UodkaClients/Core/graphql/customScalars';
import { BankAccountInTransfer, getBankAccountInTransferFromGql } from '@/utils/UodkaClients/Core/models/BankAccount';
import { ContactInTransfer, getContactInTransferFromGql } from '@/utils/UodkaClients/Core/models/Contact';
import { App, getAppFromGql } from '@/utils/UodkaClients/Core/models/App';

interface TransferError {
    setAt: Date;
    code: TransferErrorCode;
    message?: string;
}

export interface TransferInBalanceInterface {
    id: string;
    createdAt: Date;
    contact: ContactInTransfer;
    bankAccount: BankAccountInTransfer;
    amount: number;
    feeOnIssuer: Fee;
    remitterNameKana: string;
    error?: TransferError;
    issuedAt?: Date;
    operatorApp?: App;
}

export class TransferInBalance implements TransferInBalanceInterface {
    protected _id: string;

    protected _createdAt: Date;

    protected _contact: ContactInTransfer;

    protected _bankAccount: BankAccountInTransfer;

    protected _amount: number;

    protected _feeOnIssuer: Fee;

    protected _remitterNameKana: string;

    protected _error?: TransferError;

    protected _issuedAt?: Date;

    protected _operatorApp?: App;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get contact() {
        return this._contact;
    }

    get bankAccount() {
        return this._bankAccount;
    }

    get amount() {
        return this._amount;
    }

    get feeOnIssuer() {
        return this._feeOnIssuer;
    }

    get remitterNameKana() {
        return this._remitterNameKana;
    }

    get error() {
        return this._error;
    }

    get issuedAt() {
        return this._issuedAt;
    }

    get operatorApp() {
        return this._operatorApp;
    }

    get data() {
        return {
            id: this._id,
            createdAt: this._createdAt,
            contact: this._contact,
            bankAccount: this._bankAccount,
            amount: this._amount,
            feeOnIssuer: this._feeOnIssuer,
            remitterNameKana: this._remitterNameKana,
            error: this._error,
            issuedAt: this._issuedAt,
            operatorApp: this._operatorApp,
        };
    }

    constructor({
        id,
        createdAt,
        contact,
        bankAccount,
        amount,
        feeOnIssuer,
        remitterNameKana,
        error,
        issuedAt,
        operatorApp,
    }: TransferInBalanceInterface) {
        this._id = id;
        this._createdAt = createdAt;
        this._contact = contact;
        this._bankAccount = bankAccount;
        this._amount = amount;
        this._feeOnIssuer = feeOnIssuer;
        this._remitterNameKana = remitterNameKana;
        this._error = (() => {
            if (error) {
                return {
                    setAt: error.setAt,
                    code: error.code,
                    message: error.message,
                };
            }
            return undefined;
        })();
        this._issuedAt = issuedAt;
        this._operatorApp = operatorApp;
    }
}

export const getTransferInBalanceFromGql = ({
    id,
    createdAt,
    contact,
    bankAccount,
    amount,
    feeOnIssuer,
    remitterNameKana,
    error,
    issuedAt,
    operatorApp,
}: GqlTransferInBalance) => {
    return new TransferInBalance({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        contact: getContactInTransferFromGql(contact),
        bankAccount: getBankAccountInTransferFromGql(bankAccount),
        amount,
        feeOnIssuer,
        remitterNameKana,
        error: (() => {
            if (error) {
                return {
                    setAt: GqlDateTimeData.serialize(error.setAt),
                    code: error.code,
                    message: error.message || undefined,
                };
            }
            return undefined;
        })(),
        issuedAt: issuedAt ? GqlDateTimeData.serialize(issuedAt) : undefined,
        operatorApp: operatorApp ? getAppFromGql(operatorApp) : undefined,
    });
};
