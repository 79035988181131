import { useEffect, useCallback } from 'react';

import { UodkaCoreClientForOwner, InviteForMemberAPIForOwner } from '@/utils/UodkaClients/Core';
import { useLazyItems } from '@/utils/customHooks';

export interface MemberItem {
    id: string;
    inviteAPI: InviteForMemberAPIForOwner;
}

export const useInviteItems = ({ coreClient }: { coreClient: UodkaCoreClientForOwner }) => {
    // HOOKS
    const { items, setLazyItems } = useLazyItems<MemberItem>();
    const fetchInvites = useCallback(async () => {
        const lazyItems = await (async () => {
            const inviteAPIs = await coreClient.getInviteForMemberAPIs();
            return inviteAPIs.map((inviteAPI) => ({
                id: inviteAPI.codeText,
                inviteAPI,
            }));
        })();
        setLazyItems(lazyItems);
    }, [coreClient, setLazyItems]);

    // USEEFFECT
    useEffect(() => {
        fetchInvites();
    }, [fetchInvites]);

    return {
        items,
        refetchInvites: fetchInvites,
    };
};
