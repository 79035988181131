import { useState, useEffect, useCallback } from 'react';

import { BusinessProfile, initUodkaProfileClientForAnonymous } from '@/utils/UodkaClients/Profile';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';

export const getBusinessProfile = async (clientId: string): Promise<BusinessProfile | undefined> => {
    const client = initUodkaProfileClientForAnonymous();
    const profile = await client.getProfileData({ businessId: clientId });
    if (profile) {
        return new BusinessProfile(profile);
    }
    return undefined;
};

export const useClientProfile = () => {
    // HOOKS
    const { currentBusiness } = useBusinessCtxAbsolutely();
    const [clientProfile, setClientProfile] = useState<BusinessProfile | undefined | 'fetching'>('fetching');
    const fetchClientProfile = useCallback(async () => {
        const profile = await getBusinessProfile(currentBusiness.id);
        setClientProfile(profile);
    }, [currentBusiness]);

    // USEEFFECT
    useEffect(() => {
        fetchClientProfile();
    }, [fetchClientProfile]);

    return {
        clientProfile,
        refetchClientProfile: fetchClientProfile,
    };
};
