import { BillSignatures, PaymentInReceivedBill, IssuedPayment } from '@/utils/UodkaClients/Invoices';
import { getTranferErrorDescription } from '@/views/transactions/TransferStatusDetails';

type ReceivedBillStatustype = 'processing' | 'completed' | 'error' | 'arrival';
export type ReceivedBillStatus = {
    title: string;
    textForCurrent: string;
    date: Date;
    type: ReceivedBillStatustype;
    description?: string;
    priority: number;
    section: 'bill' | 'payment';
};
export const getReceivedBillStatusHistory = ({
    bill,
    txt,
}: {
    bill: {
        payment: PaymentInReceivedBill | IssuedPayment | undefined;
        signatures: BillSignatures;
    };
    txt: (multilingualTexts: { en: string; ja?: string | undefined }) => string;
}): ReceivedBillStatus[] => {
    const { payment, signatures } = bill;
    const arr: ReceivedBillStatus[] = [
        {
            title: txt({ en: 'Issued', ja: '発行' }),
            textForCurrent: txt({ en: 'New', ja: '新着' }),
            type: 'arrival',
            description: undefined,
            date: signatures.issuer.issue.issuedAt,
            priority: 0,
            section: 'bill',
        },
    ];
    if (signatures.recipient.receive) {
        arr.push({
            title: txt({ en: 'Received', ja: '受領' }),
            textForCurrent: txt({ en: 'Received', ja: '受領' }),
            type: 'completed',
            description: undefined,
            date: signatures.recipient.receive.billReceivedAt,
            priority: 1,
            section: 'bill',
        });
    }
    if (payment) {
        arr.push({
            title: txt({ en: 'Payment process started', ja: '支払い手続きが開始' }),
            textForCurrent: txt({ en: 'Processing', ja: '手続き中' }),
            type: 'processing',
            description: undefined,
            date: payment.transfer.createdAt,
            priority: 2,
            section: 'payment',
        });
    }
    if (payment?.transfer.issuedAt) {
        arr.push({
            title: txt({ en: 'Payment completed', ja: '支払い完了' }),
            textForCurrent: txt({ en: 'Completed', ja: '完了' }),
            type: 'completed',
            description: undefined,
            date: payment.transfer.issuedAt,
            priority: 3,
            section: 'payment',
        });
    }
    if (payment?.transfer.error) {
        arr.push({
            title: txt({ en: 'Payment failed', ja: '支払いエラー' }),
            textForCurrent: txt({ en: 'Failed', ja: 'エラー' }),
            type: 'error',
            description: getTranferErrorDescription({ transferErrorCode: payment.transfer.error.code, txt }),
            date: payment.transfer.error.setAt,
            priority: 3,
            section: 'payment',
        });
    }
    if (signatures.issuer.revoke) {
        arr.push({
            title: txt({ en: 'Issuer revoked', ja: '発行者が請求を破棄' }),
            textForCurrent: txt({ en: 'Revoked', ja: '発行者が破棄' }),
            type: 'error',
            description: undefined,
            date: signatures.issuer.revoke.agreeToRevokeAt,
            priority: 4,
            section: 'bill',
        });
    }
    if (signatures.recipient.revoke) {
        arr.push({
            title: txt({ en: 'Revoked', ja: '請求を破棄' }),
            textForCurrent: txt({ en: 'Revoked', ja: '破棄' }),
            type: 'error',
            description: undefined,
            date: signatures.recipient.revoke.agreeToRevokeAt,
            priority: 4,
            section: 'bill',
        });
    }
    return arr.sort((a, b) => {
        if (a.priority > b.priority) {
            return 1;
        }
        if (a.priority < b.priority) {
            return -1;
        }
        // 同じ場合
        const aTime = a.date.getTime();
        const bTime = b.date.getTime();
        if (aTime > bTime) {
            return 1;
        }
        if (aTime < bTime) {
            return -1;
        }
        // 同じ場合
        return 1;
    });
};
