import { SharedTransfer, Charge, getSharedTransferFromGql, getChargeFromGql } from '@/utils/UodkaClients/Core';
import { GqlDateTimeData } from '@/utils/UodkaClients/Invoices/graphql/customScalars';
import { ReceivedPayment as GqlReceivedPayment } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import { PaymentData, getPaymentDataFromGql } from '@/utils/UodkaClients/Invoices/models/InvoiceData';
import { BillInPayment, getBillInPaymentFromGql } from '@/utils/UodkaClients/Invoices/models/BillEdge';

interface ReceivedPaymentInterface {
    id: string;
    createdAt: Date;
    paymentData: PaymentData;
    bill?: BillInPayment;
    incomingTransfer: SharedTransfer;
    charge?: Charge;
}

export class ReceivedPayment implements ReceivedPaymentInterface {
    protected _id: string;

    protected _createdAt: Date;

    protected _paymentData: PaymentData;

    protected _bill?: BillInPayment;

    protected _incomingTransfer: SharedTransfer;

    protected _charge?: Charge;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get paymentData() {
        return this._paymentData;
    }

    get bill() {
        return this._bill;
    }

    get incomingTransfer() {
        return this._incomingTransfer;
    }

    get charge() {
        return this._charge;
    }

    constructor({ id, createdAt, paymentData, bill, incomingTransfer, charge }: ReceivedPaymentInterface) {
        this._id = id;
        this._createdAt = createdAt;
        this._paymentData = paymentData;
        this._bill = bill;
        this._incomingTransfer = incomingTransfer;
        this._charge = charge;
    }
}

export const getReceivedPaymentFromGql = ({
    id,
    createdAt,
    paymentData,
    bill,
    incomingTransfer,
    charge,
}: GqlReceivedPayment) => {
    return new ReceivedPayment({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        paymentData: getPaymentDataFromGql(paymentData),
        bill: bill ? getBillInPaymentFromGql(bill) : undefined,
        incomingTransfer: getSharedTransferFromGql(incomingTransfer),
        charge: charge ? getChargeFromGql(charge) : undefined,
    });
};
