import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 4483 4483" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d)">
                <path
                    d="M4011.23 2556.48L4410.72 2777.22C4456.34 2802.43 4477.52 2854.56 4461.89 2902.72C4358.12 3222.57 4180.95 3511.52 3949.23 3751.52C3931.89 3769.49 3908.68 3781.28 3883.41 3784.95C3858.14 3788.63 3832.32 3783.97 3810.19 3771.74L3411 3551.03C3243.04 3688.79 3049.95 3795.67 2841.44 3866.28V4307.63C2841.44 4332.11 2832.73 4355.86 2816.76 4374.97C2800.78 4394.08 2778.48 4407.42 2753.53 4412.78C2425.85 4483.23 2073.92 4486.87 1729.7 4412.85C1678.24 4401.78 1641.55 4358.14 1641.55 4307.65V3866.28C1433.04 3795.67 1239.94 3688.8 1071.99 3551.03L672.802 3771.74C650.667 3783.97 624.846 3788.63 599.58 3784.95C574.314 3781.28 551.105 3769.49 533.764 3751.52C302.054 3511.52 124.873 3222.57 21.1014 2902.72C5.47472 2854.57 26.6509 2802.44 72.2749 2777.22L471.773 2556.48C431.647 2348.25 431.647 2134.76 471.773 1926.53L72.2843 1705.78C26.6603 1680.58 5.48409 1628.45 21.1108 1580.29C124.883 1260.44 302.054 971.487 533.774 731.493C551.115 713.523 574.324 701.732 599.59 698.056C624.856 694.38 650.676 699.039 672.811 711.266L1072 931.976C1239.96 794.215 1433.05 687.339 1641.56 616.728V175.371C1641.56 150.893 1650.27 127.143 1666.24 108.031C1682.22 88.9191 1704.52 75.5825 1729.47 70.2161C2057.15 -0.227998 2409.08 -3.87135 2753.3 70.1533C2804.76 81.2179 2841.45 124.857 2841.45 175.353V616.719C3049.96 687.326 3243.06 794.202 3411.01 931.967L3810.2 711.257C3832.33 699.03 3858.15 694.371 3883.42 698.047C3908.69 701.723 3931.89 713.514 3949.24 731.484C4180.95 971.479 4358.13 1260.43 4461.9 1580.28C4477.53 1628.43 4456.35 1680.56 4410.72 1705.77L4011.23 1926.52C4051.35 2134.75 4051.35 2348.25 4011.23 2556.48ZM2991.43 2241.5C2991.43 1845.65 2655.01 1523.6 2241.5 1523.6C1827.99 1523.6 1491.57 1845.65 1491.57 2241.5C1491.57 2637.35 1827.99 2959.4 2241.5 2959.4C2655.01 2959.4 2991.43 2637.35 2991.43 2241.5Z"
                    fill="black"
                    fillOpacity="0.55"
                />
            </g>
            <g filter="url(#filter1_d)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3234.57 804.402C3112.28 726.82 2980.28 663.73 2841.45 616.718V175.351C2841.45 124.855 2804.76 81.2184 2753.3 70.152C2409.08 -3.87146 2057.15 -0.226925 1729.46 70.2145C1704.52 75.5817 1682.22 88.9176 1666.24 108.031C1650.27 127.144 1641.56 150.89 1641.56 175.371V616.726C1433.05 687.339 1239.96 794.214 1072 931.976L672.81 711.265C650.676 699.039 624.855 694.379 599.59 698.054C574.324 701.73 551.114 713.523 533.773 731.492C302.054 971.488 124.883 1260.44 21.1103 1580.28C5.48431 1628.45 26.6601 1680.58 72.2841 1705.78L471.773 1926.53C431.647 2134.76 431.647 2348.25 471.773 2556.48L72.2743 2777.23C26.6513 2802.44 5.47454 2854.57 21.1015 2902.72C124.873 3222.57 302.054 3511.52 533.765 3751.52C546.787 3765.01 563.119 3775.02 581.146 3780.73L1609.36 2627.39C1534.82 2515.87 1491.57 2383.43 1491.57 2241.5C1491.57 1845.65 1827.99 1523.6 2241.5 1523.6C2347.67 1523.6 2448.75 1544.82 2540.37 1583.08L3234.57 804.402Z"
                    fill="white"
                    fillOpacity="0.2"
                />
            </g>
            <defs>
                <filter id="filter0_d" x="0" y="0" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="8" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
                <filter id="filter1_d" x="0" y="0" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="8" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};
Svg.displayName = 'svg/cog';
export default Svg;
