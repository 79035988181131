import { Language, Business as GqlBusiness } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { ExternalBankAccount, getExternalBankAccountFromGql } from '@/utils/UodkaClients/Core/models/BankAccount';
import {
    DepositAccountInBusiness,
    getDepositAccountInBusinessFromGql,
} from '@/utils/UodkaClients/Core/models/DepositAccount';
import { getKana } from '@/utils/UodkaClients/Core/utils';

export type ClientDataInterface = {
    id: string;
    name: string;
    notificationEmail: string;
    remitterNameKana: string;
    depositAccountFromMeToMe?: DepositAccountInBusiness;
    externalBankAccount?: ExternalBankAccount;
    language?: Language;
    isArchived: boolean;
};

export class ClientData implements ClientDataInterface {
    protected _id: string;

    protected _name: string;

    protected _notificationEmail: string;

    protected _remitterNameKana: string;

    protected _depositAccountFromMeToMe?: DepositAccountInBusiness;

    protected _externalBankAccount?: ExternalBankAccount;

    protected _language?: Language;

    protected _isArchived: boolean;

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get notificationEmail() {
        return this._notificationEmail;
    }

    get remitterNameKana() {
        const kana = getKana(this._remitterNameKana);
        if (!kana) {
            throw new Error('ClientData.remitterNameKana: unexpectedly got invalid value.');
        }
        return kana.fullWidthKatakana;
    }

    get depositAccountFromMeToMe() {
        return this._depositAccountFromMeToMe;
    }

    get externalBankAccount() {
        return this._externalBankAccount;
    }

    get language() {
        return this._language;
    }

    get isArchived() {
        return this._isArchived;
    }

    get data() {
        return {
            id: this.id,
            name: this.name,
            notificationEmail: this.notificationEmail,
            remitterNameKana: this.remitterNameKana,
            depositAccountFromMeToMe: this.depositAccountFromMeToMe,
            externalBankAccount: this.externalBankAccount,
            language: this.language,
            isArchived: this.isArchived,
        };
    }

    constructor({
        id,
        name,
        notificationEmail,
        remitterNameKana,
        depositAccountFromMeToMe,
        externalBankAccount,
        language,
        isArchived,
    }: ClientDataInterface) {
        this._id = id;
        this._name = name;
        this._notificationEmail = notificationEmail;
        this._remitterNameKana = remitterNameKana;
        this._depositAccountFromMeToMe = depositAccountFromMeToMe;
        this._externalBankAccount = externalBankAccount;
        this._language = language || undefined;
        this._isArchived = isArchived;
    }
}

export const getClientDataFromGql = ({
    id,
    name,
    notificationEmail,
    remitterNameKana,
    depositAccountFromMeToMe,
    externalBankAccount,
    language,
    isArchived,
}: GqlBusiness) => {
    return new ClientData({
        id,
        name,
        notificationEmail,
        remitterNameKana,
        depositAccountFromMeToMe: depositAccountFromMeToMe
            ? getDepositAccountInBusinessFromGql(depositAccountFromMeToMe)
            : undefined,
        externalBankAccount: externalBankAccount ? getExternalBankAccountFromGql(externalBankAccount) : undefined,
        language: language || undefined,
        isArchived,
    });
};
