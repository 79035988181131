import { FC } from 'react';

import { InvoiceDataItem } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';

import Button from '@/components/0_atom/Button';

import SvgPlus from '@/svgs/fa-plus-regular';

import { ItemEditor } from '@/views/bills/AddBillDrawer/BillEditor/BillDataEditor/ItemsEditor/ItemEditor';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // border: 'solid 2px rgba(0,0,0,.03)',
        borderRadius: 4,
        padding: '14px 7px',
        background: '#f6f6f6',
    },
    addButton: {
        width: '100%',
        height: 40,
        fontWeight: 600,
    },
}));

export const ItemsEditor: FC<{
    items: InvoiceDataItem[];
    setItems: (items: InvoiceDataItem[]) => void;
}> = ({ items, setItems }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    return (
        <div className={c.root}>
            {items.map((item, i) => (
                <ItemEditor
                    key={i}
                    item={item}
                    setItem={(newItem) =>
                        setItems(
                            items.map((existingItem, ii) => {
                                if (i === ii) {
                                    return newItem;
                                }
                                return existingItem;
                            })
                        )
                    }
                    removeItem={() => setItems(items.filter((_, ii) => i !== ii))}
                />
            ))}
            <Button
                className={c.addButton}
                onClick={() =>
                    setItems([
                        ...items,
                        new InvoiceDataItem({
                            title: '',
                            unitPrice: 0,
                            quantity: 0,
                            taxes: [],
                        }),
                    ])
                }
                startIcon={<SvgPlus />}
            >
                {txt({ en: 'Add item', ja: '項目を追加' })}
            </Button>
        </div>
    );
};
ItemsEditor.displayName = 'ItemsEditor';
