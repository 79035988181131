import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 54 243" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M38.0787 0H15.9203C9.34101 0 4.08567 5.15197 4.35856 11.334L11.1086 163.662C11.367 169.49 16.4671 174.09 22.6703 174.09H31.3287C37.5329 174.09 42.632 169.491 42.8905 163.662L49.6405 11.334C49.9143 5.15197 44.658 0 38.0787 0Z"
                fill="currentColor"
            />
            <path
                d="M0 217.612C0 203.59 12.0883 192.224 27 192.224C41.9117 192.224 54 203.59 54 217.612C54 231.633 41.9117 243 27 243C12.0883 243 0 231.633 0 217.612Z"
                fill="currentColor"
            />
        </svg>
    );
};
Svg.displayName = 'svg/fa-exclamation';
export default Svg;
