import { FC } from 'react';

import { BankAccountInTransfer } from '@/utils/UodkaClients/Core';
import { BalanceWithInvoice } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';
import { getCSV, downloadTextFile, getBankAccountText } from '@/utils/utilFunctions';
import { DatePickerButton } from '@/components/0_atom/DatePickerButton';
import { ButtonWithTextMenu } from '@/components/1_mol/ButtonWithMenu';
import { TextSelector } from '@/components/1_mol/Selector';

import SvgSpreadsheet from '@/svgs/spreadsheet';
import SvgCode from '@/svgs/code';

import { FieldItem } from '@/views/records/useAccountingItems';
import { FilterItem } from '@/views/records/AccountingSideMenu/FilterItem';
import { parepareRecordsCsv } from '@/views/records/parepareRecordsCsv';
import { parepareRecordsJson } from '@/views/records/parepareRecordsJson';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import MuiList from '@material-ui/core/List';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
    },
    title: {
        marginBottom: 28,
    },
    menu: {
        overflowY: 'scroll',
        maxHeight: `calc(100vh - ${18 * 2 + 21 + 28 + 33 + 28 + 28 + 2}px)`,
    },
    caption: {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 600,
        color: theme.palette.text.disabled,
        display: 'block',
        marginBottom: 4,
        '&:not(:first-child)': {
            marginTop: 14,
        },
    },
    downloadButtonBox: {
        marginTop: 28,
        paddingTop: 28,
        borderTop: 'solid 2px rgba(0,0,0,.03)',
    },
    downloadButton: {},
    filters: {
        padding: 0,
    },
    sortButton: {
        display: 'flex',
    },
    termItem: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 4,
        '& .MuiTypography-root': {
            marginRight: 12,
        },
    },
    termButton: {
        border: 'solid 2px rgba(0,0,0,.05)',
        width: 90,
    },
}));

export const AccountingSideMenu: FC<{
    items: FieldItem[] | undefined;
    balanceWithInvoices: BalanceWithInvoice[] | undefined;
    isContactId: boolean;
    isContactName: boolean;
    isItemName: boolean;
    isKind: boolean;
    isSelfDepositAmount: boolean;
    isReceivedBillAmount: boolean;
    isIssuedBillAmount: boolean;
    isIssuedPaymentAmount: boolean;
    isReceivedPaymentAmount: boolean;
    isPaidTaxAmount: boolean;
    isReceivedTaxAmount: boolean;
    isBankAccount: boolean;
    isBillDate: boolean;
    isPaymentDate: boolean;
    sortBy: 'billDate' | 'issueDate';
    sortOrder: 'asc' | 'desc';
    fromDate: Date | undefined;
    toDate: Date | undefined;
    setIsContactId: (bool: boolean) => void;
    setIsContactName: (bool: boolean) => void;
    setIsItemName: (bool: boolean) => void;
    setIsKind: (bool: boolean) => void;
    setIsSelfDepositAmount: (bool: boolean) => void;
    setIsReceivedBillAmount: (bool: boolean) => void;
    setIsIssuedBillAmount: (bool: boolean) => void;
    setIsIssuedPaymentAmount: (bool: boolean) => void;
    setIsReceivedPaymentAmount: (bool: boolean) => void;
    setIsPaidTaxAmount: (bool: boolean) => void;
    setIsReceivedTaxAmount: (bool: boolean) => void;
    setIsBankAccount: (bool: boolean) => void;
    setIsBillDate: (bool: boolean) => void;
    setIsPaymentDate: (bool: boolean) => void;
    setSortBy: (sortBy: 'billDate' | 'issueDate') => void;
    setSortOrder: (sortOrder: 'asc' | 'desc') => void;
    setFromDate: (date: Date | undefined) => void;
    setToDate: (date: Date | undefined) => void;
}> = ({
    items,
    balanceWithInvoices,
    isContactId,
    setIsContactId,
    isContactName,
    setIsContactName,
    isItemName,
    setIsItemName,
    isKind,
    setIsKind,
    isSelfDepositAmount,
    setIsSelfDepositAmount,
    isReceivedBillAmount,
    setIsReceivedBillAmount,
    isIssuedBillAmount,
    setIsIssuedBillAmount,
    isIssuedPaymentAmount,
    setIsIssuedPaymentAmount,
    isReceivedPaymentAmount,
    setIsReceivedPaymentAmount,
    isPaidTaxAmount,
    setIsPaidTaxAmount,
    isReceivedTaxAmount,
    setIsReceivedTaxAmount,
    isBankAccount,
    setIsBankAccount,
    isBillDate,
    setIsBillDate,
    isPaymentDate,
    setIsPaymentDate,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
}) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt, getDateStr } = useLanguage();

    return (
        <div className={c.root}>
            <MuiTypography variant={'h1'} className={c.title}>
                {txt({ en: 'Records', ja: '帳簿' })}
            </MuiTypography>
            <div className={c.menu}>
                <MuiTypography variant={'caption'} className={c.caption}>
                    {txt({ en: 'Columns to display', ja: '表示する列' })}
                </MuiTypography>
                <MuiList className={c.filters}>
                    <FilterItem
                        isChecked={isContactId}
                        onChange={() => setIsContactId(!isContactId)}
                        text={txt({ en: 'Contact ID', ja: 'コンタクトID' })}
                    />
                    <FilterItem
                        isChecked={isContactName}
                        onChange={() => setIsContactName(!isContactName)}
                        text={txt({ en: 'Contact Name', ja: 'コンタクトの名称' })}
                    />
                    <FilterItem
                        isChecked={isKind}
                        onChange={() => setIsKind(!isKind)}
                        text={txt({ en: 'Kind', ja: '種別' })}
                    />
                    <FilterItem
                        isChecked={isItemName}
                        onChange={() => setIsItemName(!isItemName)}
                        text={txt({ en: 'Item', ja: '項目名' })}
                    />
                    <FilterItem
                        isChecked={isSelfDepositAmount}
                        onChange={() => setIsSelfDepositAmount(!isSelfDepositAmount)}
                        text={txt({ en: 'Amount (self deposit)', ja: '金額 (自己入金)' })}
                    />
                    <FilterItem
                        isChecked={isReceivedBillAmount}
                        onChange={() => setIsReceivedBillAmount(!isReceivedBillAmount)}
                        text={txt({ en: 'Amount (received bill)', ja: '金額 (受取請求)' })}
                    />
                    <FilterItem
                        isChecked={isIssuedBillAmount}
                        onChange={() => setIsIssuedBillAmount(!isIssuedBillAmount)}
                        text={txt({ en: 'Amount (issued bill)', ja: '金額 (発行請求)' })}
                    />
                    <FilterItem
                        isChecked={isIssuedPaymentAmount}
                        onChange={() => setIsIssuedPaymentAmount(!isIssuedPaymentAmount)}
                        text={txt({ en: 'Amount (paid)', ja: '金額 (支払)' })}
                    />
                    <FilterItem
                        isChecked={isReceivedPaymentAmount}
                        onChange={() => setIsReceivedPaymentAmount(!isReceivedPaymentAmount)}
                        text={txt({ en: 'Amount (deposit)', ja: '金額 (入金)' })}
                    />
                    <FilterItem
                        isChecked={isPaidTaxAmount}
                        onChange={() => setIsPaidTaxAmount(!isPaidTaxAmount)}
                        text={txt({ en: 'Amount (paid tax)', ja: '金額 (支払税金)' })}
                    />
                    <FilterItem
                        isChecked={isReceivedTaxAmount}
                        onChange={() => setIsReceivedTaxAmount(!isReceivedTaxAmount)}
                        text={txt({ en: 'Amount (received tax)', ja: '金額 (受取税金)' })}
                    />
                    <FilterItem
                        isChecked={isBankAccount}
                        onChange={() => setIsBankAccount(!isBankAccount)}
                        text={txt({ en: 'Bank Account', ja: '銀行口座' })}
                    />
                    <FilterItem
                        isChecked={isBillDate}
                        onChange={() => setIsBillDate(!isBillDate)}
                        text={txt({ en: 'Bill Date', ja: '請求日' })}
                    />
                    <FilterItem
                        isChecked={isPaymentDate}
                        onChange={() => setIsPaymentDate(!isPaymentDate)}
                        text={txt({ en: 'Transaction Date', ja: '支払・入金日' })}
                    />
                </MuiList>
                <MuiTypography variant={'caption'} className={c.caption}>
                    {txt({ en: 'Order', ja: '並び順' })}
                </MuiTypography>
                <TextSelector
                    className={c.sortButton}
                    items={[
                        {
                            id: 'billDateAsc',
                            text: txt({ en: 'Bill date (asc)', ja: '請求日（昇順）' }),
                        },
                        {
                            id: 'billDateDesc',
                            text: txt({ en: 'Bill date (desc)', ja: '請求日（降順）' }),
                        },
                        {
                            id: 'paymentDateAsc',
                            text: txt({ en: 'Transaction date (asc)', ja: '支払い・入金日（昇順）' }),
                        },
                        {
                            id: 'paymentDateDesc',
                            text: txt({ en: 'Transaction date (desc)', ja: '支払い・入金日（降順）' }),
                        },
                    ]}
                    onSelect={(newId) => {
                        if (newId === 'billDateAsc') {
                            setSortBy('billDate');
                            setSortOrder('asc');
                        }
                        if (newId === 'billDateDesc') {
                            setSortBy('billDate');
                            setSortOrder('desc');
                        }
                        if (newId === 'paymentDateAsc') {
                            setSortBy('issueDate');
                            setSortOrder('asc');
                        }
                        if (newId === 'paymentDateDesc') {
                            setSortBy('issueDate');
                            setSortOrder('desc');
                        }
                    }}
                    selectedId={(() => {
                        if (sortBy === 'billDate') {
                            if (sortOrder === 'asc') {
                                return 'billDateAsc';
                            }
                            return 'billDateDesc';
                        }
                        if (sortOrder === 'asc') {
                            return 'paymentDateAsc';
                        }
                        return 'paymentDateDesc';
                    })()}
                    notSelectedText={'unexpected'}
                    emptyPlaceholder={'unexpected'}
                />
                <MuiTypography variant={'caption'} className={c.caption}>
                    {txt({ en: 'Term', ja: '期間' })}
                </MuiTypography>
                <div className={c.termItem}>
                    <MuiTypography variant={'caption'} className={c.caption}>
                        {txt({ en: 'from', ja: 'from' })}
                    </MuiTypography>
                    <DatePickerButton
                        date={fromDate}
                        setDate={setFromDate}
                        isEnablePast
                        isDisableFuture
                        className={c.termButton}
                    >
                        {fromDate ? getDateStr(fromDate) : txt({ en: 'All', ja: '指定なし' })}
                    </DatePickerButton>
                </div>
                <div className={c.termItem}>
                    <MuiTypography variant={'caption'} className={c.caption}>
                        {txt({ en: 'to', ja: 'to' })}
                    </MuiTypography>
                    <DatePickerButton
                        date={toDate}
                        setDate={setToDate}
                        isEnablePast
                        isDisableFuture
                        className={c.termButton}
                    >
                        {getDateStr(toDate || new Date())}
                    </DatePickerButton>
                </div>
            </div>
            <div className={c.downloadButtonBox}>
                <ButtonWithTextMenu
                    className={c.downloadButton}
                    role={'submit'}
                    fullWidth
                    items={[
                        {
                            icon: <SvgSpreadsheet />,
                            text: 'CSV',
                            onClick: () => {
                                if (items) {
                                    const csv = parepareRecordsCsv({
                                        items,
                                        isContactId,
                                        isContactName,
                                        isItemName,
                                        isKind,
                                        isSelfDepositAmount,
                                        isReceivedBillAmount,
                                        isIssuedBillAmount,
                                        isIssuedPaymentAmount,
                                        isReceivedPaymentAmount,
                                        isPaidTaxAmount,
                                        isReceivedTaxAmount,
                                        isBankAccount,
                                        isBillDate,
                                        isPaymentDate,
                                        txt,
                                    });
                                    downloadTextFile({ text: csv, type: 'text/csv', fileName: 'uodka_records.csv' });
                                }
                            },
                        },
                        {
                            icon: <SvgCode />,
                            text: 'JSON',
                            onClick: () => {
                                if (balanceWithInvoices) {
                                    const json = parepareRecordsJson({ balanceWithInvoices });
                                    downloadTextFile({ text: json, type: 'text/json', fileName: 'uodka_records.json' });
                                }
                            },
                        },
                    ]}
                    menuEmptyPlaceholder={'unexpected'}
                    disabled={!items || items.length === 0 || !balanceWithInvoices}
                >
                    {items ? txt({ en: 'Download', ja: 'ダウンロード' }) : txt({ en: 'Preparing...', ja: '準備中...' })}
                </ButtonWithTextMenu>
            </div>
        </div>
    );
};
AccountingSideMenu.displayName = 'AccountingSideMenu';
