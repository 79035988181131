import { BillData } from '@/utils/UodkaClients/Invoices/models/InvoiceData';

export type JsonablePrimitiveType = string | number | boolean | undefined;
export type ArrayedObject = [
    string,
    JsonablePrimitiveType | JsonablePrimitiveType[] | ArrayedObject | ArrayedObject[]
][];
export type JsonableItemType = JsonablePrimitiveType | JsonablePrimitiveType[] | ArrayedObject | ArrayedObject[];

export const convertObjectToArray = (obj: Record<string, JsonableItemType>): ArrayedObject => {
    const keys = Object.keys(obj).sort();
    return keys.map((key) => [key, obj[key]] as [string, JsonableItemType]);
};

export const getUnixTime = (date: Date | undefined) => {
    if (date) {
        return date.getTime() / 1000;
    }
    return undefined;
};

export const getBillDataString = ({ billData }: { billData: BillData }) => {
    const {
        customizedId,
        title,
        issuer: issuer_,
        recipient: recipient_,
        payee: payee_,
        issueDate,
        paymentDueDate,
        currencyCode,
        items: items_,
        summary: summary_,
        note,
    } = billData;
    const issuer = convertObjectToArray({
        name: issuer_.name,
        items: issuer_.items.map((item) => convertObjectToArray(item.data)),
    });
    const recipient = convertObjectToArray({
        name: recipient_.name,
        items: recipient_.items.map((item) => convertObjectToArray(item.data)),
    });
    const payee = (() => {
        const { uodkaBankAccount: uodkaBankAccount_ } = payee_;
        const uodkaBankAccount = convertObjectToArray(uodkaBankAccount_.data);
        return convertObjectToArray({ uodkaBankAccount });
    })();
    const items = items_.map((item_) =>
        convertObjectToArray({
            title: item_.title,
            unitPrice: item_.unitPrice,
            quantity: item_.quantity,
            date: item_.date ? item_.date.getTime() : undefined,
            taxes: item_.taxes.map((tax) => convertObjectToArray(tax.data)),
        })
    );
    const summary = convertObjectToArray({
        decimalRoundRuleForSubtotal: summary_.decimalRoundRuleForSubtotal,
        subtotal: summary_.subtotal,
        taxes: summary_.taxes.map((tax) => convertObjectToArray(tax.data)),
        total: summary_.total,
    });
    const arrayedBillData = convertObjectToArray({
        customizedId,
        title,
        issuer,
        recipient,
        payee,
        issueDate: getUnixTime(issueDate),
        paymentDueDate: getUnixTime(paymentDueDate),
        currencyCode,
        items,
        summary,
        note,
    });
    return JSON.stringify(arrayedBillData);
};

export const stringifyObject = (obj: Record<string, JsonableItemType>) => {
    const arr = convertObjectToArray(obj);
    return JSON.stringify(arr);
};
