export const getTaxName = ({
    taxTitle,
    txt,
}: {
    taxTitle: string;
    txt: (multilingualTexts: { en: string; ja?: string | undefined }) => string;
}) => {
    if (taxTitle === 'consumptionTax (10%)') {
        return txt({ en: 'Consumption tax (10%)', ja: '消費税 (10%)' });
    }
    if (taxTitle === 'consumptionTax (8%)') {
        return txt({ en: 'Consumption tax (8%)', ja: '消費税 (8%)' });
    }
    if (taxTitle === 'withholdingTax') {
        return txt({ en: 'Withholding tax', ja: '源泉所得税等' });
    }
    return taxTitle;
};
