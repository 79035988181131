import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        transition: 'margin .4s, box-shadow .2s',
        padding: '18px 21px',
        position: 'relative',
        zIndex: 1,
        '&:not(:last-child)': {
            marginBottom: 12,
        },
    },
    selected: {
        '&:first-child': {
            marginTop: 17,
        },
        '&:not(:first-child)': {
            marginTop: 17,
        },
        '&:last-child': {
            marginBottom: 17,
        },
        '&:not(:last-child)': {
            marginBottom: 17,
        },
        zIndex: 2,
    },
    selectable: {
        cursor: 'pointer',
        '&:hover:not(.MuiPaper-elevation8)': {
            boxShadow: '0 0 8px 0 rgba(0,0,0,.08)',
        },
    },
}));

export interface TableRowProps {
    items: {
        children: ReactNode;
        expandedChildren?: ReactNode;
    }[];
    isSelected?: boolean;
    onClick?: () => void;
    className?: string;
}

const TableRow: FC<TableRowProps & { widths: (string | number)[] }> = ({
    items,
    isSelected,
    onClick,
    className,
    widths,
}) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <Paper
            elevation={isSelected ? 8 : 1}
            onClick={onClick}
            className={clsx(
                c.root,
                {
                    [c.selected]: isSelected,
                    [c.selectable]: !!onClick,
                },
                className
            )}
        >
            {items.map(({ children }, i) => (
                <div key={i} style={{ width: widths[i] }}>
                    {children}
                </div>
            ))}
        </Paper>
    );
};

TableRow.displayName = 'TableRow';
export default TableRow;
