import { Balance } from '@/utils/UodkaClients/Core';

import { getChargeData } from '@/views/records/getModelData/getChargeData';
import { getTransferInBalanceData } from '@/views/records/getModelData/getTransferInBalanceData';

export const getBalanceData = ({ balance }: { balance: Balance }) => {
    const {
        amountIncrease,
        charge,
        createdAt,
        currentAmount,
        currentReserve,
        eventType,
        id,
        reserveIncrease,
        transfer,
    } = balance;
    return {
        amountIncrease,
        charge: (() => {
            if (charge) {
                return getChargeData({ charge });
            }
            return undefined;
        })(),
        createdAt,
        currentAmount,
        currentReserve,
        eventType,
        id,
        reserveIncrease,
        transfer: (() => {
            if (transfer) {
                return getTransferInBalanceData({ transfer });
            }
            return undefined;
        })(),
    };
};
