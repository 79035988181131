import { FC } from 'react';

import { CurrencyCode, DecimalRoundRule } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
    },
    block1: {},
    block2: {
        marginTop: 6,
    },
    label: {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 600,
    },
    value: {
        fontSize: theme.typography.caption.fontSize,
        marginTop: 3,
    },
}));

export const Premise: FC<{
    currencyCode: CurrencyCode;
    decimalRoundRule: DecimalRoundRule | undefined;
}> = ({ currencyCode, decimalRoundRule }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    return (
        <div className={c.root}>
            <div className={c.block1}>
                <div className={c.label}>{txt({ en: 'Currency', ja: '通貨' })}</div>
                <div className={c.value}>
                    {currencyCode === 'jpy' ? txt({ en: 'JPY', ja: '日本円' }) : currencyCode}
                </div>
            </div>
            {decimalRoundRule && (
                <div className={c.block2}>
                    <div className={c.label}>{txt({ en: 'Dealing with Decimals', ja: '小数点以下の処理' })}</div>
                    <div className={c.value}>
                        {decimalRoundRule === 'down'
                            ? txt({ en: 'Round down', ja: '切り捨て' })
                            : decimalRoundRule === 'up'
                            ? txt({ en: 'Round up', ja: '切り上げ' })
                            : decimalRoundRule === 'near'
                            ? txt({ en: 'Round', ja: '四捨五入' })
                            : 'Unexpected case'}
                    </div>
                </div>
            )}
        </div>
    );
};
Premise.displayName = 'Premise';
