import { GqlDateTimeData } from '@/utils/UodkaClients/Invoices/graphql/customScalars';
import { SharedReceipt as GqlSharedReceipt } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import { ReceiptData, getReceiptDataFromGql } from '@/utils/UodkaClients/Invoices/models/InvoiceData';
import { BillInReceipt, getBillInReceiptFromGql } from '@/utils/UodkaClients/Invoices/models/BillEdge';

interface SharedReceiptInterface {
    id: string;
    createdAt: Date;
    receiptData: ReceiptData;
    bill?: BillInReceipt;
}

export class SharedReceipt implements SharedReceiptInterface {
    protected _id: string;

    protected _createdAt: Date;

    protected _receiptData: ReceiptData;

    protected _bill?: BillInReceipt;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get receiptData() {
        return this._receiptData;
    }

    get bill() {
        return this._bill;
    }

    constructor({ id, createdAt, receiptData, bill }: SharedReceiptInterface) {
        this._id = id;
        this._createdAt = createdAt;
        this._receiptData = receiptData;
        this._bill = bill;
    }
}

export const getSharedReceiptFromGql = ({ id, createdAt, receiptData, bill }: GqlSharedReceipt) => {
    return new SharedReceipt({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        receiptData: getReceiptDataFromGql(receiptData),
        bill: bill ? getBillInReceiptFromGql(bill) : undefined,
    });
};
