import {
    BusinessMemberInBusiness as GqlBusinessMemberInBusiness,
    BusinessMemberRole,
} from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { UserInMember, getUserInMemberFromGql } from '@/utils/UodkaClients/Core/models/User';

export interface MemberInBusinessInterface {
    id: string;
    role: BusinessMemberRole;
    user: UserInMember;
}

export class MemberInBusiness implements MemberInBusinessInterface {
    protected _id: string;

    protected _role: BusinessMemberRole;

    protected _user: UserInMember;

    get id() {
        return this._id;
    }

    get role() {
        return this._role;
    }

    get user() {
        return this._user;
    }

    get data() {
        return {
            id: this.id,
            role: this.role,
            user: this.user,
        };
    }

    constructor({ id, role, user }: MemberInBusinessInterface) {
        this._id = id;
        this._role = role;
        this._user = user;
    }
}

export const getMemberInBusinessFromGql = ({ id, role, user }: GqlBusinessMemberInBusiness) => {
    return new MemberInBusiness({
        id,
        role,
        user: getUserInMemberFromGql(user),
    });
};
