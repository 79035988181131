import { FC } from 'react';

import { InvoiceDataItem, InvoiceDataItemInterface, getGqlInvoiceDataItem } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';
import { TextInput, FloatInput } from '@/components/0_atom/Input';
import Button from '@/components/0_atom/Button';

import SvgTrash from '@/svgs/trash';

import { ItemField } from '@/views/bills/AddBillDrawer/BillEditor/BillDataEditor/ItemsEditor/ItemField';
import { ConsumptionTaxEditor } from '@/views/bills/AddBillDrawer/BillEditor/BillDataEditor/ItemsEditor/ConsumptionTaxEditor';
import { WithholdingTaxEditor } from '@/views/bills/AddBillDrawer/BillEditor/BillDataEditor/ItemsEditor/WithholdingTaxEditor';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 14,
        marginBottom: 14,
        background: '#fff',
        borderRadius: 4,
        position: 'relative',
    },
    upper: {
        display: 'flex',
        '& > div:not(:last-child)': {
            marginRight: 7,
        },
    },
    taxes: {
        display: 'flex',
        marginTop: 7,
        '& > :first-child': {
            marginRight: 28,
        },
    },
    input: {
        '& input': {
            padding: 16,
            fontSize: 12,
        },
    },
    description: {
        flexGrow: 1,
    },
    unitPrice: {
        width: 100,
    },
    quantity: {
        width: 100,
    },
    removeButton: {
        minWidth: 0,
        padding: 7,
        position: 'absolute',
        bottom: 0,
        right: 0,
    },
    trashIcon: {
        width: 14,
        height: 14,
        color: theme.palette.text.disabled,
    },
}));

export const ItemEditor: FC<{
    item: InvoiceDataItem;
    setItem: (items: InvoiceDataItem) => void;
    removeItem: () => void;
}> = ({ item, setItem, removeItem }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    // HANDLER
    const handleSetItem = (newData: InvoiceDataItemInterface) => {
        setItem(new InvoiceDataItem(newData));
    };

    return (
        <div className={c.root}>
            <div className={c.upper}>
                <ItemField label={txt({ en: 'Description', ja: '摘要' })} className={c.description}>
                    <TextInput
                        fullWidth
                        className={c.input}
                        value={item.title}
                        setValue={(newTitle) =>
                            handleSetItem({
                                ...item.data,
                                title: newTitle || '',
                            })
                        }
                        placeholder={txt({ en: 'Description', ja: '摘要' })}
                    />
                </ItemField>
                <ItemField label={txt({ en: 'Unit price', ja: '単価' })} className={c.unitPrice}>
                    <FloatInput
                        className={c.input}
                        value={item.unitPrice}
                        setValue={(newUnitPrice) =>
                            handleSetItem({
                                ...item.data,
                                unitPrice: newUnitPrice || 0,
                            })
                        }
                        startAdornment={'¥'}
                    />
                </ItemField>
                <ItemField label={txt({ en: 'Quantity', ja: '数量' })} className={c.quantity}>
                    <FloatInput
                        className={c.input}
                        value={item.quantity}
                        setValue={(newQuantity) =>
                            handleSetItem({
                                ...item.data,
                                quantity: newQuantity || 0,
                            })
                        }
                    />
                </ItemField>
            </div>
            <div className={c.taxes}>
                <ItemField label={txt({ en: 'Consumption tax', ja: '消費税' })}>
                    <ConsumptionTaxEditor item={item} setItem={handleSetItem} />
                </ItemField>
                <ItemField label={txt({ en: 'Withholding tax', ja: '源泉所得税等' })}>
                    <WithholdingTaxEditor item={item} setItem={handleSetItem} />
                </ItemField>
            </div>
            <Button onClick={removeItem} className={c.removeButton}>
                <SvgTrash className={c.trashIcon} />
            </Button>
        </div>
    );
};
ItemEditor.displayName = 'ItemEditor';
