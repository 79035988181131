import { GraphQLSdk as CertSdk } from '@/utils/UodkaClients/Cert/graphql/graphqlSdk';
import { getSignatureFromGql } from '@/utils/UodkaClients/Cert';
import { BillData } from '@/utils/UodkaClients/Invoices/models/InvoiceData';
import { ReceivingSignature } from '@/utils/UodkaClients/Invoices/models/Signature';
import {
    getBillDataString,
    stringifyObject,
    getUnixTime,
    getHexDigest,
} from '@/utils/UodkaClients/Invoices/utils/verifySignature';

export const createReceivingSignature = async ({
    businessId,
    resourceId,
    certSdk,
    billData,
}: {
    businessId: string;
    resourceId: string;
    certSdk: CertSdk;
    billData: BillData;
}): Promise<ReceivingSignature> => {
    const billDataString = getBillDataString({ billData });
    const billReceivedAt = new Date();
    const billReceivedAtString = stringifyObject({ billReceivedAt: getUnixTime(billReceivedAt) });
    const hexDigest = getHexDigest(billDataString + billReceivedAtString);
    const { createSignature } = await certSdk.createSignature({
        input: {
            businessId,
            resourceId,
            text: hexDigest,
        },
    });
    return new ReceivingSignature({
        billReceivedAt,
        signature: getSignatureFromGql(createSignature.signature),
    });
};
