import { FC } from 'react';

import { useLanguage, useClientData, useClientProfile } from '@/utils/customHooks';
import Drawer from '@/components/0_atom/Drawer';
import FetchingText from '@/components/0_atom/FetchingText';

import { useBillsRouting } from '@/views/bills/useBillsRouting';
import { IssuedBillItem, ReceivedBillItem } from '@/views/bills/useBillItems';
import { IssuedBillDrawerContent } from '@/views/bills/BillDrawer/IssuedBill/IssuedBillDrawerContent';
import { ReceivedBillDrawerContent } from '@/views/bills/BillDrawer/ReceivedBill/ReceivedBillDrawerContent';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {},
    fetchingText: {
        marginTop: 38,
    },
    notFound: {
        marginTop: 38,
        fontWeight: 600,
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.disabled,
    },
}));

export const BillDrawer: FC<{
    isOpen: boolean;
    onClose: () => void;
    issuedBillItem: IssuedBillItem | undefined;
    receivedBillItem: ReceivedBillItem | undefined;
    updateIssuedBillItem: (newItem: IssuedBillItem) => void;
    updateReceivedBillItem: (newItem: ReceivedBillItem) => void;
    removeIssuedBillItem: (id: string) => void;
    removeReceivedBillItem: (id: string) => void;
    isFetching: boolean;
    isDisableNotFound?: boolean;
}> = ({
    isOpen,
    onClose,
    issuedBillItem,
    receivedBillItem,
    updateIssuedBillItem,
    updateReceivedBillItem,
    removeIssuedBillItem,
    removeReceivedBillItem,
    isFetching,
    isDisableNotFound,
}) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { slug1 } = useBillsRouting();
    const { txt } = useLanguage();
    const { clientData } = useClientData();
    const { clientProfile } = useClientProfile();

    return (
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            backText={txt({ en: 'Back to bills', ja: '一覧に戻る' })}
            title={txt({
                en: `${slug1 === 'issued' ? 'Issued' : 'Received'} bill`,
                ja: `${slug1 === 'issued' ? '発行した' : '受け取った'}請求`,
            })}
        >
            {!isFetching && clientData && clientProfile !== 'fetching' ? (
                <>
                    {issuedBillItem ? (
                        <IssuedBillDrawerContent
                            issuedBillItem={issuedBillItem}
                            updateIssuedBillItem={updateIssuedBillItem}
                            removeIssuedBillItem={() => {
                                removeIssuedBillItem(issuedBillItem.id);
                                onClose();
                            }}
                            clientData={clientData}
                            clientProfile={clientProfile}
                        />
                    ) : receivedBillItem ? (
                        <ReceivedBillDrawerContent
                            receivedBillItem={receivedBillItem}
                            updateReceivedBillItem={updateReceivedBillItem}
                            removeReceivedBillItem={() => {
                                removeReceivedBillItem(receivedBillItem.id);
                                onClose();
                            }}
                            clientData={clientData}
                            clientProfile={clientProfile}
                        />
                    ) : isDisableNotFound ? (
                        <FetchingText className={c.fetchingText} />
                    ) : (
                        <MuiTypography className={c.notFound}>
                            {txt({
                                en: 'The URL is not correct.',
                                ja: '請求がみつかりません。URLが正しくありません。',
                            })}
                        </MuiTypography>
                    )}
                </>
            ) : (
                <FetchingText className={c.fetchingText} />
            )}
        </Drawer>
    );
};
BillDrawer.displayName = 'BillDrawer';
