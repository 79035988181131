import dotenv from 'dotenv';

dotenv.config();

type UodkaEnv = 'development' | 'staging' | 'production';

const getUodkaEnv = (): UodkaEnv => {
    const str = process.env.REACT_APP_UODKA_ENV;
    if (str === 'production') {
        return 'production';
    }
    if (str === 'staging') {
        return 'staging';
    }
    if (str === 'development') {
        return 'development';
    }
    throw new Error(`getUodkaEnv: Unexpected process.env.REACT_APP_UODKA_ENV got->${str}`);
};

interface EnvVariables {
    uodkaEnv: UodkaEnv;
    rootUrl: string;
    inviteRootUrl: string;
    billsRootUrl: string;
    quickstartUrl: string;
    service: {
        core: {
            graphqlEndpointUrl: string;
        };
        profile: {
            graphqlEndpointUrl: string;
        };
        cert: {
            graphqlEndpointUrl: string;
        };
        invoices: {
            graphqlEndpointUrl: string;
        };
    };
    firebase: {
        core: {
            projectId: string;
            authDomain: string;
            apiKey: string;
        };
        profile: {
            projectId: string;
            storageBucket: string;
        };
        cert: {
            projectId: string;
        };
        invoices: {
            projectId: string;
        };
    };
}

export const getEnvVariables = (): EnvVariables => {
    return {
        uodkaEnv: getUodkaEnv(),
        rootUrl: process.env.REACT_APP_ROOT_URL || '',
        inviteRootUrl: process.env.REACT_APP_INVITE_ROOT_URL || '',
        billsRootUrl: process.env.REACT_APP_BILLS_ROOT_URL || '',
        quickstartUrl: process.env.REACT_APP_QUICKSTART_URL || '',
        service: {
            core: {
                graphqlEndpointUrl: process.env.REACT_APP_SERVICE_CORE_GRAPHQL_ENDPOINT_URL || '',
            },
            profile: {
                graphqlEndpointUrl: process.env.REACT_APP_SERVICE_PROFILE_GRAPHQL_ENDPOINT_URL || '',
            },
            cert: {
                graphqlEndpointUrl: process.env.REACT_APP_SERVICE_CERT_GRAPHQL_ENDPOINT_URL || '',
            },
            invoices: {
                graphqlEndpointUrl: process.env.REACT_APP_SERVICE_INVOICES_GRAPHQL_ENDPOINT_URL || '',
            },
        },
        firebase: {
            core: {
                projectId: process.env.REACT_APP_FIREBASE_CORE_PROJECT_ID || '',
                authDomain: process.env.REACT_APP_FIREBASE_CORE_AUTH_DOMAIN || '',
                apiKey: process.env.REACT_APP_FIREBASE_CORE_API_KEY || '',
            },
            profile: {
                projectId: process.env.REACT_APP_FIREBASE_PROFILE_PROJECT_ID || '',
                storageBucket: process.env.REACT_APP_FIREBASE_PROFILE_STORAGE_BUCKET || '',
            },
            cert: {
                projectId: process.env.REACT_APP_FIREBASE_CERT_PROJECT_ID || '',
            },
            invoices: {
                projectId: process.env.REACT_APP_FIREBASE_INVOICES_PROJECT_ID || '',
            },
        },
    };
};
