import { FC } from 'react';
import clsx from 'clsx';

import { ExternalBankAccountInterface } from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import ContactNameWithIcon from '@/components/2_org/ContactNameWithIcon';
import { SelfDepositAccountCard } from '@/components/2_org/BankAccountCard/SelfDepositAccountCard';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    holder: {
        marginBottom: 24,
    },
}));

export const SelfDepositAccountCardWithHolder: FC<{
    className?: string;
    depositAccount: ExternalBankAccountInterface;
    holder: {
        name: string;
        profile: BusinessProfile | undefined;
    };
}> = ({ className, depositAccount, holder }) => {
    // STYLE
    const theme = useTheme();
    const c = useStyles(theme);

    return (
        <div className={clsx(c.root, className)}>
            <ContactNameWithIcon className={c.holder} contact={{ name: holder.name }} profile={holder.profile} />
            <SelfDepositAccountCard depositAccount={depositAccount} />
        </div>
    );
};
SelfDepositAccountCardWithHolder.displayName = 'SelfDepositAccountCardWithHolder';
