import { FC } from 'react';
import clsx from 'clsx';

import { useLanguage } from '@/utils/customHooks';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.disabled,
        fontWeight: 600,
    },
}));

const NotSetText: FC<{
    className?: string;
    text?: string;
}> = ({ className, text }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    return (
        <MuiTypography className={clsx(c.root, className)} variant={'body2'}>
            {text || txt({ en: 'Not set', ja: '未設定' })}
        </MuiTypography>
    );
};
NotSetText.displayName = 'NotSetText';
export default NotSetText;
