import { GqlDateTimeData } from '@/utils/UodkaClients/Invoices/graphql/customScalars';
import { PaymentInSharedBill as GqlPaymentInSharedBill } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import { PaymentData, getPaymentDataFromGql } from '@/utils/UodkaClients/Invoices/models/InvoiceData';

interface PaymentInSharedBillInterface {
    id: string;
    createdAt: Date;
    paymentData: PaymentData;
}

export class PaymentInSharedBill implements PaymentInSharedBillInterface {
    protected _id: string;

    protected _createdAt: Date;

    protected _paymentData: PaymentData;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get paymentData() {
        return this._paymentData;
    }

    constructor({ id, createdAt, paymentData }: PaymentInSharedBillInterface) {
        this._id = id;
        this._createdAt = createdAt;
        this._paymentData = paymentData;
    }
}

export const getPaymentInSharedBillFromGql = ({ id, createdAt, paymentData }: GqlPaymentInSharedBill) => {
    return new PaymentInSharedBill({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        paymentData: getPaymentDataFromGql(paymentData),
    });
};
