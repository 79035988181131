import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 1047 1047" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M923.01 186.064C1109.1 406.975 1080.87 736.914 859.96 923.009C639.049 1109.1 309.109 1080.87 123.018 859.96C-63.0732 639.05 -34.8472 309.11 186.064 123.018C406.974 -63.0735 736.914 -34.8465 923.01 186.064Z"
                fill="#3D73FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M123.018 859.96C309.109 1080.87 639.05 1109.1 859.961 923.01C1080.87 736.915 1109.1 406.975 923.001 186.064L123.018 859.96Z"
                fill="black"
                fillOpacity="0.1"
            />
            <path
                d="M667.852 315.012H626.935C624.926 315.013 622.955 315.555 621.23 316.584C619.505 317.612 618.089 319.087 617.134 320.854L548.642 444.645C535.68 472.339 523.306 504.746 523.306 504.746H522.127C522.127 504.746 509.753 472.338 496.789 444.645L428.937 320.888C427.984 319.113 426.568 317.629 424.839 316.594C423.11 315.559 421.133 315.013 419.118 315.012H378.17C369.667 315.012 364.297 324.153 368.438 331.581L455.918 485.87H407.868C401.715 485.87 396.726 490.859 396.726 497.012V519.298C396.726 525.452 401.715 530.441 407.868 530.441H479.535L493.255 554.832V575.012H407.868C401.715 575.012 396.726 580.002 396.726 586.155V608.441C396.726 614.595 401.715 619.584 407.868 619.584H493.255V719.87C493.255 726.023 498.244 731.012 504.398 731.012H541.036C547.189 731.012 552.178 726.023 552.178 719.87V619.584H638.154C644.307 619.584 649.297 614.595 649.297 608.441V586.155C649.297 580.002 644.307 575.012 638.154 575.012H552.178V554.832L565.897 530.441H638.154C644.307 530.441 649.297 525.452 649.297 519.298V497.012C649.297 490.859 644.307 485.87 638.154 485.87H589.527L677.572 331.606C681.736 324.178 676.368 315.012 667.852 315.012Z"
                fill="white"
            />
        </svg>
    );
};
Svg.displayName = 'svg/yen-circle';
export default Svg;
