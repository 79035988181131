import { Signature, getSignatureFromGql } from '@/utils/UodkaClients/Cert';
import { GqlDateTimeData } from '@/utils/UodkaClients/Invoices/graphql/customScalars';
import { RevokingSignature as GqlRevokingSignature } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';

export interface RevokingSignatureInterface {
    agreeToRevokeAt: Date;
    signature: Signature;
}

export class RevokingSignature implements RevokingSignatureInterface {
    protected _agreeToRevokeAt: Date;

    protected _signature: Signature;

    get agreeToRevokeAt() {
        return this._agreeToRevokeAt;
    }

    get signature() {
        return this._signature;
    }

    constructor({ agreeToRevokeAt, signature }: RevokingSignatureInterface) {
        this._agreeToRevokeAt = agreeToRevokeAt;
        this._signature = signature;
    }
}

export const getRevokingSignatureFromGql = ({ agreeToRevokeAt, signature }: GqlRevokingSignature) => {
    return new RevokingSignature({
        agreeToRevokeAt: GqlDateTimeData.serialize(agreeToRevokeAt),
        signature: getSignatureFromGql(signature),
    });
};
