import { BusinessMemberRole } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { getGraphqlSdk } from '@/utils/UodkaClients/Invoices/graphql/graphqlSdk';
import { getGraphqlSdk as getCoreSdk } from '@/utils/UodkaClients/Core/graphql/graphqlSdk';
import { getGraphqlSdk as getCertSdk } from '@/utils/UodkaClients/Cert/graphql/graphqlSdk';
import {
    UodkaInvoicesClientForObserver,
    UodkaInvoicesClientForGeneral,
    UodkaInvoicesClientForOwner,
} from '@/utils/UodkaClients/Invoices/clients';

export const initUodkaInvoicesClientForObserver = ({
    firebaseUserIdToken,
    clientId,
}: {
    firebaseUserIdToken: string;
    clientId: string;
}) => {
    const invoicesSdk = getGraphqlSdk({ firebaseUserIdToken });
    return new UodkaInvoicesClientForObserver({
        invoicesSdk,
        clientBusinessId: clientId,
    });
};

export const initUodkaInvoicesClientForGeneral = ({
    firebaseUserIdToken,
    clientId,
}: {
    firebaseUserIdToken: string;
    clientId: string;
}) => {
    const invoicesSdk = getGraphqlSdk({ firebaseUserIdToken });
    const coreSdk = getCoreSdk({ firebaseUserIdToken });
    const certSdk = getCertSdk({ firebaseUserIdToken });
    return new UodkaInvoicesClientForGeneral({
        invoicesSdk,
        coreSdk,
        certSdk,
        clientBusinessId: clientId,
    });
};

export const initUodkaInvoicesClientForOwner = ({
    firebaseUserIdToken,
    clientId,
}: {
    firebaseUserIdToken: string;
    clientId: string;
}) => {
    const invoicesSdk = getGraphqlSdk({ firebaseUserIdToken });
    const coreSdk = getCoreSdk({ firebaseUserIdToken });
    const certSdk = getCertSdk({ firebaseUserIdToken });
    return new UodkaInvoicesClientForOwner({
        invoicesSdk,
        coreSdk,
        certSdk,
        clientBusinessId: clientId,
    });
};

export const initUodkaInvoicesClient = ({
    firebaseUserIdToken,
    clientId,
    role,
}: {
    firebaseUserIdToken?: string;
    clientId: string;
    role: BusinessMemberRole;
}) => {
    if (role === 'owner') {
        if (!firebaseUserIdToken) {
            throw new Error('initUodkaInvoicesClient: missing firebaseUserIdToken');
        }
        return initUodkaInvoicesClientForOwner({
            firebaseUserIdToken,
            clientId,
        });
    }
    if (role === 'general') {
        if (!firebaseUserIdToken) {
            throw new Error('initUodkaInvoicesClient: missing firebaseUserIdToken');
        }
        return initUodkaInvoicesClientForGeneral({
            firebaseUserIdToken,
            clientId,
        });
    }
    if (role === 'observer') {
        if (!firebaseUserIdToken) {
            throw new Error('initUodkaInvoicesClient: missing firebaseUserIdToken');
        }
        return initUodkaInvoicesClientForObserver({
            firebaseUserIdToken,
            clientId,
        });
    }
    throw new Error('initUodkaInvoicesClient: Unexpected case.');
};
