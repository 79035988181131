import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 347 248" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M309.969 114.479C311.487 108.998 312.3 103.296 312.3 97.4286C312.3 63.1625 285.136 35.4286 251.575 35.4286C242.52 35.4286 233.737 37.4214 225.55 41.4071C208.417 16.4964 179.844 0 147.475 0C96.2925 0 54.3814 41.2411 52.1584 93.1107C21.2537 104.182 0 134.13 0 168.286C0 212.35 34.9169 248 78.075 248H277.6C315.933 248 347 216.336 347 177.143C347 151.125 333.012 126.879 309.969 114.479ZM277.6 230.286H78.075C44.5136 230.286 17.35 202.552 17.35 168.286C17.35 136.843 40.2303 110.88 69.9422 106.839C69.5627 103.739 69.4 100.584 69.4 97.4286C69.4 53.4196 104.371 17.7143 147.475 17.7143C180.169 17.7143 208.146 38.1964 219.803 67.3143C227.719 58.6232 238.996 53.1429 251.575 53.1429C275.54 53.1429 294.95 72.9607 294.95 97.4286C294.95 107.67 291.534 117.136 285.787 124.664C310.673 128.65 329.65 150.627 329.65 177.143C329.65 206.482 306.336 230.286 277.6 230.286Z"
                fill="currentColor"
            />
            <path
                d="M228.961 98.078L147.845 178.762L118.039 149.116C116.594 147.679 114.251 147.679 112.806 149.116L104.084 157.792C102.639 159.229 102.639 161.56 104.084 162.997L145.228 203.922C146.673 205.359 149.016 205.359 150.461 203.922L242.916 111.959C244.361 110.522 244.361 108.191 242.916 106.754L234.194 98.078C232.749 96.6407 230.406 96.6407 228.961 98.078Z"
                fill="currentColor"
            />
        </svg>
    );
};
Svg.displayName = 'svg/cloud-success';
export default Svg;
