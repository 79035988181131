import { getCSV, getBankAccountText } from '@/utils/utilFunctions';

import { getTaxName } from '@/views/bills/BillViewer/getTaxName';
import { FieldItem, getKindName } from '@/views/records/useAccountingItems';

const getCsvDateStr = (date: Date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const parepareRecordsCsv = ({
    items,
    isContactId,
    isContactName,
    isItemName,
    isKind,
    isSelfDepositAmount,
    isReceivedBillAmount,
    isIssuedBillAmount,
    isIssuedPaymentAmount,
    isReceivedPaymentAmount,
    isPaidTaxAmount,
    isReceivedTaxAmount,
    isBankAccount,
    isBillDate,
    isPaymentDate,
    txt,
}: {
    items: FieldItem[];
    isContactId: boolean;
    isContactName: boolean;
    isItemName: boolean;
    isKind: boolean;
    isSelfDepositAmount: boolean;
    isReceivedBillAmount: boolean;
    isIssuedBillAmount: boolean;
    isIssuedPaymentAmount: boolean;
    isReceivedPaymentAmount: boolean;
    isPaidTaxAmount: boolean;
    isReceivedTaxAmount: boolean;
    isBankAccount: boolean;
    isBillDate: boolean;
    isPaymentDate: boolean;
    txt: (input: { en: string; ja?: string }) => string;
}) => {
    const headRow = (() => {
        const arr: (string | number | Date)[] = [];
        if (isContactId) {
            arr.push(txt({ en: 'Contact ID', ja: 'コンタクトID' }));
        }
        if (isContactName) {
            arr.push(txt({ en: 'Contact Name', ja: 'コンタクトの名称' }));
        }
        if (isItemName) {
            arr.push(txt({ en: 'Item', ja: '項目' }));
        }
        if (isKind) {
            arr.push(txt({ en: 'Kind', ja: '種別' }));
        }
        if (isSelfDepositAmount) {
            arr.push(txt({ en: 'Amount (Self Deposit)', ja: '金額（自己入金）' }));
        }
        if (isReceivedBillAmount) {
            arr.push(txt({ en: 'Amount (Received Bill)', ja: '金額（受取請求）' }));
        }
        if (isIssuedBillAmount) {
            arr.push(txt({ en: 'Amount (Issued Bill)', ja: '金額（発行請求）' }));
        }
        if (isIssuedPaymentAmount) {
            arr.push(txt({ en: 'Amount (Paid)', ja: '金額（支払）' }));
        }
        if (isReceivedPaymentAmount) {
            arr.push(txt({ en: 'Amount (Deposit)', ja: '金額（受取）' }));
        }
        if (isPaidTaxAmount) {
            arr.push(txt({ en: 'Amount (Paid Tax)', ja: '金額（支払税金）' }));
        }
        if (isReceivedTaxAmount) {
            arr.push(txt({ en: 'Amount (Received Tax)', ja: '金額（受取税金）' }));
        }
        if (isBankAccount) {
            arr.push(txt({ en: 'Bank Account', ja: '銀行口座' }));
        }
        if (isBillDate) {
            arr.push(txt({ en: 'Billing Date', ja: '請求発行日' }));
        }
        if (isPaymentDate) {
            arr.push(txt({ en: 'Transaction Date', ja: '支払・入金日' }));
        }
        return arr;
    })();
    const bodyRows = items.map((item) => {
        const arr: (string | number | Date | undefined)[] = [];
        if (isContactId) {
            arr.push(item.contactId || '-');
        }
        if (isContactName) {
            arr.push(item.contactName || '-');
        }
        if (isItemName) {
            arr.push(
                item.kind === 'paidTax' || item.kind === 'receivedTax'
                    ? getTaxName({ taxTitle: item.itemName, txt })
                    : item.itemName
            );
        }
        if (isKind) {
            arr.push(getKindName({ kind: item.kind, txt }));
        }
        if (isSelfDepositAmount) {
            arr.push(item.selfDepositAmount ? item.selfDepositAmount : undefined);
        }
        if (isReceivedBillAmount) {
            arr.push(item.receivedBillAmount ? item.receivedBillAmount : undefined);
        }
        if (isIssuedBillAmount) {
            arr.push(item.issuedBillAmount ? item.issuedBillAmount : undefined);
        }
        if (isIssuedPaymentAmount) {
            arr.push(item.kind === 'cost' && item.issuedPaymentAmount ? item.issuedPaymentAmount : undefined);
        }
        if (isReceivedPaymentAmount) {
            arr.push(item.kind === 'revenue' && item.receivedPaymentAmount ? item.receivedPaymentAmount : undefined);
        }
        if (isPaidTaxAmount) {
            arr.push(item.kind === 'paidTax' && item.issuedPaymentAmount ? item.issuedPaymentAmount : undefined);
        }
        if (isReceivedTaxAmount) {
            arr.push(
                item.kind === 'receivedTax' && item.receivedPaymentAmount ? item.receivedPaymentAmount : undefined
            );
        }
        if (isBankAccount) {
            arr.push(item.bankAccount ? getBankAccountText({ bankAccount: item.bankAccount }) : '-'); // later
        }
        if (isBillDate) {
            arr.push(item.billDate ? getCsvDateStr(item.billDate) : undefined);
        }
        if (isPaymentDate) {
            arr.push(getCsvDateStr(item.paymentDate));
        }
        return arr;
    });
    const csv = getCSV([headRow, ...bodyRows]);
    return csv;
};
