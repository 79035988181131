import { FC } from 'react';

import { ClientData } from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import { TransferConfirmationForBillAPIForGeneral } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';
import { ExternalBankAccountCardWithHolder, DepositAccountCardWithHolder } from '@/components/2_org/BankAccountCard';
import HorizontalTable from '@/components/0_atom/HorizontalTable';

import { getConfirmationFormMessages } from '@/views/transactions/AddTransferModal/getFormMessages';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    message: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: theme.typography.body2.fontSize,
        '& span:first-child': {},
        '& span:nth-child(2)': {
            width: 60,
            textAlign: 'right',
        },
        '&:not(:last-child)': {
            marginBottom: 7,
        },
        // color: theme.palette.text.disabled,
        // fontWeight: 600,
    },
    amount: {
        fontWeight: 600,
        // color: theme.palette.text.primary,
    },
    total: {
        fontWeight: 600,
        // color: theme.palette.text.primary,
    },
    bankAccountCard: {
        borderRadius: 4,
        border: 'solid 2px rgba(0,0,0,.03)',
        padding: 14,
    },
}));

export const TransferConfirmationModalContent: FC<{
    transferConfirmationAPI: TransferConfirmationForBillAPIForGeneral;
    contactInfo: {
        id: string;
        name: string;
        profile: BusinessProfile | undefined;
    };
    clientData: ClientData;
    clientProfile: BusinessProfile | undefined;
    currentBalance: number;
}> = ({ transferConfirmationAPI, contactInfo, clientData, clientProfile, currentBalance }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    // DATA

    return (
        <HorizontalTable
            items={[
                {
                    label: txt({ en: 'Transfer to', ja: '送金先' }),
                    children: transferConfirmationAPI.bankAccount.isDepositAccount ? (
                        <DepositAccountCardWithHolder
                            className={c.bankAccountCard}
                            depositAccount={transferConfirmationAPI.bankAccount}
                            holder={{
                                name: transferConfirmationAPI.contact.name,
                                profile: contactInfo.profile,
                            }}
                            remitter={{
                                name: clientData.name,
                                profile: clientProfile,
                            }}
                        />
                    ) : (
                        <ExternalBankAccountCardWithHolder
                            className={c.bankAccountCard}
                            bankAccount={transferConfirmationAPI.bankAccount}
                            holder={{
                                name: transferConfirmationAPI.contact.name,
                                profile: contactInfo.profile,
                            }}
                        />
                    ),
                },
                {
                    label: txt({ en: 'Amount', ja: '金額' }),
                    children: getConfirmationFormMessages({
                        messageClassName: c.message,
                        amountClassName: c.amount,
                        totalClassName: c.total,
                        txt,
                        transferFee: transferConfirmationAPI.feeOnIssuer,
                        amount: transferConfirmationAPI.amount,
                        balance: currentBalance,
                    }),
                },
            ]}
        />
    );
};
TransferConfirmationModalContent.displayName = 'TransferConfirmationModalContent';
