import { MutationValidationResult } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { GraphQLSdk } from '@/utils/UodkaClients/Core/graphql/graphqlSdk';
import {
    InviteForMemberInBusiness,
    InviteForMemberInBusinessInterface,
} from '@/utils/UodkaClients/Core/models/InviteForMember/data/InviteForMemberInBusiness';

interface InviteForMemberAPIForOwnerInterface {
    remove: () => Promise<MutationValidationResult>;
}

interface ConstructorInput extends InviteForMemberInBusinessInterface {
    graphqlSdk: GraphQLSdk;
    parentBusinessId: string;
}

export class InviteForMemberAPIForOwner
    extends InviteForMemberInBusiness
    implements InviteForMemberAPIForOwnerInterface {
    protected _graphqlSdk: GraphQLSdk;

    protected _parentBusinessId: string;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
        this._parentBusinessId = input.parentBusinessId;
    }

    async remove() {
        const { deleteInviteForBusinessMember } = await this._graphqlSdk.deleteInviteForBusinessMember({
            input: {
                businessId: this._parentBusinessId,
                inviteCodeText: this._codeText,
            },
        });
        return deleteInviteForBusinessMember.result;
    }
}
