import { FC } from 'react';

import { InvoiceDataItem } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';

import { ItemField } from '@/views/bills/BillViewer/BillViewerDigital/BillDataViewer/ItemsViewer/ItemField';
import { ConsumptionTaxViewer } from '@/views/bills/BillViewer/BillViewerDigital/BillDataViewer/ItemsViewer/ConsumptionTaxViewer';
import { TaxViewer } from '@/views/bills/BillViewer/BillViewerDigital/BillDataViewer/ItemsViewer/TaxViewer';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '14px 18px',
        background: '#fff',
        borderRadius: 4,
        position: 'relative',
        '&:not(:last-child)': {
            marginBottom: 14,
        },
    },
    upper: {
        display: 'flex',
        '& > div:not(:last-child)': {
            marginRight: 7,
        },
    },
    taxes: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 7,
        '& > :first-child': {
            marginRight: 28,
        },
    },
    input: {
        '& input': {
            padding: 16,
            fontSize: 12,
        },
    },
    description: {
        flexGrow: 1,
    },
    unitPrice: {
        width: 100,
    },
    quantity: {
        width: 100,
    },
}));

export const ItemViewer: FC<{
    item: InvoiceDataItem;
}> = ({ item }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    // later: item.dateに対応
    return (
        <div className={c.root}>
            <div className={c.upper}>
                <ItemField label={txt({ en: 'Description', ja: '摘要' })} className={c.description}>
                    <div className={c.input}>{item.title}</div>
                </ItemField>
                <ItemField label={txt({ en: 'Unit price', ja: '単価' })} className={c.unitPrice}>
                    <div className={c.input}>{`¥ ${item.unitPrice.toLocaleString()}`}</div>
                </ItemField>
                <ItemField label={txt({ en: 'Quantity', ja: '数量' })} className={c.quantity}>
                    <div className={c.input}>{item.quantity.toLocaleString()}</div>
                </ItemField>
            </div>
            <div className={c.taxes}>
                {item.taxes.map((tax, i) =>
                    tax.title === 'consumptionTax (10%)' ? (
                        <ItemField key={i} label={txt({ en: 'Consumption tax', ja: '消費税' })}>
                            <ConsumptionTaxViewer rate={0.1} taxValue={tax.value} />
                        </ItemField>
                    ) : tax.title === 'consumptionTax (8%)' ? (
                        <ItemField key={i} label={txt({ en: 'Consumption tax', ja: '消費税' })}>
                            <ConsumptionTaxViewer rate={0.08} taxValue={tax.value} />
                        </ItemField>
                    ) : tax.title === 'withholdingTax' ? (
                        <ItemField key={i} label={txt({ en: 'Withholding tax', ja: '源泉所得税等' })}>
                            <TaxViewer taxValue={tax.value} />
                        </ItemField>
                    ) : (
                        <ItemField key={i} label={tax.title}>
                            <TaxViewer taxValue={tax.value} />
                        </ItemField>
                    )
                )}
            </div>
        </div>
    );
};
ItemViewer.displayName = 'ItemViewer';
