import { FC, useState } from 'react';

import { ClientDataAPIForOwner, getValidRemitterName } from '@/utils/UodkaClients/Core';
import { useLanguage, useFormField } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import { MutationFormModal } from '@/components/1_mol/FormModal';
import { TextInput } from '@/components/0_atom/Input';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({}));

export const ModifyRemitterNameKanaButton: FC<{
    clientDataAPI: ClientDataAPIForOwner;
    setClientData: (clientData: ClientDataAPIForOwner) => void;
}> = ({ clientDataAPI, setClientData }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [isOpen, setIsOpen] = useState(false);
    const [editingRemitterNameKana, setEditingRemitterNameKana] = useFormField(clientDataAPI.remitterNameKana);

    // DATA
    const { isEntered, isValid, halfWidthKatakana } = (() => {
        if (editingRemitterNameKana) {
            const { isValid, halfWidthKatakana } = getValidRemitterName(editingRemitterNameKana);
            return {
                isEntered: true,
                isValid,
                halfWidthKatakana,
            };
        }
        return {
            isEntered: true,
            isValid: false,
            halfWidthKatakana: undefined,
        };
    })();

    // MUTATION
    const run = async () => {
        if (isValid && halfWidthKatakana) {
            const result = await clientDataAPI.modifyRemitterNameKana(halfWidthKatakana);
            setClientData(clientDataAPI);
            return result;
        }
        throw new Error('ModifyRemitterNameKanaButton.run: !(isValid && halfWidthKatakana)');
    };

    return (
        <>
            <Button onClick={() => setIsOpen(true)} variant={'contained'}>
                {txt({ en: 'Update', ja: '設定' })}
            </Button>
            <MutationFormModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={txt({
                    en: `Modify ${clientDataAPI.name}'s remitter name`,
                    ja: `${clientDataAPI.name}の振込時の名義人名を設定`,
                })}
                mutation={{
                    run,
                    description: txt({
                        en: `Modify ${clientDataAPI.name}'s remitter name`,
                        ja: `${clientDataAPI.name}の振込時の名義人名を設定`,
                    }),
                }}
                items={[
                    {
                        isRequired: true,
                        labelText: txt({ en: 'Remitter name', ja: '振込時の名義人名' }),
                        isSatisfied: isEntered && isValid,
                        isError: isEntered && !isValid,
                        children: (
                            <TextInput
                                fullWidth
                                placeholder={txt({ en: 'Remitter name', ja: '振込時の名義人名' })}
                                value={editingRemitterNameKana}
                                setValue={(newValue) => setEditingRemitterNameKana(newValue || '')}
                                isSatisfied={isEntered && isValid}
                                messages={[
                                    {
                                        isError: isEntered && !isValid,
                                        message: txt({
                                            en:
                                                'Remitter name of account should be within 19 characters in Hiragana, "（" or "）" (counting turbidity marks and half turbidity marks as one character)',
                                            ja:
                                                '口座名義は、ひらがなと「（」「）」のいずれかで、で19文字以内（濁点・半濁点を１文字としてカウント）の必要があります。',
                                        }),
                                    },
                                ]}
                            />
                        ),
                    },
                ]}
                submitText={txt({ en: 'Update', ja: '設定' })}
            />
        </>
    );
};
ModifyRemitterNameKanaButton.displayName = 'views/config/business/account/Basic/ModifyRemitterNameKanaButton';
