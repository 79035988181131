import { FC } from 'react';

import { useUserCtx } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';

import SvgNolicense from '@/svgs/nolicense';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {},
    icon: {
        width: 200,
        marginTop: 23,
    },
    title: {
        marginTop: 23,
    },
    description1: {
        marginTop: 14,
    },
    description2: {
        marginTop: 7,
    },
    button: {
        marginTop: 23,
    },
}));

const NoUserDisplay: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const { firebaseUser, signOut } = useUserCtx();

    return (
        <div className={c.root}>
            <SvgNolicense className={c.icon} />
            <MuiTypography className={c.title} variant={'h1'}>
                {'No License'}
            </MuiTypography>
            <MuiTypography className={c.description1} variant={'body1'}>
                {txt({
                    en: `You cannot use this app with the Google account ${
                        firebaseUser?.email ? `( ${firebaseUser.email} )` : ''
                    } you are currently signing in.`,
                    ja: `現在ログインに使用しているGoogleアカウント${
                        firebaseUser?.email ? ` ( ${firebaseUser.email} ) ` : ''
                    }では、このアプリを利用できません。`,
                })}
            </MuiTypography>
            <MuiTypography className={c.description2} variant={'body1'}>
                {txt({
                    en: 'Uodka is currently invite-only. To sign up, you need an invite issued by another user.',
                    ja: 'Uodkaは現在招待制となっています。利用の開始には、他のユーザーが発行した招待が必要です。',
                })}
            </MuiTypography>
            <Button onClick={signOut} role={'submit'} className={c.button}>
                {txt({
                    en: 'Sign out',
                    ja: 'ログアウト',
                })}
            </Button>
        </div>
    );
};
NoUserDisplay.displayName = 'NoUserDisplay';
export default NoUserDisplay;
