import { FC } from 'react';

import { BusinessSnapshot } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';
import { getStrWithBr } from '@/utils/utilFunctions';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#fafafa',
        padding: 14,
        borderRadius: 4,
        width: 300,
        '& table': {
            borderCollapse: 'collapse',
            '& tr': {
                textAlign: 'left',
                paddingBottom: 7,
                '& th': {
                    fontWeight: 600,
                    fontSize: theme.typography.caption.fontSize,
                    color: theme.palette.text.disabled,
                    paddingTop: 2,
                    paddingRight: 14,
                    verticalAlign: 'top',
                    minWidth: 95,
                },
                '& td': {
                    fontWeight: 600,
                },
                '&:not(:last-child) td': {
                    paddingBottom: 7,
                },
            },
        },
    },
}));

const BusinessSnapshotItemViewer: FC<{
    title: string;
    value: string;
}> = ({ title, value }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <tr>
            <th>{title}</th>
            <td>{getStrWithBr(value)}</td>
        </tr>
    );
};

export const BusinessSnapshotViewer: FC<{
    businessSnapshot: BusinessSnapshot;
}> = ({ businessSnapshot }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    return (
        <div className={c.root}>
            <table>
                <tbody>
                    <BusinessSnapshotItemViewer title={txt({ en: 'Name', ja: '名称' })} value={businessSnapshot.name} />
                    {businessSnapshot.items.map(({ title, value }, i) => (
                        <BusinessSnapshotItemViewer
                            key={i}
                            title={
                                title === 'address'
                                    ? txt({ en: 'Address', ja: '住所' })
                                    : title === 'phone'
                                    ? txt({ en: 'Phone', ja: '電話番号' })
                                    : title === 'email'
                                    ? txt({ en: 'Email', ja: 'メールアドレス' })
                                    : title === 'websiteUrl'
                                    ? txt({ en: 'Website URL', ja: 'ウェブサイトURL' })
                                    : title
                            }
                            value={value}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};
BusinessSnapshotViewer.displayName = 'BusinessSnapshotViewer';
