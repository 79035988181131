import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        // <svg className={className} viewBox="0 0 2685 2002" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <rect x="656" rx="216" fill="white" />
        //     <path
        //         d="M1708.57 887.946L1798.68 1065.61L1972.12 1082.71C1976.24 1083.05 1980.19 1084.56 1983.48 1087.06C1986.77 1089.57 1989.28 1092.96 1990.69 1096.83C1992.11 1100.7 1992.38 1104.9 1991.48 1108.92C1990.57 1112.95 1988.52 1116.63 1985.58 1119.53L1842.85 1260.32L1895.77 1451.62C1896.85 1455.68 1896.73 1459.96 1895.42 1463.95C1894.12 1467.94 1891.68 1471.48 1888.41 1474.12C1885.13 1476.77 1881.16 1478.42 1876.97 1478.87C1872.77 1479.33 1868.54 1478.56 1864.77 1476.68L1688.93 1390.03L1513.34 1476.57C1509.57 1478.46 1505.33 1479.22 1501.14 1478.77C1496.95 1478.31 1492.97 1476.66 1489.7 1474.02C1486.42 1471.37 1483.99 1467.84 1482.68 1463.84C1481.38 1459.85 1481.26 1455.57 1482.34 1451.51L1535.26 1260.21L1392.42 1119.42C1389.48 1116.52 1387.43 1112.84 1386.52 1108.82C1385.62 1104.79 1385.89 1100.6 1387.31 1096.72C1388.72 1092.85 1391.23 1089.46 1394.52 1086.96C1397.81 1084.45 1401.76 1082.94 1405.88 1082.6L1579.32 1065.5L1669.3 887.946C1671.15 884.35 1673.96 881.331 1677.43 879.223C1680.89 877.115 1684.87 876 1688.93 876C1692.99 876 1696.97 877.115 1700.44 879.223C1703.9 881.331 1706.72 884.35 1708.57 887.946V887.946Z"
        //         fill="#D8C947"
        //     />
        //     <path
        //         fillRule="evenodd"
        //         clipRule="evenodd"
        //         d="M1463.36 699.5C1463.36 796.989 1384.19 876 1286.5 876C1188.81 876 1109.64 796.989 1109.64 699.5C1109.64 602.011 1188.81 523 1286.5 523C1384.19 523 1463.36 602.011 1463.36 699.5ZM1387.23 920.125H1410.3C1469.41 920.125 1522.09 947.721 1556.11 990.702L1531.16 1039.96L1334.54 1059.35C1329.86 1059.74 1325.39 1061.45 1321.66 1064.29C1317.93 1067.13 1315.09 1070.98 1313.48 1075.37C1311.88 1079.77 1311.57 1084.53 1312.59 1089.09C1313.62 1093.66 1315.94 1097.83 1319.28 1101.12L1448.95 1229H1043.32C1006.71 1229 977 1199.35 977 1162.81V1105.45C977 1003.14 1060.18 920.125 1162.7 920.125H1185.77C1216.59 934.19 1250.58 942.188 1286.5 942.188C1322.42 942.188 1356.55 934.19 1387.23 920.125Z"
        //         fill="#676767"
        //     />
        //     <path
        //         fillRule="evenodd"
        //         clipRule="evenodd"
        //         d="M698 1912.8C737.296 1966.5 800.789 2001.38 872.429 2001.38H2097.43C2216.72 2001.38 2313.43 1904.67 2313.43 1785.38V216.379C2313.43 183.326 2306.01 152.008 2292.73 124L698 1912.8Z"
        //         fill="#D8C947"
        //         fillOpacity="0.15"
        //     />
        //     <rect
        //         x="52.918"
        //         y="296"
        //         width="3078.35"
        //         height="102.037"
        //         rx="51.0187"
        //         transform="rotate(31.2393 52.918 296)"
        //         fill="#676767"
        //     />
        // </svg>
        <svg className={className} viewBox="0 0 2685 2002" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M656 216C656 96.7065 752.706 0 872 0H2097C2216.29 0 2313 96.7065 2313 216V1785C2313 1904.29 2216.29 2001 2097 2001H872C752.707 2001 656 1904.29 656 1785V216Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2097 60H872C785.844 60 716 129.844 716 216V1785C716 1871.16 785.844 1941 872 1941H2097C2183.16 1941 2253 1871.16 2253 1785V216C2253 129.844 2183.16 60 2097 60ZM872 0C752.706 0 656 96.7065 656 216V1785C656 1904.29 752.707 2001 872 2001H2097C2216.29 2001 2313 1904.29 2313 1785V216C2313 96.7065 2216.29 0 2097 0H872Z"
                fill="#FBF8DC"
            />
            <path
                d="M1286.5 876C1384.19 876 1463.36 796.989 1463.36 699.5C1463.36 602.011 1384.19 523 1286.5 523C1188.81 523 1109.64 602.011 1109.64 699.5C1109.64 796.989 1188.81 876 1286.5 876ZM1410.3 920.125H1387.23C1356.55 934.19 1322.42 942.188 1286.5 942.188C1250.58 942.188 1216.59 934.19 1185.77 920.125H1162.7C1060.18 920.125 977 1003.14 977 1105.45V1162.81C977 1199.35 1006.71 1229 1043.32 1229H1529.68C1566.29 1229 1596 1199.35 1596 1162.81V1105.45C1596 1003.14 1512.82 920.125 1410.3 920.125Z"
                fill="#676767"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M698 1912.8C737.296 1966.5 800.789 2001.38 872.429 2001.38H2097.43C2216.72 2001.38 2313.43 1904.67 2313.43 1785.38V216.379C2313.43 183.326 2306.01 152.008 2292.73 124L698 1912.8Z"
                fill="#FBF8DC"
            />
            <path
                d="M1677.68 838.551L1779.84 1040.08L1976.46 1059.47C1981.14 1059.86 1985.61 1061.58 1989.34 1064.41C1993.07 1067.25 1995.91 1071.1 1997.52 1075.49C1999.12 1079.89 1999.43 1084.65 1998.41 1089.21C1997.38 1093.78 1995.06 1097.95 1991.72 1101.24L1829.91 1260.94L1889.9 1477.94C1891.13 1482.54 1891 1487.4 1889.52 1491.93C1888.04 1496.46 1885.27 1500.47 1881.56 1503.47C1877.85 1506.47 1873.35 1508.34 1868.59 1508.86C1863.84 1509.37 1859.03 1508.5 1854.76 1506.37L1655.42 1408.08L1456.36 1506.24C1452.09 1508.38 1447.29 1509.25 1442.53 1508.73C1437.78 1508.22 1433.27 1506.35 1429.56 1503.35C1425.85 1500.34 1423.09 1496.34 1421.61 1491.81C1420.13 1487.28 1419.99 1482.42 1421.22 1477.82L1481.21 1260.82L1319.28 1101.12C1315.94 1097.83 1313.62 1093.66 1312.59 1089.09C1311.57 1084.53 1311.88 1079.77 1313.48 1075.37C1315.09 1070.98 1317.93 1067.13 1321.66 1064.29C1325.39 1061.45 1329.86 1059.74 1334.54 1059.35L1531.16 1039.96L1633.17 838.551C1635.26 834.471 1638.45 831.047 1642.38 828.656C1646.31 826.265 1650.82 825 1655.42 825C1660.03 825 1664.54 826.265 1668.47 828.656C1672.39 831.047 1675.58 834.471 1677.68 838.551V838.551Z"
                fill="#D8C947"
            />
            <path
                d="M26.459 339.621C41.0719 315.53 72.448 307.846 96.5394 322.459L2641.32 1866.02C2665.41 1880.63 2673.09 1912.01 2658.48 1936.1V1936.1C2643.87 1960.19 2612.49 1967.87 2588.4 1953.26L43.6215 409.702C19.5301 395.089 11.8461 363.713 26.459 339.621V339.621Z"
                fill="#676767"
            />
        </svg>
    );
};
Svg.displayName = 'svg/nolicense';
export default Svg;
