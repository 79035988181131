import { DepositAccount as GqlDepositAccount } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { ContactInDepositAccount, getContactInDepositAccountFromGql } from '@/utils/UodkaClients/Core/models/Contact';

interface DepositAccountInterface {
    id: string;
    bankCode: string;
    branchCode: string;
    accountNumber: string;
    accountHolderName: string;
    fromContact?: ContactInDepositAccount;
}

export class DepositAccount implements DepositAccountInterface {
    protected _id: string;

    protected _bankCode: string;

    protected _branchCode: string;

    protected _accountNumber: string;

    protected _accountHolderName: string;

    protected _fromContact?: ContactInDepositAccount;

    get id() {
        return this._id;
    }

    get bankCode() {
        return this._bankCode;
    }

    get branchCode() {
        return this._branchCode;
    }

    get accountNumber() {
        return this._accountNumber;
    }

    get accountHolderName() {
        return this._accountHolderName;
    }

    get fromContact() {
        return this._fromContact;
    }

    get data() {
        return {
            id: this._id,
            bankCode: this.bankCode,
            branchCode: this.branchCode,
            accountNumber: this.accountNumber,
            accountHolderName: this.accountHolderName,
            fromContact: this._fromContact,
        };
    }

    constructor({ id, bankCode, branchCode, accountNumber, accountHolderName, fromContact }: DepositAccountInterface) {
        this._id = id;
        this._bankCode = bankCode;
        this._branchCode = branchCode;
        this._accountNumber = accountNumber;
        this._accountHolderName = accountHolderName;
        this._fromContact = fromContact;
    }
}

export const getDepositAccountFromGql = ({
    id,
    bankCode,
    branchCode,
    accountNumber,
    accountHolderName,
    fromContact,
}: GqlDepositAccount) => {
    return new DepositAccount({
        id,
        bankCode,
        branchCode,
        accountNumber,
        accountHolderName,
        fromContact: fromContact ? getContactInDepositAccountFromGql(fromContact) : undefined,
    });
};

// export const getDepositAccount = (depositAccountInterface?: DepositAccountInterface) => {
//     if (depositAccountInterface) {
//         return new DepositAccount(depositAccountInterface);
//     }
//     return undefined;
// };
