/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect, ReactNode } from 'react';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import imageCompression from 'browser-image-compression';

import Button from '@/components/0_atom/Button';

import SvgImagePlus from '@/svgs/image-plus';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import MuiSnackbar from '@material-ui/core/Snackbar';

const useStyles = makeStyles((theme) => ({
    error: {
        background: theme.palette.error.main,
        color: '#fff',
        padding: '1em',
        borderRadius: 4,
        boxShadow: '0 16px 16px 0 rgba(0,0,0,.1)',
    },
}));

const getOptimizedImage = async (file: File) => {
    if (file.type === 'image/svg+xml') {
        return file;
    }
    if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/webp') {
        if (file.size > 200000) {
            const blob = await imageCompression(file, {
                maxSizeMB: 0.2,
            });
            return blob;
        }
        return file;
    }
    throw new Error('File type not permitted');
};

const getOptimizedImages = async (files: File[]) => {
    return Promise.all(
        files.map(async (file) =>
            getOptimizedImage(file).catch((error) => {
                throw error;
            })
        )
    );
};

const FileDropZone: FC<{
    setFiles: (files: File[]) => void;
    children?: ReactNode;
    isDisabled?: boolean;
    className?: string;
}> = ({ setFiles, children, isDisabled, className }) => {
    // STYLES
    const classes = useStyles(useTheme());

    // HOOKS
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        disabled: isDisabled,
        accept: 'image/jpeg, image/png, image/svg+xml, image/webp',
    });
    const [isError, setIsError] = useState(false);

    // USEEFFECT
    useEffect(() => {
        if (isError) {
            setIsError(false);
        }
        getOptimizedImages(acceptedFiles)
            .catch(() => {
                setIsError(true);
                setTimeout(() => {
                    setIsError(false);
                }, 2000);
            })
            .then((resizedFiles) => {
                if (resizedFiles) {
                    console.log('resizedFiles->', resizedFiles);
                    setFiles(resizedFiles);
                }
            });
    }, [acceptedFiles]);

    return (
        <>
            <div {...getRootProps({ className: clsx('dropzone', className) })}>
                {children || (
                    <Button>
                        <SvgImagePlus />
                    </Button>
                )}
                <input {...getInputProps()} />
            </div>
            {isError && (
                <MuiSnackbar
                    open={isError}
                    autoHideDuration={6000}
                    anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                >
                    <div className={classes.error}>
                        {
                            'ファイル形式に対応していません。アップロードできるファイル形式はpng、jpeg、svg、webpのいずれかです。'
                        }
                    </div>
                </MuiSnackbar>
            )}
        </>
    );
};

FileDropZone.displayName = 'FileDropZone';
export default FileDropZone;
