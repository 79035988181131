import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 443 254" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.51525 229.899L23.3142 249.699C28.0002 254.385 35.5982 254.385 40.2852 249.699L221.414 68.9995L402.544 249.697C407.23 254.383 414.828 254.383 419.515 249.697L439.314 229.898C444 225.212 444 217.615 439.314 212.928L229.899 3.5145C225.213 -1.1715 217.615 -1.1715 212.928 3.5145L3.51525 212.928C-1.17175 217.615 -1.17175 225.213 3.51525 229.899Z"
                fill="currentColor"
            />
        </svg>
    );
};
Svg.displayName = 'svg/fa-chevron-up';
export default Svg;
