import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  UnixTime: string;
};



export type Query = {
  serviceHealth: ServiceHealth;
  verifySignature: VerifySignaturePayload;
  getResource: GetResourcePayload;
};


export type QueryVerifySignatureArgs = {
  input: VerifySignatureInput;
};


export type QueryGetResourceArgs = {
  input: GetResourceInput;
};

export type Mutation = {
  createResource: CreateResourcePayload;
  createResourceAndSignature: CreateResourceAndSignaturePayload;
  createSignature: CreateSignaturePayload;
};


export type MutationCreateResourceArgs = {
  input: CreateResourceInput;
};


export type MutationCreateResourceAndSignatureArgs = {
  input: CreateResourceAndSignatureInput;
};


export type MutationCreateSignatureArgs = {
  input: CreateSignatureInput;
};

export type Resource = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  issuer: Issuer;
  recipient?: Maybe<Recipient>;
};

export type Issuer = {
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
};

export type Recipient = {
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
};

export type GetResourceInput = {
  businessId: Scalars['ID'];
  resourceId: Scalars['ID'];
};

export type CreateResourceInput = {
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
};

export type CreateResourceAndSignatureInput = {
  businessId: Scalars['ID'];
  contactId: Scalars['ID'];
  signatureText: Scalars['String'];
};

export type GetResourcePayload = {
  resource: Resource;
};

export type CreateResourcePayload = {
  resource: Resource;
};

export type CreateResourceAndSignaturePayload = {
  resource: Resource;
  signature: Signature;
};

export type Signature = {
  resourceId: Scalars['ID'];
  id: Scalars['ID'];
  signerId: Scalars['ID'];
  contactId: Scalars['ID'];
  text: Scalars['String'];
  unixTime: Scalars['UnixTime'];
};

export type CreateSignatureInput = {
  businessId: Scalars['ID'];
  resourceId: Scalars['ID'];
  text: Scalars['String'];
};

export type VerifySignatureInput = {
  resourceId: Scalars['ID'];
  signatureId: Scalars['ID'];
  signerId: Scalars['ID'];
  contactId: Scalars['ID'];
  text: Scalars['String'];
  unixTime: Scalars['UnixTime'];
};

export type CreateSignaturePayload = {
  signature: Signature;
};

export type VerifySignaturePayload = {
  isValid: Scalars['Boolean'];
};

export type ServiceHealth = {
  code: ServiceHealthCode;
  message?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ServiceHealthCode =
  | 'ok'
  | 'outOfService';

export type MutationValidationResult =
  | 'success'
  | 'incorrectOldValue'
  | 'intervalTooShort';

export type CreateResourceMutationVariables = Exact<{
  input: CreateResourceInput;
}>;


export type CreateResourceMutation = { createResource: { resource: (
      Pick<Resource, 'id' | 'createdAt'>
      & { issuer: Pick<Issuer, 'businessId' | 'contactId'>, recipient?: Maybe<Pick<Recipient, 'businessId' | 'contactId'>> }
    ) } };

export type CreateResourceAndSignatureMutationVariables = Exact<{
  input: CreateResourceAndSignatureInput;
}>;


export type CreateResourceAndSignatureMutation = { createResourceAndSignature: { resource: (
      Pick<Resource, 'id' | 'createdAt'>
      & { issuer: Pick<Issuer, 'businessId' | 'contactId'>, recipient?: Maybe<Pick<Recipient, 'businessId' | 'contactId'>> }
    ), signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> } };

export type CreateSignatureMutationVariables = Exact<{
  input: CreateSignatureInput;
}>;


export type CreateSignatureMutation = { createSignature: { signature: Pick<Signature, 'resourceId' | 'id' | 'signerId' | 'contactId' | 'text' | 'unixTime'> } };

export type GetResourceQueryVariables = Exact<{
  input: GetResourceInput;
}>;


export type GetResourceQuery = { getResource: { resource: (
      Pick<Resource, 'id' | 'createdAt'>
      & { issuer: Pick<Issuer, 'businessId' | 'contactId'>, recipient?: Maybe<Pick<Recipient, 'businessId' | 'contactId'>> }
    ) } };

export type ServiceHealthQueryVariables = Exact<{ [key: string]: never; }>;


export type ServiceHealthQuery = { serviceHealth: Pick<ServiceHealth, 'code' | 'message' | 'url'> };

export type VerifySignatureQueryVariables = Exact<{
  input: VerifySignatureInput;
}>;


export type VerifySignatureQuery = { verifySignature: Pick<VerifySignaturePayload, 'isValid'> };


export const CreateResourceDocument = gql`
    mutation createResource($input: CreateResourceInput!) {
  createResource(input: $input) {
    resource {
      id
      createdAt
      issuer {
        businessId
        contactId
      }
      recipient {
        businessId
        contactId
      }
    }
  }
}
    `;
export const CreateResourceAndSignatureDocument = gql`
    mutation createResourceAndSignature($input: CreateResourceAndSignatureInput!) {
  createResourceAndSignature(input: $input) {
    resource {
      id
      createdAt
      issuer {
        businessId
        contactId
      }
      recipient {
        businessId
        contactId
      }
    }
    signature {
      resourceId
      id
      signerId
      contactId
      text
      unixTime
    }
  }
}
    `;
export const CreateSignatureDocument = gql`
    mutation createSignature($input: CreateSignatureInput!) {
  createSignature(input: $input) {
    signature {
      resourceId
      id
      signerId
      contactId
      text
      unixTime
    }
  }
}
    `;
export const GetResourceDocument = gql`
    query getResource($input: GetResourceInput!) {
  getResource(input: $input) {
    resource {
      id
      createdAt
      issuer {
        businessId
        contactId
      }
      recipient {
        businessId
        contactId
      }
    }
  }
}
    `;
export const ServiceHealthDocument = gql`
    query serviceHealth {
  serviceHealth {
    code
    message
    url
  }
}
    `;
export const VerifySignatureDocument = gql`
    query verifySignature($input: VerifySignatureInput!) {
  verifySignature(input: $input) {
    isValid
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    createResource(variables: CreateResourceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateResourceMutation> {
      return withWrapper(() => client.request<CreateResourceMutation>(CreateResourceDocument, variables, requestHeaders));
    },
    createResourceAndSignature(variables: CreateResourceAndSignatureMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateResourceAndSignatureMutation> {
      return withWrapper(() => client.request<CreateResourceAndSignatureMutation>(CreateResourceAndSignatureDocument, variables, requestHeaders));
    },
    createSignature(variables: CreateSignatureMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateSignatureMutation> {
      return withWrapper(() => client.request<CreateSignatureMutation>(CreateSignatureDocument, variables, requestHeaders));
    },
    getResource(variables: GetResourceQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetResourceQuery> {
      return withWrapper(() => client.request<GetResourceQuery>(GetResourceDocument, variables, requestHeaders));
    },
    serviceHealth(variables?: ServiceHealthQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ServiceHealthQuery> {
      return withWrapper(() => client.request<ServiceHealthQuery>(ServiceHealthDocument, variables, requestHeaders));
    },
    verifySignature(variables: VerifySignatureQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VerifySignatureQuery> {
      return withWrapper(() => client.request<VerifySignatureQuery>(VerifySignatureDocument, variables, requestHeaders));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;