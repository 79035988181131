import { FC } from 'react';

import { getCurrencyText } from '@/utils/utilFunctions';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    value: {
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 600,
    },
}));

export const TaxViewer: FC<{
    taxValue: number;
}> = ({ taxValue }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={c.root}>
            <span className={c.value}>{getCurrencyText(taxValue)}</span>
        </div>
    );
};
TaxViewer.displayName = 'TaxViewer';
