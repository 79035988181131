import { useState, useEffect, useCallback } from 'react';

import { ClientData, ClientDataAPIForOwner, UodkaCoreClientForOwner } from '@/utils/UodkaClients/Core';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';

export const useClientData = () => {
    // HOOKS
    const {
        clients: { core },
    } = useBusinessCtxAbsolutely();
    const [clientData, setClientData] = useState<ClientData | undefined>(undefined);
    const fetchClientData = useCallback(async () => {
        const data = await core.getData();
        setClientData(data);
    }, [core]);

    // USEEFFECT
    useEffect(() => {
        fetchClientData();
    }, [fetchClientData]);

    return {
        clientData,
        refetchClientData: fetchClientData,
    };
};

export const useModifiableClientData = () => {
    // HOOKS
    const {
        clients: { core: coreClient },
    } = useBusinessCtxAbsolutely();
    const [wrapper, setWrapper] = useState<{
        clientData?: ClientData | ClientDataAPIForOwner;
    }>({});

    // USEEFFECT
    useEffect(() => {
        if (coreClient instanceof UodkaCoreClientForOwner) {
            coreClient.getClientDataAPI().then((clientData) => setWrapper({ clientData }));
        } else {
            coreClient.getData().then((clientData) => setWrapper({ clientData }));
        }
    }, [coreClient]);

    return {
        clientData: wrapper.clientData,
        setClientData: (clientData: ClientData | ClientDataAPIForOwner) => setWrapper({ clientData }),
    };
};
