import { FC, useState } from 'react';

import { getValidRemitterName, getKana } from '@/utils/UodkaClients/Core';
import { useUserCtx } from '@/utils/ctxs';
import { useLanguage, useRouting } from '@/utils/customHooks';
import { MutationFormModal } from '@/components/1_mol/FormModal';
import { TextInput } from '@/components/0_atom/Input';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {},
    remitterNameKanaDescription: {
        marginTop: 11,
    },
    remitterNameKanaDescriptionLabel: {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 600,
        display: 'block',
        marginBottom: 3,
        color: theme.palette.text.disabled,
    },
    remitterNameKanaDescriptionValue: {
        display: 'block',
        lineHeight: '1.5',
        fontWeight: 600,
    },
}));

const AddBusinessModal: FC<{
    isOpen: boolean;
    onClose: () => void;
}> = ({ isOpen, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const { userClient, reInitialize: reInitializeUserClient } = useUserCtx();
    const { switchBusinessId } = useRouting();
    const [name, setName] = useState<string | undefined>(undefined);
    const [remitterNameKana, setRemitterNameKana] = useState<string | undefined>(undefined);

    // DATA
    const { isEntered, isValid, halfWidthKatakana } = (() => {
        if (remitterNameKana) {
            const { isValid, halfWidthKatakana } = getValidRemitterName(remitterNameKana);
            return {
                isEntered: true,
                isValid,
                halfWidthKatakana,
            };
        }
        return {
            isEntered: false,
            isValid: false,
            halfWidthKatakana: undefined,
        };
    })();

    const remitterNameKanaMessages = [
        {
            isError: isEntered && !isValid,
            message: txt({
                en:
                    'Remitter name of account should be within 19 characters in Hiragana, "（" or "）" (counting turbidity marks and half turbidity marks as one character)',
                ja:
                    '口座名義は、カナと全角括弧のいずれかで、19文字以内（濁点・半濁点を１文字としてカウント）の必要があります。',
            }),
        },
    ];

    // HANDLER
    const initializeForm = () => {
        setName(undefined);
        setRemitterNameKana(undefined);
    };
    const run = async () => {
        if (userClient && name && isValid && halfWidthKatakana) {
            const { result, memberInUser } = await userClient
                .addBusiness({ name, remitterNameKana: halfWidthKatakana })
                .catch((e) => {
                    console.error('addBusiness e-->', e);
                    throw new Error(e);
                });
            if (result === 'success') {
                reInitializeUserClient();
                switchBusinessId({ businessId: memberInUser.business.id });
            }
            return result;
        }
        throw new Error('AddBusinessModal.run: !(userClient && name && isValid && halfWidthKatakana)');
    };

    return (
        <MutationFormModal
            {...{ isOpen, onClose }}
            title={txt({ en: 'Create New Business', ja: '新しいビジネスを作成' })}
            submitText={txt({ en: 'Create', ja: '作成' })}
            mutation={{
                run,
                description: txt({ en: `Create new business '${name}'`, ja: `新しいビジネス「${name}」を作成` }),
            }}
            initializeForm={initializeForm}
            items={[
                {
                    isRequired: true,
                    isSatisfied: !!name,
                    labelText: txt({ en: 'Name', ja: '名称' }),
                    children: <TextInput value={name} setValue={setName} fullWidth isSatisfied={!!name} />,
                },
                {
                    isRequired: true,
                    isSatisfied:
                        !!remitterNameKana && !remitterNameKanaMessages.find(({ isError }) => isError)?.isError,
                    labelText: txt({ en: 'Remitter name of account (Katakana)', ja: '口座名義（カナ）' }),
                    isError: remitterNameKanaMessages.find(({ isError }) => isError)?.isError,
                    children: (
                        <>
                            <TextInput
                                value={remitterNameKana}
                                setValue={setRemitterNameKana}
                                messages={remitterNameKanaMessages}
                                fullWidth
                                error={remitterNameKanaMessages.find(({ isError }) => isError)?.isError}
                                isSatisfied={
                                    !!remitterNameKana &&
                                    !remitterNameKanaMessages.find(({ isError }) => isError)?.isError
                                }
                            />
                            <div className={c.remitterNameKanaDescription}>
                                <span className={c.remitterNameKanaDescriptionLabel}>
                                    {txt({
                                        en: 'Remitter name of account',
                                        ja: '振り込み時の名義人名',
                                    })}
                                </span>
                                <span className={c.remitterNameKanaDescriptionValue}>
                                    {remitterNameKana ? getKana(remitterNameKana)?.fullWidthKatakana || '-' : '-'}
                                </span>
                            </div>
                            <div className={c.remitterNameKanaDescription}>
                                <span className={c.remitterNameKanaDescriptionLabel}>
                                    {txt({
                                        en: "Deposit account name (Can't change it later)",
                                        ja: '振込入金口座の名義人名 (後で変更できません)',
                                    })}
                                </span>
                                <span className={c.remitterNameKanaDescriptionValue}>
                                    {`${
                                        remitterNameKana ? getKana(remitterNameKana)?.fullWidthKatakana || '-' : '-'
                                    } ／ カ）ノーシフト`}
                                </span>
                            </div>
                            {/* <MuiTypography variant={'body2'} className={c.remitterNameKanaDescription}>
                                <span className={c.remitterNameKanaDescriptionLabel}>
                                    {txt({
                                        en: 'Remitter name of account :',
                                        ja: '振り込み時の名義人名 :',
                                    })}
                                </span>
                                <span className={c.remitterNameKanaDescriptionValue}>
                                    {remitterNameKana ? getKana(remitterNameKana)?.fullWidthKatakana || '' : ''}
                                </span>
                            </MuiTypography>
                            <MuiTypography variant={'body2'} className={c.remitterNameKanaDescription}>
                                <span className={c.remitterNameKanaDescriptionLabel}>
                                    {txt({
                                        en: 'Deposit account name :',
                                        ja: '振込入金口座の名義人名 :',
                                    })}
                                </span>
                                <span className={c.remitterNameKanaDescriptionValue}>
                                    {`${
                                        remitterNameKana ? getKana(remitterNameKana)?.fullWidthKatakana || '' : ''
                                    } （ニュウキンコウザ／（カ）ノーシフト）`}
                                    {` (${txt({
                                        en: "Can't change it later.",
                                        ja: '後で変更できません :',
                                    })})`}
                                </span>
                            </MuiTypography> */}
                        </>
                    ),
                },
            ]}
        />
    );
};
AddBusinessModal.displayName = 'AddBusinessModal';
export default AddBusinessModal;
