import { FC } from 'react';

import { ReceivedBillAPIForGeneral, IssuedBillAPIForGeneral } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import ConfirmationModal from '@/components/1_mol/ConfirmationModal';

export const RevokeModal: FC<{
    billAPI: ReceivedBillAPIForGeneral | IssuedBillAPIForGeneral;
    onRevoked: () => void;
    isOpen: boolean;
    onClose: () => void;
}> = ({ billAPI, onRevoked, isOpen, onClose }) => {
    // HOOKS
    const { txt } = useLanguage();
    const { currentBusiness } = useBusinessCtxAbsolutely();

    // DATA
    const description = (() => {
        const isIssuer = currentBusiness.id === billAPI.signatures.issuer.issue.signature.signerId;
        if (isIssuer) {
            return txt({
                en: `Revoke bill to ${billAPI.billData.recipient.name}`,
                ja: `${billAPI.billData.recipient.name}への請求を破棄`,
            });
        }
        return txt({
            en: `Revoke bill from ${billAPI.billData.issuer.name}`,
            ja: `${billAPI.billData.issuer.name}からの請求を破棄`,
        });
    })();

    // MUTATION
    const runRevoke = async () => {
        const { result } = await billAPI.addRevokingSignature();
        onRevoked();
        return result;
    };

    return (
        <ConfirmationModal
            isOpen={isOpen}
            onClose={onClose}
            isWarning
            title={txt({ en: 'Revoke bill', ja: '請求を破棄' })}
            mutation={{
                run: runRevoke,
                description,
            }}
            submitText={txt({ en: 'Revoke', ja: '破棄' })}
        >
            {txt({
                en: 'Are you sure to revoke this bill? This action cannot be undone.',
                ja: 'この請求を破棄しますか？この操作は元に戻せません。',
            })}
        </ConfirmationModal>
    );
};
RevokeModal.displayName = 'RevokeModal';
