import { FC, useState } from 'react';

import { InviteForMemberAPIForOwner } from '@/utils/UodkaClients/Core';
import { useLanguage } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import ConfirmationModal from '@/components/1_mol/ConfirmationModal';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({}));

export const DeleteInviteButton: FC<{
    inviteAPI: InviteForMemberAPIForOwner;
    removeInviteItem: (id: string) => void;
    // runRemove: () => Promise<MutationValidationResult>;
}> = ({ inviteAPI, removeInviteItem }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [isOpen, setIsOpen] = useState(false);

    // MUTATION
    const run = async () => {
        const result = await inviteAPI.remove();
        removeInviteItem(inviteAPI.codeText);
        return result;
    };

    return (
        <>
            <Button color={'error'} onClick={() => setIsOpen(true)}>
                {txt({
                    en: 'Delete',
                    ja: '削除',
                })}
            </Button>
            <ConfirmationModal
                isWarning
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={txt({
                    en: 'Delete an invite',
                    ja: '招待URLを削除',
                })}
                mutation={{
                    run,
                    description: txt({
                        en: 'Delete an invite for member',
                        ja: 'メンバー招待URLを削除',
                    }),
                }}
                submitText={txt({ en: 'Delete', ja: '削除' })}
            >
                <MuiTypography>
                    {txt({
                        en: 'Deleted invite URL cannot be restored. Are you sure you want to continue?',
                        ja: '削除した招待URLは元に戻せません。本当に削除しますか？',
                    })}
                </MuiTypography>
            </ConfirmationModal>
        </>
    );
};
DeleteInviteButton.displayName = 'views/config/business/Member/DeleteInviteButton';
