import { BankAccountInTransfer } from '@/utils/UodkaClients/Core';
import { Payee as GqlPayee } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';

interface PayeeInterface {
    uodkaBankAccount: BankAccountInTransfer;
}

export class Payee implements PayeeInterface {
    protected _uodkaBankAccount: BankAccountInTransfer;

    get uodkaBankAccount() {
        return this._uodkaBankAccount;
    }

    constructor({ uodkaBankAccount }: PayeeInterface) {
        this._uodkaBankAccount = uodkaBankAccount;
    }
}

export const getPayeeFromGql = ({ uodkaBankAccount }: GqlPayee) => {
    return new Payee({
        uodkaBankAccount: new BankAccountInTransfer(uodkaBankAccount),
    });
};
