import { ReceivedBill } from '@/utils/UodkaClients/Invoices';

import { getBillData } from '@/views/records/getModelData/getBillData';
import { getTransferData } from '@/views/records/getModelData/getTransferData';
import { getPaymentData } from '@/views/records/getModelData/getPaymentData';
import { getReceiptData } from '@/views/records/getModelData/getReceiptData';
import { getBillSignaturesData } from '@/views/records/getModelData/getBillSignaturesData';

export const getReceivedBillData = ({ receivedBill }: { receivedBill: ReceivedBill }) => {
    const { billData, contact, createdAt, id, payment, receipt, signatures } = receivedBill;
    return {
        billData: getBillData({ billData }),
        contact: (() => {
            const { additionalId, id, isArchived, name, relatedBusiness } = contact;
            return {
                additionalId,
                id,
                isArchived,
                name,
                relatedBusiness: (() => {
                    if (relatedBusiness) {
                        const { id, isArchived, language, name } = relatedBusiness;
                        return { id, isArchived, language, name };
                    }
                    return undefined;
                })(),
            };
        })(),
        createdAt,
        id,
        payment: (() => {
            if (payment) {
                const { createdAt, id, paymentData, transfer } = payment;
                return {
                    createdAt,
                    id,
                    paymentData: getPaymentData({ paymentData }),
                    transfer: getTransferData({ transfer }),
                };
            }
            return undefined;
        })(),
        receipt: (() => {
            if (receipt) {
                const { createdAt, id, receiptData, transfer } = receipt;
                return {
                    createdAt,
                    id,
                    receiptData: getReceiptData({ receiptData }),
                    transfer: getTransferData({ transfer }),
                };
            }
            return undefined;
        })(),
        signatures: getBillSignaturesData({ billSignatures: signatures }),
    };
};
