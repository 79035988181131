import { FC, useState, RefObject } from 'react';

import { ClientData, BankAccountInTransfer } from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import {
    BillData,
    UodkaInvoicesClientForGeneral,
    UodkaInvoicesClientForOwner,
    getGqlBillData,
    Payee,
} from '@/utils/UodkaClients/Invoices';
import { useLanguage, ContactItem } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import MutationButton from '@/components/0_atom/MutationButton';
import FetchingText from '@/components/0_atom/FetchingText';

import { useNewBillFields } from '@/views/bills/AddBillDrawer/useNewBillFields';
import { BillEditor } from '@/views/bills/AddBillDrawer/BillEditor';
import { SlidePages } from '@/views/bills/AddBillDrawer/SlidePages';
import { BillViewer } from '@/views/bills/BillViewer';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiStepper from '@material-ui/core/Stepper';
import MuiStep from '@material-ui/core/Step';
import MuiStepLabel from '@material-ui/core/StepLabel';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: 56,
    },
    stepper: {
        padding: 0,
        marginTop: 31,
        marginBottom: 7,
        display: 'inline-flex',
        background: 'none',
        '& .MuiStep-root': {
            padding: 0,
            '& .MuiTypography-root': {
                fontWeight: 600,
            },
            '& .MuiStepIcon-root.MuiStepIcon-completed, .MuiStepIcon-root.MuiStepIcon-active': {
                color: '#555',
            },
        },
        '& .MuiStepConnector-root': {
            width: 13,
            margin: '0 13px',
            '& .MuiStepConnector-line': {
                borderTopWidth: 2,
            },
        },
        '& .MuiStepConnector-root.Mui-disabled .MuiStepConnector-line': {
            borderTopColor: 'rgba(0,0,0,.1)',
        },
        '& .MuiStepConnector-root.MuiStepConnector-active .MuiStepConnector-line': {
            borderTopColor: 'rgba(0,0,0,.55)',
        },
        '& .MuiStepLabel-iconContainer': {
            '& circle': {
                r: 8,
            },
            '& text': {
                fontWeight: 600,
            },
            paddingRight: 0,
        },
        '& .MuiStep-completed .MuiStepIcon-root': {
            width: 16,
            height: 16,
            marginRight: 4,
            marginLeft: 4,
        },
    },
    buttons: {
        position: 'fixed',
        bottom: 26,
        right: 31,
        '& .MuiButtonBase-root': {
            minWidth: 116,
        },
    },
    toPreviewButton: {
        fontSize: theme.typography.body1.fontSize,
        boxShadow: '0 16px 16px 0 rgba(0,0,0,.1)',
        width: 140,
        height: 40,
        background: '#404040',
        '&:hover': {
            background: '#404040',
        },
    },
    backButton: {
        marginRight: 10,
        fontWeight: 600,
        fontSize: theme.typography.body1.fontSize,
        boxShadow: '0 16px 16px 0 rgba(0,0,0,.1)',
        background: '#fff',
        border: 'solid 2px rgba(0,0,0,.03)',
        width: 140,
        height: 40,
        '&:hover': {
            background: '#f4f4f4',
        },
    },
    submmitButton: {
        fontSize: theme.typography.body1.fontSize,
        boxShadow: '0 16px 16px 0 rgba(0,0,0,.1)',
        width: 140,
        height: 40,
        background: '#404040',
        '&:hover': {
            background: '#404040',
        },
    },
    previewHeading: {
        fontWeight: 600,
        fontSize: theme.typography.body1.fontSize,
        margin: '23px 0',
    },
    fetchingBox: {
        background: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 700,
        width: 740,
    },
}));

export const AddBillDrawerContent: FC<{
    invoicesClient: UodkaInvoicesClientForGeneral | UodkaInvoicesClientForOwner;
    refetchIssuedBills: () => Promise<void>;
    onClose: () => void;
    contactItems: ContactItem[];
    initialContactItem: ContactItem | undefined;
    clientData: ClientData;
    clientProfile: BusinessProfile | undefined;
    drawerPaperRef: RefObject<HTMLDivElement>;
}> = ({
    invoicesClient,
    refetchIssuedBills,
    onClose,
    contactItems,
    initialContactItem,
    clientData,
    clientProfile,
    drawerPaperRef,
}) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [currentStep, setCurrentStep] = useState<1 | 2>(1);
    const {
        selectedContactItem,
        setSelectedContactItem,
        bankAccount,
        setBankAccount,
        isBankAccountManual,
        setIsBankAccountManual,
        customizedId,
        setCustomizedId,
        title,
        setTitle,
        issuer,
        setIssuer,
        recipient,
        setRecipient,
        items,
        setItems,
        note,
        setNote,
        issueDate,
        setIssueDate,
        paymentDueDate,
        setPaymentDueDate,
        decimalRoundRule,
        setDecimalRoundRule,
        summary,
    } = useNewBillFields({ clientData, clientProfile, initialContactItem });

    // DATA
    const validBillData = (() => {
        if (bankAccount && issuer.name && recipient.name && items.length > 0) {
            return new BillData({
                customizedId,
                title,
                issuer,
                recipient,
                items,
                note,
                issueDate,
                paymentDueDate,
                summary,
                payee: new Payee({
                    uodkaBankAccount: new BankAccountInTransfer(bankAccount),
                }),
                currencyCode: 'jpy',
            });
        }
        return undefined;
    })();

    // MUTATION
    const handleRun = async () => {
        if (selectedContactItem && validBillData) {
            await invoicesClient.createBill({
                contactId: selectedContactItem.contact.id,
                billData: validBillData,
            });
            await refetchIssuedBills();
            onClose();
            return 'success' as const;
        }
        throw new Error('AddBillDrawerContent.handleRun: Unexpectedly !(selectedContactItem && validBillData).');
    };

    return (
        <div className={c.root}>
            <MuiStepper activeStep={currentStep - 1} className={c.stepper}>
                <MuiStep>
                    <MuiStepLabel>{txt({ en: 'Edit Bill', ja: '請求を編集' })}</MuiStepLabel>
                </MuiStep>
                <MuiStep>
                    <MuiStepLabel>{txt({ en: 'Preview', ja: 'プレビュー' })}</MuiStepLabel>
                </MuiStep>
            </MuiStepper>
            <SlidePages
                scrollBoxRef={drawerPaperRef}
                content1={
                    <BillEditor
                        clientData={clientData}
                        clientProfile={clientProfile}
                        contactItems={contactItems}
                        selectedContactItem={selectedContactItem}
                        setSelectedContactItem={setSelectedContactItem}
                        bankAccount={bankAccount}
                        setBankAccount={setBankAccount}
                        isBankAccountManual={isBankAccountManual}
                        setIsBankAccountManual={setIsBankAccountManual}
                        {...{
                            customizedId,
                            setCustomizedId,
                            title,
                            setTitle,
                            issuer,
                            setIssuer,
                            recipient,
                            setRecipient,
                            items,
                            setItems,
                            note,
                            setNote,
                            issueDate,
                            setIssueDate,
                            paymentDueDate,
                            setPaymentDueDate,
                            decimalRoundRule,
                            setDecimalRoundRule,
                            summary,
                        }}
                    />
                }
                content2={
                    <div>
                        <MuiTypography className={c.previewHeading}>
                            {txt({ en: 'Issue a bill as below?', ja: 'この内容で発行しますか？' })}
                        </MuiTypography>
                        {currentStep === 2 && selectedContactItem && validBillData ? (
                            <BillViewer
                                issuer={{
                                    name: clientData.name,
                                    profile: clientProfile,
                                }}
                                recipient={{
                                    name: selectedContactItem.contact.name,
                                    profile: selectedContactItem.profile?.data,
                                }}
                                billData={validBillData}
                            />
                        ) : (
                            <div className={c.fetchingBox}>
                                <FetchingText />
                            </div>
                        )}
                    </div>
                }
                currentNumber={currentStep}
            />
            <div className={c.buttons}>
                {currentStep === 1 && (
                    <Button
                        onClick={() => setCurrentStep(2)}
                        role={'submit'}
                        disabled={!(selectedContactItem && validBillData)}
                        className={c.toPreviewButton}
                    >
                        {txt({ en: 'Preview', ja: 'プレビューへ' })}
                    </Button>
                )}
                {currentStep === 2 && (
                    <>
                        <Button onClick={() => setCurrentStep(1)} className={c.backButton}>
                            {txt({ en: 'Back to edit', ja: '編集に戻る' })}
                        </Button>
                        <MutationButton
                            className={c.submmitButton}
                            mutation={{
                                description: txt({
                                    en: `Issue bill to ${selectedContactItem?.contact.name}`,
                                    ja: `${selectedContactItem?.contact.name}に請求を発行`,
                                }),
                                run: handleRun,
                            }}
                            role={'submit'}
                        >
                            {txt({ en: 'Issue', ja: '発行' })}
                        </MutationButton>
                    </>
                )}
            </div>
        </div>
    );
};
AddBillDrawerContent.displayName = 'AddBillDrawerContent';
