import { MutationValidationResult } from '@/utils/UodkaClients/Profile/graphql/generated/graphqlClient';
import { GraphQLSdk } from '@/utils/UodkaClients/Profile/graphql/graphqlSdk';
import {
    BusinessProfile,
    BusinessProfileInterface,
} from '@/utils/UodkaClients/Profile/models/BusinessProfile/data/BusinessProfile';
import {
    getExtention,
    removeBusinessLogo,
    getBusinessLogoSrc,
    removeBusinessIcon,
    getBusinessIconSrc,
} from '@/utils/GoogleCloud/storage';
import { getEnvVariables } from '@/utils/envVariables';

interface BusinessProfileAPIInterface {
    modifyThemeColor: (newThemeColor?: string) => Promise<MutationValidationResult>;
    modifyPhone: (newPhone?: string) => Promise<MutationValidationResult>;
    modifyEmail: (newEmail?: string) => Promise<MutationValidationResult>;
    modifyWebsiteUrl: (newWebsiteUrl?: string) => Promise<MutationValidationResult>;
    modifyAddress: (newAddress?: string) => Promise<MutationValidationResult>;
    updateLogo: (newLogoFile?: File) => Promise<MutationValidationResult>;
    updateIcon: (newLogoFile?: File) => Promise<MutationValidationResult>;
}

interface ConstructorInput extends BusinessProfileInterface {
    graphqlSdk: GraphQLSdk;
}

export class BusinessProfileAPIForOwner extends BusinessProfile implements BusinessProfileAPIInterface {
    protected _graphqlSdk: GraphQLSdk;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
    }

    async modifyThemeColor(newThemeColor?: string) {
        const { modifyBusinessProfileThemeColor } = await this._graphqlSdk.modifyBusinessProfileThemeColor({
            input: {
                businessId: this._businessId,
                oldValue: this._themeColor,
                newValue: newThemeColor,
                requiredInterval: 5,
            },
        });
        this._themeColor = modifyBusinessProfileThemeColor.businessProfile.themeColor || undefined;
        return modifyBusinessProfileThemeColor.result;
    }

    async modifyPhone(newPhone?: string) {
        const { modifyBusinessProfilePhone } = await this._graphqlSdk.modifyBusinessProfilePhone({
            input: {
                businessId: this._businessId,
                oldValue: this._phone,
                newValue: newPhone,
                requiredInterval: 5,
            },
        });
        this._phone = modifyBusinessProfilePhone.businessProfile.phone || undefined;
        return modifyBusinessProfilePhone.result;
    }

    async modifyEmail(newEmail?: string) {
        const { modifyBusinessProfileEmail } = await this._graphqlSdk.modifyBusinessProfileEmail({
            input: {
                businessId: this._businessId,
                oldValue: this._email,
                newValue: newEmail,
                requiredInterval: 5,
            },
        });
        this._email = modifyBusinessProfileEmail.businessProfile.email || undefined;
        return modifyBusinessProfileEmail.result;
    }

    async modifyWebsiteUrl(newWebsiteUrl?: string) {
        const { modifyBusinessProfileWebsiteUrl } = await this._graphqlSdk.modifyBusinessProfileWebsiteUrl({
            input: {
                businessId: this._businessId,
                oldValue: this._websiteUrl,
                newValue: newWebsiteUrl,
                requiredInterval: 5,
            },
        });
        this._websiteUrl = modifyBusinessProfileWebsiteUrl.businessProfile.websiteUrl || undefined;
        return modifyBusinessProfileWebsiteUrl.result;
    }

    async modifyAddress(newAddress?: string) {
        const { modifyBusinessProfileAddress } = await this._graphqlSdk.modifyBusinessProfileAddress({
            input: {
                businessId: this._businessId,
                oldValue: this._address,
                newValue: newAddress,
                requiredInterval: 5,
            },
        });
        this._address = modifyBusinessProfileAddress.businessProfile.address || undefined;
        return modifyBusinessProfileAddress.result;
    }

    async updateLogo(newLogoFile?: File) {
        if (!newLogoFile) {
            removeBusinessLogo({ businessId: this._businessId });
            this._logoSrc = undefined;
            return 'success' as const;
        }
        const extentionAndContentType = getExtention(newLogoFile);
        if (!extentionAndContentType) {
            throw new Error('BusinessProfileAPIForOwner.updateLogo: invalid file extention');
        }
        const [extensionName, contentType] = extentionAndContentType;
        const { getSignedUrlToUploadBusinessLogo: uploadUrl } = await this._graphqlSdk.getSignedUrlToUploadBusinessLogo(
            {
                input: {
                    businessId: this._businessId,
                    extensionName,
                },
            }
        );
        await fetch(uploadUrl, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': contentType,
                origin: getEnvVariables().rootUrl,
            }),
            body: newLogoFile,
        }).catch(() => {
            throw new Error('BusinessProfileAPIForOwner.updateLogo: upload failed');
        });
        const newLogoSrc = await getBusinessLogoSrc({ businessId: this._businessId });
        this._logoSrc = newLogoSrc;
        return 'success' as const;
    }

    async updateIcon(newIconFile?: File) {
        if (!newIconFile) {
            removeBusinessIcon({ businessId: this._businessId });
            this._iconSrc = undefined;
            return 'success' as const;
        }
        const extentionAndContentType = getExtention(newIconFile);
        if (!extentionAndContentType) {
            throw new Error('BusinessProfileAPIForOwner.updateIcon: invalid file extention');
        }
        const [extensionName, contentType] = extentionAndContentType;
        const { getSignedUrlToUploadBusinessIcon: uploadUrl } = await this._graphqlSdk.getSignedUrlToUploadBusinessIcon(
            {
                input: {
                    businessId: this._businessId,
                    extensionName,
                },
            }
        );
        await fetch(uploadUrl, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': contentType,
                origin: getEnvVariables().rootUrl,
            }),
            body: newIconFile,
        }).catch(() => {
            throw new Error('BusinessProfileAPIForOwner.updateIcon: upload failed');
        });
        const newIconSrc = await getBusinessIconSrc({ businessId: this._businessId });
        this._iconSrc = newIconSrc;
        return 'success' as const;
    }
}
