import { FC, ReactNode } from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import MuiPopover from '@material-ui/core/Popover';
import { PopoverOrigin, PopoverPosition } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({}));

const Popover: FC<{
    className?: string;
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    anchorEl?: Element;
    anchorOrigin?: PopoverOrigin;
    anchorPosition?: PopoverPosition;
}> = ({ className, isOpen, onClose, children, anchorEl, anchorOrigin, anchorPosition }) => {
    const c = useStyles(useTheme());

    return (
        <MuiPopover
            open={isOpen}
            anchorEl={anchorEl}
            anchorOrigin={anchorOrigin}
            anchorPosition={anchorPosition}
            onClose={onClose}
            classes={{ paper: className }}
            transitionDuration={{ enter: 500, exit: 400 }}
        >
            {children}
        </MuiPopover>
    );
};
Popover.displayName = 'Popover';
export default Popover;
