import { getFirebaseStorage } from '@/utils/GoogleCloud/firebase';

export const getBusinessLogoSrc = async ({ businessId }: { businessId: string }) => {
    const storageRef = getFirebaseStorage().ref();
    const fileRef = storageRef.child('BusinessLogo').child(businessId);
    return fileRef
        .getDownloadURL()
        .then((url: string) => {
            return url;
        })
        .catch((e) => {
            return undefined;
        });
};
export const getBusinessIconSrc = async ({ businessId }: { businessId: string }) => {
    const storageRef = getFirebaseStorage().ref();
    const fileRef = storageRef.child('BusinessIcon').child(businessId);
    return fileRef
        .getDownloadURL()
        .then((url: string) => {
            return url;
        })
        .catch((e) => {
            return undefined;
        });
};
export const removeBusinessLogo = ({ businessId }: { businessId: string }) => {
    // later: ない場合は?
    const storageRef = getFirebaseStorage().ref();
    const fileRef = storageRef.child('BusinessLogo').child(businessId);
    fileRef.delete();
};
export const removeBusinessIcon = ({ businessId }: { businessId: string }) => {
    // later: ない場合は?
    const storageRef = getFirebaseStorage().ref();
    const fileRef = storageRef.child('BusinessIcon').child(businessId);
    fileRef.delete();
};

type ImageExtensionAndContentType =
    | ['jpg', 'image/jpeg']
    | ['png', 'image/png']
    | ['webp', 'image/webp']
    | ['svg', 'image/svg+xml'];

export const getExtention = (file: File): ImageExtensionAndContentType | undefined => {
    const extensionName = file.name.replace(/^.*\./, '');
    if (extensionName === 'jpg' || extensionName === 'jpeg') {
        return ['jpg', 'image/jpeg'];
    }
    if (extensionName === 'png') {
        return ['png', 'image/png'];
    }
    if (extensionName === 'webp') {
        return ['webp', 'image/webp'];
    }
    if (extensionName === 'svg') {
        return ['svg', 'image/svg+xml'];
    }
    return undefined;
};
