import { FC } from 'react';

import FetchingText from '@/components/0_atom/FetchingText';
import TableHead, { TableHeadItem } from '@/components/0_atom/Table/TableHead';
import TableRow, { ExpandableTableRowProps } from '@/components/0_atom/Table/ExpandableTableRow';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    emptyPlaceholder: {
        padding: 21,
        fontWeight: 600,
        color: theme.palette.text.disabled,
        fontSize: theme.typography.body2.fontSize,
    },
}));

const ExpandableTable: FC<{
    headItems: TableHeadItem[];
    rowItems: ExpandableTableRowProps[] | undefined;
    className?: string;
    widths: (string | number)[];
    expandedWidths: (string | number)[];
    emptyPlaceholder: string;
}> = ({ headItems, rowItems, className, widths, expandedWidths, emptyPlaceholder }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={className}>
            <div>
                <TableHead items={headItems} widths={widths} />
                <div>
                    {rowItems ? (
                        rowItems.length === 0 ? (
                            <div className={c.emptyPlaceholder}>{emptyPlaceholder}</div>
                        ) : (
                            rowItems.map((props, i) => (
                                <TableRow key={i} {...props} widths={widths} expandedWidths={expandedWidths} />
                            ))
                        )
                    ) : (
                        <div className={c.emptyPlaceholder}>
                            <FetchingText />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

ExpandableTable.displayName = 'ExpandableTable';
export default ExpandableTable;
