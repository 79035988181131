import { FC, ReactNode, ReactElement } from 'react';
import clsx from 'clsx';

import { getStrWithBr } from '@/utils/utilFunctions';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    value: {
        fontWeight: 600,
    },
    editButton: {
        marginLeft: 16,
    },
}));

const ValueWithEditButton: FC<{
    value: ReactNode;
    editButton?: ReactElement;
    className?: string;
}> = ({ value, editButton, className }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={clsx(c.root, className)}>
            {typeof value === 'string' ? (
                <MuiTypography variant={'body2'} className={c.value}>
                    {getStrWithBr(value)}
                </MuiTypography>
            ) : (
                <div className={c.value}>{value}</div>
            )}
            {editButton && <div className={c.editButton}>{editButton}</div>}
        </div>
    );
};
ValueWithEditButton.displayName = 'ValueWithEditButton';
export default ValueWithEditButton;
