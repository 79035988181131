import { FC } from 'react';
import clsx from 'clsx';

import { ContactItem, useLanguage } from '@/utils/customHooks';
import { ButtonWithMenu } from '@/components/1_mol/ButtonWithMenu';
import ContactNameWithIcon from '@/components/2_org/ContactNameWithIcon';
import NoContactDisplay from '@/components/2_org/NoContactDisplay';

import SvgChevronUp from '@/svgs/fa-chevron-up-solid';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        border: 'solid 2px rgba(0,0,0,.05)',
        position: 'relative',
        background: '#fff',
        // padding: '21px 17px',
        paddingRight: 48,
        minWidth: 237,
        minHeight: 54,
        justifyContent: 'flex-start',
        '& .MuiButton-endIcon': {
            position: 'absolute',
            right: 14,
            width: 12,
            height: 'auto',
            transform: 'rotate(180deg)',
            color: theme.palette.text.disabled,
        },
        '& .MuiButton-label': {
            fontWeight: 600,
        },
    },
    menuPaper: {
        minWidth: 237,
    },
}));

const ContactSelector: FC<{
    className?: string;
    contactItems: ContactItem[];
    selectedContactItem?: ContactItem;
    setSelectedContactItem: (contactItem: ContactItem) => void;
}> = ({ className, contactItems, selectedContactItem, setSelectedContactItem }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    return (
        <ButtonWithMenu
            menuEmptyPlaceholder={<NoContactDisplay />}
            className={clsx(c.root, className)}
            items={contactItems.map((contactItem) => ({
                children: <ContactNameWithIcon contact={contactItem.contact} profile={contactItem.profile?.data} />,
                id: contactItem.id,
                onClick: () => setSelectedContactItem(contactItem),
                isDisabled: contactItem.id === selectedContactItem?.id,
            }))}
            endIcon={<SvgChevronUp />}
            menuPaperClassName={c.menuPaper}
        >
            {selectedContactItem ? (
                <ContactNameWithIcon
                    contact={selectedContactItem.contact}
                    profile={selectedContactItem.profile?.data}
                />
            ) : (
                txt({ en: 'Select Contact', ja: '選択してください' })
            )}
        </ButtonWithMenu>
    );
};
ContactSelector.displayName = 'ContactSelector';
export default ContactSelector;
