import { FC, ReactNode, useState, useEffect, useCallback } from 'react';

import {
    MutationsCtx,
    ProcessingMutation,
    AddMutationInput,
    ProcessingMutationStatus,
} from '@/utils/ctxs/MutationsCtx';

const useMutationSlot = (): {
    init: (newMutation: AddMutationInput) => void;
    mutation?: ProcessingMutation;
} => {
    // HOOKS
    const [status, setStatus] = useState<ProcessingMutationStatus>('canceled');
    const [description, setDescription] = useState<string>('');
    const [mutation, setMutation] = useState<AddMutationInput | undefined>(undefined);
    const run = useCallback(() => {
        if (mutation) {
            setStatus('fetching');
            mutation
                .run()
                .then((result) => {
                    setStatus(result);
                    mutation.onThen && mutation.onThen(result);
                })
                .catch(() => {
                    setStatus('fetchError');
                });
        }
    }, [mutation]);

    // HANDLER
    const init = (newMutation: AddMutationInput) => {
        setDescription(newMutation.description);
        setMutation(newMutation);
    };

    // USEEFFECT
    useEffect(() => {
        run();
    }, [run]);

    return {
        init,
        mutation: ['success', 'canceled'].includes(status)
            ? undefined
            : {
                  addedAt: new Date(),
                  status,
                  retry: run,
                  cancel: () => setStatus('canceled'),
                  description,
              },
    };
};

const MutationsProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // HOOKS
    const { init: initSlot1, mutation: slot1 } = useMutationSlot();
    const { init: initSlot2, mutation: slot2 } = useMutationSlot();
    const { init: initSlot3, mutation: slot3 } = useMutationSlot();
    const { init: initSlot4, mutation: slot4 } = useMutationSlot();

    // HANDLER
    const addMutation = (newMutation: AddMutationInput): 1 | 2 | 3 | 4 | never => {
        if (!slot1) {
            initSlot1(newMutation);
            return 1;
        }
        if (!slot2) {
            initSlot2(newMutation);
            return 2;
        }
        if (!slot3) {
            initSlot3(newMutation);
            return 3;
        }
        if (!slot4) {
            initSlot4(newMutation);
            return 4;
        }
        throw new Error('MutationsProvider.addMutation: slots occupied');
    };

    return (
        <MutationsCtx.Provider value={{ slot1, slot2, slot3, slot4, addMutation }}>{children}</MutationsCtx.Provider>
    );
};
MutationsProvider.displayName = 'MutationsProvider';
export default MutationsProvider;
