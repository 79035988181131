import { FC } from 'react';

import { BankAccountInTransferInterface, ClientData } from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import { DecimalRoundRule, BusinessSnapshot, InvoiceDataItem, InvoiceDataSummary } from '@/utils/UodkaClients/Invoices';
import { useLanguage, ContactItem } from '@/utils/customHooks';
import { TextInput } from '@/components/0_atom/Input';
import { DatePickerButton } from '@/components/0_atom/DatePickerButton';
import { TextSelector } from '@/components/1_mol/Selector';

import { BillDataEditorItem } from '@/views/bills/AddBillDrawer/BillEditor/BillDataEditor/BillDataEditorItem';
import { BusinessSnapshotEditor } from '@/views/bills/AddBillDrawer/BillEditor/BillDataEditor/BusinessSnapshotEditor';
import { BankAccountSelector } from '@/views/bills/AddBillDrawer/BankAccountSelector';
import { ItemsEditor } from '@/views/bills/AddBillDrawer/BillEditor/BillDataEditor/ItemsEditor';
import { TableFoot } from '@/views/bills/BillViewer/BillViewerDigital/BillDataViewer/TableFoot';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '28px 19px',
        borderRadius: 4,
        border: 'solid 2px rgba(0,0,0,.04)',
    },
    textInput: {
        width: 320,
        '& input': {
            fontSize: theme.typography.body2.fontSize,
            padding: 16,
        },
        '& textarea': {
            fontSize: theme.typography.body2.fontSize,
            padding: 16,
        },
    },
    datePickerButton: {
        background: '#fafafa',
        border: 'solid 2px rgba(0,0,0,.03)',
        padding: '10px 16px',
        fontWeight: 600,
    },
    textSelector: {
        padding: '16px 32px 16px 16px',
    },
    tableFootBox: {
        marginTop: 7,
    },
}));

export const BillDataEditor: FC<{
    clientData: ClientData;
    clientProfile: BusinessProfile | undefined;
    selectedContactItem: ContactItem;
    bankAccount: BankAccountInTransferInterface | undefined;
    setBankAccount: (bankAccount: BankAccountInTransferInterface | undefined) => void;
    isBankAccountManual: boolean;
    setIsBankAccountManual: (isBankAccountManual: boolean) => void;
    customizedId: string | undefined;
    setCustomizedId: (customizedId: string | undefined) => void;
    title: string | undefined;
    setTitle: (title: string | undefined) => void;
    issuer: BusinessSnapshot;
    setIssuer: (issuer: BusinessSnapshot) => void;
    recipient: BusinessSnapshot;
    setRecipient: (recipient: BusinessSnapshot) => void;
    items: InvoiceDataItem[];
    setItems: (items: InvoiceDataItem[]) => void;
    note: string | undefined;
    setNote: (note: string | undefined) => void;
    issueDate: Date | undefined;
    setIssueDate: (issueDate: Date | undefined) => void;
    paymentDueDate: Date | undefined;
    setPaymentDueDate: (paymentDueDate: Date | undefined) => void;
    decimalRoundRule: DecimalRoundRule;
    setDecimalRoundRule: (decimalRoundRule: DecimalRoundRule) => void;
    summary: InvoiceDataSummary;
}> = ({
    clientData,
    clientProfile,
    selectedContactItem,
    bankAccount,
    setBankAccount,
    isBankAccountManual,
    setIsBankAccountManual,
    customizedId,
    setCustomizedId,
    title,
    setTitle,
    issuer,
    setIssuer,
    recipient,
    setRecipient,
    items,
    setItems,
    note,
    setNote,
    issueDate,
    setIssueDate,
    paymentDueDate,
    setPaymentDueDate,
    decimalRoundRule,
    setDecimalRoundRule,
    summary,
}) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt, getDateStr } = useLanguage();

    return (
        <div className={c.root}>
            <BillDataEditorItem
                label={txt({ en: 'Bill to', ja: '請求先' })}
                isSatisfied={!!recipient.name}
                isRequired
                errors={[
                    {
                        isError: !recipient.name,
                        message: txt({ en: 'Recipient name is required', ja: '請求先の名称は必須です' }),
                    },
                ]}
            >
                <BusinessSnapshotEditor businessSnapshot={recipient} setBusinessSnapshot={setRecipient} />
            </BillDataEditorItem>
            <BillDataEditorItem
                label={txt({ en: 'Issuer', ja: '発行者' })}
                isSatisfied={!!issuer.name}
                isRequired
                errors={[
                    {
                        isError: !issuer.name,
                        message: txt({ en: 'Issuer name is required', ja: '発行者の名称は必須です' }),
                    },
                ]}
            >
                <BusinessSnapshotEditor businessSnapshot={issuer} setBusinessSnapshot={setIssuer} />
            </BillDataEditorItem>
            <BillDataEditorItem
                label={txt({ en: 'Payment to', ja: '支払い先' })}
                isSatisfied={!!bankAccount}
                isRequired
                errors={[
                    {
                        isError: !bankAccount,
                        message: txt({ en: 'Required field', ja: '支払い先は必須です' }),
                    },
                ]}
            >
                <BankAccountSelector
                    clientData={clientData}
                    clientProfile={clientProfile}
                    contactItem={selectedContactItem}
                    selectedBankAccount={bankAccount}
                    setSelectedBankAccount={setBankAccount}
                    isBankAccountManual={isBankAccountManual}
                    setIsBankAccountManual={setIsBankAccountManual}
                />
            </BillDataEditorItem>
            <BillDataEditorItem
                label={txt({ en: 'Items', ja: '項目' })}
                isSatisfied={items.length > 0}
                isRequired
                errors={[
                    {
                        isError: items.length === 0,
                        message: txt({ en: 'Required at least 1 item', ja: '１つ以上の項目が必要です ' }),
                    },
                ]}
            >
                <>
                    <ItemsEditor items={items} setItems={setItems} />
                    <div className={c.tableFootBox}>
                        <TableFoot summary={summary} />
                    </div>
                </>
            </BillDataEditorItem>
            <BillDataEditorItem
                label={txt({ en: 'Dealing with Decimals', ja: '小数点以下の処理' })}
                isSatisfied={true}
                isRequired={false}
                errors={[]}
            >
                <TextSelector
                    className={c.textSelector}
                    items={[
                        {
                            id: 'down',
                            text: txt({ en: 'Round down', ja: '切り捨て' }),
                        },
                        {
                            id: 'up',
                            text: txt({ en: 'Round up', ja: '切り上げ' }),
                        },
                        {
                            id: 'near',
                            text: txt({ en: 'Round', ja: '四捨五入' }),
                        },
                    ]}
                    onSelect={(newDecimalRoundRule) => setDecimalRoundRule(newDecimalRoundRule as DecimalRoundRule)}
                    selectedId={decimalRoundRule}
                    notSelectedText={undefined}
                    emptyPlaceholder={'unexpected'}
                />
            </BillDataEditorItem>
            <BillDataEditorItem
                label={txt({ en: 'Subject', ja: '件名' })}
                isSatisfied={true}
                isRequired={false}
                errors={[]}
            >
                <TextInput
                    className={c.textInput}
                    value={title}
                    setValue={setTitle}
                    placeholder={txt({ en: 'Subject', ja: '件名' })}
                />
            </BillDataEditorItem>
            <BillDataEditorItem
                label={txt({ en: 'Invoice #', ja: '請求書コード' })}
                isSatisfied={true}
                isRequired={false}
                errors={[]}
            >
                <TextInput
                    className={c.textInput}
                    value={customizedId}
                    setValue={setCustomizedId}
                    placeholder={txt({ en: 'Invoice #', ja: '請求書コード' })}
                />
            </BillDataEditorItem>
            <BillDataEditorItem
                label={txt({ en: 'Date of issue', ja: '発行日' })}
                isSatisfied={true}
                isRequired={false}
                errors={[]}
            >
                <DatePickerButton className={c.datePickerButton} date={issueDate} setDate={setIssueDate} isEnablePast>
                    {issueDate ? getDateStr(issueDate) : txt({ en: 'Add', ja: '追加' })}
                </DatePickerButton>
            </BillDataEditorItem>
            <BillDataEditorItem
                label={txt({ en: 'Payment due date', ja: '支払い期限' })}
                isSatisfied={true}
                isRequired={false}
                errors={[]}
            >
                <DatePickerButton
                    className={c.datePickerButton}
                    date={paymentDueDate}
                    setDate={setPaymentDueDate}
                    isEnablePast={false}
                >
                    {paymentDueDate ? getDateStr(paymentDueDate) : txt({ en: 'Add', ja: '追加' })}
                </DatePickerButton>
            </BillDataEditorItem>
            <BillDataEditorItem
                label={txt({ en: 'Note', ja: 'メッセージ' })}
                isSatisfied={true}
                isRequired={false}
                errors={[]}
            >
                <TextInput
                    className={c.textInput}
                    value={note}
                    setValue={setNote}
                    placeholder={txt({ en: 'Note', ja: 'メッセージ' })}
                    multiline
                />
            </BillDataEditorItem>
        </div>
    );
};
BillDataEditor.displayName = 'BillDataEditor';
