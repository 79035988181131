import { FC } from 'react';

import { ContactAPIForOwner, BankAccountInTransferInterface, ClientData } from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage, ContactItem } from '@/utils/customHooks';
import { getStrWithBr } from '@/utils/utilFunctions';
import BusinessIcon from '@/components/0_atom/BusinessIcon';
import HorizontalTable from '@/components/0_atom/HorizontalTable';
import ValueWithEditButton from '@/components/0_atom/ValueWithEditButton';
import NotSetText from '@/components/0_atom/NotSetText';
import {
    DepositAccountCardWithHolder,
    ExternalBankAccountCardWithHolder,
    PreparingBankAccountCardWithHolder,
} from '@/components/2_org/BankAccountCard';

import { DeleteContactButton } from '@/views/contacts/DeleteContactButton';
import { InviteButton, getIsInvited, getIsLinked, getIsNotInvited } from '@/views/contacts/InviteButton';
import { ModifyContactNameButton } from '@/views/contacts/ModifyContactNameButton';
import { ModifyContactNotificationEmailButton } from '@/views/contacts/ModifyContactNotificationEmailButton';
import { ModifyContactBankAccountButton } from '@/views/contacts/ModifyContactBankAccountButton';

import SvgLinked from '@/svgs/linked';
import SvgUnLink from '@/svgs/unlink';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {},
    header: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    contactBrand: {
        display: 'flex',
        alignItems: 'center',
    },
    contactIcon: {
        width: 38,
        height: 38,
        marginRight: 10,
    },
    actions: {
        '&>.MuiButtonBase-root': {
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
        },
    },
    linked: {
        display: 'flex',
        alignItems: 'center',
        lineHeight: '1',
        padding: '6px 12px',
        height: 33,
        color: theme.palette.text.disabled,
        '& svg': {
            width: 14,
            height: 14,
            marginRight: 8,
        },
    },
    content: {
        marginTop: 49,
    },
    bankAccountCard: {
        border: 'solid 2px rgba(0,0,0,.03)',
        padding: 14,
        borderRadius: 4,
        width: 300,
    },
}));

export const ContactDetails: FC<
    ContactItem & {
        updateItem: (item: ContactItem) => void;
        removeContactItem: (id: string) => void;
        clientProfile: BusinessProfile | undefined;
        clientData: ClientData;
    }
> = ({ contact, profile: contactProfile, clientProfile, clientData, updateItem, removeContactItem }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const { currentBusiness } = useBusinessCtxAbsolutely();

    const bankAccountFromContact: BankAccountInTransferInterface | undefined = (() => {
        if (contact.relatedBusiness) {
            if (contact.relatedBusiness.externalBankAccount) {
                return {
                    isDepositAccount: false,
                    ...contact.relatedBusiness.externalBankAccount.data,
                };
            }
            if (contact.relatedBusiness.depositAccountFromMeToContact) {
                return {
                    isDepositAccount: true,
                    ...contact.relatedBusiness.depositAccountFromMeToContact.data,
                };
            }
            return undefined;
        }
        if (contact.externalBankAccount) {
            return {
                isDepositAccount: false,
                ...contact.externalBankAccount.data,
            };
        }
        return undefined;
    })();
    const bankAccountToContact: BankAccountInTransferInterface | undefined = (() => {
        if (clientData.externalBankAccount) {
            return {
                isDepositAccount: false,
                ...clientData.externalBankAccount.data,
            };
        }
        if (contact.depositAccountFromContactToMe) {
            return {
                isDepositAccount: true,
                ...contact.depositAccountFromContactToMe.data,
            };
        }
        return undefined;
    })();

    return (
        <div className={c.root}>
            <div className={c.header}>
                <div className={c.contactBrand}>
                    <div className={c.contactIcon}>
                        <BusinessIcon
                            name={contact.name}
                            iconSrc={contactProfile?.data?.iconSrc}
                            themeColor={contactProfile?.data?.themeColor}
                        />
                    </div>
                    <MuiTypography variant={'h1'}>{contact.name}</MuiTypography>
                </div>
                <div className={c.actions}>
                    {contact.relatedBusiness ? (
                        <div className={c.linked}>
                            <SvgLinked />
                            {txt({ en: 'Linked', ja: 'リンク済み' })}
                        </div>
                    ) : contact instanceof ContactAPIForOwner && (getIsInvited(contact) || getIsNotInvited(contact)) ? (
                        <InviteButton
                            contactAPI={contact}
                            updateItem={(contact) =>
                                updateItem({
                                    id: contact.id,
                                    contact,
                                    profile: contactProfile,
                                })
                            }
                        />
                    ) : (
                        <div className={c.linked}>
                            <SvgUnLink />
                            {txt({ en: 'Not linked', ja: '未リンク' })}
                        </div>
                    )}
                    {!contact.relatedBusiness && contact instanceof ContactAPIForOwner && (
                        <DeleteContactButton contactAPI={contact} removeContactItem={removeContactItem} />
                    )}
                </div>
            </div>
            <div className={c.content}>
                <HorizontalTable
                    items={[
                        {
                            label: txt({ en: "This contact's Deposit Account", ja: 'このコンタクトの入金口座' }),
                            children: (
                                <ValueWithEditButton
                                    value={
                                        <>
                                            {bankAccountFromContact?.isDepositAccount ? (
                                                <DepositAccountCardWithHolder
                                                    className={c.bankAccountCard}
                                                    depositAccount={bankAccountFromContact}
                                                    holder={{
                                                        name: contact.relatedBusiness?.name || contact.name,
                                                        profile: contactProfile?.data,
                                                    }}
                                                    remitter={{
                                                        name: currentBusiness.name,
                                                        profile: clientProfile,
                                                    }}
                                                />
                                            ) : bankAccountFromContact ? (
                                                <ExternalBankAccountCardWithHolder
                                                    className={c.bankAccountCard}
                                                    bankAccount={bankAccountFromContact}
                                                    holder={{
                                                        name: contact.relatedBusiness?.name || contact.name,
                                                        profile: contactProfile?.data,
                                                    }}
                                                />
                                            ) : contact.relatedBusiness ? (
                                                <PreparingBankAccountCardWithHolder
                                                    className={c.bankAccountCard}
                                                    holder={{
                                                        name: contact.relatedBusiness?.name || contact.name,
                                                        profile: contactProfile?.data,
                                                    }}
                                                    remitter={{
                                                        name: currentBusiness.name,
                                                        profile: clientProfile,
                                                    }}
                                                />
                                            ) : (
                                                <NotSetText />
                                            )}
                                        </>
                                    }
                                    editButton={
                                        contact instanceof ContactAPIForOwner && !contact.relatedBusiness ? (
                                            <ModifyContactBankAccountButton
                                                contactAPI={contact}
                                                updateItem={(newContactAPI) =>
                                                    updateItem({
                                                        id: newContactAPI.id,
                                                        contact: newContactAPI,
                                                        profile: contactProfile,
                                                    })
                                                }
                                            />
                                        ) : undefined
                                    }
                                />
                            ),
                        },
                        {
                            label: txt({
                                en: 'Bank account to receive payments from this contact',
                                ja: 'このコンタクトからの入金を受け取る口座',
                            }),
                            children: (
                                <ValueWithEditButton
                                    value={
                                        <>
                                            {bankAccountToContact?.isDepositAccount ? (
                                                <DepositAccountCardWithHolder
                                                    className={c.bankAccountCard}
                                                    depositAccount={bankAccountToContact}
                                                    holder={{
                                                        name: currentBusiness.name,
                                                        profile: clientProfile,
                                                    }}
                                                    remitter={{
                                                        name: contact.relatedBusiness?.name || contact.name,
                                                        profile: contactProfile?.data,
                                                    }}
                                                />
                                            ) : bankAccountToContact ? (
                                                <ExternalBankAccountCardWithHolder
                                                    className={c.bankAccountCard}
                                                    bankAccount={bankAccountToContact}
                                                    holder={{
                                                        name: currentBusiness.name,
                                                        profile: clientProfile,
                                                    }}
                                                />
                                            ) : (
                                                <PreparingBankAccountCardWithHolder
                                                    className={c.bankAccountCard}
                                                    holder={{
                                                        name: currentBusiness.name,
                                                        profile: clientProfile,
                                                    }}
                                                    remitter={{
                                                        name: contact.relatedBusiness?.name || contact.name,
                                                        profile: contactProfile?.data,
                                                    }}
                                                />
                                            )}
                                        </>
                                    }
                                    editButton={undefined}
                                />
                            ),
                        },
                        ...(() => {
                            if (!getIsLinked(contact)) {
                                return [
                                    {
                                        label: txt({ en: 'Name', ja: '名称' }),
                                        children: (
                                            <ValueWithEditButton
                                                value={contact.name}
                                                editButton={
                                                    contact instanceof ContactAPIForOwner ? (
                                                        <ModifyContactNameButton
                                                            contactAPI={contact}
                                                            updateItem={(newContactAPI) =>
                                                                updateItem({
                                                                    id: newContactAPI.id,
                                                                    contact: newContactAPI,
                                                                    profile: contactProfile,
                                                                })
                                                            }
                                                        />
                                                    ) : undefined
                                                }
                                            />
                                        ),
                                    },
                                    {
                                        label: txt({
                                            en: 'Email address for notification',
                                            ja: '通知先メールアドレス',
                                        }),
                                        children: (
                                            <ValueWithEditButton
                                                value={contact.notificationEmail || <NotSetText />}
                                                editButton={
                                                    contact instanceof ContactAPIForOwner ? (
                                                        <ModifyContactNotificationEmailButton
                                                            contactAPI={contact}
                                                            updateItem={(newContactAPI) =>
                                                                updateItem({
                                                                    id: newContactAPI.id,
                                                                    contact: newContactAPI,
                                                                    profile: contactProfile,
                                                                })
                                                            }
                                                        />
                                                    ) : undefined
                                                }
                                            />
                                        ),
                                    },
                                ];
                            }
                            return [
                                {
                                    label: txt({ en: 'Address', ja: '住所' }),
                                    children: (
                                        <>
                                            {contactProfile?.data?.address ? (
                                                getStrWithBr(contactProfile.data.address)
                                            ) : (
                                                <NotSetText />
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    label: txt({ en: 'Email', ja: 'メールアドレス' }),
                                    children: <>{contactProfile?.data?.email || <NotSetText />}</>,
                                },
                                {
                                    label: txt({ en: 'Phone', ja: '電話' }),
                                    children: <>{contactProfile?.data?.phone || <NotSetText />}</>,
                                },
                                {
                                    label: txt({ en: 'Website', ja: 'ウェブサイト' }),
                                    children: <>{contactProfile?.data?.websiteUrl || <NotSetText />}</>,
                                },
                            ];
                        })(),
                    ]}
                />
            </div>
        </div>
    );
};
ContactDetails.displayName = 'ContactDetails';
