import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 513 513" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M301.149 394.704L221.949 473.894C171.171 524.693 88.9116 524.718 38.1086 473.894C-12.6904 423.116 -12.7154 340.857 38.1086 290.054L117.299 210.854C118.455 209.698 119.632 208.563 120.831 207.451C128.381 200.446 140.626 205.447 141.039 215.737C141.232 220.544 141.637 225.344 142.255 230.121C142.736 233.838 141.509 237.568 138.858 240.217C122.378 256.686 63.7156 315.345 63.5576 315.503C26.8196 352.262 26.8266 411.691 63.5576 448.443C100.317 485.181 159.746 485.174 196.498 448.443L275.698 369.243L276.058 368.883C312.359 332.211 312.198 272.813 275.688 236.303C267.474 228.089 258.111 221.723 248.103 217.194C243.537 215.128 240.677 210.527 240.969 205.524C241.27 200.339 242.219 195.213 243.795 190.265C245.898 183.664 253.326 180.304 259.714 182.985C274.787 189.309 288.901 198.605 301.149 210.853C351.837 261.542 351.828 344.023 301.149 394.704ZM210.853 301.15C223.101 313.398 237.215 322.694 252.288 329.018C258.676 331.698 266.104 328.338 268.207 321.738C269.782 316.789 270.731 311.663 271.033 306.479C271.325 301.476 268.464 296.875 263.899 294.809C253.891 290.281 244.528 283.915 236.314 275.7C199.804 239.19 199.643 179.792 235.944 143.12L236.304 142.76L315.504 63.5596C352.256 26.8286 411.685 26.8216 448.444 63.5596C485.175 100.312 485.182 159.741 448.444 196.5C448.287 196.657 389.625 255.317 373.144 271.786C370.493 274.436 369.266 278.165 369.747 281.882C370.364 286.656 370.77 291.455 370.963 296.266C371.376 306.557 383.622 311.557 391.171 304.552C392.369 303.439 393.547 302.305 394.703 301.149L473.893 221.949C524.717 171.146 524.692 88.8866 473.893 38.1086C423.091 -12.7154 340.831 -12.6904 290.053 38.1086L210.853 117.299C160.174 167.981 160.165 250.462 210.853 301.15Z"
                fill="currentColor"
            />
        </svg>
    );
};
Svg.displayName = 'svg/link';
export default Svg;
