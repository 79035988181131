import { User as GqlUser, Language } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';

export interface UserInterface {
    id: string;
    firebaseUid: string;
    email: string;
    language?: Language;
}

export class User implements UserInterface {
    protected _id: string;

    protected _firebaseUid: string;

    protected _email: string;

    protected _language?: Language;

    get id() {
        return this._id;
    }

    get firebaseUid() {
        return this._firebaseUid;
    }

    get email() {
        return this._email;
    }

    get language() {
        return this._language;
    }

    get data() {
        return {
            id: this.id,
            firebaseUid: this.firebaseUid,
            email: this.email,
            language: this.language,
        };
    }

    constructor({ id, firebaseUid, email, language }: UserInterface) {
        this._id = id;
        this._firebaseUid = firebaseUid;
        this._email = email;
        this._language = language;
    }
}

export const getUserFromGql = ({ id, firebaseUid, email, language }: GqlUser) => {
    return new User({
        id,
        firebaseUid,
        email,
        language: language || undefined,
    });
};
