import { FC } from 'react';

import {
    ExternalBankAccountInterface,
    getIsValidAccountNumber,
    getIsValidBankCode,
    getIsValidBranchCode,
    getValidRemitterName,
} from '@/utils/UodkaClients/Core';
import { useFormField, useLanguage } from '@/utils/customHooks';
import { FormModal } from '@/components/1_mol/FormModal/FormModal';
import { TextInput } from '@/components/0_atom/Input';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({}));

export const FormModalForBankAccount: FC<{
    isOpen: boolean;
    animation?: 'left' | 'right' | 'up' | 'down' | 'grow' | 'fade' | undefined;
    onClose: () => void;
    title: string;
    setBankAccount: (bankAccount: ExternalBankAccountInterface) => void;
    submitText: string;
    bankAccount: ExternalBankAccountInterface | undefined;
}> = ({ isOpen, animation, onClose, title, setBankAccount, submitText, bankAccount }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [bankCode, setBankCode] = useFormField(bankAccount?.bankCode);
    const [branchCode, setBranchCode] = useFormField(bankAccount?.branchCode);
    const [accountNumber, setAccountNumber] = useFormField(bankAccount?.accountNumber);
    const [accountHolderName, setAccountHolderName] = useFormField(bankAccount?.accountHolderName);

    // DATA
    const validBankCode = (() => {
        if (bankCode && getIsValidBankCode(bankCode)) {
            return bankCode;
        }
        return undefined;
    })();
    const validBranchCode = (() => {
        if (branchCode && getIsValidBranchCode(branchCode)) {
            return branchCode;
        }
        return undefined;
    })();
    const validAccountNumber = (() => {
        if (accountNumber && getIsValidAccountNumber(accountNumber)) {
            return accountNumber;
        }
        return undefined;
    })();
    const validAccountHolderName = (() => {
        if (accountHolderName) {
            const { isValid, fullWidthKatakana } = getValidRemitterName(accountHolderName);
            if (isValid && fullWidthKatakana) {
                return fullWidthKatakana;
            }
        }
        return undefined;
    })();

    // HANDLER
    const handleSubmit = () => {
        if (validBankCode && validBranchCode && validAccountNumber && validAccountHolderName) {
            setBankAccount({
                bankCode: validBankCode,
                branchCode: validBranchCode,
                accountNumber: validAccountNumber,
                accountHolderName: validAccountHolderName,
            });
            return;
        }
        throw new Error(
            'FormModalForBankAccount.handleSubmit: Unexpectedly !(bankCode && branchCode && accountNumber && accountHolderName)'
        );
    };

    return (
        <FormModal
            {...{
                isOpen,
                animation,
                onClose,
                title,
                submitText,
            }}
            onSubmit={handleSubmit}
            items={[
                {
                    labelText: txt({ en: 'Bank code', ja: '銀行コード' }),
                    isRequired: true,
                    isSatisfied: !!validBankCode,
                    isError: !!bankCode && !validBankCode,
                    children: (
                        <TextInput
                            value={bankCode}
                            setValue={setBankCode}
                            isSatisfied={!!validBankCode}
                            messages={[
                                {
                                    message: txt({
                                        en: 'Bank code should be 4-digit',
                                        ja: '銀行コードは半角数字４桁です',
                                    }),
                                    isError: !!bankCode && !validBankCode,
                                },
                            ]}
                        />
                    ),
                },
                {
                    labelText: txt({ en: 'Branch code', ja: '支店コード' }),
                    isRequired: true,
                    isSatisfied: !!validBranchCode,
                    isError: !!branchCode && !validBranchCode,
                    children: (
                        <TextInput
                            value={branchCode}
                            setValue={setBranchCode}
                            isSatisfied={!!validBranchCode}
                            messages={[
                                {
                                    message: txt({
                                        en: 'Branch code should be 3-digit',
                                        ja: '支店コードは半角数字３桁です',
                                    }),
                                    isError: !!branchCode && !validBranchCode,
                                },
                            ]}
                        />
                    ),
                },
                {
                    labelText: txt({ en: 'Account number', ja: '口座番号' }),
                    isRequired: true,
                    isSatisfied: !!validAccountNumber,
                    isError: !!accountNumber && !validAccountNumber,
                    children: (
                        <TextInput
                            value={accountNumber}
                            setValue={setAccountNumber}
                            isSatisfied={!!validAccountNumber}
                            messages={[
                                {
                                    message: txt({
                                        en: 'Account number should be 7-digit',
                                        ja: '口座番号は半角数字７桁です',
                                    }),
                                    isError: !!accountNumber && !validAccountNumber,
                                },
                            ]}
                        />
                    ),
                },
                {
                    labelText: txt({ en: 'Account holder name', ja: '名義人' }),
                    isRequired: true,
                    isSatisfied: !!validAccountHolderName,
                    isError: !!accountHolderName && !validAccountHolderName,
                    children: (
                        <TextInput
                            value={accountHolderName}
                            setValue={setAccountHolderName}
                            isSatisfied={!!accountHolderName}
                            messages={[
                                {
                                    message: txt({
                                        en:
                                            'Remitter name of account should be within 19 characters in Kana, "（" or "）" (counting turbidity marks and half turbidity marks as one character)',
                                        ja:
                                            '口座名義はカナと「（」「）」のいずれかで、19文字以内（濁点・半濁点を１文字としてカウント）の必要があります。',
                                    }),
                                    isError: !!accountHolderName && !validAccountHolderName,
                                },
                            ]}
                        />
                    ),
                },
            ]}
        />
    );
};
FormModalForBankAccount.displayName = 'FormModalForBankAccount';
