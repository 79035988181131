import { FC } from 'react';

import { TransferInBalance } from '@/utils/UodkaClients/Core';
import { useLanguage } from '@/utils/customHooks';

import SvgEllipsis from '@/svgs/ellipsis';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    ellipsis: {
        width: 10,
        height: 10,
        marginRight: 9,
    },
    text: {
        fontWeight: 600,
        lineHeight: 1,
    },
    description: {},
}));

export const TransferStatus: FC<{
    transfer: TransferInBalance;
}> = ({ transfer }) => {
    // STYLE
    const theme = useTheme();
    const c = useStyles(theme);

    // HOOKS
    const { txt } = useLanguage();

    // DATA
    const { issuedAt, error } = transfer;

    return (
        <div
            className={c.root}
            style={{
                color: issuedAt
                    ? theme.palette.success.main
                    : error
                    ? theme.palette.error.main
                    : theme.palette.warning.main,
            }}
        >
            <SvgEllipsis className={c.ellipsis} />
            <MuiTypography variant={'body2'} className={c.text}>
                {issuedAt
                    ? txt({ en: 'Completed', ja: '完了' })
                    : error
                    ? txt({ en: 'Error', ja: 'エラー' })
                    : txt({ en: 'Processing', ja: '受付中' })}
            </MuiTypography>
            {/* {error && (
                <MuiTypography variant={'body2'} className={c.description}>
                    {error.code}
                </MuiTypography>
            )} */}
        </div>
    );
};
TransferStatus.displayName = 'TransferStatus';
