import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 1046 1262" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 88C0 39.3989 39.3989 0 88 0H958C1006.6 0 1046 39.3989 1046 88V1174C1046 1222.6 1006.6 1262 958 1262H88C39.399 1262 0 1222.6 0 1174V88Z"
                fill="#00B607"
            />
            <path
                d="M424 167C424 141.595 444.595 121 470 121H947C972.405 121 993 141.595 993 167V309C993 334.405 972.405 355 947 355H470C444.595 355 424 334.405 424 309V167Z"
                fill="black"
                fillOpacity="0.1"
            />
            <path
                d="M104 560C104 534.595 124.595 514 150 514H947C972.405 514 993 534.595 993 560V702C993 727.405 972.405 748 947 748H150C124.595 748 104 727.405 104 702V560Z"
                fill="black"
                fillOpacity="0.1"
            />
            <path
                d="M104 850C104 824.595 124.595 804 150 804H947C972.405 804 993 824.595 993 850V992C993 1017.41 972.405 1038 947 1038H150C124.595 1038 104 1017.41 104 992V850Z"
                fill="black"
                fillOpacity="0.1"
            />
            <path
                d="M890.209 167H876.307C875.625 167 874.955 167.184 874.369 167.533C873.783 167.881 873.302 168.381 872.977 168.98L849.708 210.938C845.304 220.325 841.1 231.309 841.1 231.309H840.7C840.7 231.309 836.496 220.324 832.091 210.938L809.039 168.992C808.715 168.39 808.234 167.887 807.647 167.536C807.059 167.185 806.387 167 805.703 167H791.791C788.902 167 787.078 170.098 788.485 172.616L818.206 224.911H801.881C799.79 224.911 798.095 226.602 798.095 228.688V236.241C798.095 238.327 799.79 240.018 801.881 240.018H826.229L830.89 248.285V255.125H801.881C799.79 255.125 798.095 256.816 798.095 258.902V266.455C798.095 268.541 799.79 270.232 801.881 270.232H830.89V304.223C830.89 306.309 832.586 308 834.676 308H847.124C849.214 308 850.909 306.309 850.909 304.223V270.232H880.119C882.21 270.232 883.905 268.541 883.905 266.455V258.902C883.905 256.816 882.21 255.125 880.119 255.125H850.909V248.285L855.57 240.018H880.119C882.21 240.018 883.905 238.327 883.905 236.241V228.688C883.905 226.602 882.21 224.911 880.119 224.911H863.598L893.511 172.624C894.926 170.107 893.102 167 890.209 167Z"
                fill="white"
            />
        </svg>
    );
};
Svg.displayName = 'svg/bill';
export default Svg;
