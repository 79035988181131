import { FC } from 'react';

import { UodkaCoreClientForOwner } from '@/utils/UodkaClients/Core';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';

import { MembersTable } from '@/views/config/business/Member/MembersTable';
import { InvitesTable } from '@/views/config/business/Member/InvitesTable';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {},
    firbiddenText: {
        color: theme.palette.text.disabled,
        fontWeight: 600,
        fontSize: theme.typography.body2.fontSize,
    },
}));

export const ConfigBusinessMembers: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const {
        clients: { core: coreClient },
    } = useBusinessCtxAbsolutely();

    return (
        <div className={c.root}>
            {coreClient instanceof UodkaCoreClientForOwner ? (
                <>
                    <MembersTable coreClient={coreClient} />
                    <InvitesTable coreClient={coreClient} />
                </>
            ) : (
                <MuiTypography className={c.firbiddenText}>
                    {txt({
                        en: 'Only the owners can view and control the members.',
                        ja: 'メンバーの閲覧・操作はオーナーのみが可能です。',
                    })}
                </MuiTypography>
            )}
        </div>
    );
};
ConfigBusinessMembers.displayName = 'ConfigBusinessMembers';
