import { GqlDateTimeData } from '@/utils/UodkaClients/Invoices/graphql/customScalars';
import { SharedBill as GqlSharedBill } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import { BillData, getBillDataFromGql } from '@/utils/UodkaClients/Invoices/models/InvoiceData';
import { BillSignatures, getBillSignaturesFromGql } from '@/utils/UodkaClients/Invoices/models/BillSignatures';
import { PaymentInSharedBill, getPaymentInSharedBillFromGql } from '@/utils/UodkaClients/Invoices/models/PaymentEdge';
import { ReceiptInSharedBill, getReceiptInSharedBillFromGql } from '@/utils/UodkaClients/Invoices/models/ReceiptEdge';

interface SharedBillInterface {
    id: string;
    createdAt: Date;
    billData: BillData;
    signatures: BillSignatures;
    payment?: PaymentInSharedBill;
    receipt?: ReceiptInSharedBill;
}

export class SharedBill implements SharedBillInterface {
    protected _id: string;

    protected _createdAt: Date;

    protected _billData: BillData;

    protected _signatures: BillSignatures;

    protected _payment?: PaymentInSharedBill;

    protected _receipt?: ReceiptInSharedBill;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get billData() {
        return this._billData;
    }

    get signatures() {
        return this._signatures;
    }

    get payment() {
        return this._payment;
    }

    get receipt() {
        return this._receipt;
    }

    constructor({ id, createdAt, billData, signatures, payment, receipt }: SharedBillInterface) {
        this._id = id;
        this._createdAt = createdAt;
        this._billData = billData;
        this._signatures = signatures;
        this._payment = payment;
        this._receipt = receipt;
    }
}

export const getSharedBillFromGql = ({ id, createdAt, billData, signatures, payment, receipt }: GqlSharedBill) => {
    return new SharedBill({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        billData: getBillDataFromGql(billData),
        signatures: getBillSignaturesFromGql(signatures),
        payment: payment ? getPaymentInSharedBillFromGql(payment) : undefined,
        receipt: receipt ? getReceiptInSharedBillFromGql(receipt) : undefined,
    });
};
