import { FC, useState, useEffect, useCallback } from 'react';

import { useBusinessCtxAbsolutely } from '@/utils/ctxs';

import { IssuedBillItem, ReceivedBillItem, fetchIssuedBill, fetchReceivedBill } from '@/views/bills/useBillItems';
import { BillDrawer } from '@/views/bills/BillDrawer';

export type BillInfomation = {
    direction: 'issued' | 'received';
    contactId: string;
    billId: string;
};

export const BillModal: FC<{
    billInfomation: BillInfomation | undefined;
    onClose: () => void;
}> = ({ billInfomation, onClose }) => {
    // HOOKS
    const {
        clients: { core: coreClient, invoices: invoicesClient },
    } = useBusinessCtxAbsolutely();
    const [issuedBillItem, setIssuedBillItem] = useState<IssuedBillItem | undefined>();
    const [receivedBillItem, setReceivedBillItem] = useState<ReceivedBillItem | undefined>();

    // CALLBACK
    const fetchBill = useCallback(async () => {
        if (billInfomation?.direction === 'issued') {
            const fetchedBill = await fetchIssuedBill({
                coreClient,
                invoicesClient,
                contactId: billInfomation.contactId,
                billId: billInfomation.billId,
            });
            if (fetchedBill) {
                setIssuedBillItem(fetchedBill);
            } else {
                // setIs500(true);
            }
        } else if (billInfomation?.direction === 'received') {
            const fetchedBill = await fetchReceivedBill({
                coreClient,
                invoicesClient,
                contactId: billInfomation.contactId,
                billId: billInfomation.billId,
            });
            if (fetchedBill) {
                setReceivedBillItem(fetchedBill);
            } else {
                // setIs500(true);
            }
        } else if (!billInfomation) {
            setIssuedBillItem(undefined);
            setReceivedBillItem(undefined);
        }
    }, [billInfomation, coreClient, invoicesClient]);

    // USEEFFECT
    useEffect(() => {
        fetchBill();
    }, [fetchBill]);

    return (
        <BillDrawer
            isOpen={!!billInfomation}
            onClose={onClose}
            issuedBillItem={issuedBillItem}
            receivedBillItem={receivedBillItem}
            updateIssuedBillItem={() => undefined}
            updateReceivedBillItem={() => undefined}
            removeIssuedBillItem={() => undefined}
            removeReceivedBillItem={() => undefined}
            isFetching={false}
            isDisableNotFound
        />
    );
};
BillModal.displayName = 'BillModal';
