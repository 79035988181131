import {
    getBusinessProfileInterfaceFromGql,
    BusinessProfileAPIForOwner,
} from '@/utils/UodkaClients/Profile/models/BusinessProfile';
import { UodkaProfileClientForObserver } from '@/utils/UodkaClients/Profile/clients/Business/ForObserver';

import { getBusinessLogoSrc, getBusinessIconSrc } from '@/utils/GoogleCloud/storage';

interface UodkaProfileClientForOwnerInterface {
    getProfileDataAPI: () => Promise<BusinessProfileAPIForOwner | undefined>;
}

export class UodkaProfileClientForOwner
    extends UodkaProfileClientForObserver
    implements UodkaProfileClientForOwnerInterface {
    async getProfileDataAPI() {
        const { businessProfile } = await this._graphqlSdk.businessProfile({
            id: this._businessId,
        });
        const logoSrc = await getBusinessLogoSrc({ businessId: this._businessId });
        const iconSrc = await getBusinessIconSrc({ businessId: this._businessId });
        if (businessProfile || logoSrc || iconSrc) {
            return new BusinessProfileAPIForOwner({
                ...getBusinessProfileInterfaceFromGql({
                    businessId: this._businessId,
                    ...businessProfile,
                }),
                logoSrc,
                iconSrc,
                graphqlSdk: this._graphqlSdk,
            });
        }
        return undefined;
    }
}
