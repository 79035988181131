import { FC, useState } from 'react';

import { UodkaCoreClientForOwner, BusinessMemberRole } from '@/utils/UodkaClients/Core';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { MutationFormModal } from '@/components/1_mol/FormModal';
import { TextSelector } from '@/components/1_mol/Selector';
import { IntInput } from '@/components/0_atom/Input';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    input: {
        '& input': {
            textAlign: 'center',
        },
    },
}));

export const AddInviteModal: FC<{
    coreClient: UodkaCoreClientForOwner;
    refetchInvites: () => void;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}> = ({ coreClient, refetchInvites, isOpen, setIsOpen }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { currentBusiness } = useBusinessCtxAbsolutely();
    const { txt } = useLanguage();
    const [role, setRole] = useState<BusinessMemberRole>('general');
    const [numberOfSeats, setNumberOfSeats] = useState(1);
    const [durationDays, setDurationDays] = useState(30);

    // MUTATION
    const run = async () => {
        const { result } = await coreClient.issueInviteForMember({
            role,
            numberOfSeats,
            durationDays,
        });
        refetchInvites();
        return result;
    };

    return (
        <>
            <MutationFormModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={txt({
                    en: `Issue an invite for ${currentBusiness.name}`,
                    ja: 'メンバー招待URLを発行',
                })}
                mutation={{
                    run,
                    description: txt({
                        en: 'Issue an invite for member',
                        ja: 'メンバー招待URLを発行',
                    }),
                }}
                submitText={txt({ en: 'Issue', ja: '発行' })}
                items={[
                    {
                        labelText: txt({ en: 'Role', ja: '権限' }),
                        isSatisfied: !!role,
                        isError: !role,
                        isRequired: true,
                        children: (
                            <TextSelector
                                emptyPlaceholder={'unexpected'}
                                items={[
                                    { id: 'owner', text: txt({ en: 'Owner', ja: 'オーナー' }) },
                                    { id: 'general', text: txt({ en: 'General', ja: '一般' }) },
                                    { id: 'observer', text: txt({ en: 'Observer', ja: '閲覧のみ' }) },
                                ]}
                                onSelect={(newRole) => setRole(newRole as BusinessMemberRole)}
                                selectedId={role}
                            />
                        ),
                    },
                    {
                        labelText: txt({ en: 'Number of seats', ja: '一度に招待する人数' }),
                        isSatisfied: !!numberOfSeats,
                        isError: !numberOfSeats,
                        isRequired: true,
                        children: (
                            <IntInput
                                fullWidth
                                className={c.input}
                                startAdornment={' '}
                                endAdornment={txt({ en: numberOfSeats > 1 ? 'seats' : 'seat', ja: '人' })}
                                value={numberOfSeats}
                                setValue={(v) => setNumberOfSeats(v || 0)}
                                isSatisfied={!!numberOfSeats}
                            />
                        ),
                    },
                    {
                        labelText: txt({ en: 'Term of validity', ja: '有効期限' }),
                        isSatisfied: !!durationDays,
                        isError: !durationDays,
                        isRequired: true,
                        children: (
                            <IntInput
                                fullWidth
                                className={c.input}
                                startAdornment={txt({ en: '', ja: '今日から' })}
                                endAdornment={txt({
                                    en: durationDays > 1 ? 'days from today' : 'day from today',
                                    ja: '日間',
                                })}
                                value={durationDays}
                                setValue={(v) => setDurationDays(v || 0)}
                                isSatisfied={!!durationDays}
                            />
                        ),
                    },
                ]}
            />
        </>
    );
};
AddInviteModal.displayName = 'views/config/business/Member/AddInviteModal';
