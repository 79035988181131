import { FC, ReactNode, useState, useCallback, useEffect } from 'react';

import { useUserCtx, BusinessCtx, InitializeClient, useErrorCtx } from '@/utils/ctxs';
import { useRouting } from '@/utils/customHooks';
import {
    initUodkaCoreClient,
    UodkaCoreClientForObserver,
    UodkaCoreClientForGeneral,
    UodkaCoreClientForOwner,
    ClientDataInBusinessMember,
} from '@/utils/UodkaClients/Core';
import {
    initUodkaProfileClient,
    UodkaProfileClientForAnonymous,
    UodkaProfileClientForObserver,
    UodkaProfileClientForGeneral,
    UodkaProfileClientForOwner,
} from '@/utils/UodkaClients/Profile';
import {
    initUodkaCertClient,
    UodkaCertClientForObserver,
    UodkaCertClientForGeneral,
    UodkaCertClientForOwner,
} from '@/utils/UodkaClients/Cert';
import {
    initUodkaInvoicesClient,
    UodkaInvoicesClientForObserver,
    UodkaInvoicesClientForGeneral,
    UodkaInvoicesClientForOwner,
} from '@/utils/UodkaClients/Invoices';

const BusinessProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // HOOKS
    const { setIs404 } = useErrorCtx();
    const { businessId } = useRouting();
    const { firebaseUser, memberWithBusinessProfiles } = useUserCtx();
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [currentBusiness, setCurrentBusiness] = useState<ClientDataInBusinessMember | undefined>(undefined);
    const [coreClient, setCoreClient] = useState<
        UodkaCoreClientForObserver | UodkaCoreClientForGeneral | UodkaCoreClientForOwner | undefined
    >(undefined);
    const [profileClient, setProfileClient] = useState<
        | UodkaProfileClientForAnonymous
        | UodkaProfileClientForObserver
        | UodkaProfileClientForGeneral
        | UodkaProfileClientForOwner
        | undefined
    >(undefined);
    const [certClient, setCertClient] = useState<
        UodkaCertClientForObserver | UodkaCertClientForGeneral | UodkaCertClientForOwner | undefined
    >(undefined);
    const [invoicesClient, setInvoicesClient] = useState<
        UodkaInvoicesClientForObserver | UodkaInvoicesClientForGeneral | UodkaInvoicesClientForOwner | undefined
    >(undefined);
    const initialize: InitializeClient = useCallback(
        async ({ businessId, role }) => {
            if (firebaseUser) {
                const newCoreClient = initUodkaCoreClient({
                    firebaseUserIdToken: firebaseUser.idToken,
                    clientId: businessId,
                    role,
                });
                const newProfileClient = initUodkaProfileClient({
                    firebaseUserIdToken: firebaseUser.idToken,
                    clientId: businessId,
                    role,
                });
                const newCertClient = initUodkaCertClient({
                    firebaseUserIdToken: firebaseUser.idToken,
                    clientId: businessId,
                    role,
                });
                const newInvoicesClient = initUodkaInvoicesClient({
                    firebaseUserIdToken: firebaseUser.idToken,
                    clientId: businessId,
                    role,
                });
                setCoreClient(newCoreClient);
                setProfileClient(newProfileClient);
                setCertClient(newCertClient);
                setInvoicesClient(newInvoicesClient);
                setIsFetching(false);
            } else {
                setIsFetching(false);
                throw new Error('BusinessProvider.initialize: missing firebaseUser');
            }
        },
        [firebaseUser]
    );

    // USEEFFECT
    useEffect(() => {
        if (businessId !== currentBusiness?.id) {
            const targetMemberWithBusinessProfile = memberWithBusinessProfiles?.find(
                ({ member }) => member.business.id === businessId
            );
            if (targetMemberWithBusinessProfile) {
                const { member } = targetMemberWithBusinessProfile;
                setCurrentBusiness(member.business);
                initialize({
                    businessId: member.business.id,
                    role: member.role,
                });
            } else if (currentBusiness?.id) {
                setIs404(true);
            }
        }
    }, [businessId, initialize, setIs404, currentBusiness?.id, memberWithBusinessProfiles]);

    return (
        <BusinessCtx.Provider
            value={{
                clients: {
                    core: coreClient,
                    profile: profileClient,
                    cert: certClient,
                    invoices: invoicesClient,
                },
                isFetching,
                reInitialize: initialize,
                currentBusiness,
            }}
        >
            {children}
        </BusinessCtx.Provider>
    );
};
BusinessProvider.displayName = 'BusinessProvider';
export default BusinessProvider;
