import clsx from 'clsx';

import { UodkaFee } from '@/utils/UodkaClients/Core';
import FetchingText from '@/components/0_atom/FetchingText';

export const getFormMessages = ({
    messageClassName,
    txt,
    transferFee,
    amount,
    balance,
    isAmountSatisfied,
}: {
    messageClassName?: string;
    txt: (multilingualTexts: { en: string; ja?: string | undefined }) => string;
    transferFee: UodkaFee | undefined;
    amount: number;
    balance: number | undefined;
    isAmountSatisfied: boolean;
}) => {
    return [
        {
            message: (
                <div className={messageClassName}>
                    <span>
                        {txt({
                            en: 'Fee (tax included):',
                            ja: '手数料（税込）：',
                        })}
                    </span>
                    <span>{transferFee ? `¥${transferFee.total.toLocaleString()}` : <FetchingText />}</span>
                </div>
            ),
        },
        {
            message: (
                <div className={messageClassName}>
                    <span>
                        {txt({
                            en: 'Required funds:',
                            ja: '必要資金：',
                        })}
                    </span>
                    <span>{transferFee ? `¥${(amount + transferFee.total).toLocaleString()}` : <FetchingText />}</span>
                </div>
            ),
        },
        {
            isError: !!amount && !isAmountSatisfied,
            message: (
                <div className={messageClassName}>
                    <span>
                        {txt({
                            en: 'Ceiling:',
                            ja: '現在の送金可能額（最大）：',
                        })}
                    </span>
                    <span>
                        {balance !== undefined && transferFee ? (
                            `¥${(balance - transferFee.total > 0 ? balance - transferFee.total : 0).toLocaleString()}`
                        ) : (
                            <FetchingText />
                        )}
                    </span>
                </div>
            ),
        },
        {
            message: (
                <div className={messageClassName}>
                    <span>
                        {txt({
                            en: 'Current balance:',
                            ja: '現在の残高：',
                        })}
                    </span>
                    <span>{balance !== undefined ? `¥${balance.toLocaleString()}` : <FetchingText />}</span>
                </div>
            ),
        },
        {
            message: (
                <div className={messageClassName}>
                    <span>
                        {txt({
                            en: 'Balance after remittance:',
                            ja: '送金後の残高：',
                        })}
                    </span>
                    <span>
                        {balance !== undefined && transferFee ? (
                            `¥${(balance - (amount + transferFee.total)).toLocaleString()}`
                        ) : (
                            <FetchingText />
                        )}
                    </span>
                </div>
            ),
        },
    ];
};

export const getConfirmationFormMessages = ({
    messageClassName,
    amountClassName,
    totalClassName,
    txt,
    transferFee,
    amount,
    balance,
}: {
    messageClassName?: string;
    amountClassName?: string;
    totalClassName?: string;
    txt: (multilingualTexts: { en: string; ja?: string | undefined }) => string;
    transferFee: UodkaFee | undefined;
    amount: number;
    balance: number | undefined;
}) => {
    return [
        <div className={clsx(messageClassName, amountClassName)}>
            <span>
                {txt({
                    en: 'Transfer Amount:',
                    ja: '送金額：',
                })}
            </span>
            <span>{`¥${amount.toLocaleString()}`}</span>
        </div>,
        <div className={messageClassName}>
            <span>
                {txt({
                    en: 'Fee (tax included):',
                    ja: '手数料（税込）：',
                })}
            </span>
            <span>{transferFee ? `¥${transferFee.total.toLocaleString()}` : <FetchingText />}</span>
        </div>,
        <div className={clsx(messageClassName, totalClassName)}>
            <span>
                {txt({
                    en: 'Total withdraw:',
                    ja: '出金総額：',
                })}
            </span>
            <span>{transferFee ? `¥${(amount + transferFee.total).toLocaleString()}` : <FetchingText />}</span>
        </div>,
        // <div className={messageClassName}>
        //     <span>
        //         {txt({
        //             en: 'Current balance:',
        //             ja: '現在の残高：',
        //         })}
        //     </span>
        //     <span>{balance !== undefined ? `¥${balance.toLocaleString()}` : <FetchingText />}</span>
        // </div>,
        // <div className={messageClassName}>
        //     <span>
        //         {txt({
        //             en: 'Balance after remittance:',
        //             ja: '送金後の残高：',
        //         })}
        //     </span>
        //     <span>
        //         {balance !== undefined && transferFee ? (
        //             `¥${(balance - (amount + transferFee.total)).toLocaleString()}`
        //         ) : (
        //             <FetchingText />
        //         )}
        //     </span>
        // </div>,
    ];
};
