import { FC, ReactNode, useCallback } from 'react';

import { AddMutationInput, getWrappedMutation } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import Modal from '@/components/0_atom/Modal';
import MutationButton from '@/components/0_atom/MutationButton';
import Button from '@/components/0_atom/Button';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        marginBottom: 33,
    },
    children: {},
    submits: {
        display: 'flex',
        marginTop: 65,
    },
    cancelButton: {
        flexGrow: 1,
        width: 'calc(50% - 14px)',
    },
    submitButton: {
        flexGrow: 1,
        marginLeft: 14,
        width: 'calc(50% - 14px)',
    },
    backdropWarning: {
        backgroundColor: 'rgba(0,0,0,.3)',
    },
}));

const ConfirmationModal: FC<{
    isOpen: boolean;
    animation?: 'left' | 'right' | 'up' | 'down' | 'grow' | 'fade' | undefined;
    onClose: () => void;
    title: string;
    mutation: AddMutationInput;
    submitText: string;
    children: ReactNode;
    isWarning?: boolean;
}> = ({ isOpen, animation, onClose, title, mutation, submitText, children, isWarning }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    // DATA
    const wrappedMutation = getWrappedMutation({
        mutation,
        additionalOnThen: (result) => {
            if (result === 'success') {
                handleClose();
            }
        },
    });

    return (
        <Modal {...{ isOpen, animation, onClose }} backdropClassName={isWarning ? c.backdropWarning : undefined}>
            <MuiTypography variant={'h1'} className={c.title}>
                {title}
            </MuiTypography>
            <div className={c.children}>{children}</div>
            <div className={c.submits}>
                <Button onClick={handleClose} className={c.cancelButton} role={'cancel'} variant={'text'}>
                    {txt({ en: 'Cancel', ja: 'キャンセル' })}
                </Button>
                <MutationButton
                    mutation={wrappedMutation}
                    className={c.submitButton}
                    role={isWarning ? 'dangerouslySubmit' : 'submit'}
                >
                    {submitText}
                </MutationButton>
            </div>
        </Modal>
    );
};
ConfirmationModal.displayName = 'ConfirmationModal';
export default ConfirmationModal;
