import { FC, ComponentProps } from 'react';

import { AddMutationInput } from '@/utils/ctxs';
import Modal from '@/components/0_atom/Modal';
import { MutationForm } from '@/components/0_atom/Form';
import FormItem from '@/components/0_atom/FormItem';

export const MutationFormModal: FC<{
    isOpen: boolean;
    animation?: 'left' | 'right' | 'up' | 'down' | 'grow' | 'fade' | undefined;
    onClose: () => void;
    title: string;
    mutation: AddMutationInput;
    items: ComponentProps<typeof FormItem>[];
    submitText: string;
    initializeForm?: () => void;
}> = ({ isOpen, animation, onClose, title, mutation, items, submitText, initializeForm }) => {
    return (
        <Modal {...{ isOpen, animation, onClose }}>
            <MutationForm
                {...{ onClose, title, mutation, items, submitText, initializeForm, isDisplayCancelButton: true }}
            />
        </Modal>
    );
};
MutationFormModal.displayName = 'MutationFormModal';
