import { FC } from 'react';

import { ContactAPIForOwner, InviteForContactAPIForOwner, MutationValidationResult } from '@/utils/UodkaClients/Core';
import { useLanguage } from '@/utils/customHooks';
import MutationButton from '@/components/0_atom/MutationButton';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    issueButton: {
        marginTop: 32,
    },
}));

export const IssuedBillShareModalContentNoInvite: FC<{
    contactAPI: ContactAPIForOwner;
    issueInvite: (
        durationDays?: number | undefined
    ) => Promise<{
        inviteAPI: InviteForContactAPIForOwner;
        result: MutationValidationResult;
    }>;
    setInviteAPI: (inviteAPI: InviteForContactAPIForOwner) => void;
}> = ({ contactAPI, issueInvite, setInviteAPI }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    // MUTATION
    const runIssueInvite = async () => {
        const { inviteAPI: newInviteAPI, result } = await issueInvite(30);
        setInviteAPI(newInviteAPI);
        return result;
    };

    return (
        <>
            <MuiTypography>
                {txt({
                    en: 'The person who opens the URL will be able to view the shared invoice and link to the contact.',
                    ja: 'URLを開いた相手は、共有された請求書の確認・コンタクトのリンクが可能になります。',
                })}
            </MuiTypography>
            <MutationButton
                className={c.issueButton}
                fullWidth
                role={'submit'}
                mutation={{
                    run: runIssueInvite,
                    description: txt({
                        en: `Issue URL for sharing with ${contactAPI.name}`,
                        ja: `${contactAPI.name}への請求の共有URLを発行`,
                    }),
                }}
            >
                {txt({
                    en: 'Issue URL for sharing',
                    ja: '共有URLを発行',
                })}
            </MutationButton>
        </>
    );
};
IssuedBillShareModalContentNoInvite.displayName = 'IssuedBillShareModalContentNoInvite';
