import { FC, ReactElement } from 'react';

import { BalanceWithInvoice } from '@/utils/UodkaClients/Invoices';
import FetchingText from '@/components/0_atom/FetchingText';

import { FieldItem } from '@/views/records/useAccountingItems';
import { AccountingSideMenu } from '@/views/records/AccountingSideMenu';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        marginLeft: 150 + 36,
        width: '100%',
        height: 'calc(100vh - 36px)',
        overflowX: 'scroll',
        overflowY: 'scroll',
        boxShadow: '0 0 64px 0 rgba(0,0,0,.1)',
        borderRadius: 4,
        background: '#fff',
        '&::-webkit-scrollbar': {
            overflow: 'hidden',
            width: 4,
            background: '#fff',
            '&:horizontal': {
                height: 4,
            },
        },
        '&::-webkit-scrollbar-thumb': {
            overflow: 'hidden',
            background: 'rgba(0,0,0,.45)',
            borderRadius: 2,
            '&:horizontal': {
                height: 2,
            },
        },
    },
    fetchingText: {
        marginLeft: 150 + 36,
    },
}));

export const AccountingTemplate: FC<{
    children: ReactElement | undefined;
    items: FieldItem[] | undefined;
    balanceWithInvoices: BalanceWithInvoice[] | undefined;
    isContactId: boolean;
    isContactName: boolean;
    isItemName: boolean;
    isKind: boolean;
    isSelfDepositAmount: boolean;
    isReceivedBillAmount: boolean;
    isIssuedBillAmount: boolean;
    isIssuedPaymentAmount: boolean;
    isReceivedPaymentAmount: boolean;
    isPaidTaxAmount: boolean;
    isReceivedTaxAmount: boolean;
    isBankAccount: boolean;
    isBillDate: boolean;
    isPaymentDate: boolean;
    sortBy: 'billDate' | 'issueDate';
    sortOrder: 'asc' | 'desc';
    fromDate: Date | undefined;
    toDate: Date | undefined;
    setIsContactId: (bool: boolean) => void;
    setIsContactName: (bool: boolean) => void;
    setIsItemName: (bool: boolean) => void;
    setIsKind: (bool: boolean) => void;
    setIsSelfDepositAmount: (bool: boolean) => void;
    setIsReceivedBillAmount: (bool: boolean) => void;
    setIsIssuedBillAmount: (bool: boolean) => void;
    setIsIssuedPaymentAmount: (bool: boolean) => void;
    setIsReceivedPaymentAmount: (bool: boolean) => void;
    setIsPaidTaxAmount: (bool: boolean) => void;
    setIsReceivedTaxAmount: (bool: boolean) => void;
    setIsBankAccount: (bool: boolean) => void;
    setIsBillDate: (bool: boolean) => void;
    setIsPaymentDate: (bool: boolean) => void;
    setSortBy: (sortBy: 'billDate' | 'issueDate') => void;
    setSortOrder: (sortOrder: 'asc' | 'desc') => void;
    setFromDate: (date: Date | undefined) => void;
    setToDate: (date: Date | undefined) => void;
}> = ({ children, balanceWithInvoices, ...filterProps }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={c.root}>
            <AccountingSideMenu balanceWithInvoices={balanceWithInvoices} {...filterProps} />
            {children ? <div className={c.content}>{children}</div> : <FetchingText className={c.fetchingText} />}
        </div>
    );
};
AccountingTemplate.displayName = 'AccountingTemplate';
