import { FC, useEffect, useState } from 'react';

import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage, useClientData, useClientProfile } from '@/utils/customHooks';
import FetchingText from '@/components/0_atom/FetchingText';
import { SelfDepositAccountCardWithHolder } from '@/components/2_org/BankAccountCard';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#fff',
        borderRadius: 4,
        padding: 18,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    currentBalance: {
        margin: '70px auto 97px auto',
    },
    currentBalanceFetchingText: {
        height: 51,
        display: 'flex',
        alignItems: 'center',
    },
    currentBalanceLabel: {
        fontWeight: 600,
    },
    currentBalanceValue: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        fontSize: 36,
    },
    currentBalanceSign: {
        fontSize: 24,
        marginRight: 10,
    },
    currentBalanceNumber: {
        fontSize: 36,
    },
    caption: {
        color: theme.palette.text.disabled,
        fontWeight: 600,
        marginBottom: 7,
        display: 'block',
    },
    depositAccount: {
        marginBottom: 28,
    },
    bankAccountCard: {
        border: 'solid 2px rgba(0,0,0,.03)',
        borderRadius: 4,
        padding: 14,
    },
    bankAccountPlaceholder: {
        borderRadius: 4,
        background: '#fafafa',
        fontWeight: 600,
        padding: '28px',
        width: 360,
        color: theme.palette.text.disabled,
    },
}));

export const ConfigBusinessAccountCharge: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS

    const {
        clients: { core: coreClient },
    } = useBusinessCtxAbsolutely();
    const [currentBalance, setCurrentBalance] = useState<number | undefined>(undefined);
    const { txt } = useLanguage();
    const { clientData } = useClientData();
    const { clientProfile } = useClientProfile();

    // USEEFFECT
    useEffect(() => {
        coreClient.getRecentBalance().then((balance) => setCurrentBalance(balance?.currentAmount || 0));
    }, [coreClient]);

    return (
        <div className={c.root}>
            <div className={c.currentBalance}>
                <MuiTypography variant={'body2'} className={c.currentBalanceLabel}>
                    {txt({ en: 'Current Balance', ja: '現在の残高' })}
                </MuiTypography>
                {currentBalance !== undefined ? (
                    <div className={c.currentBalanceValue}>
                        <span className={c.currentBalanceSign}>{'¥'}</span>
                        <span className={c.currentBalanceNumber}>{currentBalance.toLocaleString()}</span>
                    </div>
                ) : (
                    <FetchingText className={c.currentBalanceFetchingText} />
                )}
            </div>
            <div className={c.depositAccount}>
                <MuiTypography variant={'caption'} className={c.caption}>
                    {txt({
                        en: 'For self-deposit, please transfer funds to the following bank account.',
                        ja: '自己入金の際は、以下の銀行口座にお振り込みください。',
                    })}
                </MuiTypography>
                {clientData && clientProfile !== 'fetching' ? (
                    clientData.depositAccountFromMeToMe ? (
                        <SelfDepositAccountCardWithHolder
                            className={c.bankAccountCard}
                            depositAccount={clientData.depositAccountFromMeToMe.data}
                            holder={{ name: clientData.name, profile: clientProfile }}
                        />
                    ) : (
                        <div className={c.bankAccountPlaceholder}>
                            {txt({
                                en: `We are currently preparing a deposit account for ${clientData.name}. Please allow up to 3 business days.`,
                                ja: `ただいま${clientData.name}の入金用口座を準備中です。最大で３営業日お待ちください。`,
                            })}
                        </div>
                    )
                ) : (
                    <div className={c.bankAccountPlaceholder}>
                        <FetchingText />
                    </div>
                )}
            </div>
        </div>
    );
};
ConfigBusinessAccountCharge.displayName = 'ConfigBusinessAccountCharge';
