import { InvoiceDataSummary } from '@/utils/UodkaClients/Invoices';

export const getInvoiceDataSummaryData = ({ invoiceDataSummary }: { invoiceDataSummary: InvoiceDataSummary }) => {
    const { decimalRoundRuleForSubtotal, subtotal, taxes, total } = invoiceDataSummary;
    return {
        decimalRoundRuleForSubtotal,
        subtotal,
        taxes: taxes.map(({ decimalRoundRule, title, taxableValue, value }) => ({
            decimalRoundRule,
            title,
            taxableValue,
            value,
        })),
        total,
    };
};
