import { FC, ReactNode, useMemo } from 'react';

import { HealthCtx } from '@/utils/ctxs';
import { getUodkaCoreHealth } from '@/utils/UodkaClients/Core';
import { getUodkaProfileHealth } from '@/utils/UodkaClients/Profile';
import { getUodkaCertHealth } from '@/utils/UodkaClients/Cert';
import { getUodkaInvoicesHealth } from '@/utils/UodkaClients/Invoices';
import { useHealth } from '@/providers/HealthProvider/useHealth';

const HealthProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // HOOKS
    const coreHealth = useHealth(getUodkaCoreHealth);
    const profileHealth = useHealth(getUodkaProfileHealth);
    const certHealth = useHealth(getUodkaCertHealth);
    const invoicesHealth = useHealth(getUodkaInvoicesHealth);

    // MEMO
    const content = useMemo(
        () => (
            <HealthCtx.Provider
                value={{ core: coreHealth, profile: profileHealth, cert: certHealth, invoices: invoicesHealth }}
            >
                {children}
            </HealthCtx.Provider>
        ),
        [children, coreHealth, profileHealth, certHealth, invoicesHealth]
    );

    return content;
};
HealthProvider.displayName = 'HealthProvider';
export default HealthProvider;
