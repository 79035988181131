import { getGraphqlSdk } from '@/utils/UodkaClients/Core/graphql/graphqlSdk';
import { UodkaUserClient } from '@/utils/UodkaClients/Core/clients';
import {
    MemberInUser,
    getUserWithMembersFromGql,
    getUserFromGql,
    getMemberInUserFromGql,
} from '@/utils/UodkaClients/Core/models';

export const initUodkaUserClient = async (input: { firebaseUserIdToken: string }) => {
    const graphqlSdk = getGraphqlSdk(input);
    const { user } = await graphqlSdk.user().catch(({ message }) => {
        // later: ここでエラーハンドリング
        console.error('message->', message);
        throw new Error('initUodkaUserClient: invalid firebaseUserIdToken');
    });
    if (user) {
        return new UodkaUserClient({
            graphqlSdk,
            ...getUserFromGql(user).data,
        });
    }
    throw new Error('initUodkaUserClient: No user account');
};

export const initUodkaUserClientWithMembers = async (input: { firebaseUserIdToken: string }) => {
    const graphqlSdk = getGraphqlSdk(input);
    const { userWithBusinessMembers } = await graphqlSdk.userWithBusinessMembers().catch(({ message }) => {
        // later: ここでエラーハンドリング
        console.log('message->', message);
        throw new Error('initUodkaUserClientWithMembers: invalid firebaseUserIdToken');
    });
    if (userWithBusinessMembers) {
        const userClient = new UodkaUserClient({
            graphqlSdk,
            ...getUserWithMembersFromGql(userWithBusinessMembers).data,
        });
        const members = userWithBusinessMembers.businessMembers.map((businessMember) =>
            getMemberInUserFromGql(businessMember)
        );
        return {
            userClient,
            members,
        };
    }
    throw new Error('initUodkaUserClientWithMembers: No user account');
};
