import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5227 3.44464L9.68229 0.495606C9.3776 0.179262 8.96458 0 8.53464 0H1.625C0.727865 0.00351494 0 0.759227 0 1.69069V16.3128C0 17.2443 0.727865 18 1.625 18H11.375C12.2721 18 13 17.2443 13 16.3128V4.63972C13 4.19332 12.8273 3.76098 12.5227 3.44464ZM11.7576 4.24253C11.8286 4.31634 11.876 4.40422 11.8997 4.50264H8.66667V1.14587C8.76146 1.17047 8.84609 1.21968 8.91719 1.2935L11.7576 4.24253ZM11.375 16.8752H1.625C1.32708 16.8752 1.08333 16.6221 1.08333 16.3128V1.69069C1.08333 1.38137 1.32708 1.1283 1.625 1.1283H7.58333V4.78383C7.58333 5.25132 7.94557 5.62742 8.39583 5.62742H11.9167V16.3128C11.9167 16.6221 11.6729 16.8752 11.375 16.8752ZM10.6302 10.8576C10.1732 10.3902 8.76823 10.5343 8.13516 10.6221C7.41745 10.1722 6.94349 9.5536 6.60833 8.63269C6.75391 8 7.01458 6.97364 6.825 6.35149C6.67604 5.3638 5.48099 5.4833 5.3151 6.11248C5.14583 6.75571 5.30495 7.67311 5.59948 8.8471C5.19661 9.84534 4.59401 11.1986 4.17422 11.9613C3.47005 12.3374 2.34271 12.9912 2.18359 13.8032C2.0651 14.3937 2.95885 15.1881 3.98125 14.0281C4.28932 13.6801 4.63464 13.1564 5.04089 12.4288C5.94479 12.1195 6.9401 11.7329 7.81693 11.5852C8.55833 12.007 9.42839 12.2847 10.0039 12.2847C10.9417 12.2882 10.9823 11.2232 10.6302 10.8576ZM2.87083 13.9859C3.07057 13.4271 3.83906 12.7768 4.07266 12.5518C3.32448 13.7926 2.87083 14.0105 2.87083 13.9859ZM6.09375 6.17223C6.38828 6.17223 6.35781 7.49033 6.16484 7.84534C5.9888 7.27241 5.99557 6.17223 6.09375 6.17223ZM5.13229 11.7715C5.51484 11.0756 5.84323 10.2531 6.10729 9.53251C6.43568 10.1547 6.85547 10.6468 7.29557 10.9912C6.47292 11.1564 5.75859 11.522 5.13229 11.7715ZM10.3255 11.5641C10.3255 11.5641 10.1292 11.8102 8.85287 11.2443C10.2409 11.1388 10.4677 11.4692 10.3255 11.5641Z"
                fill="currentColor"
            />
        </svg>
    );
};
Svg.displayName = 'svg/pdf';
export default Svg;
