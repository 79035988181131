import { FC, useState } from 'react';

import { UodkaCoreClientForOwner } from '@/utils/UodkaClients/Core';
import { useLanguage } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import { MutationFormModal } from '@/components/1_mol/FormModal';
import { TextInput } from '@/components/0_atom/Input';

import SvgPlus from '@/svgs/fa-plus-regular';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding: '7px 9px',
        justifyContent: 'flex-start',
        fontWeight: 600,
        '& .MuiButton-startIcon': {
            width: '28px !important',
            height: '28px !important',
            margin: '0 11px 0 0',
            '& svg': {
                width: 14,
                height: 14,
            },
        },
    },
}));

export const AddContactButton: FC<{
    coreClient: UodkaCoreClientForOwner;
    refetchContacts: () => void;
}> = ({ coreClient, refetchContacts }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState<string | undefined>(undefined);

    // DATA
    const initializeForm = () => {
        setName(undefined);
    };
    const run = async () => {
        if (name) {
            const { result } = await coreClient.addContact({
                name,
            });
            refetchContacts();
            return result;
        }
        throw new Error('AddContactButton.run: !name');
    };

    return (
        <>
            <Button className={c.root} onClick={() => setIsOpen(true)} startIcon={<SvgPlus />} fullWidth>
                {txt({ en: 'Add Contact', ja: '追加' })}
            </Button>
            <MutationFormModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={txt({ en: 'Add New Contact', ja: '新しいコンタクトを追加' })}
                mutation={{
                    description: txt({
                        en: `Add new contact "${name}"`,
                        ja: `新しいコンタクト「${name}」を追加`,
                    }),
                    run,
                }}
                initializeForm={initializeForm}
                items={[
                    {
                        isRequired: true,
                        labelText: txt({ en: 'Name', ja: '名称' }),
                        isSatisfied: !!name,
                        children: <TextInput value={name} setValue={setName} isSatisfied={!!name} />,
                    },
                ]}
                submitText={txt({ en: 'Add', ja: '追加' })}
            />
        </>
    );
};
AddContactButton.displayName = 'AddContactButton';
