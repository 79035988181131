import { createHash } from 'crypto';
import { GraphQLSdk as CertSdk } from '@/utils/UodkaClients/Cert/graphql/graphqlSdk';
import { Signature } from '@/utils/UodkaClients/Cert/models';
import { BillSignatures } from '@/utils/UodkaClients/Invoices/models/BillSignatures';

export const getHexDigest = (text: string) => {
    return createHash('sha512').update(text).digest('hex');
};

export const verifySignature = async ({ certSdk, signature }: { signature: Signature; certSdk: CertSdk }) => {
    const { verifySignature: result } = await certSdk.verifySignature({
        input: {
            resourceId: signature.resourceId,
            contactId: signature.contactId,
            signatureId: signature.id,
            signerId: signature.signerId,
            text: signature.text,
            unixTime: String(signature.unixTime),
        },
    });
    return result.isValid;
};

export const getResourceId = ({ billSignatures }: { billSignatures: BillSignatures }) => {
    const { issuer, recipient } = billSignatures;
    const givenItem = [issuer.issue, issuer.revoke, recipient.receive, recipient.revoke].find(
        (item) => item && item.signature.resourceId
    );
    if (!givenItem) {
        throw new Error('getResourceId: Got no signature.');
    }
    const isAllIdsSame = ![issuer.issue, issuer.revoke, recipient.receive, recipient.revoke].find(
        (item) => item && item.signature.resourceId !== givenItem.signature.resourceId
    );
    if (!isAllIdsSame) {
        throw new Error('getResourceId: Signatures have different resourceIds.');
    }
    return givenItem.signature.resourceId;
};
