import { createContext, useContext } from 'react';

import {
    UodkaCoreClientForObserver,
    UodkaCoreClientForGeneral,
    UodkaCoreClientForOwner,
    ClientDataInBusinessMember,
} from '@/utils/UodkaClients/Core';
import {
    UodkaProfileClientForAnonymous,
    UodkaProfileClientForObserver,
    UodkaProfileClientForGeneral,
    UodkaProfileClientForOwner,
} from '@/utils/UodkaClients/Profile';
import {
    UodkaCertClientForObserver,
    UodkaCertClientForGeneral,
    UodkaCertClientForOwner,
} from '@/utils/UodkaClients/Cert';
import {
    UodkaInvoicesClientForObserver,
    UodkaInvoicesClientForGeneral,
    UodkaInvoicesClientForOwner,
} from '@/utils/UodkaClients/Invoices';

// Anonymousは、BusinessCtxで持つことを考えれば不要。
export type InitializeClient = (input: { businessId: string; role: 'observer' | 'general' | 'owner' }) => void | never;
interface BusinessCtxType {
    clients: {
        core?: UodkaCoreClientForObserver | UodkaCoreClientForGeneral | UodkaCoreClientForOwner;
        profile?:
            | UodkaProfileClientForAnonymous
            | UodkaProfileClientForObserver
            | UodkaProfileClientForGeneral
            | UodkaProfileClientForOwner;
        cert?: UodkaCertClientForObserver | UodkaCertClientForGeneral | UodkaCertClientForOwner;
        invoices?: UodkaInvoicesClientForObserver | UodkaInvoicesClientForGeneral | UodkaInvoicesClientForOwner;
    };
    isFetching: boolean;
    reInitialize: InitializeClient;
    currentBusiness?: ClientDataInBusinessMember;
}
export const BusinessCtx = createContext<BusinessCtxType>({
    clients: {},
    isFetching: true,
    reInitialize: () => undefined,
});
export const useBusinessCtx = () => {
    return useContext(BusinessCtx);
};
export const useBusinessCtxAbsolutely = () => {
    const {
        currentBusiness,
        clients: { core, profile, cert, invoices },
        ...otherProps
    } = useBusinessCtx();
    if (!currentBusiness || !core || !profile || !cert || !invoices) {
        throw new Error('useBusinessCtxAbsolutely: !currentBusiness || !core || !profile || !cert || !invoices');
    }
    return {
        currentBusiness,
        clients: { core, profile, cert, invoices },
        ...otherProps,
    };
};
