import { FC, useState } from 'react';

import { ContactAPIForOwner } from '@/utils/UodkaClients/Core';
import { useLanguage } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import ConfirmationModal from '@/components/1_mol/ConfirmationModal';

import SvgTrash from '@/svgs/trash';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: 4,
    },
}));

export const DeleteContactButton: FC<{
    contactAPI: ContactAPIForOwner;
    removeContactItem: (id: string) => void;
}> = ({ contactAPI, removeContactItem }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [isOpen, setIsOpen] = useState(false);

    // DATA
    const run = async () => {
        const result = await contactAPI.archive();
        removeContactItem(contactAPI.id);
        return result;
    };

    return (
        <>
            <Button onClick={() => setIsOpen(true)} startIcon={<SvgTrash />} className={c.button} color={'error'}>
                {txt({ en: 'Delete Contact', ja: 'コンタクトを削除' })}
            </Button>
            <ConfirmationModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={txt({ en: `Delete contact "${contactAPI.name}"`, ja: `コンタクト「${contactAPI.name}」を削除` })}
                mutation={{
                    description: txt({
                        en: `Delete contact "${contactAPI.name}"`,
                        ja: `コンタクト「${contactAPI.name}」を削除`,
                    }),
                    run,
                }}
                submitText={txt({ en: 'Delete', ja: '削除' })}
                isWarning
                children={
                    <MuiTypography>
                        {txt({
                            en: 'Deleted contacts cannot be restored. Are you sure you want to continue?',
                            ja: '削除したコンタクトは元に戻せません。本当に削除しますか？',
                        })}
                    </MuiTypography>
                }
            />
        </>
    );
};
DeleteContactButton.displayName = 'DeleteContactButton';
