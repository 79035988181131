import { BillIssuerSignatures as GqlBillIssuerSignatures } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import {
    IssueingSignature,
    RevokingSignature,
    getIssueingSignatureFromGql,
    getRevokingSignatureFromGql,
} from '@/utils/UodkaClients/Invoices/models/Signature';

export interface BillIssuerSignaturesInterface {
    issue: IssueingSignature;
    revoke?: RevokingSignature;
}

export class BillIssuerSignatures implements BillIssuerSignaturesInterface {
    protected _issue: IssueingSignature;

    protected _revoke?: RevokingSignature;

    get issue() {
        return this._issue;
    }

    get revoke() {
        return this._revoke;
    }

    constructor({ issue, revoke }: BillIssuerSignaturesInterface) {
        this._issue = issue;
        this._revoke = revoke;
    }
}

export const getBillIssuerSignaturesFromGql = ({ issue, revoke }: GqlBillIssuerSignatures) => {
    return new BillIssuerSignatures({
        issue: getIssueingSignatureFromGql(issue),
        revoke: revoke ? getRevokingSignatureFromGql(revoke) : undefined,
    });
};
