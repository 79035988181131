import { FC, useState, useEffect } from 'react';
import clsx from 'clsx';

import { useLanguage } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import { TextInput } from '@/components/0_atom/Input';
import ColorPalette, { getIsValidHexColorCode } from '@/components/0_atom/ColorPalette';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {},
    // button: {
    //     height: 30,
    //     border: 'solid 1px rgba(0,0,0,.1)',
    // },
    // palletRow: {
    //     display: 'flex',
    //     '&:not(:last-child)': {
    //         marginBottom: 4,
    //     },
    // },
    // palletBlock: {
    //     flexGrow: 1,
    //     '&:not(:last-child)': {
    //         marginRight: 7,
    //     },
    // },
    // paper: {
    //     padding: '1em',
    // },
    inputBox: {
        '& > .MuiTypography-root': {
            display: 'inline',
        },
    },
    // paletteItem: {},
    paletteItem: {},
    preview: {
        width: 14,
        height: 14,
        borderRadius: 3,
        border: 'solid 2px rgba(0,0,0,.03)',
    },
}));

const WrappedColorPalette: FC<{
    hexNumber: string;
    setColor: (hexNumber: string) => void;
}> = ({ hexNumber, setColor }) => {
    // STYLE
    const c = useStyles(useTheme());

    return <ColorPalette className={c.paletteItem} color={`#${hexNumber}`} onClick={() => setColor(hexNumber)} />;
};

const ColorPicker: FC<{
    className?: string;
    color?: string;
    setColor: (newColor: string) => void;
}> = ({ className, color, setColor }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    // DATA
    const hexCode = color && getIsValidHexColorCode(color) ? color.toUpperCase() : undefined;

    // HANDLER
    const handleSetColor = (hexNumber: string) => {
        const extended = `#${hexNumber}`;
        const newHexCode = getIsValidHexColorCode(extended) ? extended : undefined;
        if (newHexCode) {
            setColor(newHexCode.toUpperCase());
        }
    };

    return (
        <div className={clsx(c.root, className)}>
            {/* <div className={c.palletRow}>
                <div className={c.palletBlock}>
                    <WrappedColorPalette hexNumber={'ff0000'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'ff3333'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'ff5555'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'ff7777'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'ffaaaa'} setColor={handleSetColor} />
                </div>
                <div className={c.palletBlock}>
                    <WrappedColorPalette hexNumber={'ffb000'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'ffb000'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'ffb000'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'ffb000'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'ffb000'} setColor={handleSetColor} />
                </div>
                <div className={c.palletBlock}>
                    <WrappedColorPalette hexNumber={'0000ff'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'3333ff'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'5555ff'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'7777ff'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'aaaaff'} setColor={handleSetColor} />
                </div>
                <div className={c.palletBlock}>
                    <WrappedColorPalette hexNumber={'000000'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'555555'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'aaaaaa'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'dddddd'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'ffffff'} setColor={handleSetColor} />
                </div>
            </div>
            <div className={c.palletRow}>
                <div className={c.palletBlock}>
                    <WrappedColorPalette hexNumber={'ff0000'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'00ff00'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'0000ff'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'00ff00'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'0000ff'} setColor={handleSetColor} />
                </div>
                <div className={c.palletBlock}>
                    <WrappedColorPalette hexNumber={'ff0000'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'00ff00'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'0000ff'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'00ff00'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'0000ff'} setColor={handleSetColor} />
                </div>
                <div className={c.palletBlock}>
                    <WrappedColorPalette hexNumber={'ff0000'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'00ff00'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'0000ff'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'00ff00'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'0000ff'} setColor={handleSetColor} />
                </div>
                <div className={c.palletBlock}>
                    <WrappedColorPalette hexNumber={'ff0000'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'00ff00'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'0000ff'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'00ff00'} setColor={handleSetColor} />
                    <WrappedColorPalette hexNumber={'0000ff'} setColor={handleSetColor} />
                </div>
            </div> */}
            <div className={c.inputBox}>
                <TextInput
                    value={hexCode ? hexCode.replace(/^#/, '') : ''}
                    setValue={(v) => handleSetColor(v || '')}
                    placeholder={txt({ en: 'HEX color code', ja: 'HEXカラーコード' })}
                    startAdornment={'#'}
                    endAdornment={<div className={c.preview} style={{ background: hexCode }} />}
                />
            </div>
        </div>
    );
};
ColorPicker.displayName = 'ColorPicker';
export default ColorPicker;
