import { useEffect, useCallback } from 'react';

import { UodkaCoreClientForOwner, MemberAPIForOwner } from '@/utils/UodkaClients/Core';
import { useLazyItems } from '@/utils/customHooks';

export interface MemberItem {
    id: string;
    memberAPI: MemberAPIForOwner;
}

export const useMemberItems = ({ coreClient }: { coreClient: UodkaCoreClientForOwner }) => {
    // HOOKS
    const { items, setLazyItems } = useLazyItems<MemberItem>();
    const fetchMembers = useCallback(async () => {
        const lazyItems = await (async () => {
            const memberAPIs = await coreClient.getMemberAPIs();
            return memberAPIs.map((memberAPI) => ({
                id: memberAPI.id,
                memberAPI,
            }));
        })();
        setLazyItems(lazyItems);
    }, [coreClient, setLazyItems]);

    // USEEFFECT
    useEffect(() => {
        fetchMembers();
    }, [fetchMembers]);

    return {
        items,
        refetchMembers: fetchMembers,
    };
};
