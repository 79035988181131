import { FC } from 'react';

import { Payee } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';
import { BankAccountText } from '@/components/2_org/BankAccountCard';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: 42,
    },
    sectionTitle: {
        fontSize: theme.typography.caption.fontSize,
        marginBottom: 2,
        paddingLeft: 2,
    },
    card: {
        border: 'solid 2px rgba(0,0,0,.03)',
        borderRadius: 4,
        // padding: '17px 21px',
        padding: 14,
        maxWidth: 240,
    },
    depositTip: {
        color: theme.palette.text.disabled,
        fontWeight: 600,
        fontSize: theme.typography.caption.fontSize,
        marginBottom: 10,
        marginTop: -4,
    },
    bankAccountText: {
        '& > div:nth-child(2)': {
            marginTop: 6,
        },
        '& > span:nth-child(2)': {
            marginTop: 2,
        },
    },
}));

export const PaymentDestination: FC<{
    payee: Payee;
    recipientName: string;
}> = ({ payee, recipientName }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt, language } = useLanguage();

    return (
        <div className={c.root}>
            <div className={c.sectionTitle}>{txt({ en: 'Please Make Payment To', ja: 'お支払い先' })}</div>
            <div className={c.card}>
                {payee.uodkaBankAccount.isDepositAccount && (
                    <div className={c.depositTip}>
                        {(!language || language === 'en') && (
                            <span>{txt({ en: 'Dedicated Deposit Account for ' })}</span>
                        )}
                        <span>{recipientName}</span>
                        {language === 'ja' && <span>{txt({ en: '', ja: '様 専用入金口座' })}</span>}
                    </div>
                )}
                <BankAccountText
                    className={c.bankAccountText}
                    bankAccount={payee.uodkaBankAccount}
                    isWithUodkaLogo={payee.uodkaBankAccount.isDepositAccount}
                />
            </div>
        </div>
    );
};
PaymentDestination.displayName = 'PaymentDestination';
