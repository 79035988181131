import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#fff',
        transition: 'margin .4s, box-shadow .2s',
        position: 'relative',
        zIndex: 1,
        boxShadow: '0 0px 4px 0 rgba(0, 0, 0, .02)',
        borderRadius: 4,
        '&:not(:last-child)': {
            marginBottom: 12,
        },
        '&:hover:not(.MuiPaper-elevation8)': {
            boxShadow: '0 0 8px 0 rgba(0,0,0,.08)',
        },
        '&.Mui-expanded': {
            '&:first-child': {
                marginTop: 17,
            },
            '&:not(:first-child)': {
                marginTop: 17,
            },
            '&:last-child': {
                marginBottom: 17,
            },
            '&:not(:last-child)': {
                marginBottom: 17,
            },
            zIndex: 2,
            boxShadow: '0 24px 32px 0 rgba(0, 0, 0, .1)',
        },
    },
    summary: {
        '& .MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
        },
        padding: '18px 21px',
    },
    details: {
        padding: '0 21px 24px 21px',
    },
    item: {
        transition: 'width .2s',
    },
}));

export interface ExpandableTableRowProps {
    items: {
        children: ReactNode;
    }[];
    isSelected?: boolean;
    expandedChildren: ReactNode;
    onClick: () => void;
}

const ExpandableTableRow: FC<
    ExpandableTableRowProps & { widths: (string | number)[]; expandedWidths: (string | number)[] }
> = ({ items, isSelected, expandedChildren, onClick, widths, expandedWidths }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <MuiAccordion
            elevation={isSelected ? 8 : 1}
            onClick={onClick}
            className={c.root}
            expanded={isSelected}
            TransitionProps={{
                timeout: {
                    appear: 500,
                    enter: 300,
                    exit: 500,
                },
            }}
        >
            <MuiAccordionSummary className={c.summary}>
                {items.map(({ children }, i) => (
                    <div key={i} style={{ width: isSelected ? expandedWidths[i] : widths[i] }} className={c.item}>
                        {children}
                    </div>
                ))}
            </MuiAccordionSummary>
            <MuiAccordionDetails className={c.details}>{expandedChildren}</MuiAccordionDetails>
        </MuiAccordion>
    );
};

ExpandableTableRow.displayName = 'ExpandableTableRow';
export default ExpandableTableRow;
