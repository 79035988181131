const fullWidthHiragana = [
    'あ',
    'い',
    'う',
    'え',
    'お',
    'か',
    'き',
    'く',
    'け',
    'こ',
    'さ',
    'し',
    'す',
    'せ',
    'そ',
    'た',
    'ち',
    'つ',
    'て',
    'と',
    'な',
    'に',
    'ぬ',
    'ね',
    'の',
    'は',
    'ひ',
    'ふ',
    'へ',
    'ほ',
    'ま',
    'み',
    'む',
    'め',
    'も',
    'や',
    'ゆ',
    'よ',
    'ら',
    'り',
    'る',
    'れ',
    'ろ',
    'わ',
    'を',
    'ん',
    'が',
    'ぎ',
    'ぐ',
    'げ',
    'ご',
    'ざ',
    'じ',
    'ず',
    'ぜ',
    'ぞ',
    'だ',
    'ぢ',
    'づ',
    'で',
    'ど',
    'ば',
    'び',
    'ぶ',
    'べ',
    'ぼ',
    'ぱ',
    'ぴ',
    'ぷ',
    'ぺ',
    'ぽ',
    'ぁ',
    'ぃ',
    'ぅ',
    'ぇ',
    'ぉ',
    'ゃ',
    'ゅ',
    'ょ',
    'っ',
    'ゔ',
    'ー',
    '（',
    '）',
    ' ',
    '／',
];
const fullWidthKatakana = [
    'ア',
    'イ',
    'ウ',
    'エ',
    'オ',
    'カ',
    'キ',
    'ク',
    'ケ',
    'コ',
    'サ',
    'シ',
    'ス',
    'セ',
    'ソ',
    'タ',
    'チ',
    'ツ',
    'テ',
    'ト',
    'ナ',
    'ニ',
    'ヌ',
    'ネ',
    'ノ',
    'ハ',
    'ヒ',
    'フ',
    'ヘ',
    'ホ',
    'マ',
    'ミ',
    'ム',
    'メ',
    'モ',
    'ヤ',
    'ユ',
    'ヨ',
    'ラ',
    'リ',
    'ル',
    'レ',
    'ロ',
    'ワ',
    'ヲ',
    'ン',
    'ガ',
    'ギ',
    'グ',
    'ゲ',
    'ゴ',
    'ザ',
    'ジ',
    'ズ',
    'ゼ',
    'ゾ',
    'ダ',
    'ヂ',
    'ヅ',
    'デ',
    'ド',
    'バ',
    'ビ',
    'ブ',
    'ベ',
    'ボ',
    'パ',
    'ピ',
    'プ',
    'ペ',
    'ポ',
    'ァ',
    'ィ',
    'ゥ',
    'ェ',
    'ォ',
    'ャ',
    'ュ',
    'ョ',
    'ッ',
    'ヴ',
    'ー',
    '（',
    '）',
    ' ',
    '／',
];
const halfWidthKatakana = [
    'ｱ',
    'ｲ',
    'ｳ',
    'ｴ',
    'ｵ',
    'ｶ',
    'ｷ',
    'ｸ',
    'ｹ',
    'ｺ',
    'ｻ',
    'ｼ',
    'ｽ',
    'ｾ',
    'ｿ',
    'ﾀ',
    'ﾁ',
    'ﾂ',
    'ﾃ',
    'ﾄ',
    'ﾅ',
    'ﾆ',
    'ﾇ',
    'ﾈ',
    'ﾉ',
    'ﾊ',
    'ﾋ',
    'ﾌ',
    'ﾍ',
    'ﾎ',
    'ﾏ',
    'ﾐ',
    'ﾑ',
    'ﾒ',
    'ﾓ',
    'ﾔ',
    'ﾕ',
    'ﾖ',
    'ﾗ',
    'ﾘ',
    'ﾙ',
    'ﾚ',
    'ﾛ',
    'ﾜ',
    'ｦ',
    'ﾝ',
    'ｶﾞ',
    'ｷﾞ',
    'ｸﾞ',
    'ｹﾞ',
    'ｺﾞ',
    'ｻﾞ',
    'ｼﾞ',
    'ｽﾞ',
    'ｾﾞ',
    'ｿﾞ',
    'ﾀﾞ',
    'ﾁﾞ',
    'ﾂﾞ',
    'ﾃﾞ',
    'ﾄﾞ',
    'ﾊﾞ',
    'ﾋﾞ',
    'ﾌﾞ',
    'ﾍﾞ',
    'ﾎﾞ',
    'ﾊﾟ',
    'ﾋﾟ',
    'ﾌﾟ',
    'ﾍﾟ',
    'ﾎﾟ',
    'ｧ',
    'ｨ',
    'ｩ',
    'ｪ',
    'ｫ',
    'ｬ',
    'ｭ',
    'ｮ',
    'ｯ',
    'ｳﾞ',
    'ｰ',
    '(',
    ')',
    ' ',
    '/',
];

type Hiragana = string;
type FullWidthKatakatana = string;
type HalfWidthKatakatana = string;

const getIndexes = ({
    kana,
    vocabulary,
}: {
    kana: Hiragana | FullWidthKatakatana | HalfWidthKatakatana;
    vocabulary: typeof fullWidthHiragana | typeof fullWidthKatakana | typeof halfWidthKatakana;
}) => {
    const kanaArr = [...kana].reduce((acc, prev) => {
        if (prev === 'ﾞ' || prev === 'ﾟ') {
            return acc.map((item, i) => {
                if (i === acc.length - 1) {
                    return item + prev;
                }
                return item;
            });
        }
        if (prev === '-') {
            // GMOあおぞらのAPIがハイフンで扱うため
            return [...acc, 'ｰ'];
        }
        if (prev === '　') {
            // 全角スペースを半角スペースへ
            return [...acc, ' '];
        }
        return [...acc, prev];
    }, [] as string[]);
    const indexes = kanaArr.map((character) => vocabulary.indexOf(character));
    const missingIndex = indexes.find((index) => index === -1);
    if (missingIndex !== undefined) {
        return undefined;
    }
    return indexes;
};
export const getKana = (input: Hiragana | FullWidthKatakatana | HalfWidthKatakatana) => {
    const indexes = (() => {
        const hiraganaIndexes = getIndexes({ kana: input, vocabulary: fullWidthHiragana });
        if (hiraganaIndexes) {
            return hiraganaIndexes;
        }
        const fullWidthKatakanaIndexes = getIndexes({ kana: input, vocabulary: fullWidthKatakana });
        if (fullWidthKatakanaIndexes) {
            return fullWidthKatakanaIndexes;
        }
        const halfWidthKatakanaIndexes = getIndexes({ kana: input, vocabulary: halfWidthKatakana });
        if (halfWidthKatakanaIndexes) {
            return halfWidthKatakanaIndexes;
        }
        return undefined;
    })();
    if (indexes) {
        return {
            hiragana: indexes.map((index) => fullWidthHiragana[index]).reduce((acc, prev) => acc + prev, ''),
            fullWidthKatakana: indexes.map((index) => fullWidthKatakana[index]).reduce((acc, prev) => acc + prev, ''),
            halfWidthKatakana: indexes.map((index) => halfWidthKatakana[index]).reduce((acc, prev) => acc + prev, ''),
        };
    }
    return undefined;
};
