import { ContactWithInvite as GqlContactWithInvite } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import {
    ExternalBankAccount,
    BankAccountInTransferInterface,
    getExternalBankAccountFromGql,
} from '@/utils/UodkaClients/Core/models/BankAccount';
import { ClientDataInContact, getClientDataInContactFromGql } from '@/utils/UodkaClients/Core/models/ClientData';
import {
    InviteForContactInContact,
    getInviteForContactInContactFromGql,
} from '@/utils/UodkaClients/Core/models/InviteForContact';
import {
    DepositAccountInContact,
    getDepositAccountInContactFromGql,
} from '@/utils/UodkaClients/Core/models/DepositAccount';

export interface ContactWithInviteInterface {
    id: string;
    additionalId?: string;
    name: string;
    notificationEmail?: string;
    externalBankAccount?: ExternalBankAccount;
    depositAccountFromContactToMe?: DepositAccountInContact;
    relatedBusiness?: ClientDataInContact;
    invite?: InviteForContactInContact;
    isArchived: boolean;
}

export class ContactWithInvite implements ContactWithInviteInterface {
    protected _id: string;

    protected _additionalId?: string;

    protected _name: string;

    protected _notificationEmail?: string;

    protected _externalBankAccount?: ExternalBankAccount;

    protected _depositAccountFromContactToMe?: DepositAccountInContact;

    protected _relatedBusiness?: ClientDataInContact;

    protected _invite?: InviteForContactInContact;

    protected _isArchived: boolean;

    get id() {
        return this._id;
    }

    get additionalId() {
        return this._additionalId;
    }

    get name() {
        return this._name;
    }

    get notificationEmail() {
        return this._notificationEmail;
    }

    get externalBankAccount() {
        return this._externalBankAccount;
    }

    get relatedBusiness() {
        return this._relatedBusiness;
    }

    get depositAccountFromContactToMe() {
        return this._depositAccountFromContactToMe;
    }

    get invite() {
        return this._invite;
    }

    get isArchived() {
        return this._isArchived;
    }

    get transferDestination(): BankAccountInTransferInterface | undefined {
        const { relatedBusiness, externalBankAccount } = this;
        if (relatedBusiness) {
            if (relatedBusiness) {
                if (relatedBusiness.externalBankAccount) {
                    return {
                        ...relatedBusiness.externalBankAccount.data,
                        isDepositAccount: false,
                    };
                }
                if (relatedBusiness.depositAccountFromMeToContact) {
                    return {
                        ...relatedBusiness.depositAccountFromMeToContact.data,
                        isDepositAccount: true,
                    };
                }
                return undefined;
            }
        }
        if (externalBankAccount) {
            return {
                ...externalBankAccount.data,
                isDepositAccount: false,
            };
        }
        return undefined;
    }

    get data() {
        return {
            id: this.id,
            additionalId: this.additionalId,
            name: this.name,
            notificationEmail: this.notificationEmail,
            externalBankAccount: this.externalBankAccount,
            depositAccountFromContactToMe: this.depositAccountFromContactToMe,
            relatedBusiness: this.relatedBusiness,
            invite: this.invite,
            isArchived: this.isArchived,
        };
    }

    constructor({
        id,
        additionalId,
        name,
        notificationEmail,
        externalBankAccount,
        depositAccountFromContactToMe,
        relatedBusiness,
        invite,
        isArchived,
    }: ContactWithInviteInterface) {
        this._id = id;
        this._additionalId = additionalId;
        this._name = name;
        this._notificationEmail = notificationEmail;
        this._externalBankAccount = externalBankAccount;
        this._depositAccountFromContactToMe = depositAccountFromContactToMe;
        this._relatedBusiness = relatedBusiness;
        this._invite = invite;
        this._isArchived = isArchived;
    }
}

export const getContactWithInviteFromGql = ({
    id,
    additionalId,
    name,
    notificationEmail,
    externalBankAccount,
    depositAccountFromContactToMe,
    relatedBusiness,
    invite,
    isArchived,
}: GqlContactWithInvite) => {
    return new ContactWithInvite({
        id,
        additionalId: additionalId || undefined,
        name,
        notificationEmail: notificationEmail || undefined,
        externalBankAccount: externalBankAccount ? getExternalBankAccountFromGql(externalBankAccount) : undefined,
        depositAccountFromContactToMe: depositAccountFromContactToMe
            ? getDepositAccountInContactFromGql(depositAccountFromContactToMe)
            : undefined,
        relatedBusiness: relatedBusiness ? getClientDataInContactFromGql(relatedBusiness) : undefined,
        invite: invite ? getInviteForContactInContactFromGql(invite) : undefined,
        isArchived,
    });
};
