import { Transfer, getTransferFromGql } from '@/utils/UodkaClients/Core';
import { GqlDateTimeData } from '@/utils/UodkaClients/Invoices/graphql/customScalars';
import { PaymentInReceivedBill as GqlPaymentInReceivedBill } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';
import { PaymentData, getPaymentDataFromGql } from '@/utils/UodkaClients/Invoices/models/InvoiceData';

interface PaymentInReceivedBillInterface {
    id: string;
    createdAt: Date;
    paymentData: PaymentData;
    transfer: Transfer;
}

export class PaymentInReceivedBill implements PaymentInReceivedBillInterface {
    protected _id: string;

    protected _createdAt: Date;

    protected _paymentData: PaymentData;

    protected _transfer: Transfer;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get paymentData() {
        return this._paymentData;
    }

    get transfer() {
        return this._transfer;
    }

    constructor({ id, createdAt, paymentData, transfer }: PaymentInReceivedBillInterface) {
        this._id = id;
        this._createdAt = createdAt;
        this._paymentData = paymentData;
        this._transfer = transfer;
    }
}

export const getPaymentInReceivedBillFromGql = ({ id, createdAt, paymentData, transfer }: GqlPaymentInReceivedBill) => {
    return new PaymentInReceivedBill({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        paymentData: getPaymentDataFromGql(paymentData),
        transfer: getTransferFromGql(transfer),
    });
};
