import { BankAccountInTransfer } from '@/utils/UodkaClients/Core';
import { useBalanceWithInvoiceItems, useLanguage } from '@/utils/customHooks';

type FieldItemKind = 'revenue' | 'cost' | 'paidTax' | 'receivedTax' | 'selfdeposit';
export type FieldItem = {
    contactId?: string;
    contactName?: string;
    itemName: string;
    kind: FieldItemKind;
    selfDepositAmount?: number;
    receivedBillAmount?: number;
    issuedBillAmount?: number;
    issuedPaymentAmount?: number;
    receivedPaymentAmount?: number;
    bankAccount: BankAccountInTransfer | undefined;
    billDate?: Date;
    paymentDate: Date;
};

export const getKindName = ({
    kind,
    txt,
}: {
    kind: FieldItemKind;
    txt: (input: { en: string; ja?: string }) => string;
}) => {
    const mapping = {
        revenue: {
            en: 'Revenue',
            ja: '収益',
        },
        cost: {
            en: 'Cost',
            ja: '費用',
        },
        paidTax: {
            en: 'Paid Tax',
            ja: '支払税金',
        },
        receivedTax: {
            en: 'Received Tax',
            ja: '受取税金',
        },
        selfdeposit: {
            en: 'Self Deposit',
            ja: '自己入金',
        },
    };
    const selectedMap = mapping[kind] || {
        en: 'Other',
        ja: 'その他',
    };
    return txt(selectedMap);
};

export const useAccountingItems = () => {
    // CUSTOME HOOKS
    const { items: balanceWithInvoiceItems } = useBalanceWithInvoiceItems({
        filter: {
            contactId: undefined,
            type: undefined,
        },
    });
    const { txt } = useLanguage();

    // DATA
    const { displayItems, balanceWithInvoices } = (() => {
        const items = balanceWithInvoiceItems?.ids.map((id) => balanceWithInvoiceItems.getItemAbsolutely(id));
        if (items) {
            const fieledItems: FieldItem[] = items.reduce((acc, prev) => {
                const newItems: FieldItem[] = (() => {
                    if (prev.balanceWithInvoice.balance.charge) {
                        if (prev.balanceWithInvoice.balance.charge.depositAccount.fromContact) {
                            const contactId = prev.balanceWithInvoice.balance.charge.depositAccount.fromContact?.id;
                            const contactName = prev.balanceWithInvoice.balance.charge.depositAccount.fromContact?.name;
                            const bankAccount = {
                                ...prev.balanceWithInvoice.balance.charge.depositAccount.data,
                                isDepositAccount: true,
                            };
                            const paymentDate = prev.balanceWithInvoice.balance.charge.createdAt;
                            const bill = prev.balanceWithInvoice.receivedPayment?.bill;
                            if (bill) {
                                const items: FieldItem[] = bill.billData.items.map(
                                    ({ title, unitPrice, quantity }) => ({
                                        contactId,
                                        contactName,
                                        itemName: title,
                                        bankAccount: new BankAccountInTransfer(bankAccount),
                                        paymentDate,
                                        kind: 'revenue' as const,
                                        issuedBillAmount: unitPrice * quantity,
                                        receivedPaymentAmount: unitPrice * quantity,
                                        billDate: bill.createdAt,
                                    })
                                );
                                const taxes: FieldItem[] = bill.billData.summary.taxes.map(({ title, value }) => ({
                                    contactId,
                                    contactName,
                                    itemName: title,
                                    bankAccount: new BankAccountInTransfer(bankAccount),
                                    paymentDate,
                                    kind: value >= 0 ? ('receivedTax' as const) : ('paidTax' as const),
                                    issuedBillAmount: value,
                                    issuedPaymentAmount: value >= 0 ? undefined : Math.abs(value),
                                    receivedPaymentAmount: value >= 0 ? value : undefined,
                                    billDate: bill.createdAt,
                                }));
                                return [...items, ...taxes];
                            }
                            return [
                                {
                                    contactId,
                                    contactName,
                                    itemName: '-',
                                    bankAccount: new BankAccountInTransfer(bankAccount),
                                    paymentDate,
                                    kind: 'revenue' as const,
                                    receivedPaymentAmount: prev.balanceWithInvoice.balance.charge.amount,
                                },
                            ];
                        }
                        const bankAccount = {
                            ...prev.balanceWithInvoice.balance.charge.depositAccount.data,
                            isDepositAccount: true,
                        };
                        const paymentDate = prev.balanceWithInvoice.balance.charge.createdAt;
                        return [
                            {
                                itemName: txt({ en: 'Self Deposit', ja: '自己入金' }),
                                bankAccount: new BankAccountInTransfer(bankAccount),
                                paymentDate,
                                kind: 'selfdeposit' as const,
                                selfDepositAmount: prev.balanceWithInvoice.balance.charge.amount,
                            },
                        ];
                    }
                    if (prev.balanceWithInvoice.balance.transfer) {
                        const paymentDate = prev.balanceWithInvoice.balance.transfer.issuedAt;
                        if (!paymentDate) {
                            return [];
                        }
                        const contactId = prev.balanceWithInvoice.balance.transfer.contact.id;
                        const contactName = prev.balanceWithInvoice.balance.transfer.contact.name;
                        const { bankAccount } = prev.balanceWithInvoice.balance.transfer;
                        const bill = prev.balanceWithInvoice.issuedPayment?.bill;
                        const feeItems: FieldItem[] = (() => {
                            if (prev.balanceWithInvoice.balance.transfer.feeOnIssuer.total) {
                                return [
                                    {
                                        contactId,
                                        contactName,
                                        itemName: txt({ en: 'Remittance Fee', ja: '送金手数料' }),
                                        bankAccount: undefined,
                                        paymentDate,
                                        kind: 'cost' as const,
                                        issuedPaymentAmount:
                                            prev.balanceWithInvoice.balance.transfer.feeOnIssuer.priceBeforeTax,
                                    },
                                    {
                                        contactId,
                                        contactName,
                                        itemName: 'consumptionTax (10%)',
                                        bankAccount: undefined,
                                        paymentDate,
                                        kind: 'paidTax' as const,
                                        issuedPaymentAmount:
                                            prev.balanceWithInvoice.balance.transfer.feeOnIssuer.total -
                                            prev.balanceWithInvoice.balance.transfer.feeOnIssuer.priceBeforeTax,
                                    },
                                ];
                            }
                            return [];
                        })();

                        if (bill) {
                            const items: FieldItem[] = bill.billData.items.map(({ title, unitPrice, quantity }) => ({
                                contactId,
                                contactName,
                                itemName: title,
                                bankAccount: new BankAccountInTransfer(bankAccount),
                                paymentDate,
                                kind: 'cost' as const,
                                receivedBillAmount: unitPrice * quantity,
                                issuedPaymentAmount: unitPrice * quantity,
                                billDate: bill.createdAt,
                            }));
                            const taxes: FieldItem[] = bill.billData.summary.taxes.map(({ title, value }) => ({
                                contactId,
                                contactName,
                                itemName: title,
                                bankAccount: new BankAccountInTransfer(bankAccount),
                                paymentDate,
                                kind: value >= 0 ? ('paidTax' as const) : ('receivedTax' as const),
                                receivedBillAmount: value,
                                issuedPaymentAmount: value >= 0 ? value : undefined,
                                receivedPaymentAmount: value >= 0 ? undefined : Math.abs(value),
                                billDate: bill.createdAt,
                            }));
                            return [...items, ...taxes, ...feeItems];
                        }
                        const item = {
                            contactId,
                            contactName,
                            itemName: '-',
                            bankAccount: new BankAccountInTransfer(bankAccount),
                            paymentDate,
                            kind: 'cost' as const,
                            issuedPaymentAmount: prev.balanceWithInvoice.balance.transfer.amount,
                        };
                        return [item, ...feeItems];
                    }
                    throw new Error('useAccountingItems.displayItems: Unexpectedly !charge && !transfer');
                })();
                return [...acc, ...newItems];
            }, [] as FieldItem[]);
            return {
                displayItems: fieledItems,
                balanceWithInvoices: items.map(({ balanceWithInvoice }) => balanceWithInvoice),
            };
        }
        return {
            displayItems: undefined,
            balanceWithInvoices: undefined,
        };
    })();

    return {
        accountingItems: displayItems,
        balanceWithInvoices,
    };
};
