import {
    Contact,
    ContactAPIForOwner,
    UodkaCoreClientForObserver,
    UodkaCoreClientForGeneral,
    UodkaCoreClientForOwner,
} from '@/utils/UodkaClients/Core';
import {
    UodkaInvoicesClientForObserver,
    UodkaInvoicesClientForGeneral,
    UodkaInvoicesClientForOwner,
} from '@/utils/UodkaClients/Invoices';
import { initUodkaProfileClientForAnonymous } from '@/utils/UodkaClients/Profile';
import { ContactItem } from '@/utils/customHooks';

import {
    ReceivedBillItem,
    ReceivedBillItemForObserver,
    ReceivedBillItemForGeneral,
    ReceivedBillItemForOwner,
} from '@/views/bills/useBillItems/fetchReceivedBill/ReceivedBillItem';

const fetchReceivedBillForOwner = async ({
    coreClient,
    invoicesClient,
    contactId,
    billId,
}: {
    coreClient: UodkaCoreClientForOwner;
    invoicesClient: UodkaInvoicesClientForOwner;
    contactId: string;
    billId: string;
}): Promise<ReceivedBillItemForOwner | undefined> => {
    const receivedBill = await invoicesClient.getReceivedBillAPI({ contactId, billId }).catch(() => undefined);
    if (!receivedBill) {
        return undefined;
    }
    const contactItem: ContactItem<ContactAPIForOwner> = await (async () => {
        const profileClient = initUodkaProfileClientForAnonymous();
        const fetchProfle = async () => {
            if (receivedBill.contact.relatedBusiness) {
                return profileClient.getProfileData({ businessId: receivedBill.contact.relatedBusiness.id });
            }
            return undefined;
        };
        const [contactAPI, contactProfile] = await Promise.all([
            coreClient.getContactAPI({ id: contactId }),
            fetchProfle(),
        ]);
        if (!contactAPI) {
            throw new Error('fetchReceivedBillForOwner: Unexpectedly (!contactAPI)');
        }
        return {
            id: contactAPI.id,
            contact: contactAPI,
            profile: {
                data: contactProfile,
                refetch: fetchProfle,
            },
        };
    })();
    return {
        id: receivedBill.id,
        receivedBill,
        contactItem,
    };
};

const fetchReceivedBillForGeneral = async ({
    coreClient,
    invoicesClient,
    contactId,
    billId,
}: {
    coreClient: UodkaCoreClientForGeneral;
    invoicesClient: UodkaInvoicesClientForGeneral;
    contactId: string;
    billId: string;
}): Promise<ReceivedBillItemForGeneral | undefined> => {
    const receivedBill = await invoicesClient.getReceivedBillAPI({ contactId, billId }).catch(() => undefined);
    if (!receivedBill) {
        return undefined;
    }
    const contactItem: ContactItem<Contact> = await (async () => {
        const profileClient = initUodkaProfileClientForAnonymous();
        const fetchProfle = async () => {
            if (receivedBill.contact.relatedBusiness) {
                return profileClient.getProfileData({ businessId: receivedBill.contact.relatedBusiness.id });
            }
            return undefined;
        };
        const [contactAPI, contactProfile] = await Promise.all([
            coreClient.getContactAPI({ id: contactId }),
            fetchProfle(),
        ]);
        if (!contactAPI) {
            throw new Error('fetchReceivedBillForGeneral: Unexpectedly (!contactAPI)');
        }
        return {
            id: contactAPI.id,
            contact: contactAPI,
            profile: {
                data: contactProfile,
                refetch: fetchProfle,
            },
        };
    })();
    return {
        id: receivedBill.id,
        receivedBill,
        contactItem,
    };
};

const fetchReceivedBillForObserver = async ({
    coreClient,
    invoicesClient,
    contactId,
    billId,
}: {
    coreClient: UodkaCoreClientForObserver;
    invoicesClient: UodkaInvoicesClientForObserver;
    contactId: string;
    billId: string;
}): Promise<ReceivedBillItemForObserver | undefined> => {
    const receivedBill = await invoicesClient.getReceivedBill({ contactId, billId }).catch(() => undefined);
    if (!receivedBill) {
        return undefined;
    }
    const contactItem: ContactItem<Contact> = await (async () => {
        const profileClient = initUodkaProfileClientForAnonymous();
        const fetchProfle = async () => {
            if (receivedBill.contact.relatedBusiness) {
                return profileClient.getProfileData({ businessId: receivedBill.contact.relatedBusiness.id });
            }
            return undefined;
        };
        const [contactAPI, contactProfile] = await Promise.all([
            coreClient.getContact({ id: contactId }),
            fetchProfle(),
        ]);
        if (!contactAPI) {
            throw new Error('fetchReceivedBillForObserver: Unexpectedly (!contactAPI)');
        }
        return {
            id: contactAPI.id,
            contact: contactAPI,
            profile: {
                data: contactProfile,
                refetch: fetchProfle,
            },
        };
    })();
    return {
        id: receivedBill.id,
        receivedBill,
        contactItem,
    };
};

export const fetchReceivedBill = async ({
    coreClient,
    invoicesClient,
    contactId,
    billId,
}: {
    coreClient: UodkaCoreClientForObserver | UodkaCoreClientForGeneral | UodkaCoreClientForOwner;
    invoicesClient: UodkaInvoicesClientForObserver | UodkaInvoicesClientForGeneral | UodkaInvoicesClientForOwner;
    contactId: string;
    billId: string;
}): Promise<ReceivedBillItem | undefined> => {
    if (coreClient instanceof UodkaCoreClientForOwner && invoicesClient instanceof UodkaInvoicesClientForOwner) {
        return fetchReceivedBillForOwner({ coreClient, invoicesClient, contactId, billId });
    }
    if (coreClient instanceof UodkaCoreClientForGeneral && invoicesClient instanceof UodkaInvoicesClientForGeneral) {
        return fetchReceivedBillForGeneral({ coreClient, invoicesClient, contactId, billId });
    }
    if (coreClient instanceof UodkaCoreClientForObserver && invoicesClient instanceof UodkaInvoicesClientForObserver) {
        return fetchReceivedBillForObserver({ coreClient, invoicesClient, contactId, billId });
    }
    throw new Error('fetchReceivedBill: unexpected case');
};
