import { FC } from 'react';

import { ClientData } from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import { TransferConfirmationForBillAPIForGeneral, IssuedPayment } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';
import ConfirmationModal from '@/components/1_mol/ConfirmationModal';
import FetchingText from '@/components/0_atom/FetchingText';

import { TransferConfirmationModalContent } from '@/views/bills/BillDrawer/ReceivedBill/PayToBillButton/TransferConfirmationModalContent';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
}));

export const TransferConfirmationModal: FC<{
    transferConfirmationAPI: TransferConfirmationForBillAPIForGeneral | undefined;
    setTransferConfirmationAPI: (transferConfirmationAPI: TransferConfirmationForBillAPIForGeneral | undefined) => void;
    contactInfo: {
        id: string;
        name: string;
        profile: BusinessProfile | undefined;
    };
    clientData: ClientData;
    clientProfile: BusinessProfile | undefined;
    currentBalance: number;
    onAddTransfer: (input: { payment: IssuedPayment }) => void;
}> = ({
    transferConfirmationAPI,
    setTransferConfirmationAPI,
    contactInfo,
    clientData,
    clientProfile,
    currentBalance,
    onAddTransfer,
}) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    // DATA
    const runAddTransfer = async () => {
        if (transferConfirmationAPI) {
            const { result, payment } = await transferConfirmationAPI.approve();
            setTransferConfirmationAPI(undefined);
            onAddTransfer({ payment });
            return result;
        }
        throw new Error('TransferConfirmationModal.runAddTransfer: !transferConfirmationAPI');
    };

    return (
        <ConfirmationModal
            isOpen={!!transferConfirmationAPI}
            onClose={() => setTransferConfirmationAPI(undefined)}
            title={txt({
                en: 'Are you sure to create following remittance?',
                ja: '以下の内容で送金します。よろしいですか？',
            })}
            mutation={{
                description: txt({
                    en: `Send ¥${transferConfirmationAPI?.amount.toLocaleString()} to ${contactInfo.name}`,
                    ja: `${contactInfo.name}に${transferConfirmationAPI?.amount.toLocaleString()}円を送金`,
                }),
                run: runAddTransfer,
            }}
            submitText={txt({ en: 'Remit', ja: '送金' })}
        >
            {transferConfirmationAPI && transferConfirmationAPI.contact.id === contactInfo.id ? (
                <TransferConfirmationModalContent
                    transferConfirmationAPI={transferConfirmationAPI}
                    contactInfo={contactInfo}
                    clientData={clientData}
                    clientProfile={clientProfile}
                    currentBalance={currentBalance}
                />
            ) : (
                <FetchingText />
            )}
        </ConfirmationModal>
    );
};
TransferConfirmationModal.displayName = 'TransferConfirmationModal';
