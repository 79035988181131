import { FC } from 'react';

import { TransferInBalance } from '@/utils/UodkaClients/Core';
import { IssuedPayment, ReceivedPayment } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';

import { getTaxName } from '@/views/bills/BillViewer/getTaxName';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    net: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    details: {
        padding: '11px 0 11px 15px',
        fontSize: theme.typography.caption.fontSize,
        color: theme.palette.text.disabled,
    },
    detailsItem: {
        display: 'flex',
        justifyContent: 'space-between',
        '&:not(:last-child)': {
            marginBottom: 2,
        },
    },
    detailsTitle: {},
    detailsValue: {
        fontWeight: 600,
    },
    fee: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        paddingTop: 7,
        borderTop: 'solid 2px rgba(0,0,0,.03)',
    },
    sum: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        paddingTop: 7,
    },
    title: {
        fontSize: theme.typography.caption.fontSize,
        lineHeight: 1,
        width: 126,
    },
    value: {
        fontSize: theme.typography.body2.fontSize,
        lineHeight: 1,
        fontWeight: 600,
    },
}));

export const PaymentStatement: FC<{
    payment: IssuedPayment | ReceivedPayment | undefined;
    transfer: TransferInBalance | undefined;
    sumTitle: string;
    total: number;
}> = ({ payment, transfer, sumTitle, total }) => {
    // STYLE
    const theme = useTheme();
    const c = useStyles(theme);

    // HOOKS
    const { txt } = useLanguage();

    // DATA

    return (
        <div className={c.root}>
            <div className={c.net}>
                <div className={c.title}>{sumTitle}</div>
                <div className={c.value}>{`¥${total.toLocaleString()}`}</div>
            </div>
            <div className={c.details}>
                {payment ? (
                    <>
                        {payment.paymentData.items.map((item, i) => (
                            <div key={i} className={c.detailsItem}>
                                <div className={c.detailsTitle}>{item.title}</div>
                                <div className={c.detailsValue}>{`¥${(
                                    item.unitPrice * item.quantity
                                ).toLocaleString()}`}</div>
                            </div>
                        ))}
                        {payment.paymentData.summary.taxes.map((item, i) => (
                            <div key={i} className={c.detailsItem}>
                                <div className={c.detailsTitle}>{getTaxName({ taxTitle: item.title, txt })}</div>
                                <div className={c.detailsValue}>{`¥${item.value.toLocaleString()}`}</div>
                            </div>
                        ))}
                    </>
                ) : (
                    <div className={c.detailsTitle}>{txt({ en: 'No statement.', ja: '明細がありません' })}</div>
                )}
            </div>
            {transfer && (
                <>
                    <div className={c.fee}>
                        <div className={c.title}>
                            {txt({ en: 'Remittance fee (tax included)', ja: '送金手数料 (税込)' })}
                        </div>
                        <div className={c.value}>{`¥${transfer.feeOnIssuer.total.toLocaleString()}`}</div>
                    </div>
                    <div className={c.sum}>
                        <div className={c.title}>{txt({ en: 'Total withdraw', ja: '出金額' })}</div>
                        <div className={c.value}>{`¥${(
                            transfer.amount + transfer.feeOnIssuer.total
                        ).toLocaleString()}`}</div>
                    </div>
                </>
            )}
        </div>
    );
};
PaymentStatement.displayName = 'PaymentStatement';
