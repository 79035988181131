import { FC } from 'react';
import clsx from 'clsx';

import { ReceivedBillsFilter } from '@/utils/UodkaClients/Invoices';
import { useLanguage, ContactItem, UseItems } from '@/utils/customHooks';
import Table from '@/components/0_atom/Table';
import ContactNameWithIcon from '@/components/2_org/ContactNameWithIcon';

import { ReceivedBillItem } from '@/views/bills/useBillItems';
import { useBillsRouting } from '@/views/bills/useBillsRouting';
import { ReceivedBillStatusDisplay } from '@/views/bills/ReceivedBills/ReceivedBillStatusDisplay';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        // width: '100%',
        // flexGrow: 1,
    },
    row: {
        alignItems: 'flex-start',
    },
    total: {
        fontWeight: 600,
        fontSize: theme.typography.h1.fontSize,
        // color: theme.palette.text.disabled,
    },
    subject: {
        fontWeight: 600,
        fontSize: theme.typography.body2.fontSize,
        // color: theme.palette.text.disabled,
    },
    date: {
        fontWeight: 600,
        fontSize: theme.typography.caption.fontSize,
        color: theme.palette.text.disabled,
    },
    contact: {
        '& .MuiTypography-root': {
            fontSize: theme.typography.body2.fontSize,
        },
    },
    revoked: {
        opacity: 0.3,
    },
}));

export const ReceivedBillsTable: FC<{
    contactItems: ContactItem[] | undefined;
    currentContactItem: ContactItem | undefined;
    setCurrentContactItem: (contactItem: ContactItem | undefined) => void;
    filter: ReceivedBillsFilter;
    setFilter: (filter: ReceivedBillsFilter) => void;
    items: ReturnType<UseItems<ReceivedBillItem>> | undefined;
}> = ({ contactItems, currentContactItem, setCurrentContactItem, filter, setFilter, items }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt, getDateTimeStr } = useLanguage();
    const { slug1, setSlug3 } = useBillsRouting();

    // HANDLER
    const filteredBillItems = (() => {
        const allItems = items?.ids.map((id) => items.getItemAbsolutely(id));
        if (!allItems || !currentContactItem) {
            return allItems;
        }
        return allItems.filter(({ contactItem }) => contactItem.id === currentContactItem.id);
    })();

    return (
        <Table
            className={c.root}
            emptyPlaceholder={txt({ en: 'No bill', ja: '請求がありません' })}
            widths={[150, 120, `calc(100% - ${150 + 120 + 134 + 66}px)`, 134, 66]}
            headItems={[
                {
                    label: txt({
                        en: 'Contact',
                        ja: '相手',
                    }),
                    menu: {
                        emptyPlaceholder: 'unexpecetd',
                        buttonText: (() => {
                            if (!currentContactItem) {
                                return txt({ en: 'All', ja: '全て' });
                            }
                            return currentContactItem.contact.name;
                        })(),
                        items: [
                            {
                                text: txt({ en: 'All', ja: '全て' }),
                                onClick: () => setCurrentContactItem(undefined),
                                isSelected: !currentContactItem,
                            },
                            ...(contactItems?.map((contactItem) => ({
                                text: contactItem.contact.name,
                                onClick: () => setCurrentContactItem(contactItem),
                                isSelected: currentContactItem?.id === contactItem.contact.id,
                            })) || [
                                {
                                    text: txt({ en: 'Fetching...', ja: '取得中...' }),
                                    onClick: () => undefined,
                                    isSelected: false,
                                },
                            ]),
                        ],
                    },
                },
                {
                    label: txt({
                        en: 'Amount',
                        ja: '金額',
                    }),
                },
                {
                    label: txt({
                        en: 'Subject',
                        ja: '件名',
                    }),
                },
                {
                    label: txt({
                        en: 'State',
                        ja: '状態',
                    }),
                },
                {
                    label: txt({
                        en: 'Created at',
                        ja: '作成日時',
                    }),
                },
            ]}
            rowItems={filteredBillItems?.map(({ receivedBill, contactItem }) => {
                const { contact, billData, createdAt } = receivedBill;
                return {
                    items: [
                        {
                            children: (
                                <ContactNameWithIcon
                                    contact={contact}
                                    profile={contactItem.profile?.data}
                                    className={c.contact}
                                />
                            ),
                        },
                        {
                            children: (
                                <MuiTypography
                                    className={clsx(c.total, {
                                        [c.revoked]:
                                            !!receivedBill.signatures.issuer.revoke ||
                                            !!receivedBill.signatures.recipient.revoke,
                                    })}
                                >{`¥ ${billData.summary.total.toLocaleString()}`}</MuiTypography>
                            ),
                        },
                        {
                            children: (
                                <MuiTypography
                                    className={clsx(c.subject, {
                                        [c.revoked]:
                                            !!receivedBill.signatures.issuer.revoke ||
                                            !!receivedBill.signatures.recipient.revoke,
                                    })}
                                >
                                    {billData.title || '-'}
                                </MuiTypography>
                            ),
                        },
                        {
                            children: <ReceivedBillStatusDisplay bill={receivedBill} />,
                        },
                        {
                            children: <MuiTypography className={c.date}>{getDateTimeStr(createdAt)}</MuiTypography>,
                        },
                    ],
                    isSelected: false,
                    onClick: () => {
                        setSlug3({ slug1: 'received', slug2: contact.id, slug3: receivedBill.id });
                    },
                    className: c.row,
                };
            })}
        />
    );
};
ReceivedBillsTable.displayName = 'ReceivedBillsTable';
