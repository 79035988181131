import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 345 247" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M172.5 123.5C205.868 123.5 232.875 95.8779 232.875 61.75C232.875 27.6221 205.868 0 172.5 0C139.132 0 112.125 27.6221 112.125 61.75C112.125 95.8779 139.132 123.5 172.5 123.5ZM172.5 17.6429C196.273 17.6429 215.625 37.4359 215.625 61.75C215.625 86.0641 196.273 105.857 172.5 105.857C148.727 105.857 129.375 86.0641 129.375 61.75C129.375 37.4359 148.727 17.6429 172.5 17.6429ZM51.75 105.857C75.5766 105.857 94.875 86.1192 94.875 61.75C94.875 37.3808 75.5766 17.6429 51.75 17.6429C27.9234 17.6429 8.625 37.3808 8.625 61.75C8.625 86.1192 27.9234 105.857 51.75 105.857ZM51.75 35.2857C66.0352 35.2857 77.625 47.1395 77.625 61.75C77.625 76.3605 66.0352 88.2143 51.75 88.2143C37.4648 88.2143 25.875 76.3605 25.875 61.75C25.875 47.1395 37.4648 35.2857 51.75 35.2857ZM84.6867 130.612C78.2719 126.146 70.6711 123.5 62.5312 123.5H40.9688C18.382 123.5 0 143.293 0 167.607C0 172.459 3.88125 176.429 8.625 176.429C13.3688 176.429 17.25 172.459 17.25 167.607C17.25 152.997 27.9234 141.143 40.9688 141.143H62.5312C65.4961 141.143 68.3531 141.804 70.9945 142.962C75.0375 138.276 79.6734 134.141 84.6867 130.612Z"
                fill="currentColor"
            />
            <path
                d="M336.375 61.75C336.375 37.3808 317.077 17.6429 293.25 17.6429C281.617 17.6429 271.063 22.348 263.308 30C263.035 30.2692 262.766 30.542 262.5 30.8184C262.5 30.8184 278.75 30.8184 286 30.8184C293.25 30.8184 299.5 36.0637 299.5 36.0637C310.768 38.9246 319.125 49.3434 319.125 61.75C319.125 74.2505 310.641 84.733 299.244 87.5L298 105.593C319.59 103.176 336.375 84.4772 336.375 61.75Z"
                fill="currentColor"
            />
            <path
                d="M304.031 141.143C317.077 141.143 327.75 152.997 327.75 167.607C327.75 172.459 331.631 176.429 336.375 176.429C341.119 176.429 345 172.459 345 167.607C345 143.293 326.618 123.5 304.031 123.5H298V141.143H304.031Z"
                fill="currentColor"
            />
            <path
                d="M215.679 134.527C197.674 134.527 193.2 141.143 172.5 141.143C151.8 141.143 147.326 134.527 129.321 134.527C109.753 134.527 90.7242 143.458 79.5656 160.385C72.8813 170.529 69 182.714 69 195.836V220.536C69 235.146 80.5898 247 94.875 247H225V229.357H94.875C90.1312 229.357 86.25 225.387 86.25 220.536V195.836C86.25 186.683 88.8914 177.807 93.8508 170.254C101.29 158.951 114.551 152.17 129.267 152.17C144.038 152.17 149.32 158.786 172.5 158.786C195.68 158.786 200.963 152.17 215.679 152.17C218.693 152.17 221.646 152.454 224.5 153.004L233 136.928C227.408 135.336 221.568 134.527 215.679 134.527Z"
                fill="currentColor"
            />
            <path
                d="M272.617 47H255.382C250.265 47 246.178 51.0283 246.39 55.8619L251.64 174.966C251.841 179.523 255.808 183.119 260.632 183.119H267.367C272.192 183.119 276.158 179.524 276.359 174.966L281.609 55.8619C281.822 51.0283 277.734 47 272.617 47Z"
                fill="currentColor"
            />
            <path
                d="M243 217.149C243 206.186 252.402 197.299 264 197.299C275.598 197.299 285 206.186 285 217.149C285 228.113 275.598 237 264 237C252.402 237 243 228.113 243 217.149Z"
                fill="currentColor"
            />
        </svg>
    );
};
Svg.displayName = 'svg/members-caution';
export default Svg;
