import { FC, ReactNode, RefObject } from 'react';
import clsx from 'clsx';

import Button from '@/components/0_atom/Button';

import SvgChevron from '@/svgs/fa-chevron-up-regular';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import MuiDrawer from '@material-ui/core/Drawer';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        background: '#f8f8f8',
        boxShadow: '0 80px 200px 0 rgba(0,0,0,.3)',
        padding: '29px 39px',
        width: 'calc(100vw - 170px)',
    },
    backdropProps: {
        background: 'none',
    },
    backButton: {
        fontWeight: 600,
        padding: '6px 12px 6px 0',
        margin: '0 auto 36px 0',
        '& .MuiButton-label': {
            lineHeight: 1,
        },
    },
    backButtonIcon: {
        transform: 'rotate(270deg)',
    },
    title: {
        paddingLeft: 3,
    },
}));

const Drawer: FC<{
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    paperClassName?: string;
    backdropClassName?: string;
    anchor?: 'bottom' | 'left' | 'right' | 'top';
    backText?: string;
    title?: string;
    innerRef?: RefObject<HTMLDivElement>;
    paperRef?: RefObject<HTMLDivElement>;
}> = ({
    isOpen,
    onClose,
    children,
    paperClassName,
    backdropClassName,
    anchor,
    backText,
    title,
    innerRef,
    paperRef,
}) => {
    const c = useStyles(useTheme());

    return (
        <MuiDrawer
            open={isOpen}
            anchor={anchor || 'right'}
            onClose={onClose}
            classes={{
                paper: clsx(c.drawerPaper, paperClassName),
            }}
            transitionDuration={{ enter: 500, exit: 300 }}
            BackdropProps={{
                classes: {
                    root: clsx(c.backdropProps, backdropClassName),
                },
            }}
            innerRef={innerRef}
            PaperProps={{ ref: paperRef }}
        >
            {backText && (
                <Button
                    className={c.backButton}
                    startIcon={<SvgChevron className={c.backButtonIcon} />}
                    onClick={() => onClose()}
                >
                    {backText}
                </Button>
            )}
            {title && (
                <MuiTypography className={c.title} variant={'h1'}>
                    {title}
                </MuiTypography>
            )}
            <div>{children}</div>
        </MuiDrawer>
    );
};
Drawer.displayName = 'Drawer';
export default Drawer;
