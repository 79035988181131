import { FC, useState, useEffect } from 'react';

import { InvoiceDataItem, InvoiceDataItemInterface, TaxInInvoiceDataItem } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';
import { TextSelector } from '@/components/1_mol/Selector';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'stretch',
        '& > :first-child': {
            marginRight: 7,
        },
    },
    selector: {
        padding: '0 32px 0 16px',
    },
    value: {
        padding: 14,
        fontSize: 12,
        fontWeight: 600,
        color: 'rgba(0,0,0,.35)',
        background: '#fafafa',
        borderRadius: 4,
        minWidth: 100,
    },
}));

const updateRate = ({
    item,
    newRate,
}: {
    item: InvoiceDataItemInterface;
    newRate: 0.1 | 0.08 | 0;
}): InvoiceDataItemInterface => {
    const filteredTaxes = item.taxes.filter(
        (tax) => !(tax.title === 'consumptionTax (10%)' || tax.title === 'consumptionTax (8%)')
    );
    if (newRate === 0) {
        return {
            ...item,
            taxes: filteredTaxes,
        };
    }
    return {
        ...item,
        taxes: [
            ...filteredTaxes,
            new TaxInInvoiceDataItem({
                title: newRate === 0.1 ? 'consumptionTax (10%)' : 'consumptionTax (8%)',
                value: item.unitPrice * item.quantity * newRate,
            }),
        ],
    };
};

export const ConsumptionTaxEditor: FC<{
    item: InvoiceDataItem;
    setItem: (item: InvoiceDataItemInterface) => void;
}> = ({ item, setItem }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [rate, setRate] = useState<0.1 | 0.08 | 0>(0);

    // DATA
    const subtotal = item.unitPrice * item.quantity;
    const taxValue = subtotal * rate;

    // USEEFFECT
    useEffect(() => {
        setItem(updateRate({ item: item.data, newRate: rate }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subtotal, rate]);

    return (
        <div className={c.root}>
            <MuiTypography className={c.value}>{`¥${taxValue.toLocaleString()}`}</MuiTypography>
            <TextSelector
                className={c.selector}
                items={[
                    {
                        id: '0.1',
                        text: '10%',
                    },
                    {
                        id: '0.08',
                        text: '8%',
                    },
                    {
                        id: '0',
                        text: txt({ en: 'Exempt', ja: '非課税' }),
                    },
                ]}
                onSelect={(newRate) => setRate(Number(newRate) as 0.1 | 0.08 | 0)}
                selectedId={String(rate)}
                emptyPlaceholder={'unexpected'}
            />
        </div>
    );
};
ConsumptionTaxEditor.displayName = 'ConsumptionTaxEditor';
