import { FC } from 'react';

import { InviteForContactAPIForOwner } from '@/utils/UodkaClients/Core';
import { IssuedBillAPIForGeneral } from '@/utils/UodkaClients/Invoices';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { getEnvVariables } from '@/utils/envVariables';
import { getShortenedString } from '@/utils/utilFunctions';
import MutationButton from '@/components/0_atom/MutationButton';
import CopyableText from '@/components/0_atom/CopyableText';

import SvgTrash from '@/svgs/trash';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    description: {},
    urlBox: {
        marginTop: 32,
        display: 'flex',
        alignItems: 'stretch',
    },
    url: {
        flexGrow: 1,
    },
    deleteButton: {
        marginLeft: 4,
    },
    expire: {
        color: theme.palette.text.disabled,
        fontSize: theme.typography.caption.fontSize,
        marginTop: 4,
        marginBottom: 13,
        paddingLeft: 4,
    },
}));

export const IssuedBillShareModalContentInvite: FC<{
    issuedBillAPI: IssuedBillAPIForGeneral;
    inviteAPI: InviteForContactAPIForOwner;
    setInviteAPI: (inviteAPI: InviteForContactAPIForOwner | undefined) => void;
    closeModal: () => void;
}> = ({ issuedBillAPI, inviteAPI, setInviteAPI, closeModal }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { currentBusiness } = useBusinessCtxAbsolutely();
    const { txt, getDateStr } = useLanguage();

    // MUTATION
    const runRemoveInvite = async () => {
        const result = await inviteAPI.remove();
        setInviteAPI(undefined);
        return result;
    };

    // DATA
    const inviteUrl = `${getEnvVariables().billsRootUrl}/${currentBusiness.id}/${issuedBillAPI.contact.id}/${
        issuedBillAPI.id
    }/${inviteAPI.codeText}`;

    return (
        <>
            <MuiTypography className={c.description}>
                {txt({
                    en: 'The person who opens the URL can view the shared invoice and link to the contact.',
                    ja: 'URLを開いた相手は、共有された請求書の確認・コンタクトのリンクが可能です。',
                })}
            </MuiTypography>
            <div className={c.urlBox}>
                <CopyableText
                    className={c.url}
                    copyText={inviteUrl}
                    displayText={getShortenedString({
                        text: inviteUrl,
                        maxLangth: 32,
                    })}
                />
                <MutationButton
                    startIcon={<SvgTrash />}
                    className={c.deleteButton}
                    color={'error'}
                    mutation={{
                        run: runRemoveInvite,
                        description: txt({ en: 'Linked', ja: 'リンク済み' }),
                        onThen: () => {
                            closeModal();
                        },
                    }}
                >
                    {txt({ en: 'Delete', ja: '削除' })}
                </MutationButton>
            </div>
            <MuiTypography className={c.expire}>
                {txt({
                    en: `URL is valid through ${getDateStr(inviteAPI.expireAt)}`,
                    ja: `URLの有効期限：${getDateStr(inviteAPI.expireAt)}`,
                })}
            </MuiTypography>
        </>
    );
};
IssuedBillShareModalContentInvite.displayName = 'IssuedBillShareModalContentInvite';
