import { GraphQLSdk as CertSdk } from '@/utils/UodkaClients/Cert/graphql/graphqlSdk';
import { getSignatureFromGql } from '@/utils/UodkaClients/Cert';
import { BillData } from '@/utils/UodkaClients/Invoices/models/InvoiceData';
import { IssueingSignature } from '@/utils/UodkaClients/Invoices/models/Signature';
import {
    getBillDataString,
    stringifyObject,
    getUnixTime,
    getHexDigest,
} from '@/utils/UodkaClients/Invoices/utils/verifySignature';

export const createIssueingSignature = async ({
    businessId,
    contactId,
    certSdk,
    billData,
}: {
    businessId: string;
    contactId: string;
    certSdk: CertSdk;
    billData: BillData;
}): Promise<IssueingSignature> => {
    const billDataString = getBillDataString({ billData });
    const issuedAt = new Date();
    const issuedAtString = stringifyObject({ issuedAt: getUnixTime(issuedAt) });
    const hexDigest = getHexDigest(billDataString + issuedAtString);
    const { createResourceAndSignature } = await certSdk.createResourceAndSignature({
        input: {
            businessId,
            contactId,
            signatureText: hexDigest,
        },
    });
    return new IssueingSignature({
        issuedAt,
        signature: getSignatureFromGql(createResourceAndSignature.signature),
    });
};
