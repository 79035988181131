import { FC } from 'react';
import clsx from 'clsx';

import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import ContactNameWithIcon from '@/components/2_org/ContactNameWithIcon';
import { PreparingBankAccountCard } from '@/components/2_org/BankAccountCard/PreparingBankAccountCard';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    holder: {
        marginBottom: 24,
    },
}));

export const PreparingBankAccountCardWithHolder: FC<{
    className?: string;
    remitter:
        | {
              name: string;
              profile: BusinessProfile | undefined;
          }
        | 'self';
    holder: {
        name: string;
        profile: BusinessProfile | undefined;
    };
}> = ({ className, remitter, holder }) => {
    // STYLE
    const theme = useTheme();
    const c = useStyles(theme);

    return (
        <div className={clsx(c.root, className)}>
            <ContactNameWithIcon className={c.holder} contact={{ name: holder.name }} profile={holder.profile} />
            <PreparingBankAccountCard remitter={remitter} />
        </div>
    );
};
PreparingBankAccountCardWithHolder.displayName = 'PreparingBankAccountCardWithHolder';
