import { FC, useState, useEffect } from 'react';

import { ClientData } from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import {
    ReceivedBillAPIForGeneral,
    TransferConfirmationForBillAPIForGeneral,
    IssuedPayment,
} from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';
import FetchingText from '@/components/0_atom/FetchingText';
import MutationButton from '@/components/0_atom/MutationButton';
import { useTransferInformations } from '@/views/transactions/AddTransferModal/useTransferInformations';

import { TransferConfirmationModal } from '@/views/bills/BillDrawer/ReceivedBill/PayToBillButton/TransferConfirmationModal';

export const PayToBillButton: FC<{
    className?: string;
    receivedBillAPI: ReceivedBillAPIForGeneral;
    onAddTransfer: (input: { payment: IssuedPayment }) => void;
    contactInfo: {
        id: string;
        name: string;
        profile: BusinessProfile | undefined;
    };
    clientData: ClientData;
    clientProfile: BusinessProfile | undefined;
    isAbleToCreateTransfer: boolean | 'fetching';
    setIsAbleToCreateTransfer: (isAbleToCreateTransfer: boolean) => void;
}> = ({
    className,
    receivedBillAPI,
    onAddTransfer,
    contactInfo,
    clientData,
    clientProfile,
    isAbleToCreateTransfer,
    setIsAbleToCreateTransfer,
}) => {
    // HOOKS
    const { fees, currentBalance } = useTransferInformations();
    const { txt } = useLanguage();
    const [transferConfirmationForBillAPI, setTransferConfirmationForBillAPI] = useState<
        TransferConfirmationForBillAPIForGeneral | undefined
    >(undefined);

    // USEEFFECT
    useEffect(() => {
        if (fees !== undefined && currentBalance !== undefined) {
            const fee = (() => {
                if (receivedBillAPI.billData.payee.uodkaBankAccount.isDepositAccount) {
                    return fees.transfer.internal;
                }
                return fees.transfer.external;
            })();
            const totalCost = receivedBillAPI.billData.summary.total + fee.total;
            setIsAbleToCreateTransfer(totalCost <= currentBalance);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fees, currentBalance]);

    // DATA
    const paymentForBillState = (() => {
        if (!receivedBillAPI.payment) {
            return 'new';
        }
        if (receivedBillAPI.payment.transfer.error) {
            return 'retry';
        }
        if (receivedBillAPI.payment.transfer.issuedAt) {
            return 'completed';
        }
        return 'processing';
    })();
    const isFetching = isAbleToCreateTransfer === 'fetching' || fees === undefined || currentBalance === undefined;

    // HANDLER
    const runAddTransferConfirmation = async () => {
        if (isAbleToCreateTransfer) {
            const {
                transferConfirmationForBillAPI: fetchedTransferConfirmationForBillAPI,
                result,
            } = await receivedBillAPI.addTransferConfirmation();
            setTransferConfirmationForBillAPI(fetchedTransferConfirmationForBillAPI);
            return result;
        }
        throw new Error('PayToBillButton: !(isAbleToCreateTransfer)');
    };

    return (
        <>
            <MutationButton
                className={className}
                mutation={{
                    run: runAddTransferConfirmation,
                    description: txt({
                        en: `Prepare to pay ${receivedBillAPI.contact.name}'s bill`,
                        ja: `${receivedBillAPI.contact.name}からの請求への支払いを準備`,
                    }),
                }}
                role={'submit'}
                disabled={
                    isAbleToCreateTransfer === false ||
                    paymentForBillState === 'processing' ||
                    paymentForBillState === 'completed' ||
                    !!transferConfirmationForBillAPI ||
                    isFetching
                }
            >
                {isFetching ? (
                    <FetchingText />
                ) : paymentForBillState === 'new' ? (
                    txt({ en: 'Set up a payment', ja: '支払いを開始' })
                ) : paymentForBillState === 'retry' ? (
                    txt({ en: 'Retry payment', ja: '支払いをリトライ' })
                ) : paymentForBillState === 'completed' ? (
                    txt({ en: 'Payment completed', ja: '支払い済み' })
                ) : (
                    txt({ en: 'Processing payment', ja: '支払い処理中' })
                )}
            </MutationButton>
            {!isFetching && currentBalance !== undefined && (
                <TransferConfirmationModal
                    transferConfirmationAPI={transferConfirmationForBillAPI}
                    setTransferConfirmationAPI={setTransferConfirmationForBillAPI}
                    contactInfo={contactInfo}
                    clientData={clientData}
                    clientProfile={clientProfile}
                    currentBalance={currentBalance}
                    onAddTransfer={onAddTransfer}
                />
            )}
        </>
    );
};
PayToBillButton.displayName = 'PayToBillButton';
