import { InviteForContactInContact as GqlInviteForContactInContact } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { GqlDateTimeData } from '@/utils/UodkaClients/Core/graphql/customScalars';

export interface InviteForContactInContactInterface {
    codeText: string;
    createdAt: Date;
    expireAt: Date;
}

export class InviteForContactInContact implements InviteForContactInContactInterface {
    protected _codeText: string;

    protected _createdAt: Date;

    protected _expireAt: Date;

    get codeText() {
        return this._codeText;
    }

    get createdAt() {
        return this._createdAt;
    }

    get expireAt() {
        return this._expireAt;
    }

    get data() {
        return {
            codeText: this.codeText,
            createdAt: this.createdAt,
            expireAt: this.expireAt,
        };
    }

    constructor({ codeText, createdAt, expireAt }: InviteForContactInContactInterface) {
        this._codeText = codeText;
        this._createdAt = createdAt;
        this._expireAt = expireAt;
    }
}

export const getInviteForContactInContactFromGql = ({
    codeText,
    createdAt,
    expireAt,
}: GqlInviteForContactInContact) => {
    return new InviteForContactInContact({
        codeText,
        createdAt: GqlDateTimeData.serialize(createdAt),
        expireAt: GqlDateTimeData.serialize(expireAt),
    });
};
