import { FC } from 'react';
import clsx from 'clsx';

import { TransferInBalance, TransferErrorCode } from '@/utils/UodkaClients/Core';
import { useLanguage } from '@/utils/customHooks';

import SvgEllipsis from '@/svgs/ellipsis';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    '@keyframes ellipsisError': {
        '0%': {
            boxShadow: '0 0 0 0 rgba(255,71,71,.8)',
        },
        '100%': {
            boxShadow: '0 0 0 13px rgba(255,71,71,0)',
        },
    },
    '@keyframes ellipsisProcessing': {
        '0%': {
            boxShadow: '0 0 0 0 rgba(255,211,56,.8)',
        },
        '100%': {
            boxShadow: '0 0 0 13px rgba(255,211,56,0)',
        },
    },
    item: {
        color: '#ddd',
        position: 'relative',
        '&:not(:last-child)': {
            // marginBottom: 31,
            height: 18 + 31,
            '&::before': {
                content: '""',
                display: 'block',
                width: 2,
                height: 47,
                background: '#ddd',
                position: 'absolute',
                top: 9,
                left: 2,
            },
        },
    },
    itemInner: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    ellipsis: {
        width: 6,
        height: 6,
        marginRight: 20,
        marginTop: 7,
    },
    lastEllipsisError: {
        borderRadius: '50%',
        animation: '$ellipsisError 3s linear infinite',
    },
    lastEllipsisProcessing: {
        borderRadius: '50%',
        animation: '$ellipsisProcessing 3s linear infinite',
    },
    title: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 600,
        width: 127,
    },
    date: {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 600,
        width: 94,
        color: '#ddd',
        marginTop: 2,
    },
    description: {
        fontSize: theme.typography.caption.fontSize,
        width: 127 + 94,
        marginTop: 7,
        marginLeft: 26,
        display: 'block',
        fontWeight: 'normal',
    },
    completed: {
        color: theme.palette.success.main,
    },
    error: {
        color: theme.palette.error.main,
    },
    processing: {
        color: theme.palette.warning.main,
    },
}));

type ItemData = {
    title: string;
    date: Date;
    type: 'processing' | 'completed' | 'error';
    description?: string;
};

export const getTranferErrorDescription = ({
    transferErrorCode,
    txt,
}: {
    transferErrorCode: TransferErrorCode;
    txt: (multilingualTexts: { en: string; ja?: string | undefined }) => string;
}) => {
    if (transferErrorCode === 'bankProcessError') {
        return txt({
            en: 'Transfer has been cancelled by bank. Please check remitee account information and your remitter name.',
            ja: '銀行によって振り込みがキャンセルされました。振込先の口座情報と振り込み名義人を確認してください。',
        });
    }
    if (transferErrorCode === 'inputError') {
        return txt({
            en: 'The payee you specified or your remitter name seems to be incorrect.',
            ja: '指定された振込先、または振り込み名義人が正しくありません。',
        });
    }
    return txt({
        en: 'Transfer has been cancelled due to system reasons.',
        ja: 'システムの都合で振り込みがキャンセルされました。お手数ですがもう一度お試しください。',
    });
};

export const TransferStatusDetails: FC<{
    className?: string;
    transfer: TransferInBalance;
}> = ({ className, transfer }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt, getDateTimeStr } = useLanguage();

    // DATA
    const items: ItemData[] = (() => {
        const { createdAt, issuedAt, error } = transfer;
        const arr: ItemData[] = [
            {
                title: txt({ en: 'Applied', ja: '受付' }),
                date: createdAt,
                type: 'processing',
            },
        ];
        if (issuedAt) {
            arr.push({
                title: txt({ en: 'Completed', ja: '完了' }),
                date: issuedAt,
                type: 'completed',
            });
        }
        if (error) {
            arr.push({
                title: txt({ en: 'Failed', ja: 'エラー' }),
                date: error.setAt,
                type: 'error',
                description: getTranferErrorDescription({ transferErrorCode: error.code, txt }),
            });
        }
        return arr;
    })();

    return (
        <div className={className}>
            {items.map((item, i) => (
                <div
                    key={i}
                    className={clsx(c.item, {
                        [c.completed]: item.type === 'completed' && i + 1 === items.length,
                        [c.error]: item.type === 'error' && i + 1 === items.length,
                        [c.processing]: item.type === 'processing' && i + 1 === items.length,
                    })}
                >
                    <div className={c.itemInner}>
                        <SvgEllipsis
                            className={clsx(c.ellipsis, {
                                [c.lastEllipsisError]: item.type === 'error' && i + 1 === items.length,
                                [c.lastEllipsisProcessing]: item.type === 'processing' && i + 1 === items.length,
                            })}
                        />
                        <MuiTypography className={c.title}>{item.title}</MuiTypography>
                        <MuiTypography className={c.date}>{getDateTimeStr(item.date)}</MuiTypography>
                    </div>
                    {item.description && <span className={c.description}>{item.description}</span>}
                </div>
            ))}
        </div>
    );
};
TransferStatusDetails.displayName = 'TransferStatusDetails';
