import { FC, useState, useEffect } from 'react';

import { InvoiceDataItem, InvoiceDataItemInterface, TaxInInvoiceDataItem } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';
import { FloatInput } from '@/components/0_atom/Input';
import { TextSelector } from '@/components/1_mol/Selector';
import { getCurrencyText } from '@/utils/utilFunctions';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'stretch',
        '& > :first-child': {
            marginRight: 7,
        },
    },
    selector: {
        padding: '0 32px 0 16px',
    },
    input: {
        '& input': {
            padding: 14,
            fontSize: 12,
        },
    },
    value: {
        padding: 14,
        fontSize: 12,
        fontWeight: 600,
        color: 'rgba(0,0,0,.35)',
        background: '#fafafa',
        borderRadius: 4,
        minWidth: 100,
    },
}));

const getGeneralAmount = (priceBeforeTax: number): number => {
    if (priceBeforeTax > 1000000) {
        return (priceBeforeTax - 1000000) * 0.2042 + 102100;
    }
    return priceBeforeTax * 0.1021 * -1;
};

const updateValue = ({
    item,
    newValue,
}: {
    item: InvoiceDataItemInterface;
    newValue: number;
}): InvoiceDataItemInterface => {
    const filteredTaxes = item.taxes.filter((tax) => tax.title !== 'withholdingTax');
    if (newValue === 0) {
        return {
            ...item,
            taxes: filteredTaxes,
        };
    }
    return {
        ...item,
        taxes: [
            ...filteredTaxes,
            new TaxInInvoiceDataItem({
                title: 'withholdingTax',
                value: newValue,
            }),
        ],
    };
};

export const WithholdingTaxEditor: FC<{
    item: InvoiceDataItem;
    setItem: (item: InvoiceDataItemInterface) => void;
}> = ({ item, setItem }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [mode, setMode] = useState<'general' | 'manual' | 'na'>('na');
    const [taxValue, setTaxValue] = useState(0);

    // DATA
    const subtotal = item.unitPrice * item.quantity;

    // USEEFFECT
    useEffect(() => {
        if (mode === 'general') {
            const newValue = getGeneralAmount(subtotal);
            setTaxValue(newValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subtotal]);
    useEffect(() => {
        setItem(updateValue({ item: item.data, newValue: taxValue }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taxValue]);
    useEffect(() => {
        if (mode === 'general') {
            const newValue = getGeneralAmount(subtotal);
            setTaxValue(newValue);
        }
        if (mode === 'na') {
            setTaxValue(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);

    return (
        <div className={c.root}>
            {mode === 'manual' ? (
                <FloatInput
                    className={c.input}
                    value={-taxValue}
                    setValue={(newValue) => setTaxValue(-(newValue || 0))}
                    startAdornment={'¥'}
                />
            ) : (
                <MuiTypography className={c.value}>{getCurrencyText(taxValue)}</MuiTypography>
            )}
            <TextSelector
                className={c.selector}
                items={[
                    {
                        id: 'general',
                        text: txt({ en: 'General remuneration', ja: '一般的な報酬' }),
                    },
                    {
                        id: 'manual',
                        text: txt({ en: 'Manual', ja: '手動入力' }),
                    },
                    {
                        id: 'na',
                        text: txt({ en: 'N.A.', ja: 'なし' }),
                    },
                ]}
                onSelect={(newMode) => setMode(newMode as 'general' | 'manual' | 'na')}
                selectedId={mode}
                emptyPlaceholder={'unexpected'}
            />
        </div>
    );
};
WithholdingTaxEditor.displayName = 'WithholdingTaxEditor';
