import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 590 513" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M365 336.1L325.5 296.6C320.8 291.9 313.2 291.9 308.5 296.6L269.5 335.6L269 448.1H525V304.1L469.5 248.6C464.8 243.9 457.2 243.9 452.5 248.6L365 336.1ZM461 285.4L493 317.4V416.1H301.1L301.4 348.9L317 333.3L365 381.3C385.3 361 442.7 303.7 461 285.4V285.4ZM333 256.1C368.3 256.1 397 227.4 397 192.1C397 156.8 368.3 128.1 333 128.1C297.7 128.1 269 156.8 269 192.1C269 227.4 297.7 256.1 333 256.1ZM333 160.1C350.6 160.1 365 174.5 365 192.1C365 209.7 350.6 224.1 333 224.1C315.4 224.1 301 209.7 301 192.1C301 174.5 315.4 160.1 333 160.1ZM575.9 98L492 14.1C483 5.1 470.8 0 458.1 0H254C227.5 0.1 206 21.6 206 48.1V464.1C206 490.6 227.5 512.1 254 512.1H542C568.5 512.1 590 490.6 590 464.1V132C590 119.3 584.9 107 575.9 98ZM462 32.6C464.8 33.3 467.3 34.7 469.4 36.8L553.3 120.7C555.4 122.8 556.8 125.3 557.5 128.1H462V32.6ZM558 464.1C558 472.9 550.8 480.1 542 480.1H254C245.2 480.1 238 472.9 238 464.1V48.1C238 39.3 245.2 32.1 254 32.1H430V136.1C430 149.4 440.7 160.1 454 160.1H558V464.1Z"
                fill="currentColor"
            />
            <path
                d="M162.917 349.833H99.1667V286.083C99.1667 282.17 95.9969 279 92.0833 279H77.9167C74.0031 279 70.8333 282.17 70.8333 286.083V349.833H7.08333C3.16979 349.833 0 353.003 0 356.917V371.083C0 374.997 3.16979 378.167 7.08333 378.167H70.8333V441.917C70.8333 445.83 74.0031 449 77.9167 449H92.0833C95.9969 449 99.1667 445.83 99.1667 441.917V378.167H162.917C166.83 378.167 170 374.997 170 371.083V356.917C170 353.003 166.83 349.833 162.917 349.833Z"
                fill="currentColor"
            />
        </svg>
    );
};
Svg.displayName = 'svg/image-plus';
export default Svg;
