import { TaxInInvoiceDataItem as GqlTaxInInvoiceDataItem } from '@/utils/UodkaClients/Invoices/graphql/generated/graphqlClient';

interface TaxInInvoiceDataItemInterface {
    title: string;
    value: number;
}

export class TaxInInvoiceDataItem implements TaxInInvoiceDataItemInterface {
    protected _title: string;

    protected _value: number;

    get title() {
        return this._title;
    }

    get value() {
        return this._value;
    }

    get data() {
        return {
            title: this._title,
            value: this._value,
        };
    }

    constructor({ title, value }: TaxInInvoiceDataItemInterface) {
        this._title = title;
        this._value = value;
    }
}

export const getTaxInInvoiceDataItemFromGql = ({ title, value }: GqlTaxInInvoiceDataItem) => {
    return new TaxInInvoiceDataItem({ title, value });
};
