import { InviteForContact as GqlInviteForContact } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { GqlDateTimeData } from '@/utils/UodkaClients/Core/graphql/customScalars';
import { ClientDataInInvite, getClientDataInInviteFromGql } from '@/utils/UodkaClients/Core/models/ClientData';
import { ContactInInvite, getContactInInviteFromGql } from '@/utils/UodkaClients/Core/models/Contact';

export interface InviteForContactInterface {
    codeText: string;
    createdAt: Date;
    expireAt: Date;
    business: ClientDataInInvite;
    contact: ContactInInvite;
}

export class InviteForContact implements InviteForContactInterface {
    protected _codeText: string;

    protected _createdAt: Date;

    protected _expireAt: Date;

    protected _business: ClientDataInInvite;

    protected _contact: ContactInInvite;

    get codeText() {
        return this._codeText;
    }

    get createdAt() {
        return this._createdAt;
    }

    get expireAt() {
        return this._expireAt;
    }

    get business() {
        return this._business;
    }

    get contact() {
        return this._contact;
    }

    get data() {
        return {
            codeText: this.codeText,
            createdAt: this.createdAt,
            expireAt: this.expireAt,
            business: this.business,
            contact: this.contact,
        };
    }

    constructor({ codeText, createdAt, expireAt, business, contact }: InviteForContactInterface) {
        this._codeText = codeText;
        this._createdAt = createdAt;
        this._expireAt = expireAt;
        this._business = business;
        this._contact = contact;
    }
}

export const getInviteForContactFromGql = ({
    codeText,
    createdAt,
    expireAt,
    business,
    contact,
}: GqlInviteForContact) => {
    return new InviteForContact({
        codeText,
        createdAt: GqlDateTimeData.serialize(createdAt),
        expireAt: GqlDateTimeData.serialize(expireAt),
        business: getClientDataInInviteFromGql(business),
        contact: getContactInInviteFromGql(contact),
    });
};
