/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';

import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import { useBusinessCtxAbsolutely } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import BusinessIcon from '@/components/0_atom/BusinessIcon';
import NestableButtons from '@/components/1_mol/NestableButtons';

import { ConfigViwSlug1, ConfigViwSlug2, ConfigViwSlug3 } from '@/views/config/useConfigRouting';

import SvgUser from '@/svgs/user';
import SvgMembers from '@/svgs/members';
import SvgProfile from '@/svgs/profile';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        width: 258,
    },
}));

export const ConfigNavMenu: FC<{
    slug1: ConfigViwSlug1;
    slug2: ConfigViwSlug2;
    slug3: ConfigViwSlug3;
    setSlug1: (slugs: { slug1?: ConfigViwSlug1 }) => void;
    setSlug2: (slugs: { slug1: ConfigViwSlug1; slug2?: ConfigViwSlug2 }) => void;
    setSlug3: (slugs: { slug1: ConfigViwSlug1; slug2: ConfigViwSlug2; slug3?: ConfigViwSlug3 }) => void;
    profile: BusinessProfile | undefined | 'fetching';
}> = ({ slug1, slug2, slug3, setSlug1, setSlug2, setSlug3, profile }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { currentBusiness } = useBusinessCtxAbsolutely();
    const { txt } = useLanguage();

    return (
        <NestableButtons
            className={c.root}
            selectedId={slug1}
            setSelectedId={(id: any) => setSlug1({ slug1: id })}
            items={[
                {
                    button: {
                        icon: (
                            <BusinessIcon
                                name={currentBusiness.name}
                                iconSrc={profile === 'fetching' ? undefined : profile?.iconSrc}
                                themeColor={profile === 'fetching' ? undefined : profile?.themeColor}
                            />
                        ),
                        text: txt({
                            en: `${currentBusiness?.name}'s Configs`,
                            ja: `${currentBusiness?.name} の設定`,
                        }),
                        isAvatarIcon: true,
                    },
                    id: 'business',
                    isSelected: slug1 === 'business',
                    childButtons: {
                        selectedChildId: slug2,
                        setSelectedChildId: (id: any) => setSlug2({ slug1: 'business', slug2: id }),
                        items: [
                            {
                                button: {
                                    icon: (
                                        <BusinessIcon
                                            name={currentBusiness.name}
                                            iconSrc={profile === 'fetching' ? undefined : profile?.iconSrc}
                                            themeColor={profile === 'fetching' ? undefined : profile?.themeColor}
                                        />
                                    ),
                                    text: txt({
                                        en: 'Account',
                                        ja: 'アカウント',
                                    }),
                                },
                                id: 'account',
                                isSelected: slug2 === 'account',
                                childButtons: {
                                    selectedChildId: slug3,
                                    setSelectedChildId: (id: any) =>
                                        setSlug3({ slug1: 'business', slug2: 'account', slug3: id }),
                                    items: [
                                        {
                                            button: {
                                                text: txt({
                                                    en: 'Basic',
                                                    ja: '基本情報',
                                                }),
                                                size: 'small',
                                            },
                                            id: 'data',
                                            isSelected: slug3 === 'data',
                                        },
                                        {
                                            button: {
                                                text: txt({
                                                    en: 'Deposit Account',
                                                    ja: '入金口座',
                                                }),
                                                size: 'small',
                                            },
                                            id: 'charge',
                                            isSelected: slug3 === 'charge',
                                        },
                                        {
                                            button: {
                                                text: txt({
                                                    en: 'License',
                                                    ja: 'ライセンス',
                                                }),
                                                size: 'small',
                                            },
                                            id: 'license',
                                            isSelected: slug3 === 'license',
                                        },
                                    ],
                                },
                            },
                            {
                                button: {
                                    icon: <SvgMembers />,
                                    text: txt({
                                        en: 'Members',
                                        ja: 'メンバー',
                                    }),
                                },
                                id: 'members',
                                isSelected: slug2 === 'members',
                            },
                            {
                                button: {
                                    icon: <SvgProfile />,
                                    text: txt({
                                        en: 'Profile',
                                        ja: 'プロフィール',
                                    }),
                                },
                                id: 'profile',
                                isSelected: slug2 === 'profile',
                            },
                            // {
                            //     button: {
                            //         icon: <SvgBill />,
                            //         text: txt({
                            //             en: 'Bills',
                            //             ja: '請求書',
                            //         }),
                            //     },
                            //     id: 'bills',
                            //     isSelected: slug2 === 'bills',
                            // },
                        ],
                    },
                },
                {
                    button: {
                        icon: <SvgUser />,
                        text: txt({
                            en: 'Personal Configs',
                            ja: '個人設定',
                        }),
                    },
                    id: 'user',
                    isSelected: slug1 === 'user',
                },
            ]}
        />
    );
};
ConfigNavMenu.displayName = 'ConfigNavMenu';
