import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { BankAccountInTransferInterface } from '@/utils/UodkaClients/Core';
import { BankAccountText } from '@/components/2_org/BankAccountCard/BankAccountText';

import SvgUodka from '@/svgs/uodkalogo';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    description: {
        marginRight: 14,
        width: 74,
        display: 'block',
        textAlign: 'left',
        whiteSpace: 'normal',
        lineHeight: 1,
    },
    bankAccount: {},
}));

export const BankAccountCardBase: FC<{
    className?: string;
    bankAccount: BankAccountInTransferInterface;
    accountDescription: ReactNode;
}> = ({ className, bankAccount, accountDescription }) => {
    // STYLE
    const theme = useTheme();
    const c = useStyles(theme);

    return (
        <div className={clsx(c.root, className)}>
            <div className={c.description}>{accountDescription}</div>
            <div className={c.bankAccount}>
                <BankAccountText bankAccount={bankAccount} isWithUodkaLogo={bankAccount.isDepositAccount} />
            </div>
        </div>
    );
};
BankAccountCardBase.displayName = 'BankAccountCardBase';
